import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Row, Col } from 'antd'

import { TalkTopTen, ProgramTalks } from '@cms/events/components/Dashboard'

import EventDetails from '@cms/events/components/Event/EventsDetails'

import { GET_EVENT } from '@cms/events/graphql/queries/event'

import { StyledCol } from '../styles'
import Loader from '@cms/core/components/Loader'
import Rating from '@cms/events/components/Rating'

import { TDDashboardCard } from '@cms/core/components/TDCards'

export default function Event({ history }) {
    const { eventId } = useParams()

    const { error, data, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding='10px' />

    if (loading || error) return null

    const { event } = data.event ? data : { event: null }

    return (
        <>
            {event && (
                <Row gutter={[24, 24]}>
                    <Col lg={8} sm={12} xs={12} md={12}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <TDDashboardCard>
                                    <Rating
                                        total={event.talksStats.total}
                                        average={event.talksStats.average}
                                        options={[
                                            {
                                                value: event.talksStats.average,
                                                quantity: event.talksStats.total
                                            }
                                        ]}
                                    />
                                </TDDashboardCard>
                            </Col>

                            <Col span={24}>
                                <TDDashboardCard>
                                    <ProgramTalks hideAction={'all'} />
                                </TDDashboardCard>
                            </Col>
                        </Row>
                    </Col>

                    <Col lg={8} sm={12} xs={12} md={12}>
                        <TDDashboardCard highlight>
                            <EventDetails eventId={eventId} />
                        </TDDashboardCard>
                    </Col>

                    <Col lg={8} sm={12} xs={12} md={12}>
                        <TDDashboardCard>
                            <TalkTopTen hideAction={'all'} />
                        </TDDashboardCard>
                    </Col>
                </Row>
            )}
        </>
    )
}
