import React from 'react'
import { Form, Input, Select } from 'antd'

import { WrapperConfig } from '..'
import { usePageBuilderContext } from '../../context/context'
import { nanoid } from 'nanoid'
import { NAVTITLES } from '../../constants/labels'

const redirectModes = [
    { type: 'page', title: 'Página interna' },
    { type: 'url', title: 'Url' },
    { type: 'formModal', title: 'Abrir Formulário de inscrição' },
    { type: 'streaming', title: 'Página de transmissão' }
]

const RedirectOptions = (props) => {
    const {
        fieldPath = [],
        excludeModes = [],
        initialValue = { mode: 'page' }
    } = props
    const { site } = usePageBuilderContext()
    let path = []

    if (props.path) {
        path = Array.isArray(props.path) ? props.path : [props.path]
    }
    return (
        <WrapperConfig title="Redirecionamento">
            <Form.Item
                name={[...fieldPath, 'redirect', 'mode']}
                label="Modo de redirecionamento"
                initialValue={initialValue.mode}
            >
                <Select>
                    {redirectModes
                        .filter((mode) => !excludeModes.includes(mode.type))
                        .map((mode) => (
                            <Select.Option value={mode.type} key={mode.type}>
                                {mode.title}
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue, setFields }) => {
                    const redirectMode = getFieldValue([
                        ...(path || []),
                        ...fieldPath,
                        'redirect',
                        'mode'
                    ])

                    return redirectMode === 'page' ? (
                        <Form.Item
                            name={[...fieldPath, 'redirect', 'page']}
                            label="Página"
                            initialValue={initialValue.page}
                        >
                            <Select
                                onChange={() => {
                                    setFields([
                                        {
                                            name: [
                                                ...(path || []),
                                                ...fieldPath,
                                                'redirect',
                                                'section'
                                            ],
                                            value: undefined,
                                            touched: false
                                        }
                                    ])
                                }}
                            >
                                {Object.keys(site.pages)
                                    .filter((page) => {
                                        return !site.pages[page].model?.name
                                    })
                                    .map((page) => (
                                        <Option
                                            value={page}
                                            key={page + nanoid()}
                                        >
                                            {page in NAVTITLES
                                                ? NAVTITLES[page]
                                                : site.pages[page]?.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    ) : redirectMode === 'url' ? (
                        <Form.Item
                            name={[...fieldPath, 'redirect', 'url']}
                            label="Url"
                            initialValue={initialValue.url}
                        >
                            <Input />
                        </Form.Item>
                    ) : null
                }}
            </Form.Item>

            <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => {
                    const page = getFieldValue([
                        ...(path || []),
                        ...fieldPath,
                        'redirect',
                        'page'
                    ])

                    const mode = getFieldValue([
                        ...(path || []),
                        ...fieldPath,
                        'redirect',
                        'mode'
                    ])

                    const _section = getFieldValue([
                        ...(path || []),
                        ...fieldPath,
                        'redirect',
                        'section'
                    ])

                    return page &&
                        mode === 'page' &&
                        site.pages[page]?.sections.length ? (
                        <Form.Item
                            name={[...fieldPath, 'redirect', 'section']}
                            label="Seção"
                            initialValue={null}
                        >
                            <Select>
                                <Select.Option value={null} key={'nenhum'}>
                                    Topo da página
                                </Select.Option>
                                {site.pages[page].sections
                                    .filter((section) => section.active)
                                    .map((section) => (
                                        <Select.Option
                                            value={section.configs.id}
                                            key={section.configs.id}
                                        >
                                            {section.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    ) : null
                }}
            </Form.Item>
        </WrapperConfig>
    )
}

export default RedirectOptions
