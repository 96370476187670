import React from 'react'

import Card from '@cms/core/components/Card'
import { InputTranslation } from '@cms/core/components/InputTranslation'
import { Form } from 'antd'

const BaseConfig = (props) => {
    const { children, title, description } = props
    return (
        <>
            <Card title="Geral">
                <>
                    {title && (
                        <Form.Item
                            name={[props.name, 'config', 'title']}
                            label="Título"
                        >
                            <InputTranslation />
                        </Form.Item>
                    )}

                    {description && (
                        <Form.Item
                            name={[props.name, 'config', 'description']}
                            label="Descrição"
                        >
                            <InputTranslation mode="textArea" />
                        </Form.Item>
                    )}
                </>
                {children}
            </Card>
        </>
    )
}

export default BaseConfig
