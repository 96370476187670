import React from 'react'
import { Footer, Button, Wrapper } from './styles'

import { CheckCircleFilled } from '@ant-design/icons'

import { usePageBuilderContext } from '@cms/events/pages/Config/PageBuilder/context/context'
import Affix from '@cms/core/components/Affix'

const PanelFooter = ({ setSection, customPage = false }) => {
    const { loading, formSubmit$ } = usePageBuilderContext()
    const handledSetSection = () => {
        if (setSection) setSection(null)
    }

    formSubmit$.useSubscription(() => {
        handledSetSection()
    })

    return (
        <Wrapper>
            <Affix offsetBottom={0}>
                <Footer
                    className="affix-form-footer"
                    justify={customPage ? 'end' : 'space-between'}
                >
                    {!customPage && (
                        <Button type="ghost" onClick={handledSetSection}>
                            Fechar
                        </Button>
                    )}
                    <Button
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        icon={<CheckCircleFilled />}
                    >
                        Salvar
                    </Button>
                </Footer>
            </Affix>
        </Wrapper>
    )
}

export default PanelFooter

