import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'antd'
import { useMutation } from '@apollo/client'

import { WidgetsInternal } from './routes/widgets'
import { Nav } from './components'
import { useWidgetContext } from './context/widgets'

const WidgetsPage = () => {
    const { widgets, setWidgets, setModalVisible } = useWidgetContext()
    const { eventId } = useParams()

    return (
        <>
            <Nav />
            <WidgetsInternal />
        </>
    )
}

export default WidgetsPage
