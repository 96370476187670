import React from 'react'
import { Form, Row, Col, Input, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import Card from '@cms/core/components/Card'
import SelectSearch from '@cms/core/components/SelectSearch'

const BodyValues = ({
    formName,
    nameOptions,
    isLoading,
    removeActionsIn = []
}) => {
    return (
        <Col span={24}>
            <Card title="Valores">
                <Form.List name={formName}>
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={`body.${index}`}>
                                    <Row
                                        className="actionsBox"
                                        gutter={24}
                                        align="middle"
                                    >
                                        <Col span={8}>
                                            <Form.Item
                                                label="Nome"
                                                name={[field.name, 'name']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Nome é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <SelectSearch
                                                    mode="single"
                                                    options={nameOptions}
                                                    loading={isLoading}
                                                    disabled={removeActionsIn.includes(
                                                        field.name
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                {...field}
                                                label="Valor"
                                                name={[field.name, 'value']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Valor é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <Input
                                                    disabled={removeActionsIn.includes(
                                                        field.name
                                                    )}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            {!removeActionsIn.includes(
                                                field.name
                                            ) && (
                                                <MinusCircleOutlined
                                                    style={{
                                                        marginTop: 20
                                                    }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({})
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Card>
        </Col>
    )
}

export default BodyValues

