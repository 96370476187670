import { makeVar } from '@apollo/client'

export const CurrentUser = makeVar(null)

export const TOKEN_KEY = '@td-token'

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null

export const getToken = () => localStorage.getItem(TOKEN_KEY)

export const login = (data) => {
    CurrentUser(data.user)
    localStorage.setItem(TOKEN_KEY, data.accessToken)
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY)
}

