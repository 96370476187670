import React from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input, Button, Select, Divider, Tag } from 'antd'
import { useQuery } from '@apollo/client'

import {
    GET_BRAND_LIST,
    GET_BRAND_TAGS
} from '@cms/events/graphql/queries/brand'
import { useCrudSelect } from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'

import { ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'

const Owner = (props) => {
    const { eventId } = useParams()

    const queryBrands = useQuery(GET_BRAND_LIST, {
        variables: { first: 9999, eventId }
    })

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const [brandsOptions, brandsSelectOptions] = useCrudSelect(queryBrands, {
        queryName: 'brands'
    })

    return (
        <>
            <ContainerConfig {...props} />{' '}
            <Wrapper>
                <Title level={4}>Regras de exibição</Title>
                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Exibir apenas realizadores com as tags:"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.brandTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                {/* <Title level={4}>Realizadores</Title> */}
                <Form.Item
                    label="Realizadores"
                    name={[props.name, 'configs', 'brands']}
                >
                    <SelectSearch {...brandsSelectOptions}>
                        {brandsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Box</Title>
                <Form.Item
                    name={[props.name, 'configs', 'box', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'box', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
                <Divider orientation="left">Logo</Divider>
                <Form.Item
                    name={[props.name, 'configs', 'box', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
                <Divider orientation="left">Título</Divider>
                <Form.Item
                    name={[props.name, 'configs', 'box', 'title', 'text']}
                    label="Texto"
                >
                    <Input.TextArea />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Owner
