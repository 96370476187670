import styled from 'styled-components'

import { Col, Typography } from 'antd'
import { transparentize } from 'polished'

const { Title } = Typography

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

export const StyledCol = styled.div`
    height: 70px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`

export const MediaIcon = styled.span`
    margin-right: 10px;
    border-radius: 100%;

    .icon {
        border-radius: 100% !important;
    }
`

export const Name = styled(Title).attrs(({ level }) => ({
    level: level || 5
}))`
    &.ant-typography {
        color: '#fff';

        margin: 0;
    }
`

export const Percent = styled.span`
    font-size: 0.9em;

    font-weight: bold;
`

export const Quantity = styled.div`
    height: 30px;
    margin-top: 12px;
    padding: 3px 15px;
    background-color: ${({ theme }) => transparentize(0.9, theme.color.primaryColor)};
    border-radius: 40px;
    font-weight: bold;
    text-align: center;
    font-size: 1em;
`