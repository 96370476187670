import React from 'react'

import { Typography, Row, Col } from 'antd'

import { GET_BRAND_BY_SOCIAL } from '@cms/events/graphql/queries/brand'

import { useQuery } from '@apollo/client'

import { EnvironmentOutlined } from '@ant-design/icons'

import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../../styles'

import SocialMetric from './SocialMetric'

const { Text } = Typography

const SocialNetworks = ({ eventId, brandId }) => {
    const { data, loading, error } = useQuery(GET_BRAND_BY_SOCIAL, {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    const getInfoByName = (name) =>
        data.brandBySocial.find(
            (b) => b.name.toUpperCase() === name.toUpperCase()
        )

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <EnvironmentOutlined size={60} style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Cliques por Rede Social</DashboardTitle>
            </DashboardCardTitle>
            <Row gutter={24}>
                <Col sm={24}>
                    <SocialMetric
                        nameSocial="Facebook"
                        eventId={eventId}
                        {...getInfoByName('Facebook')}
                    />
                    <SocialMetric
                        nameSocial="Instagram"
                        eventId={eventId}
                        {...getInfoByName('Instagram')}
                    />
                    <SocialMetric
                        nameSocial="Whatsapp"
                        eventId={eventId}
                        {...getInfoByName('Whatsapp')}
                    />
                    <SocialMetric
                        nameSocial="LinkedIn"
                        eventId={eventId}
                        {...getInfoByName('LinkedIn')}
                    />
                    <SocialMetric
                        nameSocial="Link"
                        eventId={eventId}
                        {...getInfoByName('Link')}
                    />
                </Col>
            </Row>
        </>
    )
}

export default SocialNetworks
