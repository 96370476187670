import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_FAQ_TOPIC = gql`
    mutation CreateTopic(
        $event: ObjectID!
        $name: String!
        $description: String
    ) {
        createFaqTopic(
            input: {
                event: $event
                name: $name
                description: $description
            }
        ) {
            ...FaqTopicData
        }
    }

    ${fragments.FaqTopicData}
`

export const UPDATE_FAQ_TOPIC = gql`
    mutation UpdateFaqTopic(
        $id: ObjectID!
        $event: ObjectID!
        $name: String!
        $description: String
    ) {
        updateFaqTopic(
            id: $id
            input: {
                event: $event
                name: $name
                description: $description
            }
        ) {
            ...FaqTopicData
        }
    }

    ${fragments.FaqTopicData}
`

export const DELETE_FAQ_TOPIC = gql`
    mutation DeleteFaqTopic($id: ObjectID!) {
        deleteFaqTopic(id: $id) {
            id
            success
        }
    }
`
