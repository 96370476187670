import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'
import * as fragments from '@cms/events/graphql/fragments'

import { CrudList, CrudColumns } from '@cms/core/components/Crud'
import { DashboardCard } from '@cms/events/components/Dashboard'

import { Row, Typography } from 'antd'
import { CrownOutlined } from '@ant-design/icons'
import { IconWrapper } from '../styles'

const RankingTalkList = function (props) {
    const { Text } = Typography
    const { eventId, id: category } = useParams()

    const query = useQuery(
        gql`
            query (
                $first: Int
                $afterCursor: Cursor
                $eventId: ObjectID
                $category: ObjectID
            ) {
                talks(
                    first: $first
                    after: $afterCursor
                    filter: { event: $eventId, category: $category }
                    orderBy: { reports: { views: DESC } }
                ) {
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                    edges {
                        node {
                            ...TalkData
                        }
                    }
                }
            }

            ${fragments.TalkData}
        `,
        {
            variables: {
                eventId,
                category
            }
        }
    )

    const columns = [
        {
            title: 'Palestras',
            dataIndex: [''],
            render: (_value, obj, index) => {
                const title = obj.node.title || '---'
                const views = obj.node?.reports?.views || '---'
                return (
                    <Link
                        title="clique para visualizar o dashboard"
                        to={`/evento/${eventId}/palestras/${obj.node.id}/dashboard`}
                    >
                        <CrudColumns
                            counterSize={25}
                            counter={index + 1}
                            title={title}
                            text={[`${obj.node.title} - 👁️ ${views} views`]}
                        />
                    </Link>
                )
            }
        }
    ]

    return (
        <DashboardCard>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <CrownOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>
                    Rank - Palestras desta categoria
                </Text>
            </Row>
            <div className="minimal-padding">
                <CrudList
                    header={{}}
                    columns={columns}
                    queryName={'talks'}
                    hideAction={'all'}
                    showHeader={false}
                    search={false}
                    {...query}
                />
            </div>
        </DashboardCard>
    )
}

export default RankingTalkList
