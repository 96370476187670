import React, { useCallback, useEffect, useRef } from 'react'
import { Form, Select, Typography, Row, Col } from 'antd'
import WebFont from 'webfontloader'

// import Colors from './Colors'
import Colors from './Colors'
import FontSelector from './FontSelector'
import { Uploader, PanelFooter, Preview } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { googleFonts } from '../../constants/fonts'
import { ColForm, Wrapper } from './styles'

import { COLORS } from '../../constants/labels'
import Affix from '@cms/core/components/Affix'

const { Option } = Select
const { Title } = Typography

const General = () => {
    const [form] = Form.useForm()
    const { site, colors } = usePageBuilderContext()
    const colRef = useRef(null)
    const previewRef = useRef(null)

    WebFont.load({
        classes: false,
        events: false,
        google: {
            families: googleFonts.map((font) => font)
        }
    })

    const updateSection = () => {
        const data = form.getFieldsValue()
        const _data = {
            ...site,
            theme: { ...data }
            // sections: site.pages.home.sections
        }

        if (_data && previewRef.current) {
            previewRef.current.Emit('fullPage', _data)
        }
    }

    useEffect(() => {
        form.setFieldsValue(site.theme)
    }, [site])

    if (!site) return null

    const onInit = () => {
        previewRef.current.Emit('fullPage', {
            ...site,
            sections: site.pages.home.sections
        })
        previewRef.current.handleParentWidth(colRef.current.offsetWidth)
        previewRef.current.handleParentHeight(600)
    }

    return (
        <Row gutter={24}>
            <ColForm md={12} sm={24}>
                <Form
                    name="theme"
                    form={form}
                    onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Form.Item label="Box" name={['box', 'type']}>
                        <Select>
                            <Option value="circle">Redondo</Option>
                            <Option value="rounded">Arredondado</Option>
                            <Option value="default">Quadrado</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Botão" name={['button', 'type']}>
                        <Select>
                            <Option value="circle">Redondo</Option>
                            <Option value="rounded">Arredondado</Option>
                            <Option value="default">Quadrado</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Fontes" name={['font']}>
                        <FontSelector />
                    </Form.Item>

                    <Title className="list-label" level={4}>
                        Cores
                    </Title>
                    {Object.entries(colors).map(([key, color]) => {
                        return (
                            <Wrapper key={key}>
                                <Form.Item
                                    name={['colors', key]}
                                    label={COLORS[key]}
                                    key={key}
                                >
                                    <Colors />
                                </Form.Item>
                            </Wrapper>
                        )
                    })}
                    <PanelFooter />
                </Form>
            </ColForm>
            <Col md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <div>
                        <Preview
                            ref={previewRef}
                            onInit={onInit}
                            page="checkout"
                        />
                    </div>
                </Affix>
            </Col>
        </Row>
    )
}

export default General
