import styled from 'styled-components'
import { Row } from 'antd'
import { Link } from 'react-router-dom'
import { COLORS } from '@cms/core/themes'

export const LinkStyled = styled(Link)`
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
    padding: 8px 15px;

    &:hover {
        color: ${({ theme }) => theme.color.activeColors};
    }
`
export const HrefStyled = styled.a`
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
    padding: 8px 15px;

    &:hover {
        color: ${({ theme }) => theme.color.activeColors};
    }
`

export const Navigation = styled(Row)`
    background-color: ${COLORS.bgLight};
    width: fit-content;
    border-radius: 84px;
    height: 38px;
    align-items: center;

    a {
        padding: 12px 20px;
        font-weight: 600;
        border-radius: 84px;
        &.active-nav {
            background-color: ${COLORS.active};
            color: ${COLORS.text};
        }
    }
`

export const Wrapper = styled.div`
    display: contents;
    margin-bottom: 20px;

    .header {
        margin-bottom: 20px;
    }

    .ant-affix {
        > .header {
            margin-left: -40px;
            margin-right: -40px;
            padding: 10px 40px;
            min-height: 58px;
            background: ${({ theme }) => theme.color.backgroundDarkHeaders};
            backdrop-filter: blur(10px);
            /* border-bottom: 1px solid ${({ theme }) =>
                theme.color.textColor}; */
            box-shadow: 1px 0px 3px #ffffff57;
        }

        ${Navigation} {
        }
    }
`
