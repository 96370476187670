import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import { GET_BRAND_PLAN_LIST_ORDENABLE } from '@cms/events/graphql/queries/brandPlan'
import { DELETE_BRAND_PLAN } from '@cms/events/graphql/mutations/brandPlan'

import BrandPlanModal from './BrandPlansModal'
import { removeFromList } from '@cms/core/graphql/utils'

import dayjs from 'dayjs'

const path = '/principal/patrocinador/planos'

const ModalRoutes = CreateModalRoutes(BrandPlanModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name'
    },
    {
        title: 'Tipo',
        dataIndex: ['node', 'type']
    },
    {
        title: 'Prioridade',
        dataIndex: ['node', 'priority'],
        sorter: 'priority'
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const BrandPlanList = () => {
    const query = useQuery(GET_BRAND_PLAN_LIST_ORDENABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_BRAND_PLAN, {
        update: removeFromList({ list: 'brandPlans', Type: 'BrandPlan' })
    })

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [{ name: 'name', label: 'Nome', queryType: 'Filter' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Planos de Patrocinador',
                    subTitle: 'Adicione e gerencie planos de patrocinador',
                    buttons: [
                        {
                            children: 'Novo Plano',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'brandPlans'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default BrandPlanList

