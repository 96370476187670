import styled from 'styled-components'
import { Row, Button as AntButton } from 'antd'
import { COLORS } from '@cms/core/themes'

export const Footer = styled(Row)`
    padding: 10px 20px;
    background: ${COLORS.textDark};
    border-top: 1px solid ${COLORS.textLight};
    border-radius: 0px 0px 10px 10px;
`

export const Button = styled(AntButton)``

export const Wrapper = styled.div`
    display: block;
    width: 100%;

    .section-form-footer {
        background: #464654;

        .ant-btn-background-ghost {
            color: ${COLORS.text};
        }
    }
`

