import React from 'react'
import { Form, Input } from 'antd'

import { ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'

const Payment = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'title', 'text']}
                    label="Texto"
                >
                    <Input type={'text'} />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Payment
