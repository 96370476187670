import { gql } from '@apollo/client'
import { WatchCategoryData } from '../fragments'

export const CREATE_WATCH_CATEGORY = gql`
    mutation CreateWatchCategory($input: WatchCategoryInputs!) {
        createWatchCategory(input: $input) {
            ...WatchCategoryData
        }
    }

    ${WatchCategoryData}
`

export const UPDATE_WATCH_CATEGORY = gql`
    mutation UpdateWatchCategory($id: ObjectID!, $input: WatchCategoryInputs!) {
        updateWatchCategory(id: $id, input: $input) {
            ...WatchCategoryData
        }
    }

    ${WatchCategoryData}
`

export const DELETE_WATCH_CATEGORY = gql`
    mutation DeleteWatchCategory($id: ObjectID!) {
        deleteWatchCategory(id: $id) {
            id
            success
        }
    }
`
