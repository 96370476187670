import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import SponsorTicker from './SponsorTicker/SponsorTicker'

export const Ticker = {
    SponsorTicker: {
        Component: SponsorTicker,
        name: 'Ticker de patrocinadores',
        category: 'Ticker de patrocinadores',
        thumb: require('./SponsorTicker/SponsorTicker.png'),
        columns: '1',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'POWERED BY',
                    textColor: getColorContrast(colors, 'primary')
                },
                logo: {
                    color: 'black'
                },
                arrows: {
                    color: 'black'
                }
            }
        }
    }
}
