import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import {
    CREATE_CAMPAIGN,
    DELETE_CAMPAIGN,
    UPDATE_CAMPAIGN
} from '@cms/events/graphql/mutations/engagement'

import { Layout } from 'antd'
import { removeObjectProps } from '@cms/events/utils'

import Loader from '@cms/core/components/Loader'
import CampaingEditScreen from '@cms/events/components/Campaings/EditScreen'
import { CampaingPage } from '@cms/events/components/Campaings/styles'

const blankCampaign = {
    triggers: []
}

export default function EngagementEditPage() {
    const { eventId } = useParams()
    const [campaingDataClean, setCampaingDataClean] = useState(null)
    const [saveCampaign] = useMutation(CREATE_CAMPAIGN)
    const [updateEvent] = useMutation(UPDATE_EVENT)
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN)

    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
        refetchQueries: [{ query: GET_EVENT, variables: { id: eventId } }]
    })

    const {
        error: eventError,
        data: eventData,
        refetch
    } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    useEffect(() => {
        if (!eventData || !eventData.event.engagementCampaign) return
        setCampaingDataClean(
            removeObjectProps(eventData.event.engagementCampaign, [
                '__typename'
            ])
        )
    }, [eventData])

    if (!eventData) return <Loader fixed={false} padding='10px' />
    if (eventError) return `Error! ${eventError.message}`

    const onUpdate = async (variables) => {
        const { data: saveData } = await updateCampaign({
            variables
        })

        setCampaingDataClean(
            removeObjectProps(saveData.updateEngagementCampaign, ['__typename'])
        )
    }

    const handleNewCampaign = async () => {
        blankCampaign.event = eventId

        const response = await saveCampaign({
            variables: blankCampaign
        })

        await updateEvent({
            variables: {
                id: eventId,
                engagementCampaign: response.data.createEngagementCampaign.id
            }
        })
        refetch()
    }

    const handleDelete = async () => {
        await deleteCampaign({
            variables: { id: eventData.event.engagementCampaign.id }
        })
        setCampaingDataClean(null)
    }

    return (
        <CampaingPage>
            <CampaingEditScreen
                type="engagement"
                data={campaingDataClean}
                onUpdate={onUpdate}
                onDelete={handleDelete}
                handleNewCampaign={handleNewCampaign}
            />
        </CampaingPage>
    )
}
