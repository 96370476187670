import React, { useEffect } from 'react'

import { Modal, Form, message } from 'antd'

import { ModalContainer } from './styles'

const { error } = message

const ModalForm = ({ children, ...props }) => {
    const { setField, field, isEdit, title, type } = props
    const [form] = Form.useForm()

    useEffect(() => {
        if (field) {
            form.setFieldsValue(field)
        }
    }, [field])

    const onFinish = async () => {
        form.validateFields()
            .then(async (values) => {
                await form.submit()
                setField(false)
            })
            .catch((info) => {
                error('Verifique os campos do formulário!')
                return false
            })
    }

    if (!field) return null

    return (
        (<Modal
            width={600}
            title={isEdit ? `Editar ${title}` : `Novo(a) ${title}`}
            open={field ? true : false}
            onOk={onFinish}
            onCancel={() => setField(false)}
        >
            <ModalContainer>
                {React.cloneElement(children, {
                    ...props,
                    form: form
                })}
            </ModalContainer>
        </Modal>)
    );
}

export default ModalForm
