import React from 'react'
import { Form, Select, Tag } from 'antd'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { GET_BRAND_TAGS } from '@cms/events/graphql/queries/brand'

import { ColorsBadges, ContainerConfig } from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'

const SponsorTicker = (props) => {
    const { eventId } = useParams()

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Filtro</Title>
                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Tags"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.brandTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Logo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default SponsorTicker
