import { darken } from 'polished'

export const DEFAULT_THEME = {
    bgColor: '#121212',
    textColor: '#fff',
    font: 'Times New Roman',
    primaryColor: '#414141',
    highlight: '#FEBE10',
    messages: {
        success: '#4BB543',
        error: '#FF0000',
        warning: '#FEBE10'
    },
    menu: {
        bgColor: '#121212',
        textColor: '#888',
        highlight: '#FEBE10'
    },
    box: {
        main: {
            bgColor: '#414141',
            textColor: '#fff',
            borderRadius: 50,
            logoColor: 'white',
            button: {
                bgColor: '#FEBE10',
                textColor: '#000',
                borderRadius: 50,
                hover: {
                    transition: 0.2,
                    bgColor: darken(0.14, '#FEBE10'),
                    textColor: '#000',
                    borderRadius: 50
                }
            }
        },
        highlight: {
            bgColor: '#FEBE10',
            textColor: '#000',
            borderRadius: 50,
            logoColor: 'black',
            button: {
                bgColor: '#414141',
                textColor: '#fff',
                borderRadius: 50,
                hover: {
                    transition: 0.2,
                    bgColor: '#fff',
                    textColor: '#414141',
                    borderRadius: 50
                }
            }
        },
        sponsor: {
            bgColor: '#FFF',
            textColor: '#FFF',
            borderRadius: 50,
            logoColor: 'default',
            button: {
                bgColor: '#414141',
                textColor: '#fff',
                borderRadius: 50,
                hover: {
                    transition: 0.2,
                    bgColor: darken(0.14, '#414141'),
                    textColor: '#fff',
                    borderRadius: 50
                }
            }
        }
    },
    login: {
        bgColor: '#121212',
        textColor: '#fff',
        button: {
            bgColor: '#FEBE10',
            textColor: '#000',
            borderRadius: 50,
            hover: {
                transition: 0.2,
                bgColor: darken(0.14, '#FEBE10'),
                textColor: '#000',
                borderRadius: 50
            }
        }
    },
    button: {
        bgColor: '#FEBE10',
        textColor: '#000',
        borderRadius: 50,
        hover: {
            transition: 0.2,
            bgColor: darken(0.14, '#FEBE10'),
            textColor: '#000',
            borderRadius: 50
        }
    }
}

