import React, { useState } from 'react'
import { PlusOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Wrapper, CustomCard } from './styles'
import { usePageBuilderContext } from '../../context/context'
import { Empty, Image } from 'antd'
import Sections from '../../sections'

const sections = Object.entries(Sections)

const SectionsList = (props) => {
    const { site, sectionsModalParams, setSectionsModalParams, event } =
        usePageBuilderContext()
    const { index, form } = sectionsModalParams || {}

    const handleAddSection = async (type, section) => {
        const { sections } = form.getFieldsValue()
        sections.splice(index, 0, {
            type,
            active: true,
            configs: await section.create(site, event)
        })
        form.setFieldsValue({ sections })
        form.submit()

        setSectionsModalParams(null)
    }

    const { filters } = props

    const searchFilter = ([_, section]) => {
        if (filters.search) {
            return section.category
                .toLowerCase()
                .includes(filters.search.toLowerCase())
        }

        return true
    }

    const categoriesFilter = ([_, section]) => {
        if (filters.categories && filters.categories.length > 0) {
            return filters.categories.find((category) => {
                return category.toLowerCase() === section.category.toLowerCase()
            })
        }

        return true
    }

    const columnsFilter = ([_, section]) => {
        if (filters.columns.length > 0 && filters.columns) {
            return filters.columns.find((column) => {
                return column.toLowerCase() === section.columns.toLowerCase()
            })
        }

        return true
    }

    const list = sections
        .filter(columnsFilter)
        .filter(categoriesFilter)
        .filter(searchFilter)
        .map(([type, section], i) => {
            if (!section.create) {
                return null
            }

            return (
                <CustomCard
                    key={i}
                    title={section.name}
                    size="small"
                    bgimg={section.thumb}
                    extra={
                        <a
                            href="#"
                            onClick={() => handleAddSection(type, section)}
                        >
                            <PlusOutlined />
                        </a>
                    }
                >
                    <Image src={section.thumb} />
                </CustomCard>
            )
        })

    return (
        <Wrapper>
            {list.length > 0 ? (
                list
            ) : (
                <Empty
                    description={
                        <span>
                            <a>Nenhuma seção encontrada com a busca atual</a>
                        </span>
                    }
                ></Empty>
            )}
        </Wrapper>
    )
}

export default SectionsList
