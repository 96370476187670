import styled from 'styled-components'
import { Typography, Row } from 'antd'
import { COLORS } from '@cms/core/themes'

const { Title: AntTitle } = Typography

export const Navigation = styled(Row)`
    background-color: ${COLORS.bgLight};
    backdrop-filter: blur(30px);
    width: fit-content;
    border-radius: 84px;
    height: 38px;
    align-items: center;
    margin-bottom: 20px;

    a {
        padding: 12px 20px;
        font-weight: 600;
        border-radius: 84px;
        &.active-nav {
            background-color: ${COLORS.active};
            color: ${COLORS.text};
        }
    }
`

export const Wrapper = styled.div`
    padding: 8px;
    border: 2px solid rgba(228, 236, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #424246;
`
export const Title = styled(AntTitle)`
    padding-left: 8px;
`

export const WrapperBtns = styled.div`
    display: block;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;

    button {
        background: rgba(228, 236, 255, 0.1);
        backdrop-filter: blur(30px);
    }
`

export const Container = styled.div`
    padding: 40px;

    form {
        input {
            background: #38383c;
        }

        .fake-label {
            margin-bottom: 8px;

            &:hover {
                ${WrapperBtns} {
                    display: block;
                    opacity: 1;
                }
            }
        }

        .ant-form-item::not('.panel-header') {
            border: 2px solid rgba(228, 236, 255, 0.1);
            border-radius: 20px;
            padding: 16px;
        }

        .panel-header {
            margin-bottom: 0px;
        }
    }

    .ant-collapse {
        margin-bottom: 20px;

        .ant-collapse-header {
            /* background-color: #424246; */
        }
        .ant-collapse-content {
            /* background-color: rgba(255, 255, 255, 0.2); */
        }
    }
    .ant-collapse-content-box {
        background-color: rgba(255, 255, 255, 0.1);
        ${Wrapper} {
            background-color: transparent;
        }
    }

    .ant-divider {
        &::after {
            border-color: ${({ theme }) => theme.color.backgroundDarkLight};
        }
    }

    .ant-form-large .ant-form-item-label > label {
        height: 40px;
    }

    .ant-form-item {
        label {
            width: 100%;
        }

        &:hover {
            ${WrapperBtns} {
                opacity: 1;
            }
        }
    }
`
