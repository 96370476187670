import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_TALK_TOTAL_COUNT } from '@cms/events/graphql/queries/blocks/totals'

import Card from '../CardInner'

const TotalTalks = ({ eventId, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_TALK_TOTAL_COUNT, {
        variables: { eventId }
    })

    if (loading || error) return null

    return (
        <>
            <Card
                title={'Palestras'}
                reverse={reverse}
                data={data.dashboardTalksCount || 0}
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalTalks
