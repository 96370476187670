import React, { useEffect, useRef } from 'react'
import { Form, Row, Col } from 'antd'
import { throttle } from 'lodash'

import { ModalForm } from '../../components'

import { useFormBuilderContext } from '../../context/context'
import FieldsForm from './components/FieldsForm'

import Preview from './components/Preview'
import AddFields from './components/AddFields'

const FieldsPage = ({ type = 'register' }) => {
    const { formData, selectField, setSelectField } = useFormBuilderContext()

    const [form] = Form.useForm()

    const handleValidateFieldName = (value, isEdit) => {
        const { fields } = form.getFieldValue()
        const found = fields.find((field) => field.name === value)

        if (!found || isEdit) {
            return true
        }
        return false
    }

    const debounceChange = useRef(
        throttle(handleValidateFieldName, 500)
    ).current

    useEffect(() => {
        form.setFieldsValue(formData)
    }, [formData])

    if (!formData) return null

    return (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <AddFields />
                </Col>
                <Col span={12}>
                    <Preview />
                </Col>
            </Row>
            {selectField?.field && (
                <ModalForm
                    field={selectField.field}
                    setField={setSelectField}
                    isEdit={selectField.isEdit}
                    debounceChange={debounceChange}
                    type={'fields'}
                    title={'campo'}
                >
                    <FieldsForm />
                </ModalForm>
            )}
        </>
    )
}

export default FieldsPage
