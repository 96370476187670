import React, { useState } from 'react'

import { message, Space } from 'antd'
import _get from 'lodash/get'
import { useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { CrudColumns, CrudList } from '@cms/core/components/Crud'

import { GET_INTEGRATION_LOGS } from '@cms/events/graphql/queries/integrationLogs'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { StatusBadge } from './styles'
import IntegrationLogModal from './Modal'
import Button from '@cms/events/components/Buttons/ButtonDefault'
import { RETRY_INTEGRATION } from '@cms/events/graphql/mutations/integrationLogs'

dayjs.extend(relativeTime)

const IntegrationLogs = () => {
    const { eventId } = useParams()

    const [selectedIntegrationLog, setSelectedIntegrationLog] = useState('')

    const query = useQuery(GET_INTEGRATION_LOGS, {
        variables: {
            first: 25,
            eventId,
            orderBy: { updatedAt: 'DESC' }
        }
    })

    const [retryIntegration] = useMutation(RETRY_INTEGRATION)

    const handleRetryIntegration = async (id) => {
        await retryIntegration({ variables: { id } }), query.refetch()
        message.success('Integração reenviada com sucesso!')
    }

    const columns = [
        {
            title: 'Nome da Integração',
            dataIndex: [''],
            width: 200,
            fixed: true,
            render: (value, obj) => {
                return (
                    <CrudColumns
                        onlyText
                        title={'Nome da Integração'}
                        text={[obj.node.integration?.name]}
                    />
                )
            }
        },
        {
            title: 'Tipo',
            dataIndex: [''],
            width: 200,
            render: (value, obj) => {
                return (
                    <CrudColumns
                        onlyText
                        title={'Tipo'}
                        text={[obj.node.integration?.type]}
                    />
                )
            }
        },
        {
            title: 'Status',
            dataIndex: ['node', 'status'],
            width: 140,
            sorter: 'status',
            render: (value, obj) => {
                return (
                    <CrudColumns
                        onlyText
                        title={'Status'}
                        text={[
                            <StatusBadge status={obj.node.status}>
                                {obj.node.status === 'SUCCESS'
                                    ? 'Sucesso'
                                    : 'Erro'}
                            </StatusBadge>
                        ]}
                    />
                )
            }
        },
        {
            title: 'Registrado',
            dataIndex: [''],
            width: 140,
            sorter: 'updatedAt',
            render: (value, obj) => {
                return (
                    <CrudColumns
                        onlyText
                        title={'Registrado'}
                        text={[dayjs(obj.node.updatedAt).fromNow()]}
                    />
                )
            }
        }
    ]

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 240,
        fixed: 'right',
        align: 'right',
        render: (_, record) => {
            const id = record.node.id

            return (
                <Space style={{ paddingRight: '10px' }}>
                    <Button
                        key={id + 'details'}
                        type="primary"
                        onClick={() => setSelectedIntegrationLog(record.node)}
                    >
                        Detalhes
                    </Button>
                    {record.node.status === 'FAIL' && (
                        <Button
                            key={id + 'retry'}
                            type="primary"
                            onClick={() =>
                                handleRetryIntegration(record.node.id)
                            }
                        >
                            Tentar novamente
                        </Button>
                    )}
                </Space>
            )
        }
    }

    return (
        <>
            <IntegrationLogModal
                integrationLog={selectedIntegrationLog}
                onCancel={() => setSelectedIntegrationLog(undefined)}
            />
            <CrudList
                header={{
                    title: 'Logs',
                    countNames: {
                        singular: 'Log',
                        plural: 'Logs'
                    }
                }}
                style={{
                    height: '15vh'
                }}
                columns={columns}
                queryName={'integrationLogs'}
                hideAction={'all'}
                search={false}
                showHeader
                actionsExtra={actionsExtra}
                scrollY={'100%'}
                {...query}
            />
        </>
    )
}

export default IntegrationLogs
