import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'

export const SponsorModules: IAppModule[] = [
    {
        name: 'Meus Eventos',
        icon: sidebarIcons.dashbord,
        module: 'events.main.events',
        type: 'main',
        children: [
            {
                name: 'Meus Eventos',
                exact: true,
                path: '/principal',
                module: 'events.main.events.list'
            }
        ]
    }
]
