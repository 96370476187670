import React, { createContext, useContext } from 'react'

interface Props {
    sections: Record<string, React.ComponentType<any>>
    children: React.ReactNode
}

const SectionBuilderContext = createContext({
    sections: {}
})

export const useSectionBuilder = () => useContext(SectionBuilderContext)

export const SectionBuilderProvider = ({ sections, children }: Props) => {
    return (
        <SectionBuilderContext.Provider value={{ sections }}>
            {children}
        </SectionBuilderContext.Provider>
    )
}

