import { gql } from '@apollo/client'
import { WatchPlanData } from '../fragments'

export const CREATE_WATCH_PLAN = gql`
    mutation CreateWatchPlan(
        $name: String
        $permissions: WatchPlanPermissionsInput!
    ) {
        createWatchPlan(
            input: {
                name: $name,
                permissions: $permissions 
            }
        ) {
            ...WatchPlanData
        }
    }
    ${WatchPlanData}
`

export const UPDATE_WATCH_PLAN = gql`
    mutation UpdateWatchPlan(
        $id: ObjectID!
        $name: String
        $permissions: WatchPlanPermissionsInput!
    ) {
        updateWatchPlan(
            id: $id
            input: {
                name: $name,
                permissions: $permissions 
            }
        ) {
            ...WatchPlanData
        }
    }
    ${WatchPlanData}
`

export const DELETE_WATCH_PLAN = gql`
    mutation DeleteWatchPlan($id: ObjectID!) {
        deleteWatchPlan(id: $id) {
            id
            success
        }
    }
`
