import { gql } from '@apollo/client'
import { WatchCategoryData } from '../fragments'

export const GET_WATCH_CATEGORIES_LIST_ORDENABLE = gql`
    query GetWatchCategories(
        $first: Int
        $afterCursor: Cursor
        $orderBy: WatchCategoryOrderBy
        $search: String
        $filter: WatchCategoryFilters
    ) {
        watchCategories(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: $filter
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...WatchCategoryData
                }
            }
        }
    }

    ${WatchCategoryData}
`

export const GET_WATCH_CATEGORY = gql`
    query GetWatchCategory($id: ObjectID!) {
        watchCategory(id: $id) {
            ...WatchCategoryData
        }
    }

    ${WatchCategoryData}
`

export const GET_WATCH_CATEGORIES_SELECT_LIST = gql`
    query GetCategoriesSelectList($orderBy: WatchCategoryOrderBy) {
        watchCategories(orderBy: $orderBy) {
            edges {
                node {
                    value: id
                    label: name
                }
            }
        }
    }
`
