import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_CATEGORIES_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchCategory'

import { DELETE_WATCH_CATEGORY } from '@cms/watch/graphql/mutations/watchCategory'
import { removeFromList } from '@cms/watch/graphql/utils'

import CategoriesModal from './CategoriesModal'

import dayjs from 'dayjs'

const path = `/conteudo/categorias`
const ModalRoutes = CreateModalRoutes(CategoriesModal, path, {})

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchCategories = () => {
    const query = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: {
            filter: {
                parent: null
            },
            first: 25,
            orderBy: {
                createdAt: 'DESC'
            }
        }
    })

    const [deleteData] = useMutation(DELETE_WATCH_CATEGORY, {
        update: removeFromList({
            list: 'watchCategories',
            Type: 'WatchCategory'
        })
    })

    if (query.error) return <div>Error</div>

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Categorias',
                    subTitle: 'Adicione e gerencie categorias',
                    buttons: [
                        {
                            children: 'Nova Categoria',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'watchCategories'}
                onDelete={deleteData}
                childQuery={GET_WATCH_CATEGORIES_LIST_ORDENABLE}
                childQueryShouldShow={(node) => node.children.totalCount > 0}
                {...query}
            />
        </>
    )
}

export default WatchCategories

