const getMediaUrl = (media) => {
    if (media.url) {
        return media.url
    }
    return media
}

const template = (data) => {
    let header = ''

    if (data.sizeMedia === 'logo' && data.media) {
        header = `<td align="left" valign="top" style="padding: 16px;font-size: 14px; color: rgb(161,161,161); text-align: left;line-height: 32px; vertical-align: bottom;">
            <img src=${getMediaUrl(
                data.media
            )} width="" height="" style="margin:0; padding:0; border:none; display:block;max-width: 156px;" border="0" alt=""/>
        </td>`
    } else if (data.sizeMedia === 'imagem' && data.media) {
        header = `<td align="center" valign="top" style="font-size: 14px; color: rgb(161,161,161); text-align: left;line-height: 32px; vertical-align: bottom;">
            <img src=${getMediaUrl(
                data.media
            )} width="100%" height="" style="margin:0; padding:0; border:none; display:block;" border="0" alt="" />
        </td>`
    }

    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">

    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <title>Your Message Subject or Title</title>
        <style type="text/css">
            #outlook a {padding:0;}
            body{width:100% !important; -webkit-text-size-adjust:100%; -ms-text-size-adjust:100%; margin:0; padding:0;}
            .ExternalClass {width:100%;}
            .ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div {line-height: 100%;}
            #backgroundTable {margin:0; padding:0; width:100% !important; line-height: 100% !important;}
            img {outline:none; text-decoration:none; -ms-interpolation-mode: bicubic;}
            a img {border:none;}
            .image_fix {display:block;}
            p {margin: 1em 0;}
            h1, h2, h3, h4, h5, h6 {color: rgba(0,0,0,.87)!important;}
            h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {color: blue !important;}
            h1 a:active, h2 a:active,  h3 a:active, h4 a:active, h5 a:active, h6 a:active {
                color: red !important;
             }
            h1 a:visited, h2 a:visited,  h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {
                color: purple !important;
            }
            table td {border-collapse: collapse;}
            table { border-collapse:collapse; mso-table-lspace:0pt; mso-table-rspace:0pt; font-family: sans-serif; }
            @media only screen and (max-device-width: 480px) {
                a[href^="tel"], a[href^="sms"] {
                            text-decoration: none;
                            color: black; /* or whatever your want */
                            pointer-events: none;
                            cursor: default;
                        }
                .mobile_link a[href^="tel"], .mobile_link a[href^="sms"] {
                            text-decoration: default;
                            color: orange !important; /* or whatever your want */
                            pointer-events: auto;
                            cursor: default;
                        }
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                a[href^="tel"], a[href^="sms"] {
                            text-decoration: none;
                            color: blue;
                            pointer-events: none;
                            cursor: default;
                        }
                .mobile_link a[href^="tel"], .mobile_link a[href^="sms"] {
                            text-decoration: default;
                            color: orange !important;
                            pointer-events: auto;
                            cursor: default;
                        }
            }
            @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                /* Put your iPhone 4g styles in here */
            }
            @media only screen and (-webkit-device-pixel-ratio:.75){
                /* Put CSS for low density (ldpi) Android layouts in here */
            }
            @media only screen and (-webkit-device-pixel-ratio:1){
                /* Put CSS for medium density (mdpi) Android layouts in here */
            }
            @media only screen and (-webkit-device-pixel-ratio:1.5){
                /* Put CSS for high density (hdpi) Android layouts in here */
            }
        </style>
        <!--[if IEMobile 7]>
        <style type="text/css">
            /* Targeting Windows Mobile */
        </style>
        <![endif]-->
        <!--[if gte mso 9]>
        <style>
            /* Target Outlook 2007 and 2010 */
        </style>
        <![endif]-->
    </head>
    <body>
    <table id="backgroundTable" style="background-color:#f2f2f5 ;" width="100%" border="0" cellpadding="0" cellspacing="0" align="center" class="full" object="drag-module">
        <tbody>
            <tr>
                <td>
                    <table width=600 align="center" cellpadding="0" cellspacing="0" border="0" style="background-color:#fff ;margin-inline-start: auto; margin-inline-end: auto;">
                        <tr>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        ${header}
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <td valign="bottom" width="100%" style="padding: 0px 16px 0px 16px;text-align: left;font-size: 16px;line-height: 24px;">
                                            <h1 style="font-size: 20px;line-height: 30px;margin-top: 16px;">
                                                ${data.sayHello}
                                            <h1>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td >
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <td align="left" valign="top" style="padding: 16px;text-align: left; color: rgb(51, 51, 50); font-size: 16px; line-height: 24px;">
                                            <span style="font-size: 16px;line-height: 27px;margin-top: 16px;margin-bottom: 16px;color: #888888;">${data.content}</span>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="auto" border="0" cellpadding="0" cellspacing="0" align="center" style="float: center;">
                                    <tbody>
                                        <tr>
                                            <td align="center" valign="middle" bgcolor="${data.button.backgroundColor}" style="font-size: 18px; border-top-left-radius: 5px; border-top-right-radius: 5px; border-bottom-right-radius: 5px; border-bottom-left-radius: 5px; display: block; padding: 0px ; line-height: 22px; vertical-align: middle;width: max-content;
                                            color: ${data.button.textColor}; background-color: ${data.button.backgroundColor};">
                                                <a href=${data.button.link} style="color: ${data.button.textColor}; font-size: 16px; text-decoration: none; display: block; line-height: 24px; padding:8px 14px!important;">
                                                    <strong><span style="font-weight: 400!important;font-size: 16px;line-height: 27px;margin-top: 16px;margin-bottom: 16px;">${data.button.text}
                                                    </span></strong>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" border="0">
                                    <tr>
                                        <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td align="left" valign="top" style="padding: 16px;text-align: left; color: rgb(51, 51, 50); font-size: 16px; line-height: 24px;">
                                            <span style="font-size: 16px;line-height: 27px;margin-top: 16px;margin-bottom: 16px;color: #888888;">${data.thankYouMessage}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td height="10" style="font-size:10px; line-height:10px;">&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="column" width="100%" border="0" cellspacing="0" cellpadding="0" style="border-spacing: 0;mso-table-lspace: 0pt;mso-table-rspace: 0pt;width: 100%;vertical-align: top;">
                            <tbody>
                                <tr>
                                    <td align="left" valign="top" style="font-family: Helvetica, Arial, sans-serif;font-size: 10px;text-align: center;vertical-align: top;color: #b3b3b5;padding-bottom: 0;padding-top: 16px;">
                                        <div>

                                        </div>
                                        <div>
                                            <a href="" style="color: #888888; text-decoration: none;">unsubscribe</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    </body>
    </html>
    `
}

module.exports = template
