import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import FormOtherBuilder from '../otherFormBuilder'
import { Fields, InfosPage, Integrations } from '../../pages'

export const FormBuilderRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/others/:formId/editar/"
                component={() => <InfosPage />}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/others/:formId/editar/campos"
                component={Fields}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/others/adicionar"
                component={Fields}
            />
        </Switch>
    )
}

export const FormRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path="/evento/:eventId/forms/others/:formId/editar"
                component={FormOtherBuilder}
            />
            <PrivateRoute
                path="/evento/:eventId/forms/others/adicionar"
                component={FormOtherBuilder}
            />
        </Switch>
    )
}

export default FormBuilderRoutes
