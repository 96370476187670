import React, { useState } from 'react'
import { Switch } from 'react-router-dom'
import PrivateRoute from '@cms/core/routes/privateRoute'

import UsersReportedList from '../Chat/UsersReportedList'
import UsersReportedOverview from '../Chat/UsersReportedOverview'
import ChatNav from '../Chat/ChatNav'
import ChatDashboard from '../Chat/ChatDashboard'
import UsersBanned from '../Chat/UsersBanned'

export const ChatBuilderRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                checkModulePath
                path="/principal/chat/usuarios-denunciados"
                component={UsersReportedList}
            />
            <PrivateRoute
                exact
                path="/principal/chat/usuarios-denunciados/:id"
                component={UsersReportedOverview}
            />
            <PrivateRoute
                exact
                checkModulePath
                path="/principal/chat/usuarios-banidos"
                component={UsersBanned}
            />
            <PrivateRoute
                exact
                checkModulePath
                path="/principal/chat/palavras-banidas"
                component={ChatDashboard}
            />
        </Switch>
    )
}

export const chatRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path="/chat/usuarios-denunciados"
                component={ChatNav}
            />
        </Switch>
    )
}

export default ChatBuilderRoutes

