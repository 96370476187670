import Banners from './Banners'

export const BannersSections = {
    banners: {
        Component: Banners,
        name: 'banners',
        category: 'Banners',
        label: 'Banners',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-BR': 'Banners',
                'en-US': 'Banners',
                'es-ES': 'Banners',
                'pt-PT': 'Banners'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'pages',
                        type: '$in',
                        value: ['Home']
                    }
                ]
            }
        }
    }
}

