import styled from 'styled-components'

import { Typography } from 'antd'

const { Text } = Typography

export const TextTitle = styled(Text)`
    &.ant-typography { 
        font-size: 12px;
    }
`