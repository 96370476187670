import Galery from './Galery/Galery'
import Galery2 from './Galery2/Galery'
import Galery3 from './Galery3/Galery'
import Galery4 from './Galery4/Galery'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Galeries = {
    Galery: {
        Component: Galery,
        name: 'Galeria 3:4',
        category: 'Galerias',
        columns: '1',
        thumb: require('./Galery/galery.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Galery2: {
        Component: Galery2,
        name: 'Galeria 16:9',
        category: 'Galerias',
        columns: '1',
        thumb: require('./Galery2/galery.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Galery3: {
        Component: Galery3,
        name: 'Galeria 1:1',
        category: 'Galerias',
        columns: '1',
        thumb: require('./Galery3/galery.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Galery4: {
        Component: Galery4,
        name: 'Galeria Full Screen',
        category: 'Galerias',
        columns: '1',
        thumb: require('./Galery4/galery.png'),
        create: async (site) => {
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                }
            }
        }
    }
}
