import React from 'react'
import { Form, Select } from 'antd'

import Colors from '@cms/core/components/Colors'
import { Wrapper } from '@cms/core/components/Theme/styles'
import { ButtonTheme } from '@cms/core/components/Theme/components'
import Title from 'antd/es/typography/Title'

interface Props {
    name: string[] | string
}

const BoxTheme = ({ name = ['box'] }: Props) => {
    return (
        <>
            <Wrapper>
                <Form.Item
                    label="Cor de fundo"
                    name={[...name, 'bgColor']}
                    initialValue={'#500d6d'}
                >
                    <Colors formItem />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Form.Item
                    label="Cor de texto"
                    name={[...name, 'textColor']}
                    initialValue={'#a81be4'}
                >
                    <Colors formItem gradient={false} />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title className="list-label" level={4}>
                    Logo
                </Title>
                <Form.Item name={[...name, 'logoColor']} label="Cor do logo">
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title className="list-label" level={4}>
                    Botões dentro da caixa
                </Title>

                <ButtonTheme name={[...name, 'button']} />
            </Wrapper>
        </>
    )
}

export default BoxTheme
