import styled from 'styled-components'

import { Typography } from 'antd'

const { Title } = Typography

export const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 10px;
`

export const MetricTitle = styled.h1`
    margin-top: 10px;
`

export const BigCount = styled(Title)`
    &.ant-typography{
        font-size: 26px;
        margin-top: 0;
        margin-bottom: 22px;
        letter-spacing: -1px;
        color: #fff;

        @media(min-width: 1600px){
            font-size: 32px;
        }
    }

    &.reverse-count{
        margin-bottom: 0;
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: left;
    justify-content: center;
    height:  ${props => props.full ? '100%' : 'auto'};
    width: 100%;
    margin-left: 10px;

    h1{
        text-align: ${props => props.align ? props.align : 'left'};
        font-size: 13px;

        @media(min-width: 1600px){
            font-size: 15px;
        }
    }
`
