export const LABELS = {
    sponsors: 'Patrocinadores',
    networking: 'Networking',
    ondemand: 'On demand',
    referral: 'Indique e ganhe',
    engagement: 'Recompensas',
    certificate: 'Certificado',
    schedule: 'Agenda',
    lecturers: 'Palestrantes',
    speakers: 'Palestrantes',
    feed: 'Feed do evento',
    'event-info': 'Informações do evento',
    guestlist: 'Lista de convidados',
    favorites: 'Favoritos',
    faq: 'Central de ajuda'
}
