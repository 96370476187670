import styled from 'styled-components'

export const FormsLead = styled.div`
    padding: 8px;
    background-color: ${(props) =>
        props.bg ? props.bg : 'rgba(255, 255, 255, 0.2)'};
    width: 100%;
    border-radius: 3.2vh;
    padding: 20px;
    margin-top: 15px;

    h1.ant-typography {
        margin-bottom: 0;
        color: #ececec;
        font-weight: 500;
        font-size: 1.1em;
    }

    .ant-divider-horizontal {
        margin: 12px 0;
    }

    #ScrollDetails {
        height: 90%;
        overflow-y: scroll;
        margin-bottom: 1.5vh;
    }

    #ScrollDetails::-webkit-scrollbar {
        margin-left: 10px;
    }
`
