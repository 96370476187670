import styled from 'styled-components'
import { Button } from 'antd'

export const ButtonStyled = styled(Button)`
    width: ${props => props.maxsize === 'true' ? '100%' : 'auto'};
`
// &.ant-btn-primary{
//     color: #fff;
//     border-color: ${props => props.theme.color.primary};
//     background-color: ${props => props.theme.color.primary};
//     border: 2px solid;
//     &:hover{
//         background-color: #fff;
//         color: ${props => props.theme.color.primary};
//     }
// }
// &.ant-btn-outline-primary{
//     border-color: ${props => props.theme.color.primary};
//     color: ${props => props.theme.color.primary};
//     &:hover{
//         background-color: ${props => props.theme.color.primary};
//         color: #fff;;
//     }
// }