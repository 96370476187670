import React from 'react'

import { Row, Typography, Col, Space, Button, Tag, Badge } from 'antd'
import { CrudList } from '@cms/core/components/Crud'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { GET_COUNT_REGISTER_BY_REFERAL } from '@cms/events/graphql/queries/eventRegistration'
import { GET_VIRAL_USER_REWARDS_LIST } from '@cms/events/graphql/queries/campaign'
import { UPDATE_VIRAL_REWARD } from '@cms/events/graphql/mutations/campaign'
import { REQUEST_REPORT } from '@cms/events/graphql/mutations/reports'
import { addToList } from '@cms/core/graphql/utils'
import { useParams, useHistory } from 'react-router-dom'

import ContentDefault from '@cms/core/components/Content'
import {
    EmailIcon,
    FacebookIcon,
    WhatsappIcon,
    TwitterIcon
} from '@cms/core/components/Icons'
const { Title, Text } = Typography

export const ReferalReview = () => {
    const { eventId } = useParams()
    const { data: countRegister, loading: loadingCountRegister } = useQuery(
        GET_COUNT_REGISTER_BY_REFERAL,
        { variables: { eventId } }
    )
    return (
        <Title level={4}>
            <Tag color="#EF6C0C">
                {countRegister?.countOfUserRegisteredByReferral
                    ? countRegister.countOfUserRegisteredByReferral
                    : 0}
            </Tag>
        </Title>
    )
}

export const ReferalList = ({ campaingDataClean, eventId }) => {
    const query = useQuery(GET_VIRAL_USER_REWARDS_LIST, {
        variables: { first: 25, viralCampaign: campaingDataClean.id }
    })

    const [updateReward] = useMutation(UPDATE_VIRAL_REWARD, {
        refetchQueries: [{ query: GET_VIRAL_USER_REWARDS_LIST }]
    })

    if (query.error) return <div>Error</div>

    const handleChangeStatus = (id, delivered) => {
        updateReward({ variables: { id, delivered: delivered } })
    }
    const client = useApolloClient()
    const history = useHistory()

    const Actions = ({ id, value, delivered }) => {
        return (
            <Space>
                {delivered === false ? (
                    <Button
                        onClick={() => handleChangeStatus(id, true)}
                        type={value ? 'primary' : 'default'}
                        style={{
                            marginRight: 8,
                            color: 'white',
                            background: '#EF6C0C',
                            borderColor: '#EF6C0C'
                        }}
                    >
                        Pendente
                    </Button>
                ) : (
                    <Button
                        type={value ? 'primary' : 'default'}
                        style={{
                            marginRight: 8,
                            background: '#00d394',
                            borderColor: '#00d394'
                        }}
                    >
                        Recompensado
                    </Button>
                )}
            </Space>
        )
    }

    const handleRowKey = (o) => o.node.id

    const exporRewards = (_) => {}

    const columns = [
        {
            title: 'Nome',
            dataIndex: '',
            render: (value, obj) => {
                return (
                    <span>
                        {obj.node.user?.name ? obj.node.user?.name : '---'}
                    </span>
                )
            }
        },
        {
            title: 'E-mail',
            dataIndex: '',
            render: (value, obj) => {
                return (
                    <span>
                        {obj.node.user?.email ? obj.node.user?.email : '---'}
                    </span>
                )
            }
        },
        {
            title: 'Prêmios',
            dataIndex: '',
            render: (value, obj) => {
                return <span>{obj.node.name || '---'}</span>
            }
        },
        {
            title: 'Indicações',
            dataIndex: '',
            align: 'center',
            render: (value, obj) => {
                return (
                    <Tag color="#26eb10">
                        {obj.node.indications || obj.node.points || '0'}
                    </Tag>
                )
            }
        },
        {
            title: 'Alterar Status',
            dataIndex: '',
            align: 'center',
            render: (value, obj) => {
                const { id } = obj.node
                return (
                    <span className={id}>
                        <Actions
                            id={id}
                            value={value}
                            delivered={obj.node.delivered}
                        />
                    </span>
                )
            }
        }
    ]

    return (
        <ContentDefault>
            <Row align="middle  " style={{ marginBottom: '20px' }}>
                <Space>
                    <Title level={4}>
                        Participantes que conquistaram os prêmios
                    </Title>
                    <ReferalReview />
                </Space>
                <Button
                    className="ant-btn ant-btn-primary ant-btn-lg"
                    style={{
                        marginLeft: 'auto'
                    }}
                    onClick={() => {
                        client.mutate({
                            mutation: REQUEST_REPORT,
                            update: addToList({
                                list: 'reports',
                                Type: 'Report'
                            }),
                            variables: {
                                type: 'rewards',
                                event: eventId
                            }
                        })
                        history.push('/evento/' + eventId + '/reports')
                    }}
                >
                    Exportar Ganhadores
                </Button>
            </Row>
            <Row>
                {query ? (
                    <CrudList
                        columns={columns}
                        queryName={'viralRewards'}
                        disableActions={true}
                        hideAction={'edit'}
                        {...query}
                    />
                ) : null}
            </Row>
        </ContentDefault>
    )
}
