import styled from 'styled-components'

export const EditorWrapper = styled.div`
    /* width: 1086px; */
    margin: 0 auto;

    .ql-editor {
        min-height: 100px;
    }

    .ql-container {
        min-height: 10em;
        border-bottom-left-radius: 0.5em;
        border-bottom-right-radius: 0.5em;
        background: #fefcfc90;
        color: #000;
    }

    .ql-color-picker {
        .ql-picker-item {
            border: 1px solid #000;
            &:first-child {
                background-color: transparent !important;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    content: '\\1F6AB';
                }
            }
        }
    }

    .ql-toolbar {
        background: #eaecec;
        border-top-left-radius: 0.5em;
        border-top-right-radius: 0.5em;
        border-bottom: none;
    }

    .td-align-center {
        text-align: center;
    }

    .td-align-left {
        text-align: left;
    }

    .td-align-right {
        text-align: right;
    }

    .td-align-justify {
        text-align: justify;
    }
`
