import { currencies } from './currencies'

/**
 * Format a number as a currency string
 * @param {number} value - The value to format in cents
 * @param {string} currency - The currency symbol
 * @returns {string} The formatted currency string
 */
export const formatCurrency = (value, currency) => {
    const { intlConfig, locale } =
        currencies.find((c) => c.symbol === currency) || currencies[0]

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        ...intlConfig
    }).format(value / 100)
}

export default formatCurrency
