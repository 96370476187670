import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const REQUEST_REPORT = gql`
    mutation RequestReport(
        $event: ObjectID!
        $talk: ObjectID
        $type: ReportTypes!
        $params: JSON
        $segment: SegmentsInput
        $dateRange: SegmentedDatesInput
    ) {
        requestReport(
            input: {
                event: $event
                talk: $talk
                type: $type
                params: $params
                dateRange: $dateRange
                segment: $segment
            }
        ) {
            ...ReportData
        }
    }

    ${fragments.ReportData}
`

export const DELETE_REPORT = gql`
    mutation DeleteReport($id: ObjectID!) {
        deleteReport(id: $id) {
            success
            id
        }
    }
`
