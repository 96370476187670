import styled from 'styled-components'
import { Typography } from 'antd'
import { COLORS } from '@cms/core/themes'
import { lighten } from 'polished'

const { Title: AntTitle } = Typography

export const Wrapper = styled.div`
    padding: 8px;
    border: 2px solid rgba(228, 236, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: ${lighten(0.1, COLORS.bgLight)};
`

export const TitleWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0.5em;
    padding-right: 8px;
    .ant-typography {
        margin-bottom: 0px;
    }
`

export const Title = styled(AntTitle)`
    padding-left: 8px;
    margin-top: 0px;
`

export const Container = styled.div`
    position: relative;

    form {
        input {
            background: #38383c;
        }

        .ant-form-item::not('.panel-header') {
            border: 2px solid rgba(228, 236, 255, 0.1);
            border-radius: 20px;
            padding: 16px;
        }
        .panel-header {
            margin-bottom: 0px;
        }
    }

    .ant-collapse {
        margin-bottom: 20px;

        .ant-collapse-header {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            &:hover {
                cursor: pointer;
            }

            h4 {
                margin: unset;
            }

            .ant-form-item-control-input {
                min-height: unset;
            }
        }

        .ant-collapse-content {
            /* background-color: rgba(255, 255, 255, 0.2); */
        }
    }
    .ant-collapse-content-box {
        ${Wrapper} {
            background-color: transparent;
        }
    }

    .ant-divider {
        &::after,
        &::before {
            border-color: ${({ theme }) => theme.color.backgroundDarkLight};
        }
    }
`

