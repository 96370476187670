import { useApolloClient } from '@apollo/client'
import Content from '@cms/core/components/Content'
import { useCrudForm } from '@cms/core/components/Crud'
import EditorHTML from '../../components/Editor'
import Loader from '@cms/core/components/Loader'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Col, message, Row } from 'antd'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Form } from '../../../styles'

const AdditionalScreen = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [content, setContent] = useState('')

    const [{ form, formSave, saveLoading, dataLoading, formUpdate }] =
        useCrudForm(
            {
                relateds: [],
                id: eventId,
                getData: async (id) => {
                    if (!id) return
                    const response = await client.query({
                        query: GET_EVENT,
                        variables: { id }
                    })

                    setContent(response.data.event?.eventInfos)
                    return { ...response.data.event }
                },
                updateData: async (id, formData) => {
                    const variables = { id, ...formData }

                    await client.mutate({
                        mutation: UPDATE_EVENT,
                        variables
                    })

                    message.success(
                        'Informaçõs adicionais atualizadas com sucesso!'
                    )
                }
            },
            [eventId]
        )

    if (dataLoading) return <Loader fixed={false} padding='10px' />

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Informações do Evento"
                subTitle="Configuração de informações básicas do evento"
            />
            <Content className="container-light">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={formSave}
                    onValuesChange={formUpdate}
                    hideRequiredMark
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                style={{ textAlign: 'left' }}
                                name="eventInfos"
                            >
                                <EditorHTML />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }} className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default AdditionalScreen
