import React, { useEffect, useState } from 'react'
import { useApolloClient } from '@apollo/client'
import { Form, Input, message, Select } from 'antd'
import { mock as progressiveMock } from '../../FormProgressive/mock'
import { mock as otherMock } from '../../FormOthers/mock'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'
import { CREATE_EVENT_FORM } from '@cms/events/graphql/mutations/form'
import { useHistory } from 'react-router-dom'
import FORM_WITH_PAGES from '../../FormProgressive/pages'
import { useLocation } from 'react-router-dom'

const FormCreationModal = ({ params, ...props }) => {
    const { eventId, formType } = params
    const history = useHistory()
    const client = useApolloClient()
    const [formId, setFormId] = useState()

    const mocks = {
        progressive: progressiveMock,
        others: otherMock
    }

    const [{ form, getError, formUpdate }, modalProps] = useCrudForm({
        getData: () => {},
        createData: async (formData) => {
            const { data } = await client.mutate({
                mutation: CREATE_EVENT_FORM,
                variables: {
                    ...formData,
                    event: eventId,
                    fields: mocks[formType].fields,
                    type: formType
                }
            })

            setFormId(data.createEventForm.id)
            message.success('Formulário criado com sucesso!')
        }
    })

    useEffect(() => {
        if (formId) {
            history.push(
                `/evento/${eventId}/forms/${formType}/${formId}/editar`
            )
        }
    }, [formId])

    return (
        <CrudModal
            title="Novo formulário"
            redirect={false}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input autoFocus />
                </Form.Item>

                {formType === 'progressive' && (
                    <Form.Item
                        label="Página"
                        name={['config', 'page']}
                        placeholder="Selecione um tipo"
                        rules={[
                            { required: true, message: 'Página é obrigatório!' }
                        ]}
                        style={{ width: '100%' }}
                    >
                        <Select onChange={() => formUpdate()}>
                            {FORM_WITH_PAGES.map((value) => (
                                <Select.Option key={value} value={value}>
                                    {value}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
            </Form>
        </CrudModal>
    )
}

export default FormCreationModal
