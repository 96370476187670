import styled from 'styled-components'

export const Wrapper = styled.div`
    height: 120px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 100%;
    margin: 20px 0px;
    border-radius: 10px;
    padding: 15px;
`

export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Reaction = styled.div`
    display: grid;
    justify-items: center;
    margin: 10px 20px;
    .ant-typography {
        margin-top: 3px;
    }
`

export const WrapperReactions = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`
