import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_TALK_NOTIFICATION = gql`
    mutation CreateTalkNotification(
        $event: ObjectID!
        $talk: ObjectID!
        $title: String!
        $image: MediaInput!
        $message: String
        $buttonText: String
        $link: String!
        $chat: Boolean
        $popup: Boolean
        $integrations: [ObjectID]
    ) {
        createTalkNotification(
            input: {
                event: $event
                talk: $talk
                title: $title
                image: $image
                message: $message
                buttonText: $buttonText
                link: $link
                chat: $chat
                popup: $popup
                integrations: $integrations
            }
        ) {
            ...TalkNotificationData
        }
    }

    ${fragments.TalkNotificationData}
`

export const UPDATE_TALK_NOTIFICATION = gql`
    mutation UpdateTalkNotification(
        $id: ObjectID!
        $event: ObjectID!
        $talk: ObjectID!
        $title: String
        $image: MediaInput
        $message: String
        $buttonText: String
        $link: String
        $chat: Boolean
        $popup: Boolean
        $integrations: [ObjectID]
    ) {
        updateTalkNotification(
            id: $id
            input: {
                event: $event
                talk: $talk
                title: $title
                image: $image
                message: $message
                buttonText: $buttonText
                link: $link
                chat: $chat
                popup: $popup
                integrations: $integrations
            }
        ) {
            ...TalkNotificationData
        }
    }

    ${fragments.TalkNotificationData}
`

export const DELETE_TALK_NOTIFICATION = gql`
    mutation DeleteTalkNotification($id: ObjectID!) {
        deleteTalkNotification(id: $id) {
            id
            success
        }
    }
`

export const SEND_TALK_NOTIFICATION = gql`
    mutation sendTalkNotification($id: ObjectID!) {
        sendTalkNotification(id: $id)
    }
`
