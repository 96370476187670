import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { GET_TALK_LIST } from '@cms/events/graphql/queries/talk'
import { CrudList, CrudColumns } from '@cms/core/components/Crud'

import { Typography, Row } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../styles'
import { Wrapper } from './styles'

const ProgramTalks = function (props) {
    const { eventId } = useParams()
    const { Text } = Typography

    const query = useQuery(GET_TALK_LIST, {
        variables: { eventId }
    })

    if (query.error) console.log(query.error)

    const columns = [
        {
            title: 'Programação',
            dataIndex: [''],
            render: (value, obj) => {
                const date = obj.node.date
                const start = dayjs(new Date(date)).format('D MMM [-] H:mm')
                const end = dayjs(new Date(obj.node.endDate)).format('H:mm')
                const fullDate = start + ' - ' + end
                return (
                    <Link
                        title="clique para visualizar o dashboard"
                        to={`/evento/${eventId}/palestras/${obj.node.id}/dashboard`}
                    >
                        <CrudColumns
                            title={obj.node.title}
                            text={[fullDate ? fullDate : '---']}
                            onlyText
                        />
                    </Link>
                )
            }
        }
    ]

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <EyeOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Programação</DashboardTitle>
            </DashboardCardTitle>

            <Wrapper>
                <CrudList
                    header={{}}
                    columns={columns}
                    queryName={'talks'}
                    hideAction={props.hideAction}
                    showHeader={false}
                    transparent
                    {...query}
                />
            </Wrapper>
        </>
    )
}

export default ProgramTalks
