import React, { useEffect, useState } from 'react'

import { WatchProvider, useWatchContext } from './context'
import { BASE_URL, NAV_ROUTES } from './constants/routes'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'
import { GET_WATCH_TOKENS } from '@cms/watch/graphql/queries/watchConfig'
import { useQuery } from '@apollo/client'

const WatchConfigContent = () => {
    const { save } = useWatchContext()

    return <ThemeBuilderContent save={save} />
}

const WatchConfigScreen = () => {
    const [previewUrl, setPreviewUrl] = useState(null)

    const { data: tokensData } = useQuery(GET_WATCH_TOKENS)

    useEffect(() => {
        if (tokensData?.watchTokens) {
            const { token } = tokensData?.watchTokens
            setPreviewUrl(
                `${process.env.APIGW_WATCH_ENDPOINT}/auth/magiclink/callback?token=${token}`
            )
        }
    }, [tokensData])

    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: NAV_ROUTES }}
            previewUrl={previewUrl}
        >
            <WatchProvider>
                <WatchConfigContent />
            </WatchProvider>
        </ThemeBuilderProvider>
    )
}

export default WatchConfigScreen
