import React from 'react'

import AppLayout from '@cms/core/components/AppLayout'
import SponsorRoutes from '@cms/events/routes/sponsorRouters'

const Sponsor = function () {
    return (
        <AppLayout rootModule="events.sponsor">
            <SponsorRoutes />
        </AppLayout>
    )
}

export default Sponsor
