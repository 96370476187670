import { useQuery } from '@apollo/client'
import { GET_WATCH_CONTENTS_ORDENABLE } from '@cms/watch/graphql/queries/watchContent'
import { Button, Modal, Table } from 'antd'

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

interface ContentLibraryProps {
    onFinished?: (items: any[]) => void
    path?: string
}

const columns = [
    {
        title: 'Título',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Duração',
        dataIndex: 'duration',
        key: 'duration',
        with: 100,
        render: (duration: number = 0) => {
            const hours = Math.floor(duration / 60)
            const minutes = duration % 60
            return `${hours}h ${minutes}m`
        }
    }
]

const ContentLibrary: React.FC<ContentLibraryProps> = ({
    path,
    onFinished
}) => {
    const [_visible, setVisible] = useState(true)
    const history = useHistory()

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

    const query = useQuery(GET_WATCH_CONTENTS_ORDENABLE, {
        variables: {
            first: 9999999,
            module: 'COURSE',
            orderBy: {
                createdAt: 'DESC'
            }
        }
    })

    const handleCancel = () => {
        setVisible(false)
        if (path) {
            setTimeout(() => {
                history.push(path)
            }, 200)
        }
    }

    const handleSave = () => {
        if (onFinished) {
            onFinished(
                query.data?.watchContents?.edges
                    .filter((edge: any) =>
                        selectedRowKeys.includes(edge.node.id)
                    )
                    .map((edge: any) => edge.node) || []
            )
        }
        setVisible(false)

        if (path) {
            setTimeout(() => {
                history.push(path)
            }, 200)
        }
    }

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    return (
        <Modal
            title="Biblioteca de Conteúdos"
            width={800}
            open={_visible}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancelar
                </Button>,
                <Button
                    data-cy="save-button"
                    key="submit"
                    type="primary"
                    onClick={handleSave}
                    disabled={selectedRowKeys.length === 0}
                >
                    Adicionar
                </Button>
            ]}
        >
            {/* @todo Add Infite scroll */}
            <Table
                tableLayout="fixed"
                rowSelection={{
                    selectedRowKeys,
                    onChange: onSelectChange,
                    columnWidth: 50
                }}
                columns={columns}
                dataSource={
                    query.data?.watchContents?.edges.map(
                        (edge: any) => edge.node
                    ) || []
                }
                loading={query.loading}
                rowKey="id"
                pagination={false}
                virtual={true}
                scroll={{ y: 400, x: '100vw' }}
            />
        </Modal>
    )
}

export default ContentLibrary
