import React, { useMemo } from 'react'
import { AppContext } from '..'
import { findModule } from '../../../Sidebar/common'
import { IAppModule } from '@cms/core/types/app'

interface IUseRootModule {
    rootModuleKey?: string
    setRootModule?: (rootModule: string) => void
    rootModule?: IAppModule
    modules?: IAppModule[]
}

export const useRootModule = (): IUseRootModule => {
    const { rootModuleKey, setRootModule, app } = React.useContext(AppContext)

    const rootModule = useMemo(() => {
        const _rootModule = findModule(rootModuleKey, app.modules)
        return _rootModule
    }, [rootModuleKey, app.modules])

    const modules = useMemo(() => {
        return rootModule?.children
    }, [rootModule])

    return {
        rootModuleKey,
        setRootModule,
        rootModule,
        modules
    }
}

