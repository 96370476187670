import React from 'react'
import { useQuery, gql } from '@apollo/client'

import Card from '../CardInner'

const TalkViews = ({ reverse, align, talk, event, full }) => {
    const { data, loading, error } = useQuery(
        gql`
            query ($event: ObjectID!, $talk: ObjectID) {
                uniqueUsers(event: $event, talk: $talk)
            }
        `,
        { variables: { talk, event } }
    )

    if (loading || error) return null

    return (
        <>
            <Card
                title={'Usuários Únicos'}
                reverse={reverse}
                data={data?.uniqueUsers || 0}
                align={align}
                full={full}
            />
        </>
    )
}

TalkViews.defaultProps = {
    reverse: false,
    align: ''
}

export default TalkViews
