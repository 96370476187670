import React from 'react'

import AppLayout from '@cms/core/components/AppLayout'
import AdminRoutes from '@cms/admin/routes/adminRoutes'

const Container = () => {
    return (
        <AppLayout rootModule={'admin'}>
            <AdminRoutes />
        </AppLayout>
    )
}

export default Container
