import React from 'react'

import {
    Row,
    Col,
    Typography,
    Layout,
    Form,
    Input,
    Radio,
    Collapse,
    Switch
} from 'antd'

import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { ContentDefault } from '@cms/events/components/Referal/Reward/Add/styles'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import EmailEditor from '@cms/core/components/EmailEditor'
import EditorPreview from '@cms/core/components/EmailEditor/Preview'
import ColorPicker from '@cms/events/components/ColorPicker'

import { ContentEditEmail } from './styles'

const { Title, Paragraph } = Typography
const { Panel } = Collapse

const ModalContent = ({ title, field }) => {
    return (
        <Layout>
            <ContentDefault>
                <ContentEditEmail className="td-content-default">
                    <Row style={{ padding: '20px' }}>
                        {/* <Col span={12}>Nome campanha</Col> */}
                    </Row>
                    <Row style={{ padding: '20px' }}>
                        <Col span={24}>
                            <Title level={2} style={{ textAlign: 'left' }}>
                                {title}
                            </Title>
                            <Paragraph style={{ textAlign: 'left' }}>
                                Alguns detalhes sobre a campanha
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{ padding: '20px' }}>
                            <Row>
                                <Col span={24}>Informações básicas</Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'nameFrom']}
                                        label="De"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Enviado por" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={[

                                            'template',
                                            'emailFrom'
                                        ]}
                                        label="E-mail"
                                        rules={[
                                            { required: true, type: 'email' }
                                        ]}
                                    >
                                        <Input placeholder="E-mail" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'subject']}
                                        label="Assunto"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Assunto" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'replyTo']}
                                        label="Responder para"
                                        rules={[
                                            { required: true, type: 'email' }
                                        ]}
                                    >
                                        <Input placeholder="Responder para" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>Conteúdo</Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'sayHello']}
                                        label="Saudação"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Enviado por" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'content']}
                                        label="Conteúdo"
                                        rules={[{ required: true }]}
                                    >
                                        <EmailEditor />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Collapse>
                                        <Panel header="Variáveis">
                                            <p>{`{{ name }} para acessar o nome do inscrito.`}</p>
                                            <p>{`{{ email }} para acessar o email do inscrito.`}</p>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'button', 'text']}
                                        label="Texto do botão"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Texto do botão" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'button', 'link']}
                                        label="Link do botão"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Link do botão" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={[
                                            'template',
                                            'button',
                                            'backgroundColor'
                                        ]}
                                        label="Cor de fundo do botão"
                                        rules={[{ required: true }]}
                                        initialValue={'#1ABC9C'}
                                    >
                                        <ColorPicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={[
                                            'template',
                                            'button',
                                            'textColor'
                                        ]}
                                        label="Cor do botão"
                                        rules={[{ required: true }]}
                                        initialValue={'#1ABC9C'}
                                    >
                                        <ColorPicker />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'thankYouMessage']}
                                        label="Mensagem de agradecimento"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="Mensagem de agradecimento" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'sizeMedia']}
                                        label="Imagem cabeçalho"
                                    >
                                        <Radio.Group>
                                            <Radio value={'imagem'}>
                                                Imagem
                                            </Radio>
                                            <Radio value={'logo'}>Logo</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={['template', 'media']}
                                        label="Imagem/Logo"
                                    >
                                        <MediaUploader />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={14} style={{ padding: '20px' }}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Ativo"
                                        name={['active']}
                                        valuePropName="checked"
                                        style={{
                                            display: '-webkit-box',
                                            justifyContent: 'left'
                                        }}
                                    >
                                        <Switch
                                            style={{ marginLeft: '10px' }}
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={
                                                <CloseOutlined />
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Title level={2} style={{ textAlign: 'center' }}>
                                <Form.Item
                                    style={{ textAlign: 'left' }}
                                    name={['template']}
                                >
                                    <EditorPreview />
                                </Form.Item>
                            </Title>
                        </Col>
                    </Row>
                </ContentEditEmail>
            </ContentDefault>
        </Layout>
    )
}

export default ModalContent
