export const layout02 = (event) => ({
    layout: 'layout02',
    info: {
        name: 'Evok',
        image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-2-1635444476233.png'
    },
    theme: {
        colors: {
            white: '#F4FFFA',
            black: '#121A16',
            neutral: '#CDEDEB;#CDEDEB' /* 4 */,
            primary: '#2C4944;#000F0B' /* blue | 2 */,
            secondary: '#2295D6;#192FF1' /* pink | 3 */,
            highlight: '#800FB5;#AF2A4A' /* green | 1 */
        },
        button: {
            type: 'rounded'
        },
        box: {
            type: 'rounded'
        },
        font: 'Sora'
    },
    seo: {
        title: 'Evok',
        description: 'Evok',
        keywords: [],
        image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-2-1635444476233.png'
    },
    formModal: {
        box: {
            bgColor: 'white',
            textColor: 'black'
        },
        headline: {
            text: 'Inscreva-se Gratuitamente!'
        },
        button: {
            text: 'Inscreva-se gratuitamente',
            bgColor: 'primary',
            textColor: 'white'
        },
        countdown: {
            date: event.startDate,
            text: 'As vagas acabam em:'
        }
    },
    cookies: {
        configs: {
            container: {
                bgColor: 'primary'
            },
            text: {
                textColor: 'white'
            },
            button: {
                bgColor: 'primary',
                textColor: 'white',
                text: 'Ok'
            }
        }
    },
    pages: {
        home: {
            name: 'Home Page',
            slug: 'home',
            sections: [
                {
                    type: 'Header',
                    active: 'disabled',
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        date: {
                            text: '8 a 12 de Março - Online e gratuito',
                            textColor: 'white'
                        },
                        badge: {
                            bgColor: 'highlight'
                        },
                        headline: {
                            text: 'Esse é um\nexemplo do\nmaior evento\ndigital do mundo',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: `Esse é um exemplo do\nmaior evento digital do mundo`,
                            textColor: 'white'
                        },
                        owners: {
                            title: {
                                text: 'REALIZADO POR',
                                textColor: 'white'
                            },
                            logo: {
                                color: 'white'
                            }
                        },
                        form: {
                            box: {
                                bgColor: 'black',
                                textColor: 'white'
                            },
                            headline: {
                                text: 'Inscreva-se Gratuitamente!',
                                enabled: true
                            },
                            button: {
                                text: 'Inscreva-se gratuitamente',
                                bgColor: 'secondary',
                                textColor: 'white'
                            },
                            countdown: {
                                date: event.startDate,
                                text: 'As vagas acabam em:',
                                enabled: true
                            }
                        }
                    }
                },
                {
                    type: 'SponsorCarousel',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'black',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'POWERED BY',
                            textColor: 'white'
                        },
                        logo: {
                            color: 'white'
                        },
                        arrows: {
                            color: 'white'
                        }
                    }
                },
                {
                    type: 'EventNumbers',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'highlight',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        title: {
                            textColor: 'white'
                        },
                        subtitle: {
                            textColor: 'white'
                        },
                        columns: [
                            {
                                title: {
                                    text: '100%'
                                },
                                subtitle: {
                                    text: 'Online\ne Gratuito'
                                }
                            },
                            {
                                title: {
                                    text: '100%'
                                },
                                subtitle: {
                                    text: 'Online\ne Gratuito'
                                }
                            },
                            {
                                title: {
                                    text: '100%'
                                },
                                subtitle: {
                                    text: 'Online\ne Gratuito'
                                }
                            },
                            {
                                title: {
                                    text: '100%'
                                },
                                subtitle: {
                                    text: 'Online\ne Gratuito'
                                }
                            }
                        ],
                        box: {
                            section: { type: '1' }
                        }
                    }
                },
                {
                    type: 'About',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'Sobre o evento',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                            textColor: 'white'
                        },
                        video: {
                            url: 'https://vimeo.com/76979871'
                        }
                    }
                },
                {
                    type: 'Learn',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'O que você\nvai aprender:',
                            textColor: 'white'
                        },
                        content: {
                            text: 'A nova realidade de vendas no mundo híbrido, omnichannel. \n\n Visão de futuro da experiência para vendas b2b. \n\n Visão profunda de toda a nova jornada de vendas, suas oportunidades e pontos de atenção. \n\n Insights práticos com impacto direto em geração de receita. \n\n Como otimizar os múltiplos novos canais de vendas disponíveis.',
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Speakers',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'black',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'Conheça os palestrantes',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Saiba o que as maiores lideranças de marketing do Brasil\ne do mundo têm a dizer.',
                            textColor: 'white'
                        },
                        modal: {
                            bgColor: 'white',
                            text: {
                                textColor: 'black'
                            },
                            logo: {
                                color: 'black'
                            },
                            icon: {
                                color: 'default'
                            }
                        },
                        cards: {
                            text: {
                                textColor: 'white'
                            },
                            logo: {
                                color: 'white'
                            }
                        }
                    }
                },
                {
                    type: 'Rewards',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            indications: {
                                textColor: 'secondary'
                            },
                            reward: {
                                textColor: 'black'
                            }
                        },
                        headline: {
                            text: 'indique\namigos\ne ganhe\nprêmios',
                            textColor: 'white'
                        },
                        content: {
                            text: 'Além de ter acesso a esse conteúdo\ngratuito, você pode convidar seus\namigos e colegas de trabalho para\n participar com você e ser\nrecompensado por isso! Confira:',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'highlight',
                            textColor: 'black',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Scheduled',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'highlight',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            textColor: 'white',
                            text: 'Confira os horários\ndas palestras e os palestrantes'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            time: {
                                textColor: 'secondary'
                            },
                            hostType: {
                                bgColor: 'highlight'
                            }
                        }
                    }
                },
                {
                    type: 'Cta',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: `Seja um participante do\n ${event.name}!`,
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Pela primeira vez, um evento digital sem palestrinhas monótonas e com\n muitos dados práticos que podem te ajudar a superar em 100% a expectativa',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'secondary',
                            textColor: 'white',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Sponsor',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'black',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'black',
                            logo: {
                                color: 'white'
                            }
                        },
                        headline: {
                            text: `Quem patrocina o  ${event.name}`,
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seus amigos',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'white',
                            textColor: 'black',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Owner',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'black',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            logo: { color: 'black' },
                            title: {
                                text: 'Sobre o realizador'
                            }
                        },
                        headline: {
                            text: `O ${event.name} é realizado por`,
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Address',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        titles: {
                            textColor: 'white'
                        },
                        text: {
                            textColor: 'white'
                        },
                        title: {
                            text: 'Onde vai ser?'
                        },
                        name: {
                            text: 'Hotel Unique São Paulo'
                        },
                        address: {
                            text: `${event.address ? event.address : ''}`
                        },
                        contact: {
                            text: '(11) 99999-9999'
                        }
                    }
                },
                /* {
                    type: 'Ticket',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        title: {
                            text: 'Comprar ingressos',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Escolha qual tipo de ingresso você quer comprar.',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'primary',
                            textColor: 'white',
                            ticket: {
                                name: {
                                    textColor: 'white'
                                },
                                description: {
                                    textColor: 'white'
                                },
                                soldOut: {
                                    textColor: 'white'
                                },
                                price: {
                                    bgColor: 'highlight',
                                    textColor: 'white',
                                    line: {
                                        bgColor: 'white'
                                    }
                                }
                            }
                        }
                    }
                }, */
                {
                    type: 'Countdown',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        countdown: {
                            date: `${event.startDate}`
                        },
                        message: {
                            text: 'Inscreva-se! O evento começa em:'
                        },
                        text: {
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'white',
                            textColor: 'black',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Footer',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'black',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        terms: {
            name: 'Termos de uso',
            slug: 'termos-de-uso',
            custom: false,
            sections: [
                {
                    type: 'Cta',
                    name: 'Header termos de uso',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            text: `Termos e condições de uso`,
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Leia atentamente os termos e condições de uso do site',
                            textColor: 'white'
                        },
                        button: {
                            active: false
                        }
                    }
                },
                {
                    type: 'Cta',
                    name: 'Termos de uso',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            active: false
                        },
                        subtitle: {
                            text: `<span><div class="td-align-center"><strong>EXEMPLO de Termos de Uso</strong></div><div class="td-align-left"><br></div><div class="td-align-left"> 1. Aceitação dos Termos </div><div class="td-align-left"> Ao acessar e utilizar este site/aplicativo, você concorda em cumprir e estar vinculado a estes Termos de Uso, bem como a todas as leis e regulamentos aplicáveis. Caso você não concorde com algum dos termos, por favor, não utilize nossos serviços. </div><div class="td-align-left"><br></div><div class="td-align-left"> 2. Modificações nos Termos </div><div class="td-align-left"> Reservamo-nos o direito de modificar estes Termos de Uso a qualquer momento, a nosso exclusivo critério. As alterações entrarão em vigor imediatamente após a publicação. É de sua responsabilidade revisar periodicamente os Termos de Uso para garantir que está ciente de quaisquer mudanças. </div><div class="td-align-left"><br></div><div class="td-align-left"> 3. Uso do Serviço </div><div class="td-align-left"> Você concorda em utilizar o site/aplicativo apenas para fins legais e de acordo com as leis e regulamentos aplicáveis. Não é permitido: </div><div class="td-align-left"><br></div><div class="td-align-left"> Violar qualquer direito de terceiros, incluindo, mas não se limitando a, direitos de propriedade intelectual ou privacidade. Distribuir, modificar ou revender qualquer conteúdo obtido através de nossos serviços. Utilizar nossos serviços de maneira que possa danificar, desativar, sobrecarregar ou prejudicar qualquer servidor ou rede. </div><div class="td-align-left"><br></div><div class="td-align-left"> 4. Conta de Usuário </div><div class="td-align-left"> Ao criar uma conta, você concorda em fornecer informações verdadeiras, precisas e completas. Você é o único responsável por manter a confidencialidade de sua conta e senha, bem como por todas as atividades que ocorram sob sua conta.</div></span>`,
                            textColor: 'white'
                        },
                        button: {
                            active: false
                        }
                    }
                },
                {
                    type: 'Footer',
                    name: 'Rodapé',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        speaker: {
            slug: 'speaker',
            name: 'Home palestrante',
            custom: false,
            model: {
                name: 'Speaker',
                fieldRef: 'page.slug'
            },
            seo: {
                title: 'Palestrante',
                description: 'Conheça o palestrante',
                keywords: [],
                image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-1-1635444295095.png'
            },
            sections: [
                {
                    type: 'Header2',
                    active: true,
                    name: 'Cabeçalho',
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill',
                                objectFit: 'fill',
                                objectPosition: 'center center'
                            }
                        },
                        badge: {
                            bgColor: 'white'
                        },
                        date: {
                            textColor: 'white',
                            text: '<div class="td-align-right">8 a 12 de Março</div>'
                        },
                        owners: {
                            title: {
                                textColor: 'white'
                            }
                        },
                        headline: {
                            textColor: 'white'
                        },
                        subtitle: {
                            textColor: 'white'
                        },
                        button: {
                            active: true,
                            redirect: {
                                mode: 'page'
                            },
                            bgColor: 'secondary',
                            textColor: 'white'
                        },
                        modelName: {
                            text: '<div><span class="td-color-white">{{ref.name}}</span></div>'
                        },
                        smallText: {
                            text: '<span><div><span class="td-color-white">Num cenário de retomada ao presencial, </span></div><div><span class="td-color-white">é hora dos empreendedores aprenderem a </span></div><div><span class="td-color-white">equilibrar seus esforços </span></div></span>'
                        },
                        address: {
                            text: '<div class="td-align-right"><span class="td-color-white">Hotel Unique - Jardins - São Paulo/SP</span></div>'
                        },
                        model: {
                            logo: {
                                color: 'default'
                            }
                        }
                    }
                },
                {
                    type: 'About',
                    name: 'Sobre',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'Sobre o evento',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                            textColor: 'white'
                        },
                        video: {
                            url: 'https://vimeo.com/76979871'
                        }
                    }
                },
                {
                    type: 'Rewards',
                    name: 'Recompensas da indicação',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            indications: {
                                textColor: 'secondary'
                            },
                            reward: {
                                textColor: 'black'
                            }
                        },
                        headline: {
                            text: 'indique\namigos\ne ganhe\nprêmios',
                            textColor: 'white'
                        },
                        content: {
                            text: 'Além de ter acesso a esse conteúdo\ngratuito, você pode convidar seus\namigos e colegas de trabalho para\n participar com você e ser\nrecompensado por isso! Confira:',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'highlight',
                            textColor: 'black',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Scheduled',
                    name: 'Agenda',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'secondary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            textColor: 'white',
                            text: 'Confira os horários\ndas palestras e os palestrantes'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            time: {
                                textColor: 'primary'
                            },
                            hostType: {
                                bgColor: 'primary'
                            }
                        }
                    }
                },
                {
                    type: 'Owner',
                    name: 'Realizadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            textColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            logo: { color: 'black' },
                            title: {
                                text: 'Sobre o realizador'
                            }
                        },
                        headline: {
                            text: `O ${event.name} é realizado por`,
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Countdown',
                    name: 'Countdown',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'secondary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        countdown: {
                            date: `${event.startDate}`
                        },
                        message: {
                            text: 'Inscreva-se! O evento começa em:'
                        },
                        text: {
                            textColor: 'white'
                        },
                        button: {
                            text: 'Inscreva-se gratuitamente',
                            bgColor: 'highlight',
                            textColor: 'black'
                        }
                    }
                },
                {
                    type: 'Footer',
                    name: 'Rodapé',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        sponsor: {
            slug: 'sponsor',
            name: 'Home patrocinador',
            custom: false,
            model: {
                name: 'Brand',
                fieldRef: 'page.slug'
            },
            seo: {
                title: 'Patrocinador',
                description: 'Conheça o patrocinador',
                keywords: [],
                image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-1-1635444295095.png'
            },
            sections: [
                {
                    type: 'Header3',
                    active: true,
                    name: 'Cabeçalho',
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill',
                                objectFit: 'fill',
                                objectPosition: 'center center'
                            }
                        },
                        date: {
                            textColor: 'white',
                            text: '<span class="td-color-white">8 a 12 de Março</span>'
                        },
                        address: {
                            text: '<span class="td-color-white">Hotel Unique - Jardins - São Paulo/SP</span>'
                        },
                        headline: {
                            textColor: 'white',
                            text: '<div><span class="td-color-white">{{ref.page.title}}</span></div>'
                        },
                        smallText: {
                            text: 'Realizador'
                        },
                        model: {
                            logo: {
                                color: 'default'
                            }
                        },
                        button: {
                            active: true,
                            redirect: {
                                mode: 'page'
                            },
                            bgColor: 'secondary',
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Sponsor',
                    name: 'Patrocinadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            logo: {
                                color: 'black'
                            }
                        },
                        headline: {
                            text: `Quem patrocina o ${event.name}`,
                            textColor: 'black'
                        },
                        subtitle: {
                            text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seus amigos',
                            textColor: 'black'
                        }
                    }
                },
                {
                    type: 'Owner',
                    name: 'Realizadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            textColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            logo: { color: 'black' },
                            title: {
                                text: 'Sobre o realizador'
                            }
                        },
                        headline: {
                            text: `O ${event.name} é realizado por`,
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Footer',
                    name: 'Rodapé',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        patrocinadores: {
            slug: 'sponsors',
            name: 'Patrocinadores',
            custom: true,
            seo: {
                title: 'Patrocinadores',
                description: 'Conheça os patrocinadores',
                keywords: [],
                image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-1-1635444295095.png'
            },
            sections: [
                {
                    type: 'Cta',
                    name: 'Header',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            text: `Confira os patrocinadores!`,
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Saiba o que as maiores lideranças de marketing do Brasil e do mundo têm a dizer.',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'highlight',
                            textColor: 'black',
                            text: 'Inscreva-se gratuitamente'
                        }
                    }
                },
                {
                    type: 'Sponsor',
                    name: 'Patrocinadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            logo: {
                                color: 'black'
                            }
                        },
                        headline: {
                            text: `Quem patrocina o ${event.name}`,
                            textColor: 'black'
                        },
                        subtitle: {
                            text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seus amigos',
                            textColor: 'black'
                        }
                    }
                },
                {
                    type: 'Owner',
                    name: 'Realizadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            textColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            logo: { color: 'black' },
                            title: {
                                text: 'Sobre o realizador'
                            }
                        },
                        headline: {
                            text: `O ${event.name} é realizado por`,
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Footer',
                    name: 'Rodapé',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        palestrantes: {
            slug: 'palestrantes',
            name: 'Palestrantes',
            custom: true,
            seo: {
                title: 'Palestrantes',
                description: 'Conheça os palestrantes',
                keywords: [],
                image: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/template-1-1635444295095.png'
            },
            sections: [
                {
                    type: 'Header',
                    active: true,
                    name: 'Cabeçalho',
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        date: {
                            text: '8 a 12 de Março - Online e gratuito',
                            textColor: 'white'
                        },
                        badge: {
                            bgColor: 'white'
                        },
                        headline: {
                            text: 'Conheça os palestrantes que vão participar',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: `Esse é um exemplo do\nmaior evento digital do mundo`,
                            textColor: 'white'
                        },
                        owners: {
                            title: {
                                text: 'REALIZADO POR',
                                textColor: 'white'
                            },
                            logo: {
                                color: 'white'
                            }
                        },
                        form: {
                            box: {
                                bgColor: 'white',
                                textColor: 'black'
                            },
                            headline: {
                                text: 'Inscreva-se Gratuitamente!',
                                enabled: true
                            },
                            button: {
                                text: 'Inscreva-se gratuitamente',
                                bgColor: 'primary',
                                textColor: 'white'
                            },
                            countdown: {
                                date: event.startDate,
                                text: 'As vagas acabam em:',
                                enabled: true
                            }
                        }
                    }
                },
                {
                    type: 'Speakers',
                    name: 'Palestrantes',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'Conheça os palestrantes',
                            textColor: 'black'
                        },
                        subtitle: {
                            text: 'Saiba o que as maiores lideranças de marketing do Brasil\ne do mundo têm a dizer.',
                            textColor: 'black'
                        },
                        cards: {
                            text: {
                                textColor: 'black'
                            },
                            logo: {
                                color: 'black'
                            }
                        }
                    }
                },
                {
                    type: 'Owner',
                    name: 'Realizadores',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            textColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            logo: { color: 'black' },
                            title: {
                                text: 'Sobre o realizador'
                            }
                        },
                        headline: {
                            text: `O ${event.name} é realizado por`,
                            textColor: 'white'
                        }
                    }
                },
                {
                    type: 'Footer',
                    name: 'Rodapé',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        text: {
                            textColor: 'white'
                        }
                    }
                }
            ]
        },
        indication: {
            slug: 'inscricao-confirmada',
            name: 'Inscrição confirmada',
            sections: [
                {
                    type: 'Viral',
                    active: true,
                    configs: {
                        id: 'section-indication',
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: null, // fill, repeat
                                objectFit: null, // fill, contain, cover, none
                                objectPosition: null // top, center, bottom
                            }
                        },
                        headline: {
                            text: 'Inscrição concluída!',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Em instantes você receberá um email \n com mais informações. Caso não receba em 15 minutos, \n por gentileza, verifique a caixa de SPAM.',
                            textColor: 'white'
                        },
                        button: {
                            bgColor: 'highlight',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'black',
                            headline: {
                                text: 'Indique amigos e\n ganhe prêmios!',
                                textColor: 'white'
                            },
                            text: {
                                text: 'Use seu link único para convidar seus amigos!',
                                textColor: 'white'
                            },
                            viralWidget: {
                                textColor: 'white',
                                buttons: {
                                    bgColor: 'highlight',
                                    textColor: 'black'
                                }
                            },
                            telegram: {
                                text: {
                                    text: 'Participe também do nosso Telegram!',
                                    textColor: 'white'
                                },
                                button: {
                                    bgColor: 'primary',
                                    textColor: 'white',
                                    text: 'Telegram',
                                    action: {
                                        type: 'external',
                                        url: 'https://web.telegram.org/'
                                    }
                                }
                            },
                            rewards: {
                                bgColor: 'white',
                                indications: {
                                    textColor: 'secondary'
                                },
                                reward: {
                                    textColor: 'black'
                                }
                            }
                        },
                        footer: {
                            text: 'Os prêmios serão entregues em até 15 dias úteis após o evento.\n*As indicações serão consideradas válidas apenas se corresponderem a emails de CPFs diferentes.',
                            textColor: 'white'
                        }
                    }
                }
            ]
        }
    }
})
