import React, { createContext, useCallback, useState } from 'react'

interface IAppConfigContext {
    languages?: string[]
    setLanguages?: (languages: string[]) => void
}

export const AppConfigContext = createContext<IAppConfigContext>({
    languages: [],
    setLanguages: () => {}
})

export interface IAppConfigProviderProps {
    children: React.ReactNode
}

export function AppConfigProvider({ children }: IAppConfigProviderProps) {
    const [languages, _setLanguages] = useState<[]>([])

    const setLanguages = useCallback((languages: []) => {
        _setLanguages(languages)
    }, [])

    return (
        <AppConfigContext.Provider value={{ languages, setLanguages }}>
            {children}
        </AppConfigContext.Provider>
    )
}
