import { Collapse, Divider, DividerProps, Input } from 'antd'
import styled from 'styled-components'

export const CustomCollapse = styled(Collapse)`
    .ant-collapse-item > .ant-collapse-header {
        display: flex;
        align-items: center;
    }
`

interface AddItemProps extends DividerProps {
    onClick?: () => void
}

export const AddItem = styled(Divider)<AddItemProps>`
    height: 20px;
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;

    &:hover {
        height: 30px;
        transition: 0.4s;
        cursor: pointer;
        opacity: 1;
    }
`

export const CustomInput = styled(Input)`
    &.ant-input-borderless {
        background-color: rgba(0, 0, 0, 0.2) !important;
        &:focus {
            background-color: #38383c !important;
            ~ .ant-input-group-addon {
                display: table-cell !important;
                background-color: #38383c !important;
                svg {
                    visibility: visible;
                }
            }
        }
    }
`
