import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useQuery } from '@apollo/client'
import { UsergroupAddOutlined } from '@ant-design/icons'

import {
    IconWrapper,
    BigCount,
    Wrapper,
    DashboardTitle,
    DashboardCardTitle
} from '../styles'

import {
    GET_COUNT_ALL_REGISTER,
    GET_COUNT_ALL_REGISTER_REFERAL,
    GET_COUNT_UNREGISTER_REFERAL
} from '@cms/events/graphql/queries/eventRegistration'

type Props = {
    eventId?: any
    data?: any
}

const TotalsChart = ({ eventId, data }: Props) => {
    const { data: TotalRegistersData } = useQuery(GET_COUNT_ALL_REGISTER, {
        variables: { eventId: eventId }
    })

    const { data: TotalRegistersReferalData } = useQuery(
        GET_COUNT_ALL_REGISTER_REFERAL,
        {
            variables: { eventId: eventId }
        }
    )

    const { data: TotalUnregistersReferalData } = useQuery(
        GET_COUNT_UNREGISTER_REFERAL,
        {
            variables: { eventId: eventId }
        }
    )

    const TotalRegisters = TotalRegistersData
        ? TotalRegistersData.countOfAllRegisteredUsers
        : 0
    const TotalRegistersReferal = TotalRegistersReferalData
        ? TotalRegistersReferalData.countOfUserRegisteredByReferral
        : 0
    const TotalUnregistersReferal = TotalUnregistersReferalData
        ? TotalUnregistersReferalData.countOfUnregisteredUserByReferral
        : 0

    const formatValue = (value) => (value ? value : 0)

    return (
        <>
            <Row>
                <Col span={24}>
                    <DashboardCardTitle>
                        <IconWrapper>
                            <UsergroupAddOutlined style={{ color: '#fff' }} />
                        </IconWrapper>
                        <DashboardTitle>Total</DashboardTitle>
                    </DashboardCardTitle>{' '}
                </Col>
                <Col span={24}>
                    <Wrapper>Indicações</Wrapper>
                    <BigCount>{formatValue(TotalRegistersReferal)}</BigCount>
                </Col>
                <Col span={24}>
                    <Wrapper>Não indicados</Wrapper>
                    <BigCount>{formatValue(TotalUnregistersReferal)}</BigCount>
                </Col>
                <Col span={24}>
                    <Wrapper>Inscritos</Wrapper>
                    <BigCount>{formatValue(TotalRegisters)}</BigCount>
                </Col>
            </Row>
        </>
    )
}

export default TotalsChart
