import React from 'react'

import Card, { MainNumberV2, CompareCircle } from '../../Card'

import { MobileOutlined, UserOutlined } from '@ant-design/icons'
import { Col, Row } from 'antd'

const RealTimeCard = ({ data }) => {
    const { totalUsersInRealtime, userPerPlatform } = data

    const values =
        userPerPlatform.length > 0
            ? userPerPlatform.map((users) => {
                  const { platform, platformPercentage } = users
                  return {
                      name: platform,
                      percent: platformPercentage
                  }
              })
            : []

    return (
        <Row gutter={[6, 6]} style={{ height: '100%' }}>
            <Col lg={10} md={10} sm={24}>
                <Card icon={UserOutlined} title="Usuários ativos">
                    <MainNumberV2 value={totalUsersInRealtime} />
                </Card>
            </Col>
            <Col lg={14} md={14} sm={24}>
                <Card icon={MobileOutlined} title="Tipo de dispositivo">
                    <CompareCircle values={values} />
                </Card>
            </Col>
        </Row>
    )
}

export default RealTimeCard
