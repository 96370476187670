import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { message, Modal } from 'antd'
import dayjs from 'dayjs'

import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client'

import { useHistory } from 'react-router-dom'

import ModalStep from '@cms/events/components/ModalStep'
import { useCrudForm } from '@cms/core/components/Crud'

import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { CREATE_EVENT, UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { addToList } from '@cms/core/graphql/utils'

import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { GET_CURRENT_CUSTOMER } from '@cms/core/graphql/queries/customer'

const EventModal = ({ eventId }) => {
    const history = useHistory()
    const client = useApolloClient()
    const [hasLimit, setHasLimit] = useState(false)
    const { data } = useQuery(GET_CURRENT_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    })

    const id = eventId === true ? null : eventId

    useEffect(() => {
        if (id || !data) return

        let { events } = data.currentCustomer.permissions

        if (events.amount === -1 || events.amount > events.used) {
            setHasLimit(true)
            return
        }

        Modal.warn({
            title: 'Você atingiu o limite de eventos!',
            content: (
                <p>
                    Seu plano tem limite de {events.amount} evento(s). <br />
                    Delete um evento para continuar.
                </p>
            ),
            onOk() {
                setTimeout(() => {
                    dispatch({ type: 'HIDE_MODAL_EVENT' })
                }, 200)
            }
        })
    }, [data])

    const [{ form, formSave, saveLoading, getError }] = useCrudForm(
        {
            id,
            relateds: [],
            createData: async (formData) => {
                const variables = { type: 'SUMMIT', ...formData }

                const { data } = await client.mutate({
                    mutation: CREATE_EVENT,
                    update: addToList({ list: 'events', Type: 'Event' }),
                    variables
                })

                message.success('Evento criado com sucesso!')

                setTimeout(() => {
                    dispatch({ type: 'HIDE_MODAL_EVENT' })
                    history.push('/evento/' + data.createEvent.id)
                }, 200)
            },
            getData: async (id) => {
                if (!id) return
                const response = await client.query({
                    query: GET_EVENT,
                    variables: { id }
                })

                const data = { ...response.data.event }

                if (data.startDate)
                    data.startDate = dayjs(new Date(data.startDate))
                if (data.endDate) data.endDate = dayjs(new Date(data.endDate))

                return data
            },
            updateData: async (id, formData) => {
                const variables = { id, ...formData }

                await client.mutate({
                    mutation: UPDATE_EVENT,
                    variables
                })

                message.success('Evento editado com sucesso!')

                setTimeout(() => {
                    dispatch({ type: 'HIDE_MODAL_EVENT' })
                }, 200)
            }
        },
        [history, id]
    )

    const dispatch = useDispatch()

    const stepType = [
        // {
        //     title: 'Tipo Evento',
        //     component: (props) => (
        //         <StepOne form={form} getError={getError} {...props}/>
        //     ),
        //     validation: () => {
        //         const values = form.getFieldsValue(['type'])
        //         return !!values.type
        //     }
        // }
    ]

    const steps = [
        {
            title: 'Tipo Evento',
            component: (props) => (
                <StepTwo form={form} getError={getError} {...props} />
            ),
            validation: () => {
                const values = form.getFieldsValue([
                    'name',
                    'startDate',
                    'endDate'
                ])
                return !!values.name && !!values.startDate && !!values.endDate
            }
        }
        // {
        //     title: 'Form',
        //     component: (props) => (
        //         <StepThree form={form} getError={getError} {...props}/>
        //     ),
        //     validation: () => true
        // }
    ]

    const handleClose = () => {
        dispatch({ type: 'HIDE_MODAL_EVENT' })
    }

    const validateMessages = {
        required: '${label} é obrigatório!'
    }

    return (
        <ModalStep
            title={id ? 'Editar Evento' : 'Novo Evento'}
            visible={id ? true : hasLimit}
            onClose={handleClose}
            onFinish={formSave}
            steps={id ? steps : [...stepType, ...steps]}
            saveLoading={saveLoading}
            buttons={{
                finish: id ? 'Salvar' : 'Criar Evento',
                loading: id ? 'Salvando' : 'Criando Evento'
            }}
            formProps={{
                form,
                layout: 'vertical',
                validateMessages
            }}
        />
    )
}

export default EventModal
