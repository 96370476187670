import styled from 'styled-components'

export const Container = styled.div`
    padding: 30px;
    position: relative;
    min-height: calc(100vh - 80px);
    .ant-page-header {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 0;

        span.ant-page-header-heading-extra {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
    .ant-form {
        display: contents;
    }
`
