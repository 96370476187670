import { gql } from '@apollo/client'

export const IMPORT_STREAMING_SETTINGS = gql`
    mutation ImportStreamingSettings(
        $sourceEventId: ObjectID!
        $targetEventId: ObjectID!
    ) {
        importStreamingSettings(
            sourceEventId: $sourceEventId
            targetEventId: $targetEventId
        ) {
            id
        }
    }
`