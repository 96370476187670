import React from 'react'
import { Button, Collapse, Form, Tooltip } from 'antd'

import {
    DeleteOutlined,
    PlusOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'

const { Panel } = Collapse

const Galery = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>
                    Imagens{' '}
                    <Tooltip
                        title={`Tamanho de imagem recomendado: 345x460 ou
                        proporção 3:4.`}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Title>

                <Form.List name={[props.name, 'configs', 'galery', 'items']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Slide'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Form.Item
                                                name={[name, 'image', 'src']}
                                                label="Imagem"
                                            >
                                                <Uploader />
                                            </Form.Item>
                                        </Panel>
                                    )
                                )}
                            </Collapse>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid()
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar slide
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name={[props.name, 'configs', 'arrows', 'textColor']}
                    label="Cor das setas de navegação"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Galery
