import { VALIDATE_MESSAGES } from '@cms/events/pages/Forms/constants/formMessages'
import { Form, Input, Row, Col } from 'antd'
import React, { useState, useMemo } from 'react'
import * as Types from '../../connectores/formFields'
import * as _connectors from '../../connectores/configs'
import SelectType from '../../CardItem/SelectType'
import BeforeContent from './BeforeContent'
import { useHistory } from 'react-router-dom'

const ContentModal = (props) => {
    const { integration, form } = props
    const { category } = integration

    const history = useHistory()

    const types = useMemo(() => {
        return Object.keys(_connectors).filter(
            (key) => _connectors[key].category === category
        )
    }, [category])

    const handleChangeType = (value) => {
        if (value.includes('Zoho')) {
            return history.push(`steps/${value}?integration=${value}`)
        }

        form.setFieldsValue({
            type: value,
            name: _connectors[value].title,
            config: {}
        })

        if (_connectors[value].editable === false) {
            form.submit()
        }
    }

    return (
        <Form
            form={form}
            validateMessages={VALIDATE_MESSAGES}
            layout="vertical"
            name={'modal'}
            initialValues={{
                ...integration
            }}
        >
            <Form.Item name={'type'} noStyle>
                <Input type="hidden" />
            </Form.Item>

            <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                    prevValues.type !== currentValues.type
                }
            >
                {({ getFieldValue }) => {
                    const _type = getFieldValue('type')

                    const TypeComponent = Types[_type]
                    const config = _connectors[_type]

                    if (!TypeComponent) {
                        return (
                            <Row gutter={[20, 20]}>
                                {types.map((type) => (
                                    <Col span={12} key={type}>
                                        <SelectType
                                            type={type}
                                            onClick={() =>
                                                handleChangeType(type)
                                            }
                                        />
                                    </Col>
                                ))}
                            </Row>
                        )
                    }

                    return (
                        <>
                            <BeforeContent integration={integration} />
                            <TypeComponent config={config} form={form} />
                        </>
                    )
                }}
            </Form.Item>
        </Form>
    )
}

export default ContentModal

