import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_BRAND = gql`
    mutation CreateBrand(
        $event: ObjectID!
        $name: String
        $logo: MediaInput
        $image: MediaInput
        $archive: MediaInput
        $description: String
        $contact: String
        $aboutUs: String
        $email: String
        $site: String
        $social: [BrandSocialInput]
        $resources: [BrandResourceInput]
        $actions: [BrandActionInput]
        $plan: ObjectID
        $talkStand: BrandTalkStandInput
        $featured: Boolean
        $priority: Int
        $tags: [String]
        $sponsor: ObjectID
        $institutionalVideos: [InstitutionalVideoInput]
        $chiefs: [ObjectID]
        $page: PageBrandInputs
    ) {
        createBrand(
            input: {
                event: $event
                name: $name
                logo: $logo
                image: $image
                archive: $archive
                description: $description
                contact: $contact
                aboutUs: $aboutUs
                email: $email
                site: $site
                social: $social
                resources: $resources
                actions: $actions
                plan: $plan
                talkStand: $talkStand
                featured: $featured
                priority: $priority
                tags: $tags
                sponsor: $sponsor
                institutionalVideos: $institutionalVideos
                chiefs: $chiefs
                page: $page
            }
        ) {
            ...BrandData
        }
    }

    ${fragments.BrandData}
`

export const UPDATE_BRAND = gql`
    mutation UpdateBrand(
        $id: ObjectID!
        $event: ObjectID!
        $name: String
        $logo: MediaInput
        $image: MediaInput
        $archive: MediaInput
        $description: String
        $contact: String
        $aboutUs: String
        $email: String
        $site: String
        $social: [BrandSocialInput]
        $resources: [BrandResourceInput]
        $actions: [BrandActionInput]
        $plan: ObjectID
        $talkStand: BrandTalkStandInput
        $featured: Boolean
        $priority: Int
        $tags: [String]
        $sponsor: ObjectID
        $institutionalVideos: [InstitutionalVideoInput]
        $chiefs: [ObjectID]
        $page: PageBrandInputs
    ) {
        updateBrand(
            id: $id
            input: {
                event: $event
                name: $name
                logo: $logo
                image: $image
                archive: $archive
                description: $description
                contact: $contact
                aboutUs: $aboutUs
                email: $email
                site: $site
                social: $social
                resources: $resources
                actions: $actions
                plan: $plan
                talkStand: $talkStand
                featured: $featured
                priority: $priority
                tags: $tags
                sponsor: $sponsor
                institutionalVideos: $institutionalVideos
                chiefs: $chiefs
                page: $page
            }
        ) {
            ...BrandData
        }
    }

    ${fragments.BrandData}
`

export const UPDATE_BRAND_DRAFT = gql`
    mutation UpdateBrandDraft(
        $id: ObjectID!
        $logo: MediaInput
        $image: MediaInput
        $archive: MediaInput
        $description: String
        $contact: String
        $aboutUs: String
        $email: String
        $site: String
        $social: [BrandSocialInput]
        $resources: [BrandResourceInput]
        $actions: [BrandActionInput]
        $talkStand: BrandTalkStandInput
        $featured: Boolean
        $tags: [String]
        $institutionalVideos: [InstitutionalVideoInput]
        $chiefs: [ObjectID]
    ) {
        updateBrandDraft(
            id: $id
            input: {
                logo: $logo
                image: $image
                archive: $archive
                description: $description
                contact: $contact
                aboutUs: $aboutUs
                email: $email
                site: $site
                social: $social
                resources: $resources
                actions: $actions
                talkStand: $talkStand
                featured: $featured
                tags: $tags
                institutionalVideos: $institutionalVideos
                chiefs: $chiefs
            }
        ) {
            id
            draft {
                status
                updatedAt
                data {
                    name
                }
            }
        }
    }
`

export const DELETE_BRAND = gql`
    mutation DeleteBrand($id: ObjectID!) {
        deleteBrand(id: $id) {
            id
            success
        }
    }
`

export const APPROVE_BRAND_DRAFT = gql`
    mutation ApproveBrandDraft($id: ObjectID!) {
        approveBrandDraft(id: $id) {
            draft {
                status
            }
        }
    }
`

export const REJECT_BRAND_DRAFT = gql`
    mutation RejectBrandDraft($id: ObjectID!) {
        rejectBrandDraft(id: $id) {
            draft {
                status
            }
        }
    }
`
