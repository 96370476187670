import React from 'react'
import {
    RiDashboardFill,
    RiDashboardLine,
    RiEditBoxFill,
    RiEditBoxLine,
    RiStackLine,
    RiStackFill,
    RiStore2Line,
    RiStore2Fill,
    RiGiftLine,
    RiGiftFill,
    RiGroupFill,
    RiGroupLine,
    RiMegaphoneLine,
    RiMegaphoneFill,
    RiPieChartFill,
    RiPieChartLine,
    RiDraftFill,
    RiDraftLine,
    RiPagesFill,
    RiPagesLine,
    RiVidiconFill,
    RiVidiconLine,
    RiGitMergeFill,
    RiGitMergeLine,
    RiQuestionFill,
    RiQuestionLine,
    RiSettings3Fill,
    RiSettings3Line,
    RiLayoutGridFill,
    RiLayoutGridLine,
    RiChat1Fill,
    RiChat1Line,
    RiStarFill,
    RiStarLine,
    RiUserVoiceFill,
    RiUserVoiceLine,
    RiUser2Fill,
    RiUser2Line,
    RiScan2Fill,
    RiScan2Line,
    RiBankCard2Line,
    RiBankCard2Fill,
    RiProfileFill,
    RiProfileLine,
    RiEdit2Fill,
    RiEdit2Line,
    RiMenuSearchFill,
    RiMenuSearchLine,
    RiDeleteBinFill,
    RiDeleteBinLine,
    RiHomeFill,
    RiHomeLine,
    RiCoupon2Line,
    RiCoupon2Fill,
    RiGraduationCapFill,
    RiGraduationCapLine
} from '@remixicon/react'

export const sidebarIcons = {
    dashbord: {
        filled: <RiDashboardFill />,
        outline: <RiDashboardLine />
    },
    content: {
        filled: <RiEditBoxFill />,
        outline: <RiEditBoxLine />
    },
    collections: {
        outline: <RiStackLine />,
        filled: <RiStackFill />
    },
    store: {
        outline: <RiStore2Line />,
        filled: <RiStore2Fill />
    },
    gift: {
        outline: <RiGiftLine />,
        filled: <RiGiftFill />
    },
    registrations: {
        filled: <RiGroupFill />,
        outline: <RiGroupLine />
    },
    communication: {
        outline: <RiMegaphoneLine />,
        filled: <RiMegaphoneFill />
    },
    reports: {
        filled: <RiPieChartFill />,
        outline: <RiPieChartLine />
    },
    forms: {
        filled: <RiDraftFill />,
        outline: <RiDraftLine />
    },
    pageBuilder: {
        filled: <RiPagesFill />,
        outline: <RiPagesLine />
    },
    streaming: {
        filled: <RiVidiconFill />,
        outline: <RiVidiconLine />
    },
    integrations: {
        filled: <RiGitMergeFill />,
        outline: <RiGitMergeLine />
    },
    faq: {
        filled: <RiQuestionFill />,
        outline: <RiQuestionLine />
    },
    settings: {
        filled: <RiSettings3Fill />,
        outline: <RiSettings3Line />
    },
    events: {
        filled: <RiLayoutGridFill />,
        outline: <RiLayoutGridLine />
    },
    chat: {
        filled: <RiChat1Fill />,
        outline: <RiChat1Line />
    },
    stars: {
        filled: <RiStarFill />,
        outline: <RiStarLine />
    },
    tickets: {
        filled: <RiCoupon2Fill />,
        outline: <RiCoupon2Line />
    },
    speakers: {
        filled: <RiUserVoiceFill />,
        outline: <RiUserVoiceLine />
    },
    users: {
        filled: <RiUser2Fill />,
        outline: <RiUser2Line />
    },
    trackers: {
        filled: <RiScan2Fill />,
        outline: <RiScan2Line />
    },
    payments: {
        filled: <RiBankCard2Fill />,
        outline: <RiBankCard2Line />
    },
    courses: {
        filled: <RiGraduationCapFill />,
        outline: <RiGraduationCapLine />
    },
    profile: {
        filled: <RiProfileFill />,
        outline: <RiProfileLine />
    },
    read: {
        filled: <RiMenuSearchFill />,
        outline: <RiMenuSearchLine />
    },
    write: {
        filled: <RiEdit2Fill />,
        outline: <RiEdit2Line />
    },
    delete: {
        filled: <RiDeleteBinFill />,
        outline: <RiDeleteBinLine />
    }
}

export const headerMenuIcons = {
    help: {
        filled: <RiQuestionFill />,
        outline: <RiQuestionLine />
    },
    home: {
        filled: <RiHomeFill />,
        outline: <RiHomeLine />
    }
}
