import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Layout, Row, Col, Form, Input, Typography, Alert, Button } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'

import { LOGIN } from '@cms/core/graphql/mutations/auth'
import { login } from '@cms/core/utils/auth'
import Logo from '@cms/core/components/Logo'

import { Container, Box } from './styles'

const background = require('./bg.png')
const { Title, Paragraph } = Typography

export default function SignIn({ history }) {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [authentication, { loading, error }] = useMutation(LOGIN)

    async function handleSingIn(e) {
        try {
            const variables = { email: username, password: password }
            const { data } = await authentication({ variables })
            login(data.login)
            history.push('/eventos')
        } catch (e) {}
    }

    const ErrorMessage = () => {
        return (
            <Paragraph>
                <Alert description={error.message} type="error" showIcon />
            </Paragraph>
        )
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Container>
                <Row style={{ height: '100vh' }} align={'middle'}>
                    <Col
                        lg={12}
                        sm={24}
                        xs={24}
                        md={24}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box>
                            {/* TODO: Mover svg para arquivo separado? */}
                            <Logo />
                            <h2>PLATFORM</h2>

                            <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={handleSingIn}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Insira seu usuário'
                                        }
                                    ]}
                                >
                                    <Input
                                        size={'large'}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        suffix={
                                            <UserOutlined className="site-form-item-icon" />
                                        }
                                        placeholder="Username"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Insira sua senha!'
                                        }
                                    ]}
                                >
                                    <Input
                                        size={'large'}
                                        suffix={
                                            <LockOutlined className="site-form-item-icon" />
                                        }
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                    />
                                </Form.Item>
                                <Link to="/recuperar-senha">
                                    Esqueci minha senha
                                </Link>
                                {error ? <ErrorMessage /> : null}
                                <Form.Item>
                                    <Button
                                        disabled={loading}
                                        htmlType="submit"
                                        className="login-form-button"
                                    >
                                        Entrar
                                    </Button>
                                </Form.Item>
                                {/* TODO: Link recuperar senha */}
                            </Form>
                        </Box>
                    </Col>
                    <Col lg={12} sm={0} xs={0} md={0}>
                        <div className="image-wrapper">
                            <Title
                                level={1}
                                style={{ fontWeight: 500, fontSize: '36px' }}
                            >
                                Faça a <strong>Transformação Digital</strong>{' '}
                                acontecer
                            </Title>
                            <img
                                src={background}
                                style={{ maxWidth: '100%' }}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

