import React from 'react'
import { Row, Typography } from 'antd'
import { Wrapper, IconWrapper, Reaction, WrapperReactions } from './styles'
import { LikeOutlined } from '@ant-design/icons'
import {
    IconAngry,
    IconLaughing,
    IconLike,
    IconLove,
    IconSad,
    IconSurprised
} from '@cms/core/assets/icons'
import DashboardCard from '../DashboardCard'
const { Text } = Typography

const Reactions = ({ reactions }) => {
    const handleRenderReaction = (reaction) => {
        switch (reaction) {
            case 'loves':
                if (reactions.loves > 0) {
                    return (
                        <Reaction key="loves">
                            <IconLove />
                            <Text>{reactions.loves}</Text>
                        </Reaction>
                    )
                }
            case 'likes':
                if (reactions.likes > 0) {
                    return (
                        <Reaction key="like">
                            <IconLike />
                            <Text>{reactions.likes}</Text>
                        </Reaction>
                    )
                }
            case 'laughing':
                if (reactions.laughing > 0) {
                    return (
                        <Reaction key="laughing">
                            <IconLaughing />
                            <Text>{reactions.laughing}</Text>
                        </Reaction>
                    )
                }
            case 'sad':
                if (reactions.sad > 0) {
                    return (
                        <Reaction key="sad">
                            <IconSad />
                            <Text>{reactions.sad}</Text>
                        </Reaction>
                    )
                }
            case 'surprise':
                if (reactions.surprise > 0) {
                    return (
                        <Reaction key="surprised">
                            <IconSurprised />
                            <Text>{reactions.surprise}</Text>
                        </Reaction>
                    )
                }
            case 'angry':
                if (reactions.angry > 0) {
                    return (
                        <Reaction key="angry">
                            <IconAngry />
                            <Text>{reactions.angry}</Text>
                        </Reaction>
                    )
                }
        }
    }

    const renderReactions = () => {
        if (
            reactions.loves <= 0 &&
            reactions.likes <= 0 &&
            reactions.sad <= 0 &&
            reactions.surprise <= 0 &&
            reactions.laughing <= 0
        ) {
            return <Text>Sem reações até o momento</Text>
        } else {
            return Object.keys(reactions).map((reaction) =>
                handleRenderReaction(reaction)
            )
        }
    }

    return (
        <DashboardCard>
            <Row style={{ marginBottom: '15px' }} align="middle">
                <IconWrapper>
                    <LikeOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Reações</Text>
            </Row>
            <Row>
                <WrapperReactions>
                    {renderReactions(reactions)}
                </WrapperReactions>
            </Row>
        </DashboardCard>
    )
}

export default Reactions

