import React, { useEffect, useState } from 'react'
import { Button, Modal, Row } from 'antd'
import Text from 'antd/es/typography/Text'
import { GET_EVENTS_MAIN } from '@cms/events/graphql/queries/event'
import { useQuery } from '@apollo/client'
import { ModalContent, EventtItem, Footer } from '../styles'
import { CheckCircleFilled } from '@ant-design/icons'
import { useParams } from 'react-router-dom'

const ImportModal = ({ onCancel, onImport }) => {
    const { eventId } = useParams()

    const [events, setEvents] = useState([])
    const [selected, setSelected] = useState(null)

    const { data } = useQuery(GET_EVENTS_MAIN, {
        variables: { first: 9999 },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        const eventsTemp = []
        data?.events?.edges?.forEach(({ node }) => {
            node &&
                node.id !== eventId &&
                eventsTemp.push({
                    id: node.id,
                    name: node.name,
                    logo: node.logo?.url
                })
        })
        setEvents(eventsTemp)
    }, [data])

    if (!data || !events || events.length <= 0) {
        return null
    }

    return (
        <>
            <Modal
                open={true}
                footer={null}
                width={450}
                closable
                onCancel={onCancel}
            >
                <div style={{ width: '100%' }}>
                    <Text strong>
                        Importar configuações e estilos de pagina do evento
                    </Text>
                    <ModalContent>
                        {events &&
                            events?.map((event) => (
                                <EventtItem
                                    key={event.id}
                                    onClick={() => setSelected(event)}
                                    style={{
                                        background:
                                            selected?.id === event.id
                                                ? '#313137c4'
                                                : '#424246'
                                    }}
                                >
                                    <Text strong ellipsis title={event.name}>
                                        {event.name}
                                    </Text>
                                    {event.logo && <img src={event.logo} />}
                                </EventtItem>
                            ))}
                    </ModalContent>
                    <Footer>
                        <Button
                            type="primary"
                            icon={<CheckCircleFilled />}
                            onClick={() => onImport(selected)}
                        >
                            Importar
                        </Button>
                        <Button type="ghost" onClick={onCancel}>
                            Fechar
                        </Button>
                    </Footer>
                </div>
            </Modal>
        </>
    )
}

export default ImportModal
