import styled from 'styled-components'
import { Row, Button as AntButton } from 'antd'
import { COLORS } from '@cms/core/themes';

export const Footer = styled(Row)`
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;
    padding: 10px 20px;
    background-color: ${COLORS.bgLight};
    border-top: 1px solid ${COLORS.bgDark};
    border-radius: 0px 0px 10px 10px;
`

export const Button = styled(AntButton)``

export const Wrapper = styled.div`
    display: contents;

    .ant-affix {
        .ant-row {
            background-color: ${COLORS.bgLight};
        }
    }
`
