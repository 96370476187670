import React from 'react'
import { Affix } from 'antd'
import { Footer, Button, Wrapper } from './styles'

import { CheckCircleFilled } from '@ant-design/icons'

const FormFooter = () => {
    return (
        <Wrapper>
            <Affix offsetBottom={0}>
                <Footer className="affix-form-footer">
                    <Button
                        htmlType="submit"
                        type="primary"
                        // loading={loading}
                        icon={<CheckCircleFilled />}
                    >
                        {/* Salvar e copiar código */}
                        Salvar
                    </Button>
                </Footer>
            </Affix>
        </Wrapper>
    )
}

export default FormFooter
