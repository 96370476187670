import Owner from './Owner'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Owners = {
    Owner: {
        Component: Owner,
        name: 'Donos do evento',
        category: 'Donos do evento',
        thumb: require('./owners.png'),
        columns: '2',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    textColor: getColorContrast(colors, 'primary'),
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                box: {
                    bgColor: 'white',
                    textColor: 'black',
                    logo: { color: 'black' },
                    title: {
                        text: 'Sobre o realizador'
                    }
                },
                headline: {
                    text: `O ${event.name} é realizado por`,
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
