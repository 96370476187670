import styled, { css } from 'styled-components'

const deskWidth = 1240
const deskHeight = 1440

type Props = {
    height?: number
    previewWidth?: number
    previewHeight?: number
}

export const Wrapper = styled.div<Props>`
    ${({ height = 0, previewWidth, previewHeight }) => css`
        width: ${previewWidth}px;
        height: ${(height / deskWidth) * previewWidth}px;
        max-height: ${previewHeight}px;
        padding: 0;
        margin: 0 auto;
        overflow: hidden;

        iframe {
            width: ${deskWidth}px;
            height: ${height > (previewHeight / previewWidth) * deskWidth
                ? (previewHeight / previewWidth) * deskWidth
                : height}px;
            border: 0;
            transform: scale(${previewWidth / deskWidth});
            transform-origin: 0 0;
        }
    `}
`
