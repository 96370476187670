import React, { FC } from 'react'

import { Form } from 'antd'
import SelectEvent from './SelectEvent'
import SelectTaks from './SelectTalks'
import SelectCategories from './SelectCategories'

import { TDCard } from '@cms/core/components/TDCards'
import { useHistory } from 'react-router-dom'
import TDSteps from '@cms/core/components/TDSteps'
import { IMPORT_WATCH_CONTENT } from '@cms/watch/graphql/mutations/watchContent'
import { useApolloClient } from '@apollo/client'
import { TDStepItem } from '@cms/core/components/TDSteps/type'

const steps: TDStepItem[] = [
    {
        title: 'Escolher evento',
        component: <SelectEvent />
    },
    {
        title: 'Selecionar palestras',
        component: <SelectTaks />
    },
    {
        title: 'Selecionar categorias',
        component: <SelectCategories />
    }
]

/**
 * Importação de conteúdos para o TD Watch
 */
const ContentsImport: FC = () => {
    const client = useApolloClient()
    const [form] = Form.useForm()
    const history = useHistory()

    const onCheck = async () => {
        try {
            await form.validateFields()
        } catch (error) {
            return false
        }
        return true
    }

    const onFinish = async () => {
        if (await onCheck()) {
            await client.mutate({
                mutation: IMPORT_WATCH_CONTENT,
                variables: {
                    categoryId: form.getFieldValue('category'),
                    talks: form.getFieldValue('talks')
                }
            })
            history.push('/conteudo/videos')
            return true
        }

        return false
    }

    return (
        <TDCard>
            <Form form={form}>
                <TDSteps steps={steps} onNext={onCheck} onFinish={onFinish} />
            </Form>
        </TDCard>
    )
}

export default ContentsImport
