import styled from 'styled-components'

export const WrapperLines = styled.div`
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;

    .ant-progress-text {
        color: #fff;
    }
`

export const StyledRow = styled.div`
    display: flex;
    align-items: flex-end;

    margin-bottom: 10px;
    margin-top: 0
`

export const TotalNumber = styled.span`
    font-size: 2em;
    font-weight: bold;
`
export const Percent = styled.span`
    font-size: 0.8em;

    margin-bottom: 10px;
`
