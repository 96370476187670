import React, { useMemo } from 'react'
import { pathToRegexp } from 'path-to-regexp'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'
import { isAuthenticated } from '@cms/core/utils/auth'

import {
    selectModulesKey,
    selectModulesPath,
    selectPlanModuleskey,
    findModule
} from '@cms/core/components/Sidebar/common'

import Upgrade from '@cms/core/components/Upgrade'
import { AllAppsModules } from '../Apps'
import { ModulePermissionProvider } from '../components/Providers/ModulePermissionProvider'

const checkPaths = (path, paths) => {
    const _paths = paths.map((o) => pathToRegexp(o))
    return _paths.some((o) => o.test(path))
}

const PrivateRoute = ({
    component: Component,
    checkModulePath = false,
    checkModule = null,
    path,
    ...rest
}) => {
    const paths = useSelector((state) =>
        selectModulesPath(state, AllAppsModules)
    )

    const currentModule = rest?.currentModule ?? null

    const modules = useSelector((state) =>
        selectModulesKey(state, AllAppsModules)
    )

    const planModules = useSelector((state) =>
        selectPlanModuleskey(state, AllAppsModules)
    )

    let allowed = useMemo(() => {
        if (!checkModulePath) return true
        if (Array.isArray(checkModulePath || path)) {
            return path.some((p) => checkPaths(p, paths))
        }
        if (typeof checkModulePath === 'string') {
            return checkPaths(checkModulePath, paths)
        }
        return checkPaths(path, paths)
    }, [checkModulePath, path, paths?.length])

    if (checkModule && !modules.includes(checkModule)) {
        allowed = false
    }

    const menu = useMemo(() => {
        if (checkModule) {
            return findModule(checkModule, AllAppsModules, { key: 'module' })
        }

        if (checkModulePath === true) {
            if (Array.isArray(path)) {
                return path
                    .map((o) => findModule(o, AllAppsModules, { key: 'path' }))
                    .filter((o) => o)[0]
            }
            return findModule(path, AllAppsModules, { key: 'path' })
        }

        if (typeof checkModulePath === 'string') {
            return findModule(checkModulePath, AllAppsModules, { key: 'path' })
        }

        if (Array.isArray(checkModulePath)) {
            return [checkModulePath]
                .map((o) => findModule(o, AllAppsModules, { key: 'path' }))
                .filter((o) => o)[0]
        }

        return null
    }, [checkModule, checkModulePath, path, AllAppsModules])

    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                if (!allowed) {
                    return <Redirect to={'/404'} />
                }

                if (menu && !planModules.includes(menu.module)) {
                    return <Upgrade />
                }

                return isAuthenticated() ? (
                    <ModulePermissionProvider
                        module={currentModule ?? menu?.module}
                    >
                        <Component {...props} />
                    </ModulePermissionProvider>
                ) : (
                    <Redirect
                        to={{ pathname: '/', state: { from: props.location } }}
                    />
                )
            }}
        />
    )
}

export default PrivateRoute
