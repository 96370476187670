import styled from 'styled-components'
import { Radio as AntRadio } from 'antd'

const { Group } = AntRadio

export const Badge = styled.div`
    height: 30px;
    width: 30px;
    background: ${({ background }) => background};
    border-radius: 100px;
    border: 2px solid #fff;
    position: relative;

    &.remove-color {
        .anticon {
            position: absolute;
            top: -3px;
            left: -3px;
        }
        svg {
            width: 32px;
            height: 32px;
            fill: #d34141;
        }
    }
`

export const Checkbox = styled(Group)`
    .ant-radio {
        display: none;
    }

    .ant-radio-wrapper {
        margin-right: 0px;
    }

    .ant-radio-wrapper-checked {
        ${Badge} {
            /* outline: 2px solid #7730f6; */
            ::before {
                background: none;
                opacity: 0.7;
                border: 2px solid #fff;
                content: '';
                display: block;
                position: absolute;
                pointer-events: none;
                border-radius: 100%;
                height: 32px;
                width: 32px;
                transform: translate(-4.7px, -4.7px);
            }
        }
    }
`
