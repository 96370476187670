import { gql } from '@apollo/client'
import { WatchCourseData } from '../fragments'

export const CREATE_WATCH_COURSE = gql`
    mutation CreateWatchCourse(
        $title: String!
        $description: String
        $image: MediaInput
        $modules: [WatchCourseModuleInput]
    ) {
        createWatchCourse(
            input: {
                title: $title
                description: $description
                image: $image
                modules: $modules
            }
        ) {
            ...WatchCourseData
        }
    }

    ${WatchCourseData}
`

export const UPDATE_WATCH_COURSE = gql`
    mutation UpdateWatchCourse(
        $id: ObjectID!
        $title: String!
        $description: String
        $image: MediaInput
        $modules: [WatchCourseModuleInput]
    ) {
        updateWatchCourse(
            id: $id
            input: {
                title: $title
                description: $description
                image: $image
                modules: $modules
            }
        ) {
            ...WatchCourseData
        }
    }

    ${WatchCourseData}
`

export const DELETE_WATCH_COURSE = gql`
    mutation DeleteWatchCourse($id: ObjectID!) {
        deleteWatchCourse(id: $id) {
            id
            success
        }
    }
`
