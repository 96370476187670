import React from 'react'
import { Form, Input, Button, Collapse, Radio } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../components'
import { Wrapper, Title, TitleWrapper } from '../../styles'
import {
    DeleteOutlined,
    PlusOutlined,
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined
} from '@ant-design/icons'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'
const { Panel } = Collapse
const Topic = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Tópicos</Title>
                <Form.Item
                    name={[props.name, 'configs', 'topics', 'columns']}
                    label="Quantidade de colunas"
                >
                    <Radio.Group defaultValue={8}>
                        <Radio.Button value={12}>2 colunas</Radio.Button>
                        <Radio.Button value={8}>3 colunas</Radio.Button>
                        <Radio.Button value={6}>4 colunas</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.List name={[props.name, 'configs', 'topics', 'items']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Topico'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Form.Item
                                                name={[name, 'icon', 'src']}
                                                label="Icone do tópico"
                                            >
                                                <Uploader />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title', 'text']}
                                                label="Título"
                                            >
                                                <RichTextEditor placeholder="Titulo" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[
                                                    name,
                                                    'subtitle',
                                                    'text'
                                                ]}
                                                label="Subtítulo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>
                                        </Panel>
                                    )
                                )}
                            </Collapse>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid()
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar Tópico
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name={[props.name, 'configs', 'topics', 'bgColor']}
                    label="Cor do bloco"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default Topic
