import styled from 'styled-components'

export const HeaderProfile = styled.div`
    border-radius: 3.2vh;
    padding: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .widthFull {
        width: 100%;
    }

    .infoJob {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 10px;
        flex: 1;
        height: 2vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        #userName {
            font-size: 2em;
            margin-bottom: 5px;
        }

        .officeName {
            font-weight: 600;
            color: #ececec;
            margin-right: 3px;
        }

        .Left {
            margin-left: 2vw;
        }
    }
`

