import React from 'react'
import { Form, Input, Divider, Radio, Select, Tag } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig
} from '../../../components'
import { Wrapper, Title } from '../../../styles'

import {
    AlignLeftOutlined,
    AlignCenterOutlined,
    AlignRightOutlined
} from '@ant-design/icons'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { GET_TALK_TAGS } from '@cms/events/graphql/queries/talk'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

const Schedule = (props) => {
    const { eventId } = useParams()
    const { data: TagsData } = useQuery(GET_TALK_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Text"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Regras de exibição</Title>

                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Exibir apenas palestras com as tags:"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.talkTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Carrossel de datas</Title>

                <Form.Item
                    name={[props.name, 'configs', 'alignment']}
                    label="Alinhamento das datas"
                >
                    <Radio.Group
                        initialValue={'start'}
                        style={{ marginBottom: 8 }}
                    >
                        <Radio.Button
                            value="start"
                            style={{
                                borderRight: '1px solid rgb(255 255 255 / 10%)'
                            }}
                        >
                            <AlignLeftOutlined />
                        </Radio.Button>

                        <Radio.Button value="center">
                            <AlignCenterOutlined />
                        </Radio.Button>

                        <Radio.Button value="end">
                            <AlignRightOutlined />
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <ButtonConfig
                    enableText={false}
                    title={'Estilo dos botões'}
                    enableId={false}
                    enableFont={false}
                    enableActiveStyling={true}
                    fieldPath={[
                        props.name,
                        'configs',
                        'dateCarousel',
                        'button'
                    ]}
                />

                <Wrapper>
                    <Title level={4}>Setas de navegação</Title>

                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'dateCarousel',
                            'arrowColor',
                            'textColor'
                        ]}
                        label="Cor das setas"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Item da agenda</Title>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'bgColor']}
                    label="Cor do fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'title', 'textColor']}
                    label="Cor do título"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Divider orientation="left">Tempo</Divider>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'time', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'time', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Divider orientation="left">Botão adicionar na agenda</Divider>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'box',
                        'addToCalendar',
                        'bgColor'
                    ]}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'box',
                        'addToCalendar',
                        'textColor'
                    ]}
                    label="Cor do icone"
                >
                    <ColorsBadges />
                </Form.Item>

                <Divider orientation="left">Slide de palestrantes</Divider>
                <Form.Item
                    name={[props.name, 'configs', 'speakerCard', 'bgColor']}
                    label="Cor do fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'speakerCard',
                        'title',
                        'textColor'
                    ]}
                    label="Cor do título"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'speakerCard',
                        'text',
                        'textColor'
                    ]}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Wrapper>
                    <Title level={4}>Setas de navegação</Title>

                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'speakerCarousel',
                            'arrowColor',
                            'textColor'
                        ]}
                        label="Cor das setas"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>
            </Wrapper>
        </>
    )
}

export default Schedule
