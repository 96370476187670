import React, { useState, useEffect, useRef } from 'react'
import { Form, Select, Typography, Row, Tag, Input } from 'antd'

import { Uploader, PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'

const { Option } = Select
const { Title } = Typography

const General = (props) => {
    const [form] = Form.useForm()
    const { site, colors } = usePageBuilderContext()

    let inputRef = null
    let inputEditRef = null

    useEffect(() => {
        form.setFieldsValue(site.seo)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24}>
            <ColForm md={12} sm={24}>
                <Form
                    name="seo"
                    form={form}
                    // onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Form.Item label="Título" name={'title'}>
                        <Input />
                    </Form.Item>
                    <Form.Item label="Descrição" name={'description'}>
                        <Input.TextArea />
                    </Form.Item>

                    <Form.Item
                        name={['image']}
                        label="Imagem de compartilhamento (1200x630)"
                    >
                        <Uploader
                            options={{
                                optimize: {
                                    resize: {
                                        width: 1200,
                                        height: 630
                                    },
                                    quality: 90
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Palavras chaves" name={['keywords']}>
                        <Select
                            mode="tags"
                            tagRender={({ label, ...props }) => (
                                <Tag {...props} color="#7F44FF">
                                    {label}
                                </Tag>
                            )}
                            style={{ width: '100%' }}
                            placeholder="Selecione uma palavra chave existente ou crie uma nova."
                        ></Select>
                    </Form.Item>

                    <PanelFooter />
                </Form>
            </ColForm>
        </Row>
    )
}

export default General
