import React, { useState, useEffect } from 'react'
import { Progress, message, Row } from 'antd'
import {
    LoadingOutlined,
    PlusOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import { Upload } from './styles'

import { useQuery, useMutation, useApolloClient } from '@apollo/client'
import { UPLOAD_MEDIA } from '@cms/events/graphql/mutations/media'

function beforeUpload(file) {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
        message.error('Arquivo de imagem inválido!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Imagem maior que 2MB!')
    }
    return isImage && isLt2M
}

const Uploader = ({ value, onChange, options }) => {
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(false)
    const [_props, setProps] = useState()
    const [uploadMedia] = useMutation(UPLOAD_MEDIA)
    const client = useApolloClient()

    const formatFile = (file) => {
        if (file.response) {
            file.uid = file.response.id
            file.url = file.response.url
            file.name = file.response.filename
        }

        return {
            uid: file.uid,
            url: file.url,
            type: file.type,
            name: file.name,
            response: file.response,
            status: file.status
        }
    }

    const handleChange = (info) => {
        let _fileList = [...info.fileList]

        if (info.file.status === 'done') {
            _fileList = _fileList.slice(-1)
            _fileList = _fileList.map(formatFile)
            onChange(_fileList[0].response.url)
            message.success(
                `${_fileList[0].response.filename} carregado com sucesso`
            )
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} erro ao carregar.`)
        }

        setFileList(_fileList)
    }

    const handleRemove = () => {
        onChange(null)
    }

    const handleUpload = async ({ onSuccess, onError, file, onProgress }) => {
        setLoading(true)

        const result = await uploadMedia({
            variables: { file, options },
            context: {
                fetchOptions: {
                    useUpload: true,
                    onProgress: (e) => {
                        const percent = Math.floor((e.loaded / e.total) * 100)

                        setProgress(percent)
                        onProgress({ percent })
                    }
                }
            }
        })

        if (result.data && result.data.uploadMedia) {
            setLoading(false)
            setProgress(false)
            onSuccess(result.data.uploadMedia)
        } else {
            setLoading(false)
            setProgress(false)
            onError(result.errors[0].message)
        }
    }

    const uploadButton = (
        <div>
            {/* {loading ? <LoadingOutlined /> : } */}
            {progress ? (
                <Progress
                    type="circle"
                    percent={progress}
                    strokeColor={{
                        '0%': '#2BD069',
                        '100%': '#2BD069'
                    }}
                />
            ) : (
                <>
                    <PlusOutlined />
                    <div className="ant-upload-text">Upload</div>
                </>
            )}
        </div>
    )

    return (
        <>
            <Upload
                name="file"
                listType="picture-card"
                accept="image/*"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={handleUpload}
                onChange={handleChange}
                onRemove={handleRemove}
                beforeUpload={beforeUpload}
                style={{ width: '100%' }}
            >
                {value && !loading ? (
                    <img
                        className="banner"
                        src={value}
                        alt="avatar"
                        style={{ height: '100%' }}
                    />
                ) : (
                    uploadButton
                )}
            </Upload>
            {value && !loading ? (
                <Row justify="end" style={{ padding: '10px 0' }}>
                    <DeleteOutlined onClick={handleRemove} />
                </Row>
            ) : null}
        </>
    )
}

export default Uploader
