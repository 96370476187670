import React from 'react'

import ConversionsRoutes from './routes'

const ConversionsIntegrations = () => {
    return (
        <>
            <ConversionsRoutes />
        </>
    )
}

export default ConversionsIntegrations

