import { ContentByCategory } from './ContentByCategory'

export const ContentByCategorySections = {
    contentByCategory: {
        Component: ContentByCategory,
        name: 'contentByCategory',
        category: 'Conteúdo',
        label: 'Conteúdo por categoria',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-BR': 'Unindo todas as comunidades em um único lugar.',
                'pt-PT': 'Unindo todas as comunidades num único local.',
                'en-US': 'Bringing all communities together in one place.',
                'es-ES': 'Uniendo a todas las comunidades en un solo lugar.'
            },
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'categories',
                        type: '$in',
                        value: ''
                    }
                ]
            }
        }
    }
}
