import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import { Col, Form, Input, Row } from 'antd'

import React from 'react'

const StepInformation = () => {
    return (
        <Row>
            <Col span={12}>
                <Form.Item
                    name="title"
                    label="Título"
                    rules={[
                        {
                            required: true,
                            message: 'Informe o título do curso'
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Descrição" name="description">
                    <Input.TextArea />
                </Form.Item>

                <Form.Item label="Imagem (900 x 500)" name="image">
                    <MediaUploader
                        options={{
                            optimize: {
                                resize: {
                                    width: 900,
                                    height: 500
                                },
                                quality: 80
                            }
                        }}
                        type="image"
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

export default StepInformation
