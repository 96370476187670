import React from 'react'
import { Tag, Row, Space, Dropdown, Menu } from 'antd'

import { useParams, Link } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import TalksModal from '../Talks/TalksModal'
import TalkControlPanel from '../Talks/TalkControlPanel'

import { GET_TALK_LIST } from '@cms/events/graphql/queries/talk'
import { DELETE_TALK, UPDATE_TALK } from '@cms/events/graphql/mutations/talk'
import { removeFromList } from '@cms/core/graphql/utils'

import dayjs from 'dayjs'

const type = 'ONDEMAND'

const filterFields = [{ name: 'title', label: 'Título', queryType: 'Filter' }]

const TalksList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_TALK_LIST, {
        variables: { eventId, type, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_TALK, {
        update: removeFromList({ list: 'talks', Type: 'Talk' })
    })

    const [updateTalk] = useMutation(UPDATE_TALK)

    const path = `/evento/${eventId}/videos`

    const ModalRoutes = useCreation(() => {
        const Routers = CreateModalRoutes(TalksModal, path, {
            eventId,
            type,
            label: 'Vídeo'
        })
        return () => (
            <>
                <Routers />
                <Routers.Custom
                    path={`${path}/:id/painel`}
                    render={() => (
                        <TalkControlPanel
                            type={type}
                            eventId={eventId}
                            path={path}
                        />
                    )}
                />
            </>
        )
    }, [eventId])

    const columns = [
        {
            title: 'Título',
            dataIndex: ['node', 'title'],
            sorter: 'title',
            render: (value, obj) => {
                return <CrudColumns title={obj.node.title} onlyText />
            }
        },
        {
            title: 'Prioridade',
            dataIndex: ['node', 'priority'],
            sorter: 'priority'
        },
        {
            title: 'Contém vídeo cadastrado?',
            dataIndex: ['node', 'video'],
            align: 'center',
            render: (video) => {
                return (
                    <Row style={{ paddingTop: '8px' }}>
                        {video && video.url ? (
                            <Tag color="#7F44FF">Sim</Tag>
                        ) : (
                            <Tag color="#646a6a">Não</Tag>
                        )}
                    </Row>
                )
            }
        },
        {
            title: 'Data de criação',
            dataIndex: ['node', 'createdAt'],
            sorter: 'createdAt',
            render: (_value, obj) => {
                return (
                    <CrudColumns
                        onlyText
                        text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                    />
                )
            }
        }
    ]

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        width: 400,
        align: 'right',
        render: (_, record) => {
            const id = record.node.id

            const setToTalk = async (e) => {
                const variables = { id: id, type: 'LIVE', event: eventId }

                await updateTalk({ variables })
            }

            const menu = (
                <Menu>
                    <Menu.Item key="1">
                        <Link to={path + '/' + id + '/painel'}>
                            Painel de Controle
                        </Link>
                    </Menu.Item>

                    <Menu.Item key="2">
                        <a target="_blank" onClick={setToTalk}>
                            Mover para Palestras
                        </a>
                    </Menu.Item>
                </Menu>
            )

            return (
                <Space>
                    <Dropdown.Button type="primary" overlay={menu}>
                        <Link to={path + '/' + id + '/dashboard'}>
                            Dashboard
                        </Link>
                    </Dropdown.Button>
                </Space>
            )
        }
    }

    if (query.error) return null

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Vídeos On Demand',
                    subTitle: 'Adicione e gerencie seus vídeos on demand',
                    countNames: {
                        singular: 'Vídeo',
                        plural: 'Vídeos'
                    },
                    buttons: [
                        {
                            children: 'Novo Vídeo',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                queryName={'talks'}
                onDelete={deleteData}
                search={true}
                actionsExtra={actionsExtra}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default TalksList

