import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_VIRAL_CAMPAIGN = gql`
    query getCampaign($id: ObjectID!) {
        viralCampaign(id: $id) {
            ...ViralCampaignData
        }
    }
    ${fragments.ViralCampaignData}
`

export const GET_VIRAL_USER_REWARDS_LIST = gql`
    query ViralRewards(
        $viralCampaign: ObjectID
        $engagementCampaign: ObjectID
    ) {
        viralRewards(
            filter: {
                viralCampaign: $viralCampaign
                engagementCampaign: $engagementCampaign
            }
        ) {
            edges {
                node {
                    name
                    id
                    user {
                        name
                        email
                    }
                    indications
                    delivered
                }
            }
        }
    }
`
