import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import { Typography, Row, Button } from 'antd'
import { CrudList, CrudColumns } from '@cms/core/components/Crud'
import { GET_TALK_LIST_ORDERABLE } from '@cms/events/graphql/queries/talk'

import { RocketOutlined } from '@ant-design/icons'
import { IconWrapper } from '@cms/events/components/Dashboard/styles'

const TalkList = function ({ path = '' }) {
    const { eventId } = useParams()
    const { Text } = Typography

    const query = useQuery(GET_TALK_LIST_ORDERABLE, {
        variables: {
            eventId,
            orderBy: { reports: { views: 'DESC' } }
        }
    })

    const columns = [
        {
            title: 'Palestras',
            dataIndex: [''],
            render: (_value, obj, index) => {
                const id = obj.node.id
                const title = obj.node.title || '---'
                const views = obj.node?.reports?.views || '---'
                return (
                    <Link
                        title="clique para visualiza o dashboard da palestra"
                        to={`${path}/evento/${eventId}/palestras/${id}/dashboard`}
                    >
                        <CrudColumns
                            counterSize={25}
                            counter={index + 1}
                            title={title}
                            text={[
                                `${
                                    obj.node.categories?.edges.length
                                        ? `${obj.node.categories.edges[0].node.name} - `
                                        : '---'
                                } 👁️ ${views} views`
                            ]}
                        />
                    </Link>
                )
            }
        }
    ]

    return (
        <>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <RocketOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Palestras</Text>
            </Row>
            <CrudList
                header={{}}
                columns={columns}
                queryName={'talks'}
                hideAction={'all'}
                showHeader={false}
                transparent
                {...query}
            />
        </>
    )
}

export default TalkList
