import SelectPreview from './select'
import TextAreaPreview from './textarea'
import NumberPreview from './number'
import AddressPreview from './address'
import CepStateCityPreview from './cepStateCity'
import StateCityPreview from './stateCity'
import TermPreview from './term'
import CheckboxPreview from './checkbox'
import RadioPreview from './radio'
import SectionPreview from './section'
import DatePreview from './date'

export default {
    select: SelectPreview,
    textarea: TextAreaPreview,
    number: NumberPreview,
    address: AddressPreview,
    cepStateCity: CepStateCityPreview,
    stateCity: StateCityPreview,
    term: TermPreview,
    checkbox: CheckboxPreview,
    radio: RadioPreview,
    section: SectionPreview,
    date: DatePreview
}
