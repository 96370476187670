import React from 'react'
import { Form, Button, Collapse, Radio } from 'antd'

import { ButtonConfig, ColorsBadges, ContainerConfig } from '../../components'

import { Wrapper, Title } from '../../styles'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'
const { Panel } = Collapse

export interface ICardsProps {
    name: string
}

export default function Cards({ name }: ICardsProps) {
    return (
        <>
            <ContainerConfig name={name} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Cartões</Title>
                <Form.Item
                    name={[name, 'configs', 'cards', 'columns']}
                    label="Quantidade de colunas"
                >
                    <Radio.Group defaultValue={8}>
                        <Radio.Button value={12}>2 colunas</Radio.Button>
                        <Radio.Button value={8}>3 colunas</Radio.Button>
                        <Radio.Button value={6}>4 colunas</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.List name={[name, 'configs', 'cards', 'items']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name: _name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Cartão'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() =>
                                                        remove(_name)
                                                    }
                                                />
                                            }
                                        >
                                            <Form.Item
                                                {...restField}
                                                name={[_name, 'bgColor']}
                                                label="Cor do cartão"
                                            >
                                                <ColorsBadges />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[
                                                    _name,
                                                    'cardHeaderBgColor'
                                                ]}
                                                label="Cor do topo do cartão"
                                            >
                                                <ColorsBadges />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[
                                                    _name,
                                                    'headingTitle',
                                                    'text'
                                                ]}
                                                label="Título - topo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[_name, 'small', 'text']}
                                                label="Pequeno texto"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[_name, 'title', 'text']}
                                                label="Texto central"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <Form.Item
                                                {...restField}
                                                name={[
                                                    _name,
                                                    'subtitle',
                                                    'text'
                                                ]}
                                                label="Subtítulo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <ButtonConfig
                                                enableActions
                                                allowVisibilityControl
                                                fieldPath={[_name, 'button']}
                                                rootPath={[
                                                    'sections',
                                                    name,
                                                    'configs',
                                                    'cards',
                                                    'items'
                                                ]}
                                            />
                                        </Panel>
                                    )
                                )}
                            </Collapse>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid()
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar cartão
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[name, 'configs', 'button']}
                name={name}
            />
        </>
    )
}
