import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Form, Row, Col } from 'antd'
import { List } from './sections/'
import Loader from '../Loader'
import { SectionBuilderProvider } from './context'

interface Props {
    data: any
    setData: (data: any) => void
    loading?: boolean
    sections?: any
    name?: string
}

const SectionBuilder = ({ data, setData, loading, sections, name }: Props) => {
    const [form] = Form.useForm()
    const [activePanel, setActivePanel] = useState(null)
    const colRef = useRef(null)

    useEffect(() => {
        if (!loading) {
            form.setFieldsValue(data)
        }
    }, [data])

    const updateConfigs = useCallback(
        (e) => {
            const values = form.getFieldsValue()
            setData({
                ...values
            })
        },
        [form]
    )

    const handleSubmit = useCallback(
        (e) => {
            const values = form.getFieldsValue()
            setData({
                ...values
            })
        },
        [setData]
    )

    const setSection = (index) => {
        setActivePanel(index)
    }

    if (loading) {
        return <Loader />
    }

    return (
        <SectionBuilderProvider sections={sections}>
            <Form.Provider>
                <Row gutter={24}>
                    <Col md={24} sm={24}>
                        <Form
                            name={name}
                            form={form}
                            layout="vertical"
                            onFinish={handleSubmit}
                            onValuesChange={(e) => {
                                setTimeout(() => updateConfigs(e), 300)
                            }}
                        >
                            <>
                                <List
                                    activePanel={activePanel}
                                    setSection={setSection}
                                />
                            </>
                        </Form>
                    </Col>
                    {/* <Col md={12} sm={24} ref={colRef}> */}
                    {/* <Affix offsetTop={100}> */}
                    {/* <div>
                        <div
                            style={{
                                visibility: activePanel !== null ? '' : 'hidden'
                            }}
                        >
                            <Preview
                                onInit={onInit}
                                ref={previewRef}
                                page={slug}
                                fieldRefValue={fieldRefValue}
                            />
                        </div>
                    </div> */}
                    {/* </Affix> */}
                    {/* </Col> */}
                </Row>
            </Form.Provider>
        </SectionBuilderProvider>
    )
}

export default SectionBuilder

