import React from 'react'
import { useQuery } from '@apollo/client'
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { GET_CATEGORY_LIST_ORDERABLE } from '@cms/events/graphql/queries/category'
import { CrudList, CrudColumns } from '@cms/core/components/Crud'

import { Typography, Row } from 'antd'
import { EyeOutlined } from '@ant-design/icons'
import { IconWrapper } from '../styles'
import { Wrapper } from './styles'

const ProgramTalks = function (props) {
    const { eventId } = useParams()
    const { Text } = Typography

    const query = useQuery(GET_CATEGORY_LIST_ORDERABLE, {
        variables: { eventId, type: props.type }
    })

    if (query.error) return console.log(query.error)

    const columns = [
        {
            title: 'Categorias',
            dataIndex: [''],
            render: (_, obj) => {
                return (
                    <Link
                        title="clique para visualizar o dashboard"
                        to={`/evento/${eventId}/categories/${obj.node.id}/dashboard`}
                    >
                        <CrudColumns
                            onlyText
                            title={obj.node.name}
                            text={[
                                `Palestras vinculadas: ${
                                    obj.node.talksTotalCount || 0
                                }`
                            ]}
                        />
                    </Link>
                )
            }
        }
    ]

    return (
        <>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <EyeOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Categorias</Text>
            </Row>

            <Wrapper>
                <CrudList
                    header={{}}
                    columns={columns}
                    queryName={'categories'}
                    hideAction={props.hideAction}
                    showHeader={false}
                    transparent
                    {...query}
                />
            </Wrapper>
        </>
    )
}

export default ProgramTalks
