import { PlusOutlined } from '@ant-design/icons'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import Content from '@cms/core/components/Content'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Col, Input, message, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Form } from '../styles'
import { StyledTag } from './styles'

const Hashtags = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [tags, setTags] = useState([])
    const [inputValue, setInputValue] = useState('')
    const [inputVisible, setInputVisible] = useState(false)

    const { data } = useQuery(GET_EVENT, {
        variables: {
            id: eventId
        }
    })

    const [updateEvent] = useMutation(UPDATE_EVENT)

    useEffect(() => {
        if (data && data.event) {
            setTags(data.event.hashtags)
        }
    }, [data])

    const handleUpdateHashtags = () => {
        updateEvent({
            variables: {
                id: eventId,
                hashtags: tags
            }
        })
            .then((res) => {
                message.success('Hashtags atualizadas com sucesso!')
            })
            .catch((e) => {
                message.error(
                    'Ocorreu um erro ao salvar, tente novamente mais tarde!'
                )
            })
    }

    const showInput = () => {
        setInputVisible(true)
    }

    const handleClose = (removedTag) => {
        const newTags = tags.filter((tag) => tag !== removedTag)
        setTags(newTags)
    }

    const handleInputConfirm = () => {
        if (inputValue && tags.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue])
        }

        setInputVisible(false)
        setInputValue('')
    }

    const getMappedTags = (tag) => {
        const tagElem = (
            <StyledTag
                closable
                color="#7F44FF"
                onClose={(e) => {
                    e.preventDefault()
                    handleClose(tag)
                }}
            >
                #{tag}
            </StyledTag>
        )

        return (
            <span key={tag} style={{ display: 'inline-block' }}>
                {tagElem}
            </span>
        )
    }

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Hashtags"
                subTitle="Adicione até três hashtags para acompanhar as redes sociais"
            />
            <Content className="container-light">
                <Form layout="vertical" hideRequiredMark>
                    {tags.map(getMappedTags)}

                    {inputVisible && (
                        <Form.Item className="input-tag">
                            <Input
                                autoFocus
                                type="text"
                                style={{ width: 250 }}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onBlur={handleInputConfirm}
                                onPressEnter={handleInputConfirm}
                            />
                        </Form.Item>
                    )}

                    {!inputVisible && tags?.length < 3 && (
                        <Form.Item>
                            <StyledTag onClick={showInput} className="new-tag">
                                <PlusOutlined /> Nova hashtag
                            </StyledTag>
                        </Form.Item>
                    )}

                    <Row className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                onClick={handleUpdateHashtags}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default Hashtags
