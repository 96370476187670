import styled, { css } from 'styled-components'

import { Card } from 'antd'
import { COLORS } from '@cms/core/themes'
import { lighten } from 'polished'

export const SectionCard = styled(Card)`
    background-color: transparent !important;
    border-color: ${lighten(0.2, COLORS.bgLight)};
    margin-bottom: 1.4rem;
`

SectionCard.defaultProps = {
    bordered: true
}

export const TDCard = styled(Card)``

TDCard.defaultProps = {
    bordered: false,
    styles: {
        header: {
            padding: 0
        },
        body: {
            paddingTop: 2,
            paddingRight: 2,
            paddingLeft: 2,
            paddingBottom: 2
        }
    }
}

export const TDCardForForm = styled(Card)``

TDCardForForm.defaultProps = {
    bordered: false,
    styles: {
        header: {
            padding: 0
        },
        body: {
            paddingTop: 16,
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        },
        actions: {
            margin: 0,
            paddingLeft: 12,
            paddingRight: 12
        }
    }
}

export const TDCardTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
`

type TDDashboardCardProps = {
    highlight?: boolean
    padding?: number[]
    backgroundColor?: string
}

export const TDDashboardCard = styled(Card)<TDDashboardCardProps>`
    ${({ highlight }) =>
        highlight &&
        css`
            background-color: ${COLORS.active};
        `}

    ${({ backgroundColor }) =>
        backgroundColor &&
        css`
            background-color: ${backgroundColor};
        `}

    ${({ padding }) =>
        padding &&
        padding.length === 4 &&
        css`
            > .ant-card-body {
                padding: ${padding[0] + 'px'} ${padding[1] + 'px'}
                    ${padding[2] + 'px'} ${padding[3] + 'px'} !important;
            }
        `}

    .ant-card:not(.ant-card-bordered) {
        box-shadow:
            0 1px 2px 0 rgba(20, 20, 20, 0.12),
            0 1px 6px -1px rgba(20, 20, 20, 0.12),
            0 2px 4px 0 rgba(20, 20, 20, 0.12);
    }
`

TDDashboardCard.defaultProps = {
    bordered: false,
    styles: {
        header: {
            padding: 0
        },
        body: {
            paddingTop: 16,
            paddingRight: 16,
            paddingLeft: 16,
            paddingBottom: 16
        }
    }
}
