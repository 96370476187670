import styled from 'styled-components'

export const TreeWrapper = styled.div`
    display: contents;
    .ant-tree .ant-tree-node-content-wrapper {
        display: flex;
        .ant-tree-icon__customize {
            width: 14px;
            margin-right: 5px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 14px;
                height: 14px;
            }
        }
    }
`
