import { nanoid } from 'nanoid'
import Header from './Header/Header'
import Header2 from './Header2/Header'
import Header3 from './Header3/Header'
import Header4 from './Header4/Header'
import Header5 from './Header5/Header'
import Header6 from './Header6/Header'

export const Headers = {
    Header: {
        Component: Header,
        name: 'Header',
        category: 'Header',
        columns: '2',
        thumb: require('./Header/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                badge: {
                    bgColor: 'white'
                },
                date: {
                    textColor: 'white'
                },
                owners: {
                    title: {
                        textColor: 'white'
                    }
                },
                headline: {
                    textColor: 'white'
                },
                subtitle: {
                    textColor: 'white'
                },
                form: {
                    box: {
                        bgColor: 'white',
                        textColor: 'black'
                    }
                }
            }
        }
    },
    Header2: {
        Component: Header2,
        name: 'Header 2',
        category: 'Header',
        columns: '2',
        thumb: require('./Header2/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill',
                        objectFit: 'fill',
                        objectPosition: 'center center'
                    }
                },
                badge: {
                    bgColor: 'white'
                },
                date: {
                    textColor: 'white',
                    text: '<div class="td-align-right">8 a 12 de Março</div>'
                },
                owners: {
                    title: {
                        textColor: 'white'
                    }
                },
                headline: {
                    textColor: 'white'
                },
                subtitle: {
                    textColor: 'white'
                },
                button: {
                    active: true,
                    redirect: {
                        mode: 'page'
                    },
                    bgColor: 'secondary',
                    textColor: 'white'
                },
                modelName: {
                    text: '<div><span class="td-color-white">{{ref.name}}</span></div>'
                },
                smallText: {
                    text: '<span><div><span class="td-color-white">Num cenário de retomada ao presencial, </span></div><div><span class="td-color-white">é hora dos empreendedores aprenderem a </span></div><div><span class="td-color-white">equilibrar seus esforços </span></div></span>'
                },
                address: {
                    text: '<div class="td-align-right"><span class="td-color-white">Hotel Unique - Jardins - São Paulo/SP</span></div>'
                },
                model: {
                    logo: {
                        color: 'default'
                    }
                }
            }
        }
    },

    Header3: {
        Component: Header3,
        name: 'Header 3',
        category: 'Header',
        columns: '2',
        thumb: require('./Header3/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill',
                        objectFit: 'fill',
                        objectPosition: 'center center'
                    }
                },
                date: {
                    textColor: 'white',
                    text: '<span class="td-color-white">8 a 12 de Março</span>'
                },
                address: {
                    text: '<span class="td-color-white">Hotel Unique - Jardins - São Paulo/SP</span>'
                },
                headline: {
                    textColor: 'white',
                    text: '<div><span class="td-color-white">{{ref.page.title}}</span></div>'
                },
                smallText: {
                    text: 'Realizador'
                },
                model: {
                    logo: {
                        color: 'default'
                    }
                },
                button: {
                    active: true,
                    redirect: {
                        mode: 'page'
                    },
                    bgColor: 'secondary',
                    textColor: 'white'
                }
            }
        }
    },
    Header4: {
        Component: Header4,
        name: 'Header 4',
        category: 'Header',
        columns: '2',
        thumb: require('./Header4/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill',
                        objectFit: 'fill',
                        objectPosition: 'center center'
                    }
                },
                date: {
                    textColor: 'white'
                },
                owners: {
                    title: {
                        textColor: 'white'
                    }
                },
                headline: {
                    textColor: 'white'
                },
                title: {
                    textColor: 'white'
                },
                subtitle: {
                    textColor: 'white'
                },
                form: {
                    box: {
                        bgColor: 'white',
                        textColor: 'black'
                    }
                }
            }
        }
    },
    Header5: {
        Component: Header5,
        name: 'Header 5',
        category: 'Header',
        columns: '2',
        thumb: require('./Header5/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                badge: {
                    bgColor: 'white'
                },
                date: {
                    textColor: 'white'
                },
                owners: {
                    title: {
                        textColor: 'white'
                    }
                },
                headline: {
                    textColor: 'white'
                },
                form: {
                    box: {
                        bgColor: 'white',
                        textColor: 'black'
                    }
                }
            }
        }
    },
    Header6: {
        Component: Header6,
        name: 'Header 6',
        category: 'Header',
        columns: '1',
        thumb: require('./Header6/header.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                badge: {
                    bgColor: 'white'
                },
                date: {
                    textColor: 'white'
                },
                owners: {
                    title: {
                        textColor: 'white'
                    }
                },
                headline: {
                    textColor: 'white'
                },
                form: {
                    box: {
                        bgColor: 'white',
                        textColor: 'black'
                    }
                }
            }
        }
    }
}
