import React, { useCallback } from 'react'
import _get from 'lodash/get'
import { Spin } from 'antd'
import { uniqBy } from 'lodash'

const useCrudTree = (
    query,
    {
        queryName,
        includeEmpty = false,
        additionalValues = [],
        path = 'edges',
        idKey = 'node.id',
        titleKey = 'node.name',
        parentIdKey = 'node.parent.id'
    } = {}
) => {
    const { data = {}, loading, fetchMore } = query

    const pageInfo = data[queryName]?.pageInfo || {}
    const flatItems = uniqBy(
        []
            .concat(includeEmpty ? { node: { id: null } } : [])
            .concat(additionalValues)
            .concat(_get(data[queryName], path, [])),
        idKey
    )

    const onScroll = useCallback(
        async (e) => {
            if (loading || !pageInfo.hasNextPage) return

            const target = e.target
            const scrollOffset = target.scrollTop + target.offsetHeight
            const parent = (scrollOffset / target.scrollHeight) * 100

            if (parent >= 80) {
                fetchMore({
                    variables: {
                        afterCursor: pageInfo.endCursor
                    }
                })
            }
        },
        [pageInfo, loading]
    )

    const topologicalSort = useCallback(() => {
        const visited = new Set()
        const sortedItems = []

        const visit = (item) => {
            if (visited.has(item)) return

            visited.add(item)

            const parentId = _get(item, parentIdKey)
            if (parentId) {
                const parentItem = flatItems.find(
                    (i) => _get(i, idKey) === parentId
                )
                visit(parentItem)
            }

            sortedItems.push(item)
        }

        flatItems.forEach(visit)

        return sortedItems
    }, [flatItems, idKey, parentIdKey])

    const buildTree = useCallback(() => {
        const sortedItems = topologicalSort()

        const itemsById = sortedItems.reduce((acc, item) => {
            const itemId = _get(item, idKey)
            const parentId = _get(item, parentIdKey)
            acc[itemId] = {
                value: itemId,
                label: _get(item, titleKey),
                children: []
            }

            if (parentId && acc[parentId]) {
                acc[parentId].children.push(acc[itemId])
            }

            return acc
        }, {})

        const roots = sortedItems
            .filter((item) => !_get(item, parentIdKey))
            .map((item) => itemsById[_get(item, idKey)])

        return roots
    }, [topologicalSort, idKey, titleKey, parentIdKey])

    const renderTree = useCallback(() => {
        const treeData = buildTree()
        if (!loading) return treeData

        return [
            ...treeData,
            {
                value: 'loading',
                title: <Spin size="small" />,
                disabled: true
            }
        ]
    }, [buildTree, loading])

    return [
        renderTree,
        {
            onScroll: onScroll,
            loading: loading.state
        }
    ]
}

export default useCrudTree

