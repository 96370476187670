import React from 'react'

import BaseConfig from '../BaseConfig'
import { Form, Select } from 'antd'
import { SPEAKERS_CONFIGS } from '../../Segment/constants/speakers'
import Segment from '@cms/core/components/Segment'

const Speakers = (props) => {
    return (
        <>
            <BaseConfig name={props.name}>
                <Form.Item
                    name={[props.name, 'config', 'variant']}
                    label="Tipo de visualização"
                >
                    <Select>
                        <Select.Option value="default">Padrão</Select.Option>
                        <Select.Option value="highlight">
                            Destaque
                        </Select.Option>
                    </Select>
                </Form.Item>
            </BaseConfig>
            <Segment
                form={props.form}
                name={[props.name, 'config']}
                type="speakers"
                filters={SPEAKERS_CONFIGS}
            />
        </>
    )
}

export default Speakers

