import Code from './Code'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Codes = {
    Code: {
        Component: Code,
        name: 'Código',
        category: 'Código',
        columns: '1',
        thumb: require('./code.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill',
                        objectFit: 'fill',
                        objectPosition: 'center center'
                    }
                },
                headline: {
                    text: 'Escolha seu ingresso',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Escolha o ingresso que mais combina com você e aproveite o evento!',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
