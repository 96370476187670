import React from 'react'

import { Wrapper, BigCount, MetricTitle, Content } from './styles'

const CardInnerMetrics = ({ title, data, reverse, align, full }) => {

    return (
        <Wrapper reverse={reverse} align={align} full={full}>
            <Content>
                <MetricTitle>{title}</MetricTitle>
                <BigCount className={reverse ? 'reverse-count' : 'normal-count'}>
                    {data}
                </BigCount>
            </Content>
        </Wrapper>
    )
}

CardInnerMetrics.defaultProps = {
    reverse: false,
    align: '',
    full: false
}

export default CardInnerMetrics
