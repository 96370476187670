import React from 'react'
import { message, Form, Input } from 'antd'

import { useApolloClient } from '@apollo/client'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import {
    GET_USER,
    GET_SPONSORS_LIST,
    GET_SPEAKERS_LIST_ORDERABLE
} from '@cms/events/graphql/queries/user'
import {
    CREATE_OR_UPDATE_USER,
    UPDATE_USER
} from '@cms/events/graphql/mutations/user'

const SpeakerUserModal = ({ ...props }) => {
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_USER,
                variables: { id }
            })

            return { ...data.user }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_OR_UPDATE_USER,
                variables: { ...formData, role: 'speaker' },
                refetchQueries: [
                    { query: GET_SPONSORS_LIST },
                    {
                        query: GET_SPEAKERS_LIST_ORDERABLE,
                        variables: { first: 25, orderBy: { name: 'ASC' } }
                    }
                ]
            })

            message.success('Palestrante cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER,
                variables: { id, ...formData },
                refetchQueries: [{ query: GET_SPONSORS_LIST }]
            })

            message.success('Palestrante atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Palestrante' : 'Adicionar Palestrante'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <IgnorePasswordAutoComplete />

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                        { required: true, message: 'E-mail é obrigatório!' }
                    ]}
                    {...getError('email')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    {...getError('password')}
                >
                    <Input.Password placeholder={isEdit ? '**********' : ''} />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default SpeakerUserModal
