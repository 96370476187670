import React from 'react'

import { PagesRoutes } from './routes'
import { PageBuilderProvider } from './context/context'
import { Container } from './styles'

const PageBuilderScreen = () => {
    return (
        <PageBuilderProvider>
            <Container>
                <PagesRoutes />
            </Container>
        </PageBuilderProvider>
    )
}

export default PageBuilderScreen
