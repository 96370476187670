import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudLinks,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import WatchExpertsModal from './WatchExpertsModal'

import { GET_WATCH_EXPERT_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchExpert'
import { DELETE_WATCH_EXPERT } from '@cms/watch/graphql/mutations/watchExpert'

import { removeFromList } from '@cms/watch/graphql/utils'

import dayjs from 'dayjs'

const path = `/especialistas`

const ModalRoutes = CreateModalRoutes(WatchExpertsModal, path, {})

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'firstName'],
        sorter: 'name',
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.firstName + ' ' + (obj.node.lastName || '')}
                    text={[obj.node.office]}
                />
            )
        }
    },
    {
        title: 'Empresa',
        dataIndex: ['node', 'company', 'name'],
        render: (value, obj) => {
            return (
                <>
                    {obj.node.company.name ? (
                        <CrudColumns
                            image={obj.node.company?.image?.url}
                            title={obj.node.company.name}
                        />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    },
    {
        title: 'Prioridade',
        dataIndex: ['node', 'priority'],
        sorter: 'priority'
    },
    {
        title: 'Links',
        dataIndex: ['node', 'links'],
        render: (value, obj) => {
            return (
                <>
                    {obj.node.links ? (
                        <CrudLinks links={obj.node.links} />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchExpertList = () => {
    const query = useQuery(GET_WATCH_EXPERT_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_WATCH_EXPERT, {
        update: removeFromList({ list: 'WatchExperts', Type: 'WatchExpert' })
    })

    if (query.error) return <div>Error</div>

    const filterFields = [{ name: 'name', label: 'Nome', queryType: 'Filter' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Especialistas',
                    subTitle: 'Adicione e gerencie especialistas',
                    buttons: [
                        {
                            children: 'Novo Especialista',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'WatchExperts'}
                filterFields={filterFields}
                onDelete={deleteData}
                {...query}
            />
        </>
    )
}

export default WatchExpertList

