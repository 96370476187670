import styled from 'styled-components'
import Icon from '@ant-design/icons'

export const CustomIconBadge = styled(Icon)`
    /* background-color: ${(props) => (props.color ? props.color : '#fff')}; */
    border-radius: 100px;
    svg {
        fill: ${(props) => (props.fill ? props.fill : '#000')};
        height: ${(props) => props.size / 2 + 'px' || '22px'};
        width: ${(props) => props.size / 2 + 'px' || '22px'};

        @media (max-width: 1400px) {
            height: ${(props) => (props.size / 2) * 0.9 + 'px' || '40px'};
            width: ${(props) => (props.size / 2) * 0.9 + 'px' || '40px'};
        }
    }
    &:hover {
        cursor: pointer;
    }
    /* margin: 0px 5px; */
`

export const CustomIcon = styled(Icon)`
    background-color: ${(props) => (props.color ? props.color : '#fff')};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : '100px'};
    position: relative;
    height: ${(props) => props.size - 2 + 'px' || '20px'};
    width: ${(props) => props.size - 2 + 'px' || '20px'};

    svg {
        position: absolute;
        top: -1px;
        left: -1px;
        fill: ${(props) => (props.fill ? props.fill : '#000')};
        height: ${(props) => props.size + 'px' || '22px'};
        width: ${(props) => props.size + 'px' || '22px'};

        @media (max-width: 1400px) {
            height: ${(props) => props.size * 0.9 + 'px' || '20px'};
            width: ${(props) => props.size * 0.9 + 'px' || '20px'};
        }
    }
    &:hover {
        cursor: pointer;
    }
    /* margin: 0px 5px; */
`

export const IconWrapper = styled.div`
    border-radius: 6px;
    margin-bottom: 6px;
    height: ${(props) => props.size + 'px' || '44px'};
    width: ${(props) => props.size + 'px' || '44px'};
    background-color: ${(props) =>
        props.color ? props.color : props.badge ? props.badge : '#fff'};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;

    @media (max-width: 1400px) {
        height: ${(props) => props.size * 0.9 + 'px' || '40px'};
        width: ${(props) => props.size * 0.9 + 'px' || '40px'};
    }
`
