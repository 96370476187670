import { VideoCameraOutlined } from '@ant-design/icons'
import { useSubscription } from '@apollo/client'
import { SUBSCRIPTION_TALK_VIEWERS } from '@cms/events/graphql/subscription/talk'
import { Col, Row, Typography } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Buttons from '../Buttons'
import { IconWrapper, Player, PlayerWrapper } from './styles'

const { Text } = Typography

const TalkPlayer = ({ talk, eventId, type, refetch }) => {
    const { id } = useParams()
    const [ready, setReady] = useState(false)
    const [duration, setDuration] = useState(0)
    const ref = useRef()

    const { data: TalkViewData } = useSubscription(SUBSCRIPTION_TALK_VIEWERS, {
        variables: { talk: id }
    })

    useEffect(() => {
        if (!ready || !duration) return
        const player = ref.current

        if (duration > talk.currentLiveTime) {
            player.seekTo(talk.currentLiveTime, 'seconds')
        }
    }, [ready, talk, duration])

    return (
        <PlayerWrapper>
            <Row
                justify="space-between"
                align={'middle'}
                style={{ padding: '20px', width: '100%' }}
            >
                <Col>
                    <Row align="middle">
                        <IconWrapper>
                            <VideoCameraOutlined style={{ color: '#000' }} />
                        </IconWrapper>
                        <Text style={{ marginLeft: '10px' }}>Transmissão</Text>
                    </Row>
                </Col>
                <Col>
                    <Buttons
                        eventId={eventId}
                        type={type}
                        refetch={refetch}
                        status={talk?.status}
                    />
                </Col>
            </Row>
            <Row>
                <Player
                    playing={talk.status === 'STARTED'}
                    onReady={() => setReady(true)}
                    onDuration={(v) => setDuration(v)}
                    controls
                    ref={ref}
                    url={
                        talk.videoWeb?.source ||
                        talk.video?.source ||
                        talk.video?.url
                    }
                    width="100%"
                    height="500px"
                />
            </Row>
            <Row style={{ marginLeft: '15px' }}>
                {/* {talk?.status === 'STARTED' && (
                    <Text>
                        <strong>
                            {TalkViewData?.talkViewersIncremented ||
                                talk.viewers ||
                                0}
                        </strong>{' '}
                        Espectadores
                    </Text>
                )} */}
                {talk?.status === 'FINISHED' && (
                    <Text>
                        <strong>{talk.reports.views}</strong> Visualizações
                    </Text>
                )}
            </Row>
        </PlayerWrapper>
    )
}

export default TalkPlayer
