import { gql } from '@apollo/client'

export const REPORT_USER = gql`
    mutation ReportUser(
            $user: ObjectID!,
            $reason: String!,
            $message:ObjectID!
        ){
        reportUser(
            input:{
                user: $user,
                reason: $reason,
                message: $message
            }
            ) {
            id
        }
    }
`

export const RESOLVE_USER_REPORT= gql`
    mutation ResolveUserReport(
            $id: ObjectID!,
        ){
        resolveUserReport(
            reported: $id 
        )
    }
`
