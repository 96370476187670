import React from 'react'

import { Modal } from 'antd'
import AceEditor from 'react-ace'
import { Typography } from 'antd'

const { Title } = Typography

const IntegrationLogModal = ({ integrationLog, onCancel }) => {
    if (!integrationLog) {
        return null
    }

    return (
        <Modal
            width={600}
            title={integrationLog.integration.name}
            open={!!integrationLog}
            onOk={() => onCancel()}
            onCancel={() => onCancel()}
            footer={null}
        >
            <Title level={5}>Carga da requisição</Title>
            <AceEditor
                mode={'json'}
                width={'100%'}
                height={'200px'}
                theme="terminal"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={JSON.stringify(integrationLog.data.data, null, 2)}
                setOptions={{
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2
                }}
            />
            
            <Title level={5}>Resposta</Title>
            <AceEditor
                mode={'json'}
                width={'100%'}
                height={'200px'}
                theme="terminal"
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={JSON.stringify(integrationLog.response, null, 2)}
                setOptions={{
                    enableSnippets: true,
                    showLineNumbers: true,
                    tabSize: 2
                }}
            />
        </Modal>
    )
}

export default IntegrationLogModal
