const html = (strings, ...values) => {
    return strings
        .map((str, i) => {
            const value = values[i]
            return str + (value || '')
        })
        .join('')
}

exports.html = html
