import React, { useEffect, useState } from 'react'
import { Input, Select, Tag } from 'antd'
import { debounce } from 'lodash'
import { CrudLisSearchRow } from './styles'
import CrudListFilter from './CrudListFilter'

const { Option } = Select

export default function CrudListSearch({
    refetch,
    orderByFields,
    orderField,
    handledOrderBy,
    filterFields
}) {
    const [filters, setFilters] = useState([])

    const refetchDebounce = debounce(refetch, 300)

    const handleSearch = (e) => {
        const keycode = e.keyCode ? e.keyCode : e.which
        const value = e.target.value
        let _refetch = refetchDebounce

        if (keycode == '13') {
            _refetch = refetch
        }

        _refetch({
            search: value
        })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <CrudLisSearchRow key="list-actions" align={'middle'}>
                <Input.Search
                    className="input-search"
                    style={{ width: 300, marginRight: '8px' }}
                    type="text"
                    placeholder={'Buscar...'}
                    onChange={handleSearch}
                    allowClear
                />
                {filterFields && (
                    <CrudListFilter
                        fields={filterFields}
                        refetch={refetch}
                        setFilters={setFilters}
                    />
                )}
                {orderByFields && (
                    <Select
                        style={{
                            marginRight: '10px',
                            marginLeft: '10px'
                        }}
                        value={orderField}
                        onChange={handledOrderBy}
                    >
                        {orderByFields.map((item, key) => {
                            return (
                                <Option
                                    key={key}
                                    value={item.value}
                                    title={item.label}
                                >
                                    {item.label}
                                </Option>
                            )
                        })}
                    </Select>
                )}
            </CrudLisSearchRow>
            <div style={{ display: 'flex', gap: '8px' }}>
                {filters.map((f) => (
                    <Tag
                        key={f.field.name}
                        closable
                        bordered={false}
                        onClose={(e) => {
                            const find = filters.find(
                                (_f) => _f.field.name === f.field.name
                            )

                            refetch({
                                [find.field.name]: undefined
                            })

                            setFilters((prev) =>
                                prev.filter(
                                    (_f) => _f.field.name !== find.field.name
                                )
                            )
                        }}
                    >
                        {f.tag}
                    </Tag>
                ))}
            </div>
        </div>
    )
}

