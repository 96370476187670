import { Rewards } from './rewards'
import { Schedule } from './schedule'
import { Sponsors } from './sponsors'
import { Speakers } from './speakers'
import { Forms } from './forms'
import { Viral } from './viral'

export const pages = {
    rewards: Rewards,
    schedule: Schedule,
    sponsors: Sponsors,
    speakers: Speakers,
    form: Forms,
    viral: Viral
}
