import { gql } from '@apollo/client'
import { WatchCourseData } from '../fragments'

export const GET_WATCH_COURSES_ORDENABLE = gql`
    query GetWatchCourses(
        $first: Int
        $afterCursor: Cursor
        $orderBy: WatchCourseOrderBy
        $search: String
        $title: Filter
    ) {
        watchCourses(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { title: $title }
        ) {
            totalCount
            edges {
                node {
                    ...WatchCourseData
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }

    ${WatchCourseData}
`

export const GET_WATCH_COURSE = gql`
    query GetWatchCourse($id: ObjectID!) {
        watchCourse(id: $id) {
            ...WatchCourseData
        }
    }

    ${WatchCourseData}
`

export const GET_WATCH_COURSES_SELECT_LIST = gql`
    query GetCoursesSelectList($orderBy: WatchCourseOrderBy) {
        watchCourses(orderBy: $orderBy) {
            edges {
                node {
                    value: id
                    label: title
                }
            }
        }
    }
`

