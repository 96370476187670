import React from 'react'
import { Form, Select, Switch, Tag } from 'antd'

import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { GET_BRAND_TAGS } from '@cms/events/graphql/queries/brand'

import { ButtonConfig, ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const SpeakerCarousel = (props) => {
    const { eventId } = useParams()

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />

            <Wrapper>
                <Title level={4}>Regras de exibição</Title>
                <Form.Item
                    name={[props.name, 'configs', 'filters', 'featured']}
                    valuePropName="checked"
                    initialValue={false}
                    label="Exibir apenas palestrantes Destaque"
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                    />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Tags"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.brandTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Pequeno texto</Title>
                <Form.Item
                    name={[props.name, 'configs', 'smallText', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtítulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Estilo dos palestrantes</Title>

                <Form.Item
                    name={[props.name, 'configs', 'overlay', 'textColor']}
                    label="Cor da sobreposição"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'speaker',
                        'text',
                        'textColor'
                    ]}
                    label="Cor do nome dos palestrantes"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'office',
                        'text',
                        'textColor'
                    ]}
                    label="Cor do cargo dos palestrantes"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[
                        props.name,
                        'configs',
                        'speaker',
                        'company',
                        'color'
                    ]}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Navegação</Title>
                <Form.Item
                    name={[props.name, 'configs', 'navigation', 'type']}
                    label="Tipo de navegação"
                    initialValue={'arrows'}
                >
                    <Select>
                        <Select.Option value="arrows">Setas</Select.Option>
                        <Select.Option value="dots">Pontos</Select.Option>
                        <Select.Option value="both">
                            Setas e pontos
                        </Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'navigation', 'textColor']}
                    label="Cor da navegação"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <ButtonConfig enableActions allowVisibilityControl {...props} />
        </>
    )
}

export default SpeakerCarousel
