import React, { useState, useEffect, useCallback } from 'react'
import { CrudContainer } from './styles'
import { PageHeader } from '@ant-design/pro-layout'
import {
    Button,
    Input,
    Divider,
    notification,
    Tag,
    Typography,
    Row,
    Col,
    Upload,
    message,
    Card
} from 'antd'
import { PlusOutlined, CheckOutlined } from '@ant-design/icons'
import { removeObjectProps } from '@cms/events/utils'

import _, { isEmpty, isEqual } from 'lodash'
import Papa from 'papaparse'
import { useMutation, useQuery } from '@apollo/client'
import { GET_BAN_WORDS } from '@cms/events/graphql/queries/chat'
import {
    CREATE_BAN_WORD,
    DELETE_BAN_WORD
} from '@cms/events/graphql/mutations/chat'
import { CardTitle } from '@cms/core/components/Crud/CrudList/styles'

const { Text, Title } = Typography

export default function ChatDashboard({ history, match }) {
    const [data_list, setData] = useState([])

    const { data: dataBanWords, loading, refetch } = useQuery(GET_BAN_WORDS)

    const fetchData = useCallback(async () => {
        if (dataBanWords && !loading) {
            const _response = removeObjectProps(dataBanWords.banWords, [
                '__typename'
            ])
            setData(_response)
        }
    }, [dataBanWords])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const [input_list, setInput] = useState()
    const [id_list, setId] = useState()
    const [notification_, setNotification] = notification.useNotification()
    const fileReader = new FileReader()
    const [words, setWords] = useState([])
    const [createBanWord] = useMutation(CREATE_BAN_WORD)

    const [deleteBanWord] = useMutation(DELETE_BAN_WORD)

    const handleAddItem = async () => {
        await createBanWord({ variables: { word: input_list } })
        setInput('')
        refetch()
    }

    const handleCancelEditItem = async (id) => {
        await deleteBanWord({ variables: { id } })
        refetch()
    }

    const handleFiles = (files) => {
        const { status } = files.file
        if (status === 'error') {
            Papa.parse(files.file.originFileObj, {
                header: false,
                skipEmptyLines: true,
                complete: function async(_) {
                    _.data.map((d) => words.push(Object.values(d)[0]))
                    words.forEach(handleMutipleBanWords)
                }
            })
        }
    }

    const handleMutipleBanWords = async (word) => {
        await createBanWord({ variables: { word } })
        refetch()
    }

    const validInput = () => {
        return (
            !isEmpty(input_list) &&
            data_list?.filter((o) => o.word === input_list).length === 0
        )
    }

    return (
        <>
            <Card
                headStyle={{
                    padding: 0
                }}
                title={
                    <CardTitle>
                        <div>
                            <Input
                                className="input-search"
                                style={{
                                    width: 300,
                                    marginRight: '10px',
                                    borderColor: '#fff'
                                }}
                                type="text"
                                value={input_list}
                                placeholder={'Palavras bloqueadas'}
                                onPressEnter={() => {
                                    if (validInput()) {
                                        handleAddItem()
                                    } else {
                                        message.error('Palavra já cadastrada')
                                    }
                                }}
                                onChange={(e) => {
                                    setInput(e.target.value)
                                    setId(data_list.length + 1)
                                }}
                            />
                            <Button
                                // style={{
                                //     backgroundColor: '#fff',
                                //     color: '#38383cb8',
                                //     fontWeight: 600
                                // }}
                                type="primary"
                                icon={
                                    <PlusOutlined
                                        style={{ fontSize: '1.3em' }}
                                    />
                                }
                                disabled={!validInput()}
                                onClick={() => {
                                    handleAddItem()
                                }}
                            >
                                Adicionar
                            </Button>
                        </div>
                        <Upload
                            accept=".txt"
                            className="importCSV"
                            onChange={(e) => {
                                handleFiles(e)
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: '#fff',
                                    color: '#38383cb8',
                                    fontWeight: 600,
                                    marginLeft: 'auto'
                                }}
                            >
                                Importar Palavras
                            </Button>
                        </Upload>
                    </CardTitle>
                }
            >
                <Text
                    style={{
                        display: 'block',
                        marginBottom: '10px'
                    }}
                >
                    Mensagens que contém essas palavras ou frases serão
                    bloqueadas no chat:
                </Text>
                {data_list.map((data, index) => (
                    <>
                        <Tag
                            key={data.id}
                            style={{
                                marginRight: '10px',
                                marginBottom: '10px',
                                cursor: 'pointer'
                            }}
                            closable
                            onClose={() => handleCancelEditItem(data.id)}
                        >
                            {data.word}
                        </Tag>
                    </>
                ))}
            </Card>
        </>
    )
}
