import React from 'react'
import { Empty } from 'antd'

interface CustomEmptyProps {
    message?: string
}

const CustomEmpty = ({ message }: CustomEmptyProps) => (
    <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>{message ? message : 'Nenhum registro'}</span>}
    ></Empty>
)

export default CustomEmpty
