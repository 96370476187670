import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Row, Input } from 'antd'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams, Link } from 'react-router-dom'

import { GET_EVENTS_MAIN } from '@cms/events/graphql/queries/event'
import { EventDropdownWrapper } from '../style'

const useEventDropdown = function () {
    const isAdmin = useSelector((state) => state.user.isAdmin)

    const dispatch = useDispatch()
    const history = useHistory()
    const [dashboardSync] = useMutation(gql`
        mutation DashboardSync($event: ObjectID!) {
            dashboardSync(event: $event)
        }
    `)
    const { loading, data } = useQuery(GET_EVENTS_MAIN, {
        variables: { first: 999, orderBy: { createdAt: 'DESC' } }
    })
    const { eventId } = useParams()

    const [items, setItems] = useState([
        {
            key: 'loading',
            label: 'Carregando...'
        }
    ])

    const [search, setSearch] = useState('')

    useEffect(() => {
        if (eventId) {
            dashboardSync({
                variables: {
                    event: eventId
                }
            })
        }
    }, [eventId])

    useEffect(() => {
        if (data) {
            const rest = data?.events.edges.map(({ node }) => ({
                label: node.name,
                id: node.id
            }))
            setItems([...rest])
        }
    }, [data])

    const EventSearchHeader = () => (
        <EventDropdownWrapper>
            <Row justify={'space-between'} style={{ marginBottom: 10 }}>
                <span>Eventos</span>
            </Row>
            <Row>
                <Input
                    placeholder="Buscar evento"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(value) => setSearch(value.target.value)}
                    style={{ width: 200, flex: 1 }}
                />
            </Row>
        </EventDropdownWrapper>
    )

    const handleCreateEvent = (e) => {
        e.stopPropagation()
        dispatch({ type: 'SHOW_MODAL_EVENT' })
    }

    const handleChange = (eventId) => {
        history.push(`/evento/${eventId}/dashboard`)
    }

    const filteredItems = items?.filter(
        (item) =>
            item.key === 'header' || new RegExp(search, 'i').test(item.label)
    )

    return {
        EventSearchHeader,
        items: filteredItems,
        handleChange,
        eventId,
        loading
    }
}

export default useEventDropdown
