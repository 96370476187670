import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation, gql } from '@apollo/client'

import {
    GET_USER_REPORTED,
    GET_USERS_REPORTEDS_ORDERABLE
} from '@cms/events/graphql/queries/userReport'
import { BAN_USER } from '@cms/events/graphql/mutations/user'
import { RESOLVE_USER_REPORT } from '@cms/events/graphql/mutations/userReport'
import { CrudColumns, CrudList } from '@cms/core/components/Crud'

const columns = [
    {
        title: 'Name',
        dataIndex: [''],
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node.reporter.avatar?.url}
                    title="Denunciado"
                    text={[obj.node.reporter.name]}
                />
            )
        }
    },
    {
        title: 'Motivo',
        dataIndex: [],
        render: (value, obj) => {
            return (
                <CrudColumns onlyText title="Motivo" text={[obj.node.reason]} />
            )
        }
    },
    {
        title: 'Mensagem',
        dataIndex: [],
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[<strong>Mensagem</strong>, obj.node.messageContent]}
                />
            )
        }
    },
    {
        title: 'Name',
        dataIndex: [''],
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="Reportado por"
                    text={[obj.node.reported.name]}
                />
            )
        }
    }
]

export default function UsersReportedOverview({ history }) {
    const { id } = useParams()

    const query = useQuery(GET_USER_REPORTED, {
        variables: { first: 25, reported: id, orderBy: { createdAt: 'ASC' } }
    })

    const [banUser] = useMutation(BAN_USER, {
        refetchQueries: [{ query: GET_USERS_REPORTEDS_ORDERABLE }]
    })
    const [reportUserResolve] = useMutation(RESOLVE_USER_REPORT, {
        refetchQueries: [
            { query: GET_USER_REPORTED, variables: { first: 25, reported: id } }
        ]
    })

    const handledBan = async () => {
        const variables = { id: id }

        await banUser({ variables })

        history.goBack()
    }

    const handledResolve = async () => {
        const variables = { id: id }

        await reportUserResolve({ variables })

        // history.goBack()
    }

    return (
        <>
            <CrudList
                header={{
                    title: 'Usuários Denunciados',
                    subTitle: 'Banir de forma definitiva usuário reportado',
                    buttons: [
                        {
                            children: 'Voltar',
                            type: 'primary',
                            action: () => history.goBack()
                        },
                        {
                            children: 'Resolver',
                            type: 'primary',
                            warning: true,
                            title: 'Deseja resolver todas as denuncias',
                            action: handledResolve
                        },
                        {
                            children: 'Banir',
                            type: 'danger',
                            warning: true,
                            title: 'Tem certeza que deseja banir o usuário?',
                            action: handledBan
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'userReports'}
                hideAction={'all'}
                {...query}
            />
        </>
    )
}

