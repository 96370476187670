import styled from 'styled-components'
import { Row as AntRow } from 'antd'

export const Row = styled(AntRow)`
    .section-move.ant-space-vertical{
        .ant-space-item{
            margin-bottom: 0 !important;
            max-height: 12px;
        }
    }
`