import React, { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Form, message } from 'antd'
import { useMutation, useQuery } from '@apollo/client'
import { throttle } from 'lodash'

import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/form'
import {
    CREATE_EVENT_FORM,
    UPDATE_EVENT_FORM
} from '@cms/events/graphql/mutations/form'
import { removeObjectProps, resolveRelateds } from '@cms/events/utils'

import { Nav } from '../components'
import { Container } from '../styles'

import { mock } from './mock'
import { useFormBuilderContext } from '../context/context'
import { FormBuilderRoutes } from './routes'
import Loader from '@cms/core/components/Loader'

const FormBuilder = () => {
    const { eventId } = useParams()
    const { setFormData, selectField, formData } = useFormBuilderContext()

    const [createForm] = useMutation(CREATE_EVENT_FORM)
    const [updateForm] = useMutation(UPDATE_EVENT_FORM, {
        onError: (err) =>
            message.error(`Ocorreu um erro ao publicar o formulário: ${err}`),
        onCompleted: () => message.success('Formulário atualizado com sucesso!')
    })

    const { data: eventForms, loading } = useQuery(GET_EVENT_FORMS, {
        variables: {
            eventId,
            type: 'register'
        }
    })

    const emailTemplate = {
        nameFrom: 'John Doe',
        emailFrom: 'john@doe.com',
        subject: 'Obrigado por se inscrever',
        replyTo: 'john@doe.com',
        sayHello: 'Ola',
        content:
            '<p>Seja bem-vindo.<p><p><br></p><p><br></p><p>Você se cadastrou em nosso evento, agora aproveite e convide amigos também.</p>',
        thankYouMessage: 'Obrigado',
        button: {
            text: 'Convide seus amigos',
            link: 'https://tdplataform.com/campaign/minha-campanha',
            backgroundColor: '#e91e63',
            textColor: '#fff'
        }
    }

    const formUpdate = async (fields) => {
        await updateForm({
            variables: {
                event: eventId,
                ...resolveRelateds(fields, ['email.template.media'])
            }
        })
    }

    const onFormFinish = async (name, { values, forms }) => {
        let _formData = formData

        if (name !== 'form-nav') {
            const _currentForm = forms.preview
            const preview = _currentForm.getFieldsValue()

            if (name === 'preview') {
                _formData = { ..._formData, ...preview }

                setFormData(_formData)
            }

            if (name.indexOf('edit') === -1) {
                preview[name]?.push(values)
                _currentForm.setFieldsValue(preview)
                setFormData({ ..._formData, [name]: [...preview[name]] })
            } else {
                const node = name.split('-edit')[0]

                preview[node][selectField.target] = values
                _currentForm.setFieldsValue({ ...preview })

                setFormData({
                    ..._formData,
                    [node]: preview[node]
                })
            }
        } else {
            try {
                formUpdate(_formData)
            } catch (e) {
                message.error(e.message)
            }
        }
    }

    useEffect(() => {
        if (eventForms) {
            const { edges } = eventForms.eventForms

            if (edges.length > 0) {
                let eventForm = edges.filter(
                    ({ node }) => node.type === 'register'
                )[0]

                const emailFields = eventForm.node.email.template.nameFrom || ''

                if (emailFields.length === 0) {
                    eventForm = {
                        node: {
                            ...eventForm.node,
                            email: {
                                active: true,
                                template: emailTemplate
                            }
                        }
                    }
                }

                const _eventForm = removeObjectProps(eventForm.node, [
                    '__typename'
                ])

                _eventForm.integrations = _eventForm.integrations.map(
                    (integration) => integration.id || integration
                )

                setFormData(_eventForm)
            } else {
                const formCreate = async () => {
                    const { data } = await createForm({
                        variables: {
                            event: eventId,
                            type: 'register',
                            fields: mock.fields,
                            email: emailTemplate
                        }
                    })
                    setFormData(data)
                }
                formCreate()
            }
        }
    }, [eventForms, setFormData])

    useEffect(() => {
        console.log('render')
    }, [])

    if (loading) {
        return <Loader fixed={false} padding='10px' />
    }

    return formData ? (
        <Form.Provider onFormFinish={onFormFinish}>
            <Container>
                <Nav path="inscricao" />
                <FormBuilderRoutes />
            </Container>
        </Form.Provider>
    ) : null
}

export default FormBuilder
