import React, { useState } from 'react'
import { Input, Form, Row, Col, Typography, Checkbox } from 'antd'

const { Text } = Typography

const DateLimits = ({ type, config }) => {
    const [limits, setLimits] = useState(config.dateLimits)

    const handleLimits = (e) => {
        setLimits(e.target.checked)
    }

    return (
        <Row gutter={[24, 0]} style={{ marginBottom: 24 }}>
            <Col span={24}>
                <Form.Item
                    name={['config', 'dateLimits']}
                    valuePropName="checked"
                >
                    <Checkbox onChange={handleLimits}>
                        Controlar mínimo e máximo
                    </Checkbox>
                </Form.Item>
            </Col>
            {limits ? (
                <>
                    <Col span={12}>
                        <Row gutter={[24, 14]}>
                            <Col span={12}>
                                <Text strong>Minimo</Text>
                            </Col>
                            <Col span={12}>
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const maxCurrentDate = getFieldValue([
                                            'config',
                                            'maxCurrentDate'
                                        ])
                                        return (
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    'config',
                                                    'minCurrentDate'
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    disabled={maxCurrentDate}
                                                >
                                                    Data atual
                                                </Checkbox>
                                            </Form.Item>
                                        )
                                    }}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const minCurrentDate = getFieldValue([
                                            'config',
                                            'minCurrentDate'
                                        ])

                                        if (minCurrentDate) return null
                                        return (
                                            <Form.Item
                                                noStyle
                                                name={['config', 'min']}
                                            >
                                                <Input
                                                    type={'date'}
                                                    max={'2100-01-01'}
                                                    min={'1900-01-01'}
                                                    disabled={minCurrentDate}
                                                />
                                            </Form.Item>
                                        )
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={[24, 14]}>
                            <Col span={12}>
                                <Text strong>Máximo</Text>
                            </Col>
                            <Col span={12}>
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const minCurrentDate = getFieldValue([
                                            'config',
                                            'minCurrentDate'
                                        ])
                                        return (
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    'config',
                                                    'maxCurrentDate'
                                                ]}
                                                valuePropName="checked"
                                            >
                                                <Checkbox
                                                    disabled={minCurrentDate}
                                                >
                                                    Data atual
                                                </Checkbox>
                                            </Form.Item>
                                        )
                                    }}
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item noStyle shouldUpdate>
                                    {({ getFieldValue }) => {
                                        const maxCurrentDate = getFieldValue([
                                            'config',
                                            'maxCurrentDate'
                                        ])
                                        if (maxCurrentDate) return null
                                        return (
                                            <Form.Item
                                                noStyle
                                                name={['config', 'max']}
                                            >
                                                <Input
                                                    type={'date'}
                                                    max={'2100-01-01'}
                                                    min={'1900-01-01'}
                                                    disabled={maxCurrentDate}
                                                />
                                            </Form.Item>
                                        )
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </>
            ) : null}
        </Row>
    )
}

export default DateLimits
