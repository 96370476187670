import styled, { css } from 'styled-components'
import { COLORS } from '@cms/core/themes'
import { Button } from 'antd'
import { darken, lighten } from 'polished'
import { Link } from 'react-router-dom'

export const DropDownStyled = styled.div`
    .ant-btn {
        margin: 5px 10px;
        width: calc(100% - 20px);
    }
`
export const EventDropdownWrapper = styled.div`
    padding: 0px 10px;
    width: auto;
`
export const EventDropdownHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const EventName = styled.div`
    min-width: 100px;
    position: relative;
    margin-left: 20px;
    line-height: 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${COLORS.text};

    .current-event-page-arrow {
        right: 0;
        left: -20px;
        top: 23px;
    }

    .current-page-arrow {
        left: 0;
        left: -16px;
        top: 20px;
    }

    .ant-page-header-heading-sub-title {
        font-size: 10px;
        align-self: start;
        margin-bottom: -5px;
    }

    .ant-page-header-heading-title {
        /* opacity: 0.75; */
    }
`

export const EventsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-height: calc(40vh);
    overflow: hidden;
    width: 249px;
`

export const EventList = styled.ul`
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    list-style: none;
    padding: 0;
    padding-right: 8px;
`

export const IconLinkButton = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${COLORS.secondary};
    height: 40px;
    padding: 6px 15px;
    border-radius: 8px;

    svg {
        width: 18px;
        height: 18px;
        margin-right: 6px;
    }

    &:hover {
        background-color: ${darken(0.1, COLORS.secondary)};
    }
`

export const EventItem = styled.li<{ isActive?: boolean }>`
    height: 40px;
    line-height: 40px;
    border-radius: 8px;
    list-style: none;
    color: ${COLORS.textLight};
    background: ${lighten(0.2, COLORS.bgLight)};
    cursor: pointer;
    padding-left: 24px;
    padding-inline: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-inline: 4px;
    margin-block: 4px;

    ${({ isActive }) =>
        isActive &&
        css`
            color: ${COLORS.text};
            background: ${COLORS.bgLight};
        `}
`
