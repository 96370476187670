import { ORDER_BY_NAME } from '@cms/core/components/Segment/OrderBy/constants'
import { OPTIONS_VALUE } from './options'

import { SectionsType } from '@cms/watch/pages/Config/constants/initialData'

export const CATEGORIES_CONFIGS = {
    categories: {
        type: SectionsType.categories,
        label: 'Categoria',
        defaultValues: {},
        fields: {
            _id: {
                label: 'Categorias',
                type: 'select',
                options: OPTIONS_VALUE.categories
            }
        },
        orderBy: {
            ...ORDER_BY_NAME
        }
    }
}

