import React from 'react'
import Icon from '@ant-design/icons'

const LikeSvg = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99866 20.6497C15.5208 20.6497 19.9973 16.1731 19.9973 10.651C19.9973 5.12889 15.5208 0.652344 9.99866 0.652344C4.47655 0.652344 0 5.12889 0 10.651C0 16.1731 4.47655 20.6497 9.99866 20.6497Z" fill="url(#paint0_linear_5621_22817)"/>
    <path d="M15.991 11.4186C15.991 10.9701 15.6714 10.6397 15.3142 10.4974C15.6768 10.3416 15.9212 9.96831 15.8836 9.54129C15.8406 9.04713 15.3975 8.68188 14.9006 8.68188H11.7128C11.7128 8.68188 12.0915 7.4626 12.0458 6.528C11.9975 5.59339 10.9501 4.03303 9.87581 4.92735L10.0181 6.43668C10.0477 6.76433 9.97249 7.0893 9.80061 7.3686L8.4363 9.595C8.06837 10.1966 7.875 10.8868 7.875 11.5904V14.1123C7.875 14.5393 8.09254 14.9368 8.45241 15.165C8.79081 15.3799 9.18022 15.4954 9.58039 15.4954H13.7807C14.2803 15.4954 14.7476 15.0871 14.7691 14.5876C14.7771 14.3593 14.7073 14.1499 14.5811 13.9833C15.0994 13.978 15.5183 13.5563 15.5183 13.038C15.5183 12.7963 15.427 12.5734 15.2766 12.4069C15.6338 12.2699 15.991 11.9127 15.991 11.4186Z" fill="white"/>
    <path d="M6.98372 9.83398H5.11451C4.9668 9.83398 4.84863 9.95215 4.84863 10.0999V15.5383C4.84863 15.686 4.9668 15.8042 5.11451 15.8042H6.98372C7.13143 15.8042 7.2496 15.686 7.2496 15.5383V10.0999C7.2496 9.95215 7.12874 9.83398 6.98372 9.83398Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_5621_22817" x1="24.3456" y1="7.23532" x2="-2.39861" y2="16.9092" gradientUnits="userSpaceOnUse">
    <stop stopColor="#4518DB"/>
    <stop offset="1" stopColor="#7B29FF"/>
    </linearGradient>
    </defs>
    </svg>
)

export const IconLike = (props) => <Icon component={LikeSvg} {...props} />
