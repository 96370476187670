import SelectSearch from '@cms/core/components/SelectSearch'
import { Col, Form, Input, Row, Select, Tag } from 'antd'
import React, { useState } from 'react'

const Juicer = () => {
    const [hashtags, setHashtag] = useState([])

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Data Feed Id"
                        name={['config', 'data_feed_id']}
                        rules={[
                            {
                                required: true,
                                message: 'DataFeedId é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Hashtags" name={['config', 'hashtags']}>
                        <Select
                            mode="tags"
                            tagRender={({ label, ...props }) => (
                                <Tag {...props} color="#7F44FF">
                                    {label}
                                </Tag>
                            )}
                            onChange={(v) => setHashtag(v)}
                            style={{ width: '100%' }}
                            placeholder="Crie uma hashtag para este evento"
                        >
                            <Select.Option key={hashtags} value={hashtags}>
                                {hashtags}
                            </Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Hashtag Principal"
                        name={['config', 'hashtagPrimary']}
                    >
                        <SelectSearch
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Selecione uma hashtag para ser a principal"
                        >
                            {hashtags.map((hashtag) => (
                                <Select.Option key={hashtag} value={hashtag}>
                                    {hashtag}
                                </Select.Option>
                            ))}
                        </SelectSearch>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default Juicer
