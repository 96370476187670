import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { Row, Layout, Col } from 'antd'

import { removeObjectProps } from '@cms/events/utils'

import { ReferalList } from '../Review/'
import Loader from '@cms/core/components/Loader'
import { CampaingPage } from '@cms/events/components/Campaings/styles'

export default function EngagementListPage({ history, match }) {
    const { eventId } = useParams()
    const [campaingDataClean, setCampaingDataClean] = useState(null)
    const { error: eventError, data: eventData } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    useEffect(() => {
        if (!eventData || !eventData.event.engagementCampaign) return
        setCampaingDataClean(
            removeObjectProps(eventData.event.engagementCampaign, [
                '__typename'
            ])
        )
    }, [eventData])

    if (!eventData) return <Loader fixed={false} padding='10px' />
    if (eventError) return `Error! ${eventError.message}`

    return (
        <CampaingPage>
            {campaingDataClean ? (
                <Row
                    style={{ borderRadius: '10px', justifyContent: 'flex-end' }}
                >
                    <Col span={24}>
                        <ReferalList
                            campaingDataClean={campaingDataClean}
                            eventId={eventId}
                        />
                    </Col>
                </Row>
            ) : null}
        </CampaingPage>
    )
}
