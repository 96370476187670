import React, { useState } from 'react'
import { useQuery } from '@apollo/client'

import { Button, Form, Popover } from 'antd'

import {
    GET_TALK_LIST,
    GET_TALK_OPTIONS
} from '@cms/events/graphql/queries/talk'

import SelectSearch from '@cms/core/components/SelectSearch'
import { useCrudSelect } from '@cms/core/components/Crud'

const PopConfirm = ({ eventId, onFinish }) => {
    const queryTalks = useQuery(GET_TALK_OPTIONS, {
        variables: { first: 9999, eventId }
    })

    const [talksOptions, talksSelectOptions] = useCrudSelect(queryTalks, {
        queryName: 'talks'
    })

    return (
        <Form layout={'vertical'} onFinish={(values) => onFinish(values)}>
            <Form.Item label="Próxima palestra" name={['nextTalk']}>
                <SelectSearch
                    {...talksSelectOptions}
                    style={{ width: '100%' }}
                    mode="single"
                >
                    {talksOptions({
                        key: 'node.id',
                        label: 'node.title',
                        groupBy: {
                            key: 'node.type',
                            groups: {
                                STAGE: 'Palcos',
                                LIVE: 'Palestras',
                                ONDEMAND: 'Videos On Demand'
                            }
                        }
                    })}
                </SelectSearch>
            </Form.Item>

            <Button type="primary" block htmlType="submit">
                Confirmar
            </Button>
        </Form>
    )
}

const FinishedButton = ({ eventId, loadingStatus, onClick }) => {
    const [visible, setVisible] = useState(false)

    return (
        <Popover
            style={{ width: 400, maxWidth: 400 }}
            content={
                <PopConfirm
                    eventId={eventId}
                    onFinish={(nextTalk) => {
                        onClick('FINISHED', nextTalk)
                        setVisible(false)
                    }}
                />
            }
            placement="right"
            title="Finalizar Live?"
            trigger="click"
            visible={visible}
            onVisibleChange={(status) => setVisible(status)}
        >
            <Button
                style={{
                    color: '#fff',
                    marginBottom: 20,
                    backgroundColor: 'rgb(194 36 83)'
                }}
                onClick={() => !loadingStatus && setVisible(true)}
                loading={loadingStatus}
            >
                Finalizar Live
            </Button>
        </Popover>
    )
}

export default FinishedButton
