import PrivateRoute from '@cms/core/routes/privateRoute'
import React from 'react'
import { Switch } from 'react-router-dom'

import InfoScreen from '../pages/Info'
import AdditionalScreen from '../pages/Additional'

export const InfoBuilderRoutes = () => (
    <>
        <Switch>
            <PrivateRoute
                exact
                path="/evento/:eventId/config/info"
                component={InfoScreen}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/config/info/adicional"
                component={AdditionalScreen}
            />
        </Switch>
    </>
)

export default InfoBuilderRoutes

