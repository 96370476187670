import React, { useState, useEffect } from 'react'
import { Button } from 'antd'

const ModalFooter = ({update, step, controls, saveLoading, buttons, current, steps}) => {
    const [valid, setValid] = useState(false)

    update.useSubscription(async () => {
        setValid(step.validation(step))  
    })

    useEffect(() => {
        setValid(step.validation(step))  
    }, [step])

    console.log(valid)
     
    return (
    <>
        <Button type="ghost" onClick={ controls.close }>
            {buttons.cancel || 'Cancelar'}
        </Button>
        {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={ controls.prev }>
                {buttons.prev || 'Anterior'}
            </Button>
        )}
        {current < steps - 1 && (
            <Button disabled={!valid} type="primary" onClick={ controls.next }>
                {buttons.next || 'Próximo'} 
            </Button>
        )}
        {current === steps - 1 && (
            <Button  data-cy="create" loading={saveLoading} disabled={!valid} type="primary" onClick={ controls.finish }>
                {
                    saveLoading ? buttons.loading || 'Enviando' : buttons.finish || 'Enviar'
                }
            </Button>
        )}
    </>
    )
}

export default ModalFooter