import React from 'react'
import ListFields from './ListFields'
import { SegmentProvider } from './context'
import { ISectionType, ISegmentConfigs, TFiltersType } from './types'

interface ISegmentProps {
    name: string[]
    type: string
    style?: React.CSSProperties
    filters?: ISectionType
    configs?: ISegmentConfigs
    activeFilters?: TFiltersType[]
}

enum ListName {
    'orderBy' = 'orderBy',
    'fields' = 'filters'
}

enum ListFormPath {
    'fields' = 'condition'
}

const Segment = ({ name, type, style, filters, configs }: ISegmentProps) => {
    if (!filters) {
        return null
    }
    return (
        <SegmentProvider filters={filters} configs={configs}>
            {Object.keys(filters[type]).map((key) => {
                if (ListName[key]) {
                    const formName = ListFormPath[key]
                        ? [...name, ListFormPath[key]]
                        : [...name]
                    return (
                        <ListFields
                            name={formName}
                            type={type}
                            listName={ListName[key]}
                            style={style}
                        />
                    )
                }
                return null
            })}
        </SegmentProvider>
    )
}

export default Segment
