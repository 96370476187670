import styled from 'styled-components'
import Color from 'color'
import { Radio } from 'antd'


export const CardGroup = styled(Radio.Group)`
    display: block;
`

export const CardItem = styled(Radio)`
    background: transparent;
    padding: 20px;
    border-radius: 11px;
    border: 1px solid rgba(202,205,211,.5);   
    position: relative;
    display: block;
    transition: border-color .2s ease-out;
    margin-top: 10px;
    cursor: pointer;     
    white-space: initial;

    span {
        display: block;
    } 

    h4.ant-typography {
        color: ${props => props.color};
    }    

    .ant-radio {
        display: none;
    }

    &.ant-radio-wrapper-checked {
        border-color: ${props =>  props.color};
        border-width: 2px;
    }

    &:hover {
        border-color: ${props => Color(props.color).alpha(.5)};
    }
`