import { gql } from '@apollo/client'
import { WatchContentData } from '../fragments'

export const GET_WATCH_CONTENTS_ORDENABLE = gql`
    query GetWatchContents(
        $first: Int
        $afterCursor: Cursor
        $orderBy: WatchContentOrderBy
        $search: String
        $module: String
        $title: Filter
    ) {
        watchContents(
            filter: { module: $module, title: $title }
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            edges {
                node {
                    ...WatchContentData
                }
            }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }

    ${WatchContentData}
`

export const GET_WATCH_CONTENT = gql`
    query GetWatchContent($id: ObjectID!) {
        watchContent(id: $id) {
            ...WatchContentData
        }
    }

    ${WatchContentData}
`

export const GET_WATCH_CONTENTS_SELECT_LIST = gql`
    query GetContentssSelectList($orderBy: WatchContentOrderBy) {
        watchContents(orderBy: $orderBy) {
            edges {
                node {
                    value: id
                    label: title
                }
            }
        }
    }
`

