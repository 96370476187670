export const CURRENCY_TYPES = ['BRL'] as const

export type CurrencyType = (typeof CURRENCY_TYPES)[number]

export interface ICurrency {
    name: string
    symbol: CurrencyType
    locale: string
    intlConfig: Intl.NumberFormatOptions
}

export const currencies: ICurrency[] = [
    {
        name: 'Real',
        symbol: 'BRL',
        locale: 'pt-BR',
        intlConfig: {
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }
    }
]
