import About from './About/About'
import About2 from './About2/About'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Abouts = {
    About: {
        Component: About,
        name: 'Sobre',
        category: 'Sobre',
        columns: '1',
        thumb: require('./About/about.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                },
                video: {
                    url: 'https://vimeo.com/76979871'
                }
            }
        }
    },
    About2: {
        Component: About2,
        name: 'Sobre - Colunas de texto',
        category: 'Sobre',
        columns: '1',
        thumb: require('./About2/about.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                smallText: {
                    text: '<div class="td-align-center">Sobre</div>'
                },
                headline: {
                    text: '<span><div class="td-align-center">Os maiores</div><div class="td-align-center">players</div></span>'
                },
                subtitle: {
                    text: '<span><div class="td-align-center">do marketing estão aqui</div></span>'
                }
            }
        }
    }
}
