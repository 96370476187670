import React from 'react'
import { Route } from 'react-router-dom'

export const CreateDashboardRoute = (Component, path, params = {}) => {
    const RouterComponent = () => (
        <>
            <Route
                exact
                path={`${path}/:id/dashboard`}
                render={() => <Component path={path} params={params} />}
            />
        </>
    )

    RouterComponent.Custom = (props) => <Route {...props} />

    return RouterComponent
}
