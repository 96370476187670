import React, { useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useFormBuilderContext } from '../../context/context'
import { EmailEditor } from '../../components'
import { WelcomeEmailContent } from './styles'

const WelcomeEmail = () => {
    const { formData, setFormData } = useFormBuilderContext()
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(formData.email)
    }, [formData])

    const handleUpdateValues = (...args) => {
        setFormData({...formData, email: args[1]})
    }

    if (!formData) return null

    return (
        <WelcomeEmailContent>
            <Row gutter={24}>
                <Col span={24}>
                    <Form form={form} layout="vertical" name={'email'} onValuesChange={handleUpdateValues}>
                        <EmailEditor title={'Boas vindas ao Participante'}/>
                    </Form>
                </Col>
            </Row>
        </WelcomeEmailContent>
    )
}

export default WelcomeEmail
