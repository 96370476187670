import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_COUPON = gql`
    mutation CreateTicketCoupon(
        $code: String
        $description: String
        $event: ObjectID!
        $discountPercentage: Int
        $active: Boolean
    ) {
        createTicketCoupon(
            input: {
                code: $code
                event: $event
                description: $description
                discountPercentage: $discountPercentage
                active: $active
            }
        ) {
            ...TicketCouponData
        }
    }

    ${fragments.TicketCouponData}
`

export const UPDATE_COUPON = gql`
    mutation UpdateTicketCoupon(
        $id: ObjectID!
        $code: String
        $description: String
        $event: ObjectID!
        $discountPercentage: Int
        $active: Boolean
    ) {
        updateTicketCoupon(
            id: $id
            input: {
                code: $code
                description: $description
                event: $event
                discountPercentage: $discountPercentage
                active: $active
            }
        ) {
            ...TicketCouponData
        }
    }

    ${fragments.TicketCouponData}
`

export const DELETE_COUPON = gql`
    mutation DeleteTicketCoupon($id: ObjectID!) {
        deleteTicketCoupon(id: $id) {
            id
            success
        }
    }
`
