import React, { useState, useEffect } from 'react'
import { Upload, message, Button } from 'antd'
import { UploadOutlined, PictureOutlined } from '@ant-design/icons'
import { ImageAvatar, UploadWrapper } from './styles'
// import client from '@cms/events/api/client'
import axios from 'axios'

const uploadClient = axios.create({
    baseURL: process.env.APIGW_UPLOAD_ENDPOINT
})

uploadClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('@td-token')
    if (token) {
        const db = window.location.hostname.split('.')[0]
        config.headers['X-Customer-Id'] = db === 'localhost' ? 'platform' : db
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

const MediaUploader = (props) => {
    const { type, multi, value, options, onChange, style, disabled } = props
    const [fileList, setFileList] = useState([])
    const [_props, setProps] = useState()

    useEffect(() => {
        if (!value) return
        const newList = (multi ? [...value] : [value]).map((item) => {
            return {
                uid: item._id,
                response: item,
                name: item.filename,
                status: 'done',
                url: item.url
            }
        })
        setFileList(newList)
    }, [value])

    useEffect(() => {
        if (type) {
            switch (type) {
                case 'image':
                    return setProps({ listType: 'picture', accept: 'image/*' })
                case 'svg':
                    return setProps({
                        listType: 'picture',
                        accept: ' image/svg+xml'
                    })
                case 'doc':
                    return setProps({ listType: 'text', accept: '.doc, .pdf' })
                case 'video':
                    return setProps({ listType: 'text', accept: 'video/*' })
                case 'audio':
                    return setProps({ listType: 'text', accept: 'audio/*' })
                default:
                    return setProps({ listType: 'text', accept: type })
            }
        }
    }, [type])

    const formatFile = (file) => {
        if (file.response) {
            file.uid = file.response._id
            file.url = file.response.url
            file.name = file.response.filename
        }

        return {
            uid: file.uid,
            url: file.url,
            type: file.type,
            name: file.name,
            response: file.response,
            status: file.status
        }
    }

    const handleChange = (info) => {
        if (info.file.status === 'error') {
            message.error(info.file.error)
            setFileList([])
            return
        }

        let _fileList = [...info.fileList]

        if (multi) {
            if (info.file.status === 'done') {
                _fileList = _fileList.map(formatFile)
                onChange(_fileList.map((o) => o.response))
                message.success(`${info.file.name} carregado com sucesso`)
            }
        } else {
            if (info.file.status === 'done') {
                _fileList = _fileList.slice(-1)
                _fileList = _fileList.map(formatFile)
                onChange(_fileList[0].response)
                message.success(`${info.file.name} carregado com sucesso`)
            }
        }

        setFileList(_fileList)
    }

    const handleRemove = (item) => {
        onChange(multi ? [] : null)
    }

    const handleUpload = async ({ onSuccess, onError, file, onProgress }) => {
        try {
            const optionsArr = []

            if (options && options.optimize) {
                const { quality, resize } = options.optimize
                if (quality) {
                    optionsArr.push(`quality=${quality}`)
                }
                if (resize?.height) {
                    optionsArr.push(`resize.height=${resize.height}`)
                }
                if (resize?.width) {
                    optionsArr.push(`resize.width=${resize.width}`)
                }
            }
            const res = await uploadClient.post(
                `/upload${optionsArr.length ? `?${optionsArr.join('&')}` : ''}`,
                file,
                {
                    headers: {
                        ['Content-Type']: file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        onProgress({ percent })
                    }
                }
            )
            onSuccess({
                _id: res.data._id,
                filename: res.data.filename,
                url: res.data.url
            })
        } catch (error) {
            onError(error.message)
        }
    }

    const handleTestFile = (file) => {
        if (file.type !== _props.accept) {
            message.error(`${file.name} is not a ${_props.accept} file`)
        }
        return file.type === _props.accept
    }

    const UploadImage = () => {
        return <ImageAvatar>{<PictureOutlined />}</ImageAvatar>
    }

    const UploadButton = () => {
        return (
            <Button>
                <UploadOutlined /> Enviar Arquivo
            </Button>
        )
    }

    return (
        <UploadWrapper>
            <Upload
                {..._props}
                name={'file'}
                multiple={multi}
                customRequest={handleUpload}
                onChange={handleChange}
                onRemove={handleRemove}
                listType={style === 'image' ? 'picture-card' : 'picture'}
                fileList={fileList}
                disabled={disabled}
            >
                {(multi || !fileList.length) &&
                    (style === 'image' ? <UploadImage /> : <UploadButton />)}
            </Upload>
        </UploadWrapper>
    )
}

export default MediaUploader

