import React from 'react'
import Icon from '@ant-design/icons'

const LaughingSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.399 20.6497C15.9212 20.6497 20.3977 16.1731 20.3977 10.651C20.3977 5.12889 15.9212 0.652344 10.399 0.652344C4.87694 0.652344 0.400391 5.12889 0.400391 10.651C0.400391 16.1731 4.87694 20.6497 10.399 20.6497Z" fill="url(#paint0_radial_5621_22831)"/>
    <path opacity="0.2" d="M10.399 20.6497C15.9212 20.6497 20.3977 16.1731 20.3977 10.651C20.3977 5.12889 15.9212 0.652344 10.399 0.652344C4.87694 0.652344 0.400391 5.12889 0.400391 10.651C0.400391 16.1731 4.87694 20.6497 10.399 20.6497Z" fill="url(#paint1_radial_5621_22831)"/>
    <path d="M5.32714 7.19131C5.11498 7.19131 4.92967 7.03285 4.90281 6.81532C4.87327 6.58166 5.03978 6.36681 5.27343 6.33727C6.07375 6.2379 6.8875 6.16002 7.70931 6.10362C7.336 5.74374 6.77739 5.30061 6.04958 4.98639C5.83204 4.89239 5.73267 4.63994 5.82667 4.42241C5.92067 4.20487 6.17312 4.1055 6.39066 4.1995C8.05844 4.91925 8.93128 6.18687 8.96888 6.24059C9.05482 6.3695 9.06825 6.53332 8.99842 6.67298C8.92859 6.81263 8.78894 6.90126 8.63586 6.90931C7.5428 6.96034 6.44974 7.05165 5.38354 7.18593C5.36206 7.18862 5.34594 7.19131 5.32714 7.19131Z" fill="url(#paint2_linear_5621_22831)"/>
    <path d="M15.4759 7.19253C15.4571 7.19253 15.441 7.19253 15.4222 7.18984C14.3587 7.05556 13.2656 6.96156 12.1699 6.91322C12.0141 6.90516 11.8745 6.81654 11.8073 6.67688C11.7375 6.53723 11.7482 6.3734 11.8369 6.24449C11.8745 6.19078 12.7473 4.92316 14.4151 4.2034C14.6326 4.10941 14.8851 4.20877 14.9791 4.42631C15.0731 4.64385 14.9737 4.8963 14.7562 4.9903C14.023 5.3072 13.4644 5.74765 13.0938 6.10753C13.9156 6.16124 14.732 6.23912 15.5323 6.34118C15.766 6.37072 15.9325 6.58557 15.9029 6.81922C15.8734 7.03407 15.6881 7.19253 15.4759 7.19253Z" fill="url(#paint3_linear_5621_22831)"/>
    <path fillRule="evenodd"  d="M3.04395 9.9905C3.02515 10.208 3.01172 10.4283 3.01172 10.6512C3.01172 14.7307 6.32043 18.0394 10.3999 18.0394C14.4794 18.0394 17.7881 14.7307 17.7881 10.6512C17.7881 10.4283 17.7774 10.208 17.7559 9.9905C15.4758 9.542 12.9969 9.29492 10.3972 9.29492C7.79754 9.29492 5.32137 9.54469 3.04395 9.9905Z" fill="url(#paint4_linear_5621_22831)"/>
    <path d="M10.3984 18.0416C12.9688 18.0416 15.0526 17.3021 15.0526 16.39C15.0526 15.4778 12.9688 14.7383 10.3984 14.7383C7.82791 14.7383 5.74414 15.4778 5.74414 16.39C5.74414 17.3021 7.82791 18.0416 10.3984 18.0416Z" fill="url(#paint5_linear_5621_22831)"/>
    <defs>
    <radialGradient id="paint0_radial_5621_22831" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5719 0.944734) scale(15.0581)">
    <stop stopColor="#FEEB71"/>
    <stop offset="0.2201" stopColor="#FEE76E"/>
    <stop offset="0.4481" stopColor="#FDDA64"/>
    <stop offset="0.6798" stopColor="#FBC654"/>
    <stop offset="0.9126" stopColor="#F8A93D"/>
    <stop offset="1" stopColor="#F79C33"/>
    </radialGradient>
    <radialGradient id="paint1_radial_5621_22831" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.4001 10.6521) scale(9.99974)">
    <stop stopColor="white"/>
    <stop offset="0.3726" stopColor="#FFFEFD"/>
    <stop offset="0.5069" stopColor="#FEF9F6"/>
    <stop offset="0.6026" stopColor="#FCF0EB"/>
    <stop offset="0.68" stopColor="#FAE4DA"/>
    <stop offset="0.7463" stopColor="#F7D4C4"/>
    <stop offset="0.805" stopColor="#F3C1A8"/>
    <stop offset="0.8581" stopColor="#EEAA88"/>
    <stop offset="0.9069" stopColor="#E98E62"/>
    <stop offset="0.9523" stopColor="#E36F37"/>
    <stop offset="0.9926" stopColor="#DC4F09"/>
    <stop offset="1" stopColor="#DB4800"/>
    </radialGradient>
    <linearGradient id="paint2_linear_5621_22831" x1="4.89935" y1="5.67739" x2="9.04159" y2="5.67739" gradientUnits="userSpaceOnUse">
    <stop stopColor="#3B446B"/>
    <stop offset="0.3009" stopColor="#384167"/>
    <stop offset="0.6127" stopColor="#31375A"/>
    <stop offset="0.9284" stopColor="#242746"/>
    <stop offset="1" stopColor="#202340"/>
    </linearGradient>
    <linearGradient id="paint3_linear_5621_22831" x1="11.761" y1="5.67861" x2="15.9032" y2="5.67861" gradientUnits="userSpaceOnUse">
    <stop stopColor="#202340"/>
    <stop offset="0.071633" stopColor="#242746"/>
    <stop offset="0.3873" stopColor="#31375A"/>
    <stop offset="0.6991" stopColor="#384167"/>
    <stop offset="1" stopColor="#3B446B"/>
    </linearGradient>
    <linearGradient id="paint4_linear_5621_22831" x1="10.401" y1="18.0408" x2="10.401" y2="9.29515" gradientUnits="userSpaceOnUse">
    <stop stopColor="#85370E"/>
    <stop offset="1" stopColor="#482314"/>
    </linearGradient>
    <linearGradient id="paint5_linear_5621_22831" x1="10.3994" y1="18.0404" x2="10.3994" y2="14.7395" gradientUnits="userSpaceOnUse">
    <stop stopColor="#DB122B"/>
    <stop offset="1" stopColor="#FF6683"/>
    </linearGradient>
    </defs>
    </svg>
)

export const IconLaughing = (props) => <Icon component={LaughingSvg} {...props} />
