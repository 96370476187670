import { GET_WATCH_CONTENTS_SELECT_LIST } from '@cms/watch/graphql/queries/watchContent'

export const OPTIONS_VALUE = {
    contents: async (client) => {
        const { data } = await client.query({
            query: GET_WATCH_CONTENTS_SELECT_LIST,
            variables: {
                orderBy: { name: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data.watchContents.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    }
}
