import React from 'react'

import Segment from '@cms/core/components/Segment'
import BaseConfig from '../BaseConfig'

import { CONTENTS_CONFIGS } from '@cms/core/components/Segment/constants/contents'

interface IContentProps {
    name: string
}

export function Content({ name }: IContentProps) {
    return (
        <>
            <BaseConfig name={name} title={true} />
            <Segment
                name={[name, 'config']}
                type="content"
                filters={CONTENTS_CONFIGS}
                configs={{
                    formName: 'sections'
                }}
            />
        </>
    )
}
