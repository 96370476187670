import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ModalContent from './modalContent'

const Surveys = ({ params, path, eventId, talk, ...props }) => {
    const history = useHistory()
    const { id } = useParams()
    useEffect((_) => {
        document.body.style.overflowY = 'hidden'
        return (_) => {
            document.body.style.overflowY = ''
        }
    }, [])

    const handleCancelModal = () => {
        history.push(path)
    }
    const handleOk = () => {
        history.push(path + '/publicidade/notificacoes/adicionar')
    }

    return (
        (<Modal
            open={true}
            okText={"Nova Notificação"}
            cancelText={"Fechar"}
            onCancel={handleCancelModal}
            width={835}
            title={'Notificações'}
            children={
                <ModalContent talk={talk} path={path} eventId={eventId} />
            }
            onOk={handleOk}
            {...props}
        />)
    );
}

export default Surveys
