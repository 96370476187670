import { nanoid } from 'nanoid'
import { getColorContrast } from '../../utils'
import Footer from './Footer'
import Footer2 from './Footer2'
import Footer3 from './Footer3'

export const Footers = {
    Footer: {
        Component: Footer,
        name: 'Footer',
        category: 'Footer',
        columns: '2',
        thumb: require('./footer.png'),
        create: async (site) => {
            const colors = site.theme.colors

            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                }
            }
        }
    },
    Footer2: {
        Component: Footer2,
        name: 'Footer 2',
        category: 'Footer',
        columns: '2',
        thumb: require('./footer2.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                }
            }
        }
    },
    Footer3: {
        Component: Footer3,
        name: 'Footer 3',
        category: 'Footer',
        columns: '2',
        thumb: require('./footer3.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                }
            }
        }
    }
}
