import { theme } from 'antd'

export const transform = {
    visit: (cssObj: Record<string, any>) => {
        if ('backgroundColor' in cssObj) {
            const clone = { ...cssObj }
            clone.background = clone.backgroundColor
            delete clone.backgroundColor
            return clone
        }
        return cssObj
    }
}

export const algorithm = theme.darkAlgorithm
