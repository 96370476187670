import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_COUNT_AVERAGE_TALKS } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'

import Card from '../CardInner'

const TotalTalkViewsAverage = ({ eventId, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_COUNT_AVERAGE_TALKS, {
        variables: { eventId }
    })
    if (loading || error) return null

    return (
        <>
            <Card
                title={'Média de Público'}
                reverse={reverse}
                data={
                    data?.audienceAverage
                        ? renderBaseK(Math.floor(data.audienceAverage))
                        : 0
                }
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalTalkViewsAverage
