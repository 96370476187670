import { gql } from '@apollo/client'
import { CustomTrackerData } from '../fragments'

export const GET_CUSTOM_TRACKER_LIST_ORDENABLE = gql`
    query GetCustomTracker(
        $first: Int
        $afterCursor: Cursor
        $orderBy: CustomTrackerOrderBy
        $search: String
        $filter: CustomTrackerFilter
    ) {
        customTrackers(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: $filter
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CustomTrackerData
                }
            }
        }
    }

    ${CustomTrackerData}
`

export const GET_CUSTOM_TRACKER_LIST = gql`
    query GetCustomTrackerList($first: Int, $afterCursor: Cursor) {
        customTrackers(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CustomTrackerData
                }
            }
        }
    }

    ${CustomTrackerData}
`

export const GET_CUSTOM_TRACKER = gql`
    query GetCustomTracker($id: ObjectID!) {
        customTracker(id: $id) {
            ...CustomTrackerData
        }
    }

    ${CustomTrackerData}
`
