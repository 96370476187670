import styled from 'styled-components'
import { Layout, Form as AntForm } from 'antd'
import { TDCard } from '@cms/core/components/TDCards'

const { Content } = Layout

export const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75em;
    border-bottom: 1px solid #000;
    h1 {
        display: inline-block;
    }
`
export const ContentDefault = styled(Content)`
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
`
export const Form = styled(AntForm)`
    width: 100%;

    .footer {
        .ant-col {
            text-align: right;
        }
        button:first-child {
            margin-right: 10px;
        }
    }
`
export const ContentBox = styled(TDCard)`
    max-width: 100%;
    padding: 20px;
`
export const CampaingPage = styled.div``
