import React from 'react'

import BaseConfig from '../BaseConfig'
import Segment from '@cms/core/components/Segment'
import { BANNERS_CONFIGS } from '../../Segment/constants/banners'

const Banners = (props) => {
    return (
        <>
            <BaseConfig name={props.name} />
            <Segment
                name={[props.name, 'config']}
                type="banners"
                filters={BANNERS_CONFIGS}
                configs={{
                    hiddenFields: {
                        type: 'STAGE'
                    },
                    formName: 'sections'
                }}
            />
        </>
    )
}

export default Banners

