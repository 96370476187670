import React from 'react'
import { Button } from 'antd'

import { useFormBuilderContext } from '@cms/events/pages/Forms/context/context'

import { Wrapper } from './styles'

const NewField = ({ field }) => {
    const { setSelectField } = useFormBuilderContext()

    return (
        <Wrapper>
            <Button
                type="primary"
                shape="round"
                onClick={() => setSelectField({ field, isEdit: false })}
            >
                {field.btnLabel || field.label}
            </Button>
        </Wrapper>
    )
}

export default NewField
