import React from 'react'

import { Typography, Row, Col } from 'antd'
import { CrudList } from '@cms/core/components/Crud'

import { useQuery } from '@apollo/client'

import { GET_BRAND_VIEW_USERS } from '@cms/events/graphql/queries/brand'

import { StarOutlined } from '@ant-design/icons'

import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../../styles'

import { TextTitle } from './styles'

const { Text } = Typography

const ListUsers = ({ eventId, brandId }) => {
    const columns = [
        {
            title: <TextTitle>Nome</TextTitle>,
            dataIndex: ['node', 'user', 'name'],
            fixed: 'left',
            width: 200,
            ellipsis: true,
            render: (name) => {
                return <span>{name ? name : '---'}</span>
            }
        },
        {
            title: <TextTitle>E-mail</TextTitle>,
            dataIndex: ['node', 'user', 'email'],
            width: 200,
            ellipsis: true,
            render: (email) => {
                return <span>{email ? email : '---'}</span>
            }
        },
        {
            title: <TextTitle>Telefone</TextTitle>,
            dataIndex: ['node', 'data', 'phone'],
            width: 200,
            ellipsis: true,
            render: (phone) => {
                return <span>{phone ? phone : '---'}</span>
            }
        },
        {
            title: <TextTitle>Cargo</TextTitle>,
            dataIndex: ['node', 'data', 'jobTitle'],
            width: 200,
            ellipsis: true,
            render: (jobTitle) => {
                return <span>{jobTitle ? jobTitle : '---'}</span>
            }
        },
        {
            title: <TextTitle>Empresa</TextTitle>,
            dataIndex: ['node', 'data', 'company'],
            width: 200,
            ellipsis: true,
            render: (company) => {
                return <span>{company ? company : '---'}</span>
            }
        }
    ]

    const query = useQuery(GET_BRAND_VIEW_USERS, {
        variables: { eventId, brandId }
    })

    console.log('debug pagination', query)

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <StarOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>
                    Listagem de pessoas que acessaram
                </DashboardTitle>
            </DashboardCardTitle>
            <div className="minimal-padding">
                <CrudList
                    columns={columns}
                    hideAction="all"
                    queryName="brandViewsUser"
                    search={false}
                    showHeader={true}
                    scroll={{ x: '100%' }}
                    sticky
                    transparent
                    {...query}
                />
            </div>
        </>
    )
}

export default ListUsers

