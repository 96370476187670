import React, { forwardRef } from 'react'
import { Editor } from '@tinymce/tinymce-react'

import { EditorWrapper } from './styles'

interface EditorHTMLProps {
    onChange?: (value) => void
    value?: string
    toolbar?: string
}
export const TPEditorHTML = forwardRef<Editor, EditorHTMLProps>(
    ({ onChange, value, toolbar, ...rest }: EditorHTMLProps, ref) => {
        return (
            <EditorWrapper>
                <Editor
                    tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5.5.1/tinymce.min.js"
                    disabled={false}
                    id="uuid"
                    init={{ menubar: false, height: 420 }}
                    initialValue={value}
                    inline={false}
                    onEditorChange={onChange}
                    plugins="link code lists"
                    tagName="div"
                    textareaName=""
                    ref={ref}
                    toolbar={
                        'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code'
                    }
                    value={value}
                    {...rest}
                />
            </EditorWrapper>
        )
    }
)
