import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, message } from 'antd'

import { useQuery, useMutation } from '@apollo/client'
import { useApolloClient } from '@apollo/client'

import { GET_EVENT_SITE } from '@cms/events/graphql/queries/event'
import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'

import { Nav } from '@cms/events/pages/Config/PageBuilder/components'
import { usePageBuilderContext } from '@cms/events/pages/Config/PageBuilder/context/context'
import { PageBuilderRoutes } from '@cms/events/pages/Config/PageBuilder/routes'
import { set, get } from 'lodash'

import Loader from '@cms/core/components/Loader'

const PageBuilder = () => {
    const { eventId } = useParams()
    const { push } = useHistory()
    const {
        setColors,
        setCustomFonts,
        setCustomColors,
        setSite,
        site,
        setConfig,
        config,
        setLoading,
        event,
        setEvent,
        preview,
        formSubmit$
    } = usePageBuilderContext()
    const [updateSite] = useMutation(UPDATE_SITE)

    const { error, data: dataEvent } = useQuery(GET_EVENT_SITE, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    useEffect(() => {
        if (dataEvent) {
            if (!event) {
                setEvent(dataEvent.event)
            }

            if (!site) {
                const { draft } = dataEvent.event.site

                if (draft) {
                    setCustomFonts(draft.theme.customFonts || [])
                    setColors(draft.theme.colors)
                    setCustomColors(draft.theme.customColors)

                    setSite(draft)
                    setConfig(dataEvent.event.site)
                } else push(`/evento/${eventId}/paginas/templates`)
            }
        }
    }, [dataEvent])

    if (!dataEvent) return <Loader fixed={false} padding='10px' />
    if (error) return `Error! ${error.message}`

    const onFormFinish = async (name, { values, forms }) => {
        const currentValues = forms[name].getFieldsValue()
        const { id } = config
        let fields = {}
        setLoading(true)

        try {
            if (currentValues?.sections) {
                fields = {
                    ...site,
                    pages: {
                        ...site.pages,
                        [name]: {
                            ...site.pages[name],
                            sections: currentValues.sections
                        }
                    }
                }
            } else {
                if (currentValues?.colors) setColors(currentValues.colors)
                if (currentValues?.customFonts)
                    setCustomFonts(currentValues.customFonts)

                if (currentValues?.customColors)
                    setCustomColors(currentValues.customColors)

                fields = set({ ...site }, name, {
                    ...get(site, name),
                    ...currentValues
                })
            }

            await updateSite({
                variables: {
                    id: id,
                    draft: fields
                }
            })

            setSite(fields)

            formSubmit$.emit()

            message.success('Configurações atualizadas com sucesso!')

            if (preview) {
                preview.refresh()
            }
        } catch (error) {
            console.log(error)
            message.warning(
                'Não foi possível salvar suas alterações, verifique a conexão de internet e tente novamente.'
            )
        }

        setLoading(false)
    }

    return (
        <>
            {site ? (
                <>
                    <Nav />
                    <Form.Provider onFormFinish={onFormFinish}>
                        <PageBuilderRoutes />
                    </Form.Provider>
                </>
            ) : null}
        </>
    )
}

export default PageBuilder
