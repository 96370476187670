import React from 'react'
import { AffixContainer } from './styles'
import { useMainScroll } from '../AppMainContent/Hooks/useMainScroll'

const Affix = (props) => {
    const { scrollPosition } = useMainScroll()

    return (
        <AffixContainer className={scrollPosition > 0 ? 'bgOn' : ''} {...props}>
            {props.children}
        </AffixContainer>
    )
}

export default Affix
