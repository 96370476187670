import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_FORM_DATA_LIST_ORDERABLE = gql`
    query GetFormData(
        $first: Int,
        $afterCursor: Cursor
        $eventId: ObjectID!
        $uuid: String!
        $orderBy: FormDataOrderBy
        $search: String
    ) {
        formDatas(
            first: $first,
            after: $afterCursor,
            filter: { event: $eventId, uuid: $uuid }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...FormDataData
                }
            }
        }
    }

    ${fragments.FormDataData}
`