import React from 'react'
import styled from 'styled-components'

import {
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    WhatsappIcon,
    LinkIcon
} from '@cms/core/components/Icons'

import { Row, Tag } from 'antd'
import TwiterIcon from '@cms/core/components/Icons/Twitter'

const Title = styled.h5`
    font-size: 18px;
    margin-bottom: 0;

    @media (max-width: 1400px) {
        font-size: 15px;
        margin-bottom: 0;
    }
`
const Content = styled.span`
    color: ${(props) => props.color || '#fff'};
    font-size: 13px;

    @media (max-width: 1400px) {
        font-size: 11px;
        margin-bottom: 0;
    }
`

const WrapperRow = styled(Row)`
    flex-flow: nowrap;
    align-items: center;
    margin-bottom: 5px;
`
const TagIndications = styled(Tag)`
    width: 60px;
    text-align: center;
    border-radius: 0px;
    border-radius: 3px;

    @media (max-width: 1400px) {
        width: 40px;
    }
`

const Line = ({ channel, icon, conversion, totalIndications }) => {
    return (
        <>
            {conversion > 0 && (
                <WrapperRow style={{ justifyContent: 'space-between' }}>
                    <WrapperRow>
                        <span style={{ marginRight: '5px' }}>
                            {icon === 'Facebook' && (
                                <FacebookIcon fill="#003FEE" size={44} />
                            )}
                            {icon === 'Whatsapp' && <WhatsappIcon size={44} />}
                            {icon === 'Twitter' && (
                                <TwiterIcon fill="#29A4D9" size={44} />
                            )}
                            {icon === 'Linkedin' && (
                                <LinkedinIcon fill="#0274b3" size={44} />
                            )}
                            {icon === 'Email' && (
                                <EmailIcon size={44} badge={'#9C6AF9'} />
                            )}
                            {icon === 'Copy' && (
                                <LinkIcon
                                    size={44}
                                    badge={'#EF6C0C'}
                                    fill={'#fff'}
                                />
                            )}
                        </span>
                        <span span={16}>
                            <Title> {channel} </Title>
                            <Content>
                                Conversão{' '}
                                <strong>
                                    {Math.round(
                                        (conversion / totalIndications) * 100
                                    )}
                                    %
                                </strong>
                            </Content>
                        </span>
                    </WrapperRow>
                    <span>
                        <TagIndications color="#7F44FF">
                            {conversion}
                        </TagIndications>
                    </span>
                </WrapperRow>
            )}
        </>
    )
}

export default Line
