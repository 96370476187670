import React, { useState } from 'react'
import styled from 'styled-components'
import { Bagde } from './badge'
import { Popover } from './popover'
import { useControllableValue } from '@umijs/hooks'

export const ColorPickerStyled = styled.div`
    position: relative;
`

const colors = [
    '#1abc9c', '#2ecc71', '#27ae60', '#16a085',
    '#3498db', '#2980b9', '#9b59b6', '#8e44ad',
    '#34495e', '#2c3e50', '#f1c40f', '#f39c12',
    '#e67e22', '#d35400', '#e74c3c', '#c0392b',
    '#7f8c8d'
]

const ColorPicker = function({...props}) {
    const [state, setState] = useControllableValue(props)

    const [ displayColorPicker, setDisplayColorPicker ] = useState(false)

    const handleClick = () => {
        setDisplayColorPicker( !displayColorPicker )
    }

    const handleClose = () => {
        setDisplayColorPicker(false)
    }

    const handleChange = (color) => {
        setState(color.hex)
    }

    return (
        <ColorPickerStyled>
            <Bagde color={ state || '#000' } onClick={ handleClick } />
            { displayColorPicker ?
                <Popover
                    colors={colors}
                    onClose={ handleClose }
                    onChange={ handleChange }
                    value={ state || '#000' }
                />
            : null }
        </ColorPickerStyled>
    )
}

export default ColorPicker