import { ApolloClient, from, split, ApolloLink } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

import { removeObjectProps } from '@cms/events/utils'

import { logout } from '@cms/core/utils/auth'

import { customFetch } from './fetch'
import { cache } from '@cms/core/graphql/cache'

export const ApiHost = process.env.APIHOST_DEV || location.origin

export const WebSocketHost =
    ApiHost.replace('http://', 'ws://').replace('https://', 'wss://') + '/ws'

const uploadLink = createUploadLink({
    uri: `${ApiHost}/graphql`,
    fetch: customFetch
})

const wsLink = new WebSocketLink({
    uri: WebSocketHost,
    options: {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
            const token = localStorage.getItem('@td-token')
            return {
                authorization: token ? `Bearer ${token}` : ''
            }
        }
    }
})

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem('@td-token')
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ''
        }
    }
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.map(({ type }) => {
            if (type === 'Unauthorized') {
                logout()
                if (window.location.pathname !== '/') {
                    window.location = '/'
                }
            }
        })
    }
})

const removeTypeName = new ApolloLink((operation, forward) => {
    const definition = getMainDefinition(operation.query)

    if (definition.operation === 'mutation') {
        operation.variables = removeObjectProps(operation.variables, [
            '__typename'
        ])
    }
    return forward(operation)
})

const splitLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        )
    },
    wsLink,
    from([errorLink, removeTypeName, authLink, uploadLink])
)

const client = new ApolloClient({
    link: splitLink,
    cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            notifyOnNetworkStatusChange: true
        },
        query: {
            fetchPolicy: 'network-only',
            notifyOnNetworkStatusChange: false
        },
        mutate: {}
    },
    connectToDevTools: true
})

export default client

