import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_BANNER_LIST = gql`
    query GetBanners(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $orderBy: BannerOrderBy
        $search: String
    ) {
        banners(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BannerData
                }
            }
        }
    }

    ${fragments.BannerData}
`

export const GET_BANNER_LIST_ORDENABLE = gql`
    query GetBanners(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $orderBy: BannerOrderBy
        $search: String
        $active: Filter
        $pages: Filter
    ) {
        banners(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, active: $active, pages: $pages }
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...BannerData
                }
            }
        }
    }

    ${fragments.BannerData}
`

export const GET_BANNER = gql`
    query GetBanner($id: ObjectID!) {
        banner(id: $id) {
            ...BannerData
        }
    }

    ${fragments.BannerData}
`

export const GET_BANNER_SELECT_LIST = gql`
    query GetBanners(
        $afterCursor: Cursor
        $eventId: ObjectID
        $first: Int
        $orderBy: BannerOrderBy
        $search: String
    ) {
        banners(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
            orderBy: $orderBy
            search: $search
        ) {
            edges {
                node {
                    value: id
                    label: link
                }
            }
        }
    }
`
