import React from 'react'
import { Empty, Typography } from 'antd'

const { Paragraph, Title, Text } = Typography

const MainNumber = ({ title, value, percent }) => {
    return (
        <>
            <Paragraph>{title}</Paragraph>
            {value ? (
                <Title level={1}>
                    {value}
                    {percent && (
                        <Text style={{ fontSize: '14px', marginLeft: '12px' }}>
                            {Number(percent.replace('%', '')).toFixed(0)}%
                        </Text>
                    )}
                </Title>
            ) : (
                <Empty description="Não há dados" />
            )}
        </>
    )
}

export default MainNumber

