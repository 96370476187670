import React from 'react'
import { Form, Select, Divider } from 'antd'

import { ColorsBadges, Uploader } from '..'
import { Wrapper, Title } from '../../styles'

const optionsLayout = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Repetir',
        value: 'repeat'
    }
]
const optionsSize = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Conter',
        value: 'contain'
    },
    {
        label: 'Capa',
        value: 'cover'
    },
    {
        label: 'Não preencher',
        value: 'none'
    }
]

const optionsPosition = [
    {
        label: 'Centro',
        value: 'center center'
    },
    {
        label: 'Topo',
        value: 'top'
    },
    {
        label: 'Baixo',
        value: 'bottom'
    }
]
const ContainerConfig = (props) => {
    const { form, type } = props

    const handleShowImageControls = (prevValues, currentValues) => {
        return (
            prevValues.sections[props.name].configs.container.bgImage?.src !==
            currentValues.sections[props.name].configs.container.bgImage?.src
        )
    }

    const handleShowImageSubControls = (prevValues, currentValues) => {
        return (
            prevValues.sections[props.name].configs.container.bgImage
                ?.layout !==
            currentValues.sections[props.name].configs.container.bgImage?.layout
        )
    }

    return (
        <Wrapper>
            <Title level={4}>Fundo</Title>
            {/* <Divider orientation="left">Cor de fundo</Divider> */}
            <Form.Item
                name={[props.name, 'configs', 'container', 'bgColor']}
                label="Cor de fundo"
            >
                <ColorsBadges />
            </Form.Item>
            {/* <Divider orientation="left">Imagem de fundo</Divider> */}
            <Form.Item
                name={[props.name, 'configs', 'container', 'bgImage', 'src']}
                label="Imagem de fundo"
            >
                <Uploader />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={handleShowImageControls}>
                {({ getFieldValue }) => {
                    const src = getFieldValue([
                        'sections',
                        props.name,
                        'configs',
                        'container',
                        'bgImage',
                        'src'
                    ])
                    return src ? (
                        <>
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'container',
                                    'bgImage',
                                    'layout'
                                ]}
                                label="Preenchimento"
                                rules={[{ required: true }]}
                                initialValue={'fill'}
                            >
                                <Select options={optionsLayout} />
                            </Form.Item>
                            <Form.Item
                                noStyle
                                shouldUpdate={handleShowImageSubControls}
                            >
                                {({ getFieldValue }) => {
                                    const layout = getFieldValue([
                                        'sections',
                                        props.name,
                                        'configs',
                                        'container',
                                        'bgImage',
                                        'layout'
                                    ])
                                    return layout !== 'repeat' ? (
                                        <>
                                            <Form.Item
                                                name={[
                                                    props.name,
                                                    'configs',
                                                    'container',
                                                    'bgImage',
                                                    'objectFit'
                                                ]}
                                                label="Tamanho"
                                                rules={[{ required: true }]}
                                                initialValue={'cover'}
                                            >
                                                <Select options={optionsSize} />
                                            </Form.Item>
                                            <Form.Item
                                                name={[
                                                    props.name,
                                                    'configs',
                                                    'container',
                                                    'bgImage',
                                                    'objectPosition'
                                                ]}
                                                label="Posição"
                                                rules={[{ required: true }]}
                                                initialValue={'center center'}
                                            >
                                                <Select
                                                    options={optionsPosition}
                                                />
                                            </Form.Item>
                                        </>
                                    ) : null
                                }}
                            </Form.Item>
                        </>
                    ) : null
                }}
            </Form.Item>
        </Wrapper>
    )
}

export default ContainerConfig
