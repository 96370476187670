import { gql } from '@apollo/client'

export * from '@cms/core/graphql/mutations/user'

export const BLOCK_TALK_CHAT_USER = gql`
    mutation blockTalkChatUser($id: ObjectID!, $blockDate: DateTime) {
        blockTalkChat(id: $id, input: { blockDate: $blockDate }) {
            id
            blockDate
        }
    }
`
