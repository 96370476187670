import Testimonial from './Testimonial/Testimonial'
import Testimonial2 from './Testimonial2/Testimonial'

import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Testimonials = {
    Testimonial: {
        Component: Testimonial,
        name: 'Depoimentos',
        category: 'Depoimentos',
        columns: '2',
        thumb: require('./Testimonial/testimonial.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Testimonial2: {
        Component: Testimonial2,
        name: 'Depoimentos (Slider)',
        category: 'Depoimentos',
        columns: '1',
        thumb: require('./Testimonial2/testimonial.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                smallText: {
                    text: 'Depoimentos',
                    textColor: getColorContrast(colors, 'primary')
                },
                headline: {
                    text: 'O que estão falando',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que os participantes têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
