import React, { useCallback } from 'react'
import _get from 'lodash/get'
import { Select, Spin } from 'antd'
import { uniqBy } from 'lodash'
import { nanoid } from 'nanoid'

const useCrudSelect = (
    query,
    {
        queryName,
        includeEmpty = false,
        additionalValues = [],
        path = 'edges'
    } = {}
) => {
    const { data = {}, loading, fetchMore } = query

    const pageInfo = data[queryName]?.pageInfo || {}
    const items = uniqBy(
        []
            .concat(includeEmpty ? { node: { id: null } } : [])
            .concat(additionalValues)
            .concat(_get(data[queryName], path, [])),
        'node.id'
    )

    const onScroll = useCallback(
        async (e) => {
            if (loading || !pageInfo.hasNextPage) return

            const target = e.target
            const scrollOffset = target.scrollTop + target.offsetHeight
            const parent = (scrollOffset / target.scrollHeight) * 100

            if (parent >= 80) {
                fetchMore({
                    variables: {
                        afterCursor: pageInfo.endCursor
                    }
                })
            }
        },
        [pageInfo, loading]
    )

    const renderOptions = useCallback(
        ({ key, value, label, groupBy, props } = {}) => {
            const renderOption = (item, index) => (
                <Select.Option
                    key={_get(item, key || value || 'node.id', nanoid())}
                    value={_get(item, key || value || 'node.id')}
                    {...props}
                >
                    {_get(item, label || 'node.id')}
                </Select.Option>
            )

            const options = (() => {
                if (groupBy) {
                    return Object.keys(groupBy.groups).map((group) => (
                        <Select.OptGroup
                            label={groupBy.groups[group]}
                            key={groupBy.groups[group]}
                        >
                            {items
                                .filter(
                                    (item) => _get(item, groupBy.key) == group
                                )
                                .map(renderOption)}
                        </Select.OptGroup>
                    ))
                }
                return items.map(renderOption)
            })()

            if (!loading) return [...options]

            return [
                ...options,
                <Select.Option
                    key="loading"
                    style={{ textAlign: 'center' }}
                    disabled
                >
                    <Spin size="small" />
                </Select.Option>
            ]
        },
        [items, loading]
    )

    const addOptions = useCallback(async (options = []) => {}, [])

    return [
        renderOptions,
        {
            onScroll: onScroll,
            loading
        },
        addOptions
    ]
}

export default useCrudSelect
