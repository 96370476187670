import React from 'react'
import { Form, Typography, Empty, Input, Collapse } from 'antd'

import Sections from '../../../sections'
import Section from './section'
import AddSection from '../../../components/AddSection'
import { nanoid } from 'nanoid'

const { Title } = Typography

const List = (props) => {
    const { activePanel, setSection, form, main, page } = props

    return (
        <>
            <Title level={3} style={{ marginTop: '30px' }}>
                {'Seções'}
            </Title>
            <AddSection index={0} form={form} />
            <Form.List name="sections">
                {(fields, { move, remove }) => {
                    return (
                        <>
                            {fields.length === 0 && (
                                <Empty
                                    description={
                                        <span>
                                            <a>Nenhuma seção foi adicionada</a>
                                        </span>
                                    }
                                >
                                    <AddSection
                                        index={0}
                                        emptySections
                                        form={form}
                                    />
                                </Empty>
                            )}

                            {fields.map((field, key) => {
                                const _fields = form.getFieldsValue()
                                const { type, active, configs } =
                                    _fields.sections[key]

                                const Component = Sections[type]?.Component

                                const extraProps = {
                                    index: field.name,
                                    section: type,
                                    checked: active,
                                    activePanel,
                                    setSection,
                                    form,
                                    move,
                                    remove
                                }

                                return (
                                    <React.Fragment key={field.key}>
                                        <Form.Item
                                            name={[field.name, 'configs', 'id']}
                                            initialValue={'section-' + nanoid()}
                                            style={{ display: 'none' }}
                                        >
                                            <Input />
                                        </Form.Item>
                                        <Section {...extraProps}>
                                            <Component
                                                form={form}
                                                type={type}
                                                {...field}
                                            />
                                        </Section>
                                        <AddSection
                                            index={key + 1}
                                            form={form}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}

export default List
