import React from 'react'
import { Typography, Progress, Row, Col, Badge, Empty } from 'antd'

import { WrapperDots } from './styles'

const { Paragraph, Title } = Typography

const CompareCircle = ({ title, values }) => {
    const colors = ['#4518DB', '#4488F1']

    return (
        <Row>
            <Col span={24}>
                <Paragraph>{title}</Paragraph>
            </Col>
            {values.length > 0 ? (
                <>
                    <Col
                        span={24}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Progress
                            percent={100}
                            strokeColor={colors[1]}
                            success={{
                                percent: Number(
                                    values[0].percent.replace('%', '')
                                ).toFixed(0),
                                strokeColor: colors[0]
                            }}
                            strokeWidth={10}
                            showInfo={false}
                            type="circle"
                        />
                    </Col>
                    <Col span={24} style={{ marginTop: '2em' }}>
                        <Row>
                            {values.map((value, index) => (
                                <Col span={12} key={index}>
                                    <Title
                                        level={3}
                                        style={{ marginBottom: '0' }}
                                    >
                                        {Number(
                                            value.percent.replace('%', '')
                                        ).toFixed(0)}
                                        %
                                    </Title>
                                    <WrapperDots>
                                        <Badge
                                            color={colors[index]}
                                            text={value.name}
                                        />
                                    </WrapperDots>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </>
            ) : (
                <Empty description="Não há dados" />
            )}
        </Row>
    )
}

export default CompareCircle

