import styled, { css } from 'styled-components'
import { Layout, Form, Button } from 'antd'
import { COLORS } from '@cms/core/themes'

const { Content } = Layout

export const Title = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.75em;
    h1 {
        display: inline-block;
    }
`
export const ContentDefault = styled(Content)`
    padding: 0px 0px 115px 0px;
    .ant-row {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
`

export const Wrapper = styled.div`
    position: relative;

    .reward-add {
        background: transparent;
        border: 1px solid ${COLORS.textDark};
        border-radius: 8px;
    }

    .switch-email {
        > .ant-row {
            flex-direction: row;
        }
    }

    .ant-form-item {
        margin-bottom: 0px;
    }
    .ant-upload.ant-upload-select-picture-card {
        margin: 0;
    }

    .inline-form-item {
        .ant-row.ant-form-item {
            flex-direction: row;
            align-content: space-between;
            width: 100%;
            align-items: center;
        }
        .ant-form-item-control-input-content {
            text-align: end;
        }
    }

    .ant-btn[disabled] {
        border-color: rgb(0 0 0 / 17%);
    }
`

export const MoveSection = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-btn {
        height: 100%;
        &:first-child {
            border-radius: 8px 0px 0px 0px;
            border-right: 1px solid rgb(0 0 0 / 17%);
        }
        &:last-child {
            border-radius: 0px;
            border-right: none;
        }
    }
`

export const MoveButton = styled(Button)`
    width: 50%;
    border-radius: 8px 0px 0px 0px;
    border-color: transparent;
    font-size: 12px;
    line-height: 1.1em;
    height: 26px;
    border-right: 1px solid rgb(0 0 0 / 17%);
    border-bottom: none;
    ${({ $side }) =>
        $side === 'right' &&
        css`
            border-radius: 0px 8px 0px 0px;
            border-right: none;
        `}
`

