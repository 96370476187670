import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_BANNER = gql`
    mutation CreateBanner(
        $link: String
        $desktop: MediaInput
        $mobile: MediaInput
        $pages: [String]
        $active: Boolean
        $event: ObjectID!
        $talks: [ObjectID]
        $tickets: [ObjectID]
        $integrations: [ObjectID]
    ) {
        createBanner(
            input: {
                link: $link
                desktop: $desktop
                mobile: $mobile
                pages: $pages
                active: $active
                event: $event
                talks: $talks
                tickets: $tickets
                integrations: $integrations
            }
        ) {
            ...BannerData
        }
    }

    ${fragments.BannerData}
`

export const UPDATE_BANNER = gql`
    mutation UpdateBanner(
        $id: ObjectID!
        $link: String
        $desktop: MediaInput
        $mobile: MediaInput
        $pages: [String]
        $active: Boolean
        $event: ObjectID!
        $talks: [ObjectID]
        $tickets: [ObjectID]
        $integrations: [ObjectID]
    ) {
        updateBanner(
            id: $id
            input: {
                link: $link
                desktop: $desktop
                mobile: $mobile
                pages: $pages
                active: $active
                event: $event
                talks: $talks
                tickets: $tickets
                integrations: $integrations
            }
        ) {
            ...BannerData
        }
    }

    ${fragments.BannerData}
`

export const DELETE_BANNER = gql`
    mutation DeleteBanner($id: ObjectID!) {
        deleteBanner(id: $id) {
            id
            success
        }
    }
`
