import React, { useEffect } from 'react'
import { Form, Row, Collapse } from 'antd'
import AceEditor from 'react-ace'

import { PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'

const { Panel } = Collapse

const WebScripts = (props) => {
    const [form] = Form.useForm()
    const { site } = usePageBuilderContext()

    useEffect(() => {
        form.setFieldsValue(site.scripts)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24}>
            <ColForm md={12} sm={24}>
                <Form
                    name="scripts"
                    form={form}
                    // onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Collapse style={{ marginBottom: '50px' }}>
                        <Panel header="No header" key="1">
                            <Form.Item label="" name={['header']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                        <Panel header="Na abertura do body" key="2">
                            <Form.Item label="" name={['bodyStart']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                        <Panel header="No fechamento do body" key="3">
                            <Form.Item label="" name={['bodyEnd']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <PanelFooter />
                </Form>
            </ColForm>
        </Row>
    )
}

export default WebScripts
