import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_COLLECTION_CATEGORIES_LIST = gql`
    query GetCollectionCategories(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
    ) {
        collectionCategories(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CollectionCategoryData
                }
            }
        }
    }

    ${fragments.CollectionCategoryData}
`

export const GET_COLLECTION_CATEGORIES_LIST_ORDERABLE = gql`
    query GetCollectionCategories(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: CollectionCategoryOrderBy
        $search: String
        $name: Filter
    ) {
        collectionCategories(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, name: $name }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...CollectionCategoryData
                }
            }
        }
    }

    ${fragments.CollectionCategoryData}
`

export const GET_COLLECTION_CATEGORY = gql`
    query GetCollectionCategory($id: ObjectID!) {
        collectionCategory(id: $id) {
            ...CollectionCategoryData
        }
    }

    ${fragments.CollectionCategoryData}
`
export const GET_COLLECTION_CATEGORIES_SELECT_LIST = gql`
    query GetCollectionCategoriesSelectList($eventId: ObjectID!) {
        collectionCategories(filter: { event: $eventId }) {
            edges {
                node {
                    value: id
                    label: name
                }
            }
        }
    }
`

export const GET_COLLECTION_CATEGORY_TAGS = gql`
    query GetCollectionCategoryTags($eventId: ObjectID!) {
        collectionCategoryTags(event: $eventId)
    }
`

