import React from 'react'
import { Form, Input, Select } from 'antd'

const { Option } = Select

const SelectPreview = ({ options }) => {
    return (
        <Select placeholder="Selecione uma opção">
            {options.map((option, key) => (
                <Option key={key} {...option} />
            ))}
        </Select>
    )
}
export default SelectPreview
