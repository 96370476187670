import { Collapse, Row } from 'antd'
import styled from 'styled-components'

export const StyledCollapse = styled(Collapse)`
    .panel-header {
        margin-bottom: 0;
    }

    .ant-collapse-item > .ant-collapse-header {
        cursor: default;
        color: white;
    }
`
