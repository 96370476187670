import React from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import NotificationCard from '@cms/events/components/NotificationCard'
import { Button, Col, message, Row } from 'antd'
import { Link } from 'react-router-dom'
import { Wrapper } from './styles'
import { useMutation, useQuery } from '@apollo/client'
import { GET_TALK_NOTIFICATION_LIST } from '@cms/events/graphql/queries/talkNotification'
import {
    DELETE_TALK_NOTIFICATION,
    SEND_TALK_NOTIFICATION,
    UPDATE_TALK_NOTIFICATION
} from '@cms/events/graphql/mutations/talkNotification'

const ModalContent = ({ talk, eventId, path }) => {
    const { data, refetch } = useQuery(GET_TALK_NOTIFICATION_LIST, {
        variables: {
            eventId,
            talkId: talk
        }
    })
    const [updateTalkNotification] = useMutation(UPDATE_TALK_NOTIFICATION)
    const [deleteTalkNotification] = useMutation(DELETE_TALK_NOTIFICATION)
    const [sendTalkNotification] = useMutation(SEND_TALK_NOTIFICATION)

    const handleDelete = async (id) => {
        await deleteTalkNotification({ variables: { id } }), refetch()
        message.warn('Notificação deletada com sucesso!')
    }
    const handleSendNotification = async (id) => {
        await sendTalkNotification({ variables: { id } }), refetch()
        message.success('Notificação enviada com sucesso!')
    }
    const handleChat = async (id, checked) => {
        await updateTalkNotification({
            variables: {
                id,
                event: eventId,
                talk,
                chat: checked
            }
        })
        refetch()
        message.success('Notificação atualizada com sucesso!')
    }
    const handlePopup = async (id, checked) => {
        await updateTalkNotification({
            variables: {
                id,
                event: eventId,
                talk,
                popup: checked
            }
        })
        refetch()
        message.success('Notificação atualizada com sucesso!')
    }

    if (!data) return null

    return (
        <Wrapper>
            <Col>
                {data.talkNotifications.edges.map((notification) => (
                    <NotificationCard
                        notification={notification}
                        handleDelete={handleDelete}
                        handleChat={handleChat}
                        handlePopup={handlePopup}
                        handleSendNotification={handleSendNotification}
                        path={path}
                    />
                ))}
            </Col>
        </Wrapper>
    )
}

export default ModalContent
