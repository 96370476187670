import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import User from '@cms/core/pages/User'
import PrivateRoute from './privateRoute'
import ProfileList from '../pages/Team/Profiles'
import TeamList from '../pages/Team/Users'
import ChangePassword from '../pages/ChangePassword'

export default function SettingsRoutes() {
    return (
        <Switch>
            <Redirect exact path="/" to={{ pathname: '/perfil/usuario' }} />
            <PrivateRoute path={`/perfil/usuario`} component={User} />
            <PrivateRoute
                path={`/perfil/alterar-senha`}
                component={ChangePassword}
            />

            <PrivateRoute
                checkModulePath
                path={`/time/usuarios`}
                component={TeamList}
            />
            <PrivateRoute
                checkModulePath
                path={`/time/profiles`}
                component={ProfileList}
            />
        </Switch>
    )
}
