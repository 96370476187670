import Stages from './Stages'

export const StageSections = {
    stages: {
        Component: Stages,
        name: 'stages',
        category: 'Palcos',
        label: 'Palcos',
        description: 'Palestras de um palco específico',
        defaultConfig: {
            title: {
                'pt-BR': 'Palcos',
                'en-US': 'Stages',
                'es-ES': 'Escenarios',
                'pt-PT': 'Palcos'
            },
            variant: 'stage',
            condition: {
                type: 'AND'
            }
        }
    }
}
