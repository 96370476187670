import { Button, Col, Form, Row, Typography } from 'antd'

import React from 'react'
import { CustomCollapse, AddItem } from '../styles'
import { RiAddFill } from '@remixicon/react'
import CourseModule from './module'
import { Route, useParams } from 'react-router-dom'
import ContentModal from '@cms/watch/pages/Contents/ContentsModal'
import ContentLibrary from './library'

const { Paragraph } = Typography

const StepModules = () => {
    const form = Form.useFormInstance()
    const { courseId } = useParams<{ courseId: string }>()

    const path = `/cursos/${courseId}/editar`
    const ModalRoutes = (
        <>
            <Route
                exact
                path={`${path}/:moduleName/conteudo/adicionar`}
                render={({ match }) => {
                    const { moduleName } = match.params

                    return (
                        <ContentModal
                            path={path + '#step-1'}
                            mode="write"
                            params={{ module: 'COURSE' }}
                            onSaved={(data) => {
                                const contents =
                                    form.getFieldValue([
                                        'modules',
                                        moduleName,
                                        'contents'
                                    ]) || []

                                form.setFieldValue(
                                    ['modules', moduleName, 'contents'],
                                    [...contents, data]
                                )

                                form.submit()
                            }}
                        />
                    )
                }}
            />
            <Route
                exact
                path={`${path}/:moduleName/conteudo/:id/editar`}
                render={({ match }) => {
                    const { moduleName, id } = match.params

                    return (
                        <ContentModal
                            path={path + '#step-1'}
                            mode="write"
                            params={{ module: 'COURSE' }}
                            onSaved={(data) => {
                                const contents =
                                    form.getFieldValue([
                                        'modules',
                                        moduleName,
                                        'contents'
                                    ]) || []

                                form.setFieldValue(
                                    ['modules', moduleName, 'contents'],
                                    contents.map((c: any) =>
                                        c.id === id ? data : c
                                    )
                                )

                                form.submit()
                            }}
                        />
                    )
                }}
            />

            <Route
                exact
                path={`${path}/:moduleName/conteudo/biblioteca`}
                render={({ match }) => {
                    const { moduleName } = match.params

                    return (
                        <ContentLibrary
                            path={path + '#step-1'}
                            onFinished={(items) => {
                                const contents =
                                    form.getFieldValue([
                                        'modules',
                                        moduleName,
                                        'contents'
                                    ]) || []

                                form.setFieldValue(
                                    ['modules', moduleName, 'contents'],
                                    [
                                        ...contents,
                                        ...items.filter(
                                            (item) =>
                                                !contents.some(
                                                    (content: any) =>
                                                        content.id === item.id
                                                )
                                        )
                                    ]
                                )
                                form.submit()
                            }}
                        />
                    )
                }}
            />
        </>
    )

    return (
        <>
            {ModalRoutes}
            <Row>
                <Col span={24}>
                    <Form.List name="modules">
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    <CustomCollapse
                                        defaultActiveKey={['0']}
                                        accordion
                                        style={{
                                            display:
                                                fields.length === 0
                                                    ? 'none'
                                                    : 'block'
                                        }}
                                        size="small"
                                    >
                                        {fields.map((o) => (
                                            <CourseModule
                                                key={o.name}
                                                path={path}
                                                field={o}
                                                remove={remove}
                                            />
                                        ))}
                                    </CustomCollapse>
                                    {fields.length === 0 ? (
                                        <>
                                            <Paragraph>
                                                Módulos são agrupamentos de
                                                conteúdos relacionados. <br />
                                                Adicione um módulo para começar
                                                a adicionar conteúdos.
                                            </Paragraph>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    add({
                                                        name: 'Novo Modulo'
                                                    })
                                                    form.submit()
                                                }}
                                            >
                                                Adicionar
                                            </Button>
                                        </>
                                    ) : (
                                        <AddItem
                                            plain
                                            dashed
                                            className="divider"
                                            onClick={() => {
                                                add({
                                                    name: 'Novo Modulo'
                                                })
                                            }}
                                        >
                                            <RiAddFill />
                                        </AddItem>
                                    )}
                                </>
                            )
                        }}
                    </Form.List>
                </Col>
            </Row>
        </>
    )
}

export default StepModules
