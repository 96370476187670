import styled from 'styled-components'

export const StyledCard = styled.div`
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: rgba(255, 255, 255, 0.2);

    padding: 20px;
    margin-bottom: 20px;

    border-radius: 20px;
`

export const StyledContent = styled.div``

export const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`
