import React from 'react'
import { Form, Input, Button, Divider, Collapse } from 'antd'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'

import { ContainerConfig } from '../../components'
import { Wrapper, Title, TitleWrapper } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'
const { Panel } = Collapse
const EventNumbers = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Colunas</Title>
                <Form.List name={[props.name, 'configs', 'columns']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            key={key}
                                            header={'Coluna ' + (i + 1)}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Wrapper>
                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        name,
                                                        'title',
                                                        'text'
                                                    ]}
                                                    label="Título"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[
                                                        name,
                                                        'subtitle',
                                                        'text'
                                                    ]}
                                                    label="Subtítulo"
                                                >
                                                    <RichTextEditor />
                                                </Form.Item>
                                            </Wrapper>
                                        </Panel>
                                    )
                                )}
                            </Collapse>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                    disabled={fields.length >= 4}
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar Coluna
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Wrapper>
        </>
    )
}

export default EventNumbers
