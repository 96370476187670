import React from 'react'

import { WidgetProvider } from './context/widgets'
import { WidgetsMain } from './routes/widgets'

import { Container } from './styles'

const Widgets = () => {
    return (
        <Container>
            <WidgetProvider>
                <WidgetsMain />
            </WidgetProvider>
        </Container>
    )
}

export default Widgets
