import React from 'react'
import Icon from '@ant-design/icons'

const PlaySvg = () => (
    <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.532 6.81248L0.58275 12.112C0.526279 12.1496 0.460673 12.1711 0.392919 12.1744C0.325164 12.1776 0.2578 12.1624 0.197999 12.1304C0.138199 12.0984 0.0882027 12.0507 0.0533352 11.9925C0.0184677 11.9344 3.50403e-05 11.8678 0 11.8V1.20098C3.50403e-05 1.13315 0.0184677 1.06659 0.0533352 1.00841C0.0882027 0.950227 0.138199 0.902589 0.197999 0.870573C0.2578 0.838556 0.325164 0.823359 0.392919 0.8266C0.460673 0.829841 0.526279 0.8514 0.58275 0.888978L8.532 6.18848C8.58336 6.22272 8.62547 6.26912 8.6546 6.32355C8.68372 6.37797 8.69896 6.43875 8.69896 6.50048C8.69896 6.56221 8.68372 6.62298 8.6546 6.67741C8.62547 6.73184 8.58336 6.77823 8.532 6.81248Z" fill="#FBFCFF"/>
    </svg>
)

export const IconPlay = (props) => <Icon component={PlaySvg} {...props} />
