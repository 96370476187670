import React from 'react'
import { message, Form, Input } from 'antd'

import { useApolloClient } from '@apollo/client'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import {
    GET_USER,
    GET_SPONSORS_LIST,
    GET_USERS_BY_ROLE
} from '@cms/events/graphql/queries/user'
import {
    CREATE_OR_UPDATE_USER,
    UPDATE_USER
} from '@cms/events/graphql/mutations/user'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'

const ChiefUserModal = ({ ...props }) => {
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_USER,
                variables: { id }
            })

            return { ...data.user }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_OR_UPDATE_USER,
                variables: { ...formData, role: 'chief' },
                refetchQueries: [
                    { query: GET_SPONSORS_LIST },
                    {
                        query: GET_USERS_BY_ROLE,
                        variables: {
                            first: 25,
                            orderBy: { name: 'ASC' },
                            role: 'chief'
                        }
                    }
                ]
            })

            message.success('Executivo cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER,
                variables: { id, ...formData, role: 'chief' },
                refetchQueries: [{ query: GET_SPONSORS_LIST }]
            })

            message.success('Executivo atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Executivo' : 'Adicionar Executivo'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <IgnorePasswordAutoComplete />

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                        { required: true, message: 'E-mail é obrigatório!' }
                    ]}
                    {...getError('email')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    {...getError('password')}
                >
                    <Input.Password placeholder={isEdit ? '**********' : ''} />
                </Form.Item>

                <Form.Item label="Avatar" name="avatar" {...getError('avatar')}>
                    <MediaUploader type="image" />
                </Form.Item>

                <Form.Item
                    label="Cargo"
                    name="jobTitle"
                    {...getError('jobTitle')}
                >
                    <Input />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default ChiefUserModal
