import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_TALK_NOTIFICATION_LIST = gql`
    query GetNotifications(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $talkId: ObjectID
    ) {
        talkNotifications(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, talk: $talkId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TalkNotificationData
                }
            }
        }
    }

    ${fragments.TalkNotificationData}
`

export const GET_NOTIFICATION_LIST_ORDERABLE = gql`
    query GetNotifications(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $talkId: ObjectID!
        $orderBy: TalkNotificationOrderBy
        $search: String
    ) {
        talkNotifications(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, talkId: $talkId }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TalkNotificationData
                }
            }
        }
    }

    ${fragments.TalkNotificationData}
`

export const GET_TALK_NOTIFICATION = gql`
    query GetTalkNotification($id: ObjectID!) {
        talkNotification(id: $id) {
            ...TalkNotificationData
        }
    }

    ${fragments.TalkNotificationData}
`
