import React from 'react'

import {
    Row,
    Col,
    Typography,
    Layout,
    Form,
    Input,
    Radio,
    Collapse,
    Card
} from 'antd'
import { ContentDefault } from '../Reward/Add/styles'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import EmailEditor from '@cms/core/components/EmailEditor'
import EditorPreview from '@cms/core/components/EmailEditor/Preview'
import ColorPicker from '@cms/events/components/ColorPicker'

import { ContentEditEmail } from './styles'

const { Title, Paragraph } = Typography
const { Panel } = Collapse

const ModalContent = ({ field, title, variables = 'referrer' }) => {
    return (
        <Layout>
            <ContentDefault>
                <ContentEditEmail className="td-content-default">
                    <Row style={{ padding: '0 20px 20px 20px' }}>
                        <Col span={24}>
                            <Title
                                level={2}
                                style={{ textAlign: 'left', marginTop: 0 }}
                            >
                                {title}
                            </Title>
                            <Paragraph style={{ textAlign: 'left' }}>
                                Alguns detalhes sobre a campanha
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} style={{ padding: '20px' }}>
                            <Card>
                                <Row>
                                    <Col span={24}>Informações básicas</Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'nameFrom'
                                            ]}
                                            label="De"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Enviado por" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {/* <Row>
                                <Col span={24}>
                                    <Form.Item
                                        style={{ textAlign: 'left' }}
                                        name={[
                                            ...field,
                                            'emailTemplate',
                                            'emailFrom'
                                        ]}
                                        label="E-mail"
                                        rules={[
                                            { required: true, type: 'email' }
                                        ]}
                                    >
                                        <Input placeholder="E-mail" />
                                    </Form.Item>
                                </Col>
                            </Row> */}
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'subject'
                                            ]}
                                            label="Assunto"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Assunto" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'replyTo'
                                            ]}
                                            label="Responder para"
                                            rules={[
                                                {
                                                    required: true,
                                                    type: 'email'
                                                }
                                            ]}
                                        >
                                            <Input placeholder="Responder para" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>Conteúdo</Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'sayHello'
                                            ]}
                                            label="Saudação"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Enviado por" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'content'
                                            ]}
                                            label="Conteúdo"
                                            rules={[{ required: true }]}
                                        >
                                            <EmailEditor />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Collapse>
                                            {variables === 'referrer' && (
                                                <Panel header="Variáveis">
                                                    <p>{`{{ REFERRAL_NAME }} para acessar o nome da pessoa indicada.`}</p>
                                                    <p>{`{{ REFERRAL_EMAIL }} para acessar o email da pessoa indicada.`}</p>
                                                    <p>{`{{ REFERRER_NAME }} para acessar o nome da pessoa que indicou.`}</p>
                                                    <p>{`{{ REFERRER_EMAIL }} para acessar o email da pessoa que indicou.`}</p>
                                                </Panel>
                                            )}
                                            {variables === 'basic' && (
                                                <Panel header="Variáveis">
                                                    <p>{`{{ NAME }} Nome do usuário.`}</p>
                                                    <p>{`{{ EMAIL }} E-mail do Usuário.`}</p>
                                                </Panel>
                                            )}
                                        </Collapse>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'button',
                                                'text'
                                            ]}
                                            label="Texto do botão"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Texto do botão" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'button',
                                                'link'
                                            ]}
                                            label="Link do botão"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Link do botão" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'button',
                                                'backgroundColor'
                                            ]}
                                            label="Cor de fundo do botão"
                                            rules={[{ required: true }]}
                                        >
                                            <ColorPicker />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'button',
                                                'textColor'
                                            ]}
                                            label="Cor do botão"
                                            rules={[{ required: true }]}
                                        >
                                            <ColorPicker />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'thankYouMessage'
                                            ]}
                                            label="Mensagem de agradecimento"
                                            rules={[{ required: true }]}
                                        >
                                            <Input placeholder="Mensagem de agradecimento" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'sizeMedia'
                                            ]}
                                            label="Imagem cabeçalho"
                                        >
                                            <Radio.Group>
                                                <Radio value={'imagem'}>
                                                    Imagem
                                                </Radio>
                                                <Radio value={'logo'}>
                                                    Logo
                                                </Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Form.Item
                                            style={{ textAlign: 'left' }}
                                            name={[
                                                ...field,
                                                'emailTemplate',
                                                'media'
                                            ]}
                                            label="Imagem/Logo"
                                        >
                                            <MediaUploader />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={14} style={{ padding: '20px' }}>
                            <Form.Item
                                style={{ textAlign: 'left' }}
                                name={[...field, 'emailTemplate']}
                            >
                                <EditorPreview />
                            </Form.Item>
                        </Col>
                    </Row>
                </ContentEditEmail>
            </ContentDefault>
        </Layout>
    )
}

export default ModalContent

