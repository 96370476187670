// load all apps index.ts

import { IApp, IAppModule } from './types/app'

import events from '@cms/events'
import admin from '@cms/admin'
import watch from '@cms/watch'
import main from '@cms/core/MainApp'

export const Apps = {
    main,
    events,
    admin,
    watch
}

export const AllAppsModules: IAppModule[] = Object.values(Apps).reduce(
    (acc, app: IApp) => {
        return acc.concat(app.modules)
    },
    []
)
