import React, { useEffect, useState } from 'react'
import { Layout, Row, Col, Button } from './styles'

const { Content } = Layout

import Iframe from './Templates/Render'
import { COLORS } from '@cms/core/themes'
import { Card } from 'antd'

const EditorPreview = ({ value }) => {
    return (
        <Card>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <div>
                        <b>Enviado por: </b> {value ? value.nameFrom : ''}
                    </div>
                    <div>
                        <b>Responder para: </b> {value ? value.replyTo : ''}
                    </div>
                    <div>
                        <b>Assunto: </b> {value ? value.subject : ''}
                    </div>
                </Col>
            </Row>
            <Content
                style={{
                    backgroundColor: '#f2f2f5',
                    textAlign: 'center',
                    padding: '20px',
                    height: '800px'
                }}
            >
                <Iframe data={value} />
            </Content>
        </Card>
    )
}

export default EditorPreview
