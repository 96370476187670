import React from 'react'
import { Divider } from 'antd'

import { Wrapper, Title } from '../../styles'

const WrapperConfig = (props) => {
    const { inner, children, title } = props

    if (inner) {
        return (
            <>
                <Divider orientation="left">{title}</Divider>
                {children}
            </>
        )
    }

    return (
        <Wrapper>
            <Title level={4}>{title}</Title>
            {children}
        </Wrapper>
    )
}

export default WrapperConfig
