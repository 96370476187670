import Topic from './Topic'
import Topic2 from './Topic2'
import Topic3 from './Topic3'
import Topic4 from './Topic4'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Topics = {
    Topic: {
        Component: Topic,
        name: 'Topicos',
        category: 'Tópicos',
        columns: '2',
        thumb: require('./topics.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                },
                topics: {
                    columns: 8
                }
            }
        }
    },
    Topic2: {
        Component: Topic2,
        name: 'Topicos horizontais',
        category: 'Tópicos',
        columns: '1',
        thumb: require('./topics2.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Topic3: {
        Component: Topic3,
        name: 'Topicos horizontais 2',
        category: 'Tópicos',
        columns: '1',
        thumb: require('./topics3.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Tópicos',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Topic4: {
        Component: Topic4,
        name: 'Topicos - Blocos',
        category: 'Tópicos',
        columns: '1',
        thumb: require('./topics4.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'O que estamos preparando pra esse grande evento:',
                    textColor: 'white'
                },
                subtitle: {
                    text: 'Pela primeira vez na história, reuniremos os maiores experts do mundo para\n desvendar os mistérios que ainda cercam o Metaverso. ',
                    textColor: 'white'
                },
                topics: {
                    bgColor: 'secondary',
                    borderColor: 'white',
                    columns: 6
                }
            }
        }
    }
}
