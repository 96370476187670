import React, { createContext, useContext } from 'react'
import { ISegmentConfigs, ISegmentProviderProps, ISectionType } from './types'

interface Props extends ISegmentProviderProps {
    children: React.ReactNode
}

const defaultConfigs: ISegmentConfigs = {
    formName: 'sections',
    hiddenFields: null
}

const SegmentContext = createContext<{
    filters: ISectionType
    configs: ISegmentConfigs
}>({
    filters: {},
    configs: defaultConfigs
})

export const useSegment = () => useContext(SegmentContext)

export const SegmentProvider = ({
    filters,
    children,
    configs = defaultConfigs
}: Props) => {
    return (
        <SegmentContext.Provider value={{ filters, configs }}>
            {children}
        </SegmentContext.Provider>
    )
}

