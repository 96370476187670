import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_TALK = gql`
    mutation CreateTalk(
        $event: ObjectID!
        $hostType: TalkHostType
        $title: String
        $description: String
        $date: DateTime
        $duration: Int
        $featured: Boolean
        $video: TalkVideoInput
        $videoWeb: TalkVideoInput
        $videos: [TalkVideosInput]
        $iframe: TalkIframeInput
        $categories: [ObjectID]
        $speakers: [ObjectID]
        $brands: [ObjectID]
        $sponsorTmp: ObjectID
        $hiddenInHome: Boolean
        $image: MediaInput
        $imageLive: MediaInput
        $imageLiveApp: MediaInput
        $featureImage: MediaInput
        $type: TalkType
        $priority: Int
        $location: ObjectID
        $tags: [String]
        $stage: ObjectID
    ) {
        createTalk(
            input: {
                event: $event
                hostType: $hostType
                title: $title
                description: $description
                date: $date
                duration: $duration
                video: $video
                videoWeb: $videoWeb
                videos: $videos
                iframe: $iframe
                featured: $featured
                categories: $categories
                speakers: $speakers
                brands: $brands
                sponsorTmp: $sponsorTmp
                hiddenInHome: $hiddenInHome
                image: $image
                imageLive: $imageLive
                imageLiveApp: $imageLiveApp
                featureImage: $featureImage
                type: $type
                priority: $priority
                location: $location
                tags: $tags
                stage: $stage
            }
        ) {
            ...TalkData
        }
    }

    ${fragments.TalkData}
`

export const UPDATE_TALK = gql`
    mutation UpdateTalk(
        $id: ObjectID!
        $hostType: TalkHostType
        $event: ObjectID!
        $title: String
        $description: String
        $date: DateTime
        $duration: Int
        $video: TalkVideoInput
        $videos: [TalkVideosInput]
        $resources: [TalkResourceInput]
        $videoWeb: TalkVideoInput
        $iframe: TalkIframeInput
        $featured: Boolean
        $categories: [ObjectID]
        $speakers: [ObjectID]
        $brands: [ObjectID]
        $sponsorTmp: ObjectID
        $hiddenInHome: Boolean
        $image: MediaInput
        $imageLive: MediaInput
        $imageLiveApp: MediaInput
        $featureImage: MediaInput
        $status: TalkStatus
        $type: TalkType
        $nextTalk: ObjectID
        $priority: Int
        $location: ObjectID
        $tags: [String]
        $stage: ObjectID
    ) {
        updateTalk(
            id: $id
            input: {
                event: $event
                hostType: $hostType
                title: $title
                description: $description
                date: $date
                duration: $duration
                video: $video
                videos: $videos
                videoWeb: $videoWeb
                iframe: $iframe
                featured: $featured
                categories: $categories
                speakers: $speakers
                resources: $resources
                brands: $brands
                sponsorTmp: $sponsorTmp
                image: $image
                imageLive: $imageLive
                featureImage: $featureImage
                hiddenInHome: $hiddenInHome
                imageLiveApp: $imageLiveApp
                status: $status
                type: $type
                nextTalk: $nextTalk
                priority: $priority
                location: $location
                tags: $tags
                stage: $stage
            }
        ) {
            ...TalkData
        }
    }

    ${fragments.TalkData}
`

export const DELETE_TALK = gql`
    mutation DeleteTalk($id: ObjectID!) {
        deleteTalk(id: $id) {
            id
            success
        }
    }
`
