import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_ORDER_LIST_ORDENABLE = gql`
    query GetOrder(
        $first: Int
        $afterCursor: Cursor
        $orderBy: OrderOrderBy
        $filter: OrderFilters
    ) {
        orders(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            filter: $filter
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...OrderData
                }
            }
        }
    }

    ${fragments.OrderData}
`

export const GET_ORDER_LIST = gql`
    query GetOrderList($first: Int, $afterCursor: Cursor) {
        orders(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...OrderData
                }
            }
        }
    }

    ${fragments.OrderData}
`

export const GET_ORDER = gql`
    query GetOrder($id: ObjectID!) {
        order(id: $id) {
            ...OrderData
        }
    }

    ${fragments.OrderData}
`
