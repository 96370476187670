import Schedule from './Schedule/Schedule'
import Schedule2 from './Schedule2/Schedule'
import Schedule3 from './Schedule3/Schedule'

import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Schedules = {
    Scheduled: {
        Component: Schedule,
        name: 'Agenda',
        category: 'Agenda',
        thumb: require('./Schedule/schedule.png'),
        columns: '1',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'secondary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    textColor: 'white',
                    text: 'Confira os horários\ndas palestras e os palestrantes'
                },
                box: {
                    bgColor: 'white',
                    textColor: getColorContrast(colors, 'primary'),
                    time: {
                        textColor: 'primary'
                    },
                    hostType: {
                        bgColor: 'primary'
                    }
                }
            }
        }
    },
    Schedule2: {
        Component: Schedule2,
        name: 'Agenda detalhada',
        category: 'Agenda',
        thumb: require('./Schedule2/schedule.png'),
        columns: '1',
        create: async (site) => {
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                aligment: 'center',
                dateCarousel: {
                    button: {
                        bgColor: 'primary',
                        textColor: 'white',
                        activeStyles: {
                            bgColor: 'secondary',
                            textColor: 'white'
                        }
                    },
                    arrowColor: {
                        textColor: 'black'
                    }
                },
                headline: {
                    textColor: 'black',
                    text: '<div class="td-align-center">Confira os horários das palestras e os palestrantes</div>'
                },
                speakerCarousel: {
                    arrowColor: {
                        textColor: 'white'
                    }
                },
                speakerCard: {
                    textColor: 'white',
                    bgColor: 'secondary',
                    title: {
                        textColor: 'white'
                    },
                    arrowColor: {
                        textColor: 'white'
                    }
                },
                box: {
                    bgColor: 'primary',
                    textColor: 'white',
                    title: {
                        textColor: 'white'
                    },
                    time: {
                        bgColor: 'primary',
                        textColor: 'white'
                    },
                    hostType: {
                        bgColor: 'primary'
                    },
                    addToCalendar: {
                        bgColor: 'secondary',
                        textColor: 'white'
                    }
                }
            }
        }
    },
    Schedule3: {
        Component: Schedule3,
        name: 'Agenda detalhada - filtro por palco',
        category: 'Agenda',
        thumb: require('./Schedule3/schedule.png'),
        columns: '1',
        create: async (site) => {
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                aligment: 'center',
                dateCarousel: {
                    button: {
                        bgColor: 'primary',
                        textColor: 'white',
                        activeStyles: {
                            bgColor: 'secondary',
                            textColor: 'white'
                        }
                    },
                    arrowColor: {
                        textColor: 'black'
                    }
                },
                headline: {
                    textColor: 'black',
                    text: '<div class="td-align-center">Confira os horários das palestras e os palestrantes</div>'
                },
                speakerCarousel: {
                    arrowColor: {
                        textColor: 'white'
                    }
                },
                speakerCard: {
                    textColor: 'white',
                    bgColor: 'secondary',
                    title: {
                        textColor: 'white'
                    },
                    arrowColor: {
                        textColor: 'white'
                    }
                },
                box: {
                    bgColor: 'primary',
                    textColor: 'white',
                    title: {
                        textColor: 'white'
                    },
                    time: {
                        bgColor: 'primary',
                        textColor: 'white'
                    },
                    hostType: {
                        bgColor: 'primary'
                    },
                    addToCalendar: {
                        bgColor: 'secondary',
                        textColor: 'white'
                    }
                }
            }
        }
    }
}
