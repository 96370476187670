import React, { useEffect, useState } from 'react'
import { displayIfNot } from '@cms/core/utils'

import { Form, Input, Row, Col, DatePicker } from 'antd'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import { RadioButton, RadioGroup } from '@cms/core/components/Radio'
import { languages } from '@cms/core/components/ThemeBuilder/constants/languages'
import SelectSearch from '@cms/core/components/SelectSearch'

const EventInfo = ({ form, getError, edit = false }) => {
    const [hostType, setHostType] = useState('DIGITAL')

    const toggleHostType = (event) => {
        setHostType(event.target.value)

        if (!edit && event.target.value !== 'HYBRID') {
            form.setFieldsValue({ address: '' })
        }
    }

    useEffect(() => {
        if (form.getFieldsValue(['hostType']).hostType === 'HYBRID') {
            setHostType('HYBRID')
        }
    }, [])

    return (
        <>
            <Form.Item name="hostType">
                <RadioGroup
                    onChange={toggleHostType}
                    defaultValue={hostType}
                    buttonStyle="solid"
                >
                    <RadioButton value="DIGITAL">Evento Digital</RadioButton>
                    <RadioButton value="HYBRID">Evento Híbrido</RadioButton>
                </RadioGroup>
            </Form.Item>

            {edit && (
                <Form.Item label="Logo" name="logo">
                    <MediaUploader type="svg" style="image" />
                </Form.Item>
            )}

            <Form.Item
                name="name"
                label="Nome"
                rules={[{ required: true, message: 'Preencha o nome' }]}
            >
                <Input placeholder="Nome do evento" />
            </Form.Item>

            <Form.Item name="tag" label="Tag do evento">
                <Input placeholder="Tag do evento" />
            </Form.Item>

            <Form.Item name="description" label="Descrição">
                <Input.TextArea placeholder="Descrição do evento" />
            </Form.Item>

            <Form.Item
                style={displayIfNot(hostType, 'HYBRID')}
                name="addressName"
                label="Nome do endereço"
            >
                <Input placeholder="Nome do endereço do evento" />
            </Form.Item>

            <Form.Item
                style={displayIfNot(hostType, 'HYBRID')}
                name="address"
                label="Endereço"
            >
                <Input placeholder="Endereço do evento" />
            </Form.Item>

            <Row gutter={24}>
                <Col span={12}>
                    <Form.Item
                        label="Data Inicial"
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: 'Preencha a data inicial'
                            }
                        ]}
                        {...getError('startDate')}
                    >
                        <DatePicker
                            showTime
                            needConfirm={false}
                            style={{ width: '100%' }}
                            format={'DD MMM YYYY HH:mm:ss'}
                            placeholder=""
                            data-cy="date-picker-startDate"
                        />
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        label="Data Final"
                        name="endDate"
                        rules={[
                            {
                                required: true,
                                message: 'Preencha a data final'
                            }
                        ]}
                        {...getError('endDate')}
                    >
                        <DatePicker
                            showTime
                            needConfirm={false}
                            style={{ width: '100%' }}
                            format={'DD MMM YYYY HH:mm:ss'}
                            placeholder=""
                            data-cy="date-picker-endDate"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={['translation', 'languages']}
                        label="Selecione os idiomas disponíveis para o evento"
                    >
                        <SelectSearch
                            mode="multiple"
                            options={languages}
                            allowClear
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default EventInfo

