import React from 'react'

import { CustomRadioGroup } from './styles'

const RadioGroup = ({ children, ...props}) => {
    return (
        <CustomRadioGroup  {...props}>
            {children}
        </CustomRadioGroup>
    )
}

export default RadioGroup