import Speakers from './Speakers'

export const SpeakersSections = {
    speakers: {
        Component: Speakers,
        name: 'speakers',
        category: 'Palestrantes',
        label: 'Palestrantes',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-BR': 'Palestrantes',
                'en-US': 'Speakers',
                'es-ES': 'Conferenciantes',
                'pt-PT': 'Palestrantes'
            }
        }
    },
    highlight: {
        Component: Speakers,
        name: 'speakers',
        category: 'Palestrantes',
        label: 'Palestrantes destaques',
        description: 'Seção com filtro predefinido para palestrantes destaques',
        defaultConfig: {
            title: {
                'pt-BR': 'Palestrantes destaques',
                'en-US': 'Highlight speakers',
                'es-ES': 'Conferenciantes destacados',
                'pt-PT': 'Palestrantes destaques'
            },
            variant: 'highlight',
            condition: {
                type: 'AND',
                filters: [
                    {
                        field: 'featured',
                        type: '$eq',
                        value: true
                    }
                ]
            }
        }
    }
}
