import { ThemeConfig } from 'antd'
import { lighten } from 'polished'

export const SIZES = {
    borderRadiusMD: '10px'
}

export const CARD_COLORS = {
    bgLight: '#3C3C48'
}

export const COLORS = {
    primary: '#8A5AFF',
    secondary: '#3C3C48',
    active: '#7730f6',
    bgLight: '#2F2F38',
    bgDark: '#100F18',
    text: 'rgba(255, 255, 255, 1)',
    textLight: '#ABABCA',
    textDark: 'rgba(255, 255, 255, 0.1)',
    activeBg: 'rgba(0, 0, 0, 0.21)',
    divider: '#303039'
}

export const GRADIENTS = {
    primary: `linear-gradient(250deg, #4518DB -7.22%, #7B29FF 103.84%), linear-gradient(98deg, rgba(209, 214, 255, 0.20) -0.56%, rgba(214, 214, 255, 0.05) 121.76%)`,
    primaryDarken: `linear-gradient(250deg, #310aaf -7.22%, #692bce 103.84%),linear-gradient(98deg, rgba(209, 214, 255, 0.20) -0.56%, rgba(214, 214, 255, 0.05) 121.76%)`
}

export const antdTheme: ThemeConfig = {
    token: {
        colorBgBase: COLORS.bgLight,
        colorBgContainer: COLORS.bgLight,
        colorBgLayout: COLORS.bgDark,
        colorPrimary: COLORS.primary,
        colorInfo: COLORS.primary,
        colorTextBase: COLORS.text,
        colorLink: COLORS.textLight,
        colorText: COLORS.text
    },
    components: {
        Button: {
            colorPrimary: GRADIENTS.primary,
            primaryShadow: 'unset',
            colorBorder: 'transparent',
            colorPrimaryHover: GRADIENTS.primaryDarken,
            lineType: 'none',
            controlOutlineWidth: 0,
            defaultColor: COLORS.textLight,
            defaultBg: COLORS.secondary
        },
        Card: {
            actionsBg: COLORS.activeBg,
            headerBg: COLORS.bgLight
        },
        Collapse: {
            headerBg: lighten(0.1, COLORS.bgLight),
            contentBg: COLORS.bgLight
        },
        Menu: {
            darkItemBg: COLORS.bgDark,
            darkItemSelectedBg: COLORS.bgLight,
            darkItemSelectedColor: COLORS.primary,
            darkItemHoverBg: COLORS.bgLight,
            darkItemHoverColor: COLORS.primary,
            colorPrimary: 'transparent',
            colorBgContainer: COLORS.bgLight,
            itemColor: COLORS.textLight,
            itemHoverBg: GRADIENTS.primary,
            itemHoverColor: COLORS.text,
            itemSelectedBg: GRADIENTS.primary,
            itemSelectedColor: COLORS.text,
            itemActiveBg: GRADIENTS.primary,
            colorSplit: 'rgba(254, 254, 254, 0)',
            horizontalItemSelectedBg: 'rgba(0, 0, 0, 0.3)',
            itemPaddingInline: 5
        },
        Modal: {
            colorBgElevated: '#2F2F2F',
            colorBgMask: 'rgba(0, 0, 0, 0.66)'
        },
        Layout: {
            bodyBg: COLORS.bgDark,
            headerBg: COLORS.bgDark,
            lightSiderBg: COLORS.bgLight,
            siderBg: COLORS.bgLight
        },
        Popover: {
            lineType: 'none',
            colorBgElevated: lighten(0.1, COLORS.bgLight),
            lineWidth: 0
            // boxShadowSecondary: 'none'
        },
        Select: {
            optionSelectedBg: GRADIENTS.primary
        },
        Tooltip: {
            colorText: COLORS.text,
            colorBgBase: COLORS.bgDark
        }
    }
}

// @todo: remove this theme
export const defaultTheme = {
    color: {
        primaryColor: '#fff',
        backgroundDarkLight: 'rgba(255, 255, 255, 0.2)',
        backgroundDarkDark: '#424246',
        backgroundInput: '#38383c',
        backgroundBtnUpload: '#808080',
        backgroundBtnAddLinks: '#5d6a7d',
        tableBg: 'transparent',
        inputBgColor: '#38383c',
        tableBorderColor: '#ffffff12',
        activeColors: '#7730f6',
        borderRadiusBase: '10px',
        btnBoxShadow: '0px 3px 6px #00000029',
        borderRadiusCircle: '50px',
        borderRadiusInput: '6px',
        itemActiveBg: 'rgba(255, 255, 255, 0.2)',
        textColor: 'rgba(255, 255, 255, 0.65)',
        errorColor: '#6b0207',
        backgroundDarkHeaders: '#454147',
        submenuBackground: 'rgba(255, 255, 255, 0.1)'
    },
    font: {
        size: {
            small: '0.8em',
            medium: '1em',
            large: '1.5em'
        },
        fontFamily:
            'Muli,-apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol'
    },
    button: {
        primary: {
            color: '#000',
            background: '#fff',
            hover: {
                opacity: '0.5'
            }
        }
    }
}

