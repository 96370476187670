import React, { useEffect } from 'react'

import EventsRoutes from '@cms/events/routes/eventsRoutes'
import AppLayout from '@cms/core/components/AppLayout'
import { HeaderEvent, HeaderMenu } from '@cms/events/components/Header'
import { useParams } from 'react-router-dom'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { useQuery } from '@apollo/client'
import { useAppLanguages } from '@cms/core/components/Providers/AppConfigProvider/Hooks/useAppLanguages'

const Events = function () {
    const { eventId } = useParams()
    const { setLanguages } = useAppLanguages()

    const { data } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    useEffect(() => {
        if (data?.event?.translation?.languages?.length) {
            setLanguages(data.event.translation.languages)
        }
    }, [data])

    return (
        <AppLayout
            rootModule={'events.event'}
            HeaderAfterModuleComponent={HeaderEvent}
            headerMenuItems={HeaderMenu}
        >
            <EventsRoutes />
        </AppLayout>
    )
}

export default Events

