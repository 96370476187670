export const mock = {
    type: 'others',
    fields: [
        {
            name: 'name',
            label: 'Nome',
            type: 'text',
            config: {
                system: true,
                size: 24,
                required: true
            }
        },
        {
            name: 'email',
            label: 'E-mail',
            type: 'email',
            config: {
                system: true,
                size: 24,
                required: true
            }
        }
    ]
}
