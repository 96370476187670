import React, { useState } from 'react'
import { Input, Form, Row, Col, Typography, Checkbox } from 'antd'

const { Text } = Typography

const Limits = ({ type, config }) => {
    const [limits, setLimits] = useState(config.lengthControl)

    const handleLimits = (e) => {
        setLimits(e.target.checked)
    }

    return (
        <Row gutter={[24, 24]}>
            <Col span={24}>
                <Form.Item
                    name={['config', 'lengthControl']}
                    valuePropName="checked"
                >
                    <Checkbox onChange={handleLimits}>
                        Controlar mínimo e máximo
                    </Checkbox>
                </Form.Item>
            </Col>
            {limits ? (
                <>
                    <Col span={12}>
                        <Form.Item label="Minimo" name={['config', 'min']}>
                            <Input type={'number'} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Máximo" name={['config', 'max']}>
                            <Input type={'number'} />
                        </Form.Item>
                    </Col>
                </>
            ) : null}
        </Row>
    )
}

export default Limits
