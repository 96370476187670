import React from 'react'

import { SiderStyled } from './styles'
import SidebarMenu from './Menu'
import { useRootModule } from '../Providers/AppProvider/Hooks/useRootModule'

const Sidebar = () => {
    const { modules } = useRootModule()

    if (!modules?.length) return null

    return (
        <SiderStyled>
            <SidebarMenu menu={modules} />
        </SiderStyled>
    )
}

export default Sidebar

