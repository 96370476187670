/*
"neutral": "#d0d0d0",
"primary": "#642BDE;#3C8DEC",   linear-gradient(226.22deg, #642BDE -4.83%, #3C8DEC 105.47%);
"secondary": "#9F19F1;#D222D6",  linear-gradient(236.9deg, #9F19F1 -2.21%, #D222D6 88.65%)
"highlight": "#72D236;#22D66A",  background: linear-gradient(236.9deg, #72D236 -2.21%, #22D66A 88.65%)
},
"button": {
    "type": "rounded"  default | circle | rounded
},
"box": {
    "type": "rounded"  default | circle | rounded
},
"logo": {
    "color": "default",  default | white | black
}
*/

const image =
    'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/layout-01-1644598305142.png'

export const layout01 = (event) => ({
    layout: 'layout01',
    info: {
        name: 'Colors',
        image
    },
    theme: {
        colors: {
            white: '#F4FAFF',
            black: '#040B18',
            neutral: '#d0d0d0',
            primary: '#3C8DEC;#642BDE',
            secondary: '#D222D6;#9F19F1',
            highlight: '#22D66A;#72D236'
        },
        button: {
            type: 'circle'
        },
        box: {
            type: 'rounded'
        },
        font: 'Montserrat'
    },
    seo: {
        title: 'Colors',
        description: 'Colors',
        keywords: [],
        image
    },
    pages: {
        checkout: {
            sections: [
                {
                    type: 'Ticket',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        backButton: {
                            textColor: 'white',
                            text: 'Voltar'
                        },
                        title: {
                            text: 'Comprar ingressos',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Escolha qual tipo de ingresso você quer comprar.',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Escolha qual tipo de ingresso você quer comprar.',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            ticket: {
                                name: {
                                    textColor: 'primary'
                                },
                                description: {
                                    textColor: 'black'
                                },
                                soldOut: {
                                    textColor: 'black'
                                },
                                price: {
                                    bgColor: 'highlight',
                                    textColor: 'black',
                                    line: {
                                        bgColor: 'black'
                                    }
                                }
                            }
                        },
                        form: {
                            box: {
                                bgColor: 'white',
                                textColor: 'black'
                            },
                            headline: {
                                text: 'Inscreva-se Gratuitamente!'
                            },
                            button: {
                                text: 'Inscreva-se gratuitamente',
                                bgColor: 'primary',
                                textColor: 'white'
                            },
                            countdown: {
                                date: event.startDate,
                                text: 'As vagas acabam em:'
                            }
                        }
                    }
                }
            ]
        },
        checkoutPayment: {
            sections: [
                {
                    type: 'Payment',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        backButton: {
                            textColor: 'white',
                            text: 'Voltar'
                        },
                        title: {
                            text: 'Comprar ingressos',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            button: {
                                bgColor: 'highlight',
                                textColor: 'black',
                                text: 'Pagar'
                            },
                            input: {
                                bgColor: 'white',
                                textColor: 'black',
                                borderColor: 'black',
                                borderColorHover: 'secondary',
                                iconColor: 'secondary'
                            }
                        },
                        boxValues: {
                            bgColor: 'highlight',
                            textColor: 'black'
                        }
                    }
                }
            ]
        },
        'terms-of-service': {
            sections: [
                {
                    type: 'TermsOfService',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black'
                        }
                    }
                }
            ]
        }
    }
})
