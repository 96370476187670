import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { PageHeader } from '@ant-design/pro-layout'

import { Button, Layout, Row, Col, Affix } from 'antd'

import { GET_EVENT } from '@cms/events/graphql/queries/event'

const { Content } = Layout

import Loader from '@cms/core/components/Loader'

import Nav from './Nav'
import SponsorRoutes from './routes'
import { useBrandSelect } from '@cms/events/components/Container/SponsorDetail/SelectBrand/context'

const SponsorDashboard = () => {
    const { eventId } = useParams()
    const { brandId } = useBrandSelect()

    const { error, data } = useQuery(GET_EVENT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding='10px' />

    const { event } = data
    if (!event) return null

    return (
        <>
            <Layout>
                <PageHeader
                    title={event.name}
                    style={{ padding: '16px 0px' }}
                    extra={[
                        <Link
                            key="0"
                            to={`/evento/${eventId}/dashboard/brand/${brandId}/editar`}
                        >
                            <Button type="primary">Editar Informações</Button>
                        </Link>
                    ]}
                />
                <Content>
                    <SponsorRoutes />
                </Content>
            </Layout>
        </>
    )
}

const Sponsor = () => <SponsorDashboard />

export default Sponsor
