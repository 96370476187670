import React from 'react'
import { message, Form, Input, Select, Tag } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'

import { addToList } from '@cms/core/graphql/utils'
import {
    GET_COLLECTION_ITEM,
    GET_COLLECTION_ITEM_TAGS
} from '@cms/events/graphql/queries/collectionItems'
import Card from '@cms/core/components/Card'
import {
    CREATE_COLLECTION_ITEM,
    UPDATE_COLLECTION_ITEM
} from '@cms/events/graphql/mutations/collectionItems'
import { GET_COLLECTION_CATEGORIES_LIST } from '@cms/events/graphql/queries/collectionCategories'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

const CollectionItemModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const queryCategories = useQuery(GET_COLLECTION_CATEGORIES_LIST, {
        variables: { first: 9999, eventId }
    })

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_COLLECTION_ITEM,
                variables: { id }
            })

            return { ...data.collectionItem }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_COLLECTION_ITEM,
                update: addToList({
                    list: 'collectionItems',
                    Type: 'CollectionItem'
                }),
                variables: {
                    ...formData,
                    category: formData.category.id,
                    event: eventId
                }
            })

            message.success('Item enviado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_COLLECTION_ITEM,
                variables: {
                    id,
                    ...formData,
                    event: eventId,
                    category: formData.category.id,
                    image: formData.image
                }
            })

            message.success('Item atualizado com sucesso!')
        }
    })

    const [categoriesOptions, categoriesSelectOptions] = useCrudSelect(
        queryCategories,
        {
            queryName: 'collectionCategories',
            additionalValues: form.getFieldValue('category')
        }
    )

    const { data: TagsData } = useQuery(GET_COLLECTION_ITEM_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar item' : 'Novo item'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Título"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message: 'Título é obrigatório!'
                        }
                    ]}
                    {...getError('title')}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Descrição é obrigatório!'
                        }
                    ]}
                    {...getError('description')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="image"
                    label="Imagem"
                    rules={[
                        {
                            required: true,
                            message: 'Imagem é obrigatória!'
                        }
                    ]}
                    {...getError('image')}
                >
                    <MediaUploader type="image" />
                </Form.Item>

                <Form.Item
                    label="Texto do botão"
                    name={['action', 'label']}
                    rules={[
                        {
                            required: true,
                            message: 'Texto do botão é obrigatório!'
                        }
                    ]}
                    {...getError('label')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Url do botão"
                    name={['action', 'url']}
                    rules={[
                        {
                            required: true,
                            message: 'URL do botão é obrigatório!'
                        }
                    ]}
                    {...getError('url')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Categoria"
                    name={['category', 'id']}
                    rules={[
                        {
                            required: true,
                            message: 'Categoria é obrigatória!'
                        }
                    ]}
                >
                    <SelectSearch {...categoriesSelectOptions} mode="single">
                        {categoriesOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>

                <Card title="Informações adicionais">
                    <Form.Item label="Tags" name={['tags']}>
                        <Select
                            mode="tags"
                            tagRender={({ label, ...props }) => (
                                <Tag {...props} color="#7F44FF">
                                    {label}
                                </Tag>
                            )}
                            style={{ width: '100%' }}
                            placeholder="Selecione uma tag existente ou crie uma nova."
                        >
                            {TagsData?.collectionItemTags?.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                    {tag}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Card>
            </Form>
        </CrudModal>
    )
}

export default CollectionItemModal
