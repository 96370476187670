import styled from 'styled-components'
import { Layout as AntLayout, Row as AntRow, Col as AntCol } from 'antd'

export const Layout = styled(AntLayout)`
    padding: 20px;
    border: 1px solid #d0d0d0;
`

export const Row = styled(AntRow)`

`

export const Col = styled(AntCol)`

`

export const Button = styled.td`
    font-size: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    display: block;
    padding: 0px;
    line-height: 22px;
    vertical-align: middle;
    width: max-content;
    color: rgb(255,255,255);
    background-color: rgb(233,30,99);
`
