import { gql } from '@apollo/client'

export const CHAT_SEND_COMPANY_MESSAGE = gql`
    mutation chatSend(
        $channel: ChannelInput!
        $message: String!
        $link: String!
        $name: String!
        $avatar: MediaInput!
        $featuredChat: Boolean
        $featuredCTA: Boolean
    ) {
        chatSendCompanyMessage(
            channel: $channel
            message: $message
            link: $link
            name: $name
            avatar: $avatar
            featuredChat: $featuredChat
            featuredCTA: $featuredCTA
        )
    }
`

export const CHAT_DELETE_MESSAGE = gql`
    mutation DeleteChatMessage($id: String!) {
        deleteChatMessage(id: $id) {
            id
            success
        }
    }
`

export const CREATE_BAN_WORD = gql`
    mutation CreateBanWord($word: String) {
        createBanWord(input: { word: $word }) {
            word
        }
    }
`

export const DELETE_BAN_WORD = gql`
    mutation DeleteBanWord($id: ObjectID!) {
        deleteBanWord(id: $id) {
            id
            success
        }
    }
`

export const SEND_MESSAGE = gql`
    mutation SendMessage($channel: ChannelInput!, $message: String!) {
        chatSendMessage(channel: $channel, message: $message) {
            id
        }
    }
`
