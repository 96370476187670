import { gql } from '@apollo/client'
import { EventFormData } from '../fragments'

export const CREATE_EVENT_FORM = gql`
    mutation createEventForm(
        $type: EventFormType!
        $event: ObjectID!
        $fields: [EventFormFieldInput]
        $integrations: [ObjectID]
        $email: EventFormEmailInput
        $config: JSON
        $defaultTicket: ObjectID
        $emailExternalValidation: Boolean
        $botProtection: Boolean
        $name: String
    ) {
        createEventForm(
            input: {
                event: $event
                type: $type
                fields: $fields
                integrations: $integrations
                email: $email
                config: $config
                defaultTicket: $defaultTicket
                emailExternalValidation: $emailExternalValidation
                botProtection: $botProtection
                name: $name
            }
        ) {
            ...EventFormData
        }
    }

    ${EventFormData}
`

export const UPDATE_EVENT_FORM = gql`
    mutation updateEventForm(
        $id: ObjectID!
        $type: EventFormType!
        $event: ObjectID!
        $fields: [EventFormFieldInput]
        $integrations: [ObjectID]
        $email: EventFormEmailInput
        $defaultTicket: ObjectID
        $name: String
        $config: JSON
        $emailExternalValidation: Boolean
        $botProtection: Boolean
    ) {
        updateEventForm(
            id: $id
            input: {
                event: $event
                type: $type
                fields: $fields
                integrations: $integrations
                email: $email
                name: $name
                config: $config
                defaultTicket: $defaultTicket
                emailExternalValidation: $emailExternalValidation
                botProtection: $botProtection
            }
        ) {
            ...EventFormData
        }
    }

    ${EventFormData}
`
export const DELETE_EVENT_FORM = gql`
    mutation DeleteEventForm($id: ObjectID!) {
        deleteEventForm(id: $id) {
            id
            success
        }
    }
`
