import { defaultTheme } from '../../../themes'

export const menuSettings = {
    itemWidth: 50,
    itemHeight: 40,
    gap: 2,
    borderRadius: 8
}

export const menuColors = {
    active: defaultTheme.color.activeColors
}

