import React from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import Main from '../pages/Main'
import Sponsors from '../pages/Sponsors'
import SpeakersUsers from '../pages/SpeakersUsers'
import ChiefUsers from '../pages/ChiefUsers'
import BrandPlans from '../pages/BrandPlans'
import { ChatNav } from '../pages/Chat'
import CustomerInfo from '../pages/Config/Customer/Info'
import EventEdition from '../pages/EventEdition'
import Trackers from '../pages/Trackers'

// prettier-ignore
const MainRoutes = () => {

    return (
        <Switch>
            <PrivateRoute
                checkModulePath
                path={`/principal`}
                exact
                component={Main}
            />
                                      
            <PrivateRoute
                checkModulePath
                path={`/principal/patrocinador/usuarios`}
                component={Sponsors}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/patrocinador/planos`}
                component={BrandPlans}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/palestrante/usuarios`}
                component={SpeakersUsers}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/executivos/usuarios`}
                component={ChiefUsers}
            />
            <PrivateRoute
                checkModule={`events.main.chat`}
                path={`/principal/chat`}
                component={ChatNav}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/config/info`}
                component={CustomerInfo}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/edicoes`}
                component={EventEdition}
            />
            <PrivateRoute
                checkModulePath
                path={`/principal/trackers`}
                component={Trackers}
            />
        </Switch>
    )
}

export default MainRoutes
