import React, { useEffect, useState } from 'react'

import { Modal, Form, message } from 'antd'

import { ModalContainer } from './styles'
import ModalContent from './content'
import { merge } from 'lodash'
import {} from 'react'

const { error } = message

const ModalForm = ({ integration, onSave, onCancel }) => {
    const { id, name } = integration
    const [form] = Form.useForm()

    useEffect(() => {
        if (integration) {
            form.setFieldsValue(integration)
        }
    }, [integration])

    const onFinish = async () => {
        form.validateFields()
            .then(async (values) => {
                onSave(merge({}, integration, values))
                onCancel()
            })
            .catch((info) => {
                error('Verifique os campos do formulário!')
                return false
            })
    }

    return (
        <Modal
            width={600}
            title={id ? `Editar ${name}` : `Novo(a) ${name}`}
            open={!!integration}
            onOk={onFinish}
            onCancel={() => onCancel()}
        >
            <ModalContainer>
                <ModalContent integration={integration} form={form} />
            </ModalContainer>
        </Modal>
    )
}

export default ModalForm

