import React from 'react'

import Nav from './components/Nav'

import { Container } from './styles'
import InfoBuilderRoutes from './routes'

const Routes = () => (
    <Container>
        <Nav />
        <InfoBuilderRoutes />
    </Container>
)

export default Routes
