import { TDDashboardCard } from '@cms/core/components/TDCards'
import { CARD_COLORS } from '@cms/core/themes'

import styled, { css } from 'styled-components'

export const OwnershipTicketCard = styled(TDDashboardCard)`
    background-color: ${CARD_COLORS.bgLight};
    padding: 16px;

    .ant-card-head,
    .ant-card-actions {
        background-color: ${CARD_COLORS.bgLight};
    }
    .ant-card-actions li {
        margin: 12px 0px 0px 0px;
    }
    .ant-card-head {
        padding: 0px 20px;
    }

    .ant-card-body {
        padding: 20px;

        .ant-typography,
        p {
            margin: 0px;
        }

        h5.ant-typography,
        p {
            margin-bottom: 5px;
            font-size: 12px;
        }
    }

    .ant-divider {
        margin: 10px 0px;
    }
`
