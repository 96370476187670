import styled from 'styled-components'
import { Layout, Form } from 'antd'
import { COLORS } from '@cms/core/themes'

const { Content } = Layout

export const ContentDefault = styled(Content)`
    max-width: 100%;
    border-radius: 10px;
    padding: 0;
    margin: 0 20px 20px 20px;

    h3 {
        color: ${COLORS.text};
    }

    .container-light {
        background: ${COLORS.bgLight};
        padding: 20px;
        margin: 20px;
    }
`
