import React, { useEffect } from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import Pages from '../pages'
import WidgetsPage from '../widgets'
import { ROUTES } from '../constants/routes'

export const WidgetsInternal = () => {
    return (
        <>
            <Switch>
                {ROUTES.map((route) => {
                    const { path, exact, name } = route
                    return (
                        <PrivateRoute
                            key={path}
                            exact={exact}
                            path={path}
                            component={() => <Pages name={name} />}
                        />
                    )
                })}
            </Switch>
        </>
    )
}

export const WidgetsMain = () => {
    return (
        <>
            <Switch>
                <PrivateRoute
                    path="/evento/:eventId/widgets"
                    component={WidgetsPage}
                />
            </Switch>
        </>
    )
}
