import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Switch } from 'react-router-dom'

import Customers from '../pages/Customers'
import BillingPlans from '../pages/BillingPlans'
import PrivateRoute from '@cms/core/routes/privateRoute'

// prettier-ignore
export default function AdminRoutes() {
    const customer = useSelector((state) => state.customer) || {}

    return customer.master ? (
        <Switch>
            <Redirect
                exact                
                path="/"
                to={{ pathname: '/clientes' }}
            />
            <PrivateRoute path="/clientes" component={Customers} />
            <PrivateRoute path="/planos" component={BillingPlans} />
        </Switch>
    ) : (
        <Redirect to={{ pathname: '/' }} />
    )
}
