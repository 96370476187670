import React from 'react'
import { Collapse, Divider, Form, Input, Select, Switch } from 'antd'

import { Title, Wrapper } from '../../styles'
import {
    ButtonConfig,
    ColorsBadges,
    WrapperConfig,
    DatePicker,
    RedirectOptions
} from '..'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/form'
import { usePageBuilderContext } from '../../context/context'
import RichTextEditor from '../RichTextEditor/Editor'

const { Panel } = Collapse

const FormConfig = (props) => {
    const { event } = usePageBuilderContext()
    const { eventId } = useParams()
    const fieldPath = props.fieldPath || [props.name, 'configs', 'form']
    const path = props.path
    const { data: FormsData } = useQuery(GET_EVENT_FORMS, {
        variables: { eventId, type: 'others' },
        fetchPolicy: 'no-cache'
    })

    return (
        <Collapse>
            <Panel
                header={<Title level={4}>{props.title || 'Formulário'}</Title>}
            >
                <Wrapper>
                    <Title level={4}>Escolha o formulário</Title>
                    <Form.Item
                        name={[...fieldPath, 'formId']}
                        label="Formulário"
                    >
                        <Select>
                            <Select.Option value={event.formRegister.id}>
                                Inscrição
                            </Select.Option>
                            {FormsData?.eventForms?.edges.map(({ node }) => (
                                <Select.Option value={node.id} key={node.id}>
                                    {node.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Wrapper>

                <Form.Item
                    name={[...fieldPath, 'box', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[...fieldPath, 'box', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <RedirectOptions
                    fieldPath={[...fieldPath]}
                    path={path}
                    excludeModes={['formModal']}
                />

                <Divider orientation="left">Título</Divider>

                <Form.Item
                    name={[...fieldPath, 'headline', 'enabled']}
                    label="Exibir título"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    name={[...fieldPath, 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>

                <ButtonConfig
                    fieldPath={[...fieldPath, 'button']}
                    inner={true}
                />

                <Divider orientation="left">Contador</Divider>

                <Form.Item
                    name={[...fieldPath, 'countdown', 'enabled']}
                    label="Exibir contador"
                >
                    <Switch />
                </Form.Item>

                <Form.Item
                    name={[...fieldPath, 'countdown', 'text']}
                    label="Texto"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name={[...fieldPath, 'countdown', 'textColor']}
                    label="Cor do contador"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item name={[...fieldPath, 'countdown', 'date']}>
                    <DatePicker
                        showTime
                        needConfirm={false}
                        style={{ width: '100%' }}
                        format={'DD MMM YYYY HH:mm:ss'}
                        placeholder=""
                    />
                </Form.Item>
            </Panel>
        </Collapse>
    )
}

export default FormConfig
