import React, { Fragment } from 'react'
import { Form } from 'antd'

import Section from './section'

const List = (props) => {
    const { form, menu } = props

    return (
        <>
            <Form.List name={['menus', menu]}>
                {(fields, { move }) => {
                    return (
                        <>
                            {fields.map((field, key) => {
                                const _fields = form.getFieldsValue()

                                const { name, visible, label } =
                                    _fields.menus[menu][key]

                                const extraProps = {
                                    index: key,
                                    name,
                                    checked: visible,
                                    label,
                                    form,
                                    move,
                                    size: _fields.menus[menu].length
                                }

                                return (
                                    <Fragment key={key}>
                                        <Section {...extraProps} />
                                        <br />
                                    </Fragment>
                                )
                            })}
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}

export default List
