import React from 'react'
import styled, { css } from 'styled-components'

import { Col, Card } from 'antd'
import { CARD_COLORS } from '@cms/core/themes'

export const Content = styled(Card)`
    position: relative;
    height: 160px;
    transition: all 0.3s ease-in-out;
    border-color: ${CARD_COLORS.bgLight};

    &:hover {
        background: ${CARD_COLORS.bgLight};
    }

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    img {
        position: absolute;
        top: 10px;
        left: 20px;
        padding-top: 10px;
        max-width: 150px;
        height: 50px;
        object-fit: contain;
    }

    .event-info {
        position: absolute;
        bottom: 0;
        left: 20px;
        color: #fff;
        width: 100%;

        .event-name {
            width: 90%;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }

        .event-date {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }
`
export const Box = function (props) {
    return <Col xs={24} sm={24} md={12} lg={8} xl={6} {...props} />
}
