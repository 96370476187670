import React, { useState } from 'react'

import {
    message,
    Form,
    Input,
    InputNumber,
    Select,
    Tag,
    Button,
    Row,
    Col,
    Typography
} from 'antd'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { useApolloClient, useQuery } from '@apollo/client'

import { GET_BRAND, GET_BRAND_TAGS } from '@cms/events/graphql/queries/brand'

import { GET_BRAND_PLANS_LIST } from '@cms/events/graphql/queries/brandPlan'
import { useCrudForm } from '@cms/core/components/Crud'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import Card from '@cms/core/components/Card'

import { StyledCard, StyledContent, StyledFooter, Title } from './styles'
import { displayIf, getObjectDiff } from '@cms/core/utils'
import {
    APPROVE_BRAND_DRAFT,
    REJECT_BRAND_DRAFT
} from '@cms/events/graphql/mutations/brand'
import { useHistory } from 'react-router-dom'

const { Paragraph } = Typography

const modeOptions = [
    {
        name: 'Pequeno',
        value: 'small'
    },
    {
        name: 'Padrão',
        value: 'normal'
    },
    {
        name: 'Grande',
        value: 'large'
    },
    {
        name: 'Link Externo',
        value: 'external'
    }
]

const getData = (data) => {
    return {
        ...data
    }
}

const diffStyle = {
    backgroundColor: '#ffe57938',
    border: '1px solid #ffe579',
    rowGap: '0'
}

const BrandContainer = ({ path, eventId, brandId }) => {
    const [hostType, setHostType] = useState('DIGITAL')
    const [standType, setStandType] = useState()

    const [isLoading, setIsLoading] = useState(false)

    const [diff, setDiff] = useState([])
    const [draftForm] = Form.useForm()

    const client = useApolloClient()
    const history = useHistory()

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const queryPlans = useQuery(GET_BRAND_PLANS_LIST, {
        variables: { first: 9999 },
        fetchPolicy: 'cache-and-network'
    })

    const [{ form, isEdit, getError, formUpdate }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_BRAND,
                variables: { id: brandId }
            })

            setHostType(data.brand.plan?.hostType || 'DIGITAL')
            setStandType(data.brand.talkStand?.type || 'email')
            form.validateFields([['talkStand', 'type']])

            setDiff(getObjectDiff(data.brand.draft.data, data.brand))
            draftForm.setFieldsValue(data.brand.draft.data)

            return getData(data.brand)
        }
    })

    const redirect = () => {
        setTimeout(() => {
            history.push(path)
        }, 200)
    }

    const approveDraft = async () => {
        setIsLoading(true)
        await client.mutate({
            mutation: APPROVE_BRAND_DRAFT,
            variables: { id: brandId }
        })
        setIsLoading(false)
        message.success('Edição aprovada com sucesso.')
        redirect()
    }

    const rejectDraft = async () => {
        setIsLoading(true)
        await client.mutate({
            mutation: REJECT_BRAND_DRAFT,
            variables: { id: brandId }
        })
        setIsLoading(false)
        message.success('Edição reprovada com sucesso.')
        redirect()
    }

    const [socialMediaTypes] = useState([
        'Linkedin',
        'Facebook',
        'Twitter',
        'Instagram',
        'TikTok'
    ])

    const applyStyle = (isDraft, name, hasPadding = false) => {
        if (!isDraft) return null
        if (diff.includes(name)) {
            if (hasPadding) return { ...diffStyle, padding: '0 10px' }
            return diffStyle
        }
        return null
    }

    const WrapperForm = ({ isDraft = false }) => {
        console.log(draftForm.getFieldsValue())
        return (
            <div>
                <Title>{isDraft ? 'Alterado' : 'Anterior'}</Title>
                <Form
                    layout={'vertical'}
                    form={isDraft ? draftForm : form}
                    // initialValues={{ talkStand: { type: 'email' } }}
                    style={{ marginTop: '2em' }}
                >
                    <Form.Item
                        name="description"
                        label="Descrição"
                        style={applyStyle(isDraft, 'description')}
                    >
                        <Input.TextArea
                            placeholder="Descrição do patrocinador"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name="aboutUs"
                        label="Sobre Nós"
                        style={applyStyle(isDraft, 'aboutUs')}
                    >
                        <Input.TextArea disabled />
                    </Form.Item>
                    <Card title="Para a pagina de inscrição">
                        <Form.Item
                            name="site"
                            label="Site"
                            style={applyStyle(isDraft, 'site')}
                        >
                            <Input disabled />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            style={applyStyle(isDraft, 'email')}
                        >
                            <Input type="email" disabled />
                        </Form.Item>

                        <Form.Item
                            name="contact"
                            label="Contato"
                            style={applyStyle(isDraft, 'contact')}
                        >
                            <Input.TextArea
                                placeholder="Informações de Contato"
                                disabled
                            />
                        </Form.Item>

                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Logo"
                                    name="logo"
                                    style={applyStyle(isDraft, 'logo')}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Logo é obrigatório!'
                                        }
                                    ]}
                                >
                                    <MediaUploader type="svg" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item
                                    label="Banner"
                                    name="image"
                                    style={applyStyle(isDraft, 'image')}
                                >
                                    <MediaUploader type="image" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card title="Redes sociais">
                        <div style={applyStyle(isDraft, 'social', true)}>
                            <Form.List name="social">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`social.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={24}>
                                                        <Paragraph>
                                                            Rede {index + 1}
                                                        </Paragraph>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Plataforma"
                                                            name={[
                                                                field.name,
                                                                'type'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Plataforma é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled
                                                                filterOption={(
                                                                    input,
                                                                    option
                                                                ) =>
                                                                    option.children
                                                                        .toLowerCase()
                                                                        .indexOf(
                                                                            input.toLowerCase()
                                                                        ) >= 0
                                                                }
                                                            >
                                                                {socialMediaTypes.map(
                                                                    (o) => (
                                                                        <Option
                                                                            key={
                                                                                o
                                                                            }
                                                                        >
                                                                            {o}
                                                                        </Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={14}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Url"
                                                            name={[
                                                                field.name,
                                                                'url'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Url é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            disabled
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                disabled
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Card>
                    <Card
                        style={displayIf(hostType, 'ONSTAGE')}
                        title="Botões e ações do stand do patrocinador"
                    >
                        <div style={applyStyle(isDraft, 'actions', true)}>
                            <Form.List name="actions">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`actions.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={22}>
                                                        <Paragraph>
                                                            Ação {index + 1}
                                                        </Paragraph>
                                                        <Row gutter={24}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    label="Nome"
                                                                    name={[
                                                                        field.name,
                                                                        'name'
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Nome é obrigatório!'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        disabled
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Url"
                                                                    name={[
                                                                        field.name,
                                                                        'url'
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Url é obrigatório!'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        disabled
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Modo"
                                                                    name={[
                                                                        field.name,
                                                                        'size'
                                                                    ]}
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message:
                                                                                'Modo é obrigatório!'
                                                                        }
                                                                    ]}
                                                                >
                                                                    <Select
                                                                        disabled
                                                                    >
                                                                        {modeOptions.map(
                                                                            (
                                                                                option
                                                                            ) => (
                                                                                <Select.Option
                                                                                    key={
                                                                                        option.value
                                                                                    }
                                                                                    value={
                                                                                        option.value
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        option.name
                                                                                    }
                                                                                </Select.Option>
                                                                            )
                                                                        )}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            disabled
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                disabled
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Card>
                    <Card
                        style={displayIf(hostType, 'ONSTAGE')}
                        title="Materiais para download"
                    >
                        <div style={applyStyle(isDraft, 'resources', true)}>
                            <Form.List name="resources">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`resources.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={24}>
                                                        <Paragraph>
                                                            Recurso {index + 1}
                                                        </Paragraph>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Titulo"
                                                            name={[
                                                                field.name,
                                                                'title'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Titulo é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={14}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Link"
                                                            name={[
                                                                field.name,
                                                                'link'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Link é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input disabled />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            disabled
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                disabled
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Card>
                    <Card
                        style={displayIf(hostType, 'ONSTAGE')}
                        title="Vídeos institucionais"
                    >
                        <div
                            style={applyStyle(
                                isDraft,
                                'institutionalVideos',
                                true
                            )}
                        >
                            <Form.List name="institutionalVideos">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div
                                                key={`institutionalVideos.${index}`}
                                            >
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={22}>
                                                        <Paragraph>
                                                            Video {index + 1}
                                                        </Paragraph>
                                                        <Row gutter={24}>
                                                            <Col span={24}>
                                                                <Form.Item
                                                                    {...field}
                                                                    label="Url"
                                                                    name={[
                                                                        field.name,
                                                                        'url'
                                                                    ]}
                                                                >
                                                                    <Input
                                                                        disabled
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            disabled
                                                            onClick={() =>
                                                                remove(
                                                                    field.name
                                                                )
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => {
                                                    add({})
                                                }}
                                                disabled
                                                style={{ width: '100%' }}
                                            >
                                                <PlusOutlined /> Adicionar
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Card>
                    <Card
                        style={displayIf(hostType, 'ONSTAGE')}
                        title="Página do patrocinador"
                    >
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Tags"
                                    name={['tags']}
                                    style={applyStyle(isDraft, 'tags')}
                                >
                                    <Select
                                        mode="tags"
                                        tagRender={({ label, ...props }) => (
                                            <Tag {...props} color="#7F44FF">
                                                {label}
                                            </Tag>
                                        )}
                                        disabled
                                        style={{ width: '100%' }}
                                        placeholder="Selecione uma tag existe ou crie uma nova."
                                    >
                                        {TagsData?.brandTags?.map((tag) => (
                                            <Select.Option
                                                key={tag}
                                                value={tag}
                                            >
                                                {tag}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </div>
        )
    }

    return (
        <StyledCard>
            <StyledContent>
                <WrapperForm />
                <WrapperForm isDraft />
            </StyledContent>
            <StyledFooter>
                <Button
                    style={{ marginRight: '10px' }}
                    disabled={isLoading}
                    key="back"
                    onClick={rejectDraft}
                >
                    Não aprovar
                </Button>
                <Button
                    data-cy="save-button"
                    key="submit"
                    type="primary"
                    disabled={isLoading}
                    onClick={approveDraft}
                >
                    Aprovar edição
                </Button>
            </StyledFooter>
        </StyledCard>
    )
}

export default BrandContainer
