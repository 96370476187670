import { COLORS, SIZES } from '@cms/core/themes'
import styled from 'styled-components'

export const ChatWrapper = styled.div`
    background: ${COLORS.bgLight};
    border-radius: ${SIZES.borderRadiusMD};
    height: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;

    > div {
        overflow: hidden;
        flex: 1;
        border-radius: ${SIZES.borderRadiusMD};
    }

    .td-content-default {
        margin: 0;
        padding: 0;
    }
    .ant-table-cell {
        border: none;
    }

    .ant-card-body {
        padding: 0 !important;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ant-table,
    .ant-table-container,
    .ant-card {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .ant-table-body {
        background: ${COLORS.secondary};
        height: auto !important;
        max-height: 100% !important;
        padding: 0 !important;
    }
`
export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const InputWrapper = styled.div`
    margin-top: 20px;
    .ant-btn {
        border-color: linear-gradient(
            250.11deg,
            #4518db -7.22%,
            #7b29ff 103.84%
        );
        position: absolute;
        right: 37px;
        border-radius: 5px;
        height: 38px;
        margin-top: 1px;
    }
`
export const InnerChatWrapper = styled.div`
    height: 100%;
    flex: 1;
    overflow-y: hidden;
`
export const ChatElements = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

