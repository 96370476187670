import styled from 'styled-components'

export const ImageAvatar = styled.div`
    width: 140px;
    height: 140px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    border: 3px solid #b3b3b3;
    border-radius: 7px;
    background: #eaeaea;
`

export const UploadWrapper = styled.div`
    .ant-upload-list {
        padding-top: 10px;
    }
    .ant-upload-list-item-list-type-picture-card,
    .ant-upload-list-picture-card-container {
        width: 150px;
        height: 150px;

        margin: 0;

        .anticon.anticon-picture svg {
            width: 30px;
            height: 30px;
            * {
                fill: #909090;
            }
        }

        .ant-upload-list-item {
            background: #565656;
        }

        .ant-upload-list-item-info {
            &::before {
                left: 0;
            }
            > span {
                display: flex;
                justify-content: center;
                align-items: center;
                .ant-upload-list-item-thumbnail,
                .ant-upload-list-item-thumbnail img {
                    width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
`
