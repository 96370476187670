import React from 'react'
import { Typography, Avatar } from 'antd'

import { ChatItemWrapper } from './styles'

const { Text } = Typography

const ChatItem = ({ node }) => {
    return (
        <ChatItemWrapper>
            <Avatar size={35} src={node.user.avatar?.url} />
            <Text>
                <span>{node.user.name}</span>: <br /> {[node.message]}
            </Text>
        </ChatItemWrapper>
    )
}

export default ChatItem
