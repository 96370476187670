import React from 'react'
import { Form, Switch } from 'antd'

import { ColorsBadges, ContainerConfig, ButtonConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const Rewards = (props) => {
    const handleChange = (e) => {
        console.log(e)
    }
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Conteúdo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'content', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <ButtonConfig allowVisibilityControl enableActions {...props} />

            <Wrapper>
                <Title level={4}>Configuração das recompensas</Title>
                <Wrapper>
                    <Title level={4}>Exibição de imagens</Title>
                    <Form.Item
                        name={[props.name, 'configs', 'topRewardImages']}
                        label="Premiação TOP"
                        valuePropName="checked"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        name={[props.name, 'configs', 'rewardImages']}
                        label="Premiação normal"
                        valuePropName="checked"
                        initialValue={false}
                    >
                        <Switch />
                    </Form.Item>
                </Wrapper>

                <Wrapper>
                    <Title level={4}>Cor do Box</Title>

                    <Form.Item
                        name={[props.name, 'configs', 'box', 'bgColor']}
                        label="Cor de fundo"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>

                <Wrapper>
                    <Title level={4}>Cor da Premiação Top</Title>
                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'container',
                            'topBgColor'
                        ]}
                        label="Cor fundo top"
                    >
                        <ColorsBadges />
                    </Form.Item>

                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'container',
                            'topTextColor'
                        ]}
                        label="Cor do texto top"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>

                <Wrapper>
                    <Title level={4}>Cor da Premiação</Title>
                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'box',
                            'indications',
                            'textColor'
                        ]}
                        label="Cor do texto indicações"
                    >
                        <ColorsBadges />
                    </Form.Item>

                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'box',
                            'reward',
                            'textColor'
                        ]}
                        label="Cor do texto recompensas"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>
            </Wrapper>
        </>
    )
}

export default Rewards
