import React from 'react'
import { Col, Input, Row } from 'antd'

const AdressPreview = ({ config }) => {
    return (
        <Row gutter={[24, 24]}>
            <Col span={12}>
                <Input type={'text'} placeholder="Estado" disabled />
            </Col>
            <Col span={12}>
                <Input type={'text'} placeholder="Cidade" disabled />
            </Col>
        </Row>
    )
}

export default AdressPreview
