import React, { useEffect, useRef, useState } from 'react'

import { useLocation } from 'react-router-dom'
import { MainContent } from './styles'
import { useMainScroll } from './Hooks/useMainScroll'

export const AppMainContent: React.FC = ({ children }) => {
    const ref = useRef<HTMLDivElement>(null)
    const [hasScrollbar, setHasScrollbar] = useState(false)
    const { pathname, state } = useLocation<{ forceRefresh: boolean }>()
    const { setScrollPosition } = useMainScroll()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (ref.current) {
            const checkScroll = () => {
                setHasScrollbar(
                    ref.current.scrollHeight > ref.current.clientHeight
                )
            }

            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if (entry.target === ref.current) {
                        checkScroll()
                    }
                }
            })

            resizeObserver.observe(ref.current)
            checkScroll()
            ref.current.scrollTop = 0

            return () => {
                resizeObserver.disconnect()
            }
        }
    }, [pathname])

    useEffect(() => {
        if (state?.forceRefresh) {
            setLoading(true)
            setTimeout(() => {
                setLoading(false)
            }, 100)
        }
    }, [pathname, state?.forceRefresh])

    if (loading) {
        return null
    }

    return (
        <MainContent
            $hasScrollbar={hasScrollbar}
            ref={ref}
            onScroll={() => setScrollPosition(ref.current?.scrollTop || 0)}
        >
            {children}
        </MainContent>
    )
}
