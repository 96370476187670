import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import Main from '@cms/events/pages/Main'
import Sponsor from '@cms/events/pages/Sponsor'
import Route404 from '@cms/core/routes/404Route'

const SponsorRoutes = () => {
    return (
        <Switch>
            <PrivateRoute path="/principal" exact component={Main} />
            <PrivateRoute
                path="/evento/:eventId/dashboard"
                component={Sponsor}
            />
            <Route404 />
        </Switch>
    )
}

export default SponsorRoutes
