import styled from 'styled-components'

interface StatusBadgeProps {
    status: 'SUCCESS' | 'FAIL'
}

export const StatusBadge = styled.div<StatusBadgeProps>`
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    color: white;
    line-height: 1;
    margin: 0px;
    background-color: ${({ status }) =>
        status === 'SUCCESS' ? '#4CAF50' : '#F44336'};
`
