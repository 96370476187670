import React from 'react'
import { StarOutlined } from '@ant-design/icons'

import { List, Rate, Row, Typography } from 'antd'
import {
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '../Dashboard/styles'

import { WrapperCol, WrapperRow } from './styles'

const { Text } = Typography

export const getOptions = (rating) => {
    return [
        {
            value: 5,
            quantity: rating.five
        },
        {
            value: 4,
            quantity: rating.four
        },
        {
            value: 3,
            quantity: rating.three
        },
        {
            value: 2,
            quantity: rating.two
        },
        {
            value: 1,
            quantity: rating.one
        }
    ]
}

const Rating = (props) => {
    const { options, total, average } = props

    const buildRateList = () => {
        return (
            <Row style={{ minWidth: '100%' }}>
                <List
                    grid={{ gutter: 16, column: 2 }}
                    dataSource={options}
                    style={{ width: '100%' }}
                    renderItem={(item) =>
                        item.quantity > 0 && (
                            <WrapperCol key={item.value}>
                                <Rate
                                    allowHalf
                                    defaultValue={item.value}
                                    disabled
                                />
                                <Text>{item.quantity} avaliações</Text>
                            </WrapperCol>
                        )
                    }
                />
            </Row>
        )
    }

    return (
        <>
            <DashboardCardTitle align="middle" className="RowTitle">
                <IconWrapper>
                    <StarOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Avaliações</DashboardTitle>
            </DashboardCardTitle>

            <WrapperRow>
                {total > 0 ? (
                    buildRateList()
                ) : (
                    <Text>Sem avaliações até o momento</Text>
                )}
            </WrapperRow>
        </>
    )
}

export default Rating

