import React, { useEffect } from 'react'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Select, Row, Col, Form, Space } from 'antd'

import SelectField from './SelectField'
import { Typography } from 'antd'

const { Text } = Typography

const Segment = ({ form }) => {
    const orderField = [
        { name: 'AND', label: 'E' },
        { name: 'OR', label: 'Ou' }
    ]

    useEffect(() => {
        const values = form.getFieldsValue()
        if (!values?.filters) {
            const inital = {
                filters: [{ conditional: '', name: '' }]
            }
            form.setFieldsValue(inital)
        }
    }, [])

    return (
        <>
            <Row>
                <Form.Item
                    name="conditional"
                    rules={[{ required: true, message: 'Selecione condição' }]}
                >
                    <Select style={{ width: '140px' }} placeholder="Condição">
                        {orderField.map((_) => {
                            return (
                                <Select.Option
                                    key={_.name}
                                    value={_.name}
                                    title={_.label}
                                >
                                    {_.label}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>
            </Row>
            <Row gutter={24}>
                <Form.List name="filters">
                    {(fields, { add, remove }) => {
                        return (
                            <>
                                <Col span={24} style={{ marginBottom: '12px' }}>
                                    <Row gutter={24}>
                                        <Col span={6}>
                                            <Text>Campo</Text>
                                        </Col>
                                        <Col span={6}>
                                            <Text>Operador</Text>
                                        </Col>
                                        <Col span={8}>
                                            <Text>Valor</Text>
                                        </Col>
                                    </Row>
                                </Col>
                                {fields.map((field) => {
                                    return (
                                        <Col span={24} key={field.key}>
                                            <Row gutter={24}>
                                                <SelectField field={field} />

                                                <Col span={4}>
                                                    <Button
                                                        disabled={
                                                            field.fieldKey === 0
                                                        }
                                                        title="Remove"
                                                        onClick={() =>
                                                            remove(
                                                                field.fieldKey
                                                            )
                                                        }
                                                        type="primary"
                                                        icon={
                                                            <DeleteOutlined />
                                                        }
                                                    ></Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    )
                                })}
                                <Col
                                    span={24}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: '140px'
                                    }}
                                >
                                    <Space align="center">
                                        <Button
                                            style={{ marginRight: '10px' }}
                                            type="primary"
                                            htmlType="button"
                                            onClick={add}
                                            icon={<PlusOutlined />}
                                        ></Button>
                                    </Space>
                                </Col>
                            </>
                        )
                    }}
                </Form.List>
            </Row>
        </>
    )
}

export default Segment
