import React from 'react'

import Integration from '@cms/core/components/Integrations/components/Integration'
import { PageHeader } from '@ant-design/pro-layout'

const config = {
    category: 'conversions',
    unique: false,
    appType: 'EVENTS'
}

const ConversionsHome = () => {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Integrações de conversão"
                subTitle="Integre o seu evento com ferramentas de conversão"
            />
            <Integration config={config} />
        </>
    )
}

export default ConversionsHome

