import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { CrudList } from '@cms/core/components/Crud'

import { GET_ORDER_LIST_ORDENABLE } from '@cms/events/graphql/queries/order'
import { ButtonDefault } from '@cms/events/components/Buttons'
import { TICKET_CHARGETBACK } from '@cms/events/graphql/mutations/billing'

const orderByFields = [{ label: 'Data criação', value: 'createdAt' }]

const Orders = () => {
    const { eventId } = useParams()

    const variables = {
        first: 25,
        filter: { status: 'PAID', event: eventId },
        orderBy: { createdAt: 'DESC' }
    }

    const query = useQuery(GET_ORDER_LIST_ORDENABLE, {
        variables
    })

    const [chargeback] = useMutation(TICKET_CHARGETBACK, {
        refetchQueries: [{ query: GET_ORDER_LIST_ORDENABLE, variables }]
    })

    const columns = [
        {
            title: 'Id',
            dataIndex: ['node', 'id']
        },
        {
            title: 'Status',
            dataIndex: ['node', 'status']
        },
        {
            title: 'Action',
            dataIndex: [''],
            render: (_, obj) => {
                return (
                    <ButtonDefault
                        type="primary"
                        onClick={() => {
                            chargeback({
                                variables: {
                                    id: obj.node.id
                                }
                            })
                        }}
                    >
                        Estornar
                    </ButtonDefault>
                )
            }
        }
    ]

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <CrudList
                header={{
                    title: 'Pedidos',
                    subTitle: ''
                }}
                search={true}
                columns={columns}
                queryName={'orders'}
                hideAction="all"
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default Orders
