import React, { useEffect, useState, useRef } from 'react'

import Loader from '@cms/core/components/Loader'

import { Wrapper } from './styles'

type Props = {
    theme?: Record<string, any>
    iframeUrl?: string
}

const ThemePreview = ({ theme, iframeUrl }: Props) => {
    const [height] = useState(1200)
    const [previewHeight] = useState(700)
    const [previewWidth] = useState(675)
    const [loading, setLoading] = useState(true)

    const iframeRef = useRef(null)

    const Emit = (type, payload) => {
        if (iframeRef.current && iframeRef.current.contentWindow) {
            iframeRef.current.contentWindow.postMessage(
                {
                    type,
                    payload
                },
                '*'
            )
        }
    }

    useEffect(() => {
        const OnMessage = (event) => {
            if (!event.data) return
            const { type, payload } = event.data

            if (type === 'init') {
                setLoading(false)
            }
        }
        window.addEventListener('message', OnMessage)

        return () => {
            window.removeEventListener('message', OnMessage)
        }
    }, [])

    useEffect(() => {
        Emit('change', theme)
    }, [theme])

    return (
        <>
            {loading ? <Loader fixed={false} padding='10px' /> : null}
            {iframeUrl ? (
                <Wrapper
                    height={height}
                    previewWidth={previewWidth}
                    previewHeight={previewHeight}
                >
                    <iframe
                        ref={(dom) => (iframeRef.current = dom)}
                        src={iframeUrl}
                        onLoad={() => setLoading(false)}
                        // src="https://xscale-pages-0.vercel.app/platform/5f5f6ac9a7dd244f0b6d14bc/editor-preview/home"
                        // src="https://xscale-pages-0.vercel.app/editor-preview/home"
                    />
                </Wrapper>
            ) : null}
        </>
    )
}

export default ThemePreview
