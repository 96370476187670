import { Form, Select } from 'antd'
import React from 'react'
import { DIRECTIONS } from './constants'
import { IOrderByField } from './types'

const OrderBySelect = ({ field }: IOrderByField) => {
    const options = Object.keys(DIRECTIONS).map((key) => {
        return {
            value: DIRECTIONS[key],
            label: key
        }
    })

    return (
        <Form.Item
            name={[field.name, 'direction']}
            rules={[
                {
                    required: true,
                    message: 'Campo obrigatório'
                }
            ]}
        >
            <Select options={options} />
        </Form.Item>
    )
}

export default OrderBySelect

