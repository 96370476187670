import React from 'react'
import { PageHeader } from '@ant-design/pro-layout'

import Title from 'antd/es/typography/Title'
import Link from 'antd/es/typography/Link'
import { StyledContent } from './styles'
import { WarningOutlined } from '@ant-design/icons'

import { Card } from 'antd'

const Upgrade = () => {
    return (
        <>
            <PageHeader className="site-page-header" title="Upgrade" />
            <Card>
                <StyledContent>
                    <WarningOutlined />
                    <Title level={4}>
                        Você não tem acesso a essa funcionalidade, caso queira
                        usa-la entre em contato com nosso atendimento no e-mail:{' '}
                        <Link href="mailto:atendimento@tdscale.com.br">
                            atendimento@tdscale.com.br
                        </Link>
                    </Title>
                </StyledContent>
            </Card>
        </>
    )
}
export default Upgrade
