import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_COUPON_LIST_ORDENABLE = gql`
    query GetTicketCoupons(
        $first: Int
        $afterCursor: Cursor
        $orderBy: TicketCouponOrderBy
        $eventId: ObjectID!
        $search: String
        $code: Filter
    ) {
        ticketCoupons(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { event: $eventId, code: $code }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TicketCouponData
                }
            }
        }
    }

    ${fragments.TicketCouponData}
`

export const GET_COUPONS_LIST = gql`
    query GetTicketCouponList(
        $eventId: ObjectID!
        $first: Int
        $afterCursor: Cursor
    ) {
        ticketCoupons(
            filter: { event: $eventId }
            first: $first
            after: $afterCursor
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TicketCouponData
                }
            }
        }
    }

    ${fragments.TicketCouponData}
`

export const GET_COUPON = gql`
    query GetTicketCoupon($id: ObjectID!) {
        ticketCoupon(id: $id) {
            ...TicketCouponData
        }
    }

    ${fragments.TicketCouponData}
`

