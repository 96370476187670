import styled from 'styled-components'

export const SelectCategoriesSelectContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
`;

export const SelectCategoriesWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ant-form-item {
        flex: 1;
        margin-right: 10px;
    }
`;