import React from 'react'
import { Form, Input } from 'antd'
import { ButtonConfig, ContainerConfig, Uploader } from '../../../components'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { Title, Wrapper } from '../../../styles'

const About = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item name={[props.name, 'configs', 'headline', 'text']}>
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item name={[props.name, 'configs', 'subtitle', 'text']}>
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Vídeo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'video', 'url']}
                    label="Url vídeo"
                >
                    <Input type={'url'} />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'video', 'thumbnail']}
                    label="Thumbnail o vídeo"
                >
                    <Uploader />
                </Form.Item>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default About
