import React from "react";
import { Circle, Wrapper } from "./styles";

const Status = ({status}) => {
      const handleStatusText = (status) => {
        switch (status) {
            case "STARTED":
              return "ATIVO";
            case "NOT_STARTED":
              return "NÃO INICIADO";
            case "FINISHED":
              return "ENCERRADO";
            default:
              return "";
          }
    }

    return (
        <Wrapper type={status}>
            <Circle type={status}/>
            <span>{handleStatusText(status)}</span>
        </Wrapper>
    )
}

export default Status