import styled from 'styled-components'

export const Frame = styled('iframe')`
    border: none;
    width: 100%;
    min-width: 100px;
    height: 100%;
    max-height: 100%;
    margin: 0 auto;
`
