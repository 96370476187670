import { Affix, Col, Row } from 'antd'
import React from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Navigation, Wrapper } from './styles'


const Nav = () => {
    const { eventId } = useParams<any>()
    const { pathname } = useLocation()
    const baseUrl = `/evento/${eventId}/categories`

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                <Link
                                    className={
                                        pathname === baseUrl ? 'active-nav' : ''
                                    }
                                    to={baseUrl}
                                >
                                    Trilhas
                                </Link>
                                <Link
                                    className={
                                        pathname === baseUrl + '/series'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/series'}
                                >
                                    Séries
                                </Link>
                                <Link
                                    className={
                                        pathname === baseUrl + '/playlist'
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={baseUrl + '/playlist'}
                                >
                                    Playlist
                                </Link>
                            </Col>
                        </Navigation>
                    </Col>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default Nav
