import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Col, Form, Input, InputNumber, Select } from 'antd'

import { GET_SEGMENTS_FIELDS } from '@cms/events/graphql/queries/segments'
import Selection from './selection'

const SelectSegments = ({ field }) => {
    const { eventId } = useParams()
    const [current, setCurrent] = useState()
    const [conditional, setConditionals] = useState(null)
    const [fieldsConditions, setFieldsConditions] = useState(null)

    const { data: fieldsData, loading: fieldsLoading } = useQuery(
        GET_SEGMENTS_FIELDS,
        {
            variables: { event: eventId }
        }
    )

    useEffect(() => {
        if (fieldsData) {
            let _fieldsConditions = fieldsData.eventRegistrationsSegmentFields

            setFieldsConditions(_fieldsConditions)
            setCurrent({
                type: _fieldsConditions[0].type,
                options: _fieldsConditions[0].options
            })
        }
    }, [fieldsData])

    const orderByConditions = [
        { name: 'eq', label: 'É igual a' },
        { name: 'ne', label: 'Não é igual a' },
        { name: 'gt', label: 'Maior', type: 'number' },
        { name: 'gte', label: 'Maior igual', type: 'number' },
        { name: 'it', label: 'Selecionados', type: 'select' },
        { name: 'ite', label: 'Não selecionados', type: 'select' },
        { name: 'regex', label: 'Contém' }
    ]

    if (!fieldsConditions) return null

    const handleChangeName = (value) => {
        setCurrent({
            type: value.type,
            options: value.options
        })
        return value.name
    }

    const handleChangeCondional = (value) => {
        const multiple = ['it', 'ite'].indexOf(value.name) >= 0

        setConditionals(multiple)

        return value.name
    }

    return (
        <>
            <Col span={6}>
                <Form.Item
                    name={[field.name, 'name']}
                    rules={[
                        {
                            required: true,
                            message: 'Campo obrigatório'
                        }
                    ]}
                    initialValue={fieldsConditions[0].name}
                >
                    <Selection
                        selection={fieldsConditions}
                        callback={handleChangeName}
                    />
                </Form.Item>
            </Col>
            <Col span={6}>
                <Form.Item
                    name={[field.name, 'operator']}
                    rules={[
                        {
                            required: true,
                            message: 'Campo obrigatório'
                        }
                    ]}
                    initialValue={orderByConditions[0].name}
                >
                    <Selection
                        selection={orderByConditions}
                        callback={handleChangeCondional}
                        type={current.type}
                    />
                </Form.Item>
            </Col>
            {current.type !== 'select' || !conditional ? (
                <Col span={8}>
                    <Form.Item
                        name={[field.name, 'value']}
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        {current.type === 'number' ? (
                            <InputNumber />
                        ) : (
                            <Input />
                        )}
                    </Form.Item>
                </Col>
            ) : (
                <Col span={8}>
                    <Form.Item
                        name={[field.name, 'value']}
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Selection
                            selection={current.options}
                            mode="multiple"
                        />
                    </Form.Item>
                </Col>
            )}
        </>
    )
}

export default SelectSegments
