import React from 'react'
import { Affix } from 'antd'
import { Footer, Button, Wrapper } from './styles'

import { CheckCircleFilled } from '@ant-design/icons'

import { usePageBuilderContext } from '@cms/events/pages/Config/Checkout/context/context'

const PanelFooter = ({ setSection }) => {
    const { loading } = usePageBuilderContext()

    return (
        <Wrapper>
            <Affix offsetBottom={0}>
                <Footer className="affix-form-footer" justify="space-between">
                    <Button
                        onClick={() => setSection && setSection(null)}
                        type="default"
                    >
                        Fechar
                    </Button>
                    <Button
                        htmlType="submit"
                        type="primary"
                        loading={loading}
                        onClick={() => setSection && setSection(null)}
                        icon={<CheckCircleFilled />}
                    >
                        Salvar
                    </Button>
                </Footer>
            </Affix>
        </Wrapper>
    )
}

export default PanelFooter
