import React from 'react'

const Logo = (props) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="164.534"
            height="58.762"
            fill="#fff"
            viewBox="0 0 164.534 58.762"
            {...props}
        >
            <path
                className="a"
                d="M0,0H77.327V14.6H0ZM31.34,24.687V58.762H45.307V24.687Zm133.194,4.868a23.648,23.648,0,0,0-5.45-14.951c-.341-.7-6.813-7.65-6.813-7.65A21.216,21.216,0,0,0,136.6,0H87.206V14.256H134.9a10.985,10.985,0,0,1,7.835,3.477l4.769,5.216a9.375,9.375,0,0,1,0,12.865l-4.769,5.215a11.623,11.623,0,0,1-7.835,3.477H87.206V58.762H136.6a21.216,21.216,0,0,0,15.67-6.954s6.472-6.954,6.813-7.649A22.711,22.711,0,0,0,164.534,29.555Z"
            />
        </svg>
    )
}

export default Logo
