import React from 'react'

import { ROUTES, BASE_URL } from './../constants/routes'

import * as components from '../pages'
import DynamicRoutes from '../../../utils/dynamicRoutes'

export const IntegrationRoutes = () => {
    return (
        <DynamicRoutes
            baseUrl={BASE_URL}
            routes={ROUTES}
            components={components}
            redirectToRoot
        />
    )
}

export default IntegrationRoutes

