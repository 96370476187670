import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_COUNT_TOTAL_TALKS } from '@cms/events/graphql/queries/blocks/totals'

import Card from '../CardInner'

const DisplayTime = ({ eventId, talk, category, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_COUNT_TOTAL_TALKS, {
        variables: { event: eventId, talk, category }
    })

    if (loading || error) return null

    const isTalksViewTimeAverageValid =
        !data.talksViewTimeAverage.humanizedAverage.includes('NaN')

    return (
        <Card
            title={'Tempo Médio'}
            reverse={reverse}
            data={
                data.talksViewTimeAverage.humanizedAverage &&
                isTalksViewTimeAverageValid
                    ? `${
                          data.talksViewTimeAverage.humanizedAverage.split(
                              ':'
                          )[0]
                      } min`
                    : '0'
            }
            align={align}
            full={full}
        />
    )
}

export default DisplayTime
