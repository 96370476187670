import React, { useEffect } from 'react'
import { Form, Select, Row, Tag, Input } from 'antd'

import { Uploader, PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'
import CustomInput from '../../components/CustomInput'

const Seo = (props) => {
    const [form] = Form.useForm()
    const { site } = usePageBuilderContext()
    const { page } = props

    useEffect(() => {
        form.setFieldsValue(page ? site.pages[page].seo : site.seo)
    }, [site, form, page])

    if (!site) return null

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm lg={24} md={12} sm={24}>
                <Form
                    name={page ? `pages.${page}.seo` : 'seo'}
                    form={form}
                    layout="vertical"
                >
                    <Form.Item label="Título" name={'title'}>
                        <CustomInput addVariables />
                    </Form.Item>

                    <Form.Item label="Descrição" name={'description'}>
                        <CustomInput type="textarea" addVariables />
                    </Form.Item>

                    <Form.Item
                        name={['image']}
                        label="Imagem de compartilhamento (1200x630)"
                    >
                        <Uploader
                            options={{
                                optimize: {
                                    resize: {
                                        width: 1200,
                                        height: 630
                                    },
                                    quality: 90
                                }
                            }}
                        />
                    </Form.Item>

                    <Form.Item label="Palavras chaves" name={['keywords']}>
                        <Select
                            mode="tags"
                            tagRender={({ label, ...props }) => (
                                <Tag {...props} color="#7F44FF">
                                    {label}
                                </Tag>
                            )}
                            style={{ width: '100%' }}
                            placeholder="Selecione uma palavra chave existente ou crie uma nova."
                        ></Select>
                    </Form.Item>

                    <PanelFooter customPage />
                </Form>
            </ColForm>
        </Row>
    )
}

export default Seo
