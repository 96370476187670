import React from 'react'
import Icon from '@ant-design/icons'

const StopSvg = () => (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 0.25H10.5C10.6989 0.25 10.8897 0.329018 11.0303 0.46967C11.171 0.610322 11.25 0.801088 11.25 1V10C11.25 10.1989 11.171 10.3897 11.0303 10.5303C10.8897 10.671 10.6989 10.75 10.5 10.75H1.5C1.30109 10.75 1.11032 10.671 0.96967 10.5303C0.829018 10.3897 0.75 10.1989 0.75 10V1C0.75 0.801088 0.829018 0.610322 0.96967 0.46967C1.11032 0.329018 1.30109 0.25 1.5 0.25Z" fill="#FBFCFF"/>
    </svg>

)

export const IconStop = (props) => <Icon component={StopSvg} {...props} />
