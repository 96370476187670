import { TDDashboardCard } from '@cms/core/components/TDCards'
import styled from 'styled-components'

export const StyledTDDashboardCard = styled(TDDashboardCard)`
    height: 100%;
    width: 100%;
`

export const Card = styled.div`
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 16px 25px;
    height: 100%;
    width: 100%;

    .container-light {
        margin: 0;
        padding: 0;
    }
    main {
        padding: 0;
        margin: 10px 0 0 0;
    }

    td.ant-table-cell {
        padding-left: 0;
    }

    .minimal-padding {
        td.ant-table-cell,
        th {
            padding-left: 4px;
            padding-right: 4px;
        }
        .td-content-default {
            background-color: transparent;
        }
    }

    .ant-typography {
        + h1.ant-typography {
            margin-top: 0;
        }
    }
`
export const IconWrapper = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
`
