import React, { useContext } from 'react'
import { Row, Col, Typography, Form, Switch } from 'antd'

import Modal from '@cms/core/components/ModalFull'
import ModalContent from '../ModalContent'
import Context from '../context'

const { Title, Paragraph, Text } = Typography

const Step5 = () => {
    const { onSave } = useContext(Context)

    const SuccessfullIndication = () => {
        const title = 'Indicação bem sucedida ao indicador'
        return (
            <Col span={12}>
                <Row style={{ alignItems: 'baseline' }}>
                    <Text style={{ marginRight: '20px' }}>{title}</Text>
                    <Form.Item
                        valuePropName="checked"
                        style={{ marginBottom: 0 }}
                        name={['messages', 'notificationReferrer', 'active']}
                    >
                        <Switch />
                    </Form.Item>
                    <Paragraph>
                        Habilite para editar o e-mail que será enviado para o
                        referenciador.
                    </Paragraph>
                </Row>
                <Row>
                    <Modal onSave={onSave}>
                        <Form.Item
                            name={[
                                'messages',
                                'notificationReferrer',
                                'emailTemplate'
                            ]}
                        >
                            <ModalContent
                                title={title}
                                variables="referrer"
                                field={['messages', 'notificationReferrer']}
                            />
                        </Form.Item>
                    </Modal>
                </Row>
            </Col>
        )
    }
    return (
        <>
            <Row gutter={16}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        Mensagens
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}>
                        Defina a mensagem que seus usuários receberam após terem
                        uma indicação ou aderirem à campanha com sucesso
                    </Paragraph>

                    <Row gutter={16}>
                        <SuccessfullIndication />
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Step5
