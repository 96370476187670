import styled from "styled-components";

const handleStatusType = type => {
  switch (type) {
    case "STARTED":
      return "background: rgba(75, 223, 127, 0.1);color: #4BDF7F;";
    case "NOT_STARTED":
      return "background: rgba(228, 236, 255, 0.1);";
    case "FINISHED":
      return "background: rgba(228, 236, 255, 0.1);";
    default:
      return "";
  }
};

const handleCircleType = type => {
  switch (type) {
    case "STARTED":
      return "background: #4BDF7F;";
    case "NOT_STARTED":
      return "background: #F9CC15;";
    case "FINISHED":
      return "background: #EE4444;";
    default:
      return "";
  }
};


export const Circle = styled.div`
    ${({ type }) => handleCircleType(type)};
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 5px;
`

export const Wrapper = styled.div`
    ${({ type }) => handleStatusType(type)};
    border-radius: 67px;
    height: 22px;
    font-weight: 700;
    font-size: 10px;
    line-height: 120%;
    width: max-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

`