import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
    // backdrop-filter: blur(40px); // isso quebra position absolute do popover
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
    border: 2px solid rgba(228, 236, 255, 0.1);

    .ant-form-item {
        margin-bottom: 0;
    }

    .list-label {
        margin-top: 0;
    }
`
