import React from 'react'

import { TreeSelectProps } from 'antd'

import { StyledSelectTree } from './styles'
import { SelectTreeProps } from './types'

const SelectTree: React.FC<SelectTreeProps> = ({
    treeData,
    value: valueForm,
    onChange: onChangeForm,
    ...rest
}) => {
    const tSelectProps: TreeSelectProps = {
        treeData,
        value: valueForm,
        onChange: (newValue: string[]) => {
            onChangeForm(newValue)
        },
        showCheckedStrategy: 'SHOW_PARENT',
        treeDefaultExpandAll: true,
        defaultValue: valueForm,
        multiple: true,
        showSearch: true,
        filterTreeNode: (inputValue: string, treeNode: any) =>
            treeNode.title.toLowerCase().includes(inputValue.toLowerCase()),
        ...rest
    }

    return <StyledSelectTree {...tSelectProps} />
}

export default SelectTree
