import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Layout, Row, Col, Form, Input, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'

import Logo from '@cms/core/components/Logo'
import { FORGOT_PASSWORD, CODE_CHECK } from '@cms/core/graphql/mutations/auth'
import { login } from '@cms/core/utils/auth'

import { Container, Box } from './styles'

export default function RecoverPassword() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [code, setCode] = useState('')
    const [codeSubmitted, setCodeSubmitted] = useState(false)

    const [forgotPassword] = useMutation(FORGOT_PASSWORD)
    const [codeCheck] = useMutation(CODE_CHECK)

    function renderEmailForm() {
        return (
            <Form
                onFinish={async () => {
                    await forgotPassword({ variables: { email } })
                    setCodeSubmitted(true)
                }}
            >
                <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Insira seu email' }]}
                >
                    <Input
                        size={'large'}
                        onChange={(e) => setEmail(e.target.value)}
                        suffix={
                            <UserOutlined className="site-form-item-icon" />
                        }
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item>
                    <button type="submit">Confirmar</button>
                </Form.Item>
            </Form>
        )
    }

    const handleFinish = async () => {
        const { data } = await codeCheck({ variables: { email, code } })

        if (data && data.codeCheck) login(data.codeCheck)
        history.push('/main/perfil/alterar-senha')
    }

    function renderCodeForm() {
        return (
            <Form onFinish={handleFinish}>
                <Form.Item
                    name="code"
                    rules={[{ required: true, message: 'Insira o código.' }]}
                >
                    <Input
                        size={'large'}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Código"
                    />
                </Form.Item>
                <Form.Item>
                    <button type="submit">Concluir</button>
                </Form.Item>
            </Form>
        )
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Container>
                <Row style={{ height: '100vh' }} align={'middle'}>
                    <Col
                        lg={24}
                        sm={24}
                        xs={24}
                        md={24}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Box>
                            <Logo />
                            <Typography.Title level={4}>
                                Recuperação de senha
                            </Typography.Title>
                            {!codeSubmitted
                                ? renderEmailForm()
                                : renderCodeForm()}
                            <Link to="/">Fazer login</Link>
                        </Box>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}
