import React from 'react'

import { Form, Typography } from 'antd'
import { Wrapper } from '@cms/core/components/Theme/styles'

import { ISectonButtonProps } from './types'
import Colors from '@cms/core/components/Colors'

const { Title } = Typography

type TMessages = 'success' | 'error' | 'warning'

export const messagesTheme: Record<
    TMessages,
    { label: string; color: string }
> = {
    success: {
        label: 'Sucesso',
        color: '#4BB543'
    },
    error: {
        label: 'Erro',
        color: '#FF0000'
    },
    warning: {
        label: 'Aviso',
        color: '#FEBE10'
    }
}

export const SectionMessages = ({ title, name }: ISectonButtonProps) => {
    const _name = Array.isArray(name) ? [...name] : [name]
    return (
        <Wrapper>
            <Title className="list-label" level={4}>
                {title}
            </Title>

            {Object.entries(messagesTheme).map(([key, { label, color }]) => (
                <Wrapper key={key}>
                    <Form.Item
                        label={label}
                        name={[..._name, key]}
                        initialValue={color}
                    >
                        <Colors formItem gradient={false} />
                    </Form.Item>
                </Wrapper>
            ))}
        </Wrapper>
    )
}

