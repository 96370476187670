import React from 'react'
import { Form, Row, Col, Input, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import Card from '@cms/core/components/Card'
import BodyValues from '../../BodyValues/BodyValues'

const Webhook = () => {
    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Url de destino"
                        name={['privateConfig', 'url']}
                        rules={[
                            {
                                required: true,
                                message: 'Url é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <Card title="Cabeçalhos ">
                        <Form.List name={['privateConfig', 'headers']}>
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <div key={`headers.${index}`}>
                                            <Row
                                                className="actionsBox"
                                                gutter={24}
                                                align="middle"
                                            >
                                                <Col span={8}>
                                                    <Form.Item
                                                        label="Nome"
                                                        name={[
                                                            field.name,
                                                            'name'
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Nome do header é obrigatório!'
                                                            }
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={14}>
                                                    <Form.Item
                                                        {...field}
                                                        label="Valor"
                                                        name={[
                                                            field.name,
                                                            'value'
                                                        ]}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Valor do header é obrigatório!'
                                                            }
                                                        ]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={2}>
                                                    <MinusCircleOutlined
                                                        style={{
                                                            marginTop: 20
                                                        }}
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add({})
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Adicionar
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Card>
                    <BodyValues type="Webhook" />
                </Col>
            </Row>
        </>
    )
}

export default Webhook
