import React, { useState } from 'react'
import { Checkbox, Modal } from 'antd'

const Term = ({ label, config }) => {
    const [visible, setVisible] = useState(false)

    const Label = () => {
        if (config.url) {
            return (
                <a href={config.url} target="_blank" rel="noreferrer">
                    {config.label}
                </a>
            )
        } else {
            return (
                <a
                    href={'#'}
                    onClick={(e) => {
                        setVisible(true)
                    }}
                    rel="noreferrer"
                >
                    {config.label}
                </a>
            )
        }
    }

    return (<>
        <Checkbox>
            {label} <Label />
        </Checkbox>
        <Modal
            title={config.label}
            open={visible}
            onCancel={() => setVisible(false)}
            footer={null}
        >
            <div dangerouslySetInnerHTML={{ __html: config.text }} />
        </Modal>
    </>);
}

export default Term
