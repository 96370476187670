import { InMemoryCache, makeVar } from '@apollo/client'
import { isAuthenticated, CurrentUser } from '@cms/core/utils/auth'
import { relayStylePagination } from '@apollo/client/utilities'

const Args = (keyArgs) => (args) =>
    args ? keyArgs.filter((keyArg) => args.hasOwnProperty(keyArg)) : null

const DefaultArgs = ['filter', 'search', 'orderBy']
const cacheParams = Args(DefaultArgs)

export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isAuthenticated: {
                    read() {
                        return isAuthenticated()
                    }
                },
                customers: relayStylePagination(cacheParams),
                users: relayStylePagination(cacheParams),
                categories: relayStylePagination(cacheParams),
                talks: relayStylePagination(cacheParams),
                speakers: relayStylePagination(cacheParams),
                brands: relayStylePagination(cacheParams),
                banners: relayStylePagination(cacheParams),
                notifications: relayStylePagination(cacheParams),
                chatMessages: relayStylePagination(
                    Args([...DefaultArgs, 'channel'])
                ),
                eventRegistrations: relayStylePagination(cacheParams),
                formDatas: relayStylePagination(cacheParams),
                collectionCategories: relayStylePagination(cacheParams),
                collectionItems: relayStylePagination(cacheParams),
                eventEditions: relayStylePagination(cacheParams),
                userProfiles: relayStylePagination(cacheParams),
                watchContents: relayStylePagination(cacheParams),
                watchCategories: relayStylePagination(cacheParams),
                watchCourses: relayStylePagination(cacheParams)
            }
        }
    }
})
