import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_INTEGRATION_LOGS = gql`
    query GetintegrationLogs(
        $first: Int
        $afterCursor: Cursor
        $orderBy: IntegrationLogOrderBy
        $status: IntegrationLogStatus
        $integration: ObjectID
        $eventId: ObjectID
    ) {
        integrationLogs(
            first: $first
            after: $afterCursor
            filter: {
                event: $eventId
                status: $status
                integration: $integration
            }
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...IntegrationLogData
                }
            }
        }
    }
    ${fragments.IntegrationLogData}
`
