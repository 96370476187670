import {
    CaretDownOutlined,
    CaretUpOutlined,
    EyeInvisibleOutlined,
    EyeOutlined
} from '@ant-design/icons'
import { Form, Space, Switch } from 'antd'
import React from 'react'

import { LABELS } from '../../constants'
import { Row } from './styles'
import { debounce } from 'lodash'
import InputTranslation from '@cms/events/components/InputTranslation'

const PanelHeader = (props) => {
    const {
        index,
        name,
        form,
        visible,
        label,
        move,
        editable = true,
        size
    } = props

    const handleClickUp = () => {
        move(index, index - 1)
        form.submit()
    }

    const handleClickDown = () => {
        move(index, index + 1)
        form.submit()
    }

    const onChecked = () => {
        form.submit()
    }

    const onLabelChange = () => {
        form.submit()
    }

    const enableUpClick = index > 0
    const enableDownClick = index < size - 1

    const fixedMenu = name === 'Home' || name === 'Perfil do usuário'

    return (
        <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Row align="middle" justify="start">
                <Space>
                    {editable && (
                        <div className="move-name">
                            {enableUpClick && (
                                <CaretUpOutlined onClick={handleClickUp} />
                            )}
                            {enableDownClick && (
                                <CaretDownOutlined onClick={handleClickDown} />
                            )}
                        </div>
                    )}

                    {!fixedMenu && (
                        <Form.Item name={[index, 'label']} initialValue={label}>
                            <InputTranslation
                                onChange={debounce(onLabelChange, 200)}
                            />
                        </Form.Item>
                    )}

                    {fixedMenu && <div>{LABELS[name] || name}</div>}
                </Space>
            </Row>

            <Row align="middle">
                {index >= 0 ? (
                    <Form.Item
                        className="panel-header"
                        name={[index, 'visible']}
                        initialValue={visible}
                        valuePropName="checked"
                    >
                        <Switch
                            checked={visible}
                            checkedChildren={
                                <EyeOutlined
                                    style={{
                                        fontSize: '16px',
                                        display: 'block'
                                    }}
                                />
                            }
                            unCheckedChildren={
                                <EyeInvisibleOutlined
                                    style={{
                                        fontSize: '16px',
                                        display: 'block'
                                    }}
                                />
                            }
                            onChange={onChecked}
                            size="small"
                        />
                    </Form.Item>
                ) : (
                    <Switch
                        disabled
                        checked={true}
                        checkedChildren={
                            <EyeOutlined
                                style={{
                                    fontSize: '16px',
                                    display: 'block'
                                }}
                            />
                        }
                        size="small"
                    />
                )}
            </Row>
        </Row>
    )
}

export default PanelHeader
