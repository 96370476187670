import React from 'react'

import { Form, Input, Checkbox, Row, Col, Select, Button } from 'antd'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import Card from '@cms/core/components/Card'

import { Multiple, Limits, CpfCnpj, Term, DateLimits } from './fields'
import { VALIDATE_MESSAGES } from '../../../../constants/formMessages'
import { useFormBuilderContext } from '@cms/events/pages/Forms/context/context'
import { allowFloatLabelTypes } from '@cms/events/pages/Forms/constants/fields'

const { Option } = Select

const rowGutter = [24, 0]

const FieldsForm = ({ ...props }) => {
    const { field, isEdit, debounceChange, form } = props
    const { formData } = useFormBuilderContext()

    const adressFields = ['address', 'cepStateCity', 'stateCity']
    const optionsFields = ['select', 'radio', 'checkbox']

    if (!field) return null

    const hanleOnKeyPress = (e) => {
        const regex = new RegExp('^[a-zA-Z0-9._\b]+$')
        const str = String.fromCharCode(!e.charCode ? e.which : e.charCode)
        if (regex.test(str)) {
            return true
        }

        e.preventDefault()
        return false
    }

    const handleBlurLabel = () => {
        const fields = form.getFieldsValue()

        if (!fields.name) {
            const name = fields.label
                .replace(/[^a-zA-Z0-9-\s._]/g, '')
                .replace(/\s(.)/g, function ($1) {
                    return $1.toUpperCase()
                })
                .replace(/\s/g, '')

            form.setFieldsValue({ ...form.getFieldsValue(), name })
        }
    }

    return (
        <Form
            form={form}
            validateMessages={VALIDATE_MESSAGES}
            layout="vertical"
            name={isEdit ? 'fields-edit' : 'fields'}
        >
            <Form.Item
                className="field-vertical"
                label="Tipo do campo"
                name={['type']}
                style={{ display: 'none' }}
            >
                <Input disabled />
            </Form.Item>
            <Form.Item
                className="field-vertical"
                label="Sistema"
                name={['config', 'system']}
                style={{ display: 'none' }}
            >
                <Input disabled />
            </Form.Item>
            <Row gutter={rowGutter}>
                <Col span={12}>
                    <Form.Item
                        className="field-vertical"
                        label={
                            field.type === 'section'
                                ? 'Título da seção'
                                : 'Label do campo'
                        }
                        name={['label']}
                        rules={[{ required: true }]}
                    >
                        <Input type={'text'} onBlur={handleBlurLabel} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        className={`field-vertical${
                            isEdit ? ' field-isediting' : ''
                        }`}
                        tooltip="Não são permitidos caracteres especiais(com exceção _) ou espaços"
                        label="Nome do campo"
                        name={['name']}
                        rules={[
                            {
                                required: true
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    const found = debounceChange(value, isEdit)
                                    if (found) {
                                        return Promise.resolve()
                                    }
                                    return Promise.reject(
                                        new Error('O nome do campo já existe!')
                                    )
                                }
                            })
                        ]}
                    >
                        <Input
                            type={'text'}
                            disabled={field.config?.system || isEdit}
                            onKeyPress={hanleOnKeyPress}
                        />
                    </Form.Item>
                </Col>

                {allowFloatLabelTypes.includes(field.type) && (
                    <>
                        <Col span={12}>
                            <Form.Item
                                className="field-vertical"
                                name={['config', 'floatLabel']}
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Checkbox
                                    onChange={(e) => {
                                        if (!e.target.checked) {
                                            form.setFieldsValue({
                                                ...form.getFieldsValue(),
                                                config: {
                                                    ...form.getFieldsValue()
                                                        .config,
                                                    placeholder: ''
                                                }
                                            })
                                        }
                                    }}
                                >
                                    Label Flutuante
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item shouldUpdate noStyle>
                                {({ getFieldValue }) => {
                                    const floatLabel = getFieldValue([
                                        'config',
                                        'floatLabel'
                                    ])

                                    if (!floatLabel) return null

                                    return (
                                        <Form.Item
                                            className="field-vertical"
                                            label={'Placeholder do campo'}
                                            name={['config', 'placeholder']}
                                        >
                                            <Input
                                                type={'text'}
                                                onBlur={handleBlurLabel}
                                            />
                                        </Form.Item>
                                    )
                                }}
                            </Form.Item>
                        </Col>
                    </>
                )}
                {field.config?.size && adressFields.indexOf(field.type) < 0 && (
                    <Col span={12}>
                        <Form.Item
                            className="field-vertical"
                            label="Tamanho do campo"
                            name={['config', 'size']}
                            rules={[{ required: true }]}
                        >
                            <Select>
                                <Option value={24}>100%</Option>
                                <Option value={18}>75%</Option>
                                <Option value={12}>50%</Option>
                                <Option value={6}>25%</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                )}
                {field.type === 'select' && (
                    <Col span={12}>
                        <Form.Item
                            className="field-vertical"
                            name={['config', 'autoHeight']}
                            valuePropName="checked"
                            initialValue={false}
                        >
                            <Checkbox>Altura automática</Checkbox>
                        </Form.Item>
                    </Col>
                )}
            </Row>
            {field.type !== 'section' && (
                <Row gutter={rowGutter}>
                    <Col span={24}>
                        <Form.Item
                            name={['config', 'required']}
                            valuePropName="checked"
                            initialValue={true}
                            style={{ marginBottom: '6px' }}
                        >
                            <Checkbox>Campo preenchimento obrigatório</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {!field.config?.system ? (
                <Row gutter={rowGutter}>
                    <Col span={24}>
                        <Form.Item
                            name={['encrypted']}
                            valuePropName="checked"
                            initialValue={false}
                            style={{ marginBottom: '6px' }}
                        >
                            <Checkbox>Criptografar Campo</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            ) : null}
            {field.config && 'multiple' in field.config && (
                <Row gutter={rowGutter}>
                    <Col span={24}>
                        <Form.Item
                            name={['config', 'multiple']}
                            valuePropName="checked"
                            style={{ marginBottom: '6px' }}
                        >
                            <Checkbox>Campo permite multipla seleção</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {optionsFields.indexOf(field.type) >= 0 && (
                <Row gutter={rowGutter}>
                    <Col span={24}>
                        <Multiple />
                    </Col>
                </Row>
            )}
            {field.config && 'lengthControl' in field.config && (
                <Limits {...field} />
            )}
            {field.config && 'dateLimits' in field.config && (
                <DateLimits {...field} />
            )}
            {field.type === 'document' && <CpfCnpj {...field} />}
            {field.type === 'term' && <Term {...field} />}

            <Col span={24}>
                <Card title="Regras de exibição">
                    <Form.List name={['config', 'visibilityRules']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`headers.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={8}>
                                                <Form.Item
                                                    className="field-vertical"
                                                    label="Compo do Formulário"
                                                    name={[field.name, 'field']}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select>
                                                        {formData?.fields?.map(
                                                            (o) => (
                                                                <Option
                                                                    key={o.name}
                                                                    value={
                                                                        o.name
                                                                    }
                                                                >
                                                                    {o.label}
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={1}>=</Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    {...field}
                                                    label="Valor"
                                                    name={[field.name, 'value']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Valor do header é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{
                                                        marginTop: 20
                                                    }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>
            </Col>
        </Form>
    )
}

export default FieldsForm
