import React from 'react'
import { Row, Button, Space, Popconfirm } from 'antd'

import {
    EditOutlined,
    DeleteOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons'

import { useFormBuilderContext } from '@cms/events/pages/Forms/context/context'
import { WrapperBtns } from '../../../../../styles'

const Label = ({ label, target, remove, index, move, form }) => {
    const field = form.getFieldValue(['fields', target])
    const size = form.getFieldsValue('fields').fields.length
    const { setSelectField, setFormData } = useFormBuilderContext()

    const handleEdit = (e) => {
        setSelectField({ field, isEdit: true, target })
    }

    const handleRemove = async () => {
        await remove(index)

        const fields = form.getFieldValue()
        setFormData(fields)
    }

    const handleClickUp = () => {
        move(index, index - 1)
        form.submit()
    }

    const handleClickDown = () => {
        move(index, index + 1)
        form.submit()
    }

    return (
        <Row
            className="fake-label"
            style={{ width: '100%' }}
            justify="space-between"
        >
            <Space>
                <span>{label}</span>
                <div>
                    {index > 2 && (
                        <CaretUpOutlined
                            onClick={handleClickUp}
                            style={{ marginRight: '6px' }}
                        />
                    )}
                    {index > 1 && index !== size - 1 && (
                        <CaretDownOutlined onClick={handleClickDown} />
                    )}
                </div>
            </Space>
            <Space>
                <WrapperBtns>
                    <Button
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={handleEdit}
                        style={{ marginRight: '10px' }}
                    />
                    {!field.config.system && (
                        <Popconfirm
                            title="Deseja remover o campo selecionado"
                            onConfirm={handleRemove}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button shape="circle" icon={<DeleteOutlined />} />
                        </Popconfirm>
                    )}
                </WrapperBtns>
            </Space>
        </Row>
    )
}

export default Label
