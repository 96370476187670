import React from 'react'
import CategoriesBuilderRoutes from './routes'
import Content from '@cms/core/components/Content'

const CategoriesList = () => {
    return (
        <>
            <CategoriesBuilderRoutes />
        </>
    )
}

export default CategoriesList
