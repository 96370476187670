import React from 'react'
import { useParams } from 'react-router-dom'

import CheckDomainStatus from './CheckDomainStatus'

import { Form } from '../styles'
import { PageHeader } from '@ant-design/pro-layout'
import { message } from 'antd'
import { Button, Col, Row, Input } from 'antd'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'
import { CollapseDomain } from '@cms/events/components/Collapses'
import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { useApolloClient } from '@apollo/client'

const ConfigDomains = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [{ form, formSave, saveLoading, getError, formData, formUpdate }] =
        useCrudForm(
            {
                id: eventId,
                getData: async (id) => {
                    if (!id) return
                    const response = await client.query({
                        query: GET_EVENT,
                        variables: { id }
                    })

                    const data = { ...response.data.event }

                    //checkDomain(data.domain.name)

                    return data
                },
                updateData: async function (id, formData) {
                    const variables = { id, ...formData }

                    await client.mutate({
                        mutation: UPDATE_EVENT,
                        variables
                    })

                    await this.refetchData()

                    message.success('Domínio atualizado com sucesso!')
                }
            },
            [eventId]
        )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Domínio"
                subTitle="Configuração de dominio"
            />
            <Content className="container-light">
                <Form layout="vertical" form={form} onFinish={formSave}>
                    <IgnorePasswordAutoComplete />
                    <Form.Item
                        name={['domain', 'name']}
                        label={'Dominio'}
                        rules={[{ message: 'Preencha o domínio' }]}
                        {...getError('domain.name')}
                    >
                        <Input
                            placeholder="Digite o domínio do evento"
                            onChange={formUpdate}
                        />
                    </Form.Item>

                    <CheckDomainStatus field="domain" />

                    {formData?.domain?.name && (
                        <CollapseDomain
                            field="domain"
                            domainName={formData.domain.name}
                        />
                    )}

                    <Form.Item
                        name={['streamingDomain', 'name']}
                        label="Domínio de Transmissão"
                        {...getError('streamingDomain.name')}
                    >
                        <Input
                            placeholder="Digite o domínio de transmissão"
                            onChange={formUpdate}
                        />
                    </Form.Item>

                    <CheckDomainStatus field="streamingDomain" />

                    {formData?.streamingDomain?.name && (
                        <CollapseDomain
                            field="streamingDomain"
                            domainName={formData.streamingDomain.name}
                        />
                    )}

                    <Form.Item
                        name={['checkoutDomain', 'name']}
                        label="Domínio do checkout"
                        {...getError('checkoutDomain.name')}
                    >
                        <Input
                            placeholder="Digite o domínio de checkout"
                            onChange={formUpdate}
                        />
                    </Form.Item>

                    <CheckDomainStatus field="checkoutDomain" />

                    {formData?.checkoutDomain?.name && (
                        <CollapseDomain
                            field="checkoutDomain"
                            domainName={formData.checkoutDomain.name}
                        />
                    )}

                    <Row className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default ConfigDomains
