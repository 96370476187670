import styled from "styled-components";

export const Wrapper = styled.div`
    display: block;
    .answers{
        margin-bottom: 20px;
    }
    .button-footer{
        margin-top:20px;
        margin-left: 5px;
    }
`