import React, { useEffect } from 'react'
import { Form, Select, Row, Tag, Input } from 'antd'

import {
    Uploader,
    PanelFooter,
    ContainerConfig,
    ButtonConfig,
    ColorsBadges
} from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm, Wrapper } from './styles'
import { Title } from '../../styles'

const Cookies = (props) => {
    const [form] = Form.useForm()
    const { site } = usePageBuilderContext()

    useEffect(() => {
        form.setFieldsValue(site.seo)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24}>
            <ColForm md={12} sm={24}>
                <Form
                    name="cookies"
                    form={form}
                    // onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Wrapper>
                        <Title level={4}>Fundo</Title>
                        <Form.Item
                            name={['configs', 'container', 'bgColor']}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>
                    <Wrapper>
                        <Title level={4}>Texto</Title>
                        <Form.Item
                            name={['configs', 'text', 'textColor']}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>
                    <ButtonConfig
                        {...props}
                        enableText={false}
                        enableActions={false}
                        fieldPath={['configs', 'button']}
                    />
                    <PanelFooter />
                </Form>
            </ColForm>
        </Row>
    )
}

export default Cookies
