import React from 'react'
import { Button, Space, Switch, Form } from 'antd'
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons'

import { LABELS } from '../../constants/labels'
import { Row } from './styles'

const PanelHeader = (props) => {
    const { index, section, checked, form, activePanel, setSection, move } =
        props
    // if(section === 'header') return <>{labels[section]}</>
    const size = form.getFieldsValue('sections')?.sections?.length || 0

    const handleClickUp = () => {
        move(index, index - 1)
        form.submit()
    }

    const handleClickDown = () => {
        move(index, index + 1)
        form.submit()
    }

    const onChecked = (e, index) => {
        form.submit()
    }

    return (
        <Row style={{ width: '100%' }} justify="space-between">
            <Space>
                {index > 1 && index <= size - 2 && (
                    <CaretUpOutlined onClick={handleClickUp} />
                )}
                {index > 0 && index < size - 2 && (
                    <CaretDownOutlined onClick={handleClickDown} />
                )}
                {LABELS[section]}
            </Space>
            <Space>
                {index >= 1 && (
                    <Form.Item
                        className="panel-header"
                        name={[index, 'active']}
                        initialValue={checked}
                        valuePropName="checked"
                    >
                        <Switch checked={checked} onChange={onChecked} />
                    </Form.Item>
                )}
                {activePanel === section ? (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setSection(null)
                        }}
                    >
                        Fechar
                    </Button>
                ) : (
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            setSection(section)
                            // window.location.hash = `section-${section}`
                        }}
                    >
                        Editar
                    </Button>
                )}
            </Space>
        </Row>
    )
}

export default PanelHeader
