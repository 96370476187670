import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'

export const AdminModules: IAppModule[] = [
    {
        name: 'Clientes',
        icon: sidebarIcons.registrations,
        module: 'admin.clients',
        type: 'main',
        children: [
            {
                name: 'Clientes',
                alias: true,
                path: '/clientes',
                module: 'admin.clients'
            }
        ]
    },
    {
        name: 'Planos',
        icon: sidebarIcons.payments,
        module: 'admin.plans',
        type: 'main',
        children: [
            {
                alias: true,
                name: 'Planos',
                path: '/planos',
                module: 'admin.plans'
            }
        ]
    }
]
