import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import Shorts from './Shorts/Shorts'
import VideosComponent from './Videos/Videos'

export const Videos = {
    Shorts: {
        Component: Shorts,
        name: 'Vídeos 9:16 - Shorts',
        category: 'Videos',
        columns: '1',
        thumb: require('./Shorts/shorts.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Videos: {
        Component: VideosComponent,
        name: 'Vídeos 16:9',
        category: 'Videos',
        columns: '1',
        thumb: require('./Videos/videos.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
