import React from 'react'

import { useSelector } from 'react-redux'

import { Row, Col, Typography, Form } from 'antd'

import ColorPicker from '../../ColorPicker'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

const { Paragraph, Text } = Typography

const StepThree = () => {
    const enableMobile = useSelector((state) => state.customer['enableMobile'])

    return (
        <>
            <Text type="primary" level={4}>
                Para finalizar adicione sua logo.
            </Text>

            <Paragraph type="secondary" level={4}>
                Se você quiser pular essa etapa, basta clicar no botão "Salvar".
            </Paragraph>

            <Form.Item label="Logo" name="logo">
                <MediaUploader type="image" />
            </Form.Item>

            <Row>
                <Col span={12}>
                    <Form.Item
                        label="Imagem de fundo - Login"
                        name="backgroundImageDesktop"
                    >
                        <MediaUploader type="image" />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Imagem de fundo - Home"
                        name="backgroundImageHome"
                    >
                        <MediaUploader
                            type="image"
                            options={{
                                optimize: {
                                    quality: 80
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default StepThree
