import React from 'react'
import { Col, Row } from 'antd'
import Text from 'antd/es/typography/Text'

import { IHeaderLabel } from '../constants/fieldList'

export interface IListFieldHeaderProps {
    headers: IHeaderLabel[]
}
export function ListFieldHeader({ headers }: IListFieldHeaderProps) {
    return (
        <Col span={24} style={{ marginBottom: '12px' }}>
            <Row gutter={24}>
                {headers.map((headerLabel) => {
                    return (
                        <Col span={headerLabel.size} key={headerLabel.title}>
                            <Text
                                style={{
                                    color: '#fff'
                                }}
                            >
                                {headerLabel.title}
                            </Text>
                        </Col>
                    )
                })}
            </Row>
        </Col>
    )
}
