import { gql } from '@apollo/client'

export const GET_WIDGETS = gql`
    query WidgetByType($eventId: ObjectID, $type: String) {
        widgetByType(type: $type, event: $eventId) {
            type
            data
        }
    }
`
