import React, { useCallback, useEffect, useMemo } from 'react'

import { PageHeader } from '@ant-design/pro-layout'

import { Col, Row, Form } from 'antd'
import { merge } from 'lodash'

import { useThemeBuilderContext } from '../../context'
import MediaUploaderTS from '@cms/core/components/MediaUploaderTS/graphql'
import { DEFAULT_SECTIONS } from './constants'

const ThemeConfigImages = () => {
    const [form] = Form.useForm()

    const {
        images,
        setImages,
        getSectionConfig,
        sections: _sections
    } = useThemeBuilderContext()

    const currentConfigs = useMemo(() => {
        const section = getSectionConfig('Images')
        return {
            title: 'Imagens',
            subTitle: 'Configuração imagens da página de transmissão',
            sections: DEFAULT_SECTIONS,
            ...section
        }
    }, [_sections])

    useEffect(() => {
        if (images) {
            form.setFieldsValue(images)
        }
    }, [images])

    const updateImages = useCallback(
        (values) => {
            setImages((prevImages) => merge({}, prevImages, values))
        },
        [setImages]
    )

    if (!currentConfigs.sections) return null

    const { title, subTitle, sections } = currentConfigs

    return (
        <>
            <PageHeader
                className="site-page-header"
                title={title}
                subTitle={subTitle}
            />
            <Form
                layout="vertical"
                form={form}
                onValuesChange={updateImages}
                hideRequiredMark
                className="bg-light"
            >
                {sections.map((section, index) => (
                    <Row key={index} style={{ marginTop: 20 }}>
                        {Array.isArray(section) ? (
                            section.map((item, index) => (
                                <Col span={6} key={index}>
                                    <Form.Item
                                        label={item.label}
                                        name={item.name}
                                    >
                                        <MediaUploaderTS
                                            type="image"
                                            style="image"
                                            options={{
                                                optimize: {
                                                    quality: 85
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            ))
                        ) : (
                            <Col span={6} key={index}>
                                <Form.Item
                                    label={section.label}
                                    name={section.name}
                                >
                                    <MediaUploaderTS
                                        type="image"
                                        style="image"
                                        options={{
                                            optimize: {
                                                quality: 85
                                            }
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                ))}
            </Form>
        </>
    )
}

export default ThemeConfigImages
