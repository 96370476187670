import styled from 'styled-components'

export const DetailsLead = styled.div`
    padding: 8px;
    height: 55vh;
    padding: 20px;
    margin-top: 15px;
    margin-right: 15px;

    h1.ant-typography {
        margin-bottom: 0;
        color: #ececec;
        font-weight: 500;
        font-size: 1.1em;
    }

    .ant-divider-horizontal {
        margin: 12px 0;
    }

    #ScrollDetails {
        height: 90%;
        overflow-y: scroll;
        margin-bottom: 1.5vh;
    }

    #ScrollDetails::-webkit-scrollbar {
        margin-left: 10px;
    }

    @media (max-width: 1320px) {
        width: 100%;
        margin-right: 0;
    }
`

