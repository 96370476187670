import { OPTIONS_VALUE } from './options'
import { ORDER_BY_TALKS } from '../../../OrderBy/constants'
import { SectionsType } from '../../../../constants/initialData'

export const TALKS_CONFIGS = {
    talks: {
        type: SectionsType.talks,
        defaultValues: {},
        fields: {
            _id: {
                label: 'Palestras',
                type: 'select',
                options: OPTIONS_VALUE.talks
            },
            stage: {
                label: 'Palcos',
                type: 'select',
                options: OPTIONS_VALUE.stages
            },
            status: {
                label: 'Status',
                type: 'selectSimple',
                options: [
                    { value: 'WAITING', label: 'Aguardando' },
                    { value: 'STARTED', label: 'Iniciada' },
                    { value: 'FINISHED', label: 'Finalizada' }
                ]
            },
            categories: {
                label: 'Categorias',
                type: 'select',
                options: [
                    { value: 'MAIN', label: 'Trilhas' },
                    { value: 'SERIE', label: 'Series' },
                    { value: 'PLAYLIST', label: 'Playlists' }
                ]
            },
            tags: {
                label: 'Tags',
                type: 'select',
                options: OPTIONS_VALUE.talkTags
            },
            type: {
                label: 'Tipo palestra',
                type: 'selectSimple',
                options: [
                    { value: 'LIVE', label: 'Live' },
                    { value: 'ONDEMAND', label: 'On demand' },
                    { value: 'STAGE', label: 'Palco' }
                ]
            }
        },
        orderBy: {
            ...ORDER_BY_TALKS
        }
    }
}
