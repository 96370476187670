import { ORDER_BY_NAME } from '@cms/core/components/Segment/OrderBy/constants'
import { OPTIONS_VALUE } from './options'

import { SectionsType } from '@cms/watch/pages/Config/constants/initialData'

export const EXPERTS_CONFIGS = {
    experts: {
        type: SectionsType.experts,
        label: 'Especialistas',
        defaultValues: {},
        fields: {
            _id: {
                label: 'Especialistas',
                type: 'select',
                options: OPTIONS_VALUE.experts
            }
        },
        orderBy: {
            ...ORDER_BY_NAME
        }
    }
}

