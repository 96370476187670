import { Row } from "antd";
import styled from "styled-components";

export const WrapperSurvey = styled.div`
    margin: 10px 0;
    padding: 16px;
    background: linear-gradient(211.89deg, rgba(88, 93, 98, 0.3) 0.73%, rgba(88, 93, 98, 0.05) 120.33%);
    backdrop-filter: blur(40px);
    border-radius: 20px;
    width: 100%;
`
export const WrapperText = styled(Row)`
    width: 100%;
    margin-top: 15px;
    justify-content: space-between;
`

export const Actions = styled.div`
    align-self: end;

    button, .ant-btn {
        background-color: transparent;
        border: none;
        cursor: pointer;
        box-shadow: none;
    }
    a, button {
        margin-right: 30px;
    }
    a:last-child , button:last-child {
        margin-right: 0;
    }
`