import Learn from './Learn'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Learns = {
    Learn: {
        Component: Learn,
        name: 'O que você vai aprender',
        category: 'O que você vai aprender',
        thumb: require('./learn.png'),
        columns: '2',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'O que você\nvai aprender:',
                    textColor: getColorContrast(colors, 'primary')
                },
                content: {
                    text: 'A nova realidade de vendas no mundo híbrido, omnichannel. \n\n Visão de futuro da experiência para vendas b2b. \n\n Visão profunda de toda a nova jornada de vendas, suas oportunidades e pontos de atenção. \n\n Insights práticos com impacto direto em geração de receita. \n\n Como otimizar os múltiplos novos canais de vendas disponíveis.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
