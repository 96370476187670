import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { Button } from 'antd'

const ButtonLink = ({ id, path, text, to, ...props }) => {
    const history = useHistory()
    const { url } = useRouteMatch()

    const _path = path ? `${url}/${id}/${path}` : `${url}/${id}/editar`

    return (
        <Button
            type='primary'
            key={id}
            onClick={() => history.push(to ? to : _path)}
            {...props}>
            <span>{text ? text : 'Editar'}</span>
        </Button>
    )
}

export default ButtonLink