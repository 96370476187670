export const DIRECTIONS = {
    CRESCENTE: 'asc',
    DECRESCENTE: 'desc'
}

export const ORDER_BY_NAME = {
    name: {
        label: 'Nome',
        field: 'name',
        options: {
            ...DIRECTIONS
        }
    }
}

export const ORDER_BY_TITLE = {
    title: {
        label: 'Título',
        field: 'titulo',
        options: {
            ...DIRECTIONS
        }
    }
}

export const ORDER_BY_PRIORITY = {
    priority: {
        label: 'Prioridade',
        field: 'priority',
        options: {
            ...DIRECTIONS
        }
    }
}

export const ORDER_BY_TALKS = {
    title: {
        label: 'Título',
        field: 'titulo',
        options: {
            ...DIRECTIONS
        }
    },
    ...ORDER_BY_PRIORITY,
    startDate: {
        label: 'Data de início',
        field: 'startDate',
        options: {
            ...DIRECTIONS
        }
    },
    endDate: {
        label: 'Data de fim',
        field: 'endDate',
        options: {
            ...DIRECTIONS
        }
    }
}
