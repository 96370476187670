import React from 'react'
import { Card } from 'antd'

import { HeaderProfile } from './styles'

const Header = ({ children }) => {
    return <HeaderProfile>{children}</HeaderProfile>
}

export default Header

