import React from 'react'
import { Form, Select, Switch, Tag } from 'antd'

import { ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_SPEAKER_TAGS } from '@cms/events/graphql/queries/speaker'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const FeaturedSpeakers = (props) => {
    const { eventId } = useParams()
    const { data: TagsData } = useQuery(GET_SPEAKER_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Regras de exibição</Title>
                <Form.Item
                    name={[props.name, 'configs', 'filters', 'featured']}
                    valuePropName="checked"
                    initialValue={true}
                    label="Exibir apenas palestrantes Destaque"
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                    />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Exibir apenas palestrantes com as tags:"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.speakerTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Box</Title>
                <Form.Item
                    name={[props.name, 'configs', 'cards', 'text', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'cards', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'cards', 'icon', 'color']}
                    label="Cor do icone das redes sociais"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default FeaturedSpeakers
