import { gql } from '@apollo/client'

export const GET_SEGMENTS_FIELDS = gql`
    query eventRegistrationsSegmentFields($event: ObjectID!) {
        eventRegistrationsSegmentFields(event: $event) {
            name
            label
            type
            options
        }
    }
`
