import React from 'react'

import Menus from './menu'
import MainMenu from './MainMenu'
import SecondMenu from './SecondMenu'

import { SidebarListContent } from './styles'

const SidebarMenu = ({ menu, skipPermission = false }) => {
    return (
        <SidebarListContent>
            <Menus menu={menu} skipPermission={skipPermission}>
                {({
                    mainMenu,
                    mainMenuFooter,
                    secondMenu,
                    selectedMenus,
                    firstItemSelected
                }) => (
                    <>
                        {mainMenu?.length && (
                            <MainMenu
                                main={mainMenu}
                                footer={mainMenuFooter}
                                openKeys={selectedMenus}
                            />
                        )}

                        {secondMenu?.length ? (
                            <SecondMenu
                                menu={secondMenu}
                                openKeys={selectedMenus}
                                firstItemSelected={firstItemSelected}
                            />
                        ) : null}
                    </>
                )}
            </Menus>
        </SidebarListContent>
    )
}

export default SidebarMenu

