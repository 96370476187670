import React from 'react'

import { GET_TALK } from '@cms/events/graphql/queries/talk'

import { useQuery } from '@apollo/client'

import { useParams } from 'react-router-dom'

import DashboardTab from '@cms/events/components/DashboardTab'

const OnDemandDashboard = () => {
    const { eventId } = useParams()
    const { id: talkId } = useParams()

    const { data, loading, error } = useQuery(GET_TALK, {
        variables: { id: talkId }
    })

    if (loading || error) return null

    return (
        <DashboardTab
            talkId={talkId}
            data={data}
            eventId={eventId}
            components={['rating', 'cards', 'speakers', 'chat']}
        />
    )
}

export default OnDemandDashboard
