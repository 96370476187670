import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { TalksInfos } from '../card'

const TalkViews = ({ event, talk }) => {
    const { data, loading, error } = useQuery(
        gql`
            query ($event: ObjectID!, $talk: ObjectID) {
                uniqueUsers(event: $event, talk: $talk)
            }
        `,
        { variables: { talk, event } }
    )

    if (loading || error) return null

    return (
        <TalksInfos title={data?.uniqueUsers || 0} subtitle="Usuários únicos" />
    )
}

export default TalkViews
