import React, { useEffect, useState } from 'react'
import { Form, Select } from 'antd'

import { getCurrentType } from '../utils'
import { useSegment } from '../context'

export const OPERATORS = {
    select: [
        { value: '$in', label: 'Incluir' },
        { value: '$nin', label: 'Excluir' }
    ],
    selectSimple: [
        { value: '$eq', label: 'Igual' },
        { value: '$ne', label: 'Diferente' },
        { value: '$in', label: 'Incluir' },
        { value: '$nin', label: 'Excluir' }
    ],
    boolean: [
        { value: '$eq', label: 'Verdadeiro' },
        { value: '$ne', label: 'Falso' }
    ]
}

const FieldOperator = ({ field, type, name }) => {
    const { filters } = useSegment()
    const currentName = ['sections', ...name, 'filters', field.name]
    const form = Form.useFormInstance()
    const [current, setCurrent] = useState()
    const [_fieldOperators, setFieldOperators] = useState()

    const getField = (values) => {
        return values?.sections[name[0]]?.config?.condition?.filters[field.name]
            ?.field
    }

    const conditionalField = Form.useWatch([...currentName, 'field'])
    const operator = Form.useWatch([...currentName, 'type'])

    useEffect(() => {
        if (conditionalField) {
            const infoSection = filters[type]
            const fieldType = conditionalField
            const typeField = infoSection.fields[fieldType]
            const fieldOperators = OPERATORS[typeField.type]

            if (_fieldOperators && fieldOperators !== _fieldOperators) {
                form.setFieldValue([...currentName, 'type'], fieldOperators[0])
                setCurrent(fieldOperators[0])
            }

            setFieldOperators(fieldOperators)
        }

        if (conditionalField !== current) {
            setCurrent(conditionalField)
        }
    }, [conditionalField])

    useEffect(() => {
        if (conditionalField && operator) {
            const _type = getCurrentType(conditionalField, type, filters)

            if (_type === 'boolean') {
                form.setFieldValue(
                    [...currentName, 'value'],
                    operator === '$eq' ? true : false
                )
            }
        }
    }, [operator])

    return (
        <Form.Item
            noStyle
            shouldUpdate={(prev, current) => {
                if (getField(prev) !== getField(current) && getField(current)) {
                    return true
                }
            }}
        >
            {({ getFieldValue }) => {
                if (!current) {
                    return <span>Selecione o campo</span>
                }

                return (
                    <Form.Item
                        name={[field.name, 'type']}
                        rules={[
                            {
                                required: true,
                                message: 'Campo obrigatório'
                            }
                        ]}
                    >
                        <Select options={_fieldOperators} />
                    </Form.Item>
                )
            }}
        </Form.Item>
    )
}

export default FieldOperator
