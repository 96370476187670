import { GET_COLLECTION_CATEGORIES_SELECT_LIST } from '@cms/events/graphql/queries/collectionCategories'
import { GET_COLLECTION_ITEMS_SELECT_LIST } from '@cms/events/graphql/queries/collectionItems'

export const OPTIONS_VALUE = {
    items: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_COLLECTION_ITEMS_SELECT_LIST,
            variables: {
                eventId
            }
        })

        const parsedData =
            (data &&
                data.collectionItems.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    categories: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_COLLECTION_CATEGORIES_SELECT_LIST,
            variables: {
                eventId
            }
        })

        const parsedData =
            (data &&
                data.collectionCategories.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    }
}
