import { COLORS, SIZES } from '@cms/core/themes'
import styled from 'styled-components'

export const Wrapper = styled.div`
    background: ${COLORS.bgLight};
    border-radius: ${SIZES.borderRadiusMD};
    width: 100%;
    padding: 16px 25px;
    height: 100%;

    .RowTitle {
    }
`

