import { gql } from '@apollo/client'

export const GET_TALK_TOTAL_COUNT = gql`
    query GetTalkTotalCount($eventId: ObjectID!) {
        dashboardTalksCount(filter: { event: $eventId })
    }
`
export const GET_BRAND_TOTALS = gql`
    query GetBrandTotals($eventId: ObjectID!) {
        brands(filter: { event: $eventId }) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                }
            }
        }
    }
`
// query Teste GET_EVENT_TOTALS
export const GET_EVENT_TOTALS = gql`
    query GetEventTotals($eventId: ObjectID!) {
        event(id: $eventId) {
            brands {
                totalCount
            }
            speakers {
                totalCount
            }
            talks {
                totalCount
            }
        }
    }
`

export const GET_SPEAKERS_TOTALS = gql`
    query GetSpeakersTotals($eventId: ObjectID!) {
        dashboardSpeakersCount(filter: { event: $eventId })
    }
`

export const GET_CATEGORIES_TOTALS = gql`
    query GetCategoriesTotals($eventId: ObjectID!) {
        dashboardCategoryCount(filter: { event: $eventId })
    }
`

export const GET_COUNT_OF_TALK_VIEWS = gql`
    query GetCountOfTalksViews(
        $eventId: ObjectID!
        $category: ObjectID
        $talk: ObjectID
    ) {
        countOfTalkViews(event: $eventId, category: $category, talk: $talk)
    }
`
export const GET_COUNT_AVERAGE_TALKS = gql`
    query audienceAverage($eventId: ObjectID!, $category: ObjectID) {
        audienceAverage(event: $eventId, category: $category)
    }
`

export const GET_COUNT_PEAK_AUDIENCE = gql`
    query CountPeakAudience(
        $eventId: ObjectID!
        $category: ObjectID
        $talk: ObjectID
    ) {
        countPeakAudience(event: $eventId, category: $category, talk: $talk)
    }
`
export const GET_AVERAGE_DISPLAY_TIME = gql`
    query GetAverageDisplayTime(
        $event: ObjectID!
        $category: ObjectID
        $talk: ObjectID
    ) {
        talksViewTimeAverage(event: $event, category: $category, talk: $talk) {
            humanizedAverage
        }
    }
`

export const GET_COUNT_TOTAL_MESSAGE = gql`
    query CountMessage(
        $eventId: ObjectID!
        $category: ObjectID
        $talk: ObjectID
    ) {
        countMessages(event: $eventId, category: $category, talk: $talk)
    }
`

export const GET_COUNT_TOTAL_TALKS = gql`
    query GetTotalsTalks(
        $event: ObjectID!
        $category: ObjectID
        $talk: ObjectID
    ) {
        talksViewTimeAverage(event: $event, category: $category, talk: $talk) {
            total
            humanizedAverage
        }
    }
`
