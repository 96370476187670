import React, { useCallback } from 'react'

import { Collapse } from 'antd'

import { TSection } from './sections/types'

import * as SECTIONS from './sections'

const { Panel } = Collapse

interface Props {
    sections: TSection[]
    active: string | string[]
    setActive: (e: string | string[]) => void
}

export const SectionsAccordion = ({ sections, active, setActive }: Props) => {
    const mountSection = useCallback((section: TSection) => {
        const { fields, type, ...rest } = section

        if (fields?.length > 0) {
            return fields.map((field) => {
                const { type, ...rest } = field

                const SectionComponent = SECTIONS[type]

                return <SectionComponent {...rest} />
            })
        }

        const SectionComponent = SECTIONS[type]

        return <SectionComponent {...rest} />
    }, [])

    return (
        <>
            {sections.map((section) => {
                return (
                    <Collapse
                        onChange={(e) => setActive(e)}
                        activeKey={active}
                        accordion
                    >
                        <Panel header={section.title} key={section.name}>
                            {mountSection(section)}
                        </Panel>
                    </Collapse>
                )
            })}
        </>
    )
}
