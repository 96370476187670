import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Layout, Row, Col } from 'antd'

import {
    RankingParticipants,
    DailyRate,
    Channels,
    ReferalChart,
    UTMDashboard
} from '@cms/events/components/Dashboard'

import EventCardReferral from '@cms/events/components/Event/EventReferralCard'

import { GET_EVENT } from '@cms/events/graphql/queries/event'

import Loader from '@cms/core/components/Loader'
import { TDDashboardCard } from '@cms/core/components/TDCards'

export default function Event({ history }) {
    const { eventId } = useParams()

    const { error, data, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    if (loading || error) return null

    const { event } = data.event ? data : { event: null }
    return (
        <>
            {event && (
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Row gutter={[24, 24]}>
                            <Col span={24}>
                                <TDDashboardCard>
                                    <ReferalChart
                                        event={event}
                                        viralCampaign={event.viralCampaign}
                                    />
                                </TDDashboardCard>
                            </Col>

                            <Col span={15}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <TDDashboardCard>
                                            <UTMDashboard hideAction={'all'} />
                                        </TDDashboardCard>
                                    </Col>

                                    <Col span={24}>
                                        <TDDashboardCard>
                                            <RankingParticipants
                                                hideAction={'all'}
                                            />
                                        </TDDashboardCard>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={9}>
                                <Row gutter={[24, 24]}>
                                    <Col span={24}>
                                        <TDDashboardCard padding={[0, 0, 0, 0]}>
                                            <Row>
                                                <Col span={8}>
                                                    <EventCardReferral
                                                        style={{
                                                            height: '100%'
                                                        }}
                                                        eventId={eventId}
                                                    />
                                                </Col>

                                                <Col span={16}>
                                                    <Channels
                                                        eventId={eventId}
                                                    />
                                                </Col>
                                            </Row>
                                        </TDDashboardCard>
                                    </Col>

                                    <Col span={24}>
                                        <TDDashboardCard>
                                            <DailyRate hideAction={'all'} />
                                        </TDDashboardCard>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    )
}
