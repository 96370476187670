import React from 'react'

import Integration from '@cms/core/components/Integrations/components/Integration'
import { PageHeader } from '@ant-design/pro-layout';

const config = {
    category: 'others',
    unique: true,
    appType: 'EVENTS'
}

const OthersIntegrations = () => {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Outras Integrações"
                subTitle="Explore outras possibilidades para o seu evento"
            />
            <Integration config={config} />
        </>
    )
}

export default OthersIntegrations
