import styled from 'styled-components'

export const ActiveBtnWrapper = styled.div`
    display: flex;
    padding: 4px;
    margin-right: 8px;
    justify-content: center;
    align-items: center;
`

export const StyledCol = styled.div`
    display: block;
`



