import React from 'react'
import Icon from '@ant-design/icons'

const CodeSvg = () => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width="1rem"
        height="1rem"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
    >
        <g
            transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill="#fff"
            stroke="none"
        >
            <path
                d="M2932 4493 c-19 -9 -40 -30 -48 -47 -32 -69 -1074 -3622 -1074 -3661
1 -41 20 -74 55 -92 11 -6 79 -27 150 -48 126 -36 131 -37 167 -22 25 10 43
27 53 49 32 68 1075 3623 1075 3663 0 58 -33 89 -123 115 -39 12 -92 27 -117
35 -80 24 -103 26 -138 8z"
            />
            <path
                d="M688 3293 c-459 -460 -679 -687 -684 -705 -3 -16 -3 -40 0 -55 5 -19
225 -246 684 -705 l677 -678 50 0 49 0 167 167 c172 173 183 189 167 248 -6
21 -150 172 -490 512 l-483 483 483 483 c333 333 485 491 490 512 17 58 -1 84
-171 253 l-164 162 -49 0 -49 0 -677 -677z"
            />
            <path
                d="M3493 3808 c-170 -169 -188 -195 -171 -253 5 -21 157 -179 490 -512
l483 -483 -482 -482 c-265 -266 -486 -494 -492 -509 -20 -55 -5 -78 168 -252
l167 -167 49 0 50 0 677 677 c459 460 679 687 684 706 3 15 3 39 0 55 -5 18
-225 245 -684 705 l-677 677 -49 0 -49 0 -164 -162z"
            />
        </g>
    </svg>
)

export const CodeIcon = (props) => <Icon component={CodeSvg} {...props} />
