import React from 'react'
import { useParams } from 'react-router-dom'

import { PageHeader } from '@ant-design/pro-layout'

import { Button, Col, Row, message, Input } from 'antd'

import Editor from '@cms/events/components/EditorHTML'

import { Form } from '../styles'
import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { useApolloClient } from '@apollo/client'

const ConfigTerms = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [{ form, formSave, saveLoading }] = useCrudForm(
        {
            id: eventId,
            getData: async (id) => {
                if (!id) return
                const response = await client.query({
                    query: GET_EVENT,
                    variables: { id }
                })

                const data = { ...response.data.event }

                data.terms = { ...data.terms }

                data.terms.text =
                    data.terms.text ||
                    `<h1 style="text-align: center;">Termos de uso</h1>
                    <p>Exemplo de <strong>termos de uso do evento.</strong></p>`

                return data
            },
            updateData: async (id, formData) => {
                const variables = { id, ...formData }

                await client.mutate({
                    mutation: UPDATE_EVENT,
                    variables
                })

                message.success('Termos de uso atualizados com sucesso!')
            }
        },
        [eventId]
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Termos de uso"
                subTitle="Adicione uma url externa ou texto para os termos de uso do evento"
            />
            <Content className="container-light">
                <Form layout="vertical" form={form} onFinish={formSave}>
                    <Form.Item name={['terms', 'url']} label="Url Externa">
                        <Input placeholder="Ex: https://example.com/termos-de-uso" />
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const url = getFieldValue(['terms', 'url'])

                            if (url) return null

                            return (
                                <Form.Item
                                    label="Texto"
                                    name={['terms', 'text']}
                                >
                                    <Editor />
                                </Form.Item>
                            )
                        }}
                    </Form.Item>

                    <Row className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default ConfigTerms
