import React from 'react'
import { EventRouteMain } from './routes'

const EventMain = () => {
    return (
        <>
            <EventRouteMain />
        </>
    )
}

export default EventMain
