import { Button, Dropdown, Input, Menu, Tooltip } from 'antd'
import React, { useMemo } from 'react'
import { PlusSquareOutlined } from '@ant-design/icons'
import { usePageBuilderContext } from '../../context/context'
import { nanoid } from 'nanoid'
import { CustomDropdown, CustomTooltip } from './styles'

const CustomInput = ({
    type = 'input',
    addVariables = false,
    onChange,
    value
}) => {
    const { model } = usePageBuilderContext()
    const inputId = nanoid()

    const modelFields = useMemo(() => {
        return model?.fields.filter((field) => {
            return field.type !== 'image' && field.type !== 'array'
        })
    }, [model])

    const handleAddVariable = (e, variable) => {
        e.preventDefault()
        onChange(value + '{{ref.' + variable.name + '}}')
    }

    const items = (
        <Menu>
            <Menu.ItemGroup title="Variáveis disponíveis">
                {modelFields?.map((variable) => (
                    <Menu.Item key={variable.label}>
                        <a onClick={(e) => handleAddVariable(e, variable)}>
                            {variable.label}
                        </a>
                    </Menu.Item>
                ))}
            </Menu.ItemGroup>
        </Menu>
    )

    if (type === 'input') {
        return (
            <Input.Group compact="true">
                <Input
                    id={inputId}
                    value={value}
                    onChange={onChange}
                    style={{ width: addVariables ? '90%' : '100%' }}
                />
                {addVariables && modelFields?.length !== 0 && (
                    <Tooltip title="Adicionar variável">
                        <CustomDropdown overlay={items} trigger={['click']}>
                            <Button
                                icon={<PlusSquareOutlined />}
                                onClick={(e) => e.preventDefault()}
                            />
                        </CustomDropdown>
                    </Tooltip>
                )}
            </Input.Group>
        )
    }

    if (type === 'textarea') {
        return (
            <CustomTooltip>
                <Input.TextArea
                    compact="true"
                    id={inputId}
                    value={value}
                    onChange={onChange}
                    style={{ width: addVariables ? '90%' : '100%' }}
                ></Input.TextArea>
                {addVariables && modelFields?.length !== 0 && (
                    <Tooltip title="Adicionar variável">
                        <CustomDropdown overlay={items} trigger={['click']}>
                            <Button
                                icon={<PlusSquareOutlined />}
                                onClick={(e) => e.preventDefault()}
                            />
                        </CustomDropdown>
                    </Tooltip>
                )}
            </CustomTooltip>
        )
    }

    return <Input />
}

export default CustomInput
