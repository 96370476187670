import { useQuery } from '@apollo/client'
import { Form, Select } from 'antd'

import React, { useMemo } from 'react'

import { GET_EVENTS_OPTIONS } from '@cms/events/graphql/queries/event'

/**
 * Escolher evento para importação do TD Watch
 */
const SelectEvent = () => {
    const { data, loading } = useQuery(GET_EVENTS_OPTIONS, {
        variables: {
            orderBy: {
                createdAt: 'DESC'
            }
        }
    })

    const options = useMemo(() => {
        if (!data || !data.events) return []

        return data.events.edges
            .map((edge) => edge.node)
            .map((event) => ({
                value: event.id,
                label: event.name
            }))
    }, [data])

    return (
        <Form.Item
            name="event"
            rules={[
                {
                    required: true,
                    message: 'Selecione um evento para continuar'
                }
            ]}
        >
            <Select
                loading={loading}
                showSearch
                placeholder={'Selecione um evento para importar conteúdo'}
                options={options}
            />
        </Form.Item>
    )
}

export default SelectEvent
