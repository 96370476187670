import React from 'react'

import { Row, Col, Switch, Typography } from 'antd'

import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

import { CardWrapper, CardLogo } from './styles'

const { Title, Paragraph } = Typography

import * as _connectors from '../connectores/configs'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components'

const CardItem = ({
    integration,
    onChangeActive,
    onEdit,
    hiddenTitle = false,
    hiddenName = false
}) => {
    const { eventId } = useParams<any>()
    const { logs, type, name, active, config } = integration
    const path = `/evento/${eventId}/integracoes/logs`

    const StyledErrorCounter = styled.div`
        background-color: rgb(255, 77, 79);
        color: white;
        padding: 2px 6px;
        font-weight: bold;
        display: inline;
        border-radius: 40px;
    `

    return (
        <CardWrapper>
            <Row align="stretch" style={{ height: '100%' }}>
                <Col span={24}>
                    <Row>
                        <Col span={18}>
                            {_connectors[type]?.logo && (
                                <CardLogo src={_connectors[type].logo} />
                            )}
                            {!hiddenName && <Title level={3}>{name}</Title>}
                            <Paragraph>{_connectors[type].title}</Paragraph>
                            {logs?.totalCount > 0 && (
                                <>
                                    <Paragraph>
                                        Foram encontrados{' '}
                                        <StyledErrorCounter>
                                            {logs.totalCount}
                                        </StyledErrorCounter>{' '}
                                        registros com erro.{' '}
                                        <Link
                                            to={path}
                                            style={{
                                                textDecoration: 'underline'
                                            }}
                                        >
                                            Ver registros
                                        </Link>
                                    </Paragraph>
                                </>
                            )}
                        </Col>
                        <Col span={6}>
                            <Row align="middle" justify="end">
                                <Switch
                                    onChange={onChangeActive}
                                    checked={active === true}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={24} align="middle">
                        <Col span={24}>
                            {_connectors[type].editable ? (
                                <Row align="middle" justify="end">
                                    <EditOutlined
                                        disabled={!_connectors[type].editable}
                                        onClick={onEdit}
                                        style={{
                                            marginRight: '10px'
                                        }}
                                    />
                                </Row>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </CardWrapper>
    )
}

export default CardItem
