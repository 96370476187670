import React from 'react'
import { useLocation, Link, useParams } from 'react-router-dom'
import { Col, Row } from 'antd'

import { Navigation, Wrapper } from './styles'
import { ROUTES } from '../../constants/routes'

const Nav = () => {
    const { eventId } = useParams()
    const { pathname } = useLocation()

    const mountPath = (path) => {
        return path.replace(':eventId', eventId)
    }

    return (
        <Wrapper>
            <Row className="header" justify="space-between">
                <Col>
                    <Navigation>
                        <Col>
                            {ROUTES.map(({ title, path }) => (
                                <Link
                                    key={title}
                                    to={() => mountPath(path)}
                                    className={
                                        pathname === mountPath(path)
                                            ? 'active-nav'
                                            : ''
                                    }
                                >
                                    {title}
                                </Link>
                            ))}
                        </Col>
                    </Navigation>
                </Col>
            </Row>
        </Wrapper>
    )
}

export default Nav
