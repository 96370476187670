import React, { useState, useEffect } from 'react'
import { Slider, Row, Col } from 'antd'
import {
    parseToHsl,
    setHue,
    setSaturation,
    setLightness,
    hslToColorString
} from 'polished'

import { ColorWrapper, ColorBadge } from './styles'

const ColorHls = ({
    onChange,
    value,
    color = '#ff0101',
    lightness,
    hue,
    saturation,
    controls = ['h', 's', 'l']
}) => {
    const [hsl, setHsl] = useState({})

    let _value = value || color

    if (!value) {
        if (lightness !== undefined) {
            _value = setLightness(lightness / 100, _value)
        }

        if (saturation !== undefined) {
            _value = setSaturation(saturation / 100, _value)
        }

        if (hue !== undefined) {
            _value = setHue(hue, _value)
        }
    }

    useEffect(() => {
        if (!value && _value) {
            onChange(_value)
        }
    }, [value, _value, onChange])

    useEffect(() => {
        if (_value && !hsl?.hue) {
            let newHsl
            try {
                const _hsl = parseToHsl(_value)
                newHsl = {
                    hue: hue !== undefined ? hue : _hsl.hue,
                    saturation:
                        saturation !== undefined
                            ? saturation / 100
                            : _hsl.saturation,
                    lightness:
                        lightness !== undefined
                            ? lightness / 100
                            : _hsl.lightness
                }
                setHsl(newHsl)
            } catch (error) {}
        }
    }, [_value])

    const handleHue = (v) => {
        if (controls.includes('h')) {
            try {
                onChange(setHue(v, _value))

                setHsl({ ...hsl, hue: v })
            } catch (error) {}
        }
    }
    const handleSaturation = (v) => {
        if (controls.includes('s')) {
            try {
                onChange(setSaturation(v / 100, _value))

                setHsl({ ...hsl, saturation: v / 100 })
            } catch (error) {}
        }
    }
    const handleLightness = (v) => {
        if (controls.includes('l')) {
            try {
                onChange(setLightness(v / 100, _value))

                setHsl({ ...hsl, lightness: v / 100 })
            } catch (error) {}
        }
    }

    return (
        <ColorWrapper color={hsl}>
            <Row gutter={10} align="middle" style={{ width: '100%' }}>
                <Col flex="50px">
                    <ColorBadge
                        color={
                            hsl.hue !== undefined
                                ? hslToColorString(hsl)
                                : '#000'
                        }
                    />
                </Col>
                <Col flex="auto">
                    {controls.includes('h') && (
                        <Slider
                            className="color-hue"
                            min={1}
                            max={359}
                            value={hsl.hue}
                            onChange={handleHue}
                            tooltip={{
                                open: false
                            }}
                        />
                    )}
                    {controls.includes('s') && (
                        <Slider
                            className="color-saturation"
                            min={0}
                            max={100}
                            value={hsl.saturation * 100}
                            onChange={handleSaturation}
                            tooltip={{
                                open: false
                            }}
                        />
                    )}

                    {controls.includes('l') && (
                        <Slider
                            className="color-lightness"
                            min={0}
                            max={99}
                            value={hsl.lightness * 100}
                            onChange={handleLightness}
                            tooltip={{
                                open: false
                            }}
                        />
                    )}
                </Col>
            </Row>
        </ColorWrapper>
    )
}

export default ColorHls
