import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _set from 'lodash/set'

import EventCard from '../../components/Event/EventCard'
import { Row, Select, Button, Spin, Empty, Card, Col } from 'antd'
import { useQuery } from '@apollo/client'

import { GET_EVENTS_MAIN } from '@cms/events/graphql/queries/event'

import { flatKeys } from '@cms/core/utils'

import { EmptyEventContainer, WrapperSpin } from './styles'
import { CardTitle } from '@cms/core/components/Crud/CrudList/styles'
import CrudListSearch from '@cms/core/components/Crud/CrudList/Search'

const { Option } = Select

const orderByFields = [
    { label: 'Data criação', value: 'createdAt' },
    { label: 'Nome', value: 'name' }
]

export default function Event() {
    const dispatch = useDispatch()
    const isAdmin = useSelector((state) => state.user.isAdmin)

    const { loading, error, data, refetch } = useQuery(GET_EVENTS_MAIN, {
        variables: { first: 999, orderBy: { createdAt: 'DESC' } }
    })

    const OrderNormalize = flatKeys({ createdAt: 'DESC' } || {})
    const defaultOrderField = Object.keys(OrderNormalize)[0] || null
    const defaultSort = defaultOrderField
        ? OrderNormalize[defaultOrderField]
        : null

    const [sort, setSort] = useState(defaultSort)
    const [orderField, setOrderField] = useState(defaultOrderField)

    if (error) return `Error! ${error.message}`

    const handledSort = (sort) => {
        setSort(sort)
        refetch({
            orderBy: _set({}, orderField, sort)
        })
    }

    const handledOrderBy = (field) => {
        setOrderField(field)
        refetch({
            orderBy: _set({}, field, sort || 'ASC')
        })
    }

    const handleNewEventButton = () => {
        return (
            isAdmin && (
                <Button
                    type="primary"
                    onClick={() => {
                        dispatch({ type: 'SHOW_MODAL_EVENT' })
                    }}
                >
                    Criar Evento
                </Button>
            )
        )
    }

    return (
        <Card
            bordered={false}
            headStyle={{
                padding: 0
            }}
            title={
                <CardTitle>
                    <CrudListSearch
                        refetch={refetch}
                        orderByFields={orderByFields}
                        orderField={orderField}
                        handledOrderBy={handledOrderBy}
                        sort={sort}
                        handledSort={handledSort}
                    />
                    {handleNewEventButton()}
                </CardTitle>
            }
        >
            {!loading && (
                <Row gutter={[12, 12]}>
                    {data.events.edges.map(({ node }) => (
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={8}
                            xl={6}
                            key={node.id}
                        >
                            <EventCard event={node} />
                        </Col>
                    ))}
                    {!data.events.edges.length && (
                        <EmptyEventContainer>
                            <Empty description="Nenhum evento cadastrado" />
                        </EmptyEventContainer>
                    )}
                </Row>
            )}
            {loading && (
                <WrapperSpin>
                    <Spin />
                </WrapperSpin>
            )}
        </Card>
    )
}
