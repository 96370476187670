const formatField = (field, value) => {
    if (value === undefined || value === null) return value

    if (field) {
        switch (field ? field.type : 'text') {
            case 'address':
                return `${value.street}, ${value.number} - ${value.neighborhood}, ${value.city} - ${value.state}, ${value.zipCode}`
            case 'cepStateCity':
                return `${value.city} - ${value.state}, ${value.zipCode}`
            case 'stateCity':
                return `${value.city} - ${value.state}`
            case 'date':
                return new Date(value).toLocaleDateString('pt-BR', {
                    timeZone: 'UTC'
                })
            default:
                break
        }
    }

    if (Array.isArray(value)) {
        return value.join(', ')
    }

    if (typeof value === 'object') {
        return Object.keys(value)
            .map((key) => `${key}: ${value[key]}`)
            .join(', ')
    }

    if (typeof value === 'boolean') {
        return value ? 'Sim' : 'Não'
    }

    return value
}

module.exports = { formatField }
