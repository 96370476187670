export function cpfMask(v: string): string {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{3})(\d)/g, '$1.$2')
    v = v.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4')
    return v.substring(0, 14)
}

export function cnpjMask(v: string): string {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/^(\d{2})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3/$4')
    v = v.replace(/^(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})(\d)/, '$1.$2.$3/$4-$5')
    return v.substring(0, 18)
}

export function cnpjOrCpfMask(v: string): string {
    if (v.length <= 14) {
        return cpfMask(v)
    }
    return cnpjMask(v)
}

export function phoneMask(v: string): string {
    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{2})(\d{5})(\d{3})/, '($1) $2-$3')
    return v.substring(0, 15)
}
