import { Button, Result } from 'antd'
import React from 'react'
import { Link, Route, Switch } from 'react-router-dom'

export default function Route404() {
    const result = (
        <Result
            status="404"
            title="404"
            subTitle="
            Desculpe, a página que você visitou não existe"
            extra={
                <Link to="/">
                    <Button type="primary">Voltar para home</Button>
                </Link>
            }
        />
    )
    return (
        <Switch>
            <Route path={'/404'} component={() => result} />
            <Route path={'*'} component={() => result} />
        </Switch>
    )
}
