import React from 'react'
import Text from 'antd/es/typography/Text'
import { Button, Row, Col, Form, Empty, Select } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import Card from '@cms/core/components/Card'
import { LIST_FIELDS_CONFIGS } from '../constants/fieldList'
import { ListFieldHeader } from './ListFieldHeader'
import { orderField } from '../constants/orderFields'

interface IListFields {
    name: string[]
    type: string
    style?: React.CSSProperties
    listName: string
    children?: React.ReactNode
}

const ListFields = ({ name, type, style, listName, children }: IListFields) => {
    const { FieldsComponent, label, emptyMessage, headerLabels } =
        LIST_FIELDS_CONFIGS[listName]
    return (
        <Card
            title={label}
            className={`segment-section-${listName}`}
            style={style}
        >
            {listName === 'filters' ? (
                <Row>
                    <Form.Item
                        name={[...name, 'type']}
                        rules={[
                            {
                                required: true,
                                message: 'Selecione condição'
                            }
                        ]}
                        label="Condição"
                    >
                        <Select
                            style={{ width: '140px' }}
                            placeholder="Condição"
                        >
                            {orderField.map((_) => {
                                return (
                                    <Select.Option
                                        key={_.name}
                                        value={_.name}
                                        title={_.label}
                                    >
                                        {_.label}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                </Row>
            ) : null}
            <Row gutter={24}>
                <Form.List name={[...name, listName]}>
                    {(fields, { add, remove }) => {
                        return (
                            <Col span={24}>
                                <>
                                    <Row gutter={24}>
                                        <ListFieldHeader
                                            headers={headerLabels}
                                        />
                                        {!fields?.length ? (
                                            <Col span={24}>
                                                <Empty
                                                    description={
                                                        <span>
                                                            <a>
                                                                {emptyMessage}
                                                            </a>
                                                        </span>
                                                    }
                                                ></Empty>
                                            </Col>
                                        ) : fields.length > 0 ? (
                                            fields.map((field) => {
                                                return (
                                                    <Col
                                                        span={24}
                                                        key={field.key}
                                                    >
                                                        <Row gutter={24}>
                                                            <FieldsComponent
                                                                field={field}
                                                                type={type}
                                                                name={name}
                                                                remove={remove}
                                                            >
                                                                <Col span={4}>
                                                                    <Button
                                                                        disabled={
                                                                            field.key ===
                                                                            0
                                                                        }
                                                                        title="Remove"
                                                                        onClick={() => {
                                                                            remove(
                                                                                field.name
                                                                            )
                                                                        }}
                                                                        type="primary"
                                                                        icon={
                                                                            <DeleteOutlined />
                                                                        }
                                                                    ></Button>
                                                                </Col>
                                                            </FieldsComponent>
                                                        </Row>
                                                    </Col>
                                                )
                                            })
                                        ) : (
                                            <Row gutter={24}>
                                                <Col span={24}>
                                                    <Empty
                                                        description={
                                                            <Text>
                                                                {emptyMessage}
                                                            </Text>
                                                        }
                                                    ></Empty>
                                                </Col>
                                            </Row>
                                        )}

                                        <Col span={24}>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        add({})
                                                    }}
                                                    style={{ width: '100%' }}
                                                >
                                                    <PlusOutlined /> Adicionar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            </Col>
                        )
                    }}
                </Form.List>
            </Row>
        </Card>
    )
}

export default ListFields
