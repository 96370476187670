import { crudActions } from '@cms/core/components/Sidebar/common'
import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'

export const MainModules: IAppModule[] = [
    {
        name: 'Meus Eventos',
        icon: sidebarIcons.events,
        module: 'events.main.events',
        type: 'main',
        children: [
            {
                name: 'Meus Eventos',
                exact: true,
                path: '/principal',
                module: 'events.main.events.list',
                children: crudActions('events.main.events.list')
            },
            {
                name: 'Edições',
                path: '/principal/edicoes',
                module: 'events.main.editions',
                children: crudActions('events.main.editions')
            }
        ]
    },
    {
        name: 'Chat',
        icon: sidebarIcons.chat,
        module: 'events.main.chat',
        type: 'main',
        children: [
            {
                name: 'Usuários Denunciados',
                path: '/principal/chat/usuarios-denunciados',
                module: 'events.main.chat.users-reporteds'
            },
            {
                name: 'Usuários Banidos',
                path: '/principal/chat/usuarios-banidos',
                module: 'events.main.chat.users-banned'
            },
            {
                name: 'Palavras Bloqueadas',
                path: '/principal/chat/palavras-banidas',
                module: 'events.main.chat.blocked-words'
            }
        ]
    },
    {
        name: 'Patrocinadores',
        icon: sidebarIcons.stars,
        module: 'events.main.sponsors',
        type: 'main',
        children: [
            {
                name: 'Planos',
                path: '/principal/patrocinador/planos',
                module: 'events.main.sponsors.plans',
                children: crudActions('events.main.sponsors.plans')
            },
            {
                name: 'Usuários',
                path: '/principal/patrocinador/usuarios',
                module: 'events.main.sponsors.users',
                children: crudActions('events.main.sponsors.users')
            }
        ]
    },
    {
        name: 'Palestrantes',
        icon: sidebarIcons.speakers,
        module: 'events.main.speakers',
        type: 'main',
        children: [
            {
                name: 'Usuários',
                path: '/principal/palestrante/usuarios',
                module: 'events.main.speakers.users',
                children: crudActions('events.main.speakers.users')
            }
        ]
    },
    {
        name: 'Executivos',
        icon: sidebarIcons.users,
        module: 'events.main.executives',
        children: [
            {
                name: 'Usuários',
                path: '/principal/executivos/usuarios',
                module: 'events.main.executives.users',
                children: crudActions('events.main.executives.users')
            }
        ]
    },
    {
        name: 'Trackers',
        icon: sidebarIcons.trackers,
        module: 'events.main.trackers',
        children: [
            {
                name: 'Trackers customizados',
                path: '/principal/trackers',
                module: 'events.main.trackers.customTrackers'
            }
        ]
    },
    {
        name: 'Configurações',
        icon: sidebarIcons.settings,
        module: 'events.main.configuration',
        type: 'footer',
        children: [
            {
                name: 'Informações',
                path: '/principal/config/info',
                module: 'events.main.configuration.info'
            }
        ]
    }
]
