import styled from 'styled-components'
import { Typography, Row as RowAnt } from 'antd'
const { Text } = Typography

export const TextInformation = styled(Text)`
  font-size: 12px;
  padding: 5px;
  word-break: break-all;
`
export const Table = styled.div`
  border: 1px solid #d9d9d9;
  margin-top: 25px;
`
export const RowTitle = styled(RowAnt)`
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
`
export const Row = styled(RowAnt)`
  margin: 5px 0px; 
`
