import { Modal } from 'antd'
import React, {useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ModalContent from './modalContent'

const SurveyReport = ({ params, path, eventId, ...props }) => {
    const history = useHistory()
    const { surveyid } = useParams()
    useEffect((_)=>{
        document.body.style.overflowY = 'hidden'
        return (_)=>{
            document.body.style.overflowY = ''
        }
    },[])



    const handleCancelModal = () => {
        history.push(path + '/survey')
    }

    return (
        (<Modal
            open={true}
            footer={null}
            onCancel={handleCancelModal}
            width={600}
            title={'Resultado de enquete'}
            children= { <ModalContent params={params} path={path} surveyId={surveyid}/> }
            {...props}
        />)
    );
}

export default SurveyReport