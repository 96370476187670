import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_WATCH_EXPERT = gql`
    mutation CreateWatchExpert(
        $image: MediaInput
        $firstName: String
        $lastName: String
        $email: String
        $bio: String
        $company: CompanyInputs
        $office: String
        $priority: Int
        $links: [LinkInputs]
    ) {
        createWatchExpert(
            input: {
                image: $image
                firstName: $firstName
                lastName: $lastName
                email: $email
                bio: $bio
                company: $company
                office: $office
                links: $links
                priority: $priority
            }
        ) {
            ...WatchExpertData
        }
    }

    ${fragments.WatchExpertData}
`

export const UPDATE_WATCH_EXPERT = gql`
    mutation UpdateWatchExpert(
        $id: ObjectID!
        $image: MediaInput
        $firstName: String
        $lastName: String
        $email: String
        $bio: String
        $company: CompanyInputs
        $office: String
        $priority: Int
        $links: [LinkInputs]
    ) {
        updateWatchExpert(
            id: $id
            input: {
                image: $image
                firstName: $firstName
                lastName: $lastName
                email: $email
                bio: $bio
                company: $company
                office: $office
                links: $links
                priority: $priority
            }
        ) {
            ...WatchExpertData
        }
    }

    ${fragments.WatchExpertData}
`

export const DELETE_WATCH_EXPERT = gql`
    mutation DeleteWatchExpert($id: ObjectID!) {
        deleteWatchExpert(id: $id) {
            id
            success
        }
    }
`
