import { SectionsType } from '../../../../constants/initialData'
import {
    ORDER_BY_TALKS,
    DIRECTIONS,
    ORDER_BY_PRIORITY
} from '../../../OrderBy/constants'
import { OPTIONS_VALUE } from './options'

export const STAGES_CONFIGS = {
    stages: {
        type: SectionsType.stages,
        defaultValues: {},
        fields: {
            _id: {
                label: 'Palcos',
                type: 'select',
                options: OPTIONS_VALUE.stages
            },
            status: {
                label: 'Status',
                type: 'selectSimple',
                options: [
                    { value: 'WAITING', label: 'Aguardando' },
                    { value: 'STARTED', label: 'Iniciada' },
                    { value: 'FINISHED', label: 'Finalizada' }
                ]
            },
            tags: {
                label: 'Tags',
                type: 'select',
                options: OPTIONS_VALUE.talkTags
            }
        },
        orderBy: {
            ...ORDER_BY_PRIORITY,
            ...ORDER_BY_TALKS
        }
    }
}
