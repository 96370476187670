import React, { useEffect } from 'react'
import { Row, Typography } from 'antd'
import { gql, useQuery } from '@apollo/client'
import { CommentOutlined } from '@ant-design/icons'

import { CrudList, CrudColumns } from '@cms/core/components/Crud'
import { IconWrapper, BigCount } from '@cms/events/components/Dashboard/styles'
import { DashboardCard } from '@cms/events/components/Dashboard'

import { Wrapper } from './styles'

const { Text } = Typography

const Chat = (props) => {
    const query = useQuery(
        gql`
            query ChatMessages($talk: ObjectID!) {
                chatMessages(channel: { type: TALK, id: $talk }) {
                    totalCount
                    edges {
                        node {
                            id
                            message
                            user {
                                id
                                name
                                avatar {
                                    url
                                }
                            }
                        }
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                    }
                }
            }
        `,
        { variables: { talk: props.talk } }
    )

    useEffect(() => {
        query.refetch({ variables: { talk: props.talk } })
    }, [props.talk])

    const columns = [
        {
            title: 'Histórico Chat',
            dataIndex: '',
            render: (value) => {
                return (
                    <CrudColumns
                        image={value.node.user.avatar?.url}
                        title={value.node.user.name}
                        text={[value.node.message]}
                    />
                )
            }
        }
    ]

    if (query.loading || query.error) return null
    return (
        <DashboardCard>
            <Wrapper>
                <Row style={{ marginBottom: '20px' }} align="middle">
                    <IconWrapper>
                        <CommentOutlined style={{ color: '#000' }} />
                    </IconWrapper>
                    <Text style={{ marginLeft: '10px' }}>Chat</Text>
                </Row>
                <Row justify="end">
                    <Text>Interações no Chat</Text>
                </Row>
                <Row justify="end">
                    <BigCount style={{ textAlign: 'left' }}>
                        {query.data.chatMessages.totalCount}
                    </BigCount>
                </Row>
                <Row>
                    <Wrapper>Histórico chat</Wrapper>
                </Row>
                <CrudList
                    header={{}}
                    columns={columns}
                    queryName={'chatMessages'}
                    hideAction={'all'}
                    showHeader={false}
                    search={false}
                    transparent
                    {...query}
                />
            </Wrapper>
        </DashboardCard>
    )
}

export default Chat
