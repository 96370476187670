import React from 'react'

import { Checkbox, Form, Input, message } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { GET_BILLING_PLAN } from '@cms/admin/graphql/queries/billingPlan'
import CustomTree from '@cms/core/components/Tree'

import { getTreeData } from '@cms/core/components/Sidebar/common'
import {
    CREATE_BILLING_PLAN,
    UPDATE_BILLING_PLAN
} from '@cms/admin/graphql/mutations/billingPlan'
import { addToList } from '@cms/core/graphql/utils'

import { AllAppsModules } from '@cms/core/Apps'

const treeData = getTreeData(AllAppsModules)
// const defaultValues = getDefaultModules(AllAppsModules)

const BillingPlansModal = ({ ...props }) => {
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_BILLING_PLAN,
                variables: { id }
            })

            return {
                ...data.billingPlan,
                modules: [...(data.billingPlan?.modules || [])]
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_BILLING_PLAN,
                update: addToList({
                    list: 'billingPlans',
                    Type: 'BillingPlan'
                }),
                variables: {
                    ...formData,
                    modules: formData.modules || []
                }
            })

            message.success('Plano cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_BILLING_PLAN,
                variables: {
                    id,
                    ...formData,
                    modules: formData.modules || []
                }
            })

            message.success('Plano atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Plano' : 'Novo Plano'}
            {...modalProps}
            {...props}
            width={600}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="fullAccess"
                    {...getError('fullAccess')}
                    valuePropName="checked"
                >
                    <Checkbox>Acesso Total</Checkbox>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        if (getFieldValue('fullAccess')) return null

                        return (
                            <Form.Item
                                label="Módulos"
                                name="modules"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Módulos é obrigatório!'
                                    }
                                ]}
                                {...getError('modules')}
                            >
                                <CustomTree
                                    treeData={treeData}
                                    // defaultCheckedKeys={defaultValues}
                                    autoSelectParent
                                    autoExpandParent
                                />
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default BillingPlansModal
