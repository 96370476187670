import React, { useEffect, useMemo } from 'react'

import { Form } from 'antd'

import { useThemeBuilderContext } from '../../context'

import SectionBuilder from '@cms/core/components/SectionBuilder'
import Loader from '@cms/core/components/Loader'

export const ThemeBuilderConfigHome = () => {
    const [form] = Form.useForm()
    const {
        home,
        setHome,
        loading,
        sections: _sections,
        getSectionConfig
    } = useThemeBuilderContext()

    const currentHome = useMemo(() => {
        const section = getSectionConfig('Home')
        return {
            ...section
        }
    }, [_sections])

    useEffect(() => {
        if (home) {
            form.setFieldsValue(home)
        }
    }, [home])

    if (!currentHome.sections) return null

    const { sections } = currentHome

    if (loading || !sections) {
        return <Loader fixed={false} padding="10px" />
    }

    return (
        <>
            <SectionBuilder
                name={'home'}
                data={home}
                setData={setHome}
                sections={sections}
            />
        </>
    )
}

export default ThemeBuilderConfigHome

