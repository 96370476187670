export const Juicer = {
    category: 'others',
    type: 'Juicer',
    color: '#364a65',
    logo: require('./logos/juicer.svg'),
    title: 'Juicer',
    description: 'Use a sua conta juicer para integrar ao evento',
    editable: true
}

export const Mux = {
    category: 'players',
    type: 'Mux',
    color: '#364a65',
    logo: require('./logos/mux.svg'),
    title: 'Mux',
    description: 'Use a sua conta mux para lives e videos',
    editable: true
}

export const Vimeo = {
    category: 'players',
    type: 'Vimeo',
    color: '#364a65',
    logo: 'https://upload.wikimedia.org/wikipedia/commons/9/9c/Vimeo_Logo.svg',
    title: 'Vimeo',
    description: 'Use o player nativo do vimeo para lives e videos',
    editable: false
}

export const Youtube = {
    category: 'players',
    type: 'Youtube',
    color: '#364a65',
    logo: require('./logos/youtube.svg'),
    title: 'Youtube',
    description: 'Use o player nativo do youtube para lives e videos',
    editable: false
}

export const RDStation = {
    editable: true,
    bodyValues: true,
    category: 'conversions',
    type: 'RDStation',
    color: '#364a65',
    logo: require('./logos/rdstation.svg'),
    title: 'RD Station',
    description: 'Capture suas inscrições em forma de leads'
}

export const FacebookConversionApi = {
    editable: true,
    bodyValues: true,
    category: 'conversions',
    type: 'FacebookConversionApi',
    color: '#4267B2',
    logo: require('./logos/facebookConversionApi.svg'),
    title: 'Facebook Conversion API',
    description: 'Integre com a API de conversões do Facebook'
}

export const Webhook = {
    editable: true,
    bodyValues: true,
    category: 'conversions',
    type: 'Webhook',
    color: '#364a65',
    logo: require('./logos/webhook.svg'),
    title: 'Webhooks',
    description:
        'Envie os dados da conversão ​​para uma URL específico na web. Este pode ser seu próprio script privado ou um endpoint de API de terceiros.'
}

export const ZohoCampaigns = {
    editable: true,
    bodyValues: true,
    category: 'conversions',
    type: 'ZohoCampaigns',
    color: '#364a65',
    logo: require('./logos/zoho-campaigns.svg'),
    steps: true,
    title: 'Zoho Campaigns',
    description:
        'Integre com o Zoho Campaigns para gerenciar campanhas de email e capturar leads com eficiência.'
}

export const ZohoCRM = {
    editable: true,
    bodyValues: true,
    category: 'conversions',
    type: 'ZohoCRM',
    color: '#364a65',
    logo: require('./logos/zoho-crm.svg'),
    steps: true,
    title: 'Zoho CRM',
    description:
        'Integre com o Zoho CRM para gerenciar contatos e oportunidades de vendas de forma eficiente.'
}

