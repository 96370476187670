import { useQuery } from '@apollo/client'
import { GET_COUNT_PEAK_AUDIENCE } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'
import React from 'react'
import { TalksInfos } from '../card'

const PeakTime = ({ eventId, talk }) => {
    const { loading, error, data } = useQuery(GET_COUNT_PEAK_AUDIENCE, {
        variables: { eventId, talk }
    })

    if (loading || error) return null

    return (
        <TalksInfos
            title={
                data?.countPeakAudience
                    ? renderBaseK(data.countPeakAudience)
                    : 0
            }
            subtitle="Maior pico"
        />
    )
}

export default PeakTime

