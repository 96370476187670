import styled from 'styled-components'

export const EditorWrapper = styled.div`
    .mce-branding,
    .tox-statusbar__branding {
        display: none;
    }

    .tox .tox-edit-area__iframe {
        background: rgba(254, 252, 252, 0.565);
    }
`
