import React, { useState } from 'react'

import { Col, Form, Popconfirm, Row } from 'antd'
import { Steps, StepsContent, StepsActions, Button } from './styles'
import { DeleteOutlined } from '@ant-design/icons'

const { Step } = Steps

const TDSteps = ({
    steps,
    form,
    onNext,
    onPrev,
    onFinish,
    validate,
    onDelete,
    currentDefault = 1,
    enableDelete = true,
    nextLabel = 'Próximo',
    textAlign = 'center'
}) => {
    const [current, setCurrent] = useState(
        currentDefault ? currentDefault - 1 : currentDefault
    )
    const [loading, setLoading] = useState(false)

    const next = async (shift = 1) => {
        const _current = current + shift
        setLoading(true)
        if (!onNext || (await onNext(_current))) {
            setCurrent(_current)
        }
        setLoading(false)
    }

    const prev = async (shift = 1) => {
        const _current = current - shift
        setLoading(true)
        if (!onPrev || (await onPrev(_current))) {
            setCurrent(_current)
        }
        setLoading(false)
    }

    const finish = async () => {
        setLoading(true)
        if (await onNext()) {
            await onFinish?.()
            setCurrent(0)
        }
        setLoading(false)
    }

    return (
        <>
            {enableDelete && (
                <Row justify={'end'}>
                    <Col>
                        <Popconfirm
                            title="Tem certeza que deseja deletar?"
                            onConfirm={() => onDelete()}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button
                                type="danger"
                                style={{ borderTopRightRadius: '10px' }}
                            >
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </Col>
                </Row>
            )}
            <Steps type="navigation" className="td-steps" current={current}>
                {steps.map((item, index) => (
                    <Step
                        key={item.title}
                        title={item.title}
                        onClick={() => {
                            if (current < index) {
                                next(index - current)
                            } else if (current > index) {
                                prev(current - index)
                            }
                        }}
                    />
                ))}
            </Steps>
            <Form
                layout="vertical"
                form={form}
                style={{
                    width: '100%',
                    flex: 1,
                    overflow: 'auto',
                    paddingBottom: '20px'
                }}
                validateMessages={validate}
            >
                <StepsContent textAlign={textAlign}>
                    {steps[current].component}
                </StepsContent>
            </Form>
            <StepsActions
                style={current === 0 ? { justifyContent: 'flex-end' } : null}
            >
                {current > 0 && (
                    <Button
                        type="default"
                        loading={loading}
                        style={{ margin: '0 8px' }}
                        onClick={() => prev()}
                    >
                        Anterior
                    </Button>
                )}
                {current === steps.length - 1 && (
                    <Button type="primary" loading={loading} onClick={finish}>
                        Finalizar
                    </Button>
                )}
                {current < steps.length - 1 && (
                    <Button
                        type="primary"
                        loading={loading}
                        onClick={() => next()}
                    >
                        {nextLabel}
                    </Button>
                )}
            </StepsActions>
        </>
    )
}

export default TDSteps

