import React, { useCallback } from 'react'
import styled from 'styled-components'
import { BlockPicker } from 'react-color'

import { useControllableValue } from '@umijs/hooks'

export const PopoverStyled = styled.div`
    position: absolute;
    top: -80px;
    left: -1px;
    z-index: 2;
    .cover{
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }
    .block-picker  {
        width: 202px !important;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.20) !important;
    }
`



export const Popover = function({ onClose, colors, ...props }){
    const [state, setState] = useControllableValue(props)

    const checkColor = useCallback((color) => {
        for (let i = 0; i < colors.length; i++) {
            const _color = colors[i];
            if (color.toLowerCase() === _color.toLowerCase()) return true
        }
        return false
    }, [colors])

    if (state && state.length === 7 && !checkColor(state)) {
        colors.push(state)
    }

    return(
        <PopoverStyled>
            <div className="cover" onClick={ onClose }/>
            <BlockPicker
                colors={colors}
                triangle="hide"
                color={ state }
                onChangeComplete={ setState } />
        </PopoverStyled>
    )
}



