import Cta from './Cta'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import Cta3 from './Cta3'

export const Ctas = {
    Cta: {
        Component: Cta,
        name: 'CTA',
        category: 'Cta',
        thumb: require('./cta.png'),
        columns: '1',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: `Seja um participante do\n${event.name}!`,
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Pela primeira vez, um evento digital sem palestrinhas monótonas e com\n muitos dados práticos que podem te ajudar a superar em 100% a expectativa',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    },
    Cta2: {
        Component: Cta,
        name: 'CTA Horizontal',
        category: 'Cta',
        thumb: require('./cta2.png'),
        columns: '2',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: `Seja um participante do\n${event.name}!`,
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Pela primeira vez, um evento digital sem palestrinhas monótonas e com\n muitos dados práticos que podem te ajudar a superar em 100% a expectativa',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    },
    Cta3: {
        Component: Cta3,
        name: 'CTA - data do evento',
        category: 'Cta',
        thumb: require('./cta3.png'),
        columns: '1',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                date: {
                    text: '8 a 12 de Março',
                    textColor: getColorContrast(colors, 'primary')
                },
                headline: {
                    text: `Seja um participante do\n${event.name}!`,
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Pela primeira vez, um evento digital sem palestrinhas monótonas e com\n muitos dados práticos que podem te ajudar a superar em 100% a expectativa',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    }
}
