import React from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import {
    DisplayTime,
    PeakAudience,
    TotalBrands,
    TotalCategories,
    TotalSpeakers,
    TotalTalks,
    TotalViews,
    TotalTalkViewsAverage,
    TalkViews,
    TotalMessages,
    TotalTalksViews
} from '../../Dashboard/BlockItens'

import { SearchOutlined } from '@ant-design/icons'
import {
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'

const { Text } = Typography

const EventDetails = ({ eventId }) => {
    const isSponsor = useSelector((state) => state.user.role === 'sponsor')
    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <SearchOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Detalhes do evento</DashboardTitle>
            </DashboardCardTitle>

            <Row gutter={24} justify="center" style={{ padding: '15px' }}>
                <Col lg={10} md={12}>
                    <TotalCategories eventId={eventId} full={false} />
                    <TotalSpeakers eventId={eventId} full={false} />
                    <TotalViews eventId={eventId} full={false} />
                    <PeakAudience eventId={eventId} full={false} />
                    <TalkViews event={eventId} />
                </Col>
                <Col lg={10} md={12}>
                    <TotalTalks eventId={eventId} full={false} />
                    {!isSponsor && (
                        <TotalBrands eventId={eventId} full={false} />
                    )}
                    <DisplayTime eventId={eventId} full={false} />
                    <TotalTalkViewsAverage eventId={eventId} full={false} />
                    <TotalMessages eventId={eventId} full={false} />
                    <TotalTalksViews eventId={eventId} full={false} />
                </Col>
            </Row>
        </>
    )
}

export default EventDetails
