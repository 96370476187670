import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import TeamModal from './UserModal'

import { GET_TEAM_LIST_ORDERABLE } from '@cms/core/graphql/queries/user'
import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { DELETE_USER } from '@cms/core/graphql/mutations/user'
import { useCheckModuleByPath } from '@cms/core/components/CheckModule'

const path = '/time/usuarios'

const ModalRoutes = CreateModalRoutes(TeamModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.name}
                    text={[obj.node.jobTitle]}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: [''],
        render: (value, obj) => {
            return (
                <CrudColumns onlyText title="E-mail" text={[obj.node.email]} />
            )
        }
    },
    {
        title: 'Departamento',
        dataIndex: [''],
        render: (value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    title="Departamento"
                    text={[obj.node.department || '---']}
                />
            )
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const TeamList = () => {
    const allowedWrite = useCheckModuleByPath(path, 'write')
    const allowedDelete = useCheckModuleByPath(path, 'delete')

    const query = useQuery(GET_TEAM_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [GET_TEAM_LIST_ORDERABLE]
    })

    const {
        data,
        error: errorUser,
        loading: userLoading
    } = useQuery(CURRENT_USER, {})

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes allowed={allowedWrite} />
            <CrudList
                header={{
                    title: 'Produtores',
                    subTitle: 'Adicione e gerencie produtores',
                    buttons: [
                        {
                            children: 'Novo Produtor',
                            type: 'primary',
                            link: path + '/adicionar',
                            actionType: 'write'
                        }
                    ]
                }}
                search={true}
                allowed={{
                    write: allowedWrite,
                    delete: allowedDelete
                }}
                columns={columns}
                queryName={'users'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                disableFor={data.me.id}
                {...query}
            />
        </>
    )
}

export default TeamList
