import React from 'react'
import Icon from '@ant-design/icons'

const TrashSvg = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 4.5H14V14.25C14 14.4489 13.921 14.6397 13.7803 14.7803C13.6397 14.921 13.4489 15 13.25 15H2.75C2.55109 15 2.36032 14.921 2.21967 14.7803C2.07902 14.6397 2 14.4489 2 14.25V4.5ZM4.25 2.25V0.75C4.25 0.551088 4.32902 0.360322 4.46967 0.21967C4.61032 0.0790176 4.80109 0 5 0H11C11.1989 0 11.3897 0.0790176 11.5303 0.21967C11.671 0.360322 11.75 0.551088 11.75 0.75V2.25H15.5V3.75H0.5V2.25H4.25ZM5.75 1.5V2.25H10.25V1.5H5.75ZM5.75 7.5V12H7.25V7.5H5.75ZM8.75 7.5V12H10.25V7.5H8.75Z" fill="#FBFCFF"/>
    </svg>
)

export const IconTrash = (props) => <Icon component={TrashSvg} {...props} />
