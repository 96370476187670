import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import { GET_WATCH_COURSES_ORDENABLE } from '@cms/watch/graphql/queries/watchCourse'
import { DELETE_WATCH_COURSE } from '@cms/watch/graphql/mutations/watchCourse'
import { removeFromList } from '@cms/watch/graphql/utils'

import dayjs from 'dayjs'

const path = `/cursos`

const columns = [
    {
        title: 'Título',
        dataIndex: ['node', 'title'],
        sorter: 'title',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.title} onlyText />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchCourses = () => {
    const query = useQuery(GET_WATCH_COURSES_ORDENABLE, {
        variables: {
            first: 25,
            orderBy: {
                createdAt: 'DESC'
            }
        }
    })

    const [deleteData] = useMutation(DELETE_WATCH_COURSE, {
        update: removeFromList({ list: 'watchCourses', Type: 'WatchCourse' })
    })

    if (query.error) return <div>Error</div>

    const filterFields = [
        { name: 'title', label: 'Título', queryType: 'Filter' }
    ]

    return (
        <>
            <CrudList
                header={{
                    title: 'Cursos',
                    subTitle: 'Gerencie os cursos',
                    buttons: [
                        {
                            children: 'Novo Curso',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'watchCourses'}
                filterFields={filterFields}
                onDelete={deleteData}
                {...query}
            />
        </>
    )
}

export default WatchCourses

