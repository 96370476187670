import React from 'react'
import { CustomIcon, CustomIconBadge } from './styles'

const Icon = props => {
    if(props.badge) {
        return (
            <CustomIconBadge
                className='custom-icon'
                component={props.Component}
                {...props} />
        )
    }

    return (
        <CustomIcon
            className='custom-icon'
            component={props.Component}
            {...props} />
    )
}

export default Icon

