import React from 'react'
import { ReloadOutlined } from '@ant-design/icons'

import { useQuery, gql } from '@apollo/client'
import { Alert, Button, Col, Row } from 'antd'

const CheckDomainStatus = ({ field = 'domain' }) => {
    const { data, loading, error, refetch } = useQuery(
        gql`
            query GetWatchConfig {
                watchConfig {
                    ${field} {
                        name
                        validated
                        error
                    }
                }
            }
        `
    )

    const renderAlert = () => {
        if (loading || !data) return null
        if (error) return <Alert message={error.message} type="error" />
        if (data.watchConfig[field].validated)
            return (
                <Alert message="Domínio validado com sucesso." type="success" />
            )
        if (data.watchConfig[field].error)
            return (
                <Alert message={data.watchConfig[field].error} type="error" />
            )
        return <Alert message="Seu domínio será validado" type="info" />
    }

    return (
        <Row align="stretch" style={{ paddingBottom: '20px' }}>
            <Col span={23}>{renderAlert()}</Col>
            <Col span={1}>
                <Button type="primary" onClick={() => refetch()}>
                    <ReloadOutlined />
                </Button>
            </Col>
        </Row>
    )
}

export default CheckDomainStatus

