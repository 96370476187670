import React from 'react'
import { Button, Form } from 'antd'

import RewardAdd from './Add'
import { ButtonStick } from './styles'

const blankTopReward = {
    name: 'Milestone',
    description: 'Exemplo de descrição',
    media: null,
    points: 1
}
const blankReward = {
    ...blankTopReward,
    useEmail: false,
    emailTemplate: {
        nameFrom: 'Transformação digital',
        emailFrom: 'naoresponda@somostd.com.br',
        subject: 'Recompensa alcançada',
        replyTo: 'naoresponda@somostd.com.br',
        sayHello: 'Ola',
        content:
            '<p>Parabéns por chegar a este passo! Obrigado por convidar seus amigos. Temos um presente para você. Responda para saber mais sobre.<p><p><br></p><p><br></p><p><br></p><p>Agora continue convidando seus amigos para alcançar o próximo asso.</p>',
        thankYouMessage: 'Obrigado',
        button: {
            text: 'Convide seus amigos',
            link: 'https://tdplataform.com/campaign/minha-campanha',
            backgroundColor: '#e91e63',
            textColor: '#fff'
        }
    }
}

const Reward = ({
    title,
    reference,
    disableCounter = false,
    disableEmail = false,
    topReward = false,
    pointLabel = 'Indicações'
}) => {
    return (
        <>
            <Form.List name={reference}>
                {(fields, instance) => (
                    <>
                        {fields.map((field, index) => (
                            <div key={index} style={{ marginBottom: '20px' }}>
                                <RewardAdd
                                    index={index}
                                    title={title}
                                    disableCounter={disableCounter}
                                    disableEmail={disableEmail}
                                    topReward={topReward}
                                    field={field}
                                    fields={fields}
                                    instance={instance}
                                    reference={reference}
                                    size={fields.length}
                                    pointLabel={pointLabel}
                                />
                            </div>
                        ))}
                        <ButtonStick>
                            <Button
                                block
                                className="btn-action-add"
                                onClick={() =>
                                    instance.add(
                                        topReward ? blankTopReward : blankReward
                                    )
                                }
                            >
                                Adicionar mais recompensas
                            </Button>
                        </ButtonStick>
                    </>
                )}
            </Form.List>
        </>
    )
}

export default Reward

