import React from 'react'
import { Form, Input } from 'antd'

import { ColorsBadges, ContainerConfig, ButtonConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const Cta3 = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Data do evento</Title>
                <Form.Item name={[props.name, 'configs', 'date', 'text']}>
                    <RichTextEditor toolbarId="date" />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item name={[props.name, 'configs', 'headline', 'text']}>
                    <RichTextEditor toolbarId="title" />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item name={[props.name, 'configs', 'subtitle', 'text']}>
                    <RichTextEditor toolbarId="subtitle" />
                </Form.Item>
            </Wrapper>
            <ButtonConfig enableActions allowVisibilityControl {...props} />
        </>
    )
}

export default Cta3
