import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_TALK_LIST = gql`
    query GetTalks(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $type: TalkType = LIVE
        $orderBy: TalkOrderBy
        $search: String
        $title: Filter
        $status: TalkStatus
    ) {
        talks(
            first: $first
            after: $afterCursor
            filter: {
                event: $eventId
                type: $type
                status: $status
                title: $title
            }
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TalkData
                }
            }
        }
    }

    ${fragments.TalkData}
`

export const GET_TALK_LIST_ORDERABLE = gql`
    query GetTalks(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $category: ObjectID
        $type: TalkType = LIVE
        $orderBy: TalkOrderBy
        $status: TalkStatus
        $search: String
        $title: Filter
        $stage: Filter
    ) {
        talks(
            first: $first
            after: $afterCursor
            filter: {
                event: $eventId
                category: $category
                type: $type
                stage: $stage
                status: $status
                title: $title
            }
            orderBy: $orderBy
            search: $search
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...TalkData
                }
            }
        }
    }

    ${fragments.TalkData}
`

export const GET_TALK = gql`
    query GetTalk($id: ObjectID!) {
        talk(id: $id) {
            ...TalkData
        }
    }

    ${fragments.TalkData}
`

export const GET_TALK_PANEL = gql`
    query GetTalkPanel($id: ObjectID!) {
        talk(id: $id) {
            title
            video {
                source
            }
            videoWeb {
                source
            }
            currentLiveTime
            status
            viewers
            reports {
                views
            }
        }
    }
`

export const GET_TALK_LIST_TOP_TEN = gql`
    query GetTalksTopTen($eventId: ObjectID!, $type: TalkType = LIVE) {
        talks(
            first: 10
            filter: { event: $eventId, type: $type }
            orderBy: { reports: { views: DESC } }
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    title
                    categories(first: 1) {
                        edges {
                            node {
                                id
                                name
                            }
                        }
                    }
                    description
                    reports {
                        views
                    }
                }
            }
        }
    }
`

export const GET_TALK_TAGS = gql`
    query GetTalkTags($eventId: ObjectID!) {
        talkTags(event: $eventId)
    }
`

export const GET_TALK_OPTIONS = gql`
    query GetTalks(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
        $type: TalkType
        $orderBy: TalkOrderBy
        $search: String
        $status: TalkStatus
    ) {
        talks(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, type: $type, status: $status }
            orderBy: $orderBy
            search: $search
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    type
                    title
                    id
                }
            }
        }
    }
`

export const GET_STAGES = gql`
    query GetStages($eventId: ObjectID!, $orderBy: TalkOrderBy) {
        talks(orderBy: $orderBy, filter: { event: $eventId, type: STAGE }) {
            totalCount
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
`
export const GET_STAGES_SELECT_LIST = gql`
    query GetStagesSElectList($eventId: ObjectID!, $orderBy: TalkOrderBy) {
        talks(orderBy: $orderBy, filter: { event: $eventId, type: STAGE }) {
            edges {
                node {
                    value: id
                    label: title
                }
            }
        }
    }
`

export const GET_TALKS_SELECT_LIST = gql`
    query GetTalksSelectList($eventId: ObjectID!, $orderBy: TalkOrderBy) {
        talks(orderBy: $orderBy, filter: { event: $eventId }) {
            edges {
                node {
                    value: id
                    label: title
                }
            }
        }
    }
`

