import React from 'react'

import { Divider, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { HiddenArea } from './styles'
import { usePageBuilderContext } from '../../context/context'

const AddSection = (props) => {
    const { setSectionsModalParams } = usePageBuilderContext()

    const showModal = () => {
        setSectionsModalParams(props)
    }

    if (props.emptySections) {
        return (
            <>
                <Button type="primary" onClick={showModal}>
                    Adicionar
                </Button>
            </>
        )
    }

    return (
        <>
            <HiddenArea>
                <Divider plain dashed className="divider" onClick={showModal}>
                    {<PlusOutlined />}
                </Divider>
            </HiddenArea>
        </>
    )
}

export default AddSection

