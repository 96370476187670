import styled from 'styled-components'

export const Wrapper = styled.div`
    display: contents;

    .ant-btn-round {
        background-color: ${({ theme }) => theme.color.backgroundDarkLight};
        color: ${({ theme }) => theme.color.primaryColor};
        border-color: ${({ theme }) => theme.color.backgroundDarkLight};
    }
`
