import styled from 'styled-components'
import { Wrapper as DefaultWrapper } from '../../styles'

export const Wrapper = styled.div`
    .colors-group{
        margin-bottom: 20px;
    }
    .ant-divider{
        border-color: ${({ theme }) => theme.color.backgroundDarkLight};
    }
`