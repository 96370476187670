import React from 'react'

import { useQuery } from '@apollo/client'

import { GET_BRAND_DOWNLOADS } from '@cms/events/graphql/queries/brand'

import { StarOutlined } from '@ant-design/icons'

import Card, { CompareLines } from '@cms/events/pages/Events/Card'
import { Empty } from 'antd'

const DownloadsPerCard = ({ eventId, brandId, metric, titleSmall }) => {
    const { loading, error, data } = useQuery(GET_BRAND_DOWNLOADS, {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    const values =
        data.brandDownloads.length > 0
            ? data.brandDownloads.map((material) => {
                  return {
                      name: material.name,
                      percent: material.percentage || '0%',
                      value: material.total
                  }
              })
            : []

    return (
        <>
            <Card
                icon={StarOutlined}
                title={'Downloads dos materiais'}
                titleSmall={titleSmall}
                style={{
                    marginRight: '5px'
                }}
            >
                {values.length > 0 ? (
                    <CompareLines title={metric.label} values={values} />
                ) : (
                    <Empty description="Não há dados" />
                )}
            </Card>
        </>
    )
}

export default DownloadsPerCard

