import { gql } from '@apollo/client'

export * from '@cms/core/graphql/fragments'

export const BillingPlanData = gql`
    fragment BillingPlanData on BillingPlan {
        id
        name
        modules
        fullAccess
        createdAt
        updatedAt
    }
`
