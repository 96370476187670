import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, Typography } from 'antd'

import { NavDashboard } from '../../components'

const { Title } = Typography

const Dashboard = () => {
    return (
        <>
            <NavDashboard />
            <Row>
                <Title>Dashboard</Title>
            </Row>
        </>
    )
}

export default Dashboard