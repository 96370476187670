import styled from 'styled-components'
import { Row } from 'antd'
import { COLORS } from '@cms/core/themes'

export const Navigation = styled(Row)`
    background-color: ${COLORS.bgLight};
    width: fit-content;
    border-radius: 84px;
    height: 38px;
    align-items: center;

    a {
        padding: 12px 20px;
        font-weight: 600;
        border-radius: 84px;
        &.active-nav {
            background-color: ${COLORS.active};
            color: ${COLORS.text};
        }
    }
`

export const Wrapper = styled.div`
    display: contents;
    margin-bottom: 20px;

    .header {
        .actions {
            column-gap: 8px;
        }
    }

    .ant-affix {
        > .header {
            margin-left: -40px;
            margin-right: -40px;
            padding: 10px 40px;
            min-height: 58px;
            background: ${({ theme }) => theme.color.backgroundDarkHeaders};
            backdrop-filter: blur(10px);
            /* border-bottom: 1px solid ${({ theme }) =>
                theme.color.textColor}; */
            box-shadow: 1px 0px 3px #ffffff57;
        }

        ${Navigation} {
        }
    }
`

export const ModalContent = styled.div`
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    height: 60vh;
    overflow-y: auto;
`

export const EventtItem = styled.div`
    position: relative;
    height: 100px;
    border-radius: 20px;
    cursor: pointer;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-basis: 48%;
    max-width: 48%;
    text-align: center;
    padding: 10px;
    border: 1px solid #86888d;
    img {
        padding-top: 10px;
        max-width: 150px;
        height: 50px;
    }
`

export const Footer = styled(Row)`
    margin-top: 24px;
    column-gap: 16px;
`

export const ModalConfirmationContent = styled(Row)`
    text-align: center;
`
