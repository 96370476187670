import React, { useRef } from 'react'
import { message, Form, Input, Checkbox } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_USER_PROFILE } from '@cms/core/graphql/queries/userProfile'
import {
    CREATE_USER_PROFILE,
    UPDATE_USER_PROFILE
} from '@cms/core/graphql/mutations/userProfile'

import CustomTree from '@cms/core/components/Tree'

import {
    getTreeData,
    getDefaultModules
} from '@cms/core/components/Sidebar/common'
import { AllAppsModules } from '@cms/core/Apps'

const treeData = getTreeData(AllAppsModules)
const defaultValues = getDefaultModules(AllAppsModules)

const UserProfileModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_USER_PROFILE,
                variables: { id }
            })

            return {
                ...data.userProfile,
                modules: [
                    ...defaultValues,
                    ...(data.userProfile?.modules || [])
                ]
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_USER_PROFILE,
                update: addToList({
                    list: 'userProfiles',
                    Type: 'UserProfile'
                }),
                variables: {
                    ...formData,
                    modules: formData.modules || [],
                    event: eventId
                }
            })

            message.success('Perfil cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER_PROFILE,
                variables: {
                    id,
                    ...formData,
                    modules: formData.modules || [],
                    event: eventId
                }
            })

            message.success('Perfil atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Perfil' : 'Novo Perfil'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="fullAccess"
                    {...getError('fullAccess')}
                    valuePropName="checked"
                >
                    <Checkbox>Acesso Total</Checkbox>
                </Form.Item>

                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        if (getFieldValue('fullAccess')) return null

                        return (
                            <Form.Item
                                label="Módulos"
                                name="modules"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Módulos é obrigatório!'
                                    }
                                ]}
                                {...getError('modules')}
                            >
                                <CustomTree
                                    treeData={treeData}
                                    defaultCheckedKeys={defaultValues}
                                />
                            </Form.Item>
                        )
                    }}
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default UserProfileModal
