import React from 'react'
import { ChatBuilderRoutes } from '../chatBuilder'
import { Container } from './styles'

const ChatNav = () =>{
    return (
        <>
            <Container>
                <ChatBuilderRoutes />
            </Container>
        </>
    )
}

export default ChatNav
