import React from 'react'
import Icon from '@ant-design/icons'

const SadSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7985 20.6497C16.3206 20.6497 20.7971 16.1731 20.7971 10.651C20.7971 5.12889 16.3206 0.652344 10.7985 0.652344C5.27636 0.652344 0.799805 5.12889 0.799805 10.651C0.799805 16.1731 5.27636 20.6497 10.7985 20.6497Z" fill="url(#paint0_radial_5621_22860)"/>
    <path opacity="0.2" d="M10.7985 20.6497C16.3206 20.6497 20.7971 16.1731 20.7971 10.651C20.7971 5.12889 16.3206 0.652344 10.7985 0.652344C5.27636 0.652344 0.799805 5.12889 0.799805 10.651C0.799805 16.1731 5.27636 20.6497 10.7985 20.6497Z" fill="url(#paint1_radial_5621_22860)"/>
    <path fillRule="evenodd"  d="M18.1434 14.2012C18.0279 14.0267 17.7728 14.0267 17.6573 14.2012C17.0718 15.0902 15.627 17.3784 15.627 18.3774C15.627 19.6316 16.6448 20.6495 17.899 20.6495C19.1532 20.6495 20.1711 19.6316 20.1711 18.3774C20.1738 17.3784 18.7316 15.0902 18.1434 14.2012Z" fill="url(#paint2_radial_5621_22860)"/>
    <path opacity="0.1" fillRule="evenodd"  d="M17.9974 15.2887C17.9517 15.2189 17.8523 15.2189 17.8067 15.2887C17.5757 15.6378 17.0117 16.5348 17.0117 16.9269C17.0117 17.4184 17.4119 17.8186 17.9034 17.8186C18.3948 17.8186 18.795 17.4184 18.795 16.9269C18.795 16.5348 18.2283 15.6378 17.9974 15.2887Z" fill="white"/>
    <path fillRule="evenodd"  d="M10.7992 17.1204C11.8815 17.1204 12.9048 17.2896 13.7991 17.5904C14.2664 17.7462 14.688 17.2467 14.441 16.8197C13.7212 15.5762 12.3596 14.7383 10.7965 14.7383C9.23349 14.7383 7.87187 15.5762 7.15211 16.8197C6.90504 17.2467 7.32668 17.7462 7.79398 17.5904C8.69636 17.2896 9.71691 17.1204 10.7992 17.1204Z" fill="url(#paint3_linear_5621_22860)"/>
    <path d="M3.53331 9.90628C3.44469 9.90628 3.35606 9.87942 3.28086 9.82302C3.09018 9.68337 3.0499 9.4148 3.18955 9.22412C3.25401 9.1355 4.82242 7.03532 7.33619 6.63247C7.56984 6.59487 7.79006 6.75333 7.82766 6.98698C7.86526 7.22063 7.70681 7.44085 7.47316 7.47845C5.3139 7.8249 3.89588 9.71291 3.87976 9.73171C3.79382 9.84719 3.66491 9.90628 3.53331 9.90628Z" fill="url(#paint4_linear_5621_22860)"/>
    <path fillRule="evenodd"  d="M6.87775 9.47852C5.90823 9.47852 5.12402 10.4158 5.12402 11.5733C5.12402 12.7308 5.90823 13.1041 6.87775 13.1041C7.84727 13.1041 8.63148 12.7308 8.63148 11.5733C8.63148 10.4158 7.84727 9.47852 6.87775 9.47852Z" fill="url(#paint5_radial_5621_22860)"/>
    <path opacity="0.1" d="M6.43728 11.8497C6.77101 11.8497 7.04155 11.5274 7.04155 11.1299C7.04155 10.7324 6.77101 10.4102 6.43728 10.4102C6.10355 10.4102 5.83301 10.7324 5.83301 11.1299C5.83301 11.5274 6.10355 11.8497 6.43728 11.8497Z" fill="white"/>
    <path d="M18.0655 9.90628C18.1541 9.90628 18.2427 9.87942 18.3179 9.82302C18.5086 9.68337 18.5489 9.4148 18.4093 9.22412C18.3448 9.1355 16.7764 7.03532 14.2626 6.63247C14.029 6.59487 13.8087 6.75333 13.7711 6.98698C13.7335 7.22063 13.892 7.44085 14.1257 7.47845C16.2849 7.8249 17.7029 9.71291 17.719 9.73171C17.805 9.84719 17.9339 9.90628 18.0655 9.90628Z" fill="url(#paint6_linear_5621_22860)"/>
    <path fillRule="evenodd"  d="M14.7195 9.47852C15.6891 9.47852 16.4733 10.4158 16.4733 11.5733C16.4733 12.7308 15.6891 13.1041 14.7195 13.1041C13.75 13.1041 12.9658 12.7308 12.9658 11.5733C12.9685 10.4158 13.7527 9.47852 14.7195 9.47852Z" fill="url(#paint7_radial_5621_22860)"/>
    <path opacity="0.1" d="M14.2781 11.8516C14.6118 11.8516 14.8824 11.5294 14.8824 11.1319C14.8824 10.7344 14.6118 10.4121 14.2781 10.4121C13.9444 10.4121 13.6738 10.7344 13.6738 11.1319C13.6738 11.5294 13.9444 11.8516 14.2781 11.8516Z" fill="white"/>
    <defs>
    <radialGradient id="paint0_radial_5621_22860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.9714 0.944734) scale(15.0581)">
    <stop stopColor="#FEEB71"/>
    <stop offset="1" stopColor="#F79C33"/>
    </radialGradient>
    <radialGradient id="paint1_radial_5621_22860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.7995 10.6521) scale(9.99974)">
    <stop stopColor="white"/>
    <stop offset="0.3726" stopColor="#FFFEFD"/>
    <stop offset="0.5069" stopColor="#FEF9F6"/>
    <stop offset="0.6026" stopColor="#FCF0EB"/>
    <stop offset="0.68" stopColor="#FAE4DA"/>
    <stop offset="0.7463" stopColor="#F7D4C4"/>
    <stop offset="0.805" stopColor="#F3C1A8"/>
    <stop offset="0.8581" stopColor="#EEAA88"/>
    <stop offset="0.9069" stopColor="#E98E62"/>
    <stop offset="0.9523" stopColor="#E36F37"/>
    <stop offset="0.9926" stopColor="#DC4F09"/>
    <stop offset="1" stopColor="#DB4800"/>
    </radialGradient>
    <radialGradient id="paint2_radial_5621_22860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.9608 18.6274) scale(2.99992)">
    <stop stopColor="#21ADFC"/>
    <stop offset="0.2088" stopColor="#1DA4F9"/>
    <stop offset="0.5493" stopColor="#128CF0"/>
    <stop offset="0.9769" stopColor="#0164E2"/>
    <stop offset="1" stopColor="#0062E1"/>
    </radialGradient>
    <linearGradient id="paint3_linear_5621_22860" x1="10.8003" y1="17.6203" x2="10.8003" y2="14.7395" gradientUnits="userSpaceOnUse">
    <stop stopColor="#85370E"/>
    <stop offset="1" stopColor="#482314"/>
    </linearGradient>
    <linearGradient id="paint4_linear_5621_22860" x1="3.10448" y1="8.2667" x2="7.83063" y2="8.2667" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CD6700"/>
    <stop offset="1" stopColor="#E38200"/>
    </linearGradient>
    <radialGradient id="paint5_radial_5621_22860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.87858 11.2899) scale(1.63953 1.95798)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    <linearGradient id="paint6_linear_5621_22860" x1="18.4938" y1="8.2667" x2="13.7677" y2="8.2667" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CD6700"/>
    <stop offset="1" stopColor="#E38200"/>
    </linearGradient>
    <radialGradient id="paint7_radial_5621_22860" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.7209 11.2899) rotate(180) scale(1.63953 1.95798)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    </defs>
    </svg>

)

export const IconSad = (props) => <Icon component={SadSvg} {...props} />
