import styled from 'styled-components'

export const Box = styled.div`
    background: linear-gradient(211.89deg, rgba(88, 93, 98, 0.3) 0.73%, rgba(88, 93, 98, 0.05) 120.33%);
    border-radius: 20px;
    padding: 80px 60px;
    text-align: center;

    &:hover{
        background: linear-gradient(256.49deg, rgba(228, 236, 255, 0.3) -0.85%, rgba(228, 236, 255, 0.1) 100.49%)
    }
`