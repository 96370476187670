import React from 'react'

import BeforeContent from '@cms/core/components/Integrations/components/Modal/content/BeforeContent'

import { ConversionIntegrationContainer } from './styles'

const ZohoStep1 = () => {
    return (
        <ConversionIntegrationContainer>
            <BeforeContent />
        </ConversionIntegrationContainer>
    )
}

export default ZohoStep1

