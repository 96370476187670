import { divide } from 'lodash'
import React, { useState } from 'react'
import { Input, Checkbox, Menu } from 'antd'
import { Wrapper, FilterBar } from './styles'
import SectionsList from '../SectionsList'
import Sections from '../../sections'

const sections = Object.entries(Sections)

let { categories, columns } = sections
    .filter(([_, section]) => {
        return section.create
    })
    .reduce(
        (current, [_, section]) => {
            if (!current.categories.includes(section.category)) {
                current.categories.push(section.category)
            }

            if (!current.columns.includes(section.columns)) {
                current.columns.push(section.columns)
            }

            return current
        },
        { categories: [], columns: [] }
    )

categories = categories.sort()
columns = columns.sort()

const SectionFilters = (props) => {
    const { Search } = Input
    const [sectionFilters, setSectionFilters] = useState({
        search: '',
        categories: props.name === 'indication' ? ['Inscrição Confirmada'] : [],
        columns: []
    })

    if (props.name !== 'indication') {
        categories = categories.filter((o) => {
            return o !== 'Inscrição Confirmada'
        })
    }

    const handleChange = (e) => {
        setSectionFilters({
            search: e.target.value,
            categories: sectionFilters.categories,
            columns: sectionFilters.columns
        })
    }

    return (
        <Wrapper>
            <FilterBar>
                <Search
                    placeholder="Buscar seção"
                    bordered={false}
                    onChange={handleChange}
                />

                <div className="checkbox-filters">
                    <div>
                        Colunas
                        <Checkbox.Group
                            options={columns}
                            onChange={(checkedValues) => {
                                setSectionFilters({
                                    search: sectionFilters.search,
                                    categories: sectionFilters.categories,
                                    columns: checkedValues
                                })
                            }}
                        />
                    </div>
                    {props.name !== 'indication' && (
                        <div>
                            Categorias
                            <Checkbox.Group
                                options={categories}
                                onChange={(checkedValues) => {
                                    setSectionFilters({
                                        search: sectionFilters.search,
                                        categories: checkedValues,
                                        columns: sectionFilters.columns
                                    })
                                }}
                            />
                        </div>
                    )}
                </div>
            </FilterBar>

            <SectionsList
                name={props.name}
                filters={sectionFilters}
            ></SectionsList>
        </Wrapper>
    )
}

export default SectionFilters
