import React, { useCallback } from 'react'
import { Form, message } from 'antd'
import { Footer, Button, Wrapper } from './styles'

import { CheckCircleFilled } from '@ant-design/icons'
import { ISectionPanel } from '../../sections/types'

interface Props extends Omit<ISectionPanel, 'activePanel'> {
    customPage?: boolean
}

const PanelFooter = (props: Props) => {
    const { setSection, customPage } = props
    const loading = false
    const [form] = Form.useForm()
    const handledSetSection = () => {
        if (setSection) setSection(null)
    }

    const handleSubmit = useCallback(
        (e) => {
            form.submit()
            message.success('Salvo com sucesso!')
            handledSetSection()
        },
        [form]
    )

    return (
        <Wrapper>
            <Footer
                className="section-form-footer"
                justify={customPage ? 'end' : 'space-between'}
            >
                {!customPage && (
                    <Button ghost onClick={handledSetSection}>
                        Fechar
                    </Button>
                )}
                <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    onClick={handleSubmit}
                    icon={<CheckCircleFilled />}
                >
                    Salvar
                </Button>
            </Footer>
        </Wrapper>
    )
}

export default PanelFooter

