import { useApolloClient, useQuery } from '@apollo/client'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'
import {
    CREATE_CATEGORY,
    UPDATE_CATEGORY
} from '@cms/events/graphql/mutations/category'
import { GET_BRAND_LIST } from '@cms/events/graphql/queries/brand'
import { GET_CATEGORY } from '@cms/events/graphql/queries/category'
import { addToList } from '@cms/core/graphql/utils'
import { Form, Input, message } from 'antd'
import React from 'react'

const CategoriesModal = ({ params, ...props }) => {
    const { eventId, type } = params
    const client = useApolloClient()

    const queryBrands = useQuery(GET_BRAND_LIST, {
        variables: { first: 9999, eventId }
    })

    const [brandsOptions, brandsSelectOptions] = useCrudSelect(queryBrands, {
        queryName: 'brands'
    })

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_CATEGORY,
                variables: { id }
            })

            const data = { ...response.data.category }

            data.brands = data.brands?.edges.map((o) => o.node.id) || []

            return data
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_CATEGORY,
                update: addToList({ list: 'categories', Type: 'Category' }),
                variables: { ...formData, event: eventId, type }
            })

            message.success('Categoria cadastrada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_CATEGORY,
                variables: { id, ...formData, event: eventId, type }
            })

            message.success('Categoria atulizada com sucesso!')
        }
    })

    const options = []

    // Object.keys(Icons).map((key) => {
    //     const Icon = Icons[key]
    //     const iconName = key
    //         .replace(/^(Ri|Fa)/g, '')
    //         .replace(/(Line|Fill)$/g, '')
    //         .replace(/([a-z])([A-Z])/g, '$1 $2')
    //         .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    //         .replace(/^./, function (str) {
    //             return str.toUpperCase()
    //         })

    //     options.push(
    //         <Option key={key} value={key}>
    //             {' '}
    //             <Icon /> - {iconName}{' '}
    //         </Option>
    //     )
    // })

    const renderTitle = () => {
        switch (type) {
            case 'PLAYLIST':
                return isEdit ? 'Editar Playlist' : 'Nova Playlist'
            case 'SERIE':
                return isEdit ? 'Editar Série' : 'Nova Série'
            case 'MAIN':
                return isEdit ? 'Editar Trilha' : 'Nova Trilha'
            default:
                return isEdit ? 'Editar Categoria' : 'Nova Categoria'
        }
    }

    return (
        <CrudModal title={renderTitle()} {...modalProps} {...props}>
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Resumo"
                    name="summary"
                    {...getError('summary')}
                >
                    <Input.TextArea rows={2} />
                </Form.Item>

                {/* <Form.Item label="Icone" name="icon" {...getError('icon')}>
                    <Select showSearch>{options}</Select>
                </Form.Item> */}

                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('description')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Patrocinadores"
                    name={['brands']}
                    {...getError('brands')}
                >
                    <SelectSearch {...brandsSelectOptions}>
                        {brandsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default CategoriesModal
