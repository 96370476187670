import { Form, Input, Row, Col } from 'antd'
import React from 'react'

const BeforeContent = () => {
    return (
        <>
            <Form.Item
                name={'name'}
                rules={[
                    {
                        required: true,
                        message: 'Nome da integração é obrigatória!'
                    }
                ]}
                label="Nome da Integração"
            >
                <Input placeholder="ex: Minha integração" />
            </Form.Item>
        </>
    )
}

export default BeforeContent
