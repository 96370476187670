import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_CHAT_MESSAGES = gql`
    query ChatMessages(
        $channel: ChannelInput!
        $first: Int
        $sort: SortDirection
        $afterCursor: Cursor
    ) {
        chatMessages(
            channel: $channel
            first: $first
            after: $afterCursor
            orderBy: { createdAt: $sort }
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            totalCount
            edges {
                node {
                    id
                    uid
                    user {
                        id
                        name
                        avatar {
                            url
                        }
                    }
                    message
                }
            }
        }
    }
`

export const GET_BAN_WORDS = gql`
    query BanWords {
        banWords {
            id
            word
        }
    }
`
