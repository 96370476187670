import React, { useEffect } from 'react'
import { Form, Collapse } from 'antd'

import { ButtonConfig, ContainerConfig } from '../../components'

import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import AceEditor from 'react-ace'

const { Panel } = Collapse

export interface ICodeProps {
    name: string
}

export default function Code({ name }: ICodeProps) {
    const [form] = Form.useForm()

    return (
        <>
            <ContainerConfig name={name} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Collapse>
                    <Panel header="Html" key="1">
                        <Form.Item
                            label=""
                            name={[name, 'configs', 'codes', 'html']}
                        >
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'html'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor-html"
                                style={{ fontFamily: 'unset' }}
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                // onChange={(value) => {
                                //     form.setFieldValue(
                                //         [name, 'configs', 'codes', 'html'],
                                //         value
                                //     )
                                // }}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2,
                                    displayIndentGuides: true,
                                    cursorStyle: 'smooth'
                                }}
                            />
                        </Form.Item>
                    </Panel>

                    <Panel header="CSS" key="2">
                        <Form.Item
                            label=""
                            name={[name, 'configs', 'codes', 'css']}
                        >
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'css'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor-css"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2
                                }}
                            />
                        </Form.Item>
                    </Panel>

                    <Panel header="Javascript" key="3">
                        <Form.Item
                            label=""
                            name={[name, 'configs', 'codes', 'js']}
                        >
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'javascript'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor-js"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2
                                }}
                            />
                        </Form.Item>
                    </Panel>
                </Collapse>
            </Wrapper>

            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[name, 'configs', 'button']}
                name={name}
            />
        </>
    )
}
