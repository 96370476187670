import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import PageBuilder from '../pagebuilder'
import {
    Page,
    General,
    Setup,
    TemplateSelection,
    Seo,
    WebScripts,
    Config
} from '../pages'

const GeneralPage = () => <General />
const SeoPage = () => <Seo />
const WebScriptsPage = () => <WebScripts />
const TicketPage = () => <Page name="checkout" />
const CheckoutPage = () => <Page name="checkoutPayment" />
const ConfigPage = () => <Config />

export const PageBuilderRoutes = () => {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <PrivateRoute exact path={path} component={GeneralPage} />
            <PrivateRoute exact path={`${path}/seo`} component={SeoPage} />
            <PrivateRoute
                exact
                path={`${path}/scripts`}
                component={WebScriptsPage}
            />
            <PrivateRoute
                exact
                path={`${path}/ticket`}
                component={TicketPage}
            />
            <PrivateRoute
                exact
                path={`${path}/checkoutPayment`}
                component={CheckoutPage}
            />
            <PrivateRoute
                exact
                path={`${path}/config`}
                component={ConfigPage}
            />
        </Switch>
    )
}

export const PagesRoutes = () => {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <PrivateRoute exact path={`${path}/setup`} component={Setup} />
            <PrivateRoute
                exact
                path={`${path}/templates`}
                component={TemplateSelection}
            />
            <PrivateRoute path={path} component={PageBuilder} />
        </Switch>
    )
}

export const PagesRoutesOfContext = () => {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <PrivateRoute
                exact
                path={`${path}/templates`}
                component={TemplateSelection}
            />
        </Switch>
    )
}

export default PageBuilderRoutes
