import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_CUSTOM_TRACKER = gql`
    mutation createCustomTracker(
        $name: String!
        $description: String
        $eventName: String
        $eventMessage: String
    ) {
        createCustomTracker(
            input: {
                name: $name
                description: $description
                eventName: $eventName
                eventMessage: $eventMessage
            }
        ) {
            ...CustomTrackerData
        }
    }

    ${fragments.CustomTrackerData}
`

export const UPDATE_CUSTOM_TRACKER = gql`
    mutation updateCustomTracker(
        $id: ObjectID!
        $name: String!
        $description: String
        $eventName: String
        $eventMessage: String
        $active: Boolean
    ) {
        updateCustomTracker(
            id: $id
            input: {
                name: $name
                description: $description
                eventName: $eventName
                eventMessage: $eventMessage
                active: $active
            }
        ) {
            ...CustomTrackerData
        }
    }

    ${fragments.CustomTrackerData}
`

export const DELETE_CUSTOM_TRACKER = gql`
    mutation deleteCustomTracker($id: ObjectID!) {
        deleteCustomTracker(id: $id) {
            success
            id
        }
    }
`
