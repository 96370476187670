import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 12px;
    border: 2px solid rgba(228, 236, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #424246;

    .ant-space-item {
        margin-bottom: 0;
    }
`
