import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Typography, Row } from 'antd'

import { GET_RANKING_PARTICIPANTS } from '@cms/events/graphql/queries/dashboards'
import { CrudList } from '@cms/core/components/Crud'

import { CrownOutlined } from '@ant-design/icons'
import { TagDefault, Totals, TextTitle } from './styles'
import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../styles'

const RankingPartipants = (props) => {
    const params = useParams()

    const { eventId } = params
    const { Text } = Typography

    const columns = [
        {
            title: <TextTitle>Nº</TextTitle>,
            dataIndex: [''],
            width: 60,
            fixed: 'left',
            ellipsis: true,
            render: (value, obj, index) => {
                return index + 1
            }
        },
        {
            title: <TextTitle>Nome</TextTitle>,
            dataIndex: ['node', 'user', 'name'],
            ellipsis: true,
            width: 200,
            key: 'name',
            render: (name) => {
                return <span>{name ? name : '---'}</span>
            }
        },
        {
            title: <TextTitle>E-mail</TextTitle>,
            dataIndex: ['node', 'user', 'email'],
            key: 'email',
            width: 200,
            ellipsis: true,
            render: (email) => {
                return <span>{email ? email : '---'}</span>
            }
        },
        {
            title: <TextTitle>Total</TextTitle>,
            dataIndex: ['node', 'referralReports', 'total'],
            key: 'total',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (total) => {
                return <Totals>{total ? total : 0}</Totals>
            }
        },
        {
            title: <TextTitle>Copy</TextTitle>,
            dataIndex: ['node', 'referralReports', 'source', 'copy'],
            key: 'copy',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (copy) => {
                return <TagDefault>{copy ? copy : 0}</TagDefault>
            }
        },
        {
            title: <TextTitle>Whatsapp</TextTitle>,
            dataIndex: ['node', 'referralReports', 'source', 'whatsapp'],
            key: 'whatsapp',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (whatsapp) => {
                return <TagDefault>{whatsapp ? whatsapp : 0}</TagDefault>
            }
        },
        {
            title: <TextTitle>Facebook</TextTitle>,
            dataIndex: ['node', 'referralReports', 'source', 'facebook'],
            key: 'facebook',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (facebook) => {
                return <TagDefault>{facebook ? facebook : 0}</TagDefault>
            }
        },
        {
            title: <TextTitle>Twitter</TextTitle>,
            dataIndex: ['node', 'referralReports', 'source', 'twitter'],
            key: 'twitter',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (twitter) => {
                return <TagDefault>{twitter ? twitter : 0}</TagDefault>
            }
        },
        {
            title: <TextTitle>E-mail</TextTitle>,
            dataIndex: ['node', 'referralReports', 'source', 'email'],
            key: 'email',
            ellipsis: true,
            width: 80,
            align: 'center',
            render: (email) => {
                return <TagDefault>{email ? email : 0}</TagDefault>
            }
        }
    ]
    const query = useQuery(GET_RANKING_PARTICIPANTS, {
        variables: { first: 25, eventId }
    })

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <CrownOutlined style={{ color: '#ffff' }} />
                </IconWrapper>
                <DashboardTitle>Ranking de Participantes</DashboardTitle>
            </DashboardCardTitle>

            <div className="minimal-padding">
                <CrudList
                    columns={columns}
                    hideAction={props.hideAction}
                    queryName={'eventRegistrations'}
                    search={false}
                    showHeader={true}
                    scroll={{ x: '100%' }}
                    height={'700px'}
                    sticky
                    transparent
                    {...query}
                />
            </div>
        </>
    )
}

export default RankingPartipants
