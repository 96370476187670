import { SectionsType } from '../../../../constants/initialData'
import { ORDER_BY_NAME, ORDER_BY_PRIORITY } from '../../../OrderBy/constants'
import { OPTIONS_VALUE } from './options'

export const SPEAKERS_CONFIGS = {
    speakers: {
        type: SectionsType.speakers,
        defaultValues: {},
        fields: {
            _id: {
                label: 'Palestrantes',
                type: 'select',
                options: OPTIONS_VALUE.all
            },
            featured: {
                label: 'Destaques',
                type: 'boolean',
                options: null
            },
            tags: {
                label: 'Tags',
                type: 'select',
                options: OPTIONS_VALUE.tags
            }
        },
        orderBy: {
            ...ORDER_BY_NAME,
            ...ORDER_BY_PRIORITY
        }
    }
}
