import { useApolloClient, useQuery } from '@apollo/client'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'
import {
    CREATE_EVENT_EDITION,
    UPDATE_EVENT_EDITION
} from '@cms/events/graphql/mutations/eventEdition'
import {
    GET_EVENTS,
    GET_EVENTS_OPTIONS
} from '@cms/events/graphql/queries/event'
import { GET_EVENT_EDITION } from '@cms/events/graphql/queries/eventEdition'
import { addToList } from '@cms/core/graphql/utils'
import { Form, Input, message } from 'antd'
import React from 'react'

const getData = (data) => {
    return {
        ...data,
        events: data.events.map((event) => event.id)
    }
}

const EventEditionModal = ({ params, ...props }) => {
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_EVENT_EDITION,
                variables: { id }
            })

            return getData(data.eventEdition)
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_EVENT_EDITION,
                update: addToList({
                    list: 'eventEditions',
                    Type: 'EventEdition'
                }),
                variables: formData
            })

            message.success('Edição cadastrada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_EVENT_EDITION,
                variables: { id, ...formData }
            })

            message.success('Edição atualizada com sucesso!')
        }
    })

    const queryEvents = useQuery(GET_EVENTS_OPTIONS, {
        variables: { first: 9999 },
        fetchPolicy: 'network-only'
    })

    const [eventsOptions, eventsSelectProps] = useCrudSelect(queryEvents, {
        queryName: 'events'
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Edição' : 'Novo Edição'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                {eventsOptions ? (
                    <Form.Item
                        name="events"
                        label="Selecione os eventos"
                        {...getError('events')}
                    >
                        <SelectSearch {...eventsSelectProps}>
                            {eventsOptions({
                                key: 'node.id',
                                value: 'node.id',
                                label: 'node.name'
                            })}
                        </SelectSearch>
                    </Form.Item>
                ) : null}
            </Form>
        </CrudModal>
    )
}

export default EventEditionModal
