import React from 'react'
import { Form } from 'antd'

import { ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import FormConfig from '../../components/FormConfig'

const FormSection = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item name={[props.name, 'configs', 'headline', 'text']}>
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item name={[props.name, 'configs', 'subtitle', 'text']}>
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <FormConfig name={props.name} path={'sections'} />
        </>
    )
}

export default FormSection
