import { GET_CATEGORY_SELECT_LIST } from '@cms/events/graphql/queries/category'
import {
    GET_STAGES_SELECT_LIST,
    GET_TALKS_SELECT_LIST,
    GET_TALK_TAGS
} from '@cms/events/graphql/queries/talk'

export const OPTIONS_VALUE = {
    tracks: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_CATEGORY_SELECT_LIST,
            variables: {
                eventId,
                orderBy: { name: 'ASC' },
                type: 'MAIN'
            }
        })

        const parsedData =
            (data &&
                data.categories.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    series: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_CATEGORY_SELECT_LIST,
            variables: {
                eventId,
                orderBy: { name: 'ASC' },
                type: 'SERIE'
            }
        })

        const parsedData =
            (data &&
                data.categories.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    playlist: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_CATEGORY_SELECT_LIST,
            variables: {
                eventId,
                orderBy: { name: 'ASC' },
                type: 'PLAYLIST'
            }
        })

        const parsedData =
            (data &&
                data.categories.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    stages: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_STAGES_SELECT_LIST,
            variables: {
                eventId,
                orderBy: { name: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data.talks.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    talks: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_TALKS_SELECT_LIST,
            variables: {
                eventId,
                orderBy: { name: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data.talks.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    tags: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_TALK_TAGS,
            variables: {
                eventId,
                orderBy: { name: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data.talkTags.map((item) => {
                    return {
                        value: item,
                        label: item
                    }
                })) ||
            []
        return parsedData
    }
}
