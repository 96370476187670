import React from 'react'
import { Empty, Typography } from 'antd'

const { Paragraph, Title, Text } = Typography

const MainNumberV2 = ({ title, value, percent }) => {
    return (
        <>
            <Paragraph>{title}</Paragraph>
            {value ? (
                <Title
                    level={1}
                    style={{
                        fontSize: value > 9999 ? '4.3em' : '5em',
                        textAlign: 'center'
                    }}
                >
                    {value}
                    {/* {percent && (
                        <Text style={{ fontSize: '14px', marginLeft: '12px' }}>
                            {Number(percent.replace('%', '')).toFixed(0)}%
                        </Text>
                    )} */}
                </Title>
            ) : (
                <Empty description="Não há dados" />
            )}
        </>
    )
}

export default MainNumberV2

