import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd'

import {
    ColorsBadges,
    ContainerConfig,
    DatePicker,
    ButtonConfig
} from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const Countdown = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Texto</Title>
                <Form.Item
                    name={[props.name, 'configs', 'message', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Data/hora</Title>
                <Form.Item
                    name={[props.name, 'configs', 'countdownText', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item name={[props.name, 'configs', 'countdown', 'date']}>
                    <DatePicker
                        showTime
                        needConfirm={false}
                        style={{ width: '100%' }}
                        format={'DD MMM YYYY HH:mm:ss'}
                        placeholder=""
                    />
                </Form.Item>
                <Wrapper>
                    <Title level={4}>
                        Texto para dias, horas, minutos e segundos
                    </Title>
                    <Form.Item
                        name={[props.name, 'configs', 'days', 'text']}
                        label="Dias"
                        initialValue="Dias"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={[props.name, 'configs', 'hours', 'text']}
                        label="Horas"
                        initialValue="Horas"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={[props.name, 'configs', 'minutes', 'text']}
                        label="Minutos"
                        initialValue="Minutos"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={[props.name, 'configs', 'seconds', 'text']}
                        initialValue="Segundos"
                        label="Segundos"
                    >
                        <Input />
                    </Form.Item>
                </Wrapper>
            </Wrapper>
            <ButtonConfig {...props} enableText={true} enableActions={true} />
        </>
    )
}

export default Countdown
