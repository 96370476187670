import { GET_BRAND_OPTIONS } from '@cms/events/graphql/queries/brand'
import { GET_SPEAKER_OPTIONS } from '@cms/events/graphql/queries/speaker'
import { get } from 'lodash'

const models = {
    Speaker: {
        GRAPHQL_QUERY: GET_SPEAKER_OPTIONS,
        formatOptions: (list, fieldRef) => {
            return list.speakers.edges.map(({ node }) => ({
                id: node.id,
                value: get(node, fieldRef) || node.id,
                label: node.name
            }))
        },
        fields: [
            {
                name: 'name',
                label: 'Nome',
                type: 'string'
            },
            {
                name: 'firstName',
                label: 'Primeiro Nome',
                type: 'string'
            },
            {
                name: 'lastName',
                label: 'Sobrenome',
                type: 'string'
            },
            {
                name: 'bio',
                label: 'Bio',
                type: 'string'
            },
            {
                name: 'image.url',
                label: 'Imagem',
                type: 'image'
            },
            {
                name: 'company.name',
                label: 'Nome da Empresa',
                type: 'string'
            },
            {
                name: 'company.image.url',
                label: 'Image da Empresa',
                type: 'image'
            },
            {
                name: 'office',
                label: 'Cargo',
                type: 'string'
            },
            {
                name: 'featured',
                label: 'Destaque',
                type: 'boolean'
            },
            {
                name: 'page.title',
                label: 'Título da Página',
                type: 'string'
            },
            {
                name: 'page.slug',
                label: 'Slug da Página',
                type: 'string',
                isRef: true
            },
            {
                name: 'page.image.url',
                label: 'Imagem da Página',
                type: 'image'
            },
            {
                name: 'links',
                label: 'Links',
                type: 'array',
                subPaths: [
                    {
                        name: 'type',
                        label: 'Tipo',
                        type: 'string'
                    },
                    {
                        name: 'url',
                        label: 'URL',
                        type: 'string'
                    }
                ]
            }
        ]
    },
    Brand: {
        GRAPHQL_QUERY: GET_BRAND_OPTIONS,
        formatOptions: (list, fieldRef) => {
            return list.brands.edges.map(({ node }) => ({
                id: node.id,
                value: get(node, fieldRef) || node.id,
                label: node.name
            }))
        },
        fields: [
            {
                name: 'name',
                label: 'Nome',
                type: 'string'
            },
            {
                name: 'page.title',
                label: 'Título da Página',
                type: 'string'
            },
            {
                name: 'page.slug',
                label: 'Slug da Página',
                type: 'string',
                isRef: true
            },
            {
                name: 'page.image.url',
                label: 'Imagem da Página',
                type: 'image'
            }
        ]
    }
}

export default models
