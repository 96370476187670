import React, { useState } from 'react'
import {
    message,
    Form,
    Input,
    Row,
    Col,
    Divider,
    Button,
    InputNumber,
    Select,
    Checkbox,
    Tag
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { useApolloClient, useQuery } from '@apollo/client'
import { SettingOutlined } from '@ant-design/icons'
import SelectSearch from '@cms/core/components/SelectSearch'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import {
    CrudForm,
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import {
    GET_SPEAKER,
    GET_SPEAKER_USER,
    GET_SPEAKER_TAGS
} from '@cms/events/graphql/queries/speaker'
import {
    CREATE_SPEAKER,
    UPDATE_SPEAKER
} from '@cms/events/graphql/mutations/speaker'
import { GET_SPEAKERS_LIST } from '@cms/events/graphql/queries/user'
import { Wrapper } from '../../Chat/ChatNav/styles'

const { Option } = Select

const formatData = ({ ...data }) => {
    data.links =
        data.links?.map((o) => {
            return { type: o.type, url: o.url }
        }) || []

    return data
}

const getData = (data) => {
    return {
        ...data,
        user: data.user?.id
    }
}

const SpeakersModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const { data: Tags } = useQuery(GET_SPEAKER_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const [{ form, isEdit, getError, formUpdate }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_SPEAKER,
                variables: { id }
            })

            return getData(data.speaker)
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_SPEAKER,
                update: addToList({ list: 'speakers', Type: 'Speaker' }),
                variables: { ...formatData(formData), event: eventId }
            })

            message.success('Palestrante cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_SPEAKER,
                variables: {
                    id,
                    ...formatData(formData),
                    event: eventId,
                    image: formData.image
                }
            })

            message.success('Palestrante atualizado com sucesso!')
        }
    })

    const [linkTypes] = useState([
        'Linkedin',
        'Facebook',
        'Twitter',
        'Instagram',
        'TikTok',
        'Outros'
    ])

    const querySpeakers = useQuery(GET_SPEAKERS_LIST, {
        variables: { first: 9999 },
        fetchPolicy: 'cache-and-network'
    })

    const [speakersOptions, speakerSelectProps] = useCrudSelect(querySpeakers, {
        queryName: 'users',
        includeEmpty: true,
        additionalValues: form.getFieldValue('user')
    })

    const initialValues = {
        links: [{ type: 'Linkedin', url: '' }]
    }

    const handleUserSelect = async (user) => {
        if (!isEdit) {
            const { data } = await client.query({
                query: GET_SPEAKER_USER,
                variables: { user }
            })

            form.resetFields()
            formUpdate({ user })

            const speakers = data?.speakers?.edges

            if (Array.isArray(speakers) && speakers[0]) {
                const node = speakers[0].node
                formUpdate(getData(node))
            }
        }
    }

    return (
        <CrudModal
            width={600}
            title={isEdit ? 'Editar Palestrante' : 'Novo Palestrante'}
            {...modalProps}
            {...props}
        >
            <CrudForm
                layout={'vertical'}
                initialValues={initialValues}
                form={form}
            >
                <Form.Item label="Usuário" name={'user'} {...getError('user')}>
                    <SelectSearch
                        {...speakerSelectProps}
                        onSelect={handleUserSelect}
                        mode={null}
                    >
                        {speakersOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="firstName"
                            rules={[
                                {
                                    required: true,
                                    message: 'Nome é obrigatório!'
                                }
                            ]}
                            {...getError('firstName')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Sobrenome"
                            name="lastName"
                            {...getError('lastName')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Cargo"
                            name="office"
                            {...getError('office')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Foto"
                            name="image"
                            {...getError('image')}
                        >
                            <MediaUploader
                                options={{
                                    optimize: {
                                        resize: {
                                            width: 500,
                                            height: 500
                                        }
                                    }
                                }}
                                type="image"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item label="Bio" name="bio" {...getError('bio')}>
                    <Input.TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    label="Prioridade (Ordena os palestrantes do maior para o menor)"
                    name="priority"
                    {...getError('priority')}
                >
                    <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item name={['page', 'enabled']} valuePropName="checked">
                    <Checkbox>Habilitar página de palestrante</Checkbox>
                </Form.Item>
                <Form.Item noStyle shouldUpdate>
                    {({ getFieldValue }) => {
                        const speakerPage = getFieldValue(['page', 'enabled'])

                        if (!speakerPage) {
                            return null
                        }

                        const hanleOnKeyPress = (e) => {
                            const regex = new RegExp(
                                /^[A-Za-z0-9_-]+(?:-[A-Za-z0-9_-]+)*$/
                            )
                            const str = String.fromCharCode(
                                !e.charCode ? e.which : e.charCode
                            )
                            if (regex.test(str)) {
                                return true
                            }

                            e.preventDefault()
                            return false
                        }

                        return (
                            <Wrapper>
                                <Divider orientation="left">
                                    Página do palestrante
                                </Divider>
                                <Row gutter={24} align="middle">
                                    <Col span={24}>
                                        <Form.Item
                                            label="Título"
                                            name={['page', 'title']}
                                            rules={[
                                                {
                                                    required: !!speakerPage,
                                                    message:
                                                        'Título é obrigatório!'
                                                }
                                            ]}
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={8}>
                                        <Form.Item
                                            label="Foto de destaque"
                                            name={['page', 'image']}
                                            rules={[
                                                {
                                                    required: !!speakerPage,
                                                    message:
                                                        'Imagem é obrigatório!'
                                                }
                                            ]}
                                        >
                                            <MediaUploader
                                                options={{
                                                    optimize: {
                                                        resize: {
                                                            width: 500,
                                                            height: 500
                                                        },
                                                        quality: 80
                                                    }
                                                }}
                                                type="image"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={16}>
                                        <Form.Item
                                            label="Link personalizado"
                                            name={['page', 'slug']}
                                            rules={[
                                                {
                                                    required: !!speakerPage,
                                                    message:
                                                        'Link personalizado é obrigatório!'
                                                }
                                            ]}
                                            {...getError('page.slug')}
                                        >
                                            <Input
                                                onKeyPress={hanleOnKeyPress}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Wrapper>
                        )
                    }}
                </Form.Item>

                <Divider orientation="left">Empresa</Divider>

                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome da empresa"
                            name={['company', 'name']}
                            {...getError('first_name')}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item
                            label="Logo da empresa"
                            name={['company', 'image']}
                            {...getError('lastname')}
                        >
                            <MediaUploader type="svg" />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="featured" valuePropName="checked">
                    <Checkbox>Destaque</Checkbox>
                </Form.Item>

                <Divider orientation="left">Links</Divider>

                <Form.List name="links">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key}>
                                    <Row gutter={24} align="middle">
                                        <Col span={8}>
                                            <Form.Item
                                                label="Origem"
                                                name={[field.name, 'type']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Origem é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    filterOption={(
                                                        input,
                                                        option
                                                    ) =>
                                                        option.children
                                                            .toLowerCase()
                                                            .indexOf(
                                                                input.toLowerCase()
                                                            ) >= 0
                                                    }
                                                >
                                                    {linkTypes.map((o) => (
                                                        <Option key={o}>
                                                            {o}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                {...field}
                                                label="Url"
                                                name={[field.name, 'url']}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Url é obrigatório!'
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                style={{ marginTop: 20 }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => {
                                        add({})
                                    }}
                                    style={{ width: '100%' }}
                                >
                                    <PlusOutlined /> Adicionar
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

                <Form.Item label="Tags" name={['tags']}>
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existe ou crie uma nova."
                    >
                        {Tags?.speakerTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </CrudForm>
        </CrudModal>
    )
}

export default SpeakersModal

