import React from 'react'
import { Space } from 'antd'
import { useQuery, useMutation } from '@apollo/client'

import { CrudList } from '@cms/core/components/Crud'
import { ButtonPopConfirm } from '@cms/events/components/Buttons'

import { GET_USERS_BANNED } from '@cms/events/graphql/queries/user'
import { UNDO_BAN_USER } from '@cms/events/graphql/mutations/user'

const columns = [
    {
        title: 'Name',
        sorter: 'name',
        dataIndex: ['node', 'name']
    },
    {
        title: 'E-mail',
        dataIndex: ['node', 'email']
    }
]

const UsersBanned = () => {
    const query = useQuery(GET_USERS_BANNED, {
        variables: { first: 25 }
    })

    const [undoUser] = useMutation(UNDO_BAN_USER, {
        refetchQueries: [{ query: GET_USERS_BANNED }]
    })

    const actionsExtra = {
        dataIndex: '',
        key: 'x',
        align: 'right',
        render: (_, record) => {
            const id = record.node.id

            return (
                <Space>
                    <ButtonPopConfirm
                        id={id}
                        key={id}
                        text={'Desbloquear'}
                        title={'Tem certeza que deseja desbloquear o usuário?'}
                        action={undoUser}
                    />
                </Space>
            )
        }
    }

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' }
    ]

    return (
        <>
            <CrudList
                header={{
                    title: 'Usuários Banidos',
                    subTitle: 'Visualização de usuários banidos'
                }}
                search={true}
                columns={columns}
                queryName={'users'}
                actionsExtra={actionsExtra}
                extraSize={350}
                hideAction={'all'}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default UsersBanned

