import styled from 'styled-components'

export const HiddenArea = styled.div`
    height: 30px;
    transition: 0.4s;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Ajustar css abaixo */
    margin-top: -20px;

    .divider {
        /* display: none; */
    }

    .ant-divider-horizontal.ant-divider-with-text::before,
    .ant-divider-horizontal.ant-divider-with-text::after {
        border-color: rgba(255, 255, 255, 0.15);
    }

    &:hover {
        height: 30px;
        transition: 0.4s;
        cursor: pointer;

        .divider {
            display: flex;
        }
    }
`
