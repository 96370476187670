import { gql } from '@apollo/client'
import { UserData } from '../fragments'

export const IS_LOGGED_IN = gql`
    query isAuthenticated {
        isAuthenticated @client
    }
`

export const CURRENT_USER = gql`
    query CurrentUser {
        me {
            ...UserData
        }
    }

    ${UserData}
`
