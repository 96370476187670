import React from 'react'
import { Form, Input, Select, Switch, Typography } from 'antd'
import { Wrapper } from '../styles'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { RedirectOptions } from '@cms/events/pages/Config/PageBuilder/components'
import { ColorsBadges } from '@cms/events/pages/Config/Checkout/components'

const { Title } = Typography

const { Option } = Select

const MenuItem = (props) => {
    const { name, moveUp, moveDown, remove, form } = props

    return (
        <>
            <Form.Item
                name={[name, 'active']}
                initialValue={'checked'}
                valuePropName="checked"
                label="Exibir link"
            >
                <Switch
                    checkedChildren={<EyeOutlined />}
                    unCheckedChildren={<EyeInvisibleOutlined />}
                />
            </Form.Item>

            <Form.Item label="Texto" name={[name, 'name']}>
                <Input placeholder="Link" />
            </Form.Item>

            <RedirectOptions path={'links'} fieldPath={[name]} />

            <Form.Item
                name={[name, 'checkActiveMode']}
                label="Modo de checagem para validar se esta ativo"
                initialValue="exact"
            >
                <Select>
                    <Option value="exact">Validar url completa</Option>
                    <Option value="partial">Ignorar sessão</Option>
                </Select>
            </Form.Item>

            <Wrapper>
                <Title
                    level={4}
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    Estilização
                </Title>

                <Form.Item name={[name, 'bgColor']} label="Cor de fundo">
                    <ColorsBadges />
                </Form.Item>

                <Form.Item name={[name, 'textColor']} label={`Cor do texto`}>
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title
                    level={4}
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    Estilização ao clicar ou passar o mouse
                </Title>

                <Form.Item
                    name={[name, 'hoverStyles', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[name, 'hoverStyles', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title
                    level={4}
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    Estilização quando ativo
                </Title>

                <Form.Item
                    name={[name, 'activeStyles', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[name, 'activeStyles', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default MenuItem

