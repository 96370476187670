import { Content } from './Content'

export const ContentSections = {
    content: {
        Component: Content,
        name: 'content',
        category: 'Conteúdo',
        label: 'Conteúdos',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'en-US': 'Most viewed content.',
                'es-ES': 'Contenidos más vistos.',
                'pt-PT': 'Conteúdos mais vistos.',
                'pt-BR': 'Conteúdos mais vistos.'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    }
}
