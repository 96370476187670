import React, { useEffect, useState } from 'react'

import { StreamingProvider, useStreamingContext } from './context'
import { BASE_URL, ROUTES } from './constants/routes'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'

import { useQuery } from '@apollo/client'
import { GET_EVENT_STREAMING_TOKENS } from '@cms/events/graphql/queries/event'
import { useParams } from 'react-router-dom'

const StreamingConfigContent = () => {
    const { save } = useStreamingContext()

    return <ThemeBuilderContent save={save} />
}

const StreamingConfigScreen = () => {
    const { eventId } = useParams()

    const [previewUrl, setPreviewUrl] = useState(null)

    const { data: tokensData } = useQuery(GET_EVENT_STREAMING_TOKENS, {
        variables: {
            event: eventId
        }
    })

    useEffect(() => {
        if (tokensData?.eventStreamingTokens) {
            const { token } = tokensData?.eventStreamingTokens
            setPreviewUrl(
                `${process.env.APIGW_ENDPOINT}/auth/magiclink/callback?token=${token}`
            )
        }
    }, [tokensData])

    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: ROUTES }}
            previewUrl={previewUrl}
        >
            <StreamingProvider>
                <StreamingConfigContent />
            </StreamingProvider>
        </ThemeBuilderProvider>
    )
}

export default StreamingConfigScreen
