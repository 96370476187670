import React from 'react'
import { Form, Select, Tooltip, Input, Radio, Row, Col } from 'antd'
import {
    InfoCircleOutlined,
    DesktopOutlined,
    MobileOutlined
} from '@ant-design/icons'
import { ColorsBadges, Uploader } from '..'
import { Wrapper, Title } from '../../styles'
import { useState } from 'react'

const optionsLayout = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Repetir',
        value: 'repeat'
    }
]

const optionsSize = [
    {
        label: 'Preencher',
        value: 'fill'
    },
    {
        label: 'Conter',
        value: 'contain'
    },
    {
        label: 'Capa',
        value: 'cover'
    },
    {
        label: 'Não preencher',
        value: 'none'
    }
]

const optionsPosition = [
    {
        label: 'Centro',
        value: 'center center'
    },
    {
        label: 'Topo',
        value: 'top'
    },
    {
        label: 'Baixo',
        value: 'bottom'
    }
]

const ContainerConfig = (props) => {
    const { form, type, bgVideo = true } = props
    const [mode, setMode] = useState('desktop')

    const handleModeChange = (e) => {
        setMode(e.target.value)
    }

    const handleShowImageSubControls = (prevValues, currentValues) => {
        return (
            prevValues.sections[props.name].configs.container.bgImage
                ?.layout !==
            currentValues.sections[props.name].configs.container.bgImage?.layout
        )
    }

    return (
        <Wrapper>
            <Form.Item
                name={[props.name, 'configs', 'container', 'id']}
                label="ID"
            >
                <Input />
            </Form.Item>
            <Title level={4}>Fundo</Title>
            {/* <Divider orientation="left">Cor de fundo</Divider> */}
            <Form.Item
                name={[props.name, 'configs', 'container', 'bgColor']}
                label="Cor de fundo"
            >
                <ColorsBadges />
            </Form.Item>
            {/* <Divider orientation="left">Imagem de fundo</Divider> */}
            <Radio.Group
                onChange={handleModeChange}
                value={mode}
                initialValue={mode}
                style={{ marginBottom: 8 }}
            >
                <Radio.Button
                    style={{ borderRight: '1px solid rgb(255 255 255 / 100%)' }}
                    value="desktop"
                >
                    <DesktopOutlined />
                </Radio.Button>
                <Radio.Button value="mobile">
                    <MobileOutlined />
                </Radio.Button>
            </Radio.Group>
            {mode === 'desktop' && (
                <>
                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'container',
                            'bgImage',
                            'src'
                        ]}
                        label="Imagem de fundo desktop"
                    >
                        <Uploader />
                    </Form.Item>

                    {bgVideo && (
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'container',
                                'bgVideo'
                            ]}
                            label="Vídeo de fundo"
                        >
                            <Input />
                        </Form.Item>
                    )}

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const src = getFieldValue([
                                'sections',
                                props.name,
                                'configs',
                                'container',
                                'bgImage',
                                'src'
                            ])
                            return src ? (
                                <>
                                    <Form.Item
                                        name={[
                                            props.name,
                                            'configs',
                                            'container',
                                            'bgImage',
                                            'layout'
                                        ]}
                                        label={
                                            <Tooltip title="Selecione 'Preencher' para definir o modo de preenchimedo da imagem e a sua posição. Selecione 'Repetir' para que a imagem se repita de modo a ocupar todo o espaço de fundo. ">
                                                Preenchimento{' '}
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        }
                                        rules={[{ required: true }]}
                                        initialValue={'fill'}
                                    >
                                        <Select options={optionsLayout} />
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={
                                            handleShowImageSubControls
                                        }
                                    >
                                        {({ getFieldValue }) => {
                                            const layout = getFieldValue([
                                                'sections',
                                                props.name,
                                                'configs',
                                                'container',
                                                'bgImage',
                                                'layout'
                                            ])
                                            return layout !== 'repeat' ? (
                                                <>
                                                    <Form.Item
                                                        name={[
                                                            props.name,
                                                            'configs',
                                                            'container',
                                                            'bgImage',
                                                            'objectFit'
                                                        ]}
                                                        label={
                                                            <Tooltip title="Escolha o tamanho da imagem em relação ao fundo.">
                                                                Tamanho{' '}
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        }
                                                        rules={[
                                                            { required: true }
                                                        ]}
                                                        initialValue={'cover'}
                                                    >
                                                        <Select
                                                            options={
                                                                optionsSize
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[
                                                            props.name,
                                                            'configs',
                                                            'container',
                                                            'bgImage',
                                                            'objectPosition'
                                                        ]}
                                                        label={
                                                            <Tooltip title="Defina o alinhamento da imagem de fundo.">
                                                                Posição{' '}
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        }
                                                        rules={[
                                                            { required: true }
                                                        ]}
                                                        initialValue={
                                                            'center center'
                                                        }
                                                    >
                                                        <Select
                                                            options={
                                                                optionsPosition
                                                            }
                                                        />
                                                    </Form.Item>
                                                </>
                                            ) : null
                                        }}
                                    </Form.Item>
                                </>
                            ) : null
                        }}
                    </Form.Item>
                </>
            )}
            {mode === 'mobile' && (
                <>
                    <Form.Item
                        name={[
                            props.name,
                            'configs',
                            'container',
                            'mobileBgImage',
                            'src'
                        ]}
                        label="Imagem de fundo mobile"
                    >
                        <Uploader />
                    </Form.Item>

                    {bgVideo && (
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'container',
                                'mobileBgVideo'
                            ]}
                            label="Vídeo de fundo mobile"
                        >
                            <Input />
                        </Form.Item>
                    )}

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            const src = getFieldValue([
                                'sections',
                                props.name,
                                'configs',
                                'container',
                                'mobileBgImage',
                                'src'
                            ])
                            return src ? (
                                <>
                                    <Form.Item
                                        name={[
                                            props.name,
                                            'configs',
                                            'container',
                                            'mobileBgImage',
                                            'layout'
                                        ]}
                                        label={
                                            <Tooltip title="Selecione 'Preencher' para definir o modo de preenchimedo da imagem e a sua posição. Selecione 'Repetir' para que a imagem se repita de modo a ocupar todo o espaço de fundo. ">
                                                Preenchimento{' '}
                                                <InfoCircleOutlined />
                                            </Tooltip>
                                        }
                                        rules={[{ required: true }]}
                                        initialValue={'fill'}
                                    >
                                        <Select options={optionsLayout} />
                                    </Form.Item>
                                    <Form.Item
                                        noStyle
                                        shouldUpdate={
                                            handleShowImageSubControls
                                        }
                                    >
                                        {({ getFieldValue }) => {
                                            const layout = getFieldValue([
                                                'sections',
                                                props.name,
                                                'configs',
                                                'container',
                                                'mobileBgImage',
                                                'layout'
                                            ])
                                            return layout !== 'repeat' ? (
                                                <>
                                                    <Form.Item
                                                        name={[
                                                            props.name,
                                                            'configs',
                                                            'container',
                                                            'mobileBgImage',
                                                            'objectFit'
                                                        ]}
                                                        label={
                                                            <Tooltip title="Escolha o tamanho da imagem em relação ao fundo.">
                                                                Tamanho{' '}
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        }
                                                        rules={[
                                                            { required: true }
                                                        ]}
                                                        initialValue={'cover'}
                                                    >
                                                        <Select
                                                            options={
                                                                optionsSize
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={[
                                                            props.name,
                                                            'configs',
                                                            'container',
                                                            'mobileBgImage',
                                                            'objectPosition'
                                                        ]}
                                                        label={
                                                            <Tooltip title="Defina o alinhamento da imagem de fundo.">
                                                                Posição{' '}
                                                                <InfoCircleOutlined />
                                                            </Tooltip>
                                                        }
                                                        rules={[
                                                            { required: true }
                                                        ]}
                                                        initialValue={
                                                            'center center'
                                                        }
                                                    >
                                                        <Select
                                                            options={
                                                                optionsPosition
                                                            }
                                                        />
                                                    </Form.Item>
                                                </>
                                            ) : null
                                        }}
                                    </Form.Item>
                                </>
                            ) : null
                        }}
                    </Form.Item>
                </>
            )}
        </Wrapper>
    )
}

export default ContainerConfig
