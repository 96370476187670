import { TConfig } from './types'

export const DEFAULT_SECTIONS: TConfig[] = [
    {
        title: 'Geral',
        name: 'general',
        fields: [
            {
                name: 'liveTagText',
                label: 'Texto da tag Ao vivo',
                component: 'input'
            }
        ]
    },
    {
        title: 'Login',
        name: 'login',
        fields: [
            {
                name: ['showSocialAuth'],
                label: 'Exibir rede social no login',
                component: 'checkbox'
            },
            {
                name: ['allowStreamingRegistration'],
                label: 'Permitir inscrição na transmissão',
                component: 'checkbox'
            }
        ]
    },
    {
        title: 'Home',
        name: 'home',
        fields: [
            {
                name: ['showNumberOfRegistrations'],
                label: 'Exibir número de inscrições',
                component: 'checkbox'
            },
            {
                name: ['showNumberOfSpeakers'],
                label: 'Exibir número de palestrantes',
                component: 'checkbox'
            },
            {
                name: ['showDates'],
                label: 'Exibir data final do evento',
                component: 'checkbox'
            },
            {
                name: 'slogan',
                label: 'Titulo Home',
                component: 'input'
            }
        ]
    },
    {
        title: 'Live',
        name: 'live',
        fields: [
            {
                name: ['showNumberOfViewers'],
                label: 'Exibir número de espectadores/visualizações',
                component: 'checkbox'
            },
            {
                name: ['showLiveSponsor'],
                label: 'Exibir patrocinadores nas palestras',
                component: 'checkbox'
            },
            {
                name: ['showReactions'],
                label: 'Habilitar reações',
                component: 'checkbox'
            }
        ]
    },
    {
        title: 'Chat',
        name: 'chat',
        fields: [
            {
                name: ['chatConfig', 'active'],
                label: 'Habilitar Chat',
                component: 'checkbox'
            },
            {
                name: ['chatConfig', 'slowMode', 'active'],
                label: 'Habilitar Slow Mode',
                component: 'checkbox'
            },
            {
                name: ['chatConfig', 'slowMode', 'duration'],
                label: 'Duração do Slow Mode',
                component: 'inputNumber'
            }
        ]
    },
    {
        title: 'Palestrantes',
        name: 'speakers',
        fields: [
            {
                name: ['showSpeaperkSocialInfo'],
                label: 'Exibir links',
                component: 'checkbox'
            }
        ]
    }
]

