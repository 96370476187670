import { ISectionType } from './types'

interface IOption {
    value: string
    label: string
    options?: IOption[]
}

export const mountFields = (type: string, configs: ISectionType): IOption[] => {
    const options: IOption[] = []
    Object.keys(configs[type].fields).forEach((field) => {
        const currentField = configs[type].fields[field]
        if (currentField.group) {
            const hasGroup = options.find(
                (option) => option.label === currentField.group
            )

            if (!hasGroup?.options) {
                options.push({
                    value: '',
                    label: currentField.group,
                    options: [
                        {
                            value: field,
                            label: currentField.label
                        }
                    ]
                })
            } else {
                hasGroup.options.push({
                    value: field,
                    label: currentField.label
                })
            }
        } else {
            options.push({
                value: field,
                label: currentField.label
            })
        }
    })

    return options
}

export const mountOrdeByFields = (
    type: string,
    configs: ISectionType
): IOption[] => {
    const options: IOption[] = []
    Object.keys(configs[type].orderBy).forEach((field) => {
        const currentOrderByField = configs[type].orderBy[field]
        if (currentOrderByField.group) {
            const hasGroup = options.find(
                (option) => option.label === currentOrderByField.group
            )

            if (!hasGroup?.options) {
                options.push({
                    value: '',
                    label: currentOrderByField.group,
                    options: [
                        {
                            value: field,
                            label: currentOrderByField.label
                        }
                    ]
                })
            } else {
                hasGroup.options.push({
                    value: field,
                    label: currentOrderByField.label
                })
            }
        } else {
            options.push({
                value: field,
                label: currentOrderByField.label
            })
        }
    })

    return options
}

export function getCurrentType(
    current: string,
    type: string,
    configs: ISectionType
): string | undefined {
    if (!current) {
        return
    }

    const infoSection = configs[type]
    const _type = infoSection.fields[current]?.type

    return _type
}

