import { gql } from '@apollo/client'
import { UserData } from '@cms/core/graphql/fragments'

export * from '@cms/core/graphql/queries/user'

export const GET_SPONSORS_LIST = gql`
    query GetSponsors($first: Int, $afterCursor: Cursor) {
        users(
            first: $first
            after: $afterCursor
            filter: { role: { in: ["sponsor"] } }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_SPEAKERS_LIST = gql`
    query GetSpeakers($first: Int, $afterCursor: Cursor) {
        users(
            first: $first
            after: $afterCursor
            filter: { role: { in: ["speaker"] } }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_SPONSORS_LIST_ORDERABLE = gql`
    query GetSponsors(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserOrderBy
        $name: Filter
        $email: Filter
    ) {
        users(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { role: { in: ["sponsor"] }, name: $name, email: $email }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_SPEAKERS_LIST_ORDERABLE = gql`
    query GetSpeakers(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserOrderBy
        $name: Filter
        $email: Filter
    ) {
        users(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { role: { in: ["speaker"] }, name: $name, email: $email }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

export const GET_TRACKER_TIMELINE = gql`
    query GetTrackerTimeline($eventId: ObjectID!, $id: ObjectID!) {
        trackerTimeline(filter: { event: $eventId, user: $id }) {
            type
            eventName
            properties
            context
            createdAt
            related {
                ... on Brand {
                    name
                }
                ... on Banner {
                    id
                    link
                }
                ... on User {
                    name
                }
                ... on Talk {
                    title
                }
                ... on EventForm {
                    name
                }
            }
        }
    }
`

