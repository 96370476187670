import React from 'react'
import { message, Form, Input, Select } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_FAQ_TOPIC } from '@cms/events/graphql/queries/faqTopics'
import {
    CREATE_FAQ_TOPIC,
    UPDATE_FAQ_TOPIC
} from '@cms/events/graphql/mutations/faqTopics'

const FaqTopicsModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_FAQ_TOPIC,
                variables: { id }
            })

            return { ...data.faqTopic }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_FAQ_TOPIC,
                update: addToList({
                    list: 'faqTopics',
                    Type: 'FaqTopic'
                }),
                variables: { ...formData, event: eventId }
            })

            message.success('Tópico enviado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_FAQ_TOPIC,
                variables: { id, ...formData, event: eventId }
            })

            message.success('Tópico atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar tópico' : 'Novo tópico'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Nome é obrigatório!'
                        }
                    ]}
                    {...getError('name')}
                >
                    <Input autoFocus />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('description')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default FaqTopicsModal
