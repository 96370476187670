import { crudActions } from '@cms/core/components/Sidebar/common'
import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '../components/RemixIcons'

export const SettingsModules: IAppModule[] = [
    {
        name: 'Perfil',
        icon: sidebarIcons.profile,
        module: 'mainapp.settings.profile',
        type: 'main',
        disablePermissions: true,
        children: [
            {
                name: 'Editar Perfil',
                path: '/perfil/usuario',
                module: 'mainapp.settings.profile.user'
            },
            {
                name: 'Alterar Senha',
                path: '/perfil/alterar-senha',
                module: 'mainapp.settings.profile.password'
            }
        ]
    },
    {
        name: 'Usuários',
        icon: sidebarIcons.users,
        module: 'mainapp.settings.team',
        type: 'main',
        children: [
            {
                name: 'Usuários',
                path: '/time/usuarios',
                module: 'mainapp.settings.team.users',
                children: crudActions('settings.team.users')
            },
            {
                name: 'Perfis',
                path: '/time/profiles',
                module: 'mainapp.settings.team.profiles',
                children: crudActions('settings.team.profiles')
            }
        ]
    }
]
