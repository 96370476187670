import React from 'react'
import { Form, Input, Button } from 'antd'

import { ButtonConfig, ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const About = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'content', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <ButtonConfig enableActions allowVisibilityControl {...props} />
        </>
    )
}

export default About
