import { lazy } from 'react'
import { IApp } from '@cms/core/types/app'

import { AdminModules } from './modules/admin'
import { selectFullAccess } from '@cms/core/components/Sidebar/common'

const AppAdmin: IApp = {
    id: 'admin',
    name: 'Admin',
    basePath: '/admin',
    checkEnable: (state) => {
        return (
            state.customer.master &&
            state.user.isAdmin &&
            selectFullAccess(state)
        )
    },
    container: lazy(() => import('@cms/admin/components/Container')),
    modules: [
        {
            name: 'TD Admin',
            path: `/admin`,
            module: 'admin',
            root: true,
            disablePermissions: true,
            children: AdminModules
        }
    ]
}

export default AppAdmin
