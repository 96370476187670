import Rewards from './Rewards'
import Rewards2 from './Rewards2'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const RewardsSections = {
    Rewards: {
        Component: Rewards,
        name: 'Recompensas de Indicação',
        category: 'Recompensas',
        thumb: require('./rewards.png'),
        columns: '2',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    },
                    topBgColor: 'primary',
                    primaryTabTextColor: 'black',
                    secondaryTabBgColor: 'neutral',
                    topTextColor: 'white'
                },
                topRewardImages: true,
                rewardImages: false,
                box: {
                    bgColor: 'white',
                    indications: {
                        textColor: 'secondary'
                    },
                    reward: {
                        textColor: getColorContrast(colors, 'primary')
                    },
                    instructions: {
                        textColor: getColorContrast(colors, 'primary')
                    },
                    instructionsPoints: {
                        textColor: getColorContrast(colors, 'primary')
                    }
                },
                headline: {
                    text: 'indique\namigos\ne ganhe\nprêmios',
                    textColor: getColorContrast(colors, 'primary')
                },
                content: {
                    text: 'Além de ter acesso a esse conteúdo\ngratuito, você pode convidar seus\namigos e colegas de trabalho para\n participar com você e ser\nrecompensado por isso! Confira:',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    },
    Rewards2: {
        Component: Rewards2,
        name: 'Recompensas de Indicação Vertical',
        category: 'Recompensas',
        thumb: require('./rewards2.png'),
        columns: '1',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                topRewardImages: true,
                rewardImages: false,
                box: {
                    bgColor: 'transparent',
                    headline: {
                        text: 'indique amigos e ganhe prêmios',
                        textColor: getColorContrast(colors, 'primary')
                    },
                    text: {
                        text: 'indique amigos e ganhe prêmios',
                        textColor: getColorContrast(colors, 'primary')
                    },
                    viralWidget: {
                        textColor: getColorContrast(colors, 'primary')
                    },
                    rewards: {
                        bgColor: 'transparent',
                        indications: {
                            textColor: 'secondary'
                        },
                        reward: {
                            textColor: getColorContrast(colors, 'primary')
                        }
                    },
                    toprewards: {
                        bgColor: 'transparent',
                        indications: {
                            textColor: 'secondary'
                        },
                        reward: {
                            textColor: getColorContrast(colors, 'primary')
                        }
                    },
                    headline: {
                        text: 'indique amigos e ganhe prêmios',
                        textColor: getColorContrast(colors, 'primary')
                    },
                    subtitle: {
                        text: 'indique amigos e ganhe prêmios',
                        textColor: getColorContrast(colors, 'primary')
                    }
                },
                headline: {
                    text: 'indique amigos e ganhe prêmios',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'indique amigos e ganhe prêmios',
                    textColor: getColorContrast(colors, 'primary')
                },
                content: {
                    text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seusamigos e colegas de trabalho para participar com você e serrecompensado por isso! Confira:',
                    textColor: getColorContrast(colors, 'primary')
                },
                extratext: {
                    text: 'você pode convidar seus amigos e colegas de trabalho para participar com você',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    }
}
