import React from 'react'
import { ApolloProvider } from '@apollo/client'

import client from '@cms/core/graphql/client'

function GraphqlProvider({ children }) {
    return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GraphqlProvider
