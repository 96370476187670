import React from 'react'
import { Form } from 'antd'

import Card from '@cms/core/components/Card'
import { InputTranslation } from '@cms/core/components/InputTranslation'

const BaseConfig = (props) => {
    const { children } = props

    return (
        <>
            <Card title="Geral">
                <>
                    <Form.Item
                        name={[props.name, 'config', 'title']}
                        label="Título"
                    >
                        <InputTranslation />
                    </Form.Item>
                    <Form.Item
                        name={[props.name, 'config', 'description']}
                        label="Descrição"
                    >
                        <InputTranslation mode="textArea" />
                    </Form.Item>
                </>
                {children}
            </Card>
        </>
    )
}

export default BaseConfig
