import React from 'react'
import { Checkbox } from 'antd'

const { Group } = Checkbox

const CheckboxPreview = ({ options }) => {
    return <Group options={options} />
}

export default CheckboxPreview
