import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_LOCATION = gql`
    mutation CreateLocation($name: String, $event: ObjectID!) {
        createLocation(
            input: { name: $name, event: $event }
        ) {
            ...LocationData
        }
    }

    ${fragments.LocationData}
`

export const UPDATE_LOCATION = gql`
    mutation UpdateLocation(
        $id: ObjectID!
        $name: String
        $event: ObjectID!
    ) {
        updateLocation(
            id: $id
            input: { name: $name, event: $event }
        ) {
            ...LocationData
        }
    }

    ${fragments.LocationData}
`

export const DELETE_LOCATION = gql`
    mutation DeleteLocation($id: ObjectID!) {
        deleteLocation(id: $id) {
            id
            success
        }
    }
`