import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_CATEGORY = gql`
    mutation CreateCategory(
        $event: ObjectID!
        $brands: [ObjectID!]
        $name: String
        $type: CategoryType
        $description: String
        $summary: String
        $icon: String
    ) {
        createCategory(
            input: {
                event: $event
                brands: $brands
                name: $name
                type: $type
                description: $description
                summary: $summary
                icon: $icon
            }
        ) {
            ...CategoryData
        }
    }

    ${fragments.CategoryData}
`

export const UPDATE_CATEGORY = gql`
    mutation UpdateCategory(
        $id: ObjectID!
        $event: ObjectID!
        $brands: [ObjectID!]
        $name: String
        $type: CategoryType
        $description: String
        $summary: String
        $icon: String
    ) {
        updateCategory(
            id: $id
            input: {
                event: $event
                brands: $brands
                name: $name
                type: $type
                description: $description
                summary: $summary
                icon: $icon
            }
        ) {
            ...CategoryData
        }
    }

    ${fragments.CategoryData}
`

export const DELETE_CATEGORY = gql`
    mutation DeleteCategory($id: ObjectID!) {
        deleteCategory(id: $id) {
            id
            success
        }
    }
`
