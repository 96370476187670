import React from 'react'
import { Button, Popconfirm } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

const ButtonConfirm = ({
    id,
    action,
    title = undefined,
    text = undefined,
    disableFor = undefined,
    danger = true,
    ...rest
}) => {
    if (disableFor === id) return null

    return (
        <Popconfirm
            title={title || 'Tem certeza que deseja deletar?'}
            onConfirm={() => action && action({ variables: { id } })}
            okText="Sim"
            cancelText="Não"
        >
            <Button type="primary" danger={danger} {...rest}>
                {text ? text : <DeleteOutlined />}
            </Button>
        </Popconfirm>
    )
}

export default ButtonConfirm
