import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import { Checkbox, Row, Tag } from 'antd'

import {
    CrudColumns,
    CrudLinks,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import SpeakersModal from './SpeakersModal'

import { GET_SPEAKER_LIST_ORDERABLE } from '@cms/events/graphql/queries/speaker'
import { DELETE_SPEAKER } from '@cms/events/graphql/mutations/speaker'
import { removeFromList } from '@cms/core/graphql/utils'

import dayjs from 'dayjs'
import { QUERY_FILTER_OPTIONS_VALUE } from '@cms/core/components/Crud/CrudList/CrudListFilter/FilterFields/options'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'firstName'],
        sorter: 'firstName',
        width: 350,
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.firstName + ' ' + (obj.node.lastName || '')}
                    text={[obj.node.office]}
                />
            )
        }
    },
    {
        title: 'Empresa',
        dataIndex: '',
        // sorter: 'company.name',
        width: 250,
        render: (value, obj) => {
            return (
                <>
                    {obj.node.company.name ? (
                        <CrudColumns
                            image={obj.node.company?.image?.url}
                            title={obj.node.company.name}
                        />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    },
    {
        title: 'Tags',
        dataIndex: ['node', 'tags'],
        render: (tags) => {
            return (
                <Row>
                    {tags?.map((tag) => (
                        <Tag key={tag} color="#7F44FF">
                            {tag}
                        </Tag>
                    ))}
                </Row>
            )
        }
    },
    {
        title: 'Prioridade',
        dataIndex: ['node', 'priority'],
        sorter: 'priority',
        render: (priority) => {
            return (
                <Row>
                    <p>{priority} </p>
                </Row>
            )
        }
    },
    {
        title: 'Links',
        dataIndex: '',
        render: (value, obj) => {
            return (
                <>
                    {obj.node.links ? (
                        <CrudLinks links={obj.node.links} />
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    },
    {
        title: 'Data de criação',
        sorter: 'createdAt',
        dataIndex: ['node', 'createdAt'],
        render: (value, obj) => {
            return (
                <>
                    {obj.node.createdAt ? (
                        <p>{dayjs(value).format('DD/MM/YYYY [-] H:mm')} </p>
                    ) : (
                        '---'
                    )}
                </>
            )
        }
    }
]

const SpeakersList = () => {
    const { eventId } = useParams()

    const query = useQuery(GET_SPEAKER_LIST_ORDERABLE, {
        variables: { first: 25, eventId, orderBy: { firstName: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_SPEAKER, {
        update: removeFromList({ list: 'speakers', Type: 'Speaker' })
    })

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        {
            name: 'company',
            label: 'Empresa',
            queryType: 'Filter',
            options: QUERY_FILTER_OPTIONS_VALUE.brands
        }
    ]

    if (query.error) return <div>Error</div>

    const path = `/evento/${eventId}/palestrantes`

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(SpeakersModal, path, { eventId })
    }, [eventId])

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Palestrantes',
                    subTitle: 'Adicione e gerencie os palestrantes',
                    countNames: {
                        singular: 'Palestrante',
                        plural: 'Palestrantes'
                    },
                    buttons: [
                        {
                            children: 'Novo Palestrante',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'speakers'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default SpeakersList

