import React from 'react'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { Typography, Row } from 'antd'
import { CrudList, CrudColumns } from '@cms/core/components/Crud'
import { GET_TALK_LIST_TOP_TEN } from '@cms/events/graphql/queries/talk'

import { RocketOutlined, EyeOutlined } from '@ant-design/icons'
import {
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'

const TalkTopTen = function () {
    const { eventId } = useParams()
    const { Text } = Typography

    const query = useQuery(GET_TALK_LIST_TOP_TEN, {
        variables: {
            first: 10,
            eventId,
            orderBy: { reports: { views: 'DESC' } }
        }
    })

    const columns = [
        {
            title: 'Palestras',
            dataIndex: [''],
            render: (_value, obj, index) => {
                const title = obj.node.title || '---'
                const views = obj.node?.reports?.views || '---'
                return (
                    <CrudColumns
                        counterSize={25}
                        counter={index + 1}
                        title={title}
                        text={[
                            `${
                                obj.node.categories?.edges.length
                                    ? `${obj.node.categories.edges[0].node.name} - `
                                    : '---'
                            } 👁️ ${views} views`
                        ]}
                    />
                )
            }
        }
    ]

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <RocketOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Top 10 Palestras</DashboardTitle>
            </DashboardCardTitle>

            <CrudList
                header={{}}
                columns={columns}
                queryName={'talks'}
                hideAction={'all'}
                showHeader={false}
                transparent
                {...query}
            />
        </>
    )
}

export default TalkTopTen
