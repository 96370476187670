import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Form, Select, Switch } from 'antd'

import SelectSearch from '@cms/core/components/SelectSearch'
import { useCrudSelect } from '@cms/core/components/Crud'

import { GET_BRAND_LIST } from '@cms/events/graphql/queries/brand'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

import { ContainerConfig, ButtonConfig, FormConfig } from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'

const Header = (props) => {
    const { form } = props

    const selectRef = useRef()
    const { eventId } = useParams()

    const queryBrands = useQuery(GET_BRAND_LIST, {
        variables: { first: 9999, eventId }
    })

    const [brandsOptions, brandsSelectOptions] = useCrudSelect(queryBrands, {
        queryName: 'brands'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Logo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'logoActive']}
                    initialValue={'checked'}
                    valuePropName="checked"
                    label="Exibir Logo"
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                    />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Data do Evento</Title>
                <Form.Item
                    name={[props.name, 'configs', 'date', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Realizadores</Title>
                <Form.Item
                    name={[props.name, 'configs', 'owners', 'title', 'text']}
                    label="Título"
                >
                    <RichTextEditor />
                </Form.Item>
                <Form.Item
                    label="Patrocinadores (limite: 4)"
                    name={[props.name, 'configs', 'owners', 'brands']}
                    rules={[
                        {
                            validator: (rule, value) => {
                                // if (value) {
                                //     if (value.length > 4) {
                                //         value.pop()
                                //         const values = form.getFieldsValue()
                                //         form.resetFields([
                                //             `${props.name}.configs.owners.brands`
                                //         ])
                                //         form.setFieldsValue(
                                //             _set(
                                //                 values,
                                //                 `${props.name}.configs.owners.brands`,
                                //                 value
                                //             )
                                //         )
                                //         selectRef.current.blur()
                                //         message.error(
                                //             'Limite máximo de 4 patrocinadores'
                                //         )
                                //         return Promise.reject(
                                //             'Limite máximo de 4 patrocinadores'
                                //         )
                                //     }
                                // }
                                return Promise.resolve()
                            }
                        }
                    ]}
                >
                    <SelectSearch ref={selectRef} {...brandsSelectOptions}>
                        {brandsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'owners', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>
            {/* <Wrapper>
                <Title level={4}>Bagde</Title>
                <Form.Item
                    name={[props.name, 'configs', 'badge', 'bgColor']}
                    label="Cor da badge"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper> */}

            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />

            <FormConfig
                fieldPath={[props.name, 'configs', 'form']}
                path="sections"
            />

            <Wrapper>
                <Title level={4}>Login</Title>
                <Form.Item
                    name={[props.name, 'configs', 'form', 'socialAuth']}
                    label="Habilitar login via rede social"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Header
