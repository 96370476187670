import React from 'react'
import { Divider, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { HiddenArea } from './styles'

const AddLink = (props) => {
    const { add, index } = props

    const addLink = () => {
        const newLink = {
            name: `Link #${index}`,
            active: true
        }
        add(newLink)
    }

    if (props.emptyLinks) {
        return (
            <>
                <Button type="primary" onClick={addLink}>
                    Adicionar
                </Button>
            </>
        )
    }

    return (
        <>
            <HiddenArea>
                <Divider plain dashed className="divider" onClick={addLink}>
                    {<PlusOutlined />}
                </Divider>
            </HiddenArea>
        </>
    )
}

export default AddLink
