import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_COUNT_TOTAL_MESSAGE } from '@cms/events/graphql/queries/blocks/totals'

import Card from '../CardInner'

const TotalMessages = ({ eventId, category, talk, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_COUNT_TOTAL_MESSAGE, {
        variables: { eventId: eventId, category, talk }
    })

    if (loading || error) return null

    return (
        <>
            <Card
                title={'Total de mensagens'}
                reverse={reverse}
                data={data?.countMessages ? data.countMessages : 0}
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalMessages
