import React from 'react'

import { Row, Col } from 'antd'

import SponsorsViews from './SponsorsViews'
import InitiatedChats from './InitiatedChats'
import CTA from './CTA'
import SponsorsTotal from './SponsorsTotal'
import ResourcesDownloads from './ResourcesDownloads'

const SocialMetrics = ({ eventId }) => {
    return (
        <Row gutter={[24, 24]}>
            <Col>
                <SponsorsTotal eventId={eventId} full={false} />
            </Col>

            <Col>
                <CTA eventId={eventId} full={false} />
            </Col>

            <Col>
                <SponsorsViews eventId={eventId} full={false} />
            </Col>

            <Col>
                <InitiatedChats eventId={eventId} full={false} />
            </Col>

            <Col>
                <ResourcesDownloads eventId={eventId} full={false} />
            </Col>
        </Row>
    )
}

export default SocialMetrics
