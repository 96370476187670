import AntMenu from 'antd/es/menu/menu'
import styled, { css } from 'styled-components'
import { menuSettings, menuColors } from './settings'
import { COLORS } from '@cms/core/themes'
import { transparentize } from 'polished'

export const menuCss = css`
    .more-menu {
        left: ${menuSettings.itemWidth}px !important;

        [id*='more-popup'] {
            position: fixed;
            z-index: 9999;
            width: 220px;
            top: 64px;
            height: calc(100vh - 104px);
            max-height: calc(100vh - 104px);
            border-radius: 0 !important;
            padding: 10px;
            box-shadow: none;

            li {
                border-radius: ${menuSettings.borderRadius}px;

                &.ant-menu-item-selected {
                    border-radius: 6px;
                }
            }
        }
    }

    .ant-menu-inline-collapsed-tooltip {
        left: 55px !important;
        padding-left: 0 !important;
    }
`

export const SidebarItemContent = styled.div`
    flex: 1;
    .ant-menu {
        padding: 4px 2px;

        &.second-menu {
            border-radius: 8px 8px 0 0;
        }
    }
    &.first-item-selected {
        .second-menu {
            border-radius: 0 8px 0 0;
        }
    }
`

export const SidebarItemMain = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    max-height: calc(100vh - 104px);
    max-width: ${menuSettings.itemWidth}px;
`

export const RootMenu = styled(AntMenu)`
    &.ant-menu {
        border-right: none;
        position: relative;

        &.second-menu {
            height: 100%;
        }

        .menu-locked {
            svg {
                fill: gold;
            }
        }

        &.ant-menu-inline-collapsed {
            width: ${menuSettings.itemWidth}px;

            svg {
                fill: currentColor;
            }

            .ant-menu-title-content {
                display: none;
            }
        }
    }
`

export const StyledMainMenu = styled(RootMenu)`
    &.ant-menu-inline-collapsed {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-left: 1px;
        padding: 0 !important;

        svg {
            vertical-align: inherit;
            width: 20px;
            height: 20px;
        }

        > .ant-menu-item,
        > .ant-menu-submenu {
            position: relative;
            width: 100%;
            min-height: ${menuSettings.itemHeight}px;
            max-height: ${menuSettings.itemHeight}px;
            justify-content: center;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: 0.2s;
            z-index: 3;

            .ant-menu-item-icon,
            .ant-menu-item-icon span {
                margin-left: 0px;
                line-height: 0px !important;
            }
            > .ant-menu-submenu-title {
                border-radius: ${menuSettings.borderRadius}px 0 0
                    ${menuSettings.borderRadius}px;

                .anticon {
                    margin-left: 8px;
                }
            }

            background: #2f2f38;
            border-radius: 0;
            &::before {
                content: '';
                position: absolute;
                left: 0px;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                background: #0f0f18;
                border-radius: ${menuSettings.borderRadius}px 0 0
                    ${menuSettings.borderRadius}px 0 !important;
            }
            * {
                z-index: 2;
            }
        }

        .ant-menu-item:has(+ .ant-menu-item-selected) {
            &::before {
                border-radius: 0 0 10px 0 !important;
            }
        }
        .ant-menu-item-selected + .ant-menu-item {
            &::before {
                border-radius: 0 10px 0 0 !important;
            }
        }
        .ant-menu-item-selected {
            min-height: ${menuSettings.itemWidth}px;
            max-height: ${menuSettings.itemWidth}px;
        }
        .ant-menu-item-selected,
        .ant-menu-submenu-selected {
            border-radius: ${menuSettings.borderRadius}px 0 0
                ${menuSettings.borderRadius}px !important;

            &::before {
                content: '';
                position: absolute;
                left: 0px;
                top: 50%;
                bottom: 0px;
                width: 4px;
                height: 20px;
                border-radius: 60.923px;
                background: #7c3eff;
                box-shadow: 2.538px 0px 12.692px 0px #7c3eff,
                    15.231px 0px 25.385px 0px #7c3eff;
                transform: translateY(-50%);
            }
        }

        .ant-menu-item {
            margin: 0 !important;
        }

        .ant-menu-title-content {
            display: none !important;
        }

        > .ant-menu-submenu {
            > .ant-menu-submenu-title {
                width: 100%;
                padding-inline: 0;

                > .anticon {
                    margin-left: 0px;
                }
            }
        }
    }
`

export const StyledFooterMenu = styled(RootMenu)`
    max-width: ${menuSettings.itemWidth}px;
    border-radius: 0;
    bottom: 0;
    padding: 0;
    border-top: 1px solid ${COLORS.divider};

    .ant-menu-item {
        width: 100%;
        min-height: ${menuSettings.itemWidth}px;
        max-height: ${menuSettings.itemWidth}px;
        justify-content: center;
        border-radius: ${menuSettings.borderRadius}px 0 0
            ${menuSettings.borderRadius}px !important;
        margin: 0;
        justify-content: center;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .ant-menu-icon {
            margin-left: -10px !important;
        }

        &.ant-menu-item-selected {
            &::before {
                border-right: 3px solid ${menuColors.active};
                content: '';
                position: absolute;
                left: 0px;
                top: 0px;
                bottom: 0px;
                width: 1px;
                height: 50%;
                border-radius: 3px;
                transform: translate3d(0px, 50%, 0px);
                box-shadow: 0px 0px 10px 4px
                    ${transparentize(0.4, menuColors.active)};
            }
        }
    }

    .ant-menu-title-content {
        display: none !important;
    }
`

export const SidebarListContent = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0px;
    position: relative;
    flex: 1;
`

