import styled from 'styled-components'
import { Layout } from 'antd'
import { ContentDefault } from '../Content/styles'

const { Content } = Layout

export const MainLayout = styled(Layout)`
    min-height: 100vh;
    overflow: hidden;
`

export const MainLayoutInner = styled(Layout)`
    flex-direction: row;
    max-height: calc(100vh - 104px);
`

interface MainContentProps {
    $hasScrollbar: boolean
}

export const MainContent = styled(Content)<MainContentProps>`
    overflow-y: auto;
    overflow-x: hidden;
    ${ContentDefault} {
        ${({ $hasScrollbar }) => $hasScrollbar && 'margin-right: 10px;'}
    }
`
