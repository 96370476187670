import Image from './Nps'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import SideImage from './SideImage'
import CenteredImage from './CenteredImage'

export const Images = {
    Nps: {
        Component: Image,
        name: 'Imagens',
        category: 'Imagens',
        columns: '2',
        thumb: require('./nps.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    SideImage: {
        Component: SideImage,
        name: 'Imagem ao lado',
        category: 'Imagens',
        columns: '2',
        thumb: require('./SideImage.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    CenteredImage: {
        Component: CenteredImage,
        name: 'Imagem - centro',
        category: 'Imagens',
        columns: '1',
        thumb: require('./CenteredImage.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
