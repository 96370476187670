import { merge, isObject, omit } from 'lodash'
import dayjs from 'dayjs'

export const stringFormat = (text, ...args) => {
    return text.replace(/{(\d+)}/g, (match, number) => {
        return typeof args[number] !== 'undefined' ? args[number] : match
    })
}

export const getColor = (
    color,
    gradientTpl = 'linear-gradient(226.22deg, {1} -4.83%, {0} 105.47%)'
) => {
    if (!color.includes(';')) return color
    const colors = color.split(';')
    return stringFormat(gradientTpl, ...colors)
}

export const colorsToForm = (data) => {
    const { colors } = data.theme

    if (!isObject(colors)) return data

    const _colors = Object.keys(colors).map((key) => {
        if (colors[key] && colors[key].includes(';')) {
            const colorsArray = colors[key].split(';')

            return {
                type: key,
                value: colorsArray[0],
                value2: colorsArray[1],
                gradient: true
            }
        } else {
            return {
                type: key,
                value: colors[key],
                gradient: false,
                value2: colors[key]
            }
        }
    })

    let _data = { ...data, theme: { ...data.theme, colors: _colors } }

    return _data
}

export const colorsToBuilder = (data) => {
    const { colors } = data?.theme ? data.theme : data
    const _colors = {}

    colors.map((item) => {
        const { value, value2, type, gradient } = item

        if (gradient) {
            const values = `${value};${value2}`
            _colors[type] = values
        } else {
            _colors[type] = value
        }
    })

    let _data = { ...data, theme: { ...data.theme, colors: _colors } }

    return _data
}

export const parseDate = (date) => {
    return dayjs(new Date(date))
}
