import { useQuery } from '@apollo/client'
import { GET_COUNT_TOTAL_TALKS } from '@cms/events/graphql/queries/blocks/totals'
import React from 'react'
import { TalksInfos } from '../card'

const DisplayTime = ({ eventId, talk }) => {
    const { loading, error, data } = useQuery(GET_COUNT_TOTAL_TALKS, {
        variables: { event: eventId, talk }
    })

    if (loading || error) return null

    return (
        <TalksInfos
            title={
                data.talksViewTimeAverage.humanizedAverage
                    ? data.talksViewTimeAverage.humanizedAverage.split(':')[0] +
                      ' min'
                    : '0'
            }
            subtitle="Tempo médio"
        />
    )
}

export default DisplayTime

