import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_TICKET_OWNERSHIPS_LIST } from '@cms/events/graphql/queries/tickets'
import { Button, Card, Col, Divider, Layout, Row, Typography } from 'antd'
import { TicketCard } from './TicketCard/TicketCard'
import Content from '@cms/core/components/Content'
import { ButtonLink, ButtonReturn } from '@cms/events/components/Buttons'
import Header from '@cms/events/components/Lead/Header'
import { ContentContainer } from './styles'
import Loader from '@cms/core/components/Loader'
import Activity from '@cms/events/components/Lead/Activity'
import { useParams } from 'react-router-dom'
import { GET_SUBSCRIPTION_DETAIL } from '@cms/events/graphql/queries/subscriptions'
import { CrudColumns } from '@cms/core/components/Crud'

const { Text, Title } = Typography

export function ModalContent({ params }) {
    const { id, userId } = useParams()
    const { eventId } = params

    const { data, loading } = useQuery(GET_TICKET_OWNERSHIPS_LIST, {
        variables: { eventId: eventId, userId: userId }
    })

    const { data: dataUser, loading: dataUserLoading } = useQuery(
        GET_SUBSCRIPTION_DETAIL,
        {
            variables: { id }
        }
    )

    if (!data || !dataUser || loading || dataUserLoading) {
        return <Loader padding="10px" />
    }

    const { user } = dataUser?.eventRegistration

    return (
        <Layout
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Content
                style={{
                    padding: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    marginBottom: '0',
                    marginTop: '120px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                    }}
                >
                    <Row align="middle" justify="start">
                        <ButtonReturn
                            id="ButtonReturn"
                            path={`/evento/${eventId}/inscricoes`}
                        />
                        <Text
                            style={{
                                marginLeft: '20px',
                                fontWeight: 'bold'
                            }}
                        >
                            Ingressos vinculados
                        </Text>
                    </Row>

                    <Card bodyStyle={{ padding: 0 }} bordered={false}>
                        <Header>
                            <Row className="widthFull" align={'middle'}>
                                <Col span={18}>
                                    <Row>
                                        <CrudColumns
                                            avatar={{
                                                size: 80
                                            }}
                                            image={user?.avatar?.url}
                                        />
                                        <Col className="infoJob">
                                            <Title
                                                id="userName"
                                                style={{ marginTop: 0 }}
                                            >
                                                {user.name}
                                            </Title>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row justify={'space-evenly'}>
                                        <ButtonLink
                                            to={`${userId}/add-ticket`}
                                            key={id}
                                            text={'Vincular ingresso'}
                                        />
                                    </Row>
                                </Col>
                            </Row>
                        </Header>
                    </Card>
                    <ContentContainer>
                        <Card bodyStyle={{ padding: 0 }} bordered={false}>
                            <Activity>
                                <Title>Ingressos do inscrito</Title>
                                <Divider />
                                <Row gutter={[16, 16]}>
                                    {data?.ticketOwnerships?.edges?.map(
                                        (ticket, i) => (
                                            <Col span={8}>
                                                <TicketCard
                                                    ticket={ticket.node}
                                                    path
                                                    isMain={i === 0}
                                                />
                                            </Col>
                                        )
                                    )}
                                </Row>
                            </Activity>
                        </Card>
                    </ContentContainer>
                </div>
            </Content>
        </Layout>
    )
}
