import { Row } from 'antd'
import React from 'react'

const Section = ({ label }) => {
    return (
        <Row gutter={[24, 24]}>
            <h1 style={{ marginLeft: '12px', marginRight: '12px' }}>{label}</h1>
        </Row>
    )
}

export default Section
