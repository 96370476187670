import styled from 'styled-components'
import { Radio as AntRadio } from 'antd'

const { Group } = AntRadio

export const Badge = styled.div`
    height: 50px;
    width: 50px;
    background: ${({ background }) => background};
    border-radius: 100px;
    border: 2px solid #fff;
    position: relative;
`
export const Checkbox = styled(Group)`
    .ant-radio {
        display: none;
    }

    .ant-radio-wrapper {
        margin-right: 10px;
    }

    .ant-radio-wrapper-checked {
        ${Badge} {
            /* outline: 2px solid #7730f6; */
            ::before {
                background: none;
                opacity: 0.5;
                border: 3px solid #fff;
                content: '';
                display: block;
                position: absolute;
                pointer-events: none;
                border-radius: 100%;
                height: 60px;
                width: 60px;
                transform: translate(-7px, -7px);
            }
        }
    }
`
