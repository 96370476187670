import React, { useRef } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Form, Select, Switch } from 'antd'

import { useCrudSelect } from '@cms/core/components/Crud'

import { GET_BRAND_LIST } from '@cms/events/graphql/queries/brand'

import { ContainerConfig, ButtonConfig } from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'

const Header = (props) => {
    const selectRef = useRef()
    const { eventId } = useParams()

    const queryBrands = useQuery(GET_BRAND_LIST, {
        variables: { first: 9999, eventId }
    })

    const [brandsOptions, brandsSelectOptions] = useCrudSelect(queryBrands, {
        queryName: 'brands'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Exibir imagem destaque</Title>
                <Form.Item
                    name={[props.name, 'configs', 'imageActive']}
                    initialValue={'checked'}
                    valuePropName="checked"
                >
                    <Switch
                        checkedChildren={<EyeOutlined />}
                        unCheckedChildren={<EyeInvisibleOutlined />}
                    />
                </Form.Item>
            </Wrapper>
            
            <Wrapper>
                <Title level={4}>Data do Evento</Title>
                <Form.Item
                    name={[props.name, 'configs', 'date', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Local do evento</Title>
                <Form.Item
                    name={[props.name, 'configs', 'address', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>


            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'modelName', 'text']}
                    label="Nome"
                >
                    <RichTextEditor />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'smallText', 'text']}
                    label="Texto pequeno"
                >
                    <RichTextEditor />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'model', 'logo', 'color']}
                    label="Cor do logo"
                >
                    <Select>
                        <Select.Option value="default">Original</Select.Option>
                        <Select.Option value="white">Branco</Select.Option>
                        <Select.Option value="black">Preto</Select.Option>
                    </Select>
                </Form.Item>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default Header
