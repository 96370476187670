import React, { useEffect } from 'react'
import { Col, Form, Row } from 'antd'
import { useQuery } from '@apollo/client'

import Loader from '@cms/core/components/Loader'

import BodyValues from './BodyValues'
import { ConversionIntegrationContainer } from './styles'
import { GET_ZOHO_CRM_INTEGRATION_DETAILS } from '@cms/core/graphql/queries/integrations'
import SelectSearch from '@cms/core/components/SelectSearch'

const variables = {
    Email: '{{email}}',
    First_Name: '{{firstName}}',
    Last_Name: '{{lastName}}'
}

const ZohoStep2 = ({ form, integrationId }) => {
    const { loading, error, data } = useQuery(
        GET_ZOHO_CRM_INTEGRATION_DETAILS,
        {
            variables: {
                integrationId
            }
        }
    )

    useEffect(() => {
        const fetchAll = async () => {
            const defaultFieldsMappings = form.getFieldValue(['config', 'body'])

            const fieldsMapping = [
                defaultFieldsMappings ? [...defaultFieldsMappings] : [],
                ...data.zohoCRMIntegrationFields
                    .filter((contact) => !contact.optional)
                    .map((contact) => ({
                        name: contact.id,
                        value: variables[contact.id] || ''
                    }))
            ].flat()

            const fieldsMappingMap = [
                ...new Map(
                    fieldsMapping.map((item) => [item.name, item])
                ).values()
            ]

            form.setFieldValue('config', {
                campaign: form.getFieldValue(['config', 'campaign']),
                body: fieldsMappingMap
            })
        }

        integrationId && data && fetchAll()
    }, [integrationId, data])

    if (loading) return <Loader fixed={false} padding="10px" />

    if (error) return <></>

    return (
        <ConversionIntegrationContainer>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name={['config', 'campaign', 'id']}
                        label="Campanha"
                        rules={[
                            { required: true, message: 'Campo obrigatório' }
                        ]}
                    >
                        <SelectSearch
                            mode="single"
                            options={data?.zohoCRMIntegrationCampaigns?.map(
                                (m) => ({
                                    label: m.name,
                                    value: m.id
                                })
                            )}
                            onChange={(value) => {
                                const campaign =
                                    data?.zohoCRMIntegrationCampaigns?.find(
                                        (o) => o.id === value
                                    )

                                form.setFieldValue(
                                    ['config', 'campaign', 'name'],
                                    campaign?.name
                                )
                            }}
                        />
                    </Form.Item>
                    <Form.Item name={['config', 'campaign', 'name']} noStyle>
                        <input type="hidden" />
                    </Form.Item>
                </Col>
                <BodyValues
                    formName={['config', 'body']}
                    nameOptions={data?.zohoCRMIntegrationFields.map((c) => ({
                        label: c.name,
                        value: c.id
                    }))}
                    removeActionsIn={data?.zohoCRMIntegrationFields
                        ?.filter((contact) => !contact.optional)
                        .map((contact, index) => index)}
                />
            </Row>
        </ConversionIntegrationContainer>
    )
}

export default ZohoStep2
