import { Col, Row } from 'antd'
import styled from 'styled-components'

export const WrapperCol = styled(Col)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    padding: 10px;
    flex-direction: column;
`

export const WrapperRow = styled(Row)`
    min-width: 100%;
    justify-content: center;
    min-height: 50px;
`