import styled from 'styled-components'
import { Row as AntRow } from 'antd'
import { COLORS } from '@cms/core/themes'

export const Social = styled(AntRow)`
    align-items: center;
    max-width: 500px;
    margin-top: 20px;
    padding: 13px 30px;
    font-size: 20px;
    border-radius: 20px 20px 0px 0px;
    background: transparent;
    border: 1px solid ${COLORS.textDark};

    .ant-form-item {
        margin-bottom: 0;
    }
`
export const SocialRow = styled(AntRow)`
    align-items: flex-start;
    max-width: 500px;
    background: transparent;
    padding: 30px;
    border-radius: 0px 0px 20px 20px;
    border: 1px solid ${COLORS.textDark};
    border-top: none;

    .ant-form-item {
        margin-bottom: 0;
        width: 100%;
    }
    input {
        width: 100%;
    }
`

