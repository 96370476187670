import Sponsor from './Sponsor'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Sponsors = {
    Sponsor: {
        Component: Sponsor,
        name: 'Patrocinadores',
        category: 'Patrocinadores',
        thumb: require('./sponsors.png'),
        columns: '1',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                box: {
                    bgColor: 'white',
                    logo: {
                        color: 'black'
                    }
                },
                headline: {
                    text: `Quem patrocina o ${event.name}`,
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seus amigos',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    text: 'Inscreva-se gratuitamente',
                    bgColor: 'primary',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
