import React from 'react'

import { useQuery } from '@apollo/client'

import {
    GET_BRANDS_VIEWS_TOTAL,
    GET_BRANDS_CONTACT_TOTAL
} from '@cms/events/graphql/queries/brand'

import { Col } from 'antd'

import { StyledRow, TextNumber, Description } from './styles'

const CardMetric = ({ text, metric, eventId, brandId }) => {
    const getQueryByMetric = () => {
        if (metric === 'brandViewsTotal') return GET_BRANDS_VIEWS_TOTAL
        if (metric === 'brandContactTotal') return GET_BRANDS_CONTACT_TOTAL
    }

    const { loading, error, data } = useQuery(getQueryByMetric(), {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    return (
        <StyledRow>
            <Col>
                <TextNumber style={{ color: '#fff' }}>
                    {data[metric] || 0}
                </TextNumber>
            </Col>
            <Col>
                <Description>{text}</Description>
            </Col>
        </StyledRow>
    )
}

export default CardMetric
