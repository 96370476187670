const { html } = require('../../utils')

const getMediaUrl = (media) => {
    if (media.url) {
        return media.url
    }
    return media
}

const template = (data) => {
    return html`
        <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">

        <html xmlns="http://www.w3.org/1999/xhtml">
            <head>
                <meta
                    http-equiv="Content-Type"
                    content="text/html; charset=utf-8"
                />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>${data.subject}</title>
                <style type="text/css">
                    #outlook a {
                        padding: 0;
                    }
                    body {
                        width: 100% !important;
                        -webkit-text-size-adjust: 100%;
                        -ms-text-size-adjust: 100%;
                        margin: 0;
                        padding: 0;
                    }
                    .ExternalClass {
                        width: 100%;
                    }
                    .ExternalClass,
                    .ExternalClass p,
                    .ExternalClass span,
                    .ExternalClass font,
                    .ExternalClass td,
                    .ExternalClass div {
                        line-height: 100%;
                    }
                    #backgroundTable {
                        margin: 0;
                        padding: 0;
                        width: 100% !important;
                        line-height: 100% !important;
                    }
                    img {
                        outline: none;
                        text-decoration: none;
                        -ms-interpolation-mode: bicubic;
                    }
                    a img {
                        border: none;
                    }
                    .image_fix {
                        display: block;
                    }
                    p {
                        margin: 1em 0;
                    }
                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6 {
                        color: rgba(0, 0, 0, 0.87) !important;
                    }
                    h1 a,
                    h2 a,
                    h3 a,
                    h4 a,
                    h5 a,
                    h6 a {
                        color: blue !important;
                    }
                    h1 a:active,
                    h2 a:active,
                    h3 a:active,
                    h4 a:active,
                    h5 a:active,
                    h6 a:active {
                        color: red !important;
                    }
                    h1 a:visited,
                    h2 a:visited,
                    h3 a:visited,
                    h4 a:visited,
                    h5 a:visited,
                    h6 a:visited {
                        color: purple !important;
                    }
                    table td {
                        border-collapse: collapse;
                    }
                    table {
                        border-collapse: collapse;
                        mso-table-lspace: 0pt;
                        mso-table-rspace: 0pt;
                        font-family: sans-serif;
                    }
                    @media only screen and (max-device-width: 480px) {
                        a[href^='tel'],
                        a[href^='sms'] {
                            text-decoration: none;
                            color: black; /* or whatever your want */
                            pointer-events: none;
                            cursor: default;
                        }
                        .mobile_link a[href^='tel'],
                        .mobile_link a[href^='sms'] {
                            text-decoration: default;
                            color: orange !important; /* or whatever your want */
                            pointer-events: auto;
                            cursor: default;
                        }
                    }
                    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                        a[href^='tel'],
                        a[href^='sms'] {
                            text-decoration: none;
                            color: blue;
                            pointer-events: none;
                            cursor: default;
                        }
                        .mobile_link a[href^='tel'],
                        .mobile_link a[href^='sms'] {
                            text-decoration: default;
                            color: orange !important;
                            pointer-events: auto;
                            cursor: default;
                        }
                    }
                    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
                        /* Put your iPhone 4g styles in here */
                    }
                    @media only screen and (-webkit-device-pixel-ratio: 0.75) {
                        /* Put CSS for low density (ldpi) Android layouts in here */
                    }
                    @media only screen and (-webkit-device-pixel-ratio: 1) {
                        /* Put CSS for medium density (mdpi) Android layouts in here */
                    }
                    @media only screen and (-webkit-device-pixel-ratio: 1.5) {
                        /* Put CSS for high density (hdpi) Android layouts in here */
                    }
                </style>
                <!--[if IEMobile 7]>
                    <style type="text/css">
                        /* Targeting Windows Mobile */
                    </style>
                <![endif]-->
                <!--[if gte mso 9]>
                    <style>
                        /* Target Outlook 2007 and 2010 */
                    </style>
                <![endif]-->
            </head>
            <body>
                <table
                    id="backgroundTable"
                    style="background-color:#f2f2f5;"
                    width="100%"
                    border="0"
                    cellpadding="0"
                    cellspacing="0"
                    align="center"
                    class="full"
                    object="drag-module"
                >
                    <tbody>
                        <tr>
                            <td>
                                <table
                                    width="600"
                                    cellpadding="0"
                                    cellspacing="0"
                                    border="0"
                                    align="center"
                                    style="background-color:#fff ;margin-inline-start: auto; margin-inline-end: auto; margin-top: 16px; margin-bottom: 16px;"
                                >
                                    <tr>
                                        <td>
                                            <table
                                                width="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                border="0"
                                            >
                                                <tr>
                                                    <td
                                                        valign="bottom"
                                                        width="100%"
                                                        style="background: #232323;padding: 16px;text-align: center;"
                                                    >
                                                        ${data.logo.url
                                                            ? html`
                                                                  <img
                                                                      src="https://widgets.app.td/api/svgto.png?url=${data
                                                                          .logo
                                                                          .url}"
                                                                      style="max-height: 55px; max-width: 150px;"
                                                                      alt="${data
                                                                          .logo
                                                                          .alt}"
                                                                  />
                                                              `
                                                            : html`
                                                                  <h1
                                                                      style="color: #fff !important;font-size: 24px;font-weight: bold;line-height: 24px;margin: 0;padding: 0;"
                                                                  >
                                                                      ${data
                                                                          .logo
                                                                          .alt}
                                                                  </h1>
                                                              `}
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table
                                                width="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                border="0"
                                            >
                                                <tr>
                                                    <td
                                                        valign="bottom"
                                                        width="100%"
                                                        style="padding: 16px 16px 8px;text-align: left;font-size: 16px;line-height: 24px;"
                                                    >
                                                        <h1
                                                            style="font-size: 20px; margin: 0;"
                                                        >
                                                            ${data.sayHello}
                                                        </h1>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table
                                                width="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                border="0"
                                            >
                                                <tr>
                                                    <td
                                                        align="left"
                                                        valign="top"
                                                        style="padding: 8px 16px;text-align: left; color: rgb(51, 51, 50); font-size: 16px; line-height: 24px;"
                                                    >
                                                        <span
                                                            style="font-size: 16px;line-height: 27px; color: #888888;"
                                                        >
                                                            ${data.content}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>

                                            <table
                                                width="100%"
                                                cellpadding="0"
                                                cellspacing="0"
                                                border="0"
                                            >
                                                <tr>
                                                    <td
                                                        align="center"
                                                        valign="top"
                                                        style="padding: 8px 16px 16px;text-align: left; color: rgb(51, 51, 50); font-size: 16px; line-height: 24px;"
                                                    >
                                                        <span
                                                            style="font-weight: bold;font-size: 16px;line-height: 27px; color: #888888;display: block;padding: 10px;background: #eaeaea;text-align: center;"
                                                        >
                                                            ${data.code}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>

                                            ${data.contentAfterCode
                                                ? html`
                                                      <table
                                                          width="100%"
                                                          cellpadding="0"
                                                          cellspacing="0"
                                                          border="0"
                                                      >
                                                          <tr>
                                                              <td
                                                                  align="left"
                                                                  valign="top"
                                                                  style="padding: 0px 16px 16px;text-align: left; color: rgb(51, 51, 50); font-size: 16px; line-height: 24px;"
                                                              >
                                                                  <span
                                                                      style="font-size: 16px;line-height: 27px;margin-top: 16px;margin-bottom: 16px;color: #888888;"
                                                                  >
                                                                      ${data.contentAfterCode}
                                                                  </span>
                                                              </td>
                                                          </tr>
                                                      </table>
                                                  `
                                                : ''}
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </body>
        </html>
    `
}

module.exports = template
