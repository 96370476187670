import styled from 'styled-components'

import { Select } from 'antd'

export const SelectStyled = styled(Select)``

export const DropDownStyled = styled.div`
    .ant-btn {
        margin: 5px 10px;
        width: calc(100% - 20px);
    }
`
