export const mock = {
    type: 'progressive',
    fields: [
        {
            name: 'section',
            label: 'Primeira seção',
            type: 'section',
            config: {
                system: true
            }
        }
    ]
}
