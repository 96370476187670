import styled, { css } from 'styled-components'

import {
    Radio
} from 'antd'

export const CustomRadioButton = styled(Radio.Button)`
    ${({ noBorder }) => css`
        border-radius: ${noBorder} ? 0 : 10px;
        border: 1px solid white !important;
        
    `}

    &:hover {
        background-color: white !important;
        color: #626b6a;
    }
`