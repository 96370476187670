import React from 'react'
import { Select } from 'antd'

interface SelectSearchProps {
    placeholder?: string
    mode?: 'multiple' | 'tags' | undefined
    value?: any
    children?: React.ReactNode
    optionFilterProp?: string
    maxTagCount?: number
    options?: any[]
}

const SelectSearch = React.forwardRef(
    ({ children, ...props }: SelectSearchProps, ref: any) => {
        const searchFilter = (input, option) => {
            // if (option.props) {
            //     return (
            //         option.props.children
            //             .toLowerCase()
            //             .indexOf(input.toLowerCase()) >= 0 ||
            //         option.props.value.toLowerCase().indexOf(input.toLowerCase()) >=
            //             0
            //     )
            // } else {
            //     if (option.children) {
            //         return (
            //             option.children
            //                 .toLowerCase()
            //                 .indexOf(input.toLowerCase()) >= 0
            //         )
            //     }

            //     return
            // }

            return (option?.children || option?.label || '')
                .toLowerCase()
                .includes(input.toLowerCase())
        }

        return (
            <Select
                showSearch
                mode="multiple"
                optionFilterProp="children"
                ref={ref}
                labelInValue={false}
                filterOption={(input, option) => searchFilter(input, option)}
                {...props}
            >
                {children}
            </Select>
        )
    }
)

export default SelectSearch

