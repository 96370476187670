import React from 'react'

import { Row } from 'antd'
import { useQuery } from '@apollo/client'

import { GET_BRANDS_TOTAL } from '@cms/events/graphql/queries/brand'

import Card from '../CardInnerMetrics'

import { StarFilled } from '@ant-design/icons'
import { TDDashboardCard } from '@cms/core/components/TDCards'

type Props = {
    eventId?: any
    full?: any
    align?: any
}

const SponsorsTotal = ({ eventId, full, align }: Props) => {
    const { loading, error, data } = useQuery(GET_BRANDS_TOTAL, {
        variables: { eventId }
    })

    if (loading || error) return null

    return (
        <TDDashboardCard backgroundColor="#B62FCC">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Row align="middle">
                    <StarFilled style={{ color: '#fff', fontSize: '1.2em' }} />
                </Row>
                <Row align="bottom">
                    <Card
                        title={'Número de Patrocinadores'}
                        data={data.brands.totalCount || 0}
                        align={align}
                        full={full}
                        reverse
                    />
                </Row>
            </div>
        </TDDashboardCard>
    )
}

export default SponsorsTotal
