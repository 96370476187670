import React from 'react'
import { Form, Input, Button } from 'antd'

import { ColorsBadges, ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'

const Footer = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Texto</Title>
                <Form.Item
                    name={[props.name, "configs", "text", "textColor"]}
                    label="Cor do texto">
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Footer
