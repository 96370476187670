import React, { useContext, useState, createContext } from 'react'
import { FIELDS } from '../constants/fields'
export const WidgetContext = createContext({
    sponsors: null,
    setSponsors: () => null,
    widgets: null,
    setWidgets: () => null,
    modalVisible: null,
    setModalVisible: () => null
})

export const useWidgetContext = () => useContext(WidgetContext)

export const WidgetProvider = ({ children }) => {
    const [sponsors, setSponsors] = useState(null)
    const [widgets, setWidgets] = useState(null)
    const [modalVisible, setModalVisible] = useState(null)

    return (
        <WidgetContext.Provider
            value={{
                sponsors,
                setSponsors,
                widgets,
                setWidgets,
                modalVisible,
                setModalVisible
            }}
        >
            {children}
        </WidgetContext.Provider>
    )
}
