import React, { useCallback } from 'react'
import dayjs from 'dayjs'
import { Menu, Dropdown, message } from 'antd'
import { MoreOutlined } from '@ant-design/icons'

import { useQuery, useMutation } from '@apollo/client'
import { GET_CHAT_MESSAGES } from '@cms/events/graphql/queries/chat'
import { CHAT_DELETE_MESSAGE } from '@cms/events/graphql/mutations/chat'
import {
    BAN_USER,
    BLOCK_TALK_CHAT_USER
} from '@cms/events/graphql/mutations/user'

import { removeFromList } from '@cms/core/graphql/utils'

const { SubMenu } = Menu

const ChatOptions = ({ node, popupContainer }) => {
    const [deleteMessage] = useMutation(CHAT_DELETE_MESSAGE, {
        update: removeFromList({ list: 'chatMessages', Type: 'ChatMessage' })
    })

    const [timeoutUser] = useMutation(BLOCK_TALK_CHAT_USER)
    const [banUser] = useMutation(BAN_USER)

    const handleDeleteMessage = useCallback(async () => {
        await deleteMessage({
            variables: {
                id: node.id
            }
        })
    }, [node])

    const handleTimeoutUser = useCallback(
        async (value) => {
            const { id, name } = node.user

            if (value === 1000) {
                let blockDate = null

                const endTime = dayjs(new Date(), 'HH:mm:ss')
                    .add(value, 'years')
                    .format('YYYY-MM-DD HH:mm:ss')

                blockDate = new Date(endTime)

                const variables = { id, blockDate }

                await timeoutUser({ variables })

                message.success({
                    content: `Usuário "${name}" foi bloqueado permanentemente.`,
                    style: {
                        marginTop: '90vh'
                    }
                })
            } else if (value === 99) {
                await banUser({
                    variables: {
                        id
                    }
                })
                message.success({
                    content: `Usuário "${name}" foi bloqueado permanentemente.`,
                    style: {
                        marginTop: '90vh'
                    }
                })
            } else if (value) {
                const variables = {
                    id,
                    blockDate: new Date(
                        new Date().getTime() + value * 60 * 1000
                    )
                }

                await timeoutUser({ variables })

                message.success({
                    content: `Usuário "${name}" foi bloqueado por ${value} minutos.`,
                    style: {
                        marginTop: '90vh'
                    }
                })
            } else {
                message.success(`Usuário "${name}" foi debloqueado.`)
            }
        },
        [timeoutUser, node]
    )

    const menu = (
        <Menu>
            <Menu.Item onClick={handleDeleteMessage} key={'delete'}>
                Deletar Mensagem
            </Menu.Item>

            <SubMenu key={'block'} title="Bloquear">
                <Menu.Item onClick={() => handleTimeoutUser(null)}>
                    Desbloquear
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item onClick={() => handleTimeoutUser(5)}>
                    5 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(10)}>
                    10 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(15)}>
                    15 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(30)}>
                    30 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(45)}>
                    45 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(60)}>
                    60 minutos
                </Menu.Item>
                <Menu.Item onClick={() => handleTimeoutUser(1000)}>
                    Permanente
                </Menu.Item>
                {/*< Menu.Item onClick={() => handleTimeoutUser(99)}>
                    Permanente
                </Menu.Item> */}
            </SubMenu>
        </Menu>
    )

    return (
        <Dropdown
            trigger={'click'}
            placement="bottomRight"
            overlay={menu}
            getPopupContainer={() => popupContainer || document.body}
        >
            <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
            >
                <MoreOutlined />
            </a>
        </Dropdown>
    )
}

export default ChatOptions
