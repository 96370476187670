import React from 'react'

import AppLayout from '@cms/core/components/AppLayout'
import SettingsRoutes from '@cms/core/routes/mainAppRoute'

const Settings = function () {
    return (
        <AppLayout rootModule={'mainapp'}>
            <SettingsRoutes />
        </AppLayout>
    )
}

export default Settings
