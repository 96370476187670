export const mock = {
    type: 'register',
    fields: [
        {
            name: 'name',
            label: 'Nome',
            type: 'text',
            config: {
                system: true,
                size: 24
            }
            // options: {
            //     type: Schema.Types.Mixed,
            //     default: {}
            // },
        },
        {
            name: 'email',
            label: 'E-mail',
            type: 'email',
            config: {
                system: true,
                size: 24
            }
            // options: {
            //     type: Schema.Types.Mixed,
            //     default: {}
            // },
            // config: {
            //     type: Schema.Types.Mixed,
            //     default: {}
            // }
        },
        {
            name: 'jobTitle',
            label: 'Cargo',
            type: 'select',
            options: [
                {
                    value: 'Gerente'
                    // label: 'Gerente',
                    // value: 'manager'
                },
                {
                    value: 'Analista'
                    // label: 'Analista',
                    // value: 'analyst'
                },
                {
                    value: 'Desenvolvedor'
                    // label: 'Desenvolvedor',
                    // value: 'developer'
                }
            ],
            config: {
                required: false,
                multiple: false,
                system: false,
                size: 24
            }
        }
    ]
    // integrations: [
    //     {
    //         type: 'rdstation', //webhook
    //         active: true,
    //         config: {
    //             type: Schema.Types.Mixed,
    //             default: {}
    //         }
    //     }
    // ]
}
