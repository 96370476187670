import React from 'react'
import { Form, Tag, Typography } from 'antd'
import { useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'
import { useCrudSelect } from '@cms/core/components/Crud'

import { GET_INTEGRATIONS } from '@cms/core/graphql/queries/integrations'
import { LinkOutlined } from '@ant-design/icons'

const IntegrationInput = ({
    name = 'integrations',
    onChange,
    eventId,
    category = 'conversions',
    appType = 'EVENTS'
}) => {
    const queryIntegrations = useQuery(GET_INTEGRATIONS, {
        variables: { first: 9999, eventId, category, appType },
        fetchPolicy: 'cache-and-network'
    })

    const [IntegrationOptions, integrationSelectProps] = useCrudSelect(
        queryIntegrations,
        {
            queryName: 'integrations'
        }
    )

    return (
        <>
            <Form.Item
                label="Integrações"
                name={name}
                style={{ marginBottom: 0 }}
            >
                <SelectSearch
                    tagRender={({ label, value, ...props }) => {
                        const { node } =
                            queryIntegrations.data?.integrations.edges.find(
                                (i) => i.node.id === value
                            ) || {}
                        if (!node) return null
                        return (
                            <Tag
                                {...props}
                                color={node.active ? '#7F44FF' : 'red'}
                            >
                                {label} {node.active ? '' : '(inativa)'}
                            </Tag>
                        )
                    }}
                    onChange={onChange}
                    {...integrationSelectProps}
                >
                    {IntegrationOptions({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>
            <Typography.Link
                href={`/evento/${eventId}/config/integracoes`}
                target="_blank"
                style={{
                    display: 'inline-block',
                    marginTop: 5,
                    marginBottom: 20
                }}
            >
                Gerenciar integrações <LinkOutlined />
            </Typography.Link>
        </>
    )
}

export default IntegrationInput
