import { gql } from '@apollo/client'
import { UserData } from '../fragments'

export const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
        login(input: { email: $email, password: $password, origin: CMS }) {
            user {
                ...UserData
            }
            accessToken
        }
    }

    ${UserData}
`

export const FORGOT_PASSWORD = gql`
    mutation ForgotPassword($email: String!) {
        forgotPassword(input: { email: $email })
    }
`

export const CODE_CHECK = gql`
    mutation CodeCheck($email: String!, $code: String!) {
        codeCheck(input: { email: $email, code: $code }) {
            accessToken
        }
    }
`

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($password: String!) {
        changePassword(input: { password: $password })
    }
`
