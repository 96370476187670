import styled from 'styled-components'

export const SiderStyled = styled.div`
    width: 260px;
    min-width: 260px;
    .ant-layout-sider-children {
        overflow: auto;
        max-height: calc(100vh - 104px);
        min-height: calc(100vh - 104px);
        display: flex;
        flex-direction: column;
    }

    .ant-layout-sider-children::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
        border-radius: 10px !important;
    }

    .ant-layout-sider-children::-webkit-scrollbar-thumb {
        background-color: darkgrey !important;
        border-radius: 10px !important;
    }

    .ant-menu-inline-collapsed > .ant-menu-item,
    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
        padding: 0;
        text-align: center;
    }
`

export const LogoWrapper = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: row;
    align-items: center;
    padding: 20px 0;
    height: 60px;

    .ant-menu-item-selected {
        background-color: transparent !important;
    }
    .ant-menu-vertical {
        border-right: none !important;
        padding-left: 0;
        padding-right: 0;

        .ant-menu-submenu-title {
            padding-left: 0;
            margin-left: -2px;

            .ant-menu-submenu-arrow {
                right: 0;
            }
        }
    }
`

export const Title = styled.span`
    font-family: 'Helvetica Now', sans-serif;
    letter-spacing: 3.5px;
    color: rgba(255, 255, 255, 0.5);
    opacity: 0.9;
    margin-left: 10px;
    padding-top: 5px;
    font-size: 12px;
`
