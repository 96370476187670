import React, { useEffect } from 'react'
import { Form, Row, Collapse, Input } from 'antd'
import AceEditor from 'react-ace'

import { PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'

const { Panel } = Collapse

const Config = (props) => {
    const [form] = Form.useForm()
    const { site } = usePageBuilderContext()

    useEffect(() => {
        form.setFieldsValue(site.config)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24}>
            <ColForm md={12} sm={24}>
                <Form name="config" form={form} layout="vertical">
                    <Form.Item label="URL de sucesso" name={['successUrl']}>
                        <Input />
                    </Form.Item>

                    <PanelFooter />
                </Form>
            </ColForm>
        </Row>
    )
}

export default Config
