import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import FaqQuestionsModal from './FaqQuestionsModal'

import { GET_FAQ_QUESTIONS_LIST_ORDERABLE } from '@cms/events/graphql/queries/faqQuestions'
import { DELETE_FAQ_QUESTION } from '@cms/events/graphql/mutations/faqQuestions'

import dayjs from 'dayjs'

const columns = [
    {
        title: 'Pergunta',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.question]} />
        }
    },
    {
        title: 'Tópico',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.topic?.name]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const FaqQuestionList = () => {
    const { eventId } = useParams()
    const variables = {
        first: 25,
        eventId,
        orderBy: { createdAt: 'DESC' }
    }

    const query = useQuery(GET_FAQ_QUESTIONS_LIST_ORDERABLE, { variables })

    if (query.error) {
        return <div>Error</div>
    }

    const path = `/evento/${eventId}/faq/perguntas`

    const [deleteData] = useMutation(DELETE_FAQ_QUESTION, {
        refetchQueries: [{ query: GET_FAQ_QUESTIONS_LIST_ORDERABLE, variables }]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(FaqQuestionsModal, path, { eventId })
    }, [eventId])

    const filterFields = [
        { name: 'question', label: 'Pergunta', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Perguntas',
                    subTitle: 'Crie perguntas e respostas para o FAQ',
                    buttons: [
                        {
                            children: 'Nova pergunta',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'faqQuestions'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default FaqQuestionList

