import React, { useState, useEffect, useRef } from 'react'
import { Form, Row, Col, Typography } from 'antd'

import { Preview } from '../../components'

import { usePageBuilderContext } from '../../context/context'
import { List } from './sections'
import Affix from '@cms/core/components/Affix'

const { Title } = Typography

const Page = (props) => {
    const { name } = props
    const [form] = Form.useForm()
    const [activePanel, setActivePanel] = useState()
    const previewRef = useRef(null)
    const colRef = useRef(null)

    const { site } = usePageBuilderContext()

    useEffect(() => {
        if (site?.pages) {
            form.setFieldsValue(site.pages[name])
            const panelType = site.pages[name].sections[0].type
            setActivePanel(panelType)
        }
    }, [])

    const updateSection = (type) => {
        if (!type) return null
        const data = form.getFieldsValue()
        const section = data.sections.find((item) => item.type === type)
        if (section && previewRef.current) {
            previewRef.current.handleParentWidth(colRef?.current?.offsetWidth)
            previewRef.current.Emit('pageData', {
                type: type,
                configs: section.configs,
                layout: {
                    theme: site.theme
                }
            })
        }
    }

    const onInit = () => {
        if (activePanel) {
            updateSection(activePanel)
        } else if (previewRef.current) {
            previewRef.current.Emit('fullPage', {
                ...site,
                sections: site.pages[name].sections
            })
            previewRef.current.handleParentWidth(colRef.current.offsetWidth)
            previewRef.current.handleParentHeight(600)
        }
    }

    const setSection = (type) => {
        setActivePanel(type)
        updateSection(type)
    }

    return (
        <Row gutter={24}>
            <Col md={12} sm={24}>
                <Form
                    name={name}
                    form={form}
                    layout="vertical"
                    onValuesChange={() => updateSection(activePanel)}
                >
                    {site ? (
                        <>
                            <List
                                activePanel={activePanel}
                                setSection={setSection}
                                form={form}
                                main
                            />
                            <List
                                activePanel={activePanel}
                                setSection={setSection}
                                form={form}
                            />
                        </>
                    ) : null}
                </Form>
            </Col>
            <Col md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <div>
                        {activePanel && <Title level={3}>{activePanel}</Title>}
                        <div
                            style={{ visibility: activePanel ? '' : 'hidden' }}
                        >
                            <Preview
                                onInit={onInit}
                                ref={previewRef}
                                page={name}
                            />
                        </div>
                    </div>
                </Affix>
            </Col>
        </Row>
    )
}

export default Page
