import { useQuery } from '@apollo/client'
import { GET_EVENT_FORMS } from '@cms/events/graphql/queries/form'
import { Form, Input, Select, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FontSelector } from '../../components'
import { useWidgetContext } from '../../context/widgets'
import { Title, Wrapper } from '../../styles'
import Colors from '@cms/core/components/Colors'

const { Option } = Select

const Fields = ({ form }) => {
    const { eventId } = useParams()
    const { data: eventForms } = useQuery(GET_EVENT_FORMS, {
        variables: {
            eventId,
            type: 'register'
        }
    })

    useEffect(() => {
        if (eventForms) {
            const { edges } = eventForms.eventForms
            if (edges.length > 0) {
                const eventForm = edges.filter(
                    ({ node }) => node.type === 'register'
                )[0]

                form.setFieldsValue({
                    configs: {
                        form: eventForm.node.id
                    }
                })
            }
        }
    }, [eventForms])

    return (
        <>
            <Form.Item
                name={['configs', 'form']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
            <FontSelector />
            <Wrapper transparent>
                <Title level={5}>Formulário</Title>
                <Form.Item name={['configs', 'textcolor']} label="Cor do texto">
                    <Colors formItem />
                </Form.Item>
                <Form.Item name={['configs', 'shape']} label="Formato">
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    name={['configs', 'redirect']}
                    label="Redirecionamento"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name={['configs', 'socialAuth']}
                    valuePropName="checked"
                    label="Usar rede social para login"
                >
                    <Switch />
                </Form.Item>
            </Wrapper>
            <Wrapper transparent>
                <Title level={5}>Botão</Title>
                <Form.Item name={['configs', 'btn']} label="Texto">
                    <Input />
                </Form.Item>
                <Form.Item name={['configs', 'btnShape']} label="Formato">
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>
                <Form.Item name={['configs', 'highlight']} label="Cor do fundo">
                    <Colors formItem />
                </Form.Item>
                <Form.Item
                    name={['configs', 'highlightText']}
                    label="Cor do texto"
                >
                    <Colors formItem />
                </Form.Item>
            </Wrapper>
        </>
    )
}

const Preview = ({ form, widget, db }) => {
    const { eventId } = useParams()
    const { widgets } = useWidgetContext()

    const [formId, setFormId] = useState(null)
    const { data: eventForms, loading } = useQuery(GET_EVENT_FORMS, {
        variables: {
            eventId,
            type: 'register'
        }
    })

    useEffect(() => {
        if (eventForms) {
            const { edges } = eventForms.eventForms
            if (edges.length > 0) {
                const eventForm = edges.filter(
                    ({ node }) => node.type === 'register'
                )[0]

                setFormId(eventForm.node.id)

                if (widgets) {
                    form.setFieldsValue({
                        configs: {
                            ...widgets.configs,
                            form: eventForm.node.id
                        }
                    })
                }
            }
        }
    }, [eventForms, widgets])

    useEffect(() => {
        if (formId) {
            window.TD.init()
        }
    }, [formId])

    if (!formId || !db) return null

    return (
        <div
            style={{
                width: 340,
                minHeight: 100
            }}
            data-x-customer={db}
            data-x-widget="form"
            data-x-preview="true"
            data-x-form={formId}
            data-x-font="Montserrat"
            data-x-btn="Retire seu ingresso"
            data-x-btn-shape="circle"
            data-x-highlight="#22D66A"
            data-x-highlight-text="#000"
            data-x-textcolor="#000"
            data-x-shape="rounded"
            data-x-redirect="https://example.com/"
            data-x-socialAuth="false"
        />
    )
}

export const Forms = {
    Fields,
    Preview
}
