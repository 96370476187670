import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Col, Row } from 'antd'
import camelCase from 'lodash/camelCase'
import { useQuery, useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import { CREATE_OR_UPDATE_WIDGET } from '@cms/events/graphql/mutations/widgets'
import { GET_WIDGETS } from '@cms/events/graphql/queries/widgtes'
import Loader from '@cms/core/components/Loader'

import { PreviewBox, FormFooter } from '../components'
import { pages } from './pages'
import { useWidgetContext } from '../context/widgets'
import { WIDGETS } from '../constants/fieldsDefault'

import { Wrapper } from '../styles'
import Affix from '@cms/core/components/Affix'

const Pages = ({ name }) => {
    const [form] = Form.useForm()
    const [updateWidget] = useMutation(CREATE_OR_UPDATE_WIDGET)
    const [widget, setWidget] = useState(null)
    const { eventId } = useParams()
    const { widgets, setWidgets } = useWidgetContext()
    const { Fields, Preview } = pages[name]

    const db = useSelector((state) => state.customer?.db)

    const { data: dataWidgets, loading } = useQuery(GET_WIDGETS, {
        variables: {
            eventId: eventId,
            type: name
        }
    })

    const onFormFinish = async (values) => {
        await updateWidget({
            variables: {
                event: eventId,
                data: values,
                type: name
            }
        })
    }

    const updateIframe = (options) => {
        if (options.configs?.font) {
            let _options = {}
            Object.keys(options.configs).forEach((key) => {
                const _key = camelCase(key)
                _options[_key] = options.configs[key]
            })

            if (_options.box === false) {
                _options.color = 'transparent'
            }

            if (widget) widget.emit('options', { ..._options })
        }
    }

    const handleUpdate = () => {
        const options = form.getFieldsValue()
        if (widgets) updateIframe(options)
    }

    useEffect(() => {
        if (dataWidgets && dataWidgets.widgetByType) {
            const { data } = dataWidgets.widgetByType
            if (data) {
                form.setFieldsValue(data)
                setWidgets(data)
            }
        } else {
            setWidgets(WIDGETS[name])
            form.setFieldsValue(WIDGETS[name])
        }
    }, [dataWidgets])

    useEffect(() => {
        if (!widget) {
            TD.on('widget-init', (widget) => {
                setWidget(widget)
            })
            TD.init()
        } else if (widgets) {
            updateIframe(widgets)
        }
    }, [widget, widgets])

    if (!db || !dataWidgets) return <Loader fixed={false} padding="10px" />

    const baseOptions = {
        widget: name,
        customer: db,
        event: eventId
    }

    return (
        <div>
            <Row gutter={[24, 24]}>
                <Col lg={12} sm={24}>
                    <Wrapper>
                        <Form
                            form={form}
                            name={name}
                            layout="vertical"
                            onFinish={onFormFinish}
                            onValuesChange={handleUpdate}
                        >
                            <Fields form={form} />
                            <FormFooter />
                        </Form>
                    </Wrapper>
                </Col>
                <Col lg={12} sm={24}>
                    <Affix offsetTop={0}>
                        <div>
                            <PreviewBox
                                form={form}
                                baseOptions={baseOptions}
                                name={name}
                                widget={widget}
                            >
                                <Preview form={form} db={db} />
                            </PreviewBox>
                        </div>
                    </Affix>
                </Col>
            </Row>
        </div>
    )
}

export default Pages
