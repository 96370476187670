export const socialMediaTypes = [
    'Linkedin',
    'Facebook',
    'Twitter',
    'Instagram',
    'TikTok'
]

export const DEFAULT_SECTIONS = [
    {
        fields: [
            {
                name: 'backgroundColor',
                label: 'Cor de fundo',
                component: 'select',
                initialValue: 'default',
                options: [
                    { label: 'Padrão', value: 'default' },
                    { label: 'Primária', value: 'primary' },
                    { label: 'Destaque', value: 'highlight' }
                ],
                configs: {
                    mode: 'single'
                }
            },
            {
                name: 'social',
                label: {
                    plural: 'Redes Sociais',
                    singular: 'Rede'
                },
                component: 'card-multiselect',
                fields: [
                    {
                        label: 'Plataforma',
                        name: 'type',
                        component: 'select',
                        options: socialMediaTypes.map((s) => ({
                            label: s,
                            value: s
                        })),
                        configs: {
                            mode: 'single',
                            required: true
                        }
                    },
                    {
                        label: 'Url',
                        name: 'url',
                        component: 'input',
                        configs: {
                            required: true
                        }
                    }
                ]
            },
            {
                name: 'links',
                label: {
                    plural: 'Links',
                    singular: 'Link'
                },
                component: 'card-multiselect',
                fields: [
                    {
                        label: 'Título',
                        name: 'label',
                        component: 'input',
                        configs: {
                            required: true
                        }
                    },
                    {
                        label: 'Link',
                        name: 'url',
                        component: 'input',
                        configs: {
                            required: true
                        }
                    }
                ]
            }
        ]
    }
]

