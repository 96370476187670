import React from 'react'

import { Checkbox, Form, Input, message } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { GET_WATCH_PLAN } from '@cms/watch/graphql/queries/watchPlan'

import {
    CREATE_WATCH_PLAN,
    UPDATE_WATCH_PLAN
} from '@cms/watch/graphql/mutations/watchPlan'

import { SectionCard } from '@cms/core/components/TDCards'
import { GET_WATCH_CATEGORIES_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchCategory'
import { getWatchCategoryTreeData } from '@cms/watch/utils/common'
import { addToList } from '@cms/watch/graphql/utils'
import CustomTree from '@cms/core/components/Tree'

const WatchPlansModal = ({ ...props }) => {
    const client = useApolloClient()

    const queryCategories = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })

    const watchCategories = queryCategories?.data?.watchCategories?.edges?.map(
        ({ node }) => ({ ...node })
    )
    const treeData = getWatchCategoryTreeData(watchCategories)

    const mapWatchPlan = (id, formData) => {
        const {
            permissions: { categories },
            ...rest
        } = formData

        return {
            id,
            ...rest,
            permissions: {
                categories: {
                    fullAccess: categories.fullAccess,
                    items: categories?.items || []
                },
                courses: {
                    items: []
                }
            }
        }
    }

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const {
                data: { watchPlan }
            } = await client.query({
                query: GET_WATCH_PLAN,
                variables: { id }
            })

            const categories = watchPlan.permissions.categories

            return {
                ...watchPlan,
                permissions: {
                    categories: {
                        ...categories,
                        items: categories.items.map((m) => m.id)
                    }
                }
            }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_WATCH_PLAN,
                update: addToList({ list: 'watchPlans', Type: 'WatchPlan' }),
                variables: mapWatchPlan(undefined, formData)
            })

            message.success('Plano cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_WATCH_PLAN,
                variables: mapWatchPlan(id, formData)
            })

            message.success('Plano atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Plano' : 'Novo Plano'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <SectionCard title="Permissões">
                    <Form.Item
                        name={['permissions', 'categories', 'fullAccess']}
                        {...getError('fullAccess')}
                        valuePropName="checked"
                    >
                        <Checkbox>Acesso Total</Checkbox>
                    </Form.Item>

                    <Form.Item noStyle shouldUpdate>
                        {({ getFieldValue }) => {
                            if (
                                getFieldValue([
                                    'permissions',
                                    'categories',
                                    'fullAccess'
                                ])
                            )
                                return null

                            return (
                                <Form.Item
                                    label="Categorias"
                                    name={[
                                        'permissions',
                                        'categories',
                                        'items'
                                    ]}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Categoria é obrigatória!'
                                        }
                                    ]}
                                    {...getError('items')}
                                >
                                    <CustomTree
                                        placeholder="Selecione as categorias"
                                        treeData={treeData}
                                        autoSelectParent
                                        autoExpandParent
                                    />
                                </Form.Item>
                            )
                        }}
                    </Form.Item>
                </SectionCard>
            </Form>
        </CrudModal>
    )
}

export default WatchPlansModal
