import { SectionsType } from '../../../../constants/initialData'
import { OPTIONS_VALUE } from './options'

export const BANNERS_CONFIGS = {
    banners: {
        type: SectionsType.banners,
        defaultValues: {},
        fields: {
            _id: {
                label: 'Banners',
                type: 'select',
                options: OPTIONS_VALUE.all
            },
            pages: {
                label: 'Páginas',
                type: 'select',
                options: [
                    { value: 'Home', label: 'Home' },
                    { value: 'Live', label: 'Transmissão' },
                    { value: 'Sponsor', label: 'Patrocinador' }
                ]
            }
        }
    }
}

