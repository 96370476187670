import React, { useEffect, useMemo, useRef, useState } from 'react'
import { currencies, CurrencyType } from './currencies'

import { Input } from 'antd'

interface CurrencyInputProps {
    currency?: CurrencyType
    onChange: (value: string | number) => void
    value: string | number
}

export const CurrencyInput = ({
    currency,
    onChange,
    value,
    ...props
}: CurrencyInputProps) => {
    const formmatter = useMemo(() => {
        const { locale, intlConfig } =
            currencies.find((c) => c.symbol === currency) || currencies[0]
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            ...intlConfig
        })
    }, [currency])

    const inputEl = useRef(null)

    const formatValue = (value: string | number) => {
        return formmatter.format(Number(value || 0) / 100)
    }

    return (
        <Input
            ref={(_) => (inputEl.current = _?.input)}
            value={formatValue(value)}
            placeholder={formmatter.format(0)}
            onChange={() => {
                const { value, selectionEnd } = inputEl.current
                const rightCharsCount = value.length - selectionEnd

                const _value = Number(
                    inputEl.current.value.replace(/[^0-9]/g, '') || 0
                )

                const formattedValue = formatValue(_value)
                const newPosition = formattedValue.length - rightCharsCount

                onChange(Number(_value || 0))

                window.requestAnimationFrame(() => {
                    inputEl.current.setSelectionRange(newPosition, newPosition)
                })
            }}
            {...props}
        />
    )
}

export default CurrencyInput
