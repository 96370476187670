import React, { useState } from 'react'

import { Modal, Footer } from './styles'
import { Button } from 'antd'
import { useSelector } from 'react-redux'

const Modalfull = ({
    children,
    onSave,
    btnTitle,
    disableFooter,
    visible,
    showMenu
}) => {
    const [_visible, setVisible] = useState(visible)
    const collapsed = useSelector((state) => state.collapseSide)

    const handleSave = async () => {
        const response = await onSave()

        if (!response) return
        setVisible(false)
    }

    return (
        <>
            {!visible && (
                <Button
                    type={'primary'}
                    style={{ width: '100%' }}
                    onClick={() => setVisible(true)}
                >
                    {btnTitle ? btnTitle : 'Editar o e-mail'}
                </Button>
            )}
            {_visible ? (
                <Modal
                    collapsed={collapsed}
                    showMenu={showMenu}
                    className="modal-full"
                >
                    {children}
                    {!disableFooter && (
                        <Footer collapsed={collapsed}>
                            <div>
                                <Button onClick={() => setVisible(false)}>
                                    Cancelar
                                </Button>
                                <Button type="primary" onClick={handleSave}>
                                    Finalizar
                                </Button>
                            </div>
                        </Footer>
                    )}
                </Modal>
            ) : null}
        </>
    )
}

export default Modalfull

