import React, { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { GET_EVENT_STREAMING_TOKENS } from '@cms/events/graphql/queries/event'
import Preview from '@cms/core/components/Theme/Preview'
import { useParams } from 'react-router-dom'

const ThemePreview = ({ theme, previewUrl }) => {
    return <Preview theme={theme} iframeUrl={previewUrl} />
}

export default ThemePreview
