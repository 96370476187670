import EventNumbers from './EventNumbers'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const EventsNumbers = {
    EventNumbers: {
        Component: EventNumbers,
        name: 'Números do evento',
        category: 'Números do evento',
        thumb: require('./eventNumbers.png'),
        columns: '4',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'secondary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                title: {
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    textColor: getColorContrast(colors, 'primary')
                },
                columns: [
                    {
                        title: {
                            text: '100%'
                        },
                        subtitle: {
                            text: 'Online\ne Gratuito'
                        }
                    },
                    {
                        title: {
                            text: '100%'
                        },
                        subtitle: {
                            text: 'Online\ne Gratuito'
                        }
                    },
                    {
                        title: {
                            text: '100%'
                        },
                        subtitle: {
                            text: 'Online\ne Gratuito'
                        }
                    },
                    {
                        title: {
                            text: '100%'
                        },
                        subtitle: {
                            text: 'Online\ne Gratuito'
                        }
                    }
                ],
                box: {
                    section: { type: '1' }
                }
            }
        }
    }
}
