import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import React from 'react'

import { ThemeProvider } from 'styled-components'
import { ConfigProvider, theme } from 'antd'

import { Provider } from 'react-redux'

import { antdTheme, defaultTheme } from './themes'
import GlobalStyle from './themes/GlobalStyle'
import Routes from './routes'

import store from './store/store'
import { StyleProvider } from '@ant-design/cssinjs'

import Empty from '@cms/core/components/Empty'
import GraphqlProvider from './GraphqlProvider'

import { ErrorBoundary } from './Error'

import * as parser from './themes/parser'

import locale from 'antd/es/locale/pt_BR'

import dayjs_utc from 'dayjs/plugin/utc'
import dayjs_advancedFormat from 'dayjs/plugin/advancedFormat'
import dayjs_customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs_localeData from 'dayjs/plugin/localeData'
import dayjs_weekday from 'dayjs/plugin/weekday'
import dayjs_weekOfYear from 'dayjs/plugin/weekOfYear'
import dayjs_weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(dayjs_utc)
dayjs.extend(dayjs_advancedFormat)
dayjs.extend(dayjs_customParseFormat)
dayjs.extend(dayjs_localeData)
dayjs.extend(dayjs_weekday)
dayjs.extend(dayjs_weekOfYear)
dayjs.extend(dayjs_weekYear)

dayjs.locale('pt-BR')

function App() {
    return (
        // <ErrorBoundary>
        <Provider store={store}>
            <GraphqlProvider>
                <ConfigProvider
                    locale={locale}
                    renderEmpty={() => <Empty />}
                    componentSize="large"
                    theme={{
                        hashed: true,
                        ...antdTheme,
                        algorithm: parser.algorithm
                    }}
                >
                    <StyleProvider transformers={[parser.transform]}>
                        <ThemeProvider theme={defaultTheme}>
                            <div className="App">
                                <GlobalStyle />
                                <Routes />
                            </div>
                        </ThemeProvider>
                    </StyleProvider>
                </ConfigProvider>
            </GraphqlProvider>
        </Provider>
        // </ErrorBoundary>
    )
}

export default App
