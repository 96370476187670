import styled from 'styled-components'

export const ActivityLead = styled.div`
    padding: 8px;
    height: 55vh;
    max-width: 100%;
    padding: 20px;
    overflow: auto;

    h1.ant-typography {
        margin-bottom: 0;
        color: #ececec;
        font-weight: 500;
        font-size: 1.1em;
    }

    .ant-divider-horizontal {
        margin: 12px 0;
    }

    .ant-timeline-item-head {
        background-color: #69686a;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: auto;
        margin-top: 5px;
        margin-left: 15px;
    }

    @media (min-width: 1601px) {
        .ant-timeline-item-head {
            width: 3.5vh;
            height: 3.5vh;
        }
    }

    @media (max-width: 1320px) {
        width: 100%;
    }

    .ant-timeline-item-tail {
        position: absolute;
        top: 25px;
        left: 19px;
        height: calc(100%);
        border-left: 2px solid #69686a;
    }

    .ant-timeline-item-content {
        margin-left: 50px;
    }

    #scrollTimeLine {
        padding: 0;
        overflow: hidden;
        overflow-y: scroll;
        height: 45vh;
        padding-left: 0.1%;
        padding-top: 1.5%;
    }

    #scrollTimeLine::-webkit-scrollbar,
    div::-webkit-scrollbar {
        width: 0.7em;
    }

    #scrollTimeLine::-webkit-scrollbar-track,
    div::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
    }

    #scrollTimeLine::-webkit-scrollbar-thumb,
    div::-webkit-scrollbar-thumb {
        background-color: darkgrey;
        border-radius: 10px;
    }
`
