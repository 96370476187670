export const WIDGETS = {
    rewards: {
        configs: {
            font: 'Montserrat',
            box: false,
            shape: 'rounded',
            color: '#fff',
            indications: '#D222D6',
            rewards: '#000'
        }
    },
    viral: {
        configs: {
            font: 'Montserrat',
            btnShape: 'circle',
            highlight: '#22D66A',
            'highlight-text': '#000',
            textcolor: '#000',
            shape: 'rounded',
            redirect: 'https://example.com/'
        }
    },
    sponsors: {
        configs: {
            font: 'Montserrat',
            color: '#000',
            shape: 'rounded',
            logo: 'white'
        }
    },
    speakers: {
        configs: {
            font: 'Montserrat',
            color: '#000',
            logo: 'black'
        }
    },
    schedule: {
        configs: {
            font: 'Montserrat',
            title: 'Confira os horários \ndas palestras e os palestrantes',
            color: '#fff',
            textcolor: '#000',
            timecolor: '#642BDE',
            shape: 'rounded'
        }
    },
    forms: {
        configs: {
            font: 'Montserrat',
            btn: 'Retire seu ingresso',
            'btn-shape': 'circle',
            highlight: '#22D66A',
            'highlight-text': '#000',
            textcolor: '#000',
            shape: 'rounded',
            redirect: 'https://example.com/'
        }
    }
}
