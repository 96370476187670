import React from 'react'
import { useParams } from 'react-router-dom'
import { Form, Input } from 'antd'

import { BoxControl, FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'
import Colors from '@cms/core/components/Colors'

const Fields = ({ form }) => {
    return (
        <>
            <FontSelector />
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>

            <Wrapper>
                <BoxControl form={form} />
            </Wrapper>

            <Wrapper transparent>
                <Title level={5}>Recompensas Top</Title>
                <Form.Item
                    name={['configs', 'topbgcolor']}
                    label="Cor de fundo"
                >
                    <Colors formItem />
                </Form.Item>

                <Form.Item
                    name={['configs', 'toptextcolor']}
                    label="Cor do texto das recompensas"
                >
                    <Colors formItem gradient={false} />
                </Form.Item>
            </Wrapper>

            <Wrapper transparent>
                <Title level={5}>Recompensas normais</Title>
                <Form.Item
                    name={['configs', 'rewardbgcolor']}
                    label="Cor de fundo"
                >
                    <Colors formItem />
                </Form.Item>

                <Form.Item
                    name={['configs', 'rewardindicationstextcolor']}
                    label="Cor do texto d Indicações"
                >
                    <Colors formItem gradient={false} />
                </Form.Item>

                <Form.Item
                    name={['configs', 'rewardtextcolor']}
                    label="Cor do texto das Recompensas"
                >
                    <Colors formItem gradient={false} />
                </Form.Item>
            </Wrapper>
        </>
    )
}

const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="rewards"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            data-x-font="Montserrat"
            data-x-box="false"
            data-x-topboxcolor="#D222D6"
            data-x-toptextcolor="#fff"
            data-x-indications="#D222D6"
            data-x-rewards="#000"
        />
    )
}

export const Rewards = {
    Fields,
    Preview
}
