import React from 'react'
import BaseConfig from '../BaseConfig'
import Segment from '@cms/core/components/Segment'
import { CATEGORIES_CONFIGS } from '@cms/core/components/Segment/constants/categories'

interface ICategoriesProps {
    name: string
}

export function Categories({ name }: ICategoriesProps) {
    return (
        <>
            <BaseConfig name={name} title={true} description={false} />
            <Segment
                name={[name, 'config']}
                type="categories"
                filters={CATEGORIES_CONFIGS}
                configs={{
                    formName: 'sections'
                }}
            />
        </>
    )
}
