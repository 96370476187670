import { gql } from '@apollo/client'

import { UserData } from '../fragments'

export const GET_WATCH_USERS_LIST_ORDERABLE = gql`
    query GetWatchUsers(
        $first: Int
        $afterCursor: Cursor
        $search: String
        $orderBy: UserOrderBy
        $name: Filter
        $email: Filter
    ) {
        users(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            search: $search
            filter: { name: $name, email: $email }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserData
                }
            }
        }
    }

    ${UserData}
`

