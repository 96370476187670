import React from 'react'

import {
    Route,
    useHistory,
    useParams,
    Link,
    useLocation
} from 'react-router-dom'

import { Row, Col } from 'antd'

import {
    Categories,
    ProgramTalks,
    DashboardCard
} from '@cms/events/components/Dashboard'

import EventDetails from '@cms/events/components/Event/EventsDetails'

const General = () => {
    const { eventId } = useParams()

    return (
        <Row gutter={[24, 24]}>
            <Col lg={8} sm={12} xs={12} md={12}>
                <DashboardCard
                    style={{
                        backgroundColor: '#7730F6'
                    }}
                >
                    <EventDetails eventId={eventId} />
                </DashboardCard>
            </Col>
            <Col lg={8} sm={12} xs={12} md={12}>
                <DashboardCard
                    style={{
                        borderRadiusBottom: '50px'
                    }}
                >
                    <ProgramTalks hideAction={'all'} />
                </DashboardCard>
            </Col>
            <Col lg={8} sm={12} xs={12} md={12}>
                <DashboardCard
                    style={{
                        borderRadiusBottom: '50px'
                    }}
                >
                    <Categories type={'MAIN'} hideAction={'all'} />
                </DashboardCard>
            </Col>
        </Row>
    )
}

export default General
