import React from 'react'
import { Form, Row, Col, Input } from 'antd'
import BodyValues from '../../BodyValues/BodyValues'
const FacebookConversionApi = () => {
    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Identificador da conversão"
                        name={['config', 'identifier']}
                        rules={[
                            {
                                required: true,
                                message: 'Identificador é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="API Token"
                        name={['config', 'apiToken']}
                        rules={[
                            {
                                required: true,
                                message: 'API Token é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Pixel ID"
                        name={['config', 'pixelId']}
                        rules={[
                            {
                                required: true,
                                message: 'Pixel ID é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <BodyValues type="FacebookConversionApi" />
                </Col>
            </Row>
        </>
    )
}
export default FacebookConversionApi
