import React, { useState, useRef, useEffect } from 'react'
import { Row, Col, Radio, Typography, Space, Button } from 'antd'
import { CodeIcon } from '@cms/core/assets/icons'

import { LABELS } from '../../constants/fields'

import ModalScripts from './ModalScripts'
import { PreviewBoxWrapper } from './styles'

const { Title } = Typography

const PreviewBox = ({ form, children, baseOptions, name }) => {
    const [background, setBackground] = useState('light')
    const [code, setCode] = useState('')
    const [modalVisible, setModalVisible] = useState()
    const [width, setWidth] = useState(0)
    const ref = useRef(null)

    const onVisibleChange = () => {
        const fields = form.getFieldsValue()
        let element = '<div'

        Object.keys(baseOptions).forEach((key) => {
            element += ` data-x-${key}="${baseOptions[key]}"`
        })

        Object.keys(fields.configs).forEach((key) => {
            if (fields.configs[key]) {
                element += ` data-x-${key}="${fields.configs[key]}"`
            }
        })

        element += '></div>'

        setCode(element)
        setModalVisible(!modalVisible)
    }

    const onCancel = () => {
        setModalVisible(false)
    }

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current.offsetWidth)
        }
    }, [ref.current])

    return (
        <PreviewBoxWrapper background={background} ref={ref} width={width}>
            <Row gutter={[24, 24]} justify="space-between" align="middle">
                <Col>
                    <Space>
                        <Title
                            level={2}
                            style={{ marginBottom: '0px', marginTop: '0' }}
                        >
                            {LABELS[name]}
                        </Title>
                        <Button
                            className="btn-code"
                            type="default"
                            onClick={onVisibleChange}
                        >
                            Copiar código
                            <span style={{ marginLeft: '10px' }}>
                                <CodeIcon
                                    style={{
                                        width: '16px',
                                        verticalAlign: 'sub'
                                    }}
                                />
                            </span>
                        </Button>
                    </Space>
                </Col>
                <Col>
                    <Radio.Group
                        defaultValue={background}
                        buttonStyle="solid"
                        onChange={(e) => setBackground(e.target.value)}
                    >
                        <Radio.Button value={'light'}>Claro</Radio.Button>
                        <Radio.Button value={'dark'}>Escuro</Radio.Button>
                    </Radio.Group>
                </Col>
            </Row>
            <Row gutter={24} className="preview-widget-box">
                <Col span={24}>{children}</Col>
            </Row>
            <ModalScripts
                onCancel={onCancel}
                visible={modalVisible}
                code={code}
            />
        </PreviewBoxWrapper>
    )
}

export default PreviewBox
