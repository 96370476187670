import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_CAMPAIGN = gql`
    mutation CreateCampaign(
        $name: String
        $dailyGoals: DailyGoalsInputs
        $referrerRewards: [ReferrerRewardsInputs]
        $topRewards: [TopRewardsInputs]
        $indicatedRewards: [IndicatedRewardsInputs]
        $social: SocialInputs
        $messages: MessagesInputs
        $event: ObjectID
    ) {
        createViralCampaign(
            input: {
                name: $name
                dailyGoals: $dailyGoals
                referrerRewards: $referrerRewards
                topRewards: $topRewards
                indicatedRewards: $indicatedRewards
                social: $social
                messages: $messages
                event: $event
            }
        ) {
            ...ViralCampaignData
        }
    }
    ${fragments.ViralCampaignData}
`

export const UPDATE_CAMPAIGN = gql`
    mutation UpdateCampaign(
        $id: ObjectID!
        $name: String
        $dailyGoals: DailyGoalsInputs
        $referrerRewards: [ReferrerRewardsInputs]
        $topRewards: [TopRewardsInputs]
        $indicatedRewards: [IndicatedRewardsInputs]
        $social: SocialInputs
        $messages: MessagesInputs
        $event: ObjectID
    ) {
        updateViralCampaign(
            id: $id
            input: {
                name: $name
                dailyGoals: $dailyGoals
                referrerRewards: $referrerRewards
                topRewards: $topRewards
                indicatedRewards: $indicatedRewards
                social: $social
                messages: $messages
                event: $event
            }
        ) {
            ...ViralCampaignData
        }
    }
    ${fragments.ViralCampaignData}
`

export const UPDATE_VIRAL_REWARD = gql`
    mutation UpdateViralReward($id: ObjectID!, $delivered: Boolean) {
        updateViralReward(input: { delivered: $delivered }, id: $id) {
            id
            delivered
        }
    }
`

export const DELETE_CAMPAIGN = gql`
    mutation DeleteCampaign($id: ObjectID!) {
        deleteViralCampaign(id: $id) {
            id
            success
        }
    }
`
