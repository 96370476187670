import Countdown from './Countdown'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Countdowns = {
    Countdown: {
        Component: Countdown,
        name: 'Countdown',
        category: 'Countdown',
        thumb: require('./countdown.png'),
        columns: '3',
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'secondary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                countdown: {
                    date: `${event.startDate}`
                },
                message: {
                    text: 'Inscreva-se! O evento começa em:'
                },
                text: {
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    text: 'Inscreva-se gratuitamente',
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
