import React from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { CrudList } from '@cms/core/components/Crud'
import { GET_DAILY_CONVERSION } from '@cms/events/graphql/queries/eventRegistration'

import { Typography, Row } from 'antd'
import { PercentageOutlined } from '@ant-design/icons'
import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../styles'

const { Text } = Typography

const DailyRate = (props) => {
    const columns = [
        {
            title: <Text>Data</Text>,
            dataIndex: ['node', 'date'],
            key: 'date',
            ellipsis: true,
            width: 100,
            align: 'left',
            fixed: 'left',
            render: (date) => {
                const data = dayjs.utc(new Date(date)).format('DD/MM/YYYY')
                return <span>{data}</span>
            }
        },
        {
            title: <Text>Inscrições</Text>,
            dataIndex: ['node', 'count'],
            key: 'count',
            align: 'center',
            ellipsis: true,
            width: 90
        },
        {
            title: <Text>Taxa de Conversão</Text>,
            dataIndex: ['node', 'rate'],
            key: 'rate',
            align: 'center',
            width: 150,
            ellipsis: true,
            render: (rate) => {
                const rates = rate * 100
                return <span>{rates.toFixed(2) + '%'}</span>
            }
        }
    ]

    const { eventId } = useParams()

    const query = useQuery(GET_DAILY_CONVERSION, {
        variables: { eventId }
    })

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <PercentageOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Meta diária</DashboardTitle>
            </DashboardCardTitle>

            <div className="minimal-padding" style={{ paddingBottom: '30px' }}>
                <CrudList
                    columns={columns}
                    hideAction={'all'}
                    queryName={'dailyConversions'}
                    style={props.style}
                    search={false}
                    showHeader={true}
                    transparent
                    {...query}
                />
            </div>
        </>
    )
}

export default DailyRate
