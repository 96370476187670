import { useQuery } from '@apollo/client'
import { GET_TALK } from '@cms/events/graphql/queries/talk'
import React from 'react'
import { useParams } from 'react-router-dom'
import Rating from '@cms/events/components/Rating'
import { Wrapper } from './styles'

const Avaliation = () => {
    const { id: talkId } = useParams()

    const { data, loading, error } = useQuery(GET_TALK, {
        variables: { id: talkId }
    })

    if (loading || error) return null

    return (
        <Wrapper>
            <Rating
                total={data.talk.rating.total}
                average={data.talk.rating.average}
                options={[
                    {
                        value: 5,
                        quantity: data.talk.rating.five
                    },
                    {
                        value: 4,
                        quantity: data.talk.rating.four
                    },
                    {
                        value: 3,
                        quantity: data.talk.rating.three
                    },
                    {
                        value: 2,
                        quantity: data.talk.rating.two
                    },
                    {
                        value: 1,
                        quantity: data.talk.rating.one
                    }
                ]}
            />
        </Wrapper>
    )
}

export default Avaliation
