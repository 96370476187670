import { COLORS } from '@cms/core/themes'
import { lighten } from 'polished'
import styled, { css } from 'styled-components'

export const AffixContainer = styled.div`
    top: ${(props) =>
        props.offsetTop >= 0 ? props.offsetTop + 'px' : 'unset'};
    bottom: ${(props) =>
        props.offsetBottom >= 0 ? props.offsetBottom + 'px' : 'unset'};
    position: ${(props) =>
        props.offsetTop >= 0 || props.offsetBottom >= 0 ? 'sticky' : 'unset'};
    z-index: ${(props) => (props.zIndex ? props.zIndex : 'unset')};

    ${({ isNav }) =>
        isNav
            ? css`
                  padding: 0 0 30px 0;
                  width: 100%;
                  transition: 0.2s;

                  &.bgOn {
                      padding: 15px;
                      margin-bottom: 30px;
                      transition: 0.2s;
                      backdrop-filter: blur(10px);
                      box-shadow: 1px 0px 3px #ffffff57;
                      background: ${lighten(0.1, COLORS.bgLight)};
                  }
              `
            : null}
`
