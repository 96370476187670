import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useCreation } from '@umijs/hooks'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import { GET_TICKET_LIST_ORDENABLE } from '@cms/events/graphql/queries/tickets'
import { DELETE_TICKET } from '@cms/events/graphql/mutations/tickets'

import TicketModal from './TicketModal'
import formatCurrency from '@cms/events/components/CurrencyInput/format-currency'

import dayjs from 'dayjs'

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name']
    },
    {
        title: 'Moeda',
        dataIndex: ['node', 'currency']
    },
    {
        title: 'Preço',
        dataIndex: ['node', 'value'],
        render: (value, { node: { currency } }) => {
            return <span>R$ {formatCurrency(value, currency)}</span>
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const TicketList = () => {
    const { eventId } = useParams()

    const variables = { first: 25, eventId, orderBy: { createdAt: 'DESC' } }

    const path = `/evento/${eventId}/ingressos`

    const query = useQuery(GET_TICKET_LIST_ORDENABLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_TICKET, {
        refetchQueries: [{ query: GET_TICKET_LIST_ORDENABLE, variables }]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(TicketModal, path, { eventId })
    }, [eventId])

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [{ name: 'name', label: 'Nome', queryType: 'Filter' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Tipos de ingressos',
                    subTitle:
                        'Adicione e gerencie os tipos de ingressos do evento',
                    buttons: [
                        {
                            children: 'Novo Tipo de Ingresso',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'tickets'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default TicketList

