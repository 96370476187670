import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_CATEGORIES_TOTALS } from '@cms/events/graphql/queries/blocks/totals'

import Card from '../CardInner'

const TotalCategories = ({ eventId, brandId, full, align }) => {
    const { loading, error, data } = useQuery(GET_CATEGORIES_TOTALS, {
        variables: { eventId, brandId }
    })

    if (loading || error) return null

    return (
        <>
            <Card
                title={'Categorias'}
                data={data.dashboardCategoryCount || 0}
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalCategories
