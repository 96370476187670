import { useCallback, useState } from 'react'
import { useBoolean, useCreation } from '@umijs/hooks'
import { message } from 'antd'


const useCrudList = (opts = {}, deps = []) => {
    const { getData, deleteData } = useCreation(() => opts, deps)

    const loading = useBoolean(false)
    const [data, setData] = useState({ meta: {}, items: [] })

    const fetchData = useCallback(async (query) => {
        if (!getData) return
        loading.setTrue()
        setData(await getData(query))
        loading.setFalse()
    }, [getData])

    const removeData = useCallback(async (obj) => {
        if (!deleteData) return false
        try {
            const response = await deleteData(obj)
            if (response) {
                if (data.items.length > 1) {
                    setData({
                        ...data,
                        items: data.items.filter(item => item != obj)
                    })
                } else {
                    fetchData({
                        __page: Math.max(data.meta.page-1, 1),
                        __limit: data.meta.limit
                    })
                }
            }
        } catch (e) {
            message.error(e.response.data.message);
        }
    }, [data, deleteData, fetchData])

    const addOrUpdateItem = useCallback(async (response, id) => {
        if (!response) return

        if (id) {
            setData(data => ({
                ...data,
                items: data.items.map(item => item._id == id ? response : item)
            }))
        }  else {
            setData(data => ({
                meta: { ...data.meta, total: data.meta.total+1 },
                items: [...data.items, response]
            }))
        }
    }, [])

    const crudProps = {
        data,
        loading: loading.state,
        fetchData,
        removeData
    }

    return [
        { ...crudProps, addOrUpdateItem },
        crudProps
    ]
}


export default useCrudList