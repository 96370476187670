import {
    Button,
    Col,
    Collapse,
    CollapsePanelProps,
    Form,
    FormInstance,
    FormListFieldData,
    Popconfirm,
    Row,
    Space,
    Table,
    Typography
} from 'antd'

import React from 'react'
import { CustomInput } from '../styles'
import { DeleteOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

const { Title } = Typography

const columns = [
    {
        title: 'Título',
        dataIndex: 'title',
        key: 'title'
    },
    {
        title: 'Duração',
        dataIndex: 'duration',
        key: 'duration',
        render: (duration: number = 0) => {
            const hours = Math.floor(duration / 60)
            const minutes = duration % 60
            return `${hours}h ${minutes}m`
        }
    },
    {
        title: 'Ações',
        dataIndex: 'action',
        key: 'action',
        width: 160,
        render: (form: FormInstance, record: any) => {
            const history = useHistory()

            return (
                <Space size="small">
                    <Button
                        type="primary"
                        onClick={() => {
                            history.push(
                                `${record.path}/${record.id}/editar#step-1`
                            )
                        }}
                    >
                        Editar
                    </Button>
                    <Popconfirm
                        title={'Tem certeza que deseja deletar?'}
                        onConfirm={record.onDelete}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Button type="primary" danger>
                            <DeleteOutlined />
                        </Button>
                    </Popconfirm>
                </Space>
            )
        }
    }
]

interface CourseModuleProps
    extends Omit<CollapsePanelProps, 'header' | 'extra'> {
    field: FormListFieldData
    remove: (name: number) => void
    path: string
}

const CourseModule: React.FC<CourseModuleProps> = ({
    field,
    remove,
    path,
    ...rest
}) => {
    const form = Form.useFormInstance()
    const contents =
        Form.useWatch(['modules', field.name, 'contents'], form) || []
    const history = useHistory()
    return (
        <Collapse.Panel
            {...rest}
            key={field.name}
            header={
                <Row>
                    <Col span={22}>
                        <Form.Item
                            noStyle
                            name={[field.name, 'name']}
                            rules={[
                                {
                                    required: true,
                                    message: 'Campo obrigatório'
                                }
                            ]}
                        >
                            <CustomInput
                                variant="borderless"
                                placeholder="Nome do módulo"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            }
            extra={
                <Popconfirm
                    title={'Tem certeza que remover esse conteúdo do modulo?'}
                    onConfirm={() => remove(field.name)}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button type="default" danger>
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            }
            collapsible={'icon'}
        >
            <Table
                size="small"
                title={() => (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Title
                            level={4}
                            style={{
                                margin: 0
                            }}
                        >
                            Conteúdos
                        </Title>
                        <Space>
                            <Button
                                size="middle"
                                type="primary"
                                onClick={() => {
                                    history.push(
                                        `${path}/${field.name}/conteudo/adicionar#step-1`
                                    )
                                }}
                            >
                                Novo conteúdo
                            </Button>
                            <Button
                                size="middle"
                                type="default"
                                onClick={() => {
                                    history.push(
                                        `${path}/${field.name}/conteudo/biblioteca#step-1`
                                    )
                                }}
                            >
                                Adicionar da biblioteca
                            </Button>
                        </Space>
                    </div>
                )}
                columns={columns}
                rowKey="id"
                dataSource={contents.map((content: any) => ({
                    action: form,
                    path: `${path}/${field.name}/conteudo`,
                    onDelete: () => {
                        const contents =
                            form.getFieldValue([
                                'modules',
                                field.name,
                                'contents'
                            ]) || []

                        console.log(
                            contents.filter((c: any) => {
                                console.log(c.id, content.id)

                                return c.id !== content.id
                            })
                        )
                        form.setFieldValue(
                            ['modules', field.name, 'contents'],
                            contents.filter((c: any) => c.id !== content.id)
                        )
                        form.submit()
                    },
                    ...content
                }))}
                pagination={false}
            />
        </Collapse.Panel>
    )
}

export default CourseModule
