import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_EVENT } from '@cms/events/graphql/queries/event'

import { Row, Layout, Col } from 'antd'
import { removeObjectProps } from '@cms/events/utils'

import { ReferalList } from '../Review/'
import Loader from '@cms/core/components/Loader'
import { CampaingPage } from '@cms/events/components/Campaings/styles'

export default function ReferalListPage() {
    const { eventId } = useParams()
    const [campaingDataClean, setCampaingDataClean] = useState(null)
    const { pathname } = useLocation()
    const pathArray = pathname.split('/')

    const { error: eventError, data: eventData } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    useEffect(() => {
        if (!eventData || !eventData.event.viralCampaign) return

        setCampaingDataClean(
            removeObjectProps(eventData.event.viralCampaign, ['__typename'])
        )
    }, [eventData])

    if (!eventData) return <Loader fixed={false} padding='10px' />
    if (eventError) return `Error! ${eventError.message}`

    return (
        <CampaingPage>
            {campaingDataClean && (
                <Col span={24}>
                    <ReferalList
                        campaingDataClean={campaingDataClean}
                        eventId={eventId}
                    />
                </Col>
            )}
        </CampaingPage>
    )
}
