import { useCallback, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useBoolean, useCreation } from '@umijs/hooks'
import { Form, message } from 'antd'
import merge from 'lodash/merge'

import { removeObjectProps } from '@cms/events/utils'

const useCrudForm = (opts = {}, deps = []) => {
    const instance = useCreation(() => opts, deps)
    const { id, getData, createData, updateData, relateds } = instance
    const [form] = Form.useForm()
    const saveLoading = useBoolean(false)
    const dataLoading = useBoolean(false)
    const initializated = useBoolean(false)
    const [errors, setErrors] = useState({})
    const [formData, setFormData] = useState({})
    const params = useParams()

    const [currentId, setCurrentId] = useState(id || params.id)

    const fetchData = useCallback(async () => {
        if (!getData) return
        dataLoading.setTrue()
        const response = await getData(currentId)
        if (response) {
            const _response = removeObjectProps(response, ['__typename'])
            _response.$form = form
            form.setFieldsValue(_response)
            dataLoading.setFalse()
            setFormData(removeObjectProps(response, ['$form', '__typename']))
        } else {
            dataLoading.setFalse()
        }

        initializated.setTrue()
    }, [getData, setFormData, currentId])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        if (!id && params.id !== currentId) {
            setCurrentId(params.id)
        } else if (id && id !== currentId) {
            setCurrentId(id)
        }
    }, [id, params.id, currentId])

    const handleUpdate = useCallback(
        async (newData) => {
            if (newData) {
                newData = removeObjectProps(newData, ['__typename'])
            }

            const updateData = merge(
                {
                    ...form.getFieldsValue()
                },
                {
                    ...newData
                }
            )

            setFormData(updateData)

            if (newData) {
                form.setFieldsValue(updateData)
            }
        },
        [setFormData]
    )

    const checkRelateds = (values, prefix = '') => {
        if (relateds.length === 0) return
        Object.keys(values).forEach((item) => {
            if (relateds.indexOf(prefix + item) >= 0 && values[item]) {
                values[item] = values[item].id
            } else if (
                values[item] &&
                (typeof values[item] === 'object' ||
                    typeof values[item] === 'array')
            ) {
                checkRelateds(values[item], prefix + item + '.')
            }
        })
    }

    const handleSave = useCallback(async () => {
        if (!createData && !updateData) return
        saveLoading.setTrue()
        setErrors({})

        try {
            await form.validateFields()
        } catch (error) {
            saveLoading.setFalse()
            return false
        }

        try {
            const formData = form.getFieldsValue()

            if (relateds) checkRelateds(formData)

            const scope = {
                ...instance,
                setCurrentId,
                refetchData: fetchData
            }
            if (currentId) {
                await updateData.apply(scope, [currentId, formData, scope])
            } else {
                await createData.apply(scope, [formData, scope])
            }
        } catch (e) {
            console.dir(e)
            const { graphQLErrors } = e

            // console.log(graphQLErrors)

            message.error(
                e.message ||
                    'Ocorreu um erro ao salvar, tente novamente mais tarde!'
            )

            if (graphQLErrors && graphQLErrors.length) {
                let _errors = {}

                graphQLErrors.map(({ fields }) => {
                    if (fields) {
                        _errors = { ..._errors, ...fields }
                    }
                })

                saveLoading.setFalse()
                setErrors(_errors)
            } else {
                saveLoading.setFalse()
            }

            return false
        }

        saveLoading.setFalse()
        return true
    }, [currentId, form, createData, updateData, fetchData, instance])

    const getError = useCallback(
        (name) => {
            const error = errors[name]

            if (!error || !error.message) return {}

            return {
                help: error.message,
                validateStatus: 'error',
                hasFeedback: true
            }
        },
        [errors]
    )

    return [
        {
            form,
            isEdit: !!currentId,
            formData,
            errors,
            getError,
            currentId,
            setCurrentId: setCurrentId,
            saveLoading: saveLoading.state,
            saveLoadingControl: saveLoading,
            formSave: handleSave,
            formUpdate: handleUpdate,
            fetchData,
            initializated: initializated.state,
            dataLoading: dataLoading.state,
            dataLoadingControl: dataLoading
        },
        {
            saveLoading: saveLoading.state,
            dataLoading: dataLoading.state,
            onSave: handleSave
        }
    ]
}

export default useCrudForm
