import { useQuery } from '@apollo/client'
import { GET_COUNT_OF_TALK_VIEWS } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'
import React from 'react'
import { TalksInfos } from '../card'

const TotalViews = ({ eventId, talk }) => {
    const {
        loading,
        error,
        data: countTalkViews
    } = useQuery(GET_COUNT_OF_TALK_VIEWS, {
        variables: { eventId, talk }
    })

    if (loading || error) return null

    return (
        <TalksInfos
            title={
                countTalkViews?.countOfTalkViews
                    ? renderBaseK(countTalkViews?.countOfTalkViews)
                    : 0
            }
            subtitle="Total de views"
        />
    )
}

export default TotalViews

