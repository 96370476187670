import React from 'react'
import { Typography, Empty, Form } from 'antd'

import Section from './section'
import AddSection from '../components/AddSection'
import { ISectionPanel, ISectionProps } from './types'
import { useSectionBuilder } from '../context'

const { Title } = Typography

interface Props extends ISectionPanel {
    title?: string
}

const List = (props: Props) => {
    const { activePanel, setSection, title = 'Seções' } = props
    const form = Form.useFormInstance()
    const { sections } = useSectionBuilder()

    return (
        <>
            <Title level={3} style={{ marginTop: '30px' }}>
                {title}
            </Title>
            <AddSection index={0} />
            <Form.List name="sections">
                {(fields, { move, remove, add }) => {
                    return (
                        <>
                            {fields.length === 0 && (
                                <Empty
                                    description={
                                        <span>
                                            <a>Nenhuma seção foi adicionada</a>
                                        </span>
                                    }
                                >
                                    <AddSection
                                        index={0}
                                        emptySections
                                        add={add}
                                    />
                                </Empty>
                            )}

                            {fields.map((field, key) => {
                                const _fields = form.getFieldsValue()

                                const { type, active } = _fields.sections[key]

                                const Component = sections[type]?.Component

                                const extraProps: ISectionProps = {
                                    index: field.name,
                                    section: type,
                                    checked: active,
                                    activePanel,
                                    setSection,
                                    editable: !!Component,
                                    move,
                                    remove
                                }

                                return (
                                    <React.Fragment key={field.key}>
                                        {/* <Form.Item
                                            name={[field.name, 'id']}
                                            initialValue={'section-' + nanoid()}
                                            style={{ display: 'none' }}
                                        >
                                            <Input />
                                        </Form.Item> */}
                                        <Section {...extraProps}>
                                            {Component && (
                                                <Component
                                                    form={form}
                                                    type={type}
                                                    {...field}
                                                />
                                            )}
                                        </Section>
                                        <AddSection index={key + 1} add={add} />
                                    </React.Fragment>
                                )
                            })}
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}

export default List

