import { TSection } from './sections/types'

export const SECTIONS_DEFAULT: TSection[] = [
    {
        type: 'general',
        title: 'Geral',
        name: 'general',
        fields: [
            {
                type: 'color',
                name: 'bgColor',
                label: 'Cor de fundo',
                initialValue: '#000',
                gradient: true
            },
            {
                type: 'color',
                name: 'textColor',
                label: 'Cor do texto padrão',
                initialValue: '#fff',
                gradient: false
            },
            {
                type: 'color',
                name: 'highlight',
                label: 'Cor Destaque',
                initialValue: '#a81be4',
                gradient: false
            },
            {
                type: 'button',
                name: 'button',
                title: 'Botões fora de caixas'
            },
            {
                type: 'messages',
                name: 'messages',
                title: 'Mensagens de status'
            }
        ]
    },
    {
        type: 'menu',
        title: 'Menu',
        name: 'menu',
        fields: [
            {
                type: 'logo',
                name: ['menu', 'logoColor'],
                label: 'Cor do logo',
                initialValue: 'default'
            },
            {
                type: 'color',
                name: ['menu', 'bgColor'],
                label: 'Cor do fundo',
                initialValue: '#500d6d',
                gradient: true
            },
            {
                type: 'color',
                name: ['menu', 'textColor'],
                label: 'Cor do texto e icone',
                initialValue: '#a81be4',
                gradient: false
            },
            {
                type: 'color',
                name: ['menu', 'active', 'highlight'],
                label: 'Cor de destaque',
                initialValue: '#a81be4',
                gradient: true
            }
        ]
    },
    {
        type: 'box',
        name: 'main',
        title: 'Caixas primárias',
        fields: []
    },
    {
        type: 'box',
        name: 'highlight',
        title: 'Caixas de destaque',
        fields: []
    },
    {
        type: 'box',
        name: 'sponsor',
        title: 'Patrocinadores',
        fields: []
    }
]

