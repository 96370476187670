import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_USERS_LIST_ORDERABLE } from '@cms/watch/graphql/queries/watchUsers'

import { DELETE_USER } from '@cms/core/graphql/mutations/user'
import WatchUserModal from './WatchUsersModal'

import dayjs from 'dayjs'

const path = '/usuarios'
const ModalRoutes = CreateModalRoutes(WatchUserModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    },
    {
        title: 'E-mail',
        dataIndex: ['node', 'e-mail'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.email]} />
        }
    },
    {
        title: 'Plano',
        dataIndex: ['node', 'watchPlan', 'name'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.watchPlan?.name]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchUsers = () => {
    const query = useQuery(GET_WATCH_USERS_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [
            GET_WATCH_USERS_LIST_ORDERABLE,
            {
                variables: { first: 25, orderBy: { name: 'ASC' } }
            }
        ]
    })

    if (query.error) return <div>Error</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Usuários',
                    subTitle: 'Adicione e gerencie usuários',
                    buttons: [
                        {
                            children: 'Novo Usuário',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'users'}
                hideAction="delete"
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default WatchUsers

