import React, { useEffect, useState } from 'react'

import { Button, Form, Row, Col, Select, Space, Input } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { filterConditions } from './utils'
import { FilterFieldsOptionsType, FilterFieldsType } from '../../types'

import client from '@cms/core/graphql/client'
import { useParams } from 'react-router-dom'

const FieldComponent = ({
    options,
    form
}: {
    options: FilterFieldsType['options']
    form: any
}) => {
    const { eventId } = useParams<{ eventId: string }>()

    const [fieldOptions, setFieldOptions] = useState<FilterFieldsOptionsType[]>(
        []
    )

    const onChange = (value) => {
        const opt = fieldOptions?.find((opt) => opt?.value === value)

        const condition = filterConditions.find(
            (c) => c.name === form.getFieldValue(['field', 'condition'])
        )

        const label = form.getFieldValue(['field', 'label'])

        form.setFieldValue(
            ['tag'],
            `${label} ${condition.label.toLowerCase()} ${opt?.label || value}`
        )
    }

    const Component = () => {
        if (fieldOptions.length) {
            return (
                <Select placeholder="Campo" onChange={onChange}>
                    {fieldOptions?.map((field, key) => (
                        <Select.Option
                            key={key}
                            value={field?.value}
                            title={field?.label}
                        >
                            {field?.label}
                        </Select.Option>
                    ))}
                </Select>
            )
        }

        return <Input onChange={(e) => onChange(e.target.value)} />
    }

    useEffect(() => {
        const fetchData = async () => {
            if (options) {
                const resolvedOptions =
                    typeof options === 'function'
                        ? await options(client, eventId)
                        : options
                return setFieldOptions(resolvedOptions)
            }
            setFieldOptions([])
        }

        fetchData()
    }, [options, form])

    return (
        <>
            <Form.Item
                name={['field', 'value']}
                required
                style={{ marginBottom: '8px' }}
            >
                {Component()}
            </Form.Item>
            <Form.Item name={['tag']} required noStyle>
                <input type="hidden" />
            </Form.Item>
        </>
    )
}

interface IFilterFieldsProps {
    fields: FilterFieldsType[]
    form: any
}

const FilterFields = ({ fields = [], form }: IFilterFieldsProps) => {
    const [fieldType, setFieldType] = useState<{
        options: FilterFieldsType['options']
    }>()

    form.setFieldValue(['field', 'condition'], 'eq')

    const handleSubmit = async () => {
        await form.validateFields()
        form.submit()
    }

    return (
        <>
            <Space style={{ padding: 8 }}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            name={['field', 'name']}
                            style={{ marginBottom: '8px' }}
                            required
                        >
                            <Select
                                placeholder="Campo"
                                onChange={(value) => {
                                    const f = fields.find(
                                        (f) => f.name === value
                                    )
                                    form.setFieldValue(
                                        ['field', 'label'],
                                        f?.label
                                    )

                                    form.setFieldValue(
                                        ['field', 'value'],
                                        undefined
                                    )

                                    setFieldType({
                                        options: f.options
                                    })
                                }}
                            >
                                {fields?.map((field, key) => (
                                    <Select.Option
                                        key={key}
                                        value={field.name}
                                        title={field.label}
                                    >
                                        {field.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item name={['field', 'label']} required noStyle>
                            <input type="hidden" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name={['field', 'condition']}
                            style={{ marginBottom: '8px' }}
                        >
                            <Select placeholder="Condição">
                                {filterConditions?.map((condition, key) => (
                                    <Select.Option
                                        key={key}
                                        value={condition.name}
                                        title={condition.label}
                                    >
                                        {condition.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <FieldComponent form={form} {...fieldType} />
                    </Col>
                    <Col span={24}>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={handleSubmit}
                            style={{ width: '100%' }}
                        >
                            Adicionar filtro
                        </Button>
                    </Col>
                </Row>
                <Row></Row>
            </Space>
        </>
    )
}

export default FilterFields

