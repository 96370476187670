import styled, { css } from 'styled-components'

import Content from '@cms/core/components/Content'

export const CrudContainer = styled(Content)`
    max-width: 100%;
    border-radius: 10px;

    &.container-transparent {
        background: transparent;
        background-color: transparent;
    }

    .ant-page-header {
        margin-bottom: 16px;
        padding: 0;

        span.ant-page-header-heading-extra {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    .input-search {
        background: transparent;
    }

    .ant-table-column-sorter-inner > span {
        font-size: 13px;
    }

    /* .actions-main {
        padding-left: 0px;
    } */

    .crudlist-end {
        position: absolute;
        bottom: 0px;
    }

    .queryBuilder {
        select,
        .ruleGroup-addRule {
            color: #000;
            border: none;
            background: #fff;
            height: 40px;
            padding: 8px 15px;
            font-size: 14px;
            border-radius: 10px;
            margin-right: 10px;
            text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
            box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
            cursor: pointer;
        }
        .ruleGroup-addGroup {
            display: none;
        }
    }
`

export const WrapperSpin = styled.div`
    width: 100%;
    height: 100%;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
`

export const EmptyEventContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    width: 100%;
`
