import React from 'react'
import { Form, Row, Col, Input } from 'antd'
import BodyValues from '../../BodyValues/BodyValues'

const RdStation = () => {
    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Identificador da conversão"
                        name={['config', 'identifier']}
                        rules={[
                            {
                                required: true,
                                message: 'Identificador é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Token Público"
                        name={['config', 'publicToken']}
                        rules={[
                            {
                                required: true,
                                message: 'Token Público é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <BodyValues type="RDStation" />
                </Col>
            </Row>
        </>
    )
}

export default RdStation
