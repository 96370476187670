import React, { useState, useEffect } from 'react'
import { Select } from 'antd'

const Selection = ({ selection, callback, type, multiple, onChange }) => {
    const [options, setOptions] = useState(selection)

    useEffect(() => {
        if (type) {
            setOptions(
                selection.filter((item) => !item.type || item.type === type)
            )
        }
    }, [type])

    const handleChange = (e) => {
        if (callback) {
            const selected = selection.filter((item) => item.name === e)[0]

            callback(selected)
        }

        onChange(e)
    }

    return (
        <Select
            style={{
                marginRight: '10px'
            }}
            mode={multiple}
            onChange={handleChange}
        >
            {options.map((_) => {
                return (
                    <Select.Option
                        key={_.name || _.value}
                        value={_.name || _.value}
                        title={_.label}
                    >
                        {_.label}
                    </Select.Option>
                )
            })}
        </Select>
    )
}

export default Selection
