import React, { useMemo } from 'react'

import { useQuery } from '@apollo/client'
import { Button, Form, Typography } from 'antd'
import { GET_WATCH_CATEGORIES_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchCategory'
import { getWatchCategoryTreeData } from '@cms/watch/utils/common'
import SelectTree from '@cms/watch/components/SelectTree'
import { SelectCategoriesSelectContent, SelectCategoriesWrapper } from './styles'
import { LinkOutlined, LoadingOutlined, ReloadOutlined } from '@ant-design/icons'

const { Link } = Typography

/**
 * Escolher categorias que serão convertidas em conteúdos para o TD Watch
 */
const SelectCategories = () => {
    const form = Form.useFormInstance()

    const path = `${window.location.origin}/watch/conteudo/categorias`

    const { data, loading, refetch } = useQuery(GET_WATCH_CATEGORIES_LIST_ORDENABLE, {
        variables: { first: 99999, orderBy: { name: 'ASC' } }
    })

    const watchCategories = useMemo(() => {
        const categories = data?.watchCategories?.edges?.map(({ node }) => ({...node}))

        return getWatchCategoryTreeData(categories)
    }, [data]) 

    return (
        <SelectCategoriesWrapper>
            <SelectCategoriesSelectContent>
                <Form.Item 
                    name="category"
                    rules={[
                        {
                            required: true,
                            message: 'Selecione uma categoria para continuar'
                        }
                    ]}
                >
                    <SelectTree
                        placeholder="Selecione a categoria que deseja para importar" 
                        treeData={watchCategories}
                        multiple={false}
                        loading={loading}
                    />
                </Form.Item>
                <Button
                    type='primary' 
                    icon={loading ? <LoadingOutlined /> : <ReloadOutlined />} 
                    onClick={() => refetch()} 
                />
            </SelectCategoriesSelectContent>
            <Link href={path} target='_blank'>Adicione uma categoria <LinkOutlined /></Link>
        </SelectCategoriesWrapper>
    )
}

export default SelectCategories
