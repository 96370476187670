import React from 'react'

import EventInfo from '../EventInfo'

const StepTwo = ({ form, getError }) => {
    return (
        <>
            <EventInfo form={form} getError={getError} />
        </>
    )
}

export default StepTwo
