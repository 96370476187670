import React from 'react'
import { message, Form, Input, Checkbox, Col, Row } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'

import { GET_COUPON } from '@cms/events/graphql/queries/coupons'
import {
    CREATE_COUPON,
    UPDATE_COUPON
} from '@cms/events/graphql/mutations/coupons'
import TextArea from 'antd/es/input/TextArea'
import Card from '@cms/core/components/Card'

const CouponModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_COUPON,
                variables: { id }
            })

            return data.ticketCoupon
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_COUPON,
                update: addToList({
                    list: 'ticketCoupons',
                    Type: 'TicketCoupon'
                }),
                variables: { ...formData, event: eventId }
            })

            message.success('Cupom cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_COUPON,
                variables: { id, ...formData, event: eventId }
            })

            message.success('Cupom atualizado com sucesso!')
        }
    })

    const handleInputChange = (e) => {
        const { value } = e.target
        const uppercasedValue = value
            .replace(/[^a-zA-wZ0-9-\s]/g, '')
            .replace(/\s/g, '-')
            .toUpperCase()
        form.setFieldsValue({ code: uppercasedValue })
    }

    const handleNumberInput = (e) => {
        const { value } = e.target
        const removedChars = value.replace(/[^0-9]/g, '')
        const onlyNumbers = removedChars ? parseInt(removedChars) : 1
        form.setFieldsValue({ discountPercentage: onlyNumbers })
    }

    return (
        <CrudModal
            title={isEdit ? 'Editar Cupom' : 'Novo Cupom'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Card title="Dados do cupom">
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                name="active"
                                valuePropName="checked"
                                initialValue={true}
                                noStyle
                            >
                                <Checkbox>Ativo</Checkbox>
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="Código do Cupom"
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Código é obrigatório!'
                                    }
                                ]}
                                {...getError('code')}
                            >
                                <Input onChange={handleInputChange} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="Desconto(%)"
                                name="discountPercentage"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Desconto é obrigatório! (1 à 100)',
                                        validator: (_, value) => {
                                            if (value < 1 || value > 100) {
                                                return Promise.reject()
                                            }
                                            return Promise.resolve()
                                        }
                                    }
                                ]}
                                {...getError('discountPercentage')}
                            >
                                <Input
                                    onChange={handleNumberInput}
                                    suffix="%"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Descrição" name="description">
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </CrudModal>
    )
}

export default CouponModal
