import { COLORS } from '@cms/core/themes'
import { Collapse } from 'antd'
import styled from 'styled-components'

export const PreviewWrapper = styled.div`
    display: ${({ active }) => (active ? 'block' : 'none')};
`

export const StyledCollapse = styled(Collapse)`
    > .ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }

    .ant-collapse-header {
        h3 {
            margin-bottom: 0px;
            margin-top: 0;
            color: ${COLORS.text};
        }
    }

    .ant-collapse-content-box {
        padding: 0px !important;
        border-radius: 0px !important;

        .ant-row > .ant-col {
            border-radius: 0px !important;
            background-color: unset;
        }
    }
`
