import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_COLLECTION_ITEM = gql`
    mutation CreateCollectionItem(
        $title: String
        $description: String
        $image: MediaInput
        $action: ActionInput
        $event: ObjectID
        $category: ObjectID
        $tags: [String]
    ) {
        createCollectionItem(
            input: {
                title: $title
                description: $description
                image: $image
                action: $action
                event: $event
                category: $category
                tags: $tags
            }
        ) {
            ...CollectionItemData
        }
    }

    ${fragments.CollectionItemData}
`

export const UPDATE_COLLECTION_ITEM = gql`
    mutation UpdateCollectionItem(
        $id: ObjectID!
        $event: ObjectID
        $title: String
        $description: String
        $image: MediaInput
        $action: ActionInput
        $category: ObjectID
        $tags: [String]
    ) {
        updateCollectionItem(
            id: $id
            input: {
                title: $title
                description: $description
                image: $image
                action: $action
                event: $event
                category: $category
                tags: $tags
            }
        ) {
            ...CollectionItemData
        }
    }

    ${fragments.CollectionItemData}
`

export const DELETE_COLLECTION_ITEM = gql`
    mutation DeleteCollectionItem($id: ObjectID!) {
        deleteCollectionItem(id: $id) {
            id
            success
        }
    }
`
