import React, {
    createContext,
    useState,
    useEffect,
    useMemo,
    useCallback
} from 'react'

interface IBrandSelectContext {
    brandId: string
    setBrandId: (brandId: string) => void
}

const BrandSelectContext = createContext<IBrandSelectContext>({
    brandId: 'all',
    setBrandId: () => {}
})

interface BrandSelectProviderProps {
    children: React.ReactNode
    brandId?: string
}

export const BrandSelectProvider = ({
    children,
    brandId
}: BrandSelectProviderProps) => {
    const [_brandId, setBrandId] = useState<string>(
        brandId || window.localStorage.getItem('@td-sponsor-id')
    )

    useEffect(() => {
        window.localStorage.setItem('@td-sponsor-id', _brandId)
    }, [_brandId])

    return (
        <BrandSelectContext.Provider value={{ brandId: _brandId, setBrandId }}>
            {children}
        </BrandSelectContext.Provider>
    )
}

export const useBrandSelect = () => {
    return React.useContext(BrandSelectContext) || ({} as IBrandSelectContext)
}
