import React from 'react'

import AppLayout from '@cms/core/components/AppLayout'
import { HeaderEvent, HeaderMenu } from '@cms/events/components/Header'
import SponsorRoutes from '@cms/events/routes/sponsorRouters'
import SelectBrand from './SelectBrand'
import { BrandSelectProvider } from './SelectBrand/context'

const SponsorDetail = function () {
    return (
        <BrandSelectProvider>
            <AppLayout
                rootModule="events.sponsor.detail"
                HeaderAfterModuleComponent={HeaderEvent}
                HeaderBeforeModuleComponent={SelectBrand}
            >
                <SponsorRoutes />
            </AppLayout>
        </BrandSelectProvider>
    )
}

export default SponsorDetail
