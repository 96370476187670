import { SettingsModules } from './modules/settings'
import { IApp } from './types/app'
import { lazy } from 'react'

const MainApp: IApp = {
    id: 'mainApp',
    name: ' ',
    basePath: '/main',
    hidden: true,
    container: lazy(() => import('./components/Container/mainApp')),
    modules: [
        {
            name: 'Configurações',
            module: 'mainapp',
            root: true,
            children: SettingsModules
        }
    ],
    defaultRootModule: 'mainapp.settings'
}

export default MainApp
