import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import SpeakerUserModal from './SpeakerUserModal'

import { GET_SPEAKERS_LIST_ORDERABLE } from '@cms/events/graphql/queries/user'
import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { DELETE_USER } from '@cms/events/graphql/mutations/user'

import dayjs from 'dayjs'

const path = '/principal/palestrante/usuarios'

const ModalRoutes = CreateModalRoutes(SpeakerUserModal, path)

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.image?.url}
                    title={obj.node.name}
                    text={[obj.node.jobTitle]}
                />
            )
        }
    },
    {
        title: 'E-mail',
        dataIndex: ['node', 'email'],
        render: (_value, obj) => {
            return <CrudColumns onlyText text={[obj.node.email]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const TeamList = () => {
    const query = useQuery(GET_SPEAKERS_LIST_ORDERABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_USER, {
        refetchQueries: [GET_SPEAKERS_LIST_ORDERABLE]
    })

    const { data } = useQuery(CURRENT_USER)

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [
        { name: 'name', label: 'Nome', queryType: 'Filter' },
        { name: 'email', label: 'E-mail', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Palestrantes',
                    countNames: {
                        singular: 'Palestrante',
                        plural: 'Palestrantes'
                    },
                    subTitle: 'Adicione e gerencie palestrantes do evento',
                    buttons: [
                        {
                            children: 'Novo Palestrante',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'users'}
                onDelete={deleteData}
                filterFields={filterFields}
                disableFor={data.me.id}
                showHeader
                {...query}
            />
        </>
    )
}

export default TeamList

