import { gql } from '@apollo/client'
import * as fragments from '../fragments'

// export const UPDATE_SITE = gql`
//     mutation UpdateSites(
//         $id: ObjectID!
//         $domain: String
//         $slug: String
//         $published: Boolean
//         $params: JSON
//         $draft: JSON
//         $data: JSON
//     ) {
//         updateSites(
//             id: $id,
//             input: {
//                 domain: $domain
//                 slug: $slug
//                 published: $published
//                 params: $params
//                 draft: $draft
//                 data: $data
//             }
//         ) {
//             ...SiteData
//         }
//     }

//     ${fragments.SiteData}
// `
export const UPDATE_SITE = gql`
    mutation UpdateSites(
        $id: ObjectID!
        $domain: String
        $slug: String
        $published: Boolean
        $params: JSON
        $draft: JSON
        $data: JSON
    ) {
        updateSites(
            id: $id,
            input: {
                domain: $domain
                slug: $slug
                published: $published
                params: $params
                draft: $draft
                data: $data
            }
        ) {
            ...SiteData
        }
    }

    ${fragments.SiteData}
`

export const IMPORT_SITE_SETTINGS = gql`
    mutation ImportSiteSettings(
        $sourceEventId: ObjectID!
        $targetEventId: ObjectID!
    ) {
        importSiteSettings(
            sourceEventId: $sourceEventId
            targetEventId: $targetEventId
        ) {
            id
        }
    }
`