import React from 'react'
import { Form, Row, Col, Input, Select } from 'antd'
import { useQuery } from '@apollo/client'
import { GET_EVENT_FIELDS } from '@cms/events/graphql/queries/event'
import { useParams } from 'react-router-dom'

const Mux = () => {
    const { eventId } = useParams()
    const { data: eventFields } = useQuery(GET_EVENT_FIELDS, {
        variables: {
            id: eventId
        }
    })

    if (!eventFields) return null

    const { fields } = eventFields.event

    return (
        <>
            <Row gutter={24}>
                <Col span={24}>
                    <Form.Item
                        label="Env Key"
                        name={['config', 'env_key']}
                        rules={[
                            {
                                required: true,
                                message: 'Env Key é obrigatório!'
                            }
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Campo de Trackeamento"
                        name={['config', 'trackerField']}
                    >
                        <Select>
                            {fields.map((field) => (
                                <Select.Option
                                    key={field.name}
                                    value={field.name}
                                >
                                    {field.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </>
    )
}

export default Mux
