'use client'

import { MentionsInput } from 'react-mentions'
import styled from 'styled-components'

import { EmojiPickerContainer } from '../EmojiPicker/EmojiPicker.styles'
import { COLORS, GRADIENTS, SIZES } from '@cms/core/themes'

export const SendMessageContainer = styled.div`
    width: 100%;
    border-top: 2px solid ${COLORS.bgLight};
    border-bottom: 2px solid ${COLORS.bgLight};
    background: ${COLORS.secondary};
    color: ${COLORS.text};
    align-items: center;
    display: flex;
    border-radius: 0 0 0 ${SIZES.borderRadiusMD};

    > button {
        &.icon-button {
            width: 48px;
            height: 8px;
            border-radius: 0 10px 10px 0;
            border: none;
            cursor: pointer;
        }
    }
`

export const SendMessageForm = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: 3px;
    border-radius: 10px 0 0 10px;

    ${EmojiPickerContainer} {
        margin-right: 12px;
    }
`

export const SendMessageInputContainer = styled.div`
    position: relative;
    width: 100%;
    background: ${COLORS.secondary};
`

export const SendMessageInput = styled(MentionsInput)`
    width: 100%;
    max-width: 250px;
    overflow: hidden !important;
    max-height: 82px;
    resize: none;
    color: currentColor;
    border: none;
    padding: 6px;
    border-radius: 6px;

    > div {
        position: relative;
        min-height: 28px;
    }

    textarea {
        border: none;
        color: currentColor;
        overflow-y: auto !important;

        &::placeholder {
            color: currentColor;
            opacity: 0.7;
        }

        &:focus {
            outline: none;
        }
    }

    ul {
        background: ${COLORS.bgLight} !important;
        color: ${COLORS.text} !important;
        padding: 4px !important;
        border-radius: 6px;
        box-shadow: 0 0 6px 0 ${COLORS.text} !important;
    }

    > [class*='_suggestions'] {
        background: ${COLORS.bgLight} !important;
        color: ${COLORS.text} !important;
        border-radius: 6px;
    }

    ::placeholder {
        color: ${COLORS.text} !important;
    }
`

export const SendMessageError = styled.div`
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 4px;
    left: 50%;
    transform: translateX(-50%);
    background: ${COLORS.bgLight};
    color: ${({ theme }) => theme.main.input.error.textColor};
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    text-align: center;
`

export const inputStyles = () => ({
    '&multiLine': {
        input: {
            border: 'none',
            color: COLORS.text,
            overflow: 'auto'
        }
    },
    suggestions: {
        list: {
            borderRadius: '5px',
            backgroundColor: COLORS.bgLight,
            fontSize: 14
        },
        item: {
            padding: '5px 15px',
            '&focused': {
                backgroundColor: COLORS.bgLight
            }
        }
    }
})

export const SendMessageButton = styled.button`
    width: 48px;
    height: 100%;
    border-radius: 0 0 ${SIZES.borderRadiusMD} 0;
    background: ${GRADIENTS.primary};
    color: ${COLORS.text};
    border: none;
    cursor: pointer;
`
