import Pages from '../pages'
import { LABELS } from './fields'

export const BASE_URL = '/evento/:eventId/widgets'

export const ROUTES = [
    {
        path: `${BASE_URL}`,
        name: 'rewards',
        exact: true,
        title: LABELS.rewards
    },
    {
        path: `${BASE_URL}/agenda`,
        exact: true,
        name: 'schedule',
        title: LABELS.schedule
    },
    {
        path: `${BASE_URL}/patrocinadores`,
        exact: true,
        name: 'sponsors',
        title: LABELS.sponsors
    },
    {
        path: `${BASE_URL}/palestrantes`,
        exact: true,
        name: 'speakers',
        title: LABELS.speakers
    },
    {
        path: `${BASE_URL}/formularios`,
        exact: true,
        name: 'form',
        title: LABELS.forms
    },
    {
        path: `${BASE_URL}/viral`,
        exact: true,
        name: 'viral',
        title: LABELS.viral
    }
]
