import { SectionsType } from '../../../../constants/initialData'
import { OPTIONS_VALUE } from './options'

export const COLLECTIONS_CONFIGS = {
    groupCollections: {
        type: SectionsType.collections,
        defaultValues: {},
        fields: {
            _id: {
                label: 'Categorias',
                type: 'select',
                options: OPTIONS_VALUE.categories
            }
        }
    }
}

// items: {
//     label: 'Items',
//     type: 'select',
//     options: OPTIONS_VALUE.items
// },

