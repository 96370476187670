import { Button, Divider, Space, Tag, Tooltip, Typography, message } from 'antd'
import React from 'react'
import { OwnershipTicketCard } from './styles'
import { ButtonLink, ButtonPopConfirm } from '@cms/events/components/Buttons'
import { useHistory, useParams } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import {
    DELETE_TICKET_OWNERSHIP,
    SET_DEFAULT_TICKET_OWNERSHIP
} from '@cms/events/graphql/mutations/tickets'
import { removeFromList } from '@cms/core/graphql/utils'
import { TDCardTitle } from '@cms/core/components/TDCards'
import { GET_TICKET_OWNERSHIPS_LIST } from '@cms/events/graphql/queries/tickets'
import {
    EventRegistrationData,
    TicketOwnershipsData
} from '@cms/events/graphql/fragments'

const { Title, Paragraph } = Typography

export interface ITicketCardProps {
    ticket: {
        id: string
        code: string
        ticket: {
            name: string
        }
        buyer: {
            name: string
        }
        transferable: boolean
        event: {
            id: string
        }
    }
    isMain: boolean
}

export function TicketCard({ ticket, isMain = false }: ITicketCardProps) {
    const history = useHistory()
    const { id, userId } = useParams() as any

    const [deleteData] = useMutation(DELETE_TICKET_OWNERSHIP, {
        update: removeFromList({
            list: 'ticketOwnerships',
            Type: 'TicketOwnership'
        })
    })

    const [setDefaultTicket] = useMutation(SET_DEFAULT_TICKET_OWNERSHIP, {
        refetchQueries: [
            {
                query: GET_TICKET_OWNERSHIPS_LIST,
                variables: { eventId: ticket.event.id, userId: userId }
            }
        ],
        update: (cache, { data }) => {
            cache.updateFragment(
                {
                    id: 'EventRegistration:' + id,
                    fragment: gql`
                        fragment UpdateRegisterTicket on EventRegistration {
                            id
                            ticketOwnership {
                                id
                            }
                        }
                    `
                },
                (_data: any) => {
                    return {
                        ..._data,
                        ticketOwnership: data.setDefaultTicketOwnership
                    }
                }
            )
        }
    })

    return (
        <OwnershipTicketCard
            title={
                <TDCardTitle style={{ padding: '0px', paddingBottom: '16px' }}>
                    <Tooltip title={ticket.ticket.name}>
                        <Paragraph style={{ margin: 0 }}>
                            {ticket.ticket.name}
                        </Paragraph>
                        {isMain ? (
                            <Tag color={'blue'}>Principal</Tag>
                        ) : (
                            <Tag color={'gray'}>Outros</Tag>
                        )}
                    </Tooltip>
                    <ButtonPopConfirm
                        key={`delete-btn-${ticket.id}`}
                        id={ticket.id}
                        action={deleteData}
                        title="Tem certeza que deseja deletar esse ticket?"
                    />
                </TDCardTitle>
            }
            actions={[
                <Space>
                    <ButtonLink
                        to={`${history.location.pathname}/transfer/${ticket.id}`}
                        key={`transfer-btn-${ticket.id}`}
                        text={'Transferir'}
                        id={undefined}
                        path={undefined}
                    />
                    <ButtonPopConfirm
                        key={`default-btn-${ticket.id}`}
                        id={ticket.id}
                        action={setDefaultTicket}
                        text="Ingresso principal"
                        title={
                            <>
                                Tem certeza que deseja tornar esse ingresso{' '}
                                <br /> o principal desse usuário?
                            </>
                        }
                        disabled={isMain}
                        danger={false}
                        ghost={isMain}
                        type="default"
                    />
                </Space>
            ]}
        >
            <Title level={5}>Código</Title>
            <p>{ticket.code}</p>

            <Divider />

            <Title level={5}>Comprador</Title>
            <p>{ticket.buyer?.name}</p>
            <Divider />

            <Title level={5}>Transferível</Title>
            <Tag color={ticket.transferable ? 'green' : 'red'}>
                {ticket.transferable ? 'Sim' : 'Não'}
            </Tag>
        </OwnershipTicketCard>
    )
}
