import React, { useEffect, useCallback } from 'react'
import AceEditor from 'react-ace'
import { merge } from 'lodash'

import { PageHeader } from '@ant-design/pro-layout'

import { Collapse, Form } from 'antd'

import { useThemeBuilderContext } from '../../context'

import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/theme-terminal'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/ext-language_tools'

const { Panel } = Collapse

const ConfigWebScripts = () => {
    const [form] = Form.useForm()
    const { scripts, setScripts } = useThemeBuilderContext()

    useEffect(() => {
        if (scripts) {
            form.setFieldsValue(scripts)
        }
    }, [scripts])

    const updateScripts = useCallback(
        (values) => {
            setScripts((o) => merge({}, { ...o }, { ...values }))
        },
        [setScripts]
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Scripts"
                subTitle="Códigos adicionais para página de transmissão"
            />
            <Form
                layout="vertical"
                form={form}
                onValuesChange={updateScripts}
                hideRequiredMark
            >
                <Collapse style={{ marginBottom: '50px' }} defaultActiveKey="1">
                    <Panel header="No header" key="1">
                        <Form.Item label="" name={['header']}>
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'html'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2
                                }}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="Na abertura do body" key="2">
                        <Form.Item label="" name={['bodyStart']}>
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'html'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2
                                }}
                            />
                        </Form.Item>
                    </Panel>
                    <Panel header="No fechamento do body" key="3">
                        <Form.Item label="" name={['bodyEnd']}>
                            <AceEditor
                                placeholder="Entre seu código"
                                mode={'html'}
                                width={'100%'}
                                height={'200px'}
                                theme="terminal"
                                name="td-control-code-editor"
                                fontSize={14}
                                showPrintMargin={true}
                                showGutter={true}
                                highlightActiveLine={true}
                                setOptions={{
                                    enableBasicAutocompletion: true,
                                    enableLiveAutocompletion: true,
                                    enableSnippets: false,
                                    showLineNumbers: true,
                                    tabSize: 2
                                }}
                            />
                        </Form.Item>
                    </Panel>
                </Collapse>
            </Form>
        </>
    )
}

export default ConfigWebScripts

