import React from 'react'

import BaseConfig from '../BaseConfig'
import { TALKS_CONFIGS } from '../../Segment/constants/talks'
import Segment from '@cms/core/components/Segment'

const Categories = (props) => {
    return (
        <>
            <BaseConfig name={props.name} />
            <Segment
                name={[props.name, 'config']}
                type="talks"
                style={{ display: 'none' }}
                filters={TALKS_CONFIGS}
                configs={{
                    hiddenFields: {
                        type: 'STAGE'
                    },
                    formName: 'sections'
                }}
            />
        </>
    )
}

export default Categories
