import styled from 'styled-components'
import { Row, Button as AntButton } from 'antd'
import { COLORS } from '@cms/core/themes'

export const Footer = styled(Row)`
    margin-left: -11px;
    margin-right: -11px;
    margin-bottom: -11px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.color.backgroundDarkDark};
    border-top: 1px solid ${({ theme }) => theme.color.backgroundDarkLight};
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
`

export const Button = styled(AntButton)``

export const Wrapper = styled.div`
    display: contents;

    .ant-affix {
        .ant-row {
            background-color: ${COLORS.bgLight};
            border-bottom-left-radius: 0px;
            border-bottom-right-radius: 0px;
        }
    }
`
