import React from 'react'
import { Button, Col, Row } from 'antd'
import { ContentBox } from './styles'

export default function CampaingButtonNew({ handleNew }) {
    return (
        <Row gutter={24}>
            <Col
                span={24}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Button type="primary" onClick={handleNew}>
                    Nova Campanha
                </Button>
            </Col>
        </Row>
    )
}
