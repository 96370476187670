import { Courses } from './Courses'

export const CoursesSections = {
    courses: {
        Component: Courses,
        name: 'courses',
        category: 'Cursos',
        label: 'Cursos',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-PT': 'Cursos',
                'pt-BR': 'Cursos',
                'en-US': 'Cursos',
                'es-ES': 'Courses'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    },
    coursesInProgress: {
        name: 'coursesInProgress',
        category: 'Cursos',
        label: 'Cursos em andamento',
        description: 'Seção sem filtro predefinidos'
    }
}

