import React from 'react'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import UserPorfileModal from './ProfileModal'

import { GET_USER_PROFILES } from '@cms/core/graphql/queries/userProfile'
import { DELETE_USER_PROFILE } from '@cms/core/graphql/mutations/userProfile'
import { removeFromList } from '@cms/core/graphql/utils'
import { useCheckModuleByPath } from '@cms/core/components/CheckModule'

const path = '/time/profiles'

const ModalRoutes = CreateModalRoutes(UserPorfileModal, path, {})

const columns = [
    {
        title: 'Nome',
        dataIndex: '',
        render: (value, obj) => {
            return <CrudColumns onlyText title={obj.node.name} />
        }
    }
]

const orderByFields = [
    { label: 'Nome', value: 'name' },
    { label: 'Data criação', value: 'createdAt' }
]

const ProfileList = () => {
    const allowedWrite = useCheckModuleByPath(path, 'write')
    const allowedDelete = useCheckModuleByPath(path, 'delete')

    const query = useQuery(GET_USER_PROFILES, {
        variables: { first: 25, orderBy: { name: 'ASC' } }
    })

    const [deleteData] = useMutation(DELETE_USER_PROFILE, {
        update: removeFromList({ list: 'userProfiles', Type: 'UserProfile' })
    })

    if (query.error) return <div>Error: {query.error}</div>

    return (
        <>
            <ModalRoutes allowed={allowedWrite} />
            <CrudList
                header={{
                    title: 'Perfil de Produtores',
                    subTitle: 'Adicione e gerencie perfis de produtores',
                    buttons: [
                        {
                            children: 'Novo Perfil',
                            type: 'primary',
                            link: path + '/adicionar',
                            actionType: 'write'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                allowed={{
                    write: allowedWrite,
                    delete: allowedDelete
                }}
                queryName={'userProfiles'}
                onDelete={deleteData}
                orderByFields={orderByFields}
                {...query}
            />
        </>
    )
}

export default ProfileList
