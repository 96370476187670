import React from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { Button, Layout, Popconfirm, Switch, Row, Col, Tabs } from 'antd'
import { DeleteOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'
import { removeFromList } from '@cms/core/graphql/utils'

import { GET_EVENT, GET_EVENTS_MAIN } from '@cms/events/graphql/queries/event'
import { DELETE_EVENT, UPDATE_EVENT } from '@cms/events/graphql/mutations/event'

import { ActiveBtnWrapper, StyledCol } from './styles'
import Loader from '@cms/core/components/Loader'

import { EventRoutes } from './routes'

const { Content } = Layout

export default function Event({ history }) {
    const dispatch = useDispatch()
    const { eventId } = useParams()

    const [deleteEvent] = useMutation(DELETE_EVENT, {
        update: removeFromList({ list: 'events', Type: 'Event' }),
        refetchQueries: [{ query: GET_EVENTS_MAIN }]
    })
    const [updateEvent] = useMutation(UPDATE_EVENT)

    const { error, data, loading } = useQuery(GET_EVENT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    const handleEventEdit = () => {
        history.push(`/evento/${eventId}/config/info`)
    }

    const handleActiveEvent = async (e) => {
        const variables = { id: eventId, isPublished: e }

        await updateEvent({ variables })
    }

    const handleEventDelete = async () => {
        await deleteEvent({
            variables: { id: eventId },
            fetchPolicy: 'no-cache'
        })
        history.push('/')
    }

    const ActiveEvent = () => (
        <ActiveBtnWrapper>
            <span style={{ marginRight: '8px' }}>Ativo</span>
            <Switch
                data-cy="activate-event"
                checked={event.isPublished || false}
                onChange={handleActiveEvent}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked
            />
        </ActiveBtnWrapper>
    )

    const { TabPane } = Tabs

    const ButtonsHeader = () => {
        const content = (
            <>
                <ActiveEvent />
                <Button
                    type="primary"
                    onClick={handleEventEdit}
                    style={{ marginRight: '5px' }}
                >
                    Editar
                </Button>
                <Popconfirm
                    title="Tem certeza que deseja deletar?"
                    onConfirm={() => handleEventDelete()}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button type="danger">
                        <DeleteOutlined />
                    </Button>
                </Popconfirm>
            </>
        )

        return <Row style={{ justifyContent: 'flex-end' }}>{content}</Row>
    }

    if (loading || error) return null
    const { event } = data.event ? data : { event: null }

    return (
        <Layout>
            {event && (
                <Content>
                    {/* <PageHeader
                        title={event.name}
                        // subTitle={event.description}
                        // extra={<ButtonsHeader />}
                        style={{ padding: '16px 0px' }}
                    /> */}
                    {/* <Nav /> */}
                    <EventRoutes />
                </Content>
            )}
        </Layout>
    )
}
