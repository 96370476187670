import React, { useEffect, useState } from 'react'
import { message, Form, Input, Select, Modal } from 'antd'

import { useHistory } from 'react-router-dom'

import { useApolloClient, useQuery } from '@apollo/client'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'

import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import {
    GET_USER,
    GET_TEAM_LIST,
    GET_TEAM_LIST_ORDERABLE
} from '@cms/core/graphql/queries/user'

import {
    CREATE_OR_UPDATE_USER,
    UPDATE_USER
} from '@cms/core/graphql/mutations/user'

import { GET_CURRENT_CUSTOMER } from '@cms/core/graphql/queries/customer'
import { GET_USER_PROFILES } from '@cms/core/graphql/queries/userProfile'

const getData = (data) => {
    return {
        ...data,
        profile: data.profile?.id
    }
}

const TeamModal = ({ ...props }) => {
    const history = useHistory()

    const [hasLimit, setHasLimit] = useState(false)
    const { data } = useQuery(GET_CURRENT_CUSTOMER, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    })

    const queryProfiles = useQuery(GET_USER_PROFILES, {
        variables: { first: 9999 }
    })

    const [profileOptions, profileSelectOptions] = useCrudSelect(
        queryProfiles,
        {
            queryName: 'userProfiles'
        }
    )

    useEffect(() => {
        if (isEdit || !data) return

        let { team } = data.currentCustomer.permissions

        if (team.amount === -1 || team.amount > team.used) {
            setHasLimit(true)
            return
        }

        Modal.warn({
            title: 'Você atingiu o limite de produtores!',
            content: (
                <p>
                    Seu plano tem limite de {team.amount} produtor(es). <br />
                    Delete um produtor para continuar.
                </p>
            ),
            onOk() {
                setTimeout(() => {
                    history.push(props.path)
                }, 200)
            }
        })
    }, [data])

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_USER,
                variables: { id }
            })

            return getData(data.user)
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_OR_UPDATE_USER,
                variables: { ...formData, role: 'admin' },
                refetchQueries: [
                    { query: GET_TEAM_LIST },
                    {
                        query: GET_TEAM_LIST_ORDERABLE,
                        variables: { first: 25, orderBy: { name: 'ASC' } }
                    }
                ]
            })

            message.success('Produtor cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_USER,
                variables: { id, ...formData, role: 'admin' },
                refetchQueries: [{ query: GET_TEAM_LIST }]
            })

            message.success('Produtor atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Produtor' : 'Criar Produtor'}
            {...modalProps}
            {...props}
            visible={isEdit ? true : hasLimit}
        >
            <Form layout={'vertical'} form={form}>
                <IgnorePasswordAutoComplete />

                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="E-mail"
                    name="email"
                    rules={[
                        { required: true, message: 'E-mail é obrigatório!' }
                    ]}
                    {...getError('email')}
                >
                    <Input />
                </Form.Item>

                <Form.Item label="Departamento" name="department" rules={[]}>
                    <Input />
                </Form.Item>

                <Form.Item label="Cargo" name="jobTitle">
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Perfil"
                    name="profile"
                    rules={[
                        {
                            required: true,
                            message: 'Perfil é obrigatório!'
                        }
                    ]}
                    {...getError('profile')}
                >
                    <Select {...profileSelectOptions}>
                        {profileOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    {...getError('password')}
                >
                    <Input.Password placeholder={isEdit ? '**********' : ''} />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default TeamModal
