import styled from 'styled-components'

import { Col } from 'antd'

import { Collapse } from 'antd'

import { Wrapper as DefaultWrapper } from '../styles'
import { COLORS } from '@cms/core/themes'

export const CustomCol = styled(Col)`
    padding: 16px;
    border-radius: 20px;
`

export const StyledCollapse = styled(Collapse)`
    > .ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }

    .ant-collapse-extra {
        margin-left: auto;
        min-width: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .ant-collapse-header {
        h3 {
            margin-bottom: 0px;
            margin-top: 0;
            color: ${COLORS.text};
        }
    }

    .ant-collapse-content-box {
        padding: 0px !important;
        border-radius: 0px !important;

        .ant-row > .ant-col {
            border-radius: 0px !important;
            background-color: unset;
        }
    }

    .move-link {
        z-index: 10;
        margin-right: 10px;
    }
`

export const ColForm = styled(Col)`
    /* background-color: rgba(255, 255, 255, 0.2); */
    padding: 16px;
    border-radius: 20px;

    .list-label {
        font-family: HelveticaNowRegular, sans-serif;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
    }

    .affix-form-footer {
        margin-left: -12px;
        margin-right: -12px;
    }
`

export const StyledCol = styled(Col)`
    padding: 20px 0px;

    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        align-items: center;
    }

    .ant-collapse-content-box {
        padding: 20px !important;
    }
`

export const StyledCustomRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`

export const Wrapper = styled(DefaultWrapper)`
    margin-bottom: 20px;
    background-color: transparent;
    border: 2px solid rgba(228, 236, 255, 0.1);
`

