import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_INTEGRATION = gql`
    mutation CreateIntegration(
        $event: ObjectID
        $name: String
        $category: String!
        $type: String!
        $active: Boolean
        $config: JSON
        $privateConfig: JSON
        $appType: AppType!
    ) {
        createIntegration(
            input: {
                event: $event
                name: $name
                category: $category
                type: $type
                active: $active
                config: $config
                privateConfig: $privateConfig
                appType: $appType
            }
        ) {
            ...IntegrationData
        }
    }

    ${fragments.IntegrationData}
`

export const CREATE_OR_UPDATE_INTEGRATION = gql`
    mutation CreateOrUpdateIntegration(
        $event: ObjectID
        $name: String
        $category: String!
        $type: String!
        $active: Boolean
        $config: JSON
        $privateConfig: JSON
        $appType: AppType!
    ) {
        createOrUpdateIntegration(
            input: {
                event: $event
                name: $name
                category: $category
                type: $type
                active: $active
                config: $config
                privateConfig: $privateConfig
                appType: $appType
            }
        ) {
            ...IntegrationData
        }
    }

    ${fragments.IntegrationData}
`

export const UPDATE_INTEGRATION = gql`
    mutation UpdateIntegration(
        $id: ObjectID!
        $event: ObjectID
        $name: String
        $category: String!
        $type: String!
        $active: Boolean
        $config: JSON
        $privateConfig: JSON
        $appType: AppType!
    ) {
        updateIntegration(
            id: $id
            input: {
                event: $event
                name: $name
                category: $category
                type: $type
                active: $active
                config: $config
                privateConfig: $privateConfig
                appType: $appType
            }
        ) {
            ...IntegrationData
        }
    }

    ${fragments.IntegrationData}
`

export const DELETE_INTEGRATION = gql`
    mutation DeleteIntegration($id: ObjectID!) {
        deleteIntegration(id: $id) {
            id
            success
        }
    }
`

