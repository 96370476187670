import React from 'react'

import { ModuleName } from './styles'

interface IHederCrumb {
    title?: React.ReactNode
    subtitle?: React.ReactNode
}

export default function HeaderCrumb({ title, subtitle }: IHederCrumb) {
    return (
        <ModuleName>
            <i className="ant-menu-submenu-arrow current-page-arrow"></i>
            <span className="ant-page-header-heading-sub-title">
                {subtitle}
            </span>
            <span className="ant-page-header-heading-title">{title}</span>
        </ModuleName>
    )
}
