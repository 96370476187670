import React from 'react'

const IgnorePasswordAutoComplete = () => {

    const styles = {
        opacity: 0, height: 0, width: 0,
        padding: 0, margin: 0, border: 0,
        position: 'absolute', top: 0, left: 0
    }
    return (
    <>
        <input
            type="email"
            style={styles}
            name="email" />
        <input
            type="password"
            style={styles}
            name="password" />
    </>
    )
}


export default IgnorePasswordAutoComplete