import React from 'react'
import { Button, Collapse, Form, Input, Tooltip } from 'antd'

import {
    DeleteOutlined,
    PlusOutlined,
    InfoCircleOutlined
} from '@ant-design/icons'
import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'
import { Wrapper, Title } from '../../../styles'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'

const { Panel } = Collapse

const Shorts = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>
                    Vídeos 9:16{' '}
                    <Tooltip
                        title={`A proporção recomendada para os vídeos desta seção é 9:16.`}
                    >
                        <InfoCircleOutlined />
                    </Tooltip>
                </Title>

                <Form.List
                    name={[props.name, 'configs', 'shortsGallery', 'items']}
                >
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Slide'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Wrapper>
                                                <Form.Item
                                                    name={[
                                                        name,
                                                        'video',
                                                        'url'
                                                    ]}
                                                    label="Url vídeo"
                                                >
                                                    <Input type={'url'} />
                                                </Form.Item>
                                                <Form.Item
                                                    name={[
                                                        name,
                                                        'video',
                                                        'thumbnail'
                                                    ]}
                                                    label="Thumbnail o vídeo"
                                                >
                                                    <Uploader />
                                                </Form.Item>
                                            </Wrapper>
                                        </Panel>
                                    )
                                )}
                            </Collapse>

                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid()
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar slide
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name={[props.name, 'configs', 'arrows', 'textColor']}
                    label="Cor das setas de navegação"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Shorts
