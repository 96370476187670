import React from 'react'
import { Collapse, PanelBodyWrapper } from './styles'
import { PanelFooter, PanelHeader } from '../components'
import { ISectionProps } from './types'

const { Panel } = Collapse

const Section = ({
    index,
    activePanel,
    section,
    setSection,
    children,
    ...props
}: ISectionProps) => {
    const bodyRef = React.useRef<HTMLDivElement>(null)
    return (
        <Collapse activeKey={activePanel} className={`field-${index}`}>
            <Panel
                className={`section-${section}`}
                header={
                    <PanelHeader
                        setSection={setSection}
                        activePanel={activePanel}
                        index={index}
                        {...props}
                    />
                }
                key={index}
                showArrow={false}
            >
                <div
                    style={{
                        position: 'relative',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        maxHeight: '50vh',
                        padding: '24px'
                    }}
                >
                    <PanelBodyWrapper ref={bodyRef}>
                        {children}
                    </PanelBodyWrapper>
                </div>
                <PanelFooter setSection={setSection} {...props} />
            </Panel>
        </Collapse>
    )
}

export default Section

