import React from 'react'

import { Form, Typography, Select } from 'antd'
import { Wrapper } from '@cms/core/components/Theme/styles'

import { IThemeSectionsProps } from './types'

const { Title } = Typography

export const SectionLogo = (props: IThemeSectionsProps) => {
    return (
        <Wrapper>
            <Title className="list-label" level={4}>
                Logo
            </Title>
            <Form.Item {...props}>
                <Select>
                    <Select.Option value="default">Original</Select.Option>
                    <Select.Option value="white">Branco</Select.Option>
                    <Select.Option value="black">Preto</Select.Option>
                </Select>
            </Form.Item>
        </Wrapper>
    )
}
