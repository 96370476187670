import Collections from './Collections'

export const CollectionsSections = {
    groupCollections: {
        Component: Collections,
        name: 'groupCollections',
        category: 'Coleções',
        label: 'Coleções',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-BR': 'Coleções',
                'en-US': 'Collections',
                'es-ES': 'Colecciones',
                'pt-PT': 'Coleções'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    }
}

