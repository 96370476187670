import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Typography } from 'antd'

import { CardGroup, CardItem, CardLogo } from '../styles'

import * as Types from './connectores'
import * as connectorsConfigs from './connectores/configs'

import { VALIDATE_MESSAGES } from '@cms/events/pages/Forms/constants/formMessages'

const connectors = Object.keys(connectorsConfigs).map(
    (key) => connectorsConfigs[key]
)
const { Title, Text } = Typography

const IntegrationsModal = ({ params, ...props }) => {
    const { field, isEdit, form } = props
    const [typeForm, setTypeForm] = useState(field.type)

    useEffect(() => {
        if (field) {
            form.setFieldsValue(field)
        }
    }, [field])

    const handleFormChangeTypeValue = (changedValues) => {
        const fieldName = Object.keys(changedValues)[0]

        if (fieldName === 'type') {
            const value = changedValues[fieldName]

            form.setFieldsValue({
                type: value
            })
            setTypeForm(value)
        }
    }

    const handleCleanType = () => {
        form.setFieldsValue({
            type: ''
        })
        setTypeForm(null)
    }

    const initialValues = {
        active: true
    }

    const TypeComponent = typeForm ? Types[typeForm] : null
    const config = connectorsConfigs[typeForm]

    return (
        <Form
            form={form}
            validateMessages={VALIDATE_MESSAGES}
            layout="vertical"
            name={isEdit ? 'integrations-edit' : 'integrations'}
            onValuesChange={handleFormChangeTypeValue}
            initialValues={initialValues}
        >
            {!typeForm && !isEdit && (
                <Form.Item name="type" rules={[{ required: true }]}>
                    <CardGroup>
                        <Title level={4}>Selecione a integração</Title>
                        {connectors.map((item) => (
                            <CardItem
                                key={item.type}
                                value={item.type}
                                color={item.color}
                            >
                                <Row align="middle" justify="center">
                                    <CardLogo src={item.logo} />
                                </Row>
                                <Row align="middle">
                                    <Col span={24}>
                                        <Title level={4}>{item.title}</Title>
                                        <Text>{item.description}</Text>
                                    </Col>
                                </Row>
                            </CardItem>
                        ))}
                    </CardGroup>
                </Form.Item>
            )}

            {typeForm ? (
                <>
                    {!isEdit && (
                        <Row gutter={[24, 24]} style={{ marginBottom: '16px' }}>
                            <Col span={24}>
                                <Button
                                    onClick={handleCleanType}
                                    type="primary"
                                >
                                    Voltar
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Form.Item
                        className="field-vertical"
                        label="Ativo"
                        name={['active']}
                        style={{ display: 'none' }}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        className="field-vertical"
                        label="Tipo"
                        name={['type']}
                        style={{ display: 'none' }}
                    >
                        <Input disabled />
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Nome é obrigatório!'
                                    }
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>

                    {TypeComponent && (
                        <TypeComponent
                            config={config}
                            form={form}
                            params={params}
                        />
                    )}
                </>
            ) : null}
        </Form>
    )
}

export default IntegrationsModal
