import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const UPLOAD_MEDIA = gql`
    mutation Upload( $file: Upload!, $options: UploadOptions ) {
        uploadMedia(file: $file, options: $options) {
            ...MediaData
        }
    }

    ${fragments.MediaData}
`