import styled from 'styled-components'

export const ModalContainer = styled.div`
    display: contents;

    .field-vertical {
        flex-flow: column;

        .ant-form-item-label {
            text-align: left;
        }
    }

    .field-isediting {
        input {
            opacity: 0.5;
        }
    }

    .field-middle {
        align-items: center;
        margin-bottom: 0;
    }

    .ant-space-item {
        margin-bottom: 24px;

        .ant-form-item {
            margin-bottom: 0;
        }
    }
`
