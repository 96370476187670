export const SectionsType = {
    talks: 'talks',
    banners: 'banners',
    stages: 'stages',
    categories: 'categories',
    series: 'series',
    rewards: 'rewards',
    collections: 'groupCollections',
    speakers: 'speakers'
}

export const SECTIONS_MOCK = {
    sections: [
        {
            name: 'Banners',
            type: SectionsType.banners,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Banners',
                    'en-US': 'Banners',
                    'es-ES': 'Banners',
                    'pt-PT': 'Banners'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'pages',
                            type: '$in',
                            value: ['Home']
                        }
                    ]
                }
            }
        },
        {
            name: 'Palcos',
            type: SectionsType.stages,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Palcos',
                    'en-US': 'Stages',
                    'es-ES': 'Escenarios',
                    'pt-PT': 'Palcos'
                },
                variant: 'cta'
            }
        },
        {
            name: 'Próximas palestras',
            type: SectionsType.talks,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Próximas palestras',
                    'en-US': 'Next talks',
                    'es-ES': 'Próximas conferencias',
                    'pt-PT': 'Próximas palestras'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'type',
                            type: '$eq',
                            value: 'LIVE'
                        },
                        {
                            field: 'status',
                            type: '$eq',
                            value: 'WAITING'
                        }
                    ]
                }
            }
        },
        {
            name: 'Palestras anteriores',
            type: SectionsType.talks,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Palestras anteriores',
                    'en-US': 'Previous talks',
                    'es-ES': 'Conferencias anteriores',
                    'pt-PT': 'Palestras anteriores'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'type',
                            type: '$eq',
                            value: 'LIVE'
                        },
                        {
                            field: 'status',
                            type: '$eq',
                            value: 'FINISHED'
                        }
                    ]
                }
            }
        },
        {
            name: 'Trilhas',
            type: SectionsType.categories,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Trilhas',
                    'en-US': 'Tracks',
                    'es-ES': 'Pistas',
                    'pt-PT': 'Trilhas'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'type',
                            type: '$eq',
                            value: 'MAIN'
                        }
                    ]
                }
            }
        },
        {
            name: 'Séries',
            type: SectionsType.series,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Séries',
                    'en-US': 'Series',
                    'es-ES': 'Series',
                    'pt-PT': 'Séries'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'type',
                            type: '$eq',
                            value: 'SERIE'
                        }
                    ]
                }
            }
        },
        {
            name: 'Recompensas',
            type: SectionsType.rewards,
            active: true,
            config: {}
        },
        {
            name: 'Coleções',
            type: 'groupCollections',
            active: true,
            config: {
                title: {
                    'pt-BR': 'Coleções',
                    'en-US': 'Collections',
                    'es-ES': 'Colecciones',
                    'pt-PT': 'Coleções'
                },
                variant: 'withCta'
            }
        },

        {
            name: 'Palestrantes destaques',
            type: SectionsType.speakers,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Palestrantes destaques',
                    'en-US': 'Highlight speakers',
                    'es-ES': 'Conferenciantes destacados',
                    'pt-PT': 'Palestrantes destaques'
                },
                variant: 'highlight',
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'featured',
                            type: '$eq',
                            value: true
                        }
                    ]
                }
            }
        },
        {
            name: 'Palestrantes',
            type: SectionsType.speakers,
            active: true,
            config: {
                title: {
                    'pt-BR': 'Palestrantes',
                    'en-US': 'Speakers',
                    'es-ES': 'Conferenciantes',
                    'pt-PT': 'Palestrantes'
                },
                condition: {
                    type: 'AND',
                    filters: [
                        {
                            field: 'featured',
                            type: '$ne',
                            value: true
                        }
                    ]
                }
            }
        }
    ]
}
