import { Categories } from './Categories'

export const CategoriesSections = {
    categories: {
        Component: Categories,
        name: 'categories',
        category: 'Categorias',
        label: 'Categorias',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-PT': 'Categorias',
                'pt-BR': 'Categorias',
                'en-US': 'Categories',
                'es-ES': 'Categorias'
            },
            condition: {
                type: 'AND',
                filters: []
            }
        }
    }
}
