import { GET_BANNER_SELECT_LIST } from '@cms/events/graphql/queries/banner'
import { GET_TALKS_SELECT_LIST } from '@cms/events/graphql/queries/talk'

export const OPTIONS_VALUE = {
    all: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_BANNER_SELECT_LIST,
            variables: {
                eventId
            }
        })

        const parsedData =
            (data &&
                data.banners.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    talks: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_TALKS_SELECT_LIST,
            variables: {
                eventId
            }
        })

        const parsedData =
            (data &&
                data.talks.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    }
}
