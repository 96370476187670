import React, { useEffect, useRef, useState } from 'react'

import {
    Form,
    Row,
    Typography,
    Switch,
    Col,
    Collapse,
    Select,
    Button,
    Space
} from 'antd'

const { Panel } = Collapse
import {
    ColForm,
    CustomCol,
    StyledCol,
    StyledCollapse,
    StyledCustomRow,
    Wrapper
} from './styles'

import {
    DeleteOutlined,
    PlusOutlined,
    CaretDownOutlined,
    CaretUpOutlined,
    CopyOutlined
} from '@ant-design/icons'
import MenuItem from './MenuItem'
import Affix from '@cms/core/components/Affix'
import { useThemeBuilderContext } from '../../context'
import { PanelFooter } from '@cms/events/pages/Config/PageBuilder/components'

const { Title } = Typography

export const ThemeBuilderConfigNavigation = () => {
    const [form] = Form.useForm()
    const { navigation, setNavigation } = useThemeBuilderContext()
    const previewRef = useRef(null)
    const colRef = useRef(null)

    useEffect(() => {
        form.setFieldsValue(navigation)
    }, [navigation])

    const updateSection = () => {
        const data = form.getFieldsValue()

        const _data = {
            navigation: { ...data }
        }

        if (_data && previewRef.current) {
            previewRef.current.Emit('fullPage', _data)
        }
    }

    const [linkType, setLinkType] = useState('link')

    if (!navigation) return null

    // const onInit = (instance) => {
    //     previewRef.current = instance

    //     if (previewRef.current) {
    //         previewRef.current.Emit('fullPage', {
    //             ...site,
    //             sections: site.pages.home.sections
    //         })
    //         previewRef.current.handleParentWidth(colRef.current.offsetWidth)
    //         previewRef.current.handleParentHeight(600)
    //     }
    // }

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <CustomCol md={12} sm={24}>
                <Form
                    name="menu"
                    form={form}
                    layout="vertical"
                    onValuesChange={updateSection}
                    style={{}}
                >
                    <StyledCollapse>
                        <Panel
                            header={
                                <Title level={3}>
                                    {'Configurações Gerais'}
                                </Title>
                            }
                            key="1"
                        >
                            <ColForm md={24} sm={24}>
                                <Wrapper>
                                    <StyledCustomRow>
                                        <Title
                                            level={5}
                                            style={{ display: 'inline-block' }}
                                        >
                                            Habilitar Menu
                                        </Title>
                                        <Form.Item
                                            name={'active'}
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </StyledCustomRow>

                                    <StyledCustomRow>
                                        <Title
                                            level={5}
                                            style={{ display: 'inline-block' }}
                                        >
                                            Menu Fixado
                                        </Title>
                                        <Form.Item
                                            name={'fixed'}
                                            valuePropName="checked"
                                            noStyle
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </StyledCustomRow>

                                    <Form.Item noStyle shouldUpdate>
                                        {({ getFieldValue }) => {
                                            const fixed = getFieldValue('fixed')
                                            return (
                                                fixed && (
                                                    <>
                                                        <StyledCustomRow>
                                                            <Title
                                                                level={5}
                                                                style={{
                                                                    display:
                                                                        'inline-block'
                                                                }}
                                                            >
                                                                Comportamento do
                                                                menu
                                                            </Title>
                                                            <Form.Item
                                                                name={
                                                                    'fixedType'
                                                                }
                                                                initialValue="alwaysVisible"
                                                                noStyle
                                                            >
                                                                <Select>
                                                                    <Select.Option value="alwaysVisible">
                                                                        Sempre
                                                                        visível
                                                                    </Select.Option>
                                                                    <Select.Option value="hiddenInTop">
                                                                        Ocultar
                                                                        no topo
                                                                        da
                                                                        pagina
                                                                    </Select.Option>
                                                                    <Select.Option value="transparentInTop">
                                                                        Fundo
                                                                        transparente
                                                                        no topo
                                                                        da
                                                                        pagina
                                                                    </Select.Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </StyledCustomRow>
                                                    </>
                                                )
                                            )
                                        }}
                                    </Form.Item>
                                </Wrapper>

                                <PanelFooter setSection={setNavigation} />
                            </ColForm>
                        </Panel>
                    </StyledCollapse>

                    <StyledCollapse>
                        <Panel
                            header={<Title level={3}>{'Links'}</Title>}
                            key="1"
                        >
                            <StyledCol md={24} sm={24}>
                                <Form.List name={'links'}>
                                    {(fields, { add, remove, move }) => (
                                        <>
                                            <Collapse>
                                                {fields.map(
                                                    ({ key, name }, i) => {
                                                        const enableUpClick =
                                                            name > 0
                                                        const enableDownClick =
                                                            name <
                                                            fields.length - 1

                                                        const moveUp =
                                                            name > 0 &&
                                                            (() =>
                                                                move(
                                                                    name,
                                                                    name - 1
                                                                ))

                                                        const moveDown =
                                                            name <
                                                                fields.length -
                                                                    1 &&
                                                            (() =>
                                                                move(
                                                                    name,
                                                                    name + 1
                                                                ))

                                                        const field =
                                                            form.getFieldValue([
                                                                'links',
                                                                name
                                                            ])

                                                        return (
                                                            <Panel
                                                                header={
                                                                    field?.name ||
                                                                    'Novo link'
                                                                }
                                                                key={key}
                                                                extra={
                                                                    <>
                                                                        <div className="move-link">
                                                                            {moveUp && (
                                                                                <CaretUpOutlined
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation()
                                                                                        moveUp()
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {moveDown && (
                                                                                <CaretDownOutlined
                                                                                    onClick={(
                                                                                        e
                                                                                    ) => {
                                                                                        e.stopPropagation()
                                                                                        moveDown()
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </div>
                                                                        <Space>
                                                                            <CopyOutlined
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation()
                                                                                    add(
                                                                                        {
                                                                                            ...field,
                                                                                            name: `${field.name} (cópia)`
                                                                                        }
                                                                                    )
                                                                                }}
                                                                            />
                                                                            <DeleteOutlined
                                                                                onClick={(
                                                                                    e
                                                                                ) => {
                                                                                    e.stopPropagation()
                                                                                    remove(
                                                                                        name
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </Space>
                                                                    </>
                                                                }
                                                            >
                                                                <MenuItem
                                                                    key={key}
                                                                    form={form}
                                                                    name={name}
                                                                    remove={
                                                                        remove
                                                                    }
                                                                    moveUp={
                                                                        moveUp
                                                                    }
                                                                    moveDown={
                                                                        moveDown
                                                                    }
                                                                />
                                                            </Panel>
                                                        )
                                                    }
                                                )}
                                            </Collapse>
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add({})}
                                                    block
                                                    icon={<PlusOutlined />}
                                                >
                                                    Adicionar Link
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                                <PanelFooter setSection={setNavigation} />
                            </StyledCol>
                        </Panel>
                    </StyledCollapse>
                </Form>
            </CustomCol>
            <Col md={12} sm={24} ref={colRef}>
                {/* <Affix offsetTop={104}>
                    <Preview onInit={onInit} ref={previewRef} page="home" />
                </Affix> */}
            </Col>
        </Row>
    )
}

export default ThemeBuilderConfigNavigation

