import { IAppModule } from '@cms/core/types/app'
import { sidebarIcons } from '@cms/core/components/RemixIcons'
import { crudActions } from '@cms/core/components/Sidebar/common'

export const WatchModules: IAppModule[] = [
    {
        name: 'Usuários',
        icon: sidebarIcons.users,
        module: 'watch.users',
        type: 'main',
        children: [
            {
                name: 'Usuários',
                alias: true,
                path: '/usuarios',
                module: 'watch.users',
                children: crudActions('watch.users')
            }
        ]
    },
    {
        name: 'Planos',
        icon: sidebarIcons.payments,
        module: 'watch.plans',
        type: 'main',
        children: [
            {
                alias: true,
                name: 'Planos',
                path: '/planos',
                module: 'watch.plans',
                children: crudActions('watch.plans')
            }
        ]
    },
    {
        name: 'Especialistas',
        icon: sidebarIcons.speakers,
        module: 'watch.experts',
        type: 'main',
        children: [
            {
                name: 'Especialistas',
                alias: true,
                path: '/especialistas',
                module: 'watch.experts',
                children: crudActions('watch.experts')
            }
        ]
    },
    {
        name: 'Conteúdos',
        icon: sidebarIcons.content,
        module: 'watch.content',
        type: 'main',
        children: [
            {
                name: 'Vídeos',
                path: '/conteudo/videos',
                module: 'watch.content.videos',
                children: crudActions('watch.content.videos')
            },
            {
                name: 'Categorias',
                path: '/conteudo/categorias',
                module: 'watch.content.categories',
                children: crudActions('watch.content.categories')
            }
        ]
    },
    {
        name: 'Cursos',
        icon: sidebarIcons.courses,
        module: 'watch.course',
        type: 'main',
        children: [
            {
                name: 'Cursos',
                path: '/cursos',
                excludePaths: ['/cursos/videos(.*)'],
                module: 'watch.course.list',
                children: crudActions('watch.course.list')
            },
            {
                name: 'Vídeos',
                path: '/cursos/videos',
                module: 'watch.course.videos',
                children: crudActions('watch.course.videos')
            }
        ]
    },
    {
        name: 'Integrações',
        icon: sidebarIcons.integrations,
        module: 'watch.integrations',
        type: 'main',
        children: [
            {
                alias: true,
                name: 'Players',
                path: `/integracoes/players`,
                module: 'watch.integrations.players',
                children: crudActions('watch.integrations.players')
            }
        ]
    },
    {
        name: 'Configurações',
        icon: sidebarIcons.settings,
        module: 'watch.config',
        type: 'footer',
        children: [
            {
                name: 'Configurações',
                path: '/config',
                module: 'watch.config.configs'
            },
            {
                name: 'Domínio',
                path: '/dominio',
                module: 'watch.config.domain'
            }
        ]
    }
]

