import { gql } from '@apollo/client'

export const GET_EVENT_FORMS_OPTIONS = gql`
    query GetEventForms($first: Int, $afterCursor: Cursor, $eventId: ObjectID) {
        eventForms(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, type: progressive }
        ) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`
