export const rdStation = {
    type: 'rdStation',
    color: '#364a65',
    logo: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/rd-station-1637262681917.svg',
    title: 'RD Station',
    description: 'Capture suas inscrições em forma de leads'
}
export const webhook = {
    type: 'webhook',
    color: '#364a65',
    logo: 'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/webhook-1637613687893.svg',
    title: 'Webhook Genérico',
    description:
        'Envie os dados da conversão ​​para uma URL específico na web. Este pode ser seu próprio script privado ou um endpoint de API de terceiros.'
}
