import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Typography, Space, Button } from 'antd'
import { useQuery, useMutation } from '@apollo/client'
import { DownloadOutlined, EditOutlined } from '@ant-design/icons'

import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'
import { GET_EVENT_SITE } from '@cms/events/graphql/queries/event'
import { ButtonLink } from '@cms/events/components/Buttons'

import { templates } from '../../templates'
import PreviewModal from './PreviewModal'
import { Content, OverlayStyled, TemplatItem, Wrapper } from './styles'
import { usePageBuilderContext } from '../../context/context'
import Loader from '@cms/core/components/Loader'
import ImportModal from './ImportModal'
import ConfirmationModal from './ConfirmationModal'

const { Title, Text } = Typography

const TemplateSelection = () => {
    const history = useHistory()
    const { eventId } = useParams()

    const { setSite } = usePageBuilderContext()
    const [updateSite] = useMutation(UPDATE_SITE)

    const [previewData, setPreviewData] = useState(false)
    const [selectedEvent, setSelectedEvent] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [showConfirmationImportModal, setShowConfirmationImportModal] =
        useState(false)

    const { data: dataEvent, loading } = useQuery(GET_EVENT_SITE, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    const handlePublishedSite = async (template) => {
        const { id } = dataEvent.event.site

        await updateSite({
            variables: {
                id,
                draft: template
            }
        })
        setSite(null)

        history.push(`/evento/${eventId}/paginas`)
    }

    const handlePreview = (data) => {
        setPreviewData(data)
    }

    const handleToggleImportModal = () => {
        setShowImportModal((show) => !show)
    }

    const handleImportTemplate = (event) => {
        setSelectedEvent(event)
        handleToggleImportModal()
        setShowConfirmationImportModal(true)
    }

    const Overlay = ({ template, name, data }) => {
        return (
            <OverlayStyled>
                <Space direction="vertical">
                    <Title level={3}>{name}</Title>
                    <ButtonLink
                        icon={<EditOutlined />}
                        onClick={() => handlePublishedSite(template)}
                        text="Começar a editar"
                    />
                    <Button type="primary" onClick={() => handlePreview(data)}>
                        Visualizar
                    </Button>
                </Space>
            </OverlayStyled>
        )
    }

    if (loading) return <Loader fixed={false} padding='10px' />

    return (
        <Wrapper>
            <Row justify="center">
                <Title>Selecione um template</Title>
            </Row>

            <Row gutter={20} justify="center">
                <Content>
                    <TemplatItem className="import">
                        <Text>Importar site de outro evento</Text>
                        <Button
                            type="primary"
                            onClick={() => handleToggleImportModal()}
                        >
                            <DownloadOutlined />
                            Importar template
                        </Button>
                    </TemplatItem>
                    {templates.map((template) => {
                        const _template = template(dataEvent.event)
                        const {
                            layout,
                            info: { name, image }
                        } = _template
                        const data = {
                            ..._template,
                            sections: _template.pages.home.sections
                        }

                        return (
                            <TemplatItem key={layout}>
                                <img src={image} />
                                <Overlay
                                    name={name}
                                    template={_template}
                                    data={data}
                                />
                            </TemplatItem>
                        )
                    })}
                </Content>

                {previewData && (
                    <PreviewModal
                        data={previewData}
                        setPreviewData={setPreviewData}
                    />
                )}

                {showImportModal && (
                    <ImportModal
                        onCancel={handleToggleImportModal}
                        onImport={handleImportTemplate}
                    />
                )}

                {showConfirmationImportModal && (
                    <ConfirmationModal
                        event={selectedEvent}
                        onCancel={() => setShowConfirmationImportModal(false)}
                    />
                )}
            </Row>
        </Wrapper>
    )
}

export default TemplateSelection
