import { gql } from '@apollo/client'
import { UserProfileData } from '../fragments'

export const GET_USER_PROFILES = gql`
    query GetUserProfiles(
        $first: Int
        $afterCursor: Cursor
        $orderBy: UserProfileOrderBy
        $search: String
    ) {
        userProfiles(
            first: $first
            after: $afterCursor
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...UserProfileData
                }
            }
        }
    }

    ${UserProfileData}
`

export const GET_USER_PROFILE = gql`
    query GetCategory($id: ObjectID!) {
        userProfile(id: $id) {
            ...UserProfileData
        }
    }

    ${UserProfileData}
`
