import React from 'react'
import { Modal, Button, Divider, message } from 'antd'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-monokai'

const ModalScripts = ({ onCancel, visible, code }) => {
    const handleCopyClipboard = () => {
        navigator.clipboard.writeText(code)
        message.success('Copiado com sucesso!')
    }

    const scriptCode = `<script>
!function(e){e.TD=e.TD||{},e.TD.widgets=e.TD.widgets||{},e.TD._listeners=e.TD._listeners||{},e.TD.on=function(s,t){e.TD._listeners[s]=e.TD._listeners[s]||[],e.TD._listeners[s].push(t)};const s=document.createElement("script");s.src="${process.env.WIDGET_JS}",s.async=!0,document.body.appendChild(s)}(window);
</script>
    `

    return (
        (<Modal
            title="Código de integração"
            open={visible}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Voltar
                </Button>
            ]}
        >
            <div>
                <Divider orientation="left">
                    Cole o código abaixo, preferivelmente logo após a abertura
                    do body
                </Divider>
                <AceEditor
                    onFocus={handleCopyClipboard}
                    value={scriptCode}
                    readOnly={true}
                    mode="html"
                    width={'472px'}
                    height={'100px'}
                    wrapEnabled={true}
                    theme="monokai"
                    name="x-scale-script"
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                    }}
                />
                <Divider orientation="left">
                    Cole o código abaixo, aonde ele deve aparecer em sua página
                </Divider>
                <AceEditor
                    onFocus={handleCopyClipboard}
                    value={code}
                    readOnly={true}
                    mode="html"
                    width={'472px'}
                    height={'100px'}
                    wrapEnabled={true}
                    theme="monokai"
                    name="x-scale-div"
                    fontSize={14}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                    }}
                />
            </div>
        </Modal>)
    );
}

export default ModalScripts
