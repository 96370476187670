import React from 'react'
import { useParams } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'
import { useQuery, useMutation } from '@apollo/client'

import {
    CrudColumns,
    CrudList,
    CreateModalRoutes
} from '@cms/core/components/Crud'

import FaqTopicsModal from './FaqTopicsModal'

import { GET_FAQ_TOPICS_LIST_ORDERABLE } from '@cms/events/graphql/queries/faqTopics'
import { DELETE_FAQ_TOPIC } from '@cms/events/graphql/mutations/faqTopics'

import dayjs from 'dayjs'

const columns = [
    {
        title: 'Título',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.name]} />
        }
    },
    {
        title: 'Envio',
        dataIndex: '',
        render: (_, obj) => {
            const date = dayjs(new Date(obj.node.createdAt)).format(
                'DD MMM YYYY [-] HH:mm'
            )
            return <CrudColumns onlyText text={[date]} />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const FaqTopicList = () => {
    const { eventId } = useParams()
    const variables = {
        first: 25,
        eventId,
        orderBy: { createdAt: 'DESC' }
    }

    const query = useQuery(GET_FAQ_TOPICS_LIST_ORDERABLE, { variables })

    if (query.error) {
        return <div>Error</div>
    }

    const path = `/evento/${eventId}/faq/topicos`

    const [deleteData] = useMutation(DELETE_FAQ_TOPIC, {
        refetchQueries: [{ query: GET_FAQ_TOPICS_LIST_ORDERABLE, variables }]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(FaqTopicsModal, path, { eventId })
    }, [eventId])

    const filterFields = [
        { name: 'name', label: 'Título', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Tópicos',
                    subTitle: 'Crie tópicos para o FAQ',
                    buttons: [
                        {
                            children: 'Novo Tópico',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'faqTopics'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default FaqTopicList

