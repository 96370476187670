import React, { useEffect } from 'react'
import { Form, Row, Input, Divider, Col, Switch } from 'antd'

import {
    Uploader,
    PanelFooter,
    ColorsBadges,
    ButtonConfig,
    DatePicker,
    Preview,
    RedirectOptions
} from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'
import { Title } from '../../styles'
import { useRef } from 'react'
import { merge } from 'lodash'
import Affix from '@cms/core/components/Affix'

const ModalFormStyles = (props) => {
    const [form] = Form.useForm()
    const { site, setSite } = usePageBuilderContext()
    const previewRef = useRef(null)
    const colRef = useRef(null)

    const updateModalForm = (values) => {
        const formModal = merge(
            {},
            site.formModal,
            form.getFieldsValue(),
            values
        )

        // setSite({
        //     ...site,
        //     formModal
        // })

        if (previewRef.current) {
            previewRef.current.handleParentWidth(colRef?.current?.offsetWidth)
            previewRef.current.Emit('fullPage', {
                ...site,
                visibleFormModal: true,
                formModal: {
                    type: 'formModal',
                    active: true,
                    configs: {
                        form: formModal
                    }
                }
            })
        }
    }

    const onInit = (instance) => {
        previewRef.current = instance

        if (previewRef.current) {
            previewRef.current.Emit('fullPage', {
                ...site,
                visibleFormModal: true,
                formModal: {
                    type: 'formModal',
                    active: true,
                    configs: {
                        form: merge({}, site.formModal, form.getFieldsValue())
                    }
                }
            })
            previewRef.current.handleParentWidth(colRef.current.offsetWidth)
            previewRef.current.handleParentHeight(600)
        }
    }

    useEffect(() => {
        form.setFieldsValue(site.formModal)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm lg={12} md={12} sm={24}>
                <Form
                    name="formModal"
                    form={form}
                    layout="vertical"
                    onValuesChange={updateModalForm}
                >
                    <Title level={4}>Formulário</Title>
                    <Form.Item name={['box', 'bgColor']} label="Cor de fundo">
                        <ColorsBadges />
                    </Form.Item>
                    <Form.Item name={['box', 'textColor']} label="Cor do texto">
                        <ColorsBadges />
                    </Form.Item>
                    <RedirectOptions
                        initialValue={{ page: 'indication', mode: 'page' }}
                        excludeModes={['formModal']}
                    />
                    <Divider orientation="left">Título</Divider>
                    <Form.Item name={['headline', 'text']} label="Texto">
                        <Input.TextArea />
                    </Form.Item>
                    <ButtonConfig
                        fieldPath={['button']}
                        inner={true}
                        {...props}
                    />
                    <Divider orientation="left">Contador</Divider>
                    <Form.Item
                        name={['countdown', 'enabled']}
                        label="Exibir contador"
                        initialValue={true}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item name={['countdown', 'text']} label="Texto">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['countdown', 'textColor']}
                        label="Cor do contador"
                    >
                        <ColorsBadges />
                    </Form.Item>
                    <Form.Item name={['countdown', 'date']}>
                        <DatePicker
                            showTime
                            needConfirm={false}
                            style={{ width: '100%' }}
                            format={'DD MMM YYYY HH:mm:ss'}
                            placeholder=""
                        />
                    </Form.Item>

                    <PanelFooter customPage />
                </Form>
            </ColForm>
            <Col lg={12} md={12} sm={24} ref={colRef}>
                <Affix offsetTop={104}>
                    <div>
                        <div>
                            <Preview
                                onInit={onInit}
                                ref={previewRef}
                                page={'home'}
                            />
                        </div>
                    </div>
                </Affix>
            </Col>
        </Row>
    )
}

export default ModalFormStyles
