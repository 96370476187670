import styled from 'styled-components'
import { Steps as StepsAnts, Button as ButtonAnt } from 'antd'

export const Steps = styled(StepsAnts)`
    .ant-steps-item-container {
        cursor: pointer;
        display: block;
        width: 100%;
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
`
export const StepsContent = styled.div`
    width: 100%;
    margin-top: 16px;
    border-radius: 2px;
    min-height: 200px;
    text-align: center;
    padding: 20px;
`

export const StepsActions = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 24px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 20px;

    .ant-btn-default {
        border: 1px solid #bab7ba;
    }
`

export const Button = styled(ButtonAnt)``
