import React from 'react'

import Line from './Line'
import { useQuery } from '@apollo/client'
import {
    GET_COUNT_REGISTER_SOCIAL,
    GET_COUNT_ALL_REGISTER_REFERAL
} from '@cms/events/graphql/queries/eventRegistration'
import Empty from '@cms/core/components/Empty'

import { Row, Col } from 'antd'
import { ShareAltOutlined } from '@ant-design/icons'
import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../styles'
import styled from 'styled-components'

const Channels = ({ eventId }) => {
    let headerOn = false

    const { data } = useQuery(GET_COUNT_REGISTER_SOCIAL, {
        variables: { eventId: eventId }
    })
    const { data: TotalRegistersReferalData } = useQuery(
        GET_COUNT_ALL_REGISTER_REFERAL,
        {
            variables: { eventId: eventId }
        }
    )
    const TotalRegistersReferal = TotalRegistersReferalData
        ? TotalRegistersReferalData.countOfUserRegisteredByReferral
        : 0

    const capitalizedSocial = (string) =>
        string.charAt(0).toUpperCase() + string.slice(1)
    const getIconName = (string) => {
        return string
    }

    const formatData = () => {
        const parsedData = Object.keys(data.countOfUserRegisteredBySocial).map(
            (key) => {
                if (key !== '__typename') {
                    const social = capitalizedSocial(key)
                    const value = data.countOfUserRegisteredBySocial[key]

                    if (value > 0) headerOn = true

                    return {
                        name: social,
                        value: data.countOfUserRegisteredBySocial[key],
                        icon: getIconName(social),
                        totalRegisters: TotalRegistersReferal
                    }
                    // return { name: social, value: 2, icon: getIconName(social), totalRegisters: TotalRegistersReferal }
                }
            }
        )

        return parsedData.filter((item) => (item ? item : null))
    }

    const socials = data ? formatData() : []
    const totalConversions = socials.reduce((accumulator, object) => {
        return accumulator + object.salary
    }, 0)

    const StyledRow = styled(Row)`
        height: calc(100% - 56px);
        justify-content: center;
        align-items: center;

        .ant-empty-normal {
            margin: 32px 0px 64px 0px;
        }
    `

    return (
        <div style={{ padding: '16px 12px', height: '100%' }}>
            <DashboardCardTitle>
                <IconWrapper>
                    <ShareAltOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Por canais</DashboardTitle>
            </DashboardCardTitle>

            {totalConversions > 0 ? (
                <>
                    {socials.map((item) => (
                        <Line
                            key={item.name}
                            title={'Inscrições'}
                            icon={item.icon}
                            channel={item.name}
                            conversion={item.value}
                            totalIndications={item.totalRegisters}
                        />
                    ))}
                </>
            ) : (
                <StyledRow>
                    <Col>
                        <Empty />
                    </Col>
                </StyledRow>
            )}
        </div>
    )
}

export default Channels
