import React, { useEffect, useCallback, useMemo } from 'react'
import { mergeWith } from 'lodash'

import { PageHeader } from '@ant-design/pro-layout'

import { Form, Tabs } from 'antd'

import { useThemeBuilderContext } from '../../context'
import { DEFAULT_SECTIONS } from './constant'
import { ConfigFields } from './fields'

const { TabPane } = Tabs

const ThemeBuilderConfigs = () => {
    const [form] = Form.useForm()
    const {
        configs,
        setConfigs,
        sections: _sections,
        getSectionConfig
    } = useThemeBuilderContext()
    const currentConfigs = useMemo(() => {
        const section = getSectionConfig('Configs')

        return {
            title: 'Configurações',
            subTitle: 'Configurações gerais da página de transmissão',
            sections: DEFAULT_SECTIONS,
            ...section
        }
    }, [_sections])

    useEffect(() => {
        if (configs) {
            form.setFieldsValue(configs)
        }
    }, [configs])

    const updateConfigs = useCallback(
        (values) => {
            setConfigs((o) => {
                return mergeWith(
                    { ...o },
                    { ...values },
                    (objValue, srcValue) => {
                        if (Array.isArray(objValue)) {
                            return srcValue
                        }
                    }
                )
            })
        },
        [setConfigs]
    )

    if (!currentConfigs?.sections?.length) return null

    const { sections, subTitle, title } = currentConfigs

    return (
        <>
            <PageHeader
                className="site-page-header"
                title={title}
                subTitle={subTitle}
            />
            <Form
                className="bg-light"
                layout="vertical"
                form={form}
                onValuesChange={updateConfigs}
                hideRequiredMark
            >
                <Tabs defaultActiveKey={sections[0].name}>
                    {sections.map((section) => {
                        return (
                            <TabPane tab={section.title} key={section.name}>
                                <ConfigFields fields={section.fields} />
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Form>
        </>
    )
}

export default ThemeBuilderConfigs

