import React from "react";
import { Circle, Wrapper } from "./styles";

const TalkNotificationStatus = ({status}) => {

    return (
        <Wrapper type={status ? 'ACTIVE' : 'NOT_ACTIVE'}>
            <Circle type={status ? 'ACTIVE' : 'NOT_ACTIVE'}/>
            <span>{status ? 'ATIVO' : 'DESATIVADO'}</span>
        </Wrapper>
    )
}

export default TalkNotificationStatus