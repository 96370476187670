import React from 'react'
import Lottie from 'react-lottie'
import * as xAnimation from './X.json'

import { LoaderWrapper, FirstLoader, SecondLoader, ThirdLoader } from './styles'

const LoaderX = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: xAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    }

    return (
        <LoaderWrapper>
            <Lottie options={defaultOptions} />
        </LoaderWrapper>
    )
}

export default LoaderX
