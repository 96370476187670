import styled from 'styled-components'

export const StyledCard = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background-color: rgba(255, 255, 255, 0.2);

    padding: 20px;
    margin-bottom: 20px;

    border-radius: 20px;
`

export const StyledContent = styled.div`
    display: flex;
    justify-content: space-between;

    & > div {
        flex: 1;
        padding: 0 20px;
    }

    & > div:first-child {
        border-right: 1px solid #383838;

        padding-left: 5px;
        padding-right: 15px;
    }
`

export const StyledFooter = styled.div`
    display: flex;
    justify-content: flex-end;
`

export const Title = styled.span`
    font-size: 1.7em;

    font-weight: bold;
    color: #fff;
`
