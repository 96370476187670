import React from 'react'

import { Typography, Row, Col } from 'antd'

import {
    MediaIcon,
    Name,
    Percent,
    Quantity,
    StyledCol,
    StyledRow
} from './styles'
import {
    WhatsappIcon,
    FacebookIcon,
    LinkedinIcon,
    LinkIcon,
    InstagramIcon
} from '@cms/core/components/Icons'

const { Text } = Typography

const SocialMetric = ({ eventId, nameSocial, total, percentage }) => {
    const renderIcon = () => {
        if (nameSocial === 'Whatsapp') return <WhatsappIcon size={44} />
        if (nameSocial === 'Facebook')
            return <FacebookIcon fill="#003FEE" size={44} />
        if (nameSocial === 'LinkedIn')
            return <LinkedinIcon fill="#0274b3" size={44} />
        if (nameSocial === 'Instagram')
            return <InstagramIcon fill="#B93EFF" size={44} />
        if (nameSocial === 'Link')
            return <LinkIcon size={44} badge={'#EF6C0C'} fill={'#fff'} />
    }

    return (
        <StyledRow>
            <Row>
                <MediaIcon>{renderIcon()}</MediaIcon>
                <StyledCol>
                    <Name>{nameSocial}</Name>
                    <div>
                        <Text>Conversão </Text>
                        <Percent
                            style={{
                                color: '#fff'
                            }}
                        >
                            {percentage || '0%'}
                        </Percent>
                    </div>
                </StyledCol>
            </Row>
            <Row>
                <Quantity
                    style={{
                        color: '#fff'
                    }}
                >
                    {total || '0'}
                </Quantity>
            </Row>
        </StyledRow>
    )
}

export default SocialMetric
