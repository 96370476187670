import React from 'react'
import { Switch } from 'react-router-dom'

import EngagementEditPage from './Edit'
import EngagementListPage from './List'
import PrivateRoute from '@cms/core/routes/privateRoute'

export default function EngagementCampaign() {
    return (
        <>
            <Switch>
                <PrivateRoute
                    checkModulePath
                    exact
                    path={'/evento/:eventId/engajamento'}
                    component={EngagementEditPage}
                />
                <PrivateRoute
                    checkModulePath
                    exact
                    path={'/evento/:eventId/engajamento/participantes'}
                    component={EngagementListPage}
                />
            </Switch>
        </>
    )
}
