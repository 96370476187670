import { Form, Select } from 'antd'
import React from 'react'
import { mountOrdeByFields } from '../utils'
import { useSegment } from '../context'
import { IOrderByField } from './types'

const FieldSelect = ({ field, type }: IOrderByField) => {
    const { filters } = useSegment()
    const options = mountOrdeByFields(type, filters)

    return (
        <Form.Item
            name={[field.name, 'field']}
            rules={[
                {
                    required: true,
                    message: 'Campo obrigatório'
                }
            ]}
        >
            <Select options={options} />
        </Form.Item>
    )
}

export default FieldSelect

