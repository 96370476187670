import isObject from 'lodash/isPlainObject'
import forEach from 'lodash/forEach'
import merge from 'lodash/merge'

export const flatKeys = (object) => {
    const result = {}

    function _getKeys(obj, subprefix = '') {
        forEach(obj, (value, key) => {
            if (isObject(value) && !Array.isArray(value)) {
                _getKeys(value, `${subprefix}${key}.`)
            } else {
                result[`${subprefix}${key}`] = value
            }
        })
    }

    _getKeys(object)

    return result
}

export const displayIf = (field, value) => ({
    display: field === value && 'none'
})

export const displayIfNot = (field, value) => ({
    display: field !== value && 'none'
})

export const eventPath = (eventId, path) =>
    eventId ? '/events/' + eventId + path : path

export const renderBaseK = (value) =>
    value > 1000 ? `${(value / 1000).toFixed(1)} k` : value

/*
 * Compare two objects by reducing an array of keys in obj1, having the
 * keys in obj2 as the intial value of the result. Key points:
 *
 * - All keys of obj2 are initially in the result.
 *
 * - If the loop finds a key (from obj1, remember) not in obj2, it adds
 *   it to the result.
 *
 * - If the loop finds a key that are both in obj1 and obj2, it compares
 *   the value. If it's the same value, the key is removed from the result.
 */
export const getObjectDiff = (obj1, obj2) => {
    const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
            result.push(key)
        } else if (_.isEqual(obj1[key], obj2[key])) {
            const resultKeyIndex = result.indexOf(key)
            result.splice(resultKeyIndex, 1)
        }
        return result
    }, Object.keys(obj2))

    return diff
}
