import styled, { css } from 'styled-components'

import { TLoaderProps } from './types'

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`

export const CenterContent = styled.div`
    width: 100%;
    max-height: 100vh;
    height: 100%;
    align-self: self-start;
    justify-content: center;
    align-items: center;
    display: flex;
`

export const Content = styled.div<TLoaderProps>`
    ${({ fixed, padding }) => css`
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        position: ${fixed ? 'fixed' : 'absolute'};
        z-index: 999;

        ${fixed &&
        css`
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
        `}

        ${padding &&
        css`
            padding: ${padding};
        `}
    `}
`
