import React from 'react'

import { Affix, Col, Row } from 'antd'
import { useLocation, useParams, Link } from 'react-router-dom'

import { Navigation } from './styles'

const Nav = () => {
    const { pathname } = useLocation()

    const { eventId, brandId } = useParams<any>()
    const baseUrl = `/evento/${eventId}`

    const formatVisaoGeralURL = () => {
        if (brandId) return `/dashboard/visao-geral/brand/${brandId}`
        return '/dashboard/visao-geral'
    }

    const formatGenerallURL = () => {
        if (brandId) return `/dashboard/brand/${brandId}`
        return '/dashboard'
    }

    return (
        <Affix offsetTop={0}>
            <Row className="header" justify="space-between">
                <Col>
                    <Navigation>
                        <Col>
                            <Link
                                className={
                                    pathname === baseUrl + formatVisaoGeralURL()
                                        ? 'active-nav'
                                        : ''
                                }
                                to={baseUrl + formatVisaoGeralURL()}
                            >
                                Visão Geral
                            </Link>
                            <Link
                                className={
                                    pathname === baseUrl + formatGenerallURL()
                                        ? 'active-nav'
                                        : ''
                                }
                                to={baseUrl + formatGenerallURL()}
                            >
                                Conversões
                            </Link>

                            {brandId && (
                                <Link
                                    className={
                                        pathname ===
                                        baseUrl + `/dashboard/${brandId}/editar`
                                            ? 'active-nav'
                                            : ''
                                    }
                                    to={
                                        baseUrl + `/dashboard/${brandId}/editar`
                                    }
                                >
                                    Editar Informações
                                </Link>
                            )}
                        </Col>
                    </Navigation>
                </Col>
            </Row>
        </Affix>
    )
}

export default Nav
