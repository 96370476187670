import React, { useState, useEffect } from 'react'
import { Form } from 'antd'
import { useEventEmitter } from '@umijs/hooks'
import Footer from './ModalFooter'
import Steps from './ModalSteps'

import { ModalStyled } from './styles'

const ModalStep = ({
    steps,
    visible,
    saveLoading,
    buttons,
    formProps,
    showBar,
    ...props
}) => {
    const [_visible, setVisible] = useState(visible)
    const [current, setCurrent] = useState(0)
    const footerUpdate = useEventEmitter()

    useEffect(() => {
        setVisible(visible)
    }, [visible])

    useEffect(() => {
        setTimeout(() => {
            footerUpdate.emit()
        })
    }, [formProps, footerUpdate])

    const emit = async (action, ...args) => {
        if (props && props['on' + action]) {
            return await props['on' + action](...args)
        }
        return true
    }

    const controls = {
        next: () => {
            setCurrent(current + 1)
            emit('Next', current + 1)
        },
        prev: () => {
            setCurrent(current - 1)
            emit('Prev', current - 1)
        },
        close: () => {
            setVisible(false)
            emit('Close')
        },
        finish: async () => {
            if (await emit('Finish')) {
                setVisible(false)
                setCurrent(0)
            }
        },
        update: () => {
            footerUpdate.emit()
        }
    }

    const handleUpdateValues = (...args) => {
        if (formProps.onValuesChange) {
            formProps.onValuesChange(...args)
        }

        controls.update()
    }

    return (
        <ModalStyled
            {...props}
            open={_visible}
            onCancel={controls.close}
            zIndex={1031}
            footer={
                <Footer
                    update={footerUpdate}
                    step={steps[current]}
                    controls={controls}
                    current={current}
                    saveLoading={saveLoading}
                    buttons={buttons || {}}
                    steps={steps.length}
                />
            }
        >
            <Form
                {...formProps}
                onValuesChange={handleUpdateValues}
                onFieldsChange={handleUpdateValues}
            >
                {showBar && <Steps steps={steps} current={current} />}

                {steps.map((Step, index) => (
                    <div
                        key={index}
                        className={
                            current === index ? 'step-content' : 'step-hide'
                        }
                    >
                        <Step.component step={Step} controls={controls} />
                    </div>
                ))}
            </Form>
        </ModalStyled>
    )
}

export default ModalStep
