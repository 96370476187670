import React from 'react'
import { Form, Input } from 'antd'

import { ColorsBadges, ContainerConfig } from '../../components'

import { Wrapper, Title } from '../../styles'

const Address = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Cores</Title>
                <Form.Item
                    name={[props.name, 'configs', 'titles', 'textColor']}
                    label="Cor dos títulos"
                >
                    <ColorsBadges />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'text', 'textColor']}
                    label="Cor dos textos"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'title', 'text']}
                    label="Texto"
                >
                    <Input type={'text'} />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Endereço</Title>
                <Form.Item
                    name={[props.name, 'configs', 'name', 'text']}
                    label="Nome do local"
                >
                    <Input type={'text'} />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'address', 'text']}
                    label="Endereço do local"
                >
                    <Input type={'text'} />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'contact', 'text']}
                    label="Contato"
                >
                    <Input type={'text'} />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Address
