import React from 'react'
import { Input } from 'antd'

const DatePreview = ({ config, props }) => {
    const max = config.maxCurrentDate
        ? new Date().toISOString().split('T')[0]
        : config.max || '2100-01-01'

    const min = config.minCurrentDate
        ? new Date().toISOString().split('T')[0]
        : config.min || '1900-01-01'
    return <Input type={'date'} max={max} min={min} {...props} />
}

export default DatePreview
