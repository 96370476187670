import React from 'react'
import Icon from '@ant-design/icons'

const LoveSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2002 20.6523C15.723 20.6523 20.2002 16.1752 20.2002 10.6523C20.2002 5.1295 15.723 0.652344 10.2002 0.652344C4.67735 0.652344 0.200195 5.1295 0.200195 10.6523C0.200195 16.1752 4.67735 20.6523 10.2002 20.6523Z" fill="url(#paint0_linear_5621_22825)"/>
    <path fillRule="evenodd"  d="M13.0691 5.76367C10.52 5.76367 10.2004 7.80235 10.2004 7.80235C10.2004 7.80235 9.88078 5.76367 7.33176 5.76367C4.87943 5.76367 4.16496 8.24823 4.4631 9.57512C5.25279 13.0857 10.2004 15.5434 10.2004 15.5434C10.2004 15.5434 15.1507 13.0857 15.9404 9.57512C16.2386 8.24554 15.5214 5.76367 13.0691 5.76367Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear_5621_22825" x1="10.2013" y1="20.6545" x2="10.2013" y2="0.652344" gradientUnits="userSpaceOnUse">
    <stop stopColor="#EC1733"/>
    <stop offset="1" stopColor="#FF6683"/>
    </linearGradient>
    </defs>
    </svg>

)

export const IconLove = (props) => <Icon component={LoveSvg} {...props} />
