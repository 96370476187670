import React from 'react'
import { Form, Input, Divider, Switch, Collapse } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig
} from '../../../components'
import { Wrapper, Title } from '../../../styles'
import { usePageBuilderContext } from '../../../context/context'
import RichTextEditor from '../../../components/RichTextEditor/Editor'
const { Panel } = Collapse

const Viral = (props) => {
    const { event } = usePageBuilderContext()
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Tag inscrição concluida</Title>
                <Form.Item
                    name={[props.name, 'configs', 'tag', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
                <Form.Item
                    name={[props.name, 'configs', 'tag', 'bgColor']}
                    label="Cor de fundo"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Pegueno texto</Title>
                <Form.Item
                    name={[props.name, 'configs', 'tagline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtítulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Botão topo</Title>
                <ButtonConfig
                    inner
                    fieldPath={[props.name, 'configs', 'box', 'menu', 'button']}
                    allowVisibilityControl
                    defaultAction=""
                    enableActions
                    {...props}
                />
            </Wrapper>

            {!event.viralCampaign && (
                <>
                    <Wrapper>
                        <Title level={4}>Subtitulo</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'subtitle', 'text']}
                            label="Texto"
                        >
                            <Input.TextArea />
                        </Form.Item>
                        <Form.Item
                            name={[
                                props.name,
                                'configs',
                                'subtitle',
                                'textColor'
                            ]}
                            label="Cor do texto"
                        >
                            <ColorsBadges />
                        </Form.Item>
                    </Wrapper>

                    <ButtonConfig
                        fieldPath={[props.name, 'configs', 'button']}
                        enableText
                        allowVisibilityControl
                        enableActions
                        {...props}
                    />
                </>
            )}

            {event.viralCampaign && (
                <>
                    <Collapse>
                        <Panel header={<Title level={4}>Top 3 premição</Title>}>
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'top3rewards',
                                    'bgColor'
                                ]}
                                label="Cor do fundo"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Wrapper>
                                <Title level={4}>Textos</Title>
                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'points',
                                        'textColor'
                                    ]}
                                    label="Cor da pontuação TOP"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'title',
                                        'textColor'
                                    ]}
                                    label="Cor do título da recompensa"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'description',
                                        'textColor'
                                    ]}
                                    label="Cor da descrição da recompensa"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'scroll',
                                        'textColor'
                                    ]}
                                    label="Cor da barra de rolagem"
                                >
                                    <ColorsBadges />
                                </Form.Item>
                            </Wrapper>

                            <Wrapper>
                                <Title level={4}>Botões expandir</Title>
                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'expand',
                                        'more'
                                    ]}
                                    label="Texto botão 'ver mais'"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'expand',
                                        'less'
                                    ]}
                                    label="Texto botão 'ver menos'"
                                >
                                    <Input />
                                </Form.Item>

                                <ButtonConfig
                                    title="Botões"
                                    enableActions={false}
                                    inner
                                    enableText={false}
                                    fieldPath={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'top3rewards',
                                        'expand'
                                    ]}
                                    {...props}
                                />
                            </Wrapper>
                        </Panel>
                    </Collapse>

                    <Collapse>
                        <Panel
                            header={
                                <Title level={4}>
                                    Widget de compartilhamento
                                </Title>
                            }
                        >
                            <Wrapper>
                                <Title level={4}>Texto de apoio</Title>
                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'support',
                                        'text'
                                    ]}
                                    label="Texto"
                                >
                                    <RichTextEditor />
                                </Form.Item>
                            </Wrapper>

                            <Form.Item
                                name={[props.name, 'configs', 'box', 'bgColor']}
                                label="Cor de fundo"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Title level={4}>Viral</Title>
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'viralWidget',
                                    'textColor'
                                ]}
                                label="Cor do texto"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <ButtonConfig
                                title="Botões Widget"
                                fieldPath={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'viralWidget',
                                    'buttons'
                                ]}
                                inner
                                {...props}
                            />

                            <Title level={4}>Texto abaixo do Widget</Title>
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'viralWidget',
                                    'text'
                                ]}
                                label="Texto"
                            >
                                <RichTextEditor />
                            </Form.Item>
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'viralWidget',
                                    'arrowColor',
                                    'textColor'
                                ]}
                                label="Cor da seta"
                            >
                                <ColorsBadges />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <Collapse>
                        <Panel
                            header={
                                <Title level={4}>
                                    Restante da premiação TOP
                                </Title>
                            }
                        >
                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'toprewards',
                                    'bgColor'
                                ]}
                                label="Cor do fundo"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'toprewards',
                                    'card',
                                    'bgColor'
                                ]}
                                label="Cor de fundo de cada premiação"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'toprewards',
                                    'card',
                                    'borderColor'
                                ]}
                                label="Cor da borda de cada premiação"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Wrapper>
                                <Title level={4}>Textos</Title>
                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'points',
                                        'textColor'
                                    ]}
                                    label="Cor da pontuação TOP"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'title',
                                        'textColor'
                                    ]}
                                    label="Cor do título da recompensa"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'description',
                                        'textColor'
                                    ]}
                                    label="Cor da descrição da recompensa"
                                >
                                    <ColorsBadges />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'scroll',
                                        'textColor'
                                    ]}
                                    label="Cor da barra de rolagem"
                                >
                                    <ColorsBadges />
                                </Form.Item>
                            </Wrapper>

                            <Wrapper>
                                <Title level={4}>Botões expandir</Title>
                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'expand',
                                        'more'
                                    ]}
                                    label="Texto botão 'ver mais'"
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'expand',
                                        'less'
                                    ]}
                                    label="Texto botão 'ver menos'"
                                >
                                    <Input />
                                </Form.Item>

                                <ButtonConfig
                                    title="Botões"
                                    enableActions={false}
                                    inner
                                    enableText={false}
                                    fieldPath={[
                                        props.name,
                                        'configs',
                                        'box',
                                        'toprewards',
                                        'expand'
                                    ]}
                                    {...props}
                                />
                            </Wrapper>
                        </Panel>
                    </Collapse>

                    <Collapse>
                        <Panel
                            header={
                                <Title level={4}>Recompensas normais</Title>
                            }
                        >
                            <Form.Item
                                name={[props.name, 'configs', 'rewardImages']}
                                label="Exibir imagens"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Switch />
                            </Form.Item>

                            <Divider orientation="left">Bloco</Divider>

                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'rewards',
                                    'bgColor'
                                ]}
                                label="Cor do fundo"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Divider orientation="left">Indicações</Divider>

                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'rewards',
                                    'indications',
                                    'textColor'
                                ]}
                                label="Cor do texto"
                            >
                                <ColorsBadges />
                            </Form.Item>

                            <Divider orientation="left">Recompensas</Divider>

                            <Form.Item
                                name={[
                                    props.name,
                                    'configs',
                                    'box',
                                    'rewards',
                                    'reward',
                                    'textColor'
                                ]}
                                label="Cor do texto"
                            >
                                <ColorsBadges />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <ButtonConfig
                        fieldPath={[props.name, 'configs', 'buttonBottom']}
                        enableText
                        allowVisibilityControl
                        enableActions
                        {...props}
                    />

                    <Wrapper>
                        <Title level={4}>Rodapé</Title>
                        <Form.Item
                            name={[props.name, 'configs', 'footer', 'text']}
                            label="Texto"
                        >
                            <RichTextEditor />
                        </Form.Item>
                    </Wrapper>
                </>
            )}
        </>
    )
}

export default Viral
