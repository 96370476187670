import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Table, Tabs, Typography } from 'antd'

import { GET_USERS_REGISTERED_BY_UTM_TYPE } from '@cms/events/graphql/queries/dashboards'

import { TagsOutlined } from '@ant-design/icons'
import { Totals, TextTitle } from './styles'
import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../styles'
import Loader from '@cms/core/components/Loader'

const UTMDashboard = () => {
    const params = useParams()

    const { eventId } = params

    const query = useQuery(GET_USERS_REGISTERED_BY_UTM_TYPE, {
        variables: { eventId }
    })

    const Loading =
        query.loading || !query.data ? <Loader fixed={false} /> : null

    const { usersRegisteredByUTMType } = query.data || {}

    const types = Object.keys(usersRegisteredByUTMType || {})

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <TagsOutlined style={{ color: '#ffff' }} />
                </IconWrapper>
                <DashboardTitle>Cadastros por UTM</DashboardTitle>
            </DashboardCardTitle>
            {Loading}
            <Tabs type="card">
                {types
                    .filter((type) =>
                        Array.isArray(usersRegisteredByUTMType[type])
                    )
                    .map((type, index) => {
                        return (
                            <Tabs.TabPane tab={type} key={index}>
                                <Table
                                    scroll={{ y: 400 }}
                                    dataSource={usersRegisteredByUTMType[
                                        type
                                    ].map((o) => {
                                        return {
                                            ...o,
                                            key: o.source
                                        }
                                    })}
                                >
                                    <Table.Column
                                        title="Nome"
                                        dataIndex="source"
                                        key="source"
                                        filterSearch
                                        filterMode="menu"
                                        filters={usersRegisteredByUTMType[
                                            type
                                        ].map((o) => {
                                            return {
                                                text: (
                                                    <Typography.Text
                                                        style={{
                                                            maxWidth: '300px'
                                                        }}
                                                        ellipsis={{
                                                            tooltip: {
                                                                title: o.source,
                                                                placement:
                                                                    'right'
                                                            }
                                                        }}
                                                    >
                                                        {o.source}
                                                    </Typography.Text>
                                                ),
                                                value: o.source
                                            }
                                        })}
                                        onFilter={(value, record) => {
                                            return record.source === value
                                        }}
                                        render={(source) => {
                                            return (
                                                <TextTitle>{source}</TextTitle>
                                            )
                                        }}
                                    />
                                    <Table.Column
                                        title="Total"
                                        dataIndex="count"
                                        key="count"
                                        width={150}
                                        render={(count) => {
                                            return <Totals>{count}</Totals>
                                        }}
                                    />
                                </Table>
                            </Tabs.TabPane>
                        )
                    })}
            </Tabs>
        </>
    )
}

export default UTMDashboard
