import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { Row, Col } from 'antd'

import {
    SolutionOutlined,
    GlobalOutlined,
    EnvironmentOutlined,
    ClockCircleOutlined
} from '@ant-design/icons'

import { DashboardCard } from '@cms/events/components/Dashboard'
import { GET_REAL_TIME_PERFOMANCE } from '@cms/events/graphql/queries/dashboards'

import RealTimeCard from './RealTimeCard'
import UserPerCard from './UserPerCard'
import Empty from 'antd/es/empty'

import { dataMock } from './mock'
import {
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'
import { TDDashboardCard } from '@cms/core/components/TDCards'

const colSpans = {
    lg: 12,
    md: 12,
    sm: 24
}

const Analytics = () => {
    const { eventId } = useParams()

    const { data } = useQuery(GET_REAL_TIME_PERFOMANCE, {
        variables: {
            eventId: eventId
        },
        pollInterval: 60 * 1000,
        fetchPolicy: 'no-cache'
    })

    if (!data) return null

    const { usersInRealtime, usersPerCity, usersPerCountry, usersPerPage } =
        data.metricsDashboard

    return (
        <>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <ClockCircleOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Tempo real</DashboardTitle>
            </Row>
            <Row gutter={[6, 6]}>
                {usersInRealtime?.totalUsersInRealtime > 0 ? (
                    <>
                        <Col {...colSpans}>
                            <RealTimeCard data={usersInRealtime} />
                        </Col>
                        <Col {...colSpans}>
                            <UserPerCard
                                data={usersPerPage}
                                title="Usuários por página"
                                subTitle="Nome da página"
                                metric={{ name: 'page', label: 'Página' }}
                                icon={SolutionOutlined}
                            />
                        </Col>
                        <Col {...colSpans}>
                            <UserPerCard
                                data={usersPerCountry}
                                title="Usuários por país"
                                subTitle="Nome do pais"
                                metric={{ name: 'country', label: 'País' }}
                                icon={GlobalOutlined}
                            />
                        </Col>
                        <Col {...colSpans}>
                            <UserPerCard
                                data={usersPerCity}
                                title="Usuários por cidade"
                                subTitle="Nome da cidade"
                                metric={{ name: 'city', label: 'Cidade' }}
                                icon={EnvironmentOutlined}
                            />
                        </Col>
                    </>
                ) : (
                    <Col span={24}>
                        <TDDashboardCard
                            style={{
                                height: 'calc(100vh - 240px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Empty description="Não há usuários online no momento" />
                        </TDDashboardCard>
                    </Col>
                )}
            </Row>
        </>
    )
}

export default Analytics

