import { Row } from 'antd'
import styled from 'styled-components'

export const OverlayStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    visibility: hidden;
    opacity: 0;
    background: linear-gradient(250.11deg, rgba(69, 24, 219, 0.8) -7.22%, rgba(123, 41, 255, 0.8) 103.84%);
    backdrop-filter: blur(10px);
    transition: visibility 0.2s, opacity 0.5s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-space{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.6s linear;
    }
`

export const TemplatItem = styled.div`
    background: linear-gradient(211.89deg, rgba(88, 93, 98, 0.3) 0.73%, rgba(88, 93, 98, 0.05) 120.33%);
    border-radius: 20px;
    box-shadow: 0px 0px 3px #ffffff57;
    cursor: pointer;
    flex: 1;
    margin: 8px;
    position: relative;
    text-align: center;

    &.import {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        display: flex;
        font-size: 22px;
        padding: 20px;
        text-align: start;

        .ant-typography {
            font-size: 22px;
            color: #FFF;
        }

        span {
            max-width: 150px;
            line-height: 1;
            margin-bottom: 16px;
        }
    }

    img {
        width: 100%;
        border-radius: 20px;
    }

    &:hover {
        ${OverlayStyled} {
            visibility: visible;
            opacity: 1;
        }

        .ant-space {
            visibility: visible;
            opacity: 1;
        }
    }

    @media (max-width: 1068px) {
        flex-basis: 40%;
    }

    @media (max-width: 768px) {
        flex-basis: 100%;
    }
`

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`

export const Wrapper = styled.div`
    display: contents;
    position: relative;
`

export const ModalContent = styled.div`
    margin-top: 24px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 8px;
    justify-content: space-between;
    height: 60vh;
    overflow-y: auto;
`

export const EventtItem = styled.div`
    position: relative;
    height: 100px;
    border-radius: 20px;
    cursor: pointer;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-basis: 48%;
    max-width: 48%;
    text-align: center;
    padding: 10px;
    border: 1px solid #86888D;

    img {
        padding-top: 10px;
        max-width: 150px;
        height: 50px;
    }
`

export const Footer = styled(Row)`
    margin-top: 24px;
    column-gap: 16px;
`

export const ModalConfirmationContent = styled(Row)`
    text-align: center;
`