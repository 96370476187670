import styled from "styled-components";
import texture from "./texture.png";

// TODO: Trocar background-color por background
export const Container = styled.div`
 background: linear-gradient(rgba(0,0,0,.7), rgba(0,0,0,.7)),
    url(${texture})  no-repeat fixed center;
  /* background-image: url(${texture}) no-repeat fixed center; */
  height: 100vh;

  span.ant-input-affix-wrapper.ant-input-affix-wrapper-lg{
    height: 50px;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  .ant-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
  }

  .ant-input {
    width: 350px;
  }

  @media (max-width: 799px){
    .ant-input {
      width: 100%;
    }
  }

  .login-form-button {
    /* reset */
    border: none;
    color: white;

    /* size */
    width: 250px;
    height: 50px;

    background: #7730f6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;

    margin-top: 25px;

    cursor: pointer;
  }

  .image-wrapper {
    h2 {
      color: white;
      font-weight: 1;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
  padding: 0 40px;

  background-color: rgb(41 41 41 / 36%);
  border-radius: 15px;
  opacity: 1;
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);

  h2 {
    font-size: 14px;
    margin-bottom: 30px;

    font-weight: 1;
    letter-spacing: 5.25px;
    color: white;
    opacity: 0.3;
  }

  > svg {
    width: 120px;
  }
`;
