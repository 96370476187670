import FormSection from './FormSection'
import { getColorContrast } from '../../utils'

export const Forms = {
    Form: {
        Component: FormSection,
        name: 'Formulário',
        category: 'Formulários',
        columns: '1',
        thumb: require('./form.png'),
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                },
                form: {
                    formId: event.formRegister.id,
                    box: {
                        bgColor: 'white',
                        textColor: 'black'
                    },
                    headline: {
                        text: ''
                    },
                    button: {
                        bgColor: 'primary',
                        textColor: 'white'
                    },
                    countdown: {
                        date: event.startDate,
                        text: 'As vagas acabam em:',
                        enabled: true
                    }
                }
            }
        }
    }
}
