import React from 'react'
import { Select, Form } from 'antd'

import { LABELS, OPTIONS } from '@cms/events/pages/Forms/constants/masks'

const CpfCnpj = ({ type }) => {
    return (
        <Form.Item label={LABELS[type]} name={['config', 'mask', 'value']}>
            <Select options={OPTIONS[type]} />
        </Form.Item>
    )
}

export default CpfCnpj
