import { BannersSections } from './Banners'
import { CollectionsSections } from './Collections'
import { SpeakersSections } from './Speakers'
import { StageSections } from './Stages'
import { TalksSections } from './Talks'
import { RewardSections } from './Rewards'

export default {
    ...BannersSections,
    ...CollectionsSections,
    ...SpeakersSections,
    ...StageSections,
    ...RewardSections,
    ...TalksSections
}

