import React, { useEffect } from 'react'
import {
    Row,
    Col,
    Switch,
    Avatar,
    Form,
    Typography,
    Popconfirm,
    Button
} from 'antd'

import {
    DeleteOutlined,
    EditOutlined,
    CheckOutlined,
    CloseOutlined,
    UserOutlined,
    PlusOutlined
} from '@ant-design/icons'

import { CardItem, CardLogo, CardNew } from '../styles'

import * as connectors from '../IntegrationForm/connectores/configs'
import { useFormBuilderContext } from '../../../context/context'
import Empty from '@cms/core/components/Empty'

const { Title } = Typography

const List = ({ setIntegration, handleNewIntegration }) => {
    const { formData, setFormData, setSelectField } = useFormBuilderContext()
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(formData)
    })

    const handleSetIntegration = (_integration) => {
        setIntegration(_integration)
        setSelectField({ target: _integration.target })
    }

    const DeleteButton = ({ id, remove }) => {
        const handleDelete = () => {
            remove(id)
            const fields = form.getFieldValue()

            setFormData(fields)
        }

        return (
            <Popconfirm
                title={'Tem certeza que deseja deletar?'}
                onConfirm={handleDelete}
                okText="Sim"
                cancelText="Não"
            >
                <DeleteOutlined />
            </Popconfirm>
        )
    }

    return (
        <Form name="preview" form={form} layout="horizontal">
            <Row gutter={[24, 24]} align="stretch">
                <Col span={12}>
                    <CardNew onClick={handleNewIntegration} newIntegration>
                        <Button type="ghost" icon={<PlusOutlined />} />
                        <p>Nova integração</p>
                    </CardNew>
                </Col>
                <Form.List name="integrations">
                    {(integrations, { move, remove }) => {
                        return integrations.map((item, key) => {
                            const field = form.getFieldValue([
                                'integrations',
                                key
                            ])

                            return (
                                <Col span={12} key={key}>
                                    <CardItem
                                        value={field.type}
                                        color={field.color}
                                    >
                                        <Row
                                            align="stretch"
                                            style={{ height: '100%' }}
                                        >
                                            <Col span={24}>
                                                <Row>
                                                    <Col span={18}>
                                                        {field ? (
                                                            <CardLogo
                                                                src={
                                                                    connectors[
                                                                        field
                                                                            .type
                                                                    ].logo
                                                                }
                                                            />
                                                        ) : (
                                                            <Avatar
                                                                size="large"
                                                                icon={
                                                                    <UserOutlined />
                                                                }
                                                            />
                                                        )}
                                                        <Title level={4}>
                                                            {field.name}
                                                        </Title>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Row
                                                            align="middle"
                                                            justify="end"
                                                        >
                                                            <Form.Item
                                                                label="Ativo"
                                                                name={[
                                                                    item.name,
                                                                    'active'
                                                                ]}
                                                                valuePropName="checked"
                                                            >
                                                                <Switch
                                                                    checkedChildren={
                                                                        <CheckOutlined />
                                                                    }
                                                                    unCheckedChildren={
                                                                        <CloseOutlined />
                                                                    }
                                                                />
                                                            </Form.Item>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={24}>
                                                <Row gutter={24} align="middle">
                                                    <Col span={24}>
                                                        <Row
                                                            align="middle"
                                                            justify="end"
                                                        >
                                                            <EditOutlined
                                                                onClick={() =>
                                                                    handleSetIntegration(
                                                                        {
                                                                            integration:
                                                                                field,
                                                                            isEdit: true,
                                                                            target: item.name
                                                                        }
                                                                    )
                                                                }
                                                                style={{
                                                                    marginRight:
                                                                        '10px'
                                                                }}
                                                            />
                                                            <DeleteButton
                                                                remove={remove}
                                                                id={key}
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardItem>
                                </Col>
                            )
                        })
                    }}
                </Form.List>
            </Row>
        </Form>
    )
}

export default List
