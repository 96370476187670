import styled from 'styled-components'
import { Row as AntRow } from 'antd'
import { Typography, Input } from 'antd'

export const Row = styled(AntRow)`
    .section-move.ant-space-vertical {
        .ant-space-item {
            margin-bottom: 0 !important;
            max-height: 12px;
        }
    }

    .ant-form-item {
        margin-bottom: 0px;
    }

    .move-section {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        padding-right: 10px;

        span {
            margin: 0px 3px;
        }
    }

    &:hover {
        cursor: default;
        .ant-input-group-addon {
            background-color: #38383c !important;
            svg {
                visibility: visible;
            }
        }

        .ant-input-borderless {
            background-color: #38383c !important;
        }
    }
`

export const CustomInput = styled(Input)`
    .ant-input-borderless {
        background-color: transparent !important;
        border: none;

        width: auto;

        &:focus {
            background-color: #38383c !important;
            ~ .ant-input-group-addon {
                display: table-cell !important;
                background-color: #38383c !important;
                svg {
                    visibility: visible;
                }
            }
        }
    }

    .ant-input-group-addon {
        /* display: none; */
        border: none !important;
        background-color: transparent !important;
        svg {
            visibility: hidden;
        }
    }
`
