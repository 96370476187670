export const LABELS = {
    Header: 'Cabeçalho',
    EventNumbers: 'Números do evento',
    About: 'Sobre',
    SponsorCarousel: 'Carrossel Patrocinador',
    Sponsor: 'Patrocinadores',
    Learn: 'Aprender',
    Speakers: 'Palestrantes',
    Rewards: 'Indique e Ganhe',
    Scheduled: 'Agenda',
    Cta: 'Cta',
    Owner: 'Organizadores',
    Footer: 'Rodapé',
    Countdown: 'Contador',
    Viral: 'Indicações',
    Address: 'Endereço',
    Ticket: 'Ingresso',
    Checkout: 'Checkout',
    Payment: 'Pagamento'
}

export const COLORS = {
    white: 'Clara',
    black: 'Escura',
    neutral: 'Neutra',
    primary: 'Primária',
    secondary: 'Secundária',
    highlight: 'Destaque'
}
