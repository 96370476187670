import { lazy } from 'react'
import { IApp } from '@cms/core/types/app'

import { WatchModules } from './modules/watch'
import { isModuleAllowed } from '@cms/core/components/CheckModule'

const AppWatch: IApp = {
    id: 'watch',
    name: 'Watch',
    basePath: '/watch',
    checkEnable: (state) => {
        // if (process.env.ENABLE_WATCH_MODULE !== 'true') {
        //     return false
        // }
        return isModuleAllowed(state, 'watch')
    },
    container: lazy(() => import('@cms/watch/components/Container')),
    modules: [
        {
            name: 'TD Watch',
            path: `/watch`,
            module: 'watch',
            root: true,
            // disablePermissions: process.env.ENABLE_WATCH_MODULE !== 'true',
            children: WatchModules
        }
    ]
}

export default AppWatch
