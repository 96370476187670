import React from 'react'
import Icon from '@ant-design/icons'

const SurprisedSvg = () => (
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5983 20.6497C16.1204 20.6497 20.5969 16.1731 20.5969 10.651C20.5969 5.12889 16.1204 0.652344 10.5983 0.652344C5.07616 0.652344 0.599609 5.12889 0.599609 10.651C0.599609 16.1731 5.07616 20.6497 10.5983 20.6497Z" fill="url(#paint0_radial_5621_22844)"/>
    <path opacity="0.2" d="M10.5983 20.6497C16.1204 20.6497 20.5969 16.1731 20.5969 10.651C20.5969 5.12889 16.1204 0.652344 10.5983 0.652344C5.07616 0.652344 0.599609 5.12889 0.599609 10.651C0.599609 16.1731 5.07616 20.6497 10.5983 20.6497Z" fill="url(#paint1_radial_5621_22844)"/>
    <path d="M10.5983 18.8372C12.2743 18.8372 13.633 17.0023 13.633 14.7389C13.633 12.4755 12.2743 10.6406 10.5983 10.6406C8.92219 10.6406 7.56348 12.4755 7.56348 14.7389C7.56348 17.0023 8.92219 18.8372 10.5983 18.8372Z" fill="url(#paint2_linear_5621_22844)"/>
    <path d="M8.39143 4.02437C7.2742 3.3825 5.88035 3.3825 4.76313 4.02437C4.55902 4.14254 4.4865 4.40305 4.60467 4.60984C4.72284 4.81395 4.98335 4.88646 5.19014 4.76829C6.06029 4.26876 7.09427 4.26876 7.96442 4.76829C8.03156 4.80589 8.10407 4.82469 8.17658 4.82469C8.32429 4.82469 8.46932 4.74681 8.54989 4.60984C8.66537 4.40305 8.59554 4.14254 8.39143 4.02437Z" fill="url(#paint3_linear_5621_22844)"/>
    <path d="M6.57502 9.86539C7.54357 9.86539 8.32874 8.92751 8.32874 7.77058C8.32874 6.61366 7.54357 5.67578 6.57502 5.67578C5.60646 5.67578 4.82129 6.61366 4.82129 7.77058C4.82129 8.92751 5.60646 9.86539 6.57502 9.86539Z" fill="url(#paint4_radial_5621_22844)"/>
    <path opacity="0.1" d="M6.13552 8.04888C6.46925 8.04888 6.73979 7.72664 6.73979 7.32913C6.73979 6.93162 6.46925 6.60938 6.13552 6.60938C5.80179 6.60938 5.53125 6.93162 5.53125 7.32913C5.53125 7.72664 5.80179 8.04888 6.13552 8.04888Z" fill="white"/>
    <path d="M16.4373 4.02437C15.3201 3.3825 13.9263 3.3825 12.809 4.02437C12.6049 4.14254 12.5324 4.40305 12.6506 4.60984C12.7687 4.81395 13.0292 4.88646 13.236 4.76829C14.1062 4.26876 15.1402 4.26876 16.0103 4.76829C16.0775 4.80589 16.15 4.82469 16.2225 4.82469C16.3702 4.82469 16.5152 4.74681 16.5958 4.60984C16.714 4.40305 16.6414 4.14254 16.4373 4.02437Z" fill="url(#paint5_linear_5621_22844)"/>
    <path d="M14.6248 9.86539C15.5934 9.86539 16.3785 8.92751 16.3785 7.77058C16.3785 6.61366 15.5934 5.67578 14.6248 5.67578C13.6563 5.67578 12.8711 6.61366 12.8711 7.77058C12.8711 8.92751 13.6563 9.86539 14.6248 9.86539Z" fill="url(#paint6_radial_5621_22844)"/>
    <path opacity="0.1" d="M14.1853 8.04888C14.5191 8.04888 14.7896 7.72664 14.7896 7.32913C14.7896 6.93162 14.5191 6.60938 14.1853 6.60938C13.8516 6.60938 13.5811 6.93162 13.5811 7.32913C13.5811 7.72664 13.8516 8.04888 14.1853 8.04888Z" fill="white"/>
    <defs>
    <radialGradient id="paint0_radial_5621_22844" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.7712 0.944734) scale(15.0581)">
    <stop stopColor="#FEEB71"/>
    <stop offset="0.2201" stopColor="#FEE76E"/>
    <stop offset="0.4481" stopColor="#FDDA64"/>
    <stop offset="0.6798" stopColor="#FBC654"/>
    <stop offset="0.9126" stopColor="#F8A93D"/>
    <stop offset="1" stopColor="#F79C33"/>
    </radialGradient>
    <radialGradient id="paint1_radial_5621_22844" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(10.5994 10.6521) scale(9.99974)">
    <stop stopColor="white"/>
    <stop offset="0.3726" stopColor="#FFFEFD"/>
    <stop offset="0.5069" stopColor="#FEF9F6"/>
    <stop offset="0.6026" stopColor="#FCF0EB"/>
    <stop offset="0.68" stopColor="#FAE4DA"/>
    <stop offset="0.7463" stopColor="#F7D4C4"/>
    <stop offset="0.805" stopColor="#F3C1A8"/>
    <stop offset="0.8581" stopColor="#EEAA88"/>
    <stop offset="0.9069" stopColor="#E98E62"/>
    <stop offset="0.9523" stopColor="#E36F37"/>
    <stop offset="0.9926" stopColor="#DC4F09"/>
    <stop offset="1" stopColor="#DB4800"/>
    </radialGradient>
    <linearGradient id="paint2_linear_5621_22844" x1="10.5993" y1="18.8376" x2="10.5993" y2="10.6427" gradientUnits="userSpaceOnUse">
    <stop stopColor="#85370E"/>
    <stop offset="1" stopColor="#482314"/>
    </linearGradient>
    <linearGradient id="paint3_linear_5621_22844" x1="4.56851" y1="4.18404" x2="8.61603" y2="4.18404" gradientUnits="userSpaceOnUse">
    <stop stopColor="#CD6700"/>
    <stop offset="1" stopColor="#E38200"/>
    </linearGradient>
    <radialGradient id="paint4_radial_5621_22844" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(6.57544 7.77035) scale(1.75317 2.0937)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    <linearGradient id="paint5_linear_5621_22844" x1="12.6156" y1="4.18404" x2="16.6631" y2="4.18404" gradientUnits="userSpaceOnUse">
    <stop stopColor="#E38200"/>
    <stop offset="1" stopColor="#CD6700"/>
    </linearGradient>
    <radialGradient id="paint6_radial_5621_22844" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.6238 7.77035) scale(1.75317 2.0937)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    </defs>
    </svg>

)

export const IconSurprised = (props) => <Icon component={SurprisedSvg} {...props} />
