import { Button, Form, Input, Modal, Select } from 'antd'
import React from 'react'
import { usePageBuilderContext } from '../../context/context'
import { nanoid } from 'nanoid'

const AddPageModal = ({ open, onCreate, onCancel }) => {
    const { site } = usePageBuilderContext()
    const [form] = Form.useForm()
    const hanleOnKeyPress = (e) => {
        const regex = new RegExp(/^[A-Za-z0-9_-]+(?:-[A-Za-z0-9_-]+)*$/)
        const str = String.fromCharCode(!e.charCode ? e.which : e.charCode)
        if (regex.test(str)) {
            return true
        }

        e.preventDefault()
        return false
    }

    return (
        <Modal
            open={open}
            title="Adicionar página"
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button
                    data-cy="save-button"
                    key="submit"
                    type="primary"
                    onClick={() => {
                        form.validateFields()
                            .then((values) => {
                                form.resetFields()
                                onCreate(values)
                            })
                            .catch((info) => {
                                console.log('Validate Failed:', info)
                            })
                    }}
                >
                    Adicionar Página
                </Button>
            ]}
        >
            <Form
                form={form}
                layout="vertical"
                name="addPage"
                initialValues={{ modifier: 'public' }}
            >
                <Form.Item
                    label="Nome da página"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Slug"
                    name="slug"
                    rules={[
                        {
                            required: true,
                            message: 'Slug é obrigatório!'
                        },
                        {
                            validator: async (_, value) => {
                                if (
                                    !value ||
                                    !Object.values(site.pages).find(
                                        (o) => o.slug === value
                                    )
                                ) {
                                    return
                                }
                                throw new Error(
                                    'Já existe uma página com este slug!'
                                )
                            }
                        }
                    ]}
                >
                    <Input onKeyPress={hanleOnKeyPress} />
                </Form.Item>

                <Form.Item
                    label="Criar cópia a partir da página:"
                    name="copyPageSlug"
                >
                    <Select defaultValue={''}>
                        <Select.Option key="1" value="" title="Nova página">
                            Nova página
                        </Select.Option>
                        {Object.values(site?.pages).map((page) => (
                            <Select.Option
                                key={nanoid()}
                                value={page.slug}
                                title={page.name}
                            >
                                {page.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default AddPageModal
