import React, { createContext, useContext, useState } from 'react'

export const FormBuilderContext = createContext({
    loading: false,
    setLoading: () => null,
    formData: {},
    setFormData: () => null,
    currentForm: null,
    setCurrentForm: () => null,
    selectField: {},
    setSelectField: () => null,
    type: 'register'
})

export const useFormBuilderContext = () => useContext(FormBuilderContext)

export const FormBuilderProvider = ({ children, type = 'register' }) => {
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(null)
    const [selectField, setSelectField] = useState(null)
    const [currentForm, setCurrentForm] = useState(null)

    return (
        <FormBuilderContext.Provider
            value={{
                loading,
                setLoading,
                formData,
                setFormData,
                selectField,
                setSelectField,
                currentForm,
                setCurrentForm,
                type
            }}
        >
            {children}
        </FormBuilderContext.Provider>
    )
}
