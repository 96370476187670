import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_TICKET = gql`
    mutation CreateTicket(
        $name: String
        $active: Boolean!
        $event: ObjectID!
        $value: Int
        $currency: Currency
        $maxPerPurchase: Int
        $quantity: Int
        $unlimited: Boolean
        $description: String
        $accessDuration: Int
        $lifetimeAccess: Boolean
        $modules: [Modules!]
        $benefits: [String!]
        $accessRestrictions: TicketPermissionInputs
        $contentBlockedByTags: [String!]
        $contentHiddenByTags: [String!]
        $contentBlockedConfig: ContentBlockedConfigInputs
        $integrations: [ObjectID]
        $version: Int
    ) {
        createTicket(
            input: {
                name: $name
                active: $active
                event: $event
                value: $value
                currency: $currency
                maxPerPurchase: $maxPerPurchase
                quantity: $quantity
                unlimited: $unlimited
                description: $description
                accessRestrictions: $accessRestrictions
                accessDuration: $accessDuration
                lifetimeAccess: $lifetimeAccess
                modules: $modules
                benefits: $benefits
                integrations: $integrations
                contentBlockedByTags: $contentBlockedByTags
                contentHiddenByTags: $contentHiddenByTags
                contentBlockedConfig: $contentBlockedConfig
                version: $version
            }
        ) {
            ...TicketData
        }
    }

    ${fragments.TicketData}
`

export const UPDATE_TICKET = gql`
    mutation UpdateTicket(
        $id: ObjectID!
        $name: String
        $active: Boolean!
        $event: ObjectID!
        $value: Int
        $currency: Currency
        $maxPerPurchase: Int
        $quantity: Int
        $unlimited: Boolean
        $description: String
        $accessDuration: Int
        $lifetimeAccess: Boolean
        $modules: [Modules!]
        $benefits: [String!]
        $accessRestrictions: TicketPermissionInputs
        $contentBlockedByTags: [String!]
        $contentHiddenByTags: [String!]
        $contentBlockedConfig: ContentBlockedConfigInputs
        $integrations: [ObjectID]
        $version: Int
    ) {
        updateTicket(
            id: $id
            input: {
                name: $name
                active: $active
                event: $event
                value: $value
                currency: $currency
                quantity: $quantity
                maxPerPurchase: $maxPerPurchase
                unlimited: $unlimited
                description: $description
                accessRestrictions: $accessRestrictions
                accessDuration: $accessDuration
                lifetimeAccess: $lifetimeAccess
                modules: $modules
                benefits: $benefits
                integrations: $integrations
                contentBlockedByTags: $contentBlockedByTags
                contentHiddenByTags: $contentHiddenByTags
                contentBlockedConfig: $contentBlockedConfig
                version: $version
            }
        ) {
            ...TicketData
        }
    }

    ${fragments.TicketData}
`

export const DELETE_TICKET = gql`
    mutation DeleteTicket($id: ObjectID!) {
        deleteTicket(id: $id) {
            id
            success
        }
    }
`

export const ADD_TICKET_TO_USER = gql`
    mutation addTicketToUser($ticket: ObjectID!, $eventRegister: ObjectID!) {
        addTicketToUser(ticket: $ticket, eventRegister: $eventRegister) {
            code
            ticket {
                ...TicketData
            }
        }
    }

    ${fragments.TicketData}
`

export const TRANSFER_TICKET_OWNERSHIP = gql`
    mutation transferTicketOwnership(
        $id: ObjectID!
        $userData: UserTransferTicketInput!
    ) {
        transferTicketOwnership(id: $id, userData: $userData) {
            ...TicketOwnershipsData
        }
    }

    ${fragments.TicketOwnershipsData}
`

export const DELETE_TICKET_OWNERSHIP = gql`
    mutation DeleteTicketOwnership($id: ObjectID!) {
        deleteTicketOwnership(id: $id) {
            id
            success
        }
    }
`

export const SET_DEFAULT_TICKET_OWNERSHIP = gql`
    mutation SetDefaultTicketOwnership($id: ObjectID!) {
        setDefaultTicketOwnership(id: $id) {
            ...TicketOwnershipsData
        }
    }

    ${fragments.TicketOwnershipsData}
`
