import React from 'react'
import { Input } from 'antd'

const { TextArea } = Input

const TextareaPreview = () => {
    return <TextArea rows={4} />
}

export default TextareaPreview
