import React from 'react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'
import PrivateRoute from '@cms/core/routes/privateRoute'

import { SponsorDashboardProfile } from '@cms/events/components/Dashboard'

import General from './General'
import BrandEdit from './BrandEditModal'
import { useCreation } from '@umijs/hooks'

const baseUrl = '/evento/:eventId'

const SponsorRoutes = () => {
    return (
        <>
            <Switch>
                <Redirect
                    path={`${baseUrl}/dashboard`}
                    exact
                    to={`${baseUrl}/dashboard/brand`}
                />
                <PrivateRoute
                    path={`${baseUrl}/dashboard/brand`}
                    component={() => (
                        <SponsorDashboardProfile disabledLast30Days />
                    )}
                />
                <PrivateRoute
                    path={`${baseUrl}/dashboard/visao-geral`}
                    component={General}
                />

                {/* <PrivateRoute
                    exact
                    path={baseUrl + `/dashboard/${brandId}/editar`}
                    component={() => (
                        <BrandEdit
                            path={baseUrl + `/dashboard/${brandId}/editar`}
                            params={{ eventId }}
                            //isModal={false}
                        />
                    )}
                /> */}
            </Switch>
        </>
    )
}

export default SponsorRoutes
