import React from 'react'
import { Steps } from 'antd';

const { Step } = Steps;

const ModalSteps = ({ steps, current }) => {
    return (
        <Steps current={current} progressDot>
            {steps.map(item => (
                <Step key={item.title} title={item.title} />
            ))}
        </Steps>
    )
}

export default ModalSteps