import React from 'react'

import data, { Skin } from '@emoji-mart/data'
import { useState } from 'react'

import { EmojiPickerContainer, EmojiPickerModal } from './EmojiPicker.styles'
import Picker from '@emoji-mart/react'
import { SmileOutlined } from '@ant-design/icons'

export interface EmojiPickerProps {
    onSelect: (emoji: string) => void
}

export const EmojiPicker = ({ onSelect }: EmojiPickerProps) => {
    const [open, setOpen] = useState(false)

    const handleSelectEmoji = (e: Skin) => {
        const emoji = e.native
        onSelect(emoji)
    }

    return (
        <EmojiPickerContainer>
            <SmileOutlined
                onClick={() => setOpen(!open)}
                width="20"
                height="20"
                style={{ cursor: 'pointer', verticalAlign: 'baseline' }}
            />
            {open && (
                <EmojiPickerModal>
                    <Picker
                        data={data}
                        theme="dark"
                        locale="pt"
                        onEmojiSelect={handleSelectEmoji}
                        onClickOutside={() => setOpen(!open)}
                        dynamicWidth={true}
                    />
                </EmojiPickerModal>
            )}
        </EmojiPickerContainer>
    )
}

