import { COLORS } from '@cms/core/themes'
import styled from 'styled-components'

export const IconWrapper = styled.div`
    padding: 8px;
    border-radius: 100%;
    background: ${COLORS.bgLight};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    svg {
        fill: #fff;
    }

    &:hover {
        cursor: pointer;
        background: ${COLORS.active};
    }
`
