import { Tooltip } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../constants/labels'
import { StopOutlined } from '@ant-design/icons'

import { usePageBuilderContext } from '../../context/context'
import { getColor } from '../../utils'

import { Badge, Checkbox } from './styles'

const ColorsBadges = (props) => {
    const { colors, customColors } = usePageBuilderContext()
    const [colorsOptions, setColorsOptions] = useState()

    useEffect(() => {
        const _colors = [
            {
                value: 'transparent',
                label: (
                    <Tooltip title={'Sem cor'}>
                        <Badge background="#fff" className="remove-color">
                            <StopOutlined />
                        </Badge>
                    </Tooltip>
                )
            }
        ]

        if (colors) {
            Object.keys(colors).forEach((key) =>
                _colors.push({
                    value: key,
                    label: (
                        <Tooltip title={COLORS[key] || key}>
                            <Badge background={getColor(colors[key])} />
                        </Tooltip>
                    )
                })
            )
        }

        if (customColors) {
            customColors.forEach((custom, index) =>
                _colors.push({
                    value: 'customColors.' + index,
                    label: (
                        <Tooltip title={'Cor personalizada ' + (index + 1)}>
                            <Badge background={getColor(custom.color)} />
                        </Tooltip>
                    )
                })
            )
        }

        setColorsOptions(_colors)
    }, [colors, customColors])

    return (
        <>
            {colorsOptions ? (
                <Checkbox options={colorsOptions} {...props} />
            ) : null}
        </>
    )
}

export default ColorsBadges
