import React, { useEffect } from 'react'
import { Form, Row, Input, Col } from 'antd'

import FieldsPreview from '../../../../fields'
import { useFormBuilderContext } from '../../../../context/context'
import { WrapperField } from './styles'

import Label from './Label'

const PreviewFields = () => {
    const { formData } = useFormBuilderContext()
    const [form] = Form.useForm()

    useEffect(() => {
        form.setFieldsValue(formData)
    }, [formData])

    if (!formData) return null

    return (
        <Form name="preview" form={form} layout="vertical">
            <Form.List name="fields">
                {(fields, { move, remove }) => {
                    return (
                        <Row gutter={[24, 24]}>
                            {fields.map((field, index) => {
                                const {
                                    label,
                                    disableLabel,
                                    name,
                                    config,
                                    options,
                                    type
                                } = form.getFieldValue(['fields', field.name])

                                const Component = FieldsPreview[type]

                                return (
                                    <Col
                                        span={config.size || 24}
                                        key={field.fieldKey}
                                    >
                                        <WrapperField>
                                            <Label
                                                label={config.label || label}
                                                target={field.name}
                                                index={index}
                                                move={move}
                                                remove={remove}
                                                form={form}
                                            />
                                            <Form.Item rules={field.rules}>
                                                {Component ? (
                                                    <Component
                                                        label={label}
                                                        config={config}
                                                        options={options}
                                                    />
                                                ) : (
                                                    <Input
                                                        type={type}
                                                        placeholder={label}
                                                    />
                                                )}
                                            </Form.Item>
                                        </WrapperField>
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                }}
            </Form.List>
        </Form>
    )
}

export default PreviewFields
