import styled from 'styled-components'
import { Tag } from 'antd'


export const StyledTag = styled(Tag)`
	font-size: 16px;
	padding: 8px;

	&.new-tag {
		margin-top: 18px;
	}
`
