import { Experts } from './Experts'

export const ExpertsSections = {
    experts: {
        Component: Experts,
        name: 'experts',
        category: 'Especialistas',
        label: 'Especialistas',
        description: 'Seção sem filtro predefinidos',
        defaultConfig: {
            title: {
                'pt-PT':
                    'Especialistas que já passaram pelos nossos conteúdos.',
                'es-ES':
                    'Especialistas que ya han pasado por nuestros contenidos.',
                'en-US': 'Experts who have gone through our content.',
                'pt-BR': 'Especialistas que já passaram pelos nossos conteúdos.'
            }
        }
    }
}
