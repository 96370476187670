import React from 'react'
import { Col, Row, Space, Typography } from 'antd'

import { NewField } from '../../../../components'
import { FIELDS, FIELDSETS } from '../../../../constants/fields'
import { useParams } from 'react-router-dom'
import { useFormBuilderContext } from '@cms/events/pages/Forms/context/context'

const { Text } = Typography

const AddFields = () => {
    const { formId } = useParams()

    const { type } = useFormBuilderContext()

    return (
        <Space direction="vertical" size={24}>
            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Text>Campos padronizados</Text>
                </Col>
                {FIELDSETS.filter(
                    ({ config }) =>
                        // Se tem formId é form complementar
                        !config.types || config.types.includes(type)
                ).map((field, index) => (
                    <Col key={index}>
                        <NewField field={field} />
                    </Col>
                ))}
            </Row>

            <Row gutter={[12, 12]}>
                <Col span={24}>
                    <Text>Campos personalizados</Text>
                </Col>
                {FIELDS.map((field, index) => (
                    <Col key={index}>
                        <NewField field={field} />
                    </Col>
                ))}
            </Row>
        </Space>
    )
}

export default AddFields
