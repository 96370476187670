import React from 'react'

import { Form } from 'antd'
import { Wrapper } from '@cms/core/components/Theme/styles'

import { ISectionColorsProps } from './types'
import Colors from '@cms/core/components/Colors'

export const SectionColors = ({ gradient, ...rest }: ISectionColorsProps) => {
    return (
        <Wrapper>
            <Form.Item {...rest}>
                <Colors formItem gradient={gradient} />
            </Form.Item>
        </Wrapper>
    )
}

