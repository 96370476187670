import SelectOrderBy from '../OrderBy'
import SelectField from '../SelectField'

export interface IHeaderLabel {
    title: string
    size: number
}

export interface IFieldList {
    label: string
    headerLabels: IHeaderLabel[]
    FieldsComponent: React.FC
    emptyMessage: string
}

export interface IFieldListConfigs {
    filters: IFieldList
    orderBy: IFieldList
}

export const LIST_FIELDS_CONFIGS: IFieldListConfigs = {
    filters: {
        label: 'Filtros',
        headerLabels: [
            {
                title: 'Campo',
                size: 6
            },
            {
                title: 'Operador',
                size: 6
            },
            {
                title: 'Valor',
                size: 8
            }
        ],
        FieldsComponent: SelectField,
        emptyMessage: 'Nenhum filtro foi adicionado'
    },
    orderBy: {
        label: 'Ordenação',
        headerLabels: [
            {
                title: 'Campo',
                size: 6
            },
            {
                title: 'Ordenação',
                size: 6
            }
        ],
        FieldsComponent: SelectOrderBy,
        emptyMessage: 'Nenhuma ordenação foi adicionada'
    }
}

