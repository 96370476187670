import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

import { CrudList, CrudColumns } from '@cms/core/components/Crud'
import { DashboardCard } from '@cms/events/components/Dashboard'

import { Typography, Row } from 'antd'
import { CrownOutlined } from '@ant-design/icons'
import { IconWrapper } from '@cms/events/components/Dashboard/styles'

import { GET_SPEAKER_DASHBOARD_TALK } from '@cms/events/graphql/queries/speaker'

const ListOfSpeakers = function () {
    const { Text } = Typography
    const routeParams = useParams()
    const talkId = routeParams.id

    const query = useQuery(GET_SPEAKER_DASHBOARD_TALK, {
        variables: { talk: talkId }
    })

    const columns = [
        {
            title: 'palestrante',
            dataIndex: [''],
            render: (value, obj) => {
                return (
                    <CrudColumns
                        avatar={{
                            size: 56
                        }}
                        image={obj.node?.image?.url}
                        title={obj.node.name}
                        text={[obj.node.company.name, obj.node.office]}
                    />
                )
            }
        }
    ]

    return (
        <DashboardCard>
            <Row style={{ marginBottom: '20px' }} align="middle">
                <IconWrapper>
                    <CrownOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Palestrantes</Text>
            </Row>
            <CrudList
                header={{}}
                columns={columns}
                queryName={'speakers'}
                hideAction={'all'}
                showHeader={false}
                search={false}
                transparent
                {...query}
            />
        </DashboardCard>
    )
}

export default ListOfSpeakers
