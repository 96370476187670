import React from 'react'

import { useMutation, useQuery } from '@apollo/client'

import {
    CreateModalRoutes,
    CrudColumns,
    CrudList
} from '@cms/core/components/Crud'
import { GET_WATCH_PLANS_LIST_ORDENABLE } from '@cms/watch/graphql/queries/watchPlan'

import { DELETE_WATCH_PLAN } from '@cms/watch/graphql/mutations/watchPlan'

import WatchPlansModal from './WatchPlansModal'
import { removeFromList } from '@cms/watch/graphql/utils'

import dayjs from 'dayjs'

const path = `/planos`
const ModalRoutes = CreateModalRoutes(WatchPlansModal, path, {})

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (value, obj) => {
            return <CrudColumns title={obj.node.name} onlyText />
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const WatchPlans = () => {
    const query = useQuery(GET_WATCH_PLANS_LIST_ORDENABLE, {
        variables: { first: 25, orderBy: { createdAt: 'DESC' } }
    })

    const [deleteData] = useMutation(DELETE_WATCH_PLAN, {
        update: removeFromList({ list: 'watchPlans', Type: 'WatchPlan' })
    })

    if (query.error) return <div>Error</div>

    const filterFields = [{ name: 'name', label: 'Nome', queryType: 'Filter' }]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Planos',
                    subTitle: 'Adicione e gerencie planos',
                    buttons: [
                        {
                            children: 'Novo Plano',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                columns={columns}
                search={true}
                queryName={'watchPlans'}
                onDelete={deleteData}
                filterFields={filterFields}
                {...query}
            />
        </>
    )
}

export default WatchPlans

