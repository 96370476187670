import React from 'react'

import Color from 'color'

import { Row, Col, Typography, Form } from 'antd'

import { CardGroup, CardItem } from './styles'

const { Title, Text, Paragraph } = Typography

const StepOne = ({ form }) => {
    const types = [
        {
            type: 'SUMMIT',
            color: '#E74C3C',
            title: 'Summit',
            description: `
            Evento com todas as funcionalidades ativadas
            `
        },
        {
            type: 'TALKS',
            color: '#16a085',
            title: 'Talks',
            description: `
            Evento estilo Webinar, sem certificado e sem patrocinadores
            `
        },
        {
            type: 'LIVE',
            color: '#2980b9',
            title: 'Live',
            description: `
            Evento apenas com a tela de transmissão ao vivo ativada
            `
        }
    ]

    const handleType = (item) => {
        form.setFieldsValue({
            primaryColor: item.color,
            secondaryColor: Color(item.color).darken(0.2).toString()
        })
    }

    return (
        <>
            <Paragraph type="primary" level={4}>
                Que tipo de evento você está realizando?
            </Paragraph>

            <Form.Item name="type" rules={[{ required: true }]}>
                <CardGroup>
                    {types.map((item) => (
                        <CardItem
                            onClick={() => handleType(item)}
                            key={item.type}
                            value={item.type}
                            color={item.color}
                        >
                            <Row align="middle">
                                <Col flex="auto">
                                    <Title level={4}>{item.title}</Title>
                                    <Text>{item.description}</Text>
                                </Col>
                            </Row>
                        </CardItem>
                    ))}
                </CardGroup>
            </Form.Item>
        </>
    )
}

export default StepOne
