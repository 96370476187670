import React from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Button, Col, Form, Input, Row, message } from 'antd'

import { useApolloClient } from '@apollo/client'

import { useCrudForm } from '@cms/core/components/Crud'
import { GET_WATCH_CONFIG } from '@cms/watch/graphql/queries/watchConfig'
import { UPDATE_WATCH_CONFIG } from '@cms/watch/graphql/mutations/watchConfig'
import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'
import Content from '@cms/core/components/Content'
import CollapseDomain from './CollapseDomain'
import CheckDomainStatus from './CheckDomainStatus'

export default function ConfigDomain() {
    const client = useApolloClient()

    const [{ form, formSave, saveLoading, getError, formData, formUpdate }] =
        useCrudForm(
            {
                getData: async () => {
                    const { data } = await client.query({
                        query: GET_WATCH_CONFIG
                    })

                    return data?.watchConfig
                },
                createData: async function (formData) {
                    const variables = { ...formData }

                    await client.mutate({
                        mutation: UPDATE_WATCH_CONFIG,
                        variables
                    })

                    await this.refetchData()

                    message.success('Domínio atualizado com sucesso!')
                }
            },
            []
        )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Domínio"
                subTitle="Configuração de dominio"
            />
            <Content className="container-light">
                <Form layout="vertical" form={form} onFinish={formSave}>
                    <IgnorePasswordAutoComplete />
                    <Form.Item
                        name={['domain', 'name']}
                        label={'Dominio'}
                        rules={[{ message: 'Preencha o domínio' }]}
                        {...getError('domain.name')}
                    >
                        <Input
                            placeholder="Digite o domínio do evento"
                            onChange={formUpdate}
                        />
                    </Form.Item>

                    <CheckDomainStatus field="domain" />

                    {formData?.domain?.name && (
                        <CollapseDomain
                            field="domain"
                            domainName={formData.domain.name}
                        />
                    )}

                    <Row className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

