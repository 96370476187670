import React from 'react'
import dayjs from 'dayjs'
import { useParams } from 'react-router-dom'

import { Form } from '../../styles'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Input, Col, Row, message } from 'antd'

import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_CURRENT_CUSTOMER } from '@cms/core/graphql/mutations/customer'
import { GET_CURRENT_CUSTOMER } from '@cms/core/graphql/queries/customer'
import { useApolloClient } from '@apollo/client'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import Loader from '@cms/core/components/Loader'

const ConfigInfo = () => {
    const client = useApolloClient()

    const [{ form, formSave, saveLoading, dataLoading, formUpdate }] =
        useCrudForm(
            {
                relateds: [],
                id: 'current',
                getData: async () => {
                    const response = await client.query({
                        query: GET_CURRENT_CUSTOMER
                    })

                    const data = { ...response.data.currentCustomer }

                    if (data.startDate)
                        data.startDate = dayjs(new Date(data.startDate))
                    if (data.endDate)
                        data.endDate = dayjs(new Date(data.endDate))

                    return data
                },
                updateData: async (_, formData) => {
                    const variables = { ...formData }

                    await client.mutate({
                        mutation: UPDATE_CURRENT_CUSTOMER,
                        variables
                    })

                    message.success('Informaçoes atualizadas com sucesso!')
                }
            },
            []
        )

    if (dataLoading) return <Loader fixed={false} padding="10px" />

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Informações da Base"
                subTitle="Configuração de informações básicas da base"
            />
            <Content className="container-light">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={formSave}
                    onValuesChange={formUpdate}
                    hideRequiredMark
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Nome da Empresa" name="name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="Logo" name="logo">
                                <MediaUploader
                                    type="image"
                                    style="image"
                                    options={{
                                        optimize: {
                                            quality: 85
                                        }
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ marginTop: 20 }} className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default ConfigInfo
