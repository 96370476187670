import React from 'react'

import { Form, ContentDefault } from './styles'
import { PageHeader } from '@ant-design/pro-layout'
import { Layout, Space } from 'antd'
import { Button, Col, Row, Input } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'

import { useQuery, useMutation } from '@apollo/client'

import IgnorePasswordAutoComplete from '@cms/core/components/IgnorePasswordAutoComplete'
import { useCrudForm } from '@cms/core/components/Crud'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

import { CURRENT_USER } from '@cms/core/graphql/queries/auth'
import { UPDATE_USER } from '@cms/core/graphql/mutations/user'
import { TDCardForForm } from '@cms/core/components/TDCards'

export default function Event({}) {
    const { data } = useQuery(CURRENT_USER)
    const [updateUser] = useMutation(UPDATE_USER)

    const [{ form, formSave, saveLoading }] = useCrudForm(
        {
            id: data?.me.id,
            relateds: [],
            getData: async () => {
                return { ...data?.me }
            },
            updateData: async (id, formData) => {
                const variables = { id, ...formData }
                // variables.avatar = variables.avatar?.id
                await updateUser({ variables })
            }
        },
        [data]
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Usuário"
                subTitle="Manutenção do usuário"
            />
            <Form
                layout="vertical"
                form={form}
                onFinish={formSave}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TDCardForForm
                            actions={[
                                <div>
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        loading={saveLoading}
                                    >
                                        Salvar
                                    </Button>
                                </div>
                            ]}
                        >
                            <IgnorePasswordAutoComplete />
                            <Form.Item
                                name="name"
                                label="Nome"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Preencha o nome'
                                    }
                                ]}
                            >
                                <Input placeholder="Nome" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                label="Email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Preencha o email'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Insira um e-mail válido'
                                    }
                                ]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                            <Form.Item label="Foto" name="avatar">
                                <MediaUploader
                                    options={{
                                        optimize: {
                                            resize: {
                                                width: 800,
                                                height: 800
                                            },
                                            quality: 80
                                        }
                                    }}
                                    type="image"
                                />
                            </Form.Item>
                        </TDCardForForm>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
