import React from 'react'
import { Form, Input, Row, Col, Button, Typography } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import Card from '@cms/core/components/Card'
import { Wrapper } from './styles'

const Multiple = () => {
    return (
        <Card title="Opções">
            <Form.List name={['options']}>
                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map((field, index) => (
                                <div key={`headers.${index}`}>
                                    <Row
                                        className="actionsBox"
                                        gutter={24}
                                        align="middle"
                                    >
                                        <Col span={8}>
                                            <Form.Item
                                                label="Label"
                                                name={[field.name, 'label']}
                                                placeholder="Label"
                                                rules={[{ required: true }]}
                                            >
                                                <Input type={'text'} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={14}>
                                            <Form.Item
                                                label="Valor"
                                                name={[field.name, 'value']}
                                                placeholder="Valor"
                                                rules={[{ required: true }]}
                                            >
                                                <Input type={'text'} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <MinusCircleOutlined
                                                style={{
                                                    marginTop: 20
                                                }}
                                                onClick={() =>
                                                    remove(field.name)
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    block
                                >
                                    <PlusOutlined /> Adicionar opção
                                </Button>
                            </Form.Item>
                        </>
                    )
                }}
            </Form.List>
        </Card>
    )
}
export default Multiple
