const Templates = require('./templates/')

const Render = ({ data, template }) => {
    const render = Templates[template || 'Default']
    const HTML = render(data)

    return HTML
}

module.exports = { Render }
