import React from 'react'

import { Row, Col } from 'antd'

import {
    DashboardCard,
    CardMetric,
    SocialNetworks,
    UserPerCountryCard,
    ClicksLinkPerCard,
    DownloadsPerCard,
    ListUsers,
    ViewChart
} from '@cms/events/components/Dashboard'

import { StyledCol } from './styles'
import { useParams } from 'react-router-dom'
import { useBrandSelect } from '@cms/events/components/Container/SponsorDetail/SelectBrand/context'
import { useCreation } from '@umijs/hooks'
import { CreateModalRoutes } from '@cms/core/components/Crud'
import BrandEditModal from '@cms/events/pages/Sponsor/BrandEditModal'
import { TDDashboardCard } from '@cms/core/components/TDCards'

const SponsorDashboardProfile = ({ disabledLast30Days = false }) => {
    const { eventId } = useParams()

    const { brandId } = useBrandSelect()

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(
            BrandEditModal,
            `/evento/${eventId}/dashboard/brand`,
            { eventId }
        )
    }, [eventId])

    return (
        <>
            <ModalRoutes />
            {!disabledLast30Days && (
                <Row
                    style={{
                        marginBottom: '20px'
                    }}
                    gutter={[24, 24]}
                >
                    <Col sm={24}>
                        <DashboardCard
                            style={{
                                borderRadiusBottom: '50px'
                            }}
                        >
                            <ViewChart eventId={eventId} brandId={brandId} />
                        </DashboardCard>
                    </Col>
                </Row>
            )}
            <Row gutter={[24, 24]}>
                <Col lg={7} sm={12} xs={12} md={12}>
                    <StyledCol
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '24px',
                            marginBottom: '15px'
                        }}
                    >
                        <TDDashboardCard
                            padding={[16, 8, 16, 8]}
                            style={{ width: '100%' }}
                        >
                            <CardMetric
                                metric="brandViewsTotal"
                                text="Total de views"
                                eventId={eventId}
                                brandId={brandId}
                            />
                        </TDDashboardCard>

                        <TDDashboardCard
                            padding={[16, 8, 16, 8]}
                            style={{ width: '100%' }}
                        >
                            <CardMetric
                                metric="brandContactTotal"
                                text="Conversas iniciadas"
                                eventId={eventId}
                                brandId={brandId}
                            />
                        </TDDashboardCard>
                    </StyledCol>
                    <StyledCol>
                        <TDDashboardCard>
                            <SocialNetworks eventId={eventId} />
                        </TDDashboardCard>
                    </StyledCol>
                </Col>
                <Col lg={17} sm={12} xs={12} md={12}>
                    <StyledCol
                        style={{
                            display: 'flex',
                            marginBottom: '15px',
                            gap: '20px'
                        }}
                    >
                        <DownloadsPerCard
                            eventId={eventId}
                            brandId={brandId}
                            titleSmall={true}
                            metric={{ name: 'material', label: 'Material' }}
                        />

                        <ClicksLinkPerCard
                            eventId={eventId}
                            brandId={brandId}
                            metric={{
                                name: 'actions',
                                label: 'Click nos links'
                            }}
                        />

                        <UserPerCountryCard
                            eventId={eventId}
                            brandId={brandId}
                            metric={{
                                name: 'countries',
                                label: 'Usuários Por País'
                            }}
                        />
                    </StyledCol>

                    <StyledCol>
                        <TDDashboardCard>
                            <ListUsers eventId={eventId} brandId={brandId} />
                        </TDDashboardCard>
                    </StyledCol>
                </Col>
            </Row>
        </>
    )
}

export default SponsorDashboardProfile

