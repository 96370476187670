import React from 'react'
import { message, Form, Input, Select, Tag, Checkbox } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import Card from '@cms/core/components/Card'

import { addToList } from '@cms/core/graphql/utils'
import {
    GET_COLLECTION_CATEGORY,
    GET_COLLECTION_CATEGORY_TAGS
} from '@cms/events/graphql/queries/collectionCategories'
import {
    CREATE_COLLECTION_CATEGORY,
    UPDATE_COLLECTION_CATEGORY
} from '@cms/events/graphql/mutations/collectionCategories'

const CollectionCategoryModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_COLLECTION_CATEGORY,
                variables: { id }
            })

            return { ...data.collectionCategory }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_COLLECTION_CATEGORY,
                update: addToList({
                    list: 'collectionCategories',
                    Type: 'CollectionCategory'
                }),
                variables: {
                    ...formData,
                    event: eventId
                }
            })

            message.success('Categoria enviada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_COLLECTION_CATEGORY,
                variables: {
                    id,
                    ...formData,
                    event: eventId
                }
            })

            message.success('Categoria atualizada com sucesso!')
        }
    })

    const { data: TagsData } = useQuery(GET_COLLECTION_CATEGORY_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar categoria' : 'Nova categoria'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Categoria"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Categoria é obrigatória!'
                        }
                    ]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>

                {/* <Form.Item
                    label="Card com ação"
                    name="hasAction"
                    valuePropName="checked"
                    style={{
                        flexDirection: 'row',
                        marginBottom: 0,
                        alignItems: 'baseline'
                    }}
                    {...getError('hasAction')}
                >
                    <Checkbox style={{ marginLeft: '10px' }} />
                </Form.Item>
                <Form.Item
                    label="Somente para pré-evento"
                    name="showPreEvent"
                    valuePropName="checked"
                    style={{
                        flexDirection: 'row',
                        alignItems: 'baseline'
                    }}
                    {...getError('showPreEvent')}
                >
                    <Checkbox style={{ marginLeft: '10px' }} />
                </Form.Item> */}
                <Form.Item
                    label="Descrição"
                    name="description"
                    rules={[
                        {
                            required: true,
                            message: 'Descrição é obrigatória!'
                        }
                    ]}
                    {...getError('description')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Card title="Informações adicionais">
                    <Form.Item label="Tags" name={['tags']}>
                        <Select
                            mode="tags"
                            tagRender={({ label, ...props }) => (
                                <Tag {...props} color="#7F44FF">
                                    {label}
                                </Tag>
                            )}
                            style={{ width: '100%' }}
                            placeholder="Selecione uma tag existente ou crie uma nova."
                        >
                            {TagsData?.collectionCategoryTags?.map((tag) => (
                                <Select.Option key={tag} value={tag}>
                                    {tag}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Card>
            </Form>
        </CrudModal>
    )
}

export default CollectionCategoryModal
