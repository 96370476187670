import React from 'react'
import { Col, Form } from 'antd'

import FieldType from '../FieldType'
import FieldOperator from '../FieldOperator'
import FieldValue from '../FieldValue'
import { ISegmentFields } from '../types'
import { useSegment } from '../context'

interface ISelectSegments extends ISegmentFields {
    name: string
    children?: React.ReactNode
}

const SelectSegments = ({ field, type, name, children }: ISelectSegments) => {
    const {
        configs: { formName = 'sections', hiddenFields }
    } = useSegment()
    const form = Form.useFormInstance()
    const currentFieldName = [formName, ...name, 'filters', field.name]
    const currentField = form.getFieldValue([...currentFieldName, 'field'])
    const currentValue = form.getFieldValue([...currentFieldName, 'value'])

    const isHidden =
        hiddenFields &&
        Object.keys(hiddenFields).some((key) => {
            return hiddenFields[key] === currentValue && currentField === key
        })

    if (isHidden) {
        return null
    }

    return (
        <>
            <Col span={6}>
                <FieldType field={field} type={type} />
            </Col>

            <Col span={6}>
                <FieldOperator field={field} type={type} name={name} />
            </Col>

            <Col span={8}>
                <FieldValue field={field} type={type} name={name} />
            </Col>
            {children}
        </>
    )
}

export default SelectSegments

