import React, { createContext, useState } from 'react';

interface ScrollContextProps {
    scrollPosition: number;
    setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
}

export const ScrollContext = createContext<ScrollContextProps>({
    scrollPosition: 0,
    setScrollPosition: () => {},
});

export const ScrollProvider: React.FC = ({ children }) => {
    const [scrollPosition, setScrollPosition] = useState(0);

    return (
        <ScrollContext.Provider value={{ scrollPosition, setScrollPosition }}>
            {children}
        </ScrollContext.Provider>
    );
};
