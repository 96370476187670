import { useApolloClient } from '@apollo/client'
import Content from '@cms/core/components/Content'
import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { PageHeader } from '@ant-design/pro-layout'
import { Button, Col, Form, Input, message, Row } from 'antd'
import React from 'react'
import { useParams } from 'react-router-dom'

const Faq = () => {
    const { eventId } = useParams()
    const client = useApolloClient()

    const [{ form, formSave, saveLoading }] = useCrudForm(
        {
            id: eventId,
            getData: async (id) => {
                if (!id) return
                const response = await client.query({
                    query: GET_EVENT,
                    variables: { id }
                })

                const data = { ...response.data.event }
                return data
            },
            updateData: async (id, formData) => {
                const variables = { id, ...formData }

                await client.mutate({
                    mutation: UPDATE_EVENT,
                    variables
                })

                message.success('Dados gerais atualizados com sucesso!')
            }
        },
        [eventId]
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Central de ajuda"
                subTitle="Cadastre informações gerais da central de ajuda"
            />
            <Content className="container-light">
                <Form layout="vertical" form={form} onFinish={formSave}>
                    <Form.Item
                        name="helpMail"
                        label="Email para contato de ajuda"
                    >
                        <Input type="email" />
                    </Form.Item>

                    <Row className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default Faq
