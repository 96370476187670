import React, {
    ChangeEvent,
    KeyboardEvent,
    useEffect,
    useRef,
    useState
} from 'react'
import { Mention, OnChangeHandlerFunc } from 'react-mentions'
import {
    SendMessageButton,
    SendMessageContainer,
    SendMessageForm,
    SendMessageInput,
    SendMessageInputContainer
} from './styles'

import { SendOutlined } from '@ant-design/icons'
import { EmojiPicker } from '../EmojiPicker/EmojiPicker'

// import {
//   SendMessageError,
//   SendMessageInput,
//   SendMessageInputContainer
// } from './SendMessageSection.styles'

const MAX_CHARS = 450

const MOCK_USERS = [
    {
        id: '1',
        display: 'João'
    },
    {
        id: '2',
        display: 'Maria'
    },
    {
        id: '3',
        display: 'Pedro'
    }
]

interface ChatMessageInputProps {
    value: string
    disabled?: boolean
    onChange: (message: string) => void
    onSend: () => void
}

export const ChatMessageInput = ({
    value,
    disabled = false,
    onChange,
    onSend
}: ChatMessageInputProps) => {
    const textAreaRef = useRef<HTMLTextAreaElement>(null)
    const [isFocused, setIsFocused] = useState(false)

    const handleBlur = (
        event:
            | React.FocusEvent<HTMLInputElement>
            | React.FocusEvent<HTMLTextAreaElement>,
        clickedSuggestion: boolean
    ) => {
        setIsFocused(clickedSuggestion)
    }

    const handleChange: OnChangeHandlerFunc = (event) => {
        if (event.target.value.length > MAX_CHARS) {
            return
        }
        onChange(event.target.value)
    }

    const handleKeyDown = (
        event:
            | React.KeyboardEvent<HTMLTextAreaElement>
            | React.KeyboardEvent<HTMLInputElement>
    ) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault()
            console.log('send')
            onSend()
            textAreaRef.current?.blur()
        }
    }

    useEffect(() => {
        if (
            textAreaRef.current &&
            textAreaRef.current.scrollHeight >
                textAreaRef.current.clientHeight &&
            isFocused
        ) {
            textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`
        }
    }, [value, isFocused])

    return (
        <SendMessageContainer>
            <SendMessageForm>
                <SendMessageInputContainer className="send-message-input-container">
                    <SendMessageInput
                        className="send-message-input"
                        inputRef={textAreaRef}
                        placeholder="Escreva sua mensagem..."
                        value={value}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        allowSuggestionsAboveCursor
                        rows={1}
                        disabled={disabled}
                    >
                        <Mention
                            className="mention"
                            trigger="/^@.{2,}$/"
                            displayTransform={(id, display) => `@${display}`}
                            data={MOCK_USERS}
                        />
                    </SendMessageInput>
                    {value?.length === MAX_CHARS && <div>100</div>}
                </SendMessageInputContainer>
                <EmojiPicker
                    onSelect={(emoji) => {
                        const newValue = value + emoji
                        onChange(newValue)
                    }}
                />
            </SendMessageForm>

            <SendMessageButton onClick={onSend} disabled={disabled}>
                <SendOutlined />
            </SendMessageButton>
        </SendMessageContainer>
    )
}

