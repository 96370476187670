import { transparentize } from 'polished'
import styled, { css } from 'styled-components'

export const StyledOption = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
`
export const WrapperInoutTranslation = styled.div`
    ${({ theme }) => css`
        .ant-input-group-addon {
            .ant-select-selection-item {
                display: flex;
                justify-content: start;
                align-items: center;
            }
        }

        .ant-select-selector {
            background-color: ${transparentize(
                0.9,
                theme.color.primaryColor
            )} !important;
            border: none !important;
            height: 38px !important;
        }

        .ant-select-selector,
        .ant-input-group-addon {
            border-color: transparent;
            border: 0px;
            box-shadow: none !important;
        }

        .ant-input {
            border-color: ${transparentize(
                0.9,
                theme.color.primaryColor
            )} !important;
            border-width: 1px 1px 1px 0px !important;
            border-style: solid !important;
            box-shadow: none !important;

            &:focus {
                border-color: ${transparentize(
                    0.8,
                    theme.color.primaryColor
                )} !important;
            }
        }

        &.input-translation--textArea {
            .ant-select-selector {
                background-color: ${transparentize(
                    0.9,
                    theme.color.primaryColor
                )} !important;

                border-radius: 6px 6px 0px 0px !important;
            }
            .ant-input {
                border-width: 0px 1px 1px 1px !important;
                border-radius: 0px 0px 6px 6px !important;
                &:focus {
                    border-width: 0px 1px 1px 1px !important;
                }
            }
        }
    `}
`
