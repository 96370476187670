import React from 'react'
import { Divider, Button, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { HiddenArea } from './styles'
import { nanoid } from 'nanoid'

const AddCustomColor = (props) => {
    const { add, index, disabled } = props

    const addColor = () => {
        if (disabled) {
            return message.info('Máximo de cores personalizadas atingido!')
        }

        add({
            color: '#ff0000',
            name: 'CustomColor-' + nanoid()
        })
    }

    if (props.emptyCustomColor) {
        return (
            <>
                <Button type="primary" onClick={addColor}>
                    Adicionar
                </Button>
            </>
        )
    }

    return (
        <>
            <HiddenArea>
                <Divider plain dashed className="divider" onClick={addColor}>
                    {<PlusOutlined />}
                </Divider>
            </HiddenArea>
        </>
    )
}

export default AddCustomColor
