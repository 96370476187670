import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Row, Col, Typography } from 'antd'

import { Box } from './styles'

const { Title } = Typography

const Setup = () => {
    const { eventId } = useParams()
    return (
        <>
            <Row justify="center">
                <Title>Você irá ?</Title>
            </Row>
            <Row gutter={20} justify="center">
                <Col lg={8} xs={24}>
                    <Link to={'/home/'}>
                        <Box>Usar meu site</Box>
                    </Link>
                </Col>
                <Col lg={8} xs={24}>
                    <Link to={`/evento/${eventId}/paginas/templates`}>
                        <Box>Criar meu site</Box>
                    </Link>
                </Col>
            </Row>
        </>
    )
}

export default Setup
