const image =
    'https://tdstorage.s3-sa-east-1.amazonaws.com/platform/layout-03-1644598365232.png'

export const layout03 = (event) => ({
    layout: 'layout03',
    info: {
        name: 'Square',
        image
    },
    theme: {
        colors: {
            white: '#F4F7FF',
            black: '#12121A',
            neutral: '#CDDAED;#F6FBFF' /* 4 */,
            primary: '#2C3949;#02000F' /* blue | 2 */,
            secondary: '#CCFF00;#5EF119' /* pink | 3 */,
            highlight: '#4402FE;#9213F5' /* green | 1 */
        },
        button: {
            type: 'default'
        },
        box: {
            type: 'default'
        },
        font: 'Oswald'
    },
    seo: {
        title: 'Square',
        description: 'Square',
        keywords: [],
        image
    },
    pages: {
        checkout: {
            sections: [
                {
                    type: 'Ticket',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        backButton: {
                            textColor: 'white',
                            text: 'Voltar'
                        },
                        title: {
                            text: 'Comprar ingressos',
                            textColor: 'white'
                        },
                        subtitle: {
                            text: 'Escolha qual tipo de ingresso você quer comprar.',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            ticket: {
                                name: {
                                    textColor: 'highlight'
                                },
                                description: {
                                    textColor: 'black'
                                },
                                soldOut: {
                                    textColor: 'black'
                                },
                                price: {
                                    bgColor: 'secondary',
                                    textColor: 'black',
                                    line: {
                                        bgColor: 'black'
                                    }
                                }
                            }
                        },
                        form: {
                            box: {
                                bgColor: 'white',
                                textColor: 'black'
                            },
                            headline: {
                                text: 'Inscreva-se Gratuitamente!'
                            },
                            button: {
                                text: 'Inscreva-se gratuitamente',
                                bgColor: 'secondary',
                                textColor: 'white'
                            },
                            countdown: {
                                date: event.startDate,
                                text: 'As vagas acabam em:'
                            }
                        }
                    }
                }
            ]
        },
        checkoutPayment: {
            sections: [
                {
                    type: 'Payment',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'primary',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        backButton: {
                            textColor: 'white',
                            text: 'Voltar'
                        },
                        title: {
                            text: 'Comprar ingressos',
                            textColor: 'white'
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black',
                            button: {
                                bgColor: 'secondary',
                                textColor: 'black',
                                text: 'Pagar'
                            },
                            input: {
                                bgColor: 'white',
                                textColor: 'black',
                                borderColor: 'black',
                                borderColorHover: 'highlight',
                                iconColor: 'highlight'
                            }
                        },
                        boxValues: {
                            bgColor: 'secondary',
                            textColor: 'black'
                        }
                    }
                }
            ]
        },
        'terms-of-service': {
            sections: [
                {
                    type: 'TermsOfService',
                    active: true,
                    configs: {
                        container: {
                            bgColor: 'white',
                            bgImage: {
                                src: null,
                                layout: 'fill', // fill, repeat
                                objectFit: 'fill', // fill, contain, cover, none
                                objectPosition: 'center center' // top, center, bottom
                            }
                        },
                        box: {
                            bgColor: 'white',
                            textColor: 'black'
                        }
                    }
                }
            ]
        }
    }
})
