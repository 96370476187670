import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_FAQ_QUESTION = gql`
    mutation CreateQuestion(
        $event: ObjectID!
        $topic: ObjectID
        $question: String!
        $answer: String
    ) {
        createFaqQuestion(
            input: {
                event: $event
                question: $question
                answer: $answer
                topic: $topic
            }
        ) {
            ...FaqQuestionData
        }
    }

    ${fragments.FaqQuestionData}
`

export const UPDATE_FAQ_QUESTION = gql`
    mutation UpdateFaqQuestion(
        $id: ObjectID!
        $event: ObjectID
        $topic: ObjectID
        $question: String
        $answer: String
    ) {
        updateFaqQuestion(
            id: $id
            input: {
                event: $event
                question: $question
                answer: $answer
                topic: $topic
            }
        ) {
            ...FaqQuestionData
        }
    }

    ${fragments.FaqQuestionData}
`

export const DELETE_FAQ_QUESTION = gql`
    mutation DeleteFaqQuestion($id: ObjectID!) {
        deleteFaqQuestion(id: $id) {
            id
            success
        }
    }
`
