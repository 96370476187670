import { gql } from "@apollo/client";
import * as fragments from '../fragments'

export const CREATE_QUIZ = gql`
    mutation CreateQuiz(
        $talk: ObjectID
        $question: String
        $answers: [String]
        $duration: Int
    ) {
        createQuiz(
            input: {
                question: $question
                answers: $answers
                talk: $talk
                duration: $duration
            }
        ) {
            ...QuizData
        }
    }
    ${fragments.QuizData}
`

export const UPDATE_QUIZ = gql`
    mutation UpdateQuiz(
        $id: ObjectID!
        $question: String
        $answers: [String]
        $talk: ObjectID
        $duration: Int
    ) {
        updateQuiz(
            id: $id
            input: {
                question: $question
                answers: $answers
                talk: $talk
                duration: $duration
            }
        ){
            ...QuizData
        }
    }
    ${fragments.QuizData}
`

export const DELETE_QUIZ = gql`
    mutation deleteQuiz($id: ObjectID!){
        deleteQuiz(id: $id){
            id
            success
        }
    }
`

export const FINISH_QUIZ = gql`
    mutation finishQuiz($id: ObjectID!){
        finishQuiz(id: $id){
            ...QuizData
        }
    }
    ${fragments.QuizData}
`

export const START_QUIZ = gql`
    mutation startQuiz($id: ObjectID!){
        startQuiz(id: $id){
            ...QuizData
        }
    }
    ${fragments.QuizData}
`