import { Brazil, Portugal, Spain, USA } from './flags'

export const getIcon = (value: string) => {
    if (value === 'pt-BR') return Brazil
    if (value === 'pt-PT') return Portugal
    if (value === 'en-US') return USA
    if (value === 'es-ES') return Spain
}

export const languagesLabels = {
    'pt-BR': 'Português/BR',
    'pt-PT': 'Português/PT',
    'en-US': 'Inglês',
    'es-ES': 'Espanhol'
}
