import React from 'react'

import AppLayout from '@cms/core/components/AppLayout'
import WatchRoutes from '@cms/watch/routes/watchRoutes'
import { AppConfigProvider } from '@cms/core/components/Providers/AppConfigProvider'
import { StyledContainer } from './styles'

const Container = () => {
    return (
        <AppConfigProvider>
            <AppLayout rootModule={'watch'}>
                <StyledContainer>
                    <WatchRoutes />
                </StyledContainer>
            </AppLayout>
        </AppConfigProvider>
    )
}

export default Container

