import React from 'react'

import { Row, Col, Typography, Form, Input, InputNumber } from 'antd'

const { Title, Paragraph } = Typography

const Step1 = ({}) => {
    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        Informações
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}>
                        Alguns detalhes sobre a campanha
                    </Paragraph>

                    <Row gutter={16}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                name="name"
                                label="Nome da campanha"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Form.Item
                                name={['dailyGoals', 'referrals']}
                                label="Meta diária de indicações"
                                rules={[{ required: true }]}
                            >
                                <InputNumber
                                    style={{ width: '100%', textAlign: 'left' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12} style={{ textAlign: 'left' }}>
                            <Form.Item
                                name={['dailyGoals', 'subscribers']}
                                label="Meta diária de inscrições"
                                rules={[{ required: true }]}
                            >
                                <InputNumber
                                    style={{ width: '100%', textAlign: 'left' }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Step1
