import { lazy } from 'react'
import { IApp } from '@cms/core/types/app'

import { EventModules } from './modules/event'
import { MainModules } from './modules/main'
import { SponsorModules } from './modules/sponsor'
import { SponsorDetailModules } from './modules/sponsorDetail'
import { isModuleAllowed } from '@cms/core/components/CheckModule'

const AppEvents: IApp = {
    id: 'events',
    name: 'Eventos',
    basePath: '/eventos',
    checkEnable: (state) => {
        if (state.user.role === 'sponsor') {
            return true
        }

        return isModuleAllowed(state, 'events') && state.user.isAdmin
    },
    container: lazy(() => import('@cms/events/components/Container')),
    defaultRootModule: 'events.main',
    modules: [
        {
            name: 'TD Eventos',
            module: 'events',
            path: '/',
            root: true,
            children: [
                {
                    name: 'Menu Geral',
                    path: `/principal`,
                    module: 'events.main',
                    root: true,
                    children: MainModules
                },
                {
                    name: 'Menu Evento',
                    path: `/evento/:eventId`,
                    module: 'events.event',
                    root: true,
                    children: EventModules
                },
                {
                    name: 'Sponsor',
                    path: `/`,
                    module: 'events.sponsor',
                    root: true,
                    disablePermissions: true,
                    children: SponsorModules
                },
                {
                    name: 'Sponsor Detail',
                    path: `/`,
                    module: 'events.sponsor.detail',
                    root: true,
                    disablePermissions: true,
                    children: SponsorDetailModules
                }
            ]
        }
    ]
}

export default AppEvents
