import React from 'react'
import Steps from '@cms/events/components/Referal/Steps'

import { ContentBox, ContentDefault } from './styles'
import CampaingButtonNew from './ButtonNew'
import CustomEmpty from '@cms/core/components/Empty'

export default function CampaingEditScreen({
    handleNewCampaign,
    type,
    data,
    onUpdate,
    onDelete
}) {
    console.log(data)
    return (
        <ContentBox>
            {data ? (
                <ContentDefault>
                    <Steps
                        data={data}
                        onUpdate={onUpdate}
                        onDelete={onDelete}
                        type={type}
                    />
                </ContentDefault>
            ) : null}

            {!data && (
                <>
                    <CustomEmpty message={'Nenhuma campanha criada'} />
                    <CampaingButtonNew handleNew={handleNewCampaign} />
                </>
            )}
        </ContentBox>
    )
}
