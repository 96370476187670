import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Form } from '../styles'
import { PageHeader } from '@ant-design/pro-layout'
import { message } from 'antd'
import { Button, Col, Row, Checkbox, Input } from 'antd'

import ColorPicker from '@cms/events/components/ColorPicker'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { UPDATE_EVENT } from '@cms/events/graphql/mutations/event'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { useApolloClient, useQuery } from '@apollo/client'
import { ApiHost } from '@cms/core/graphql/client'
import { useSelector } from 'react-redux'

import { GET_EVENT_STREAMING_TOKENS } from '@cms/events/graphql/queries/event'

const ConfigCertificate = () => {
    const [refresh, setRefresh] = useState(Date.now())
    const { eventId } = useParams()
    const client = useApolloClient()
    const user = useSelector((state) => state.user)

    const { data: tokensData } = useQuery(GET_EVENT_STREAMING_TOKENS, {
        variables: {
            event: eventId
        }
    })

    const previewToken = tokensData?.eventStreamingTokens?.preview

    const [{ form, formSave, saveLoading, getError }] = useCrudForm(
        {
            relateds: [],
            id: eventId,
            getData: async (id) => {
                if (!id) return
                const response = await client.query({
                    query: GET_EVENT,
                    variables: { id }
                })

                const data = { ...response.data.event }

                return data
            },
            updateData: async (id, formData) => {
                const variables = { id, ...formData }

                await client.mutate({
                    mutation: UPDATE_EVENT,
                    variables
                })

                setRefresh(refresh + 1)

                message.success('Configurações atualizadas com sucesso!')
            }
        },
        [eventId, setRefresh, refresh]
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Certificado"
                subTitle="Configuração de certificado"
            />
            <Content className="container-light">
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={formSave}
                    hideRequiredMark
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                valuePropName="checked"
                                name={['certificate', 'enabled']}
                                {...getError('certificate.enabled')}
                            >
                                <Checkbox>Habilitar Certificado?</Checkbox>
                            </Form.Item>

                            <Form.Item
                                name={['certificate', 'textColor']}
                                label={'Cor de Texto'}
                                {...getError('certificate.textColor')}
                            >
                                <ColorPicker />
                            </Form.Item>

                            <Form.Item
                                name={['certificate', 'bgImage']}
                                label="Imagem de fundo (1024x768)"
                                {...getError('certificate.bgImage')}
                            >
                                <MediaUploader
                                    options={{
                                        optimize: {
                                            resize: {
                                                width: 1024,
                                                height: 768
                                            },
                                            quality: 80
                                        }
                                    }}
                                    type="image"
                                />
                            </Form.Item>

                            <Form.Item
                                name={['certificate', 'duration']}
                                label={'Carga horária'}
                                {...getError('certificate.duration')}
                            >
                                <Input
                                    style={{ width: 140 }}
                                    placeholder="ex: 40 horas"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            {previewToken && (
                                <img
                                    width="100%"
                                    src={
                                        process.env.STREAMING_ENDPOINT +
                                        '/api/certificate/' +
                                        user.id +
                                        '/image' +
                                        '?preview=' +
                                        previewToken +
                                        '&refresh=' +
                                        refresh
                                    }
                                />
                            )}
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 20 }} className="footer">
                        <Col span={24}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={saveLoading}
                            >
                                Salvar e Visualizar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </>
    )
}

export default ConfigCertificate
