import styled from 'styled-components'
import { Typography } from 'antd'

const { Title: AntTitle } = Typography

export const Wrapper = styled.div`
    padding: 8px;
    border: 2px solid rgba(228, 236, 255, 0.1);
    border-radius: 20px;
    margin-bottom: 20px;
    background-color: #424246;
`

export const Title = styled(AntTitle)`
    padding-left: 8px;
`

export const Container = styled.div`
    padding: 40px;

    form {
        input {
            background: #38383c;
        }

        .ant-form-item::not('.panel-header') {
            border: 2px solid rgba(228, 236, 255, 0.1);
            border-radius: 20px;
            padding: 16px;
        }
        .panel-header {
            margin-bottom: 0px;
        }
    }

    .ant-collapse {
        margin-bottom: 20px;

        .ant-collapse-header {
            /* background-color: #424246; */
        }
        .ant-collapse-content {
            /* background-color: rgba(255, 255, 255, 0.2); */
        }
    }
    .ant-collapse-content-box {
        ${Wrapper} {
            background-color: transparent;
        }
    }

    .ant-divider {
        &::after,
        &::before {
            border-color: ${({ theme }) => theme.color.backgroundDarkLight};
        }
    }
`
