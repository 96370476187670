import React from 'react'
import { Collapse, PanelBodyWrapper } from './styles'
import { PanelFooter, PanelHeader } from '../../../components'

const { Panel } = Collapse

const Section = (props) => {
    const { index, activePanel, section, setSection, children, form } = props

    return (
        <Collapse activeKey={activePanel} className={`field-${index}`}>
            <Panel
                className={`section-${section}`}
                header={<PanelHeader form={form} {...props} />}
                key={index}
                showArrow={false}
            >
                <PanelBodyWrapper>{children}</PanelBodyWrapper>
                <PanelFooter setSection={setSection} />
            </Panel>
        </Collapse>
    )
}

export default Section
