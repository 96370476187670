import React from 'react'
import { Row } from 'antd'

import { Container } from './styles'
import { IntegrationRoutes } from './routes'

const IntegrationsPage = () => {
    return (
        // <Container>
        <IntegrationRoutes />
        // </Container>
    )
}

export default IntegrationsPage
