import React from 'react'
import { HomeOutlined } from '@ant-design/icons'
import { IAppModule } from '@cms/core/types/app'

export const SponsorDetailModules: IAppModule[] = [
    {
        name: 'Principal',
        icon: {
            filled: <HomeOutlined />,
            outline: <HomeOutlined />
        },
        module: 'events.event.dashboard',
        type: 'main',
        children: [
            {
                name: 'Conversões',
                path: `/evento/:eventId/dashboard/brand`,
                module: 'events.event.dashboard.conversions'
            }
            // {
            //     name: 'Visão Geral',
            //     path: `/evento/:eventId/dashboard/visao-geral`,
            //     module: 'events.event.dashboard.general'
            // }
        ]
    }
]

