import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_NOTIFICATION = gql`
    mutation CreateNotification(
        $event: ObjectID!
        $title: String!
        $icon: MediaInput!
        $message: String
        $button: String
        $link: String!
        $integrations: [ObjectID]
    ) {
        createNotification(
            input: {
                event: $event
                title: $title
                icon: $icon
                message: $message
                button: $button
                link: $link
                integrations: $integrations
            }
        ) {
            ...NotificationData
        }
    }

    ${fragments.NotificationData}
`

export const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification(
        $id: ObjectID!
        $event: ObjectID!
        $title: String!
        $icon: MediaInput!
        $message: String
        $button: String
        $link: String!
        $integrations: [ObjectID]
    ) {
        updateNotification(
            id: $id
            input: {
                event: $event
                title: $title
                icon: $icon
                message: $message
                button: $button
                link: $link
                integrations: $integrations
            }
        ) {
            ...NotificationData
        }
    }

    ${fragments.NotificationData}
`

export const DELETE_NOTIFICATION = gql`
    mutation DeleteNotification($id: ObjectID!) {
        deleteNotification(id: $id) {
            id
            success
        }
    }
`
