import Modal from '@cms/core/components/ModalFull'
import React, { useEffect } from 'react'

import ModalContent from './modalContent'

const DetailsModal = ({ params, eventId }) => {
    return (
        <Modal
            disableFooter={true}
            visible={true}
            showMenu={false}
            children={<ModalContent params={params} eventId={eventId} />}
        />
    )
}

export default DetailsModal
