import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import {
    CREATE_CAMPAIGN,
    DELETE_CAMPAIGN,
    UPDATE_CAMPAIGN
} from '@cms/events/graphql/mutations/campaign'

import { removeObjectProps } from '@cms/events/utils'
import Loader from '@cms/core/components/Loader'
import CampaingEditScreen from '@cms/events/components/Campaings/EditScreen'
import { CampaingPage } from '@cms/events/components/Campaings/styles'

const blankCampaign = {
    triggers: [
        {
            event: 'indication',
            description: 'Indicar um amigo',
            points: 1
        }
    ],
    messages: {
        notificationReferrer: {
            active: false,
            emailTemplate: {
                nameFrom: 'John Doe',
                emailFrom: 'john@doe.com',
                subject: 'Chegou inscrição de indicado',
                replyTo: 'john@doe.com',
                sayHello: 'Ola',
                content:
                    '<p>Um amigo acabou de se cadastrar no evento.<p><p><br></p><p><br></p><p><br></p><p>Agora continue convidando seus amigos para alcançar o próximo asso.</p>',
                thankYouMessage: 'Obrigado',
                button: {
                    text: 'Convide seus amigos',
                    link: 'https://tdplataform.com/campaign/minha-campanha',
                    backgroundColor: '#e91e63',
                    textColor: '#fff'
                }
            }
        }
    }
}

export default function ReferalEditPage() {
    const { eventId } = useParams()
    const [campaingDataClean, setCampaingDataClean] = useState(null)
    const [saveCampaign] = useMutation(CREATE_CAMPAIGN)
    const [updateCampaign] = useMutation(UPDATE_CAMPAIGN)
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
        refetchQueries: [{ query: GET_EVENT, variables: { id: eventId } }]
    })

    const {
        error: eventError,
        data: eventData,
        refetch
    } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    useEffect(() => {
        if (!eventData || !eventData.event.viralCampaign) return
        setCampaingDataClean(
            removeObjectProps(eventData.event.viralCampaign, ['__typename'])
        )
    }, [eventData])

    if (!eventData) return <Loader fixed={false} padding="10px" />
    if (eventError) return `Error! ${eventError.message}`

    const onUpdate = async (variables) => {
        const { data: saveData } = await updateCampaign({
            variables
        })

        setCampaingDataClean(
            removeObjectProps(saveData.updateViralCampaign, ['__typename'])
        )
    }

    const handleDelete = async () => {
        await deleteCampaign({
            variables: { id: eventData.event.viralCampaign.id }
        })
        setCampaingDataClean(null)
    }

    const handleNewCampaign = async () => {
        blankCampaign.event = eventId

        const response = await saveCampaign({
            variables: blankCampaign
        })

        await updateCampaign({
            variables: {
                id: eventId,
                viralCampaign: response.data.createViralCampaign.id
            }
        })
        refetch()
    }

    return (
        <CampaingPage>
            <CampaingEditScreen
                data={campaingDataClean}
                onUpdate={onUpdate}
                onDelete={handleDelete}
                handleNewCampaign={handleNewCampaign}
            />
        </CampaingPage>
    )
}
