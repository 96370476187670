import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_COLLECTION_CATEGORY = gql`
    mutation CreateCollectionCategory(
        $event: ObjectID!
        $name: String!
        $description: String
        $hasAction: Boolean
        $showPreEvent: Boolean
        $tags: [String]
    ) {
        createCollectionCategory(
            input: {
                event: $event
                name: $name
                description: $description
                hasAction: $hasAction
                showPreEvent: $showPreEvent
                tags: $tags
            }
        ) {
            ...CollectionCategoryData
        }
    }

    ${fragments.CollectionCategoryData}
`

export const UPDATE_COLLECTION_CATEGORY = gql`
    mutation UpdateCollectionCategory(
        $id: ObjectID!
        $event: ObjectID
        $name: String
        $description: String
        $hasAction: Boolean
        $showPreEvent: Boolean
        $tags: [String]
    ) {
        updateCollectionCategory(
            id: $id
            input: {
                event: $event
                name: $name
                description: $description
                hasAction: $hasAction
                showPreEvent: $showPreEvent
                tags: $tags
            }
        ) {
            ...CollectionCategoryData
        }
    }

    ${fragments.CollectionCategoryData}
`

export const DELETE_COLLECTION_CATEGORY = gql`
    mutation DeleteCollectionCategory($id: ObjectID!) {
        deleteCollectionCategory(id: $id) {
            id
            success
        }
    }
`
