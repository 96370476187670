import React from 'react'

import { Col, Row, Button, Typography, Input } from 'antd'
import { COLORS } from '@cms/core/themes'

const { Title } = Typography

const RewardCounter = ({ onChange, value, pointLabel }) => {
    const handleIncrement = () => {
        const _value = (+value || 0) + 1
        onChange(_value)
    }

    const handleDecrement = () => {
        const _value = !+value ? 0 : +value - 1
        onChange(_value)
    }

    return (
        <div>
            <Row
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRight: `1px solid ${COLORS.textDark}`
                }}
            >
                <Col span={24} style={{ marginTop: '20px' }}>
                    {/* <Title style={{fontSize:'40px', marginBottom: 0}} level={3}>{ value || 0 }</Title> */}
                    <Input
                        min={0}
                        defaultValue={0}
                        value={value}
                        onChange={(event) => {
                            onChange(
                                event.target.value
                                    ? parseInt(event.target.value)
                                    : 0
                            )
                        }}
                        style={{
                            textAlign: 'center',
                            fontSize: '40px',
                            fontWeight: 'bold',
                            marginBottom: '0',
                            height: '40px',
                            border: 'none'
                        }}
                    />
                </Col>
                <Col span={24}>
                    <Title level={4} style={{ marginBottom: 0 }}>
                        {pointLabel}
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Button
                        style={{
                            borderRadius: '0px 0px 0px 8px',
                            borderColor: 'transparent',
                            borderRight: '1px solid rgb(0 0 0 / 17%)',
                            fontSize: '25px',
                            lineHeight: '1.1em',
                            height: '50px'
                        }}
                        block
                        onClick={handleDecrement}
                        disabled={value === 0}
                    >
                        -
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        style={{
                            borderRadius: '0px',
                            borderColor: 'transparent',
                            fontSize: '25px',
                            lineHeight: '1.1em',
                            height: '50px'
                        }}
                        block
                        onClick={handleIncrement}
                    >
                        +
                    </Button>
                </Col>
            </Row>
        </div>
    )
}

export default RewardCounter

