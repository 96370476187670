import React from 'react'
import { ContentDefault } from './styles'

const Content = ({ children, ...props }) => {
    const localClass = props.className ? `${props.className} td-content-default` : 'td-content-default'
    return (
        <ContentDefault {...props} className={localClass}>
            { children}
        </ContentDefault>
    )
}

export default Content