import React, { createContext, useCallback, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { GET_CHECKOUT_CONFIG } from '@cms/events/graphql/queries/event'

import { UPDATE_CHECKOUT_CONFIG } from '@cms/events/graphql/mutations/event'

import { removeObjectProps, resolveRelateds } from '@cms/events/utils'
import { DEFAULT_THEME } from '../constants/theme'
import { merge } from 'lodash'
import { useThemeBuilderContext } from '@cms/core/components/ThemeBuilder'

interface CheckoutContextProps {
    loading: boolean
    save: () => void
}

export const CheckoutContext = createContext<CheckoutContextProps>({
    loading: false,
    save: () => null
})

export const useCheckoutContext = () => useContext(CheckoutContext)

export const CheckoutProvider = ({
    children
}: {
    children: React.ReactNode
}) => {
    const { eventId } = useParams<{ eventId: string }>()
    const {
        images,
        theme,
        setTheme,
        configs,
        scripts,
        setImages,
        setConfigs,
        setScripts
    } = useThemeBuilderContext()

    const { data, loading } = useQuery(GET_CHECKOUT_CONFIG, {
        variables: { id: eventId }
    })

    const [updateEvent] = useMutation(UPDATE_CHECKOUT_CONFIG)

    useEffect(() => {
        if (data?.event?.checkoutConfig) {
            const checkoutConfig = removeObjectProps(
                { ...data.event.checkoutConfig },
                ['__typename']
            )

            setTheme(merge({}, DEFAULT_THEME, checkoutConfig?.theme))

            const {
                backgroundImage,
                loginBackgroundImage,
                redirectUrl,
                infos
            } = checkoutConfig

            setConfigs({
                redirectUrl,
                infos
            })

            setImages({
                backgroundImage,
                loginBackgroundImage
            })

            setScripts(checkoutConfig?.scripts)
        }
    }, [data])

    const save = useCallback(async () => {
        await updateEvent({
            variables: {
                id: eventId,
                checkoutConfig: {
                    theme,
                    redirectUrl: configs?.redirectUrl,
                    info: configs?.info,
                    scripts,
                    ...resolveRelateds({ ...images }, [
                        'backgroundImageDesktop',
                        'backgroundImageHome',
                        'scheduleImage',
                        'scheduleImageMobile'
                    ])
                }
            }
        })
    }, [eventId, theme, images, configs, scripts, updateEvent])

    return (
        <CheckoutContext.Provider
            value={{
                save,
                loading
            }}
        >
            {children}
        </CheckoutContext.Provider>
    )
}
