import React, { useState } from 'react'

import {
    message,
    Form,
    Input,
    InputNumber,
    Select,
    Tag,
    Button,
    Row,
    Col,
    Typography
} from 'antd'

import { useSelector } from 'react-redux'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useApolloClient, useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'
import { GET_BRAND, GET_BRAND_TAGS } from '@cms/events/graphql/queries/brand'
import {
    GET_BRAND_PLAN,
    GET_BRAND_PLANS_LIST
} from '@cms/events/graphql/queries/brandPlan'
import {
    CrudForm,
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import {
    CREATE_BRAND,
    UPDATE_BRAND,
    UPDATE_BRAND_DRAFT
} from '@cms/events/graphql/mutations/brand'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import Card from '@cms/core/components/Card'

import { StyledCard, StyledContent, StyledFooter } from './styles'
import { displayIf } from '@cms/core/utils'
import { merge, pickBy, identity } from 'lodash'

const { Paragraph } = Typography

const modeOptions = [
    {
        name: 'Pequeno',
        value: 'small'
    },
    {
        name: 'Padrão',
        value: 'normal'
    },
    {
        name: 'Grande',
        value: 'large'
    },
    {
        name: 'Link Externo',
        value: 'external'
    }
]

const getData = (data) => {
    return {
        ...data
    }
}

const BrandEditModal = ({ ...props }) => {
    const { eventId } = props

    const [hostType, setHostType] = useState('DIGITAL')
    const [standType, setStandType] = useState()
    const isSponsor = useSelector((state) => state.user.role === 'sponsor')
    const client = useApolloClient()

    const { data: TagsData } = useQuery(GET_BRAND_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const queryPlans = useQuery(GET_BRAND_PLANS_LIST, {
        variables: { first: 9999 },
        fetchPolicy: 'cache-and-network'
    })

    const [{ form, getError, formUpdate }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_BRAND,
                variables: { id }
            })

            setHostType(data.brand.plan?.hostType || 'DIGITAL')
            setStandType(data.brand.talkStand?.type || 'email')
            form.validateFields([['talkStand', 'type']])

            const mergeData = merge(
                {},
                data.brand,
                pickBy(data.brand.draft.data, identity)
            )

            return getData(mergeData)
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_BRAND_DRAFT,
                variables: {
                    ...formData,
                    id
                    //event: eventId
                }
            })

            message.success('Solicitação de alteração realizado com sucesso.')
        }
    })

    const [socialMediaTypes] = useState([
        'Linkedin',
        'Facebook',
        'Twitter',
        'Instagram',
        'TikTok'
    ])

    return (
        <CrudModal
            width={600}
            title="Editar Patrocinador"
            {...modalProps}
            {...props}
        >
            <CrudForm
                layout={'vertical'}
                form={form}
                initialValues={{ talkStand: { type: 'email' } }}
            >
                <Form.Item name="description" label="Descrição">
                    <Input.TextArea placeholder="Descrição do patrocinador" />
                </Form.Item>

                <Form.Item name="aboutUs" label="Sobre Nós">
                    <Input.TextArea />
                </Form.Item>

                <Card title="Para a pagina de inscrição">
                    <Form.Item name="site" label="Site">
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="Email">
                        <Input type="email" />
                    </Form.Item>

                    <Form.Item name="contact" label="Contato">
                        <Input.TextArea placeholder="Informações de Contato" />
                    </Form.Item>

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Logo"
                                name="logo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Logo é obrigatório!'
                                    }
                                ]}
                            >
                                <MediaUploader type="svg" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="Banner" name="image">
                                <MediaUploader type="image" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Redes sociais">
                    <Form.List name="social">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`social.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={24}>
                                                <Paragraph>
                                                    Rede {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Plataforma"
                                                    name={[field.name, 'type']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Plataforma é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Select
                                                        showSearch
                                                        filterOption={(
                                                            input,
                                                            option
                                                        ) =>
                                                            option.children
                                                                .toLowerCase()
                                                                .indexOf(
                                                                    input.toLowerCase()
                                                                ) >= 0
                                                        }
                                                    >
                                                        {socialMediaTypes.map(
                                                            (o) => (
                                                                <Option key={o}>
                                                                    {o}
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    {...field}
                                                    label="Url"
                                                    name={[field.name, 'url']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Url é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Botões e ações do stand do patrocinador"
                >
                    <Form.List name="actions">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`actions.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Paragraph>
                                                    Ação {index + 1}
                                                </Paragraph>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            label="Nome"
                                                            name={[
                                                                field.name,
                                                                'name'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Nome é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Url"
                                                            name={[
                                                                field.name,
                                                                'url'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Url é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Modo"
                                                            name={[
                                                                field.name,
                                                                'size'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Modo é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Select>
                                                                {modeOptions.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {
                                                                                option.name
                                                                            }
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Materiais para download"
                >
                    <Form.List name="resources">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`resources.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={24}>
                                                <Paragraph>
                                                    Recurso {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Titulo"
                                                    name={[field.name, 'title']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Titulo é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    {...field}
                                                    label="Link"
                                                    name={[field.name, 'link']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Link é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Vídeos institucionais"
                >
                    <Form.List name="institutionalVideos">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`institutionalVideos.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={22}>
                                                <Paragraph>
                                                    Video {index + 1}
                                                </Paragraph>
                                                <Row gutter={24}>
                                                    <Col span={24}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Url"
                                                            name={[
                                                                field.name,
                                                                'url'
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={2}>
                                                <MinusCircleOutlined
                                                    style={{ marginTop: 20 }}
                                                    onClick={() =>
                                                        remove(field.name)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => {
                                            add({})
                                        }}
                                        style={{ width: '100%' }}
                                    >
                                        <PlusOutlined /> Adicionar
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Card>

                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Página do patrocinador"
                >
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label="Tags" name={['tags']}>
                                <Select
                                    mode="tags"
                                    tagRender={({ label, ...props }) => (
                                        <Tag {...props} color="#7F44FF">
                                            {label}
                                        </Tag>
                                    )}
                                    style={{ width: '100%' }}
                                    placeholder="Selecione uma tag existe ou crie uma nova."
                                >
                                    {TagsData?.brandTags?.map((tag) => (
                                        <Select.Option key={tag} value={tag}>
                                            {tag}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            {!isSponsor && (
                                <Form.Item
                                    label="Ordem de listagem descrescente"
                                    name="priority"
                                    {...getError('priority')}
                                >
                                    <InputNumber style={{ width: '100%' }} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                </Card>
            </CrudForm>
        </CrudModal>
    )
}

export default BrandEditModal

