import styled, { css } from 'styled-components'

import { Tabs as TabsAnt } from 'antd'
import { COLORS } from '@cms/core/themes'
import Button from '@cms/events/components/Buttons/ButtonDefault'

const { TabPane: TabPaneAnt } = TabsAnt

export const Tabs = styled(TabsAnt)`
    .ant-tabs-extra-content {
        margin: 0 10px 3px 0;
    }
`

export const InfoCard = styled.div`
    height: 50px;
    background: ${COLORS.bgLight};
    border-radius: 10px;
    display: flex;
    padding: 0px 10px;
    flex-direction: column;
    justify-content: ${({ justify }) => (justify ? justify : 'space-between')};
    align-items: ${({ align }) => (align ? align : 'flex-start')};
    margin-right: 17px;
    p {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
    }
    span {
        display: block;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
    }
`

export const WrapperButtons = styled.div`
    width: 45%;
    .ant-btn {
        margin-right: 10px;
    }
    button:hover {
        background-color: #fff;
        color: #000;
    }
`

export const WrapperInfo = styled.div`
    display: flex;
`

export const TabPane = styled(TabPaneAnt)``

export const TalkConfigWrapper = styled.div`
    display: contents;

    .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane {
            height: 100%;
        }
    }
`

export const FullScreenBtn = styled.div`
    padding: 8px;
    border-radius: 100%;
    background: ${COLORS.bgLight};
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;

    svg {
        fill: #fff;
    }

    &:hover {
        cursor: pointer;
        background: ${COLORS.active};
    }
`
