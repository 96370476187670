import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useLazyQuery, useQuery } from '@apollo/client'
import Activity from '../../../components/Lead/Activity'
import Header from '../../../components/Lead/Header'
import Details from '../../../components/Lead/Details'
import Forms from '../../../components/Lead/Forms'

import { template } from 'lodash'

import {
    Layout,
    Row,
    Col,
    Typography,
    Divider,
    Timeline,
    Button,
    Collapse,
    Card
} from 'antd'

import _get from 'lodash/get'

import {
    LinkOutlined,
    EyeOutlined,
    LoginOutlined,
    StarOutlined,
    SmileOutlined,
    MessageOutlined,
    NotificationOutlined,
    DownCircleOutlined,
    AimOutlined,
    UserAddOutlined,
    ApiOutlined
} from '@ant-design/icons'
import { RiDraftFill } from '@remixicon/react'

import Content from '@cms/core/components/Content'
import { CrudColumns } from '@cms/core/components/Crud'
import Loader from '@cms/core/components/Loader'
import { ButtonLink, ButtonReturn } from '@cms/events/components/Buttons'

import { CardInfo, CardActivity, ContentContainer } from './styles'

import { GET_SUBSCRIPTION_DETAIL } from '@cms/events/graphql/queries/subscriptions'
import { GET_TRACKER_TIMELINE } from '@cms/events/graphql/queries/user'
import { GET_EVENT_FIELDS } from '@cms/events/graphql/queries/event'
import { GET_CUSTOM_TRACKER_LIST } from '@cms/events/graphql/queries/trackers'

import dayjs from 'dayjs'
import { GET_EVENT_FORM_DATA } from '@cms/events/graphql/queries/form'

import { formatField } from '@td/commons/fields/formatter'

const TrackersList = {
    login: {
        icon: <LoginOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Fez login no evento`
    },
    register: {
        icon: <UserAddOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Resgitrou no evento`
    },
    page: {
        icon: <EyeOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Visualizou a página ${data.properties.title}`
    },
    userView: {
        icon: <EyeOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Visualizou o perfil do usuário ${data.related.name}`
    },
    userContact: {
        icon: <MessageOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Contatou o usuário ${data.related.name}`
    },
    talkView: {
        icon: <EyeOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Visualizou a palestra ${data.related.title}`
    },
    talkRated: {
        icon: <StarOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Avaliou a palestra ${data.related.title}`
    },
    talkReacted: {
        icon: <SmileOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Reagiu a palestra ${data.related.title}`
    },
    talkAdvertising: {
        icon: <NotificationOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Clicou no anúncio palestra ${data.related.title}`
    },
    bannerClick: {
        icon: <AimOutlined style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Clicou no banner <a href=${data.related.link} tagert="_blank">${data.related.link}</a>`
    },
    brandAction: {
        icon: <AimOutlined style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Clicou na ação ${data.properties.name} no patrocinador ${data.related.name}`
    },
    brandContact: {
        icon: <MessageOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Contatou o patrocinador ${data.related.name}`
    },
    brandView: {
        icon: <EyeOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Visualizou o patrocinador ${data.related.name}`
    },
    brandSocial: {
        icon: <LinkOutlined style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Acessou a rede social "${data.properties.social}" do patrocinador ${data.related.name}`
    },
    brandSite: {
        icon: <LinkOutlined style={{ fontSize: '1em' }} />,
        message: (data) => `Acessou o site do patrocinador ${data.related.name}`
    },
    brandVideo: {
        icon: <EyeOutlined style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Assistiu o vídeo no patrocinador ${data.related.name}`
    },
    brandDownload: {
        icon: <DownCircleOutlined style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Fez o downaload do material ${data.properties.name} no patrocinador ${data.related.name}`
    },
    formAnswer: {
        icon: <RiDraftFill style={{ fontSize: '1em' }} />,
        message: (data) =>
            `Respondeu ao formulário complementar ${data.related.name}`
    }
}

const { Text, Title } = Typography

const getFieldValue = (field, userData, path, defautlValue = '---') => {
    let value = _get(userData?.data, path)
    if (!value) value = _get(userData?.data?.customFields, path)
    if (!value) value = _get(userData?.user, path)
    value = formatField(field, value)
    return value || defautlValue
}

const ModalContent = ({ params, eventId, ...props }) => {
    const { id, userId } = useParams()
    const [columns, setColumns] = useState([])

    const { data, loading } = useQuery(GET_SUBSCRIPTION_DETAIL, {
        variables: { id }
    })

    const { data: formData, loading: formDataLoading } = useQuery(
        GET_EVENT_FORM_DATA,
        {
            variables: { userId: userId, eventId }
        }
    )

    const eventFields = useQuery(GET_EVENT_FIELDS, {
        variables: { id: eventId }
    })

    const customTrackersQuery = useQuery(GET_CUSTOM_TRACKER_LIST)

    const [getTracker, { data: dataTimeline }] =
        useLazyQuery(GET_TRACKER_TIMELINE)

    let customFields = []

    useEffect(() => {
        if (data) {
            getTracker({
                variables: {
                    id: data?.eventRegistration.user.id,
                    eventId
                }
            })
        }
    }, [data])

    useEffect(() => {
        if (formData) {
            const formColumn = []
            formData.eventFormsData?.edges?.forEach(({ node }) => {
                if (node && node.form && node.form.type === 'progressive') {
                    const {
                        form: { fields, name: formName },
                        data
                    } = node
                    const cards = []

                    fields.forEach((field) => {
                        if (field.type !== 'session') {
                            let value = getFieldValue(field, data, field.name)

                            cards.push(
                                <Card key={field.name}>
                                    <Title>{field.label}:</Title>
                                    <Text>{value}</Text>
                                </Card>
                            )
                        }
                    })

                    formColumn.push({ formName, cards })
                }
            })

            setColumns(formColumn)
        }
    }, [formData])

    if (
        !data ||
        customTrackersQuery.loading ||
        loading ||
        formDataLoading ||
        eventFields.loading
    ) {
        return <Loader padding="10px" />
    }

    const { user, publicUrl } = data?.eventRegistration

    if (eventFields.data?.event.fields) {
        customFields = eventFields.data?.event.fields.map((field) => {
            const value = getFieldValue(
                field,
                data?.eventRegistration,
                field.name
            )
            return {
                label: [field.label, field?.config?.label]
                    .filter((o) => !!o)
                    .join(' '),
                value
            }
        })
    }

    const customTrackers = customTrackersQuery.data.customTrackers.edges.map(
        (edge) => edge.node
    )

    const activityMessage = (item) => {
        if (TrackersList[item.eventName]) {
            return TrackersList[item.eventName].message(item)
        }

        const customTracker = customTrackers.find(
            (customTracker) => customTracker.eventName === item.eventName
        )

        if (customTracker) {
            return template(customTracker.eventMessage, {
                interpolate: /{{([\s\S]+?)}}/g
            })(item.properties)
        }

        return null
    }

    return (
        <Layout
            style={{
                height: '100%',
                width: '100%',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <Content
                style={{
                    padding: 0,
                    backgroundColor: 'rgba(255, 255, 255, 0.0)',
                    marginBottom: '0',
                    marginTop: '120px'
                }}
            >
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '20px'
                    }}
                >
                    <Row align="middle" justify="start">
                        <ButtonReturn
                            id="ButtonReturn"
                            path={`/evento/${eventId}/inscricoes`}
                        />
                        <Text
                            style={{
                                marginLeft: '20px',
                                fontWeight: 'bold'
                            }}
                        >
                            Detalhes
                        </Text>
                    </Row>

                    <Card bodyStyle={{ padding: 0 }} bordered={false}>
                        <Header>
                            <Row className="widthFull" align={'middle'}>
                                <Col span={18}>
                                    <Row>
                                        <CrudColumns
                                            avatar={{
                                                size: 80
                                            }}
                                            image={user?.avatar?.url}
                                        />
                                        <Col className="infoJob">
                                            <Title
                                                id="userName"
                                                style={{ marginTop: 0 }}
                                            >
                                                {user.name}
                                            </Title>
                                            <Row>
                                                <Text className="officeName">
                                                    Cargo:
                                                </Text>
                                                <Text>
                                                    {[
                                                        getFieldValue(
                                                            null,
                                                            data?.eventRegistration,
                                                            'jobTitle'
                                                        )
                                                    ]}
                                                </Text>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={6}>
                                    <Row justify={'space-evenly'}>
                                        <Button
                                            type="primary"
                                            href={publicUrl}
                                            target="_blank"
                                            styles={{
                                                lineHeight: '1.8'
                                            }}
                                        >
                                            URL pública do Lead
                                        </Button>
                                    </Row>
                                </Col>
                            </Row>
                        </Header>
                    </Card>
                    <ContentContainer>
                        <Row gutter={[16, 16]}>
                            <Col span={6}>
                                <Card
                                    bodyStyle={{ padding: 0 }}
                                    bordered={false}
                                >
                                    <Details>
                                        <Title>Detalhes do lead</Title>
                                        <Divider />
                                        <Col id="ScrollDetails">
                                            {customFields.map(
                                                (field, index) => (
                                                    <CardInfo key={index}>
                                                        <Title>
                                                            {field.label}:
                                                        </Title>
                                                        <Text>
                                                            {field.value}
                                                        </Text>
                                                    </CardInfo>
                                                )
                                            )}
                                        </Col>
                                    </Details>
                                </Card>
                            </Col>
                            <Col span={18}>
                                <Card
                                    bodyStyle={{ padding: 0 }}
                                    bordered={false}
                                >
                                    <Activity>
                                        <Title>Atividades do lead</Title>
                                        <Divider />
                                        <Timeline id="scrollTimeLine">
                                            {dataTimeline?.trackerTimeline?.map(
                                                (item, index) => (
                                                    <Timeline.Item
                                                        key={index}
                                                        dot={
                                                            TrackersList[
                                                                item.eventName
                                                            ] ? (
                                                                TrackersList[
                                                                    item
                                                                        .eventName
                                                                ].icon
                                                            ) : (
                                                                <ApiOutlined
                                                                    style={{
                                                                        fontSize:
                                                                            '1em'
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    >
                                                        <CardActivity>
                                                            <Title>
                                                                <span>
                                                                    {activityMessage(
                                                                        item
                                                                    )}
                                                                </span>
                                                            </Title>
                                                            <Text>
                                                                {dayjs
                                                                    .utc(
                                                                        new Date(
                                                                            item.createdAt
                                                                        )
                                                                    )
                                                                    .local()
                                                                    .format(
                                                                        'DD/MM/YYYY HH:mm'
                                                                    )}
                                                            </Text>
                                                        </CardActivity>
                                                    </Timeline.Item>
                                                )
                                            )}
                                        </Timeline>
                                    </Activity>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            {columns && columns.size > 0 && (
                                <Forms>
                                    <Title>Formulários preenchidos</Title>
                                    <Divider />
                                    {columns.map((form, index) => (
                                        <Collapse key={index}>
                                            <Collapse.Panel
                                                header={form.formName}
                                                key={index}
                                            >
                                                {form.cards}
                                            </Collapse.Panel>
                                        </Collapse>
                                    ))}
                                </Forms>
                            )}
                        </Row>
                    </ContentContainer>
                </div>
            </Content>
        </Layout>
    )
}

export default ModalContent
