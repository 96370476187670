import Title from 'antd/es/typography/Title'
import styled from 'styled-components'
import Content from '@cms/core/components/Content'

export const StyledContent = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    .anticon-warning {
        font-size: 48px;
        margin-bottom: 10px;
    }

    .ant-typography {
        font-size: 20px;
        text-align: center;
        max-width: 600px;
        a {
            font-size: 22px;
            text-decoration: underline;
        }
    }
`
