import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { GET_COUNT_TOTAL_TALKS } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'

import Card from '../CardInner'

const TotalTalksViews = ({ eventId, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_COUNT_TOTAL_TALKS, {
        variables: { event: eventId }
    })
    if (loading || error) return null

    const hours = Math.floor(data.talksViewTimeAverage.total / (1000 * 60 * 60))
    const minutes = Math.floor(data.talksViewTimeAverage.total / (1000 * 60))

    // TODO: Renderizar melhor total
    return (
        <>
            <Card
                title={`Total de ${
                    hours > 0 ? 'horas assistidas' : 'minutos assistidos'
                }`}
                reverse={reverse}
                data={hours > 0 ? hours + 'h' : minutes + ' min'}
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalTalksViews
