import React from 'react'

import dayjs from 'dayjs'

import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Line,
    LineChart
} from 'recharts'

import { Typography, Row, Col } from 'antd'

import { useQuery } from '@apollo/client'

import { GET_BRAND_VIEW_BY_DAY } from '@cms/events/graphql/queries/brand'
import Loader from '@cms/core/components/Loader'
import Empty from '@cms/core/components/Empty'

import { RiseOutlined } from '@ant-design/icons'

import {
    ContainerChart,
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '../../styles'

const { Text } = Typography

const ViewChart = ({ eventId, brandId }) => {
    const { data, error } = useQuery(GET_BRAND_VIEW_BY_DAY, {
        variables: { eventId, brandId, limit: 30 }
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    let startDate, endDate

    if (data.brandViewByDay && data.brandViewByDay.length > 0) {
        const items = data.brandViewByDay
        startDate = new Date(items[items.length - 1].date).getTime()
        endDate = new Date(items[0].date).getTime()
    }

    console.log('data.brandViewByDay', data.brandViewByDay, startDate, endDate)

    return (
        <ContainerChart>
            <DashboardCardTitle>
                <IconWrapper>
                    <RiseOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Desempenho dos últimos 30 dias</DashboardTitle>
            </DashboardCardTitle>

            <ResponsiveContainer width="100%" height={300}>
                {data.brandViewByDay && data.brandViewByDay.length > 0 ? (
                    <LineChart
                        data={data.brandViewByDay.map((values) => ({
                            ...values,
                            total: values.total
                        }))}
                        margin={{ top: 30, right: 10, left: 10, bottom: 0 }}
                    >
                        <XAxis
                            domain={[startDate, endDate]}
                            dataKey="date"
                            type={'number'}
                            tickFormatter={(v) =>
                                dayjs.utc(v).format('DD/MM/YYYY')
                            }
                            tickCount={data.brandViewByDay.length + 1}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={10}
                        />
                        <YAxis
                            dataKey="total"
                            axisLine={false}
                            tickLine={false}
                            padding={{ right: 30 }}
                        />
                        <CartesianGrid
                            vertical={false}
                            stroke="rgba(255, 255, 255, 0.1)"
                            strokeDasharray="2 0"
                        />
                        <Tooltip
                            labelFormatter={(v) =>
                                dayjs.utc(v).format('DD/MM/YYYY')
                            }
                        />
                        <Legend />
                        <Line
                            type="monotone"
                            stroke="#7F44FF"
                            name="Visualizações"
                            dataKey="total"
                            strokeWidth={5}
                            dot={false}
                        />
                    </LineChart>
                ) : (
                    <Empty />
                )}
            </ResponsiveContainer>
        </ContainerChart>
    )
}

export default ViewChart
