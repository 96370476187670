import React from 'react'

import { useQuery } from '@apollo/client'

import { Typography, Row } from 'antd'

import { CrudList, CrudColumns } from '@cms/core/components/Crud'

import { GET_BRAND_TOP_VIEWS } from '@cms/events/graphql/queries/brand'

import { CrownOutlined } from '@ant-design/icons'

import { DashboardCardTitle, DashboardTitle, IconWrapper } from '../../styles'

const { Text } = Typography

const Ranking = ({ eventId }) => {
    const query = useQuery(GET_BRAND_TOP_VIEWS, {
        variables: {
            eventId
        }
    })

    const columns = [
        {
            title: 'Patrocinadores',
            dataIndex: [''],
            render: (_value, obj, index) => {
                const title = obj.node?.name || '---'
                const views = obj.node?.views || '0'
                return (
                    <CrudColumns
                        counterSize={25}
                        title={title}
                        text={[
                            // `Número de Acessos - 👁️ ${views} views`
                            `${views} views`
                        ]}
                    />
                )
            }
        }
    ]

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <CrownOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Top Patrocinadores</DashboardTitle>
            </DashboardCardTitle>

            <CrudList
                header={{}}
                columns={columns}
                queryName={'topBrandViews'}
                hideAction={'all'}
                showHeader={false}
                transparent
                borderless
                {...query}
            />
        </>
    )
}

export default Ranking
