import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_OR_UPDATE_WIDGET = gql`
    mutation CreateOrUpdateWidget(
        $event: ObjectID!
        $data: JSON
        $type: String
    ) {
        createOrUpdateWidget(
            input: { event: $event, data: $data, type: $type }
        ) {
            type
            data
        }
    }
`
