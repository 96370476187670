import React from 'react'
import { Avatar, Row, Space, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'

import { Title, AvatarSvg, Counter, Info, InfoText } from './styles'

const { Paragraph } = Typography

const CrudColumns = (props) => {
    const { title, text, image, avatar, onlyText, counter } = props
    const avatarProps = image ? { src: image } : { icon: <UserOutlined /> }
    let ext = image ? image.substr(image.lastIndexOf('.') + 1) : null
    const columnStyles = {
        maxWidth: '50px',
        marginRight: '10px'
    }

    if (avatar) {
        avatarProps.size = avatar.size || 'large'
        columnStyles.maxWidth = avatar.size + 'px'
    } else {
        avatarProps.size = 40
    }

    const content = text ? (
        <Space direction="vertical" size="small">
            {text.map((item, key) => (
                <span key={key}>{item}</span>
            ))}
        </Space>
    ) : null

    return (
        <Row
            className="crud-information"
            align={'middle'}
            justify={'start'}
            style={{ flexFlow: 'row' }}
        >
            {!onlyText && !counter && (
                <span style={columnStyles}>
                    {ext && ext === 'svg' ? (
                        <AvatarSvg url={image} />
                    ) : (
                        <Avatar {...avatarProps} />
                    )}
                </span>
            )}

            {counter && (
                <span style={columnStyles}>
                    <Counter>{counter}</Counter>
                </span>
            )}

            <Info className="crud-columns-info">
                {title && <Title level={4}>{title}</Title>}

                <Paragraph ellipsis={{ rows: 4, tooltip: content }}>
                    {content}
                </Paragraph>
            </Info>
        </Row>
    )
}

export default CrudColumns
