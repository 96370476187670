import React, { createContext, useContext, useState } from 'react'

export const PageBuilderContext = createContext({
    colors: {},
    setColors: () => null,
    loading: false,
    setLoading: () => null,
    site: {},
    setSite: () => null,
    config: {},
    setConfig: () => null,
    event: {},
    setEvent: () => null
})

export const usePageBuilderContext = () => useContext(PageBuilderContext)

export const PageBuilderProvider = ({ children }) => {
    const [colors, setColors] = useState(null)
    const [loading, setLoading] = useState(false)
    const [site, setSite] = useState(null)
    const [config, setConfig] = useState(null)
    const [event, setEvent] = useState(null)

    return (
        <PageBuilderContext.Provider
            value={{
                colors,
                setColors,
                site,
                setSite,
                config,
                setConfig,
                loading,
                setLoading,
                event,
                setEvent
            }}
        >
            {children}
        </PageBuilderContext.Provider>
    )
}
