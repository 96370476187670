import React from 'react'
import { message, Form, Input } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_LOCATION } from '@cms/events/graphql/queries/locations'
import {
    CREATE_LOCATION,
    UPDATE_LOCATION
} from '@cms/events/graphql/mutations/locations'

const LocationModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_LOCATION,
                variables: { id }
            })

            return data.location
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_LOCATION,
                update: addToList({ list: 'locations', Type: 'Location' }),
                variables: { ...formData, event: eventId }
            })

            message.success('Local cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_LOCATION,
                variables: { id, ...formData, event: eventId }
            })

            message.success('Local atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Local' : 'Novo Local'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[{ required: true, message: 'Nome é obrigatório!' }]}
                    {...getError('name')}
                >
                    <Input />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default LocationModal
