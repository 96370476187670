import React from 'react'
import { useQuery } from '@apollo/client'
import { GET_COUNT_OF_TALK_VIEWS } from '@cms/events/graphql/queries/blocks/totals'
import { renderBaseK } from '@cms/core/utils'

import Card from '../CardInner'

const TotalsViews = ({ eventId, category, talk, reverse, align, full }) => {
    const { loading, error, data } = useQuery(GET_COUNT_OF_TALK_VIEWS, {
        variables: { eventId, category, talk }
    })

    if (loading || error) return null

    return (
        <>
            <Card
                title={'Total de Views'}
                reverse={reverse}
                data={
                    data?.countOfTalkViews
                        ? renderBaseK(data.countOfTalkViews)
                        : 0
                }
                align={align}
                full={full}
            />
        </>
    )
}

export default TotalsViews
