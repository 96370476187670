import React, { useEffect, useRef, useState } from 'react'

import { MoreIcon } from '@cms/core/assets/icons/more'

import { menuSettings } from './settings'
import { SidebarItemMain, StyledFooterMenu, StyledMainMenu } from './styles'

const MainMenu = ({ main, footer, openKeys }) => {
    const menuRef = useRef()
    const [mainMenu, setMainMenu] = useState(main)

    useEffect(() => {
        if (menuRef?.current) {
            const itemsCount = main?.length
            const itemMenu = menuSettings.itemHeight
            const menuHeightOffset = menuRef?.current?.list?.offsetHeight
            const itemsToShow = Math.floor(menuHeightOffset / itemMenu)

            if (itemsCount > itemsToShow) {
                const _main = [...main]
                const newMenu = _main.slice(0, itemsToShow - 1)
                const rest = _main.slice(itemsToShow - 1, itemsCount)

                newMenu.push({
                    key: 'more',
                    label: '',
                    icon: <MoreIcon />,
                    popupClassName: 'more-menu',
                    children: rest.map((item) => ({
                        ...item
                    }))
                })

                setMainMenu(newMenu)
            } else {
                setMainMenu(main)
            }
        }
    }, [main])

    return (
        <SidebarItemMain $width={menuSettings.itemWidth}>
            <StyledMainMenu
                inlineCollapsed={true}
                ref={menuRef}
                style={{ overflow: 'hidden' }}
                selectedKeys={openKeys}
                mode="inline"
                items={mainMenu}
                theme={'dark'}
            />
            {footer?.length ? (
                <StyledFooterMenu
                    selectedKeys={openKeys}
                    mode="inline"
                    inlineCollapsed={true}
                    items={footer}
                    theme={'dark'}
                />
            ) : null}
        </SidebarItemMain>
    )
}

export default MainMenu

