import styled from 'styled-components'
import { rgba } from 'polished'

export const OverlayStyled = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    visibility: hidden;
    opacity: 0;
    background: linear-gradient(250.11deg, rgba(69, 24, 219, 0.8) -7.22%, rgba(123, 41, 255, 0.8) 103.84%);
    backdrop-filter: blur(10px);
    transition: visibility 0.2s, opacity 0.5s linear;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-space{
        visibility: hidden;
        opacity: 0;
        transition: visibility 0.3s, opacity 0.6s linear;
    }
`

export const Box = styled.div`
    background: linear-gradient(211.89deg, rgba(88, 93, 98, 0.3) 0.73%, rgba(88, 93, 98, 0.05) 120.33%);
    border-radius: 20px;
    /* padding: 80px 60px; */
    text-align: center;
    cursor: pointer;
    position: absolute;
    box-shadow: 0px 0px 3px #ffffff57;
    padding-bottom: 0%;

    img {
        width: 100%;
        border-radius: 20px;
    }

    &:hover {
        ${OverlayStyled}{
            visibility: visible;
            opacity: 1;
        }
        .ant-space{
            visibility: visible;
            opacity: 1;
        }
    }
`

export const Wrapper = styled.div`
    display: contents;
    position: relative;
`