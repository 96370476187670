import React from 'react'
import { Col } from 'antd'
import FieldSelect from './FieldSelect'
import OrderBySelect from './OrderBySelect'
import { ISelectOrderByField } from './types'

const SelectOrderBy = ({ field, type, children }: ISelectOrderByField) => {
    return (
        <>
            <Col span={6}>
                <FieldSelect field={field} type={type} />
            </Col>
            <Col span={6}>
                <OrderBySelect field={field} type={type} />
            </Col>
            {children}
        </>
    )
}

export default SelectOrderBy

