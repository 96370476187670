import React from 'react'

import { Typography } from 'antd'

import { CardWrapper, CardLogo } from './styles'

const { Paragraph, Title } = Typography

import * as _connectors from '../connectores/configs'

const SelectType = ({ children, type, ...props }) => {
    return (
        <CardWrapper selectType {...props}>
            <CardLogo src={_connectors[type].logo} />
            <Title level={5}>{_connectors[type].title}</Title>
            <Paragraph>{_connectors[type].description}</Paragraph>
        </CardWrapper>
    )
}

export default SelectType
