import styled from 'styled-components'
import { Typography } from 'antd'

const { Title: AntTitle, Text: AntText, Paragraph } = Typography

export const Title = styled(AntTitle)`
    &.ant-typography {
        font-size: 16px;
        line-height: 1.3;
        margin-top: 0;
        margin-bottom: 0;
        word-break: normal;
    }
`

export const Counter = styled.span`
    font-size: ${(props) => (props.size ? `${props.size}px` : '36px')};
    font-weight: 600;
`

export const Info = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`

export const InfoText = styled(Typography.Text)`
    display: block;
    font-size: 14px;
    word-break: break-word;
    line-height: 16px;
    margin-top: 2px;
    margin-bottom: 4px;
`

export const AvatarSvg = styled.div`
    padding: 10px 12px;
    border-radius: 100%;
    position: relative;
    background: #000;
    height: 43px;
    width: 43px;
    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        background-image: url(${(props) => props.url});
        background-origin: content-box;
        background-repeat: no-repeat;
        background-size: 90%;
        background-position: center;
        height: 100%;
        width: 100%;
        filter: brightness(0) invert(1);
    }
`
