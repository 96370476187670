import React from 'react'

import { Row } from 'antd'
import { useQuery } from '@apollo/client'

import { GET_BRAND_DOWNLOADS } from '@cms/events/graphql/queries/brand'

import Card from '../CardInnerMetrics'

import { FolderAddFilled } from '@ant-design/icons'
import { TDDashboardCard } from '@cms/core/components/TDCards'

type Props = {
    eventId?: any
    full?: any
    align?: any
}

const ResourcesDownloads = ({ eventId, full, align }: Props) => {
    const { loading, error, data } = useQuery(GET_BRAND_DOWNLOADS, {
        variables: { eventId }
    })

    if (loading || error) return null

    const total = data.brandDownloads
        .map((m) => m.total)
        .reduce((prev, current) => prev + current, 0)

    return (
        <TDDashboardCard backgroundColor="#2FCC93">
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Row align="middle">
                    <FolderAddFilled
                        style={{ color: '#fff', fontSize: '1.2em' }}
                    />
                </Row>
                <Row align="bottom">
                    <Card
                        title={'Materiais Baixados'}
                        data={total || 0}
                        align={align}
                        full={full}
                        reverse
                    />
                </Row>
            </div>
        </TDDashboardCard>
    )
}

export default ResourcesDownloads
