import React from 'react'

import Segment from '@cms/core/components/Segment'
import BaseConfig from '../BaseConfig'
// import { CONTENTS_CONFIGS } from '../../Segment/constants/contents'

interface IContentByCategoryProps {
    name: string
}

export function ContentByCategory({ name }: IContentByCategoryProps) {
    return (
        <>
            <BaseConfig name={name} title={true} />
            {/* <Segment
                name={['sections', name, 'config', 'condition']}
                type="content"
                filters={CONTENTS_CONFIGS}
            /> */}
        </>
    )
}
