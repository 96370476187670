import React from 'react'
import { CheckCircleFilled } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import QuizCard from '@cms/events/components/QuizCard'
import {
    DELETE_QUIZ,
    FINISH_QUIZ,
    START_QUIZ
} from '@cms/events/graphql/mutations/quiz'
import { GET_QUIZZES } from '@cms/events/graphql/queries/quiz'
import { Button, Col, message, Row } from 'antd'
import { Link } from 'react-router-dom'
import { Wrapper } from './styles'

const ModalContent = ({ talkId, eventId, path }) => {
    const { data, refetch } = useQuery(GET_QUIZZES, {
        variables: {
            talkId: talkId
        }
    })
    const [deleteQuiz] = useMutation(DELETE_QUIZ)
    const [startQuiz] = useMutation(START_QUIZ)
    const [finishQuiz] = useMutation(FINISH_QUIZ)

    const handleDelete = async (id) => {
        await deleteQuiz({ variables: { id } }), refetch()
        message.success('Enquete deletada com sucesso!')
    }
    const handlePlayQuiz = async (id) => {
        await startQuiz({ variables: { id } }), refetch()
        message.success('Enquete iniciada com sucesso!')
    }
    const handleFinishQuiz = async (id) => {
        await finishQuiz({ variables: { id } }), refetch()
        message.success('Enquete finalizada com sucesso!')
    }

    if (!data) return null

    return (
        <Wrapper>
            <Col>
                {data.quizzes.edges.map((quiz) => (
                    <QuizCard
                        quiz={quiz}
                        path={path}
                        refetch={refetch}
                        handleDelete={handleDelete}
                        handlePlayQuiz={handlePlayQuiz}
                        handleFinishQuiz={handleFinishQuiz}
                    />
                ))}
            </Col>
        </Wrapper>
    )
}

export default ModalContent
