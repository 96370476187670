import React from 'react'
import { Form, Tabs } from 'antd'

import Colors from '@cms/core/components/Colors'
import { Wrapper } from '@cms/core/components/Theme/styles'

interface Props {
    name: string[] | string
}
const ButtonTheme = ({ name = ['button'] }: Props) => {
    return (
        <Tabs>
            <Tabs.TabPane tab="Normal" key="1">
                <Wrapper>
                    <Form.Item
                        label="Cor do fundo"
                        name={[...name, 'bgColor']}
                        initialValue={'#500d6d'}
                    >
                        <Colors formItem />
                    </Form.Item>
                </Wrapper>

                <Wrapper>
                    <Form.Item
                        label="Cor do texto"
                        name={[...name, 'textColor']}
                        initialValue={'#a81be4'}
                    >
                        <Colors formItem gradient={false} />
                    </Form.Item>
                </Wrapper>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Hover" key="2">
                <Wrapper>
                    <Form.Item
                        label="Cor do fundo"
                        name={[...name, 'hover', 'bgColor']}
                        initialValue={'#500d6d'}
                    >
                        <Colors formItem />
                    </Form.Item>
                </Wrapper>

                <Wrapper>
                    <Form.Item
                        label="Cor do texto"
                        name={[...name, 'hover', 'textColor']}
                        initialValue={'#a81be4'}
                    >
                        <Colors formItem gradient={false} />
                    </Form.Item>
                </Wrapper>
            </Tabs.TabPane>
        </Tabs>
    )
}

export default ButtonTheme

