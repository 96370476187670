import Address from './Address'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Addresses = {
    Address: {
        Component: Address,
        name: 'Endereço',
        category: 'Endereço',
        columns: '2',
        thumb: require('./address.png'),
        create: async (site, event) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                titles: {
                    textColor: getColorContrast(colors, 'primary')
                },
                text: {
                    textColor: getColorContrast(colors, 'primary')
                },
                title: {
                    text: 'Onde vai ser?'
                },
                name: {
                    text: 'Hotel Unique São Paulo'
                },
                address: {
                    text: `${event.address ? event.address : ''}`
                },
                contact: {
                    text: '(11) 99999-9999'
                }
            }
        }
    }
}
