import React from 'react'
import { Form, Typography } from 'antd'

import Sections from '../../../sections'
import Section from './section'

import { get, intersection } from 'lodash'

const { Title } = Typography

const List = (props) => {
    const { activePanel, setSection, form, main } = props
    const mainTypes = ['Header', 'Footer']

    const sections = get(form.getFieldsValue(), 'sections', []).map(
        (section) => section.type
    )

    if (main && !intersection(mainTypes, sections).length) {
        return null
    }

    return (
        <>
            <Title level={3}>{main ? 'Cabeçalho/Rodapé' : 'Seções'}</Title>
            <Form.List name="sections">
                {(fields, { move }) => {
                    if (fields.length === 0) return null
                    return (
                        <>
                            {fields.map((field, key) => {
                                const _fields = form.getFieldsValue()
                                const { type, active } = _fields.sections[key]

                                if (
                                    (!main && mainTypes.includes(type)) ||
                                    (main && !mainTypes.includes(type))
                                )
                                    return null

                                const Component = Sections[type]
                                const extraProps = {
                                    index: key,
                                    section: type,
                                    checked: active,
                                    activePanel,
                                    setSection,
                                    form,
                                    move
                                }

                                return (
                                    <Section key={key} {...extraProps}>
                                        <Component
                                            form={form}
                                            type={type}
                                            {...field}
                                        />
                                    </Section>
                                )
                            })}
                        </>
                    )
                }}
            </Form.List>
        </>
    )
}

export default List
