import React from 'react'
import { Form, Input, Button, Collapse } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../components'
import { Wrapper, Title, TitleWrapper } from '../../styles'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import { nanoid } from 'nanoid'
const { Panel } = Collapse
const Topic3 = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Tópicos</Title>
                <Form.List name={[props.name, 'configs', 'topics', 'items']}>
                    {(fields, { add, remove }) => (
                        <>
                            <Collapse>
                                {fields.map(
                                    ({ key, name, ...restField }, i) => (
                                        <Panel
                                            header={i + 1 + 'º Topico'}
                                            key={key}
                                            extra={
                                                <DeleteOutlined
                                                    onClick={() => remove(name)}
                                                />
                                            }
                                        >
                                            <Form.Item
                                                name={[name, 'icon', 'src']}
                                                label="Imagem do tópico"
                                            >
                                                <Uploader />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[name, 'title', 'text']}
                                                label="Título"
                                            >
                                                <RichTextEditor placeholder="Titulo" />
                                            </Form.Item>
                                            <Form.Item
                                                {...restField}
                                                name={[
                                                    name,
                                                    'subtitle',
                                                    'text'
                                                ]}
                                                label="Subtítulo"
                                            >
                                                <RichTextEditor />
                                            </Form.Item>

                                            <ButtonConfig
                                                enableActions
                                                allowVisibilityControl
                                                fieldPath={[name, 'button']}
                                                rootPath={[
                                                    'sections',
                                                    props.name,
                                                    'configs',
                                                    'topics',
                                                    'items'
                                                ]}
                                                {...props}
                                            />
                                        </Panel>
                                    )
                                )}
                            </Collapse>
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() =>
                                        add({
                                            id: nanoid(),
                                            button: {
                                                active: false
                                            }
                                        })
                                    }
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    Adicionar Tópico
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item
                    name={[props.name, 'configs', 'topics', 'bgColor']}
                    label="Cor do bloco"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default Topic3
