import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_BRAND_PLAN = gql`
    mutation CreateBrandPlan(
        $hostType: TalkHostType
        $name: String
        $sponsorPage: Boolean
        $sponsorLink: Boolean
        $type: String
        $priority: Int
        $assets: [BrandPlanAssetInput]
    ) {
        createBrandPlan(
            input: {
                name: $name
                sponsorPage: $sponsorPage
                sponsorLink: $sponsorLink
                type: $type
                priority: $priority
                hostType: $hostType
                assets: $assets
            }
        ) {
            ...BrandPlanData
        }
    }

    ${fragments.BrandPlanData}
`

export const UPDATE_BRAND_PLAN = gql`
    mutation UpdateBrandPlan(
        $id: ObjectID!
        $hostType: TalkHostType
        $name: String
        $sponsorPage: Boolean
        $sponsorLink: Boolean
        $type: String
        $priority: Int
        $assets: [BrandPlanAssetInput]
    ) {
        updateBrandPlan(
            id: $id
            input: {
                name: $name
                sponsorPage: $sponsorPage
                sponsorLink: $sponsorLink
                type: $type
                priority: $priority
                hostType: $hostType
                assets: $assets
            }
        ) {
            ...BrandPlanData
        }
    }

    ${fragments.BrandPlanData}
`

export const DELETE_BRAND_PLAN = gql`
    mutation DeleteBrandPlan($id: ObjectID!) {
        deleteBrandPlan(id: $id) {
            id
            success
        }
    }
`
