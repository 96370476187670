import React from 'react'
import { SidebarItemContent, RootMenu } from './styles'

const SecondMenu = ({ menu, openKeys, firstItemSelected }) => {
    if (!menu?.length) {
        return null
    }

    return (
        <SidebarItemContent
            className={firstItemSelected ? 'first-item-selected' : ''}
        >
            <RootMenu
                className="second-menu"
                mode="inline"
                selectedKeys={openKeys}
                items={menu}
                theme={'light'}
            />
        </SidebarItemContent>
    )
}

export default SecondMenu

