import React, { useState } from 'react'
import { Button, message, Progress, Typography } from 'antd'
import Status from '@cms/events/components/Status'
import { useMutation, useQuery } from '@apollo/client'
import { GET_QUIZ } from '@cms/events/graphql/queries/quiz'
import { Wrapper } from './styles'
import { useHistory } from 'react-router-dom'
import { FINISH_QUIZ } from '@cms/events/graphql/mutations/quiz'

const { Text } = Typography

const ModalContent = ({ surveyId }) => {
    const history = useHistory()
    const { data, refetch } = useQuery(GET_QUIZ, {
        variables: {
            id: surveyId
        }
    })
    const [finishQuiz] = useMutation(FINISH_QUIZ)
    const handleFinishQuiz = async (id) => {
        await finishQuiz({ variables: { id } }), refetch()
        message.success('Enquete finalizada com sucesso!')
    }
    const getTotal = (quiz) => {
        const quantitys = quiz.stats.map((stat) => {
            return stat.quantity
        })
        if (!quantitys) return null
        let total = quantitys.reduce((sum, i) => {
            return sum + i
        })
        return total
    }
    if (!data) return null
    return (
        <Wrapper>
            <Status status={data.quiz.status} />
            <h2>{data.quiz.question}</h2>
            <Text style={{ display: 'block' }} className="answers">
                Respostas
            </Text>
            {data.quiz.stats.map((stat) => (
                <>
                    <Text>{stat.answer}</Text>
                    <Progress
                        percent={(100 * stat.quantity) / getTotal(data.quiz)}
                        status="active"
                    />
                </>
            ))}
            <Button
                onClick={() => history.goBack()}
                className="button-footer"
                style={{ marginLeft: 0 }}
            >
                Voltar
            </Button>
            {data.quiz.status === 'STARTED' && (
                <Button
                    className="button-footer"
                    onClick={() => handleFinishQuiz(data.quiz.id)}
                >
                    Encerrar Enquete
                </Button>
            )}
        </Wrapper>
    )
}

export default ModalContent
