import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_DAILY_CONVERSION = gql`
    query DailyConversions( $eventId: ObjectID! ){
        dailyConversions( filter: {event: $eventId }){
            pageInfo {
                hasNextPage
                endCursor
            }
            edges{
                node{
                    date
                    count
                    rate

                } 
            }
        }
    }
`

export const GET_COUNT_REGISTER_BY_REFERAL = gql`
    query CountRegisterByReferal( $eventId: ObjectID! ){
    countOfUserRegisteredByReferral( event: $eventId )
    }
`

export const GET_COUNT_ALL_REGISTER = gql`
    query CountOfAllRegisteredUsers( $eventId: ObjectID! ){
        countOfAllRegisteredUsers( event: $eventId )
    }
`

export const GET_COUNT_ALL_REGISTER_REFERAL = gql`
    query CountOfUserRegisteredByReferral( $eventId: ObjectID! ){
        countOfUserRegisteredByReferral( event: $eventId )
    }
`

export const GET_COUNT_UNREGISTER_REFERAL = gql`
    query CountOfUnregisteredUserByReferral( $eventId: ObjectID! ){
        countOfUnregisteredUserByReferral( event: $eventId )
    }
`

export const GET_COUNT_REGISTER_SOCIAL = gql`
    query CountOfUserRegisteredBySocial( $eventId: ObjectID! ){
        countOfUserRegisteredBySocial( event: $eventId ){
            facebook
            whatsapp
            twitter
            linkedin
            email
            copy
        }
    }
`