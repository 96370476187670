import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

import { EditorWrapper } from './styles'

const EditorHTML = ({ onChange, value, toolbar, ...rest }) => {
    const handleEditorChange = (content, editor) => {
        onChange(content)
    }
    return (
        <EditorWrapper>
            <Editor
                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5.5.1/tinymce.min.js"
                disabled={false}
                id="uuid"
                init={{
                    menubar: false,
                    height: '400px',
                    plugins: 'link code image lists emoticons table',
                    toolbar:
                        'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | link | code | image | emoticons | table',
                    extended_valid_elements:
                        'p,svg[*],path[*],a[href|target=_blank],strong/b,div[align],br,img[class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name]'
                }}
                inline={false}
                onEditorChange={handleEditorChange}
                tagName="div"
                textareaName=""
                value={value}
                {...rest}
            />
        </EditorWrapper>
    )
}

export default EditorHTML

