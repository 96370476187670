import * as fragments from '../../apps/events/graphql/fragments'

export const addToList = ({ Type, list, Datakey = '', firstItem = true }) => {
    return (cache, { data }) => {
        const result = data[Datakey ? Datakey : 'create' + Type]
        cache.modify({
            fields: {
                [list](connection) {
                    const node = cache.writeFragment({
                        data: result,
                        fragment: fragments[Type + 'Data'],
                        fragmentName: Type + 'Data'
                    })

                    const edge = {
                        node,
                        __typename: Type + 'Edge'
                    }

                    const edges = [...connection.edges]

                    if (firstItem) {
                        edges.push(edge)
                    } else {
                        edges.unshift(edge)
                    }

                    return {
                        ...connection,
                        edges
                    }
                }
            }
        })
    }
}

export const removeFromList = ({ Type, list, Datakey = '' }) => {
    return (cache, { data }) => {
        const { id } = data[Datakey ? Datakey : 'delete' + Type]
        cache.modify({
            fields: {
                [list](connection) {
                    return {
                        ...connection,
                        edges: [
                            ...connection.edges.filter(({ node }) => {
                                return node.__ref !== `${Type}:${id}`
                            })
                        ]
                    }
                }
            }
        })
    }
}
