import { COLORS } from '@cms/core/themes'
import { rgba } from 'polished'
import styled from 'styled-components'

function hexToRgb(hex: string, alpha = 1): string | null {
    hex = rgba(hex, 1)

    const [r, g, b, a] = hex.match(/\d+/g) || []

    const color = alpha ? `${r}, ${g}, ${b}, ${alpha}` : `${r}, ${g}, ${b}`

    return color
}

export const EmojiPickerContainer = styled.div``

export const EmojiPickerModal = styled.div`
    width: 100%;
    left: 2%;
    bottom: 70px;
    position: absolute;
    border-radius: 10px;
    padding: 10px;

    section {
        border: 1px solid ${COLORS.text};
    }

    em-emoji-picker {
        width: 98%;
        box-shadow: rgb(255 255 255 / 23%) 0px 0px 1px 2px;

        --rgb-background: ${hexToRgb(COLORS.bgLight)};
        --rgb-color: ${hexToRgb(COLORS.text)};
        --rgb-accent: ${hexToRgb(COLORS.primary)};
    }
`

