import styled from 'styled-components'
import { Col } from 'antd'
import { Wrapper as DefaultWrapper } from '../styles'
import { Uploader } from '../../components'
import { COLORS } from '@cms/core/themes'

export const ColForm = styled(Col)`
    background-color: ${COLORS.bgLight};
    padding: 16px;
    border-radius: 20px;

    .list-label {
        font-family: HelveticaNowRegular, sans-serif;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
    }

    .affix-form-footer {
        margin-left: -12px;
        margin-right: -12px;
    }

    .favUploader {
        .avatar-uploader {
            width: 224px !important;

            .ant-upload {
                height: 126px !important;
            }
        }
    }
`

export const Wrapper = styled(DefaultWrapper)`
    margin-bottom: 20px;
    background-color: transparent;
    border: 2px solid rgba(228, 236, 255, 0.1);
`
