import React from 'react'
import { Col, Input, Row } from 'antd'

const AdressPreview = ({ config }) => {
    return (
        <Row gutter={[24, 24]}>
            <Col span={8}>
                <Input type={'text'} placeholder="____-___" disabled />
            </Col>
            <Col span={16}>
                <Input type={'text'} placeholder="Rua/Avenida" disabled />
            </Col>
            <Col span={4}>
                <Input type={'text'} placeholder="Nº" disabled />
            </Col>
            <Col span={20}>
                <Input type={'text'} placeholder="Bairro" disabled />
            </Col>
            <Col span={12}>
                <Input type={'text'} placeholder="Estado" disabled />
            </Col>
            <Col span={12}>
                <Input type={'text'} placeholder="Cidade" disabled />
            </Col>
            <Col span={24}>
                <Input type={'text'} placeholder="Complemento" disabled />
            </Col>
        </Row>
    )
}

export default AdressPreview
