import React from 'react'
import { message, Form, Input, Select } from 'antd'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'

import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_NOTIFICATION } from '@cms/events/graphql/queries/notification'
import {
    CREATE_NOTIFICATION,
    UPDATE_NOTIFICATION
} from '@cms/events/graphql/mutations/notification'
import IntegrationInput from '@cms/core/components/Integrations/components/Input'

const { Option } = Select

const NotificationModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const response = await client.query({
                query: GET_NOTIFICATION,
                variables: { id }
            })

            const data = { ...response.data.notification }
            data.integrations = data.integrations.map((i) => i.id)

            return data
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_NOTIFICATION,
                update: addToList({
                    list: 'notifications',
                    Type: 'Notification'
                }),
                variables: { ...formData, event: eventId }
            })

            message.success('Notificação criada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_NOTIFICATION,
                variables: { id, ...formData, event: eventId }
            })

            message.success('Notificação atualizada com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar Notificação' : 'Nova Notificação'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Título"
                    name="title"
                    rules={[
                        { required: true, message: 'Título é obrigatório!' }
                    ]}
                    {...getError('title')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Icone"
                    name="icon"
                    rules={[
                        { required: true, message: 'Icone é obrigatório!' }
                    ]}
                    {...getError('avatar')}
                >
                    <MediaUploader
                        options={{
                            optimize: {
                                resize: {
                                    width: 310,
                                    height: 210
                                },
                                quality: 80
                            }
                        }}
                        type="image"
                    />
                </Form.Item>

                <Form.Item
                    label="Mensagem"
                    name="message"
                    {...getError('message')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Link"
                    name="link"
                    rules={[{ required: true, message: 'Link é obrigatório!' }]}
                    {...getError('link')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Texto do Botão"
                    name="button"
                    {...getError('button')}
                >
                    <Input placeholder="Saiba mais" />
                </Form.Item>

                <IntegrationInput eventId={eventId} />
            </Form>
        </CrudModal>
    )
}

export default NotificationModal
