import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import SpeakerCarousel from './SpeakerCarousel'

export const SpeakersCarousel = {
    SpeakerCarousel: {
        Component: SpeakerCarousel,
        name: 'Carrosel de palestrantes',
        category: 'Palestrantes',
        thumb: require('./speakersCarousel.png'),
        columns: '1',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                smallText: {
                    text: '<div class="td-align-center">Palestrantes</div>'
                },
                headline: {
                    text: '<span><div class="td-align-center">Os maiores</div><div class="td-align-center">players</div></span>'
                },
                subtitle: {
                    text: '<span><div class="td-align-center">do marketing estão aqui</div></span>'
                },
                overlay: {
                    textColor: 'black'
                },
                speaker: {
                    text: {
                        textColor: 'white'
                    },
                    company: {
                        color: 'white'
                    }
                },
                office: {
                    text: {
                        textColor: 'highlight'
                    }
                },
                navigation: {
                    type: 'both',
                    textColor: 'white'
                }
            }
        }
    }
}
