import FeaturedSpeakers from './FeaturedSpeakers'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const FeaturedSpeakersSections = {
    FeaturedSpeakers: {
        Component: FeaturedSpeakers,
        name: 'Palestrantes Destaque',
        category: 'Palestrantes',
        thumb: require('./FeaturedSpeakers.jpg'),
        columns: '1',
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'white',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Conheça os palestrantes',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\ne do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                },
                cards: {
                    text: {
                        textColor: getColorContrast(colors, 'primary')
                    },
                    logo: {
                        color: 'black'
                    },
                    icon: {
                        color: 'default'
                    }
                }
            }
        }
    }
}
