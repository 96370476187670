import { CrownOutlined, UserOutlined } from '@ant-design/icons'
import CustomEmpty from '@cms/core/components/Empty'
import { Avatar, Image, Row, Typography } from 'antd'
import React from 'react'
import {
    Wrapper,
    IconWrapper,
    Speaker,
    WrapperSpeakers,
    SpeakerImg,
    TextWrapper
} from './styles'
const { Text } = Typography

const Speakers = ({ speakers }) => {
    const handleRenderSpeaker = (speaker) => {
        return (
            <Speaker key={speaker.node.id}>
                {speaker?.node?.image?.url ? (
                    <SpeakerImg src={speaker.node.image.url} />
                ) : (
                    <Avatar>
                        <UserOutlined />
                    </Avatar>
                )}
                <TextWrapper>
                    <h4>{speaker.node.name}</h4>
                    <p>{speaker.node.office}</p>
                    <span>{speaker.node.company.name}</span>
                </TextWrapper>
            </Speaker>
        )
    }
    return (
        <Wrapper>
            <Row style={{ marginBottom: '15px' }} align="middle">
                <IconWrapper>
                    <CrownOutlined style={{ color: '#000' }} />
                </IconWrapper>
                <Text style={{ marginLeft: '10px' }}>Palestrantes</Text>
            </Row>
            {speakers.length >= 1 ? (
                <WrapperSpeakers>
                    {speakers.map((speaker) => handleRenderSpeaker(speaker))}
                </WrapperSpeakers>
            ) : (
                <CustomEmpty />
            )}
        </Wrapper>
    )
}

export default Speakers
