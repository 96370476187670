import React, { useEffect, useCallback, useState, useMemo } from 'react'

import { PageHeader } from '@ant-design/pro-layout'
import { Affix } from 'antd'
import { merge } from 'lodash'
import { Form, Col, Row } from 'antd'

import ThemePreview from './Preview'
import { useThemeBuilderContext } from '../../context'

import { SectionsAccordion } from './accordion'
import { SECTIONS_DEFAULT } from './constants'
import { CrudForm } from '@cms/core/components/Crud'

export const ThemeBuilderConfigTheme = () => {
    const [form] = Form.useForm()
    const [active, setActive] = useState<string | string[]>(['general'])
    const {
        theme,
        setTheme,
        sections: _sections,
        getSectionConfig,
        previewUrl
    } = useThemeBuilderContext()

    const currentTheme = useMemo(() => {
        const section = getSectionConfig('Theme')
        return {
            title: 'Cores da transmissão',
            subTitle: 'Configuração de cores da página de transmissão',
            sections: SECTIONS_DEFAULT,
            ...section
        }
    }, [_sections])

    useEffect(() => {
        if (theme) {
            form.setFieldsValue(theme)
        }
    }, [theme])

    const updateTheme = useCallback(
        (values) => {
            setTheme((o) => merge({}, { ...o }, { ...values }))
        },
        [setTheme]
    )

    if (!currentTheme.sections.length) return null

    const { sections, subTitle, title } = currentTheme

    return (
        <>
            <PageHeader
                className="site-page-header"
                title={title}
                subTitle={subTitle}
            />
            <CrudForm
                layout="vertical"
                form={form}
                onValuesChange={updateTheme}
                hideRequiredMark
            >
                <Row gutter={16}>
                    <Col span={10}>
                        <SectionsAccordion
                            sections={sections}
                            setActive={setActive}
                            active={active}
                        />
                    </Col>

                    <Col span={14}>
                        {previewUrl ? (
                            <Affix offsetTop={100}>
                                <ThemePreview
                                    theme={theme || {}}
                                    previewUrl={previewUrl}
                                />
                            </Affix>
                        ) : null}
                    </Col>
                </Row>
            </CrudForm>
        </>
    )
}

export default ThemeBuilderConfigTheme

