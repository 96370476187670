import { useApolloClient, useQuery } from '@apollo/client'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'
import {
    ADD_TICKET_TO_USER,
    TRANSFER_TICKET_OWNERSHIP
} from '@cms/events/graphql/mutations/tickets'
import {
    GET_TICKETS_LIST,
    GET_TICKET_OWNERSHIPS_LIST
} from '@cms/events/graphql/queries/tickets'
import { useHistory, useParams } from 'react-router-dom'
import { Form, Input, message } from 'antd'
import React from 'react'
import { cnpjOrCpfMask, phoneMask } from '../../utils/masks'

const TransferTicket = ({ params, ...props }) => {
    const { eventId } = params
    const { userId, ticketOwnershipId } = useParams()
    const history = useHistory()
    const client = useApolloClient()

    const [{ form, getError }, modalProps] = useCrudForm(
        {
            id: ticketOwnershipId,
            updateData: async (id, formData) => {
                await client.mutate({
                    mutation: TRANSFER_TICKET_OWNERSHIP,
                    variables: {
                        id,
                        userData: { ...formData }
                    },
                    refetchQueries: [
                        {
                            query: GET_TICKET_OWNERSHIPS_LIST,
                            variables: { eventId: eventId, userId: userId }
                        }
                    ]
                })

                message.success('Ingresso transferido com sucesso!')
            }
        },
        [ticketOwnershipId, eventId, userId]
    )

    return (
        <CrudModal
            title={'Transferir ingresso'}
            {...modalProps}
            {...props}
            path={history.location.pathname.split('/transfer')[0]}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Name"
                    name={['name']}
                    rules={[{ required: true, message: 'Nome é obrigatório' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Email"
                    name={['email']}
                    rules={[
                        { required: true, message: 'E-mail é obrigatório' }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="CPF/CNPJ"
                    name={['document']}
                    rules={[
                        { required: true, message: 'CPF/CNPJ é obrigatório' }
                    ]}
                    mask={cnpjOrCpfMask}
                >
                    <Input
                        onChange={(e) => {
                            const { value } = e.target
                            const maskedValue = cnpjOrCpfMask(value)
                            form.setFieldsValue({ document: maskedValue })
                        }}
                    />
                </Form.Item>

                <Form.Item
                    label="Phone"
                    name={['phone']}
                    rules={[
                        { required: true, message: 'Telefone é obrigatório' }
                    ]}
                >
                    <Input
                        onChange={(e) => {
                            const { value } = e.target
                            const maskedValue = phoneMask(value)
                            form.setFieldsValue({ phone: maskedValue })
                        }}
                    />
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default TransferTicket
