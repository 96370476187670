import styled from 'styled-components'
import { Row, Button as AntButton } from 'antd'

export const Footer = styled(Row)`
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: -16px;
    padding: 10px 20px;
    background-color: ${({ theme }) => theme.color.backgroundDarkDark};
    border-top: 1px solid ${({ theme }) => theme.color.backgroundDarkLight};
`

export const Button = styled(AntButton)`

`

export const Wrapper = styled.div`
    display: contents;

    .ant-affix {
        .ant-row {
            background-color: ${({ theme }) => theme.color.backgroundDarkHeader};
        }
    }
`