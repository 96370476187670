import styled from 'styled-components'

import { Upload as UploadAnt } from 'antd'

export const Upload = styled(UploadAnt)`
    .ant-upload-select-picture-card {
        margin: 0;
        width: 100%;

        .ant-upload {
            /* padding: 8px; */
        }

        .anticon-check {
            svg {
                fill: #2bd069;
            }
        }

        .anticon-delete {
            &:hover {
                svg {
                    fill: ${({ theme }) => theme.color.activeColors};
                }
            }
        }
    }

    .banner {
        height: 100%;
        max-width: 100%;
        border-radius: 6px;
        object-fit: contain;
    }

    .ant-upload {
        height: 200px;
    }

    .delete-button {
        position: absolute;
        bottom: 20px;
    }
`
