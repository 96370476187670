import React from 'react'
import { Collapse } from 'antd'
import PanelHeader from '../PanelHeader'
import { StyledCollapse } from '../../styles'

const { Panel } = Collapse

const Section = (props) => {
    const { name, form } = props

    return (
        <StyledCollapse collapsible="disabled">
            <Panel
                id={`section-${name}`}
                header={<PanelHeader form={form} {...props} />}
                key={name}
                showArrow={false}
            />
        </StyledCollapse>
    )
}

export default Section
