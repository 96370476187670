import { Collapse as AntCollapse } from 'antd'
import styled from 'styled-components'

export const Collapse = styled(AntCollapse)`
    .ant-collapse-content-box {
        border-radius: 0px 0px 10px 10px;
    }

    .ant-collapse-header {
        cursor: default;

        &:hover {
            cursor: default;
        }
    }
`

export const PanelBodyWrapper = styled.div`
    > div {
        padding: 15px;

        &.ant-collapse {
            padding: 0px;
        }
        > h4 {
            padding: 0px;
        }
    }
`
