import styled from 'styled-components'

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: row;
    justify-content: start;
    flex-direction: row;
`
export const FilterBar = styled.div`
    width: 256px;
    padding-right: 12px;
    border-right: 1px solid #303030;

    .checkbox-filters {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-top: 10px;

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 20px;
        }
    }

    .ant-checkbox-group-item {
        width: 100%;
    }

    .ant-menu-item-group-title {
        padding-left: 0px;
    }
`
