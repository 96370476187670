import React, { FC, useCallback, useRef } from 'react'

import { Form, message } from 'antd'

import TDSteps from '@cms/core/components/TDSteps'
import { useHistory, useParams } from 'react-router-dom'
import { TDCard } from '@cms/core/components/TDCards'
import { TDStepItem } from '@cms/core/components/TDSteps/type'
import { useCrudForm } from '@cms/core/components/Crud'
import { useApolloClient } from '@apollo/client'
import { GET_WATCH_COURSE } from '@cms/watch/graphql/queries/watchCourse'
import {
    CREATE_WATCH_COURSE,
    UPDATE_WATCH_COURSE
} from '@cms/watch/graphql/mutations/watchCourse'
import StepInformation from './informations/stepInformations'
import { PageHeader } from '@ant-design/pro-layout'
import Loader from '@cms/core/components/Loader'
import StepModules from './modules/stepModules'

const steps: TDStepItem[] = [
    {
        title: 'Informações',
        allowFinish: true,
        icon: <span />,
        component: <StepInformation />
    },
    {
        title: 'Modulos',
        allowFinish: true,
        icon: <span />,
        component: <StepModules />
    }
    // {
    //     title: 'Certificado',
    //     icon: <span />,
    //     component: <>Em breve!</>
    // }
]

const formatData = (data: any) => {
    data = JSON.parse(JSON.stringify(data))

    // convert modules contents to array of ids

    if (data.modules) {
        data.modules = data.modules.map((module: any) => {
            if (module.contents) {
                module.contents = module.contents.map(
                    (content: any) => content.id
                )
            }

            return module
        })
    }

    return data
}

const CourseEditor = () => {
    const client = useApolloClient()
    const history = useHistory()
    const initialDataRef = useRef<any>({})
    const { courseId } = useParams<{ courseId: string }>()

    const [{ form, formSave, isEdit, initializated, formData }] = useCrudForm(
        {
            id: courseId,
            getData: async (id: string) => {
                if (!id) return

                const {
                    data: { watchCourse }
                } = await client.query({
                    query: GET_WATCH_COURSE,
                    variables: { id }
                })

                const data = {
                    ...watchCourse
                }

                initialDataRef.current = data

                return {
                    ...data
                }
            },
            createData: async (formData: any) => {
                initialDataRef.current = {
                    ...initialDataRef.current,
                    ...formData
                }

                const { data } = await client.mutate({
                    mutation: CREATE_WATCH_COURSE,
                    variables: formatData(initialDataRef.current)
                })

                history.replace(`/cursos/${data.createWatchCourse.id}/editar`)

                message.success('Curso cadastrado com sucesso!')
            },
            updateData: async (id: string, formData: any) => {
                initialDataRef.current = {
                    ...initialDataRef.current,
                    ...formData,
                    id
                }

                await client.mutate({
                    mutation: UPDATE_WATCH_COURSE,
                    variables: formatData(initialDataRef.current)
                })

                message.success('Curso atualizado com sucesso!')
            }
        },
        [courseId]
    )

    const onSave = useCallback(async () => {
        console.log('onSave')
        try {
            await form.validateFields()
            await formSave()
        } catch (error) {
            return false
        }
        return true
    }, [formSave])

    const onFinished = useCallback(async () => {
        if (await onSave()) {
            history.push('/cursos')
            return true
        }

        return false
    }, [onSave])

    return (
        <>
            <PageHeader
                onBack={() => history.push('/cursos')}
                title={isEdit ? 'Editar Curso' : 'Novo Curso'}
                subTitle={(formData as any)?.title}
            />
            <TDCard
                style={{
                    transform:
                        !initializated && 'translate(0px, 0px) !important'
                }}
            >
                {!initializated && <Loader />}
                <Form layout={'vertical'} form={form} onFinish={onSave}>
                    {initializated && (
                        <TDSteps
                            enableStepHash
                            steps={steps}
                            onNext={onSave}
                            onPrev={onSave}
                            onFinish={onFinished}
                        />
                    )}
                </Form>
            </TDCard>
        </>
    )
}

export default CourseEditor
