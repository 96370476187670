import styled from 'styled-components'
import { hsl } from 'polished'

export const ColorWrapper = styled.div`
    width: 100%;
    height: 60px;

    .ant-slider-rail {
        background-color: transparent !important;
    }
    .color-hue {
        .ant-slider-step {
            background: linear-gradient(
                to right,
                #ff0000 0%,
                #ffff00 17%,
                #00ff00 33%,
                #00ffff 50%,
                #0000ff 67%,
                #ff00ff 83%,
                #ff0000 100%
            );

            background-color: transparent;
        }
    }
    .color-saturation {
        .ant-slider-step {
            background-image: linear-gradient(
                    to right,
                    #494949 0%,
                    transparent 100%
                ),
                linear-gradient(to right, #fff 0%, transparent 0%);

            background-color: ${({ color }) =>
                hsl({ ...color, saturation: 1, lightness: 0.5 })};
        }
    }

    .color-lightness {
        .ant-slider-step {
            background-image: linear-gradient(
                    to right,
                    #000 0%,
                    transparent 100%
                ),
                linear-gradient(to right, #fff 0%, transparent 100%);

            background-color: #fff;
        }
    }
`

export const ColorBadge = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 10px;
    background-color: ${({ color }) => color};
`
