import { useMutation, useQuery } from '@apollo/client'
import { removeObjectProps } from '@cms/events/utils'
import { Col, Form, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import Loader from '@cms/core/components/Loader'
import { UPDATE_EVENT_FORM } from '@cms/events/graphql/mutations/form'
import { GET_EVENT_FORM } from '@cms/events/graphql/queries/form'
import { Nav } from '../components'
import { useFormBuilderContext } from '../context/context'
import { Container } from '../styles'
import { FormBuilderRoutes } from './routes'

const FormBuilder = () => {
    const { formId, eventId } = useParams()

    const path = `progressive/${formId}/editar`

    const { setFormData, selectField, formData } = useFormBuilderContext()

    const mutationOptions = {
        onError: (err) =>
            message.error(`Ocorreu um erro ao publicar o formulário: ${err}`),
        onCompleted: () => message.success('Formulário atualizado com sucesso!')
    }

    const [updateForm] = useMutation(UPDATE_EVENT_FORM, mutationOptions)

    const { data, loading } = useQuery(GET_EVENT_FORM, {
        variables: {
            formId
        },
        skip: !formId
    })

    const formUpdate = async (fields) => {
        await updateForm({
            variables: {
                event: eventId,
                ...fields
            }
        })
    }

    const onFormFinish = async (name, { values, forms }) => {
        let _formData = formData

        if (name !== 'form-nav') {
            const _currentForm = forms.preview
            const preview = _currentForm.getFieldsValue()

            if (name === 'preview') {
                _formData = { ..._formData, ...preview }
                setFormData({ ..._formData })
            }

            if (name.indexOf('edit') === -1) {
                preview[name]?.push(values)
                _currentForm.setFieldsValue(preview)
                setFormData({ ..._formData, [name]: [...preview[name]] })
            } else {
                const node = name.split('-edit')[0]

                preview[node][selectField.target] = values
                _currentForm.setFieldsValue(preview)

                setFormData({
                    ..._formData,
                    [node]: preview[node]
                })
            }
        } else {
            try {
                _formData = {
                    ..._formData,
                    config: {
                        ..._formData.config
                    }
                }
                formUpdate(_formData)
            } catch (e) {
                message.error(e.message)
            }
        }
    }

    useEffect(() => {
        if (data && data.eventForm) {
            const { eventForm } = data

            const _eventForm = removeObjectProps(eventForm, ['__typename'])

            _eventForm.integrations = _eventForm.integrations.map(
                (integration) => integration.id || integration
            )

            setFormData(_eventForm)
        }
    }, [data, setFormData])

    useEffect(() => {
        console.log('render formulário complementar')
    }, [])

    if (loading) {
        return <Loader fixed={false} padding='10px' />
    }

    return formData ? (
        <Form.Provider onFormFinish={onFormFinish}>
            <Container>
                <Nav path={path} />
                <FormBuilderRoutes />
            </Container>
        </Form.Provider>
    ) : null
}

export default FormBuilder
