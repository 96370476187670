import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_EVENTS = gql`
    query GetEvents {
        events(first: 100) {
            edges {
                node {
                    ...EventData
                }
            }
        }
    }

    ${fragments.EventData}
`
export const GET_EVENTS_OPTIONS = gql`
    query GetEventsOptions($orderBy: EventOrderBy) {
        events(orderBy: $orderBy) {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`

export const GET_EVENTS_MAIN = gql`
    query GetEvents($first: Int, $search: String, $orderBy: EventOrderBy) {
        events(first: $first, orderBy: $orderBy, search: $search) {
            edges {
                node {
                    id
                    name
                    startDate
                    endDate
                    liveTalks {
                        totalCount
                    }
                    logo {
                        ...MediaData
                    }
                }
            }
        }
    }
    ${fragments.MediaData}
`

export const GET_CHECKOUT_CONFIG = gql`
    query GetEvent($id: ObjectID!) {
        event(id: $id) {
            checkoutConfig {
                ...CheckoutConfigData
            }
        }
    }

    ${fragments.CheckoutConfigData}
`

export const GET_EVENT = gql`
    query GetEvent($id: ObjectID!) {
        event(id: $id) {
            ...EventData
        }
    }

    ${fragments.EventData}
`

export const GET_EVENT_SITE = gql`
    query GetEventSite($id: ObjectID!) {
        event(id: $id) {
            name
            startDate
            address
            viralCampaign {
                id
            }
            site {
                id
                data
                draft
            }
            formRegister {
                id
            }
        }
    }
`

export const GET_EVENT_CHECKOUT = gql`
    query GetEventCheckout($id: ObjectID!) {
        event(id: $id) {
            name
            startDate
            address
            checkout {
                id
                data
                draft
            }
        }
    }
`

export const GET_EVENT_FIELDS = gql`
    query GetEventFields($id: ObjectID!) {
        event(id: $id) {
            fields {
                type
                label
                name
                config
            }
        }
    }
`

export const GET_EVENT_FORMS = gql`
    query GetEventForms($id: ObjectID!) {
        event(id: $id) {
            forms {
                uuid
                name
                fields {
                    label
                    name
                }
                email
            }
        }
    }
`

export const GET_EVENT_LANGUAGES = gql`
    query GetEventLanguages($id: ObjectID!) {
        event(id: $id) {
            translation {
                languages
            }
        }
    }
`

export const GET_EVENT_INTEGRATIONS = gql`
    query GetEventSite($id: ObjectID!) {
        event(id: $id) {
            integrations {
                active
                name
                config
            }
        }
    }
`

export const GET_EVENT_STREAMING_TOKENS = gql`
    query GetStreamingTokens($event: ObjectID!) {
        eventStreamingTokens(event: $event) {
            token
            preview
        }
    }
`

export const GET_EVENT_CHECKOUT_TOKENS = gql`
    query GetCheckoutTokens($event: ObjectID!) {
        eventCheckoutTokens(event: $event) {
            token
            preview
        }
    }
`

export const GET_EVENT_CONTENTS_TAGS = gql`
    query GetEventContentsTags($eventId: ObjectID!) {
        eventContentsTags(event: $eventId)
    }
`
