import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled, { css } from 'styled-components'

import Loader from '@cms/core/components/Loader'

// const previewWidth = 675
// const previewWidth = 675
const deskWidth = 1240
const deskHeight = 1440

const Wrapper = styled.div`
    ${({ height = 0, previewWidth, previewHeight }) => css`
        width: ${previewWidth}px;
        height: ${(height / deskWidth) * previewWidth}px;
        max-height: ${previewHeight}px;
        padding: 0;
        margin: 0 auto;
        overflow: hidden;

        iframe {
            width: ${deskWidth}px;
            height: ${height > (previewHeight / previewWidth) * deskWidth
                ? (previewHeight / previewWidth) * deskWidth
                : height}px;
            border: 0;
            transform: scale(${previewWidth / deskWidth});
            transform-origin: 0 0;
        }
    `}
`
const EditorPreviewTest = React.forwardRef(
    ({ onInit, page, fieldRefValue }, ref) => {
        const { eventId } = useParams()
        const [height, setHeight] = useState(0)
        const [previewHeight, setPreviewHeight] = useState(700)
        const [previewWidth, setPreviewWidth] = useState(675)
        const { customer } = useSelector((state) => state)

        // const iframeUrl = `http://localhost:5001/api/preview?db=${customer.db}&event=${eventId}&editor=true&page=${page}`
        const iframeUrl = `${process.env.PAGE_PREVIEW_ENDPOINT}?db=${
            customer.db
        }&event=${eventId}&editor=true&page=${page}${
            fieldRefValue ? '&ref=' + fieldRefValue : ''
        }`

        const iframeRef = useRef(null)

        const Emit = (type, payload) => {
            if (iframeRef.current && iframeRef.current.contentWindow) {
                iframeRef.current.contentWindow.postMessage(
                    {
                        type,
                        payload
                    },
                    '*'
                )
            }
        }

        const handleParentWidth = (parentWidth) => {
            setPreviewWidth(parentWidth - 30)
        }

        const handleParentHeight = (parentHeight) => {
            setPreviewHeight(parentHeight)
        }

        const refresh = () => {
            try {
                iframeRef.current.src = iframeRef.current.src
            } catch (error) {
                console.log(error)
            }
        }

        useEffect(() => {
            const OnMessage = (event) => {
                if (!event.data) return
                const { type, payload } = event.data

                if (type === 'setHeight') {
                    setHeight(payload.height)
                }

                if (type === 'init') {
                    setLoading(false)
                    ref.current = {
                        Emit,
                        handleParentWidth,
                        handleParentHeight,
                        refresh
                    }
                    onInit && onInit(ref.current)
                }
            }
            window.addEventListener('message', OnMessage)

            return () => {
                window.removeEventListener('message', OnMessage)
            }
        }, [onInit])

        useEffect(() => {
            Emit('init')
        }, [])

        const [loading, setLoading] = useState(true)

        const handleLoad = () => {
            setLoading(false)
        }

        return (
            <>
                {loading ? <Loader fixed={false} padding='10px' /> : null}
                <Wrapper
                    height={height}
                    previewWidth={previewWidth}
                    previewHeight={previewHeight}
                >
                    <iframe
                        ref={(dom) => (iframeRef.current = dom)}
                        src={iframeUrl}
                        onLoad={handleLoad}
                        // src="https://xscale-pages-0.vercel.app/platform/5f5f6ac9a7dd244f0b6d14bc/editor-preview/home"
                        // src="https://xscale-pages-0.vercel.app/editor-preview/home"
                    />
                </Wrapper>
            </>
        )
    }
)

export default EditorPreviewTest
