import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import FormBuilder from '../formBuilder'
import { InfosPage, Fields, Integrations, WelcomeEmail } from '../../pages'

export const FormBuilderRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/inscricao"
                component={InfosPage}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/inscricao/campos"
                component={Fields}
            />
            <PrivateRoute
                exact
                path="/evento/:eventId/forms/inscricao/email"
                component={WelcomeEmail}
            />
        </Switch>
    )
}

export const FormRoutes = () => {
    return (
        <Switch>
            {/* <PrivateRoute exact path="/evento/:eventId/paginas/" component={Dashboard} /> */}
            <PrivateRoute
                path="/evento/:eventId/forms/inscricao"
                component={FormBuilder}
            />
        </Switch>
    )
}

export default FormBuilderRoutes
