import React, { useState } from 'react'
import { useLocation, useParams, Link } from 'react-router-dom'
import { Affix, Col, Button, Row, Space, Form } from 'antd'

import { Wrapper, Navigation } from './styles'

const Nav = ({ path }) => {
    const [form] = Form.useForm()
    const { eventId } = useParams()
    const { pathname } = useLocation()

    const [loading, setLoading] = useState(false)

    const baseUrl = `/evento/${eventId}/forms/${path}`

    const handlePublishedForm = async () => {
        setLoading(true)
        await form.submit()
        setLoading(false)
    }

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row className="header" justify="space-between">
                    <Col>
                        <Navigation>
                            <Col>
                                <Link
                                    to={baseUrl}
                                    className={
                                        !pathname.includes('email') &&
                                        !pathname.includes('campos') &&
                                        !pathname.includes('integracoes')
                                            ? 'active-nav'
                                            : ''
                                    }
                                >
                                    Informações
                                </Link>
                                <Link
                                    to={baseUrl + '/campos'}
                                    className={
                                        pathname === baseUrl + '/campos'
                                            ? 'active-nav'
                                            : ''
                                    }
                                >
                                    Campos
                                </Link>
                                {/* <Link
                                    to={baseUrl + '/integracoes'}
                                    className={
                                        pathname.includes('integracoes')
                                            ? 'active-nav'
                                            : ''
                                    }
                                >
                                    Integrações
                                </Link> */}
                                {path === 'inscricao' ? (
                                    <Link
                                        to={baseUrl + '/email'}
                                        className={
                                            pathname.includes('email')
                                                ? 'active-nav'
                                                : ''
                                        }
                                    >
                                        Email
                                    </Link>
                                ) : null}
                            </Col>
                        </Navigation>
                    </Col>
                    <Col>
                        <Space>
                            <Form name="form-nav" form={form}>
                                <Button
                                    loading={loading}
                                    type="primary"
                                    onClick={handlePublishedForm}
                                >
                                    Publicar
                                </Button>
                            </Form>
                        </Space>
                    </Col>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default Nav
