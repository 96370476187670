import { useQuery } from '@apollo/client'
import { useCrudSelect } from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'
import { GET_CATEGORY_LIST } from '@cms/events/graphql/queries/category'
import { GET_COLLECTION_CATEGORIES_LIST } from '@cms/events/graphql/queries/collectionCategories'
import { GET_SPEAKER_LIST } from '@cms/events/graphql/queries/speaker'
import { GET_TALK_OPTIONS } from '@cms/events/graphql/queries/talk'
import { Form } from 'antd'
import React from 'react'

export const DeprecatedPermissionInputs = ({ getError, eventId } = {}) => {
    const queryCategories = useQuery(GET_CATEGORY_LIST, {
        variables: { first: 9999, eventId },
        fetchPolicy: 'cache-and-network'
    })

    const [CategoriesOptions, categoriesSelectProps] = useCrudSelect(
        queryCategories,
        {
            queryName: 'categories'
        }
    )

    const queryTalks = useQuery(GET_TALK_OPTIONS, {
        variables: { eventId, orderBy: { createdAt: 'ASC' } }
    })

    const [TalksOptions, TalksSelectProps] = useCrudSelect(queryTalks, {
        queryName: 'talks'
    })

    const querySpeakers = useQuery(GET_SPEAKER_LIST, {
        variables: { eventId, orderBy: { createdAt: 'ASC' } }
    })

    const [SpeakersOptions, SpeakersSelectProps] = useCrudSelect(
        querySpeakers,
        {
            queryName: 'speakers'
        }
    )

    const queryCollectionCategories = useQuery(GET_COLLECTION_CATEGORIES_LIST, {
        variables: { eventId, orderBy: { createdAt: 'ASC' } }
    })

    const [CollectionCategoriesOptions, CollectionCategoriesSelectProps] =
        useCrudSelect(queryCollectionCategories, {
            queryName: 'collectionCategories'
        })

    return (
        <>
            <Form.Item
                label="Tags (Palestras, Categoria, Palestrantes, Coleções)"
                name={['accessRestrictions', 'tags']}
                {...getError('accessRestrictions.collectionCategories')}
            >
                <SelectSearch {...CollectionCategoriesSelectProps}>
                    {CollectionCategoriesOptions({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>

            <Form.Item
                label="Palestras"
                name={['accessRestrictions', 'talks']}
                {...getError('accessRestrictions.talks')}
            >
                <SelectSearch {...TalksSelectProps}>
                    {TalksOptions({
                        key: 'node.id',
                        label: 'node.title',
                        groupBy: {
                            key: 'node.type',
                            groups: {
                                STAGE: 'Palcos',
                                LIVE: 'Palestras',
                                ONDEMAND: 'Videos On Demand'
                            }
                        }
                    })}
                </SelectSearch>
            </Form.Item>

            <Form.Item
                label="Trilhas"
                name={['accessRestrictions', 'categories']}
                {...getError('accessRestrictions.categories')}
            >
                <SelectSearch {...categoriesSelectProps}>
                    {CategoriesOptions({
                        key: 'node.id',
                        label: 'node.name',
                        groupBy: {
                            key: 'node.type',
                            groups: {
                                MAIN: 'Trilhas',
                                PLAYLIST: 'Playlists'
                            }
                        }
                    })}
                </SelectSearch>
            </Form.Item>

            <Form.Item
                label="Palestrantes"
                name={['accessRestrictions', 'speakers']}
                {...getError('accessRestrictions.speakers')}
            >
                <SelectSearch {...SpeakersSelectProps}>
                    {SpeakersOptions({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>

            <Form.Item
                label="Categorias de coleções"
                name={['accessRestrictions', 'collectionCategories']}
                {...getError('accessRestrictions.collectionCategories')}
            >
                <SelectSearch {...CollectionCategoriesSelectProps}>
                    {CollectionCategoriesOptions({
                        key: 'node.id',
                        label: 'node.name'
                    })}
                </SelectSearch>
            </Form.Item>
        </>
    )
}
