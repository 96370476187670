import React from 'react'
import { Input, Form } from 'antd'

const Term = ({ config }) => {
    return (
        <>
            <Form.Item label="Label url" name={['config', 'label']}>
                <Input type={'text'} />
            </Form.Item>
            <Form.Item label="Url" name={['config', 'url']}>
                <Input
                    type={'url'}
                    placeholder="Deixe em branco para utilizar o termos de uso do evento."
                />
            </Form.Item>
        </>
    )
}

export default Term
