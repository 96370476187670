import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_CAMPAIGN = gql`
    mutation CreateCampaign(
        $name: String
        $rewards: [EngagementRewardsInputs]
        $triggers: [TriggerInputs]
        $event: ObjectID
    ) {
        createEngagementCampaign(
            input: {
                name: $name
                rewards: $rewards
                event: $event
                triggers: $triggers
            }
        ) {
            ...EngagementCampaignData
        }
    }
    ${fragments.EngagementCampaignData}
`

export const UPDATE_CAMPAIGN = gql`
    mutation UpdateCampaign(
        $id: ObjectID!
        $name: String
        $rewards: [EngagementRewardsInputs]
        $triggers: [TriggerInputs]
        $event: ObjectID
    ) {
        updateEngagementCampaign(
            id: $id
            input: {
                name: $name
                rewards: $rewards
                event: $event
                triggers: $triggers
            }
        ) {
            ...EngagementCampaignData
        }
    }
    ${fragments.EngagementCampaignData}
`

export const UPDATE_REWARD = gql`
    mutation UpdateEngagementReward($id: ObjectID!, $delivered: Boolean) {
        updateEngagementReward(input: { delivered: $delivered }, id: $id) {
            id
            delivered
        }
    }
`

export const DELETE_CAMPAIGN = gql`
    mutation DeleteCampaign($id: ObjectID!) {
        deleteEngagementCampaign(id: $id) {
            id
            success
        }
    }
`
