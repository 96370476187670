import React from 'react'

import BaseConfig from '../BaseConfig'
import { Form, Select } from 'antd'
import { TALKS_CONFIGS } from '../../Segment/constants/talks'
import Segment from '@cms/core/components/Segment'

const Talks = (props) => {
    const isStage =
        Form.useFormInstance().getFieldValue([
            'sections',
            props.name,
            'config',
            'variant'
        ]) === 'stage'
    return (
        <>
            <BaseConfig name={props.name}>
                {!isStage ? (
                    <Form.Item
                        name={[props.name, 'config', 'variant']}
                        label="Tipo de visualização"
                        initialValue={'default'}
                    >
                        <Select>
                            <Select.Option value="default">
                                Padrão
                            </Select.Option>
                            <Select.Option value="cta">Cta</Select.Option>
                            <Select.Option value="stage">
                                Destaque
                            </Select.Option>
                        </Select>
                    </Form.Item>
                ) : null}
            </BaseConfig>
            <Segment
                form={props.form}
                name={[props.name, 'config']}
                type="talks"
                filters={TALKS_CONFIGS}
                configs={{
                    hiddenFields: {
                        type: 'STAGE'
                    },
                    formName: 'sections'
                }}
            />
        </>
    )
}

export default Talks

