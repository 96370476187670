import { Modal } from 'antd'
import React, {useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ModalContent from './modalContent'

const Surveys = ({ params, path, eventId, talkId, ...props }) => {
    const history = useHistory()
    const { id } = useParams()
    useEffect((_)=>{
        document.body.style.overflowY = 'hidden'
        return (_)=>{
            document.body.style.overflowY = ''
        }
    },[])



    const handleCancelModal = () => {
        history.push(path)
    }
    const handleOk = () => {
        history.push(path + '/survey/adicionar')
    }


    return (
        (<Modal
            open={true}
            onOk={handleOk}
            okText={"Nova enquete"}
            cancelText={"Fechar"}
            onCancel={handleCancelModal}
            width={600}
            title={'Gerenciamento de enquete'}
            children= { <ModalContent talkId={talkId} path={path} eventId={eventId}/> }
            {...props}
        />)
    );
}

export default Surveys