import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_SPEAKER = gql`
    mutation CreateSpeaker(
        $event: ObjectID!
        $image: MediaInput
        $firstName: String
        $lastName: String
        $email: String
        $bio: String
        $company: CompanyInputs
        $office: String
        $featured: Boolean
        $priority: Int
        $links: [LinkInputs]
        $tags: [String]
        $user: ObjectID
        $page: PageSpeakerInputs
    ) {
        createSpeaker(
            input: {
                event: $event
                image: $image
                firstName: $firstName
                lastName: $lastName
                email: $email
                bio: $bio
                company: $company
                office: $office
                links: $links
                featured: $featured
                priority: $priority
                tags: $tags
                user: $user
                page: $page
            }
        ) {
            ...SpeakerData
        }
    }

    ${fragments.SpeakerData}
`

export const UPDATE_SPEAKER = gql`
    mutation UpdateSpeaker(
        $id: ObjectID!
        $event: ObjectID!
        $image: MediaInput
        $firstName: String
        $lastName: String
        $email: String
        $bio: String
        $company: CompanyInputs
        $office: String
        $featured: Boolean
        $priority: Int
        $links: [LinkInputs]
        $tags: [String]
        $user: ObjectID
        $page: PageSpeakerInputs
    ) {
        updateSpeaker(
            id: $id
            input: {
                event: $event
                image: $image
                firstName: $firstName
                lastName: $lastName
                email: $email
                bio: $bio
                company: $company
                office: $office
                links: $links
                featured: $featured
                priority: $priority
                tags: $tags
                user: $user
                page: $page
            }
        ) {
            ...SpeakerData
        }
    }

    ${fragments.SpeakerData}
`

export const DELETE_SPEAKER = gql`
    mutation DeleteSpeaker($id: ObjectID!) {
        deleteSpeaker(id: $id) {
            id
            success
        }
    }
`
