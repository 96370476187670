import React, { useState, useEffect } from 'react'

import ReactQuill from 'react-quill'

import { EditorWrapper } from './styles'

const EmailEditor = ({ onChange, value }) => {
    return (
        <EditorWrapper>
            <ReactQuill
                onChange={onChange}
                defaultValue=""
                value={value}
                modules={{
                    toolbar: true,
                    history: {
                        delay: 500,
                        maxStack: 100,
                        userOnly: true
                    }
                }}
                // tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5.5.1/tinymce.min.js"
                // disabled={false}
                // id="uuid"
                // init={{ menubar: false }}
                // initialValue=""
                // inline={false}
                // onEditorChange={onChange}
                // plugins="link"
                // tagName="div"
                // textareaName=""
                // toolbar="bold italic underline link"
                // value={value}
            />
        </EditorWrapper>
    )
}

export default EmailEditor
