import React, { useState } from 'react'

import { Button, Dropdown, Form } from 'antd'

import { FormProvider } from 'antd/lib/form/context'
import FilterFields from './FilterFields'
import { FilterOutlined } from '@ant-design/icons'
import { FilterFieldsType } from '../types'

interface ICrudListFilterProps {
    fields: FilterFieldsType[]
    refetch: (obj: any) => void
    setFilters: any
}

const CrudListFilter = ({
    fields,
    refetch,
    setFilters
}: ICrudListFilterProps) => {
    const [open, setOpen] = useState<boolean>()

    const [form] = Form.useForm()

    const handleFormFinish = (name, { values }) => {
        setFilters((prev) => [
            ...prev,
            {
                ...values,
                query: {
                    [values.field.name]: values.field.value
                }
            }
        ])

        const fetchObject = {
            [values.field.name]:
                values.queryType === 'Filter'
                    ? {
                          [values.field.condition]: values.field.value
                      }
                    : values.field.value
        }

        refetch(fetchObject)

        setOpen(false)
        form.resetFields()
    }

    return (
        <FormProvider onFormFinish={handleFormFinish}>
            <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                    <div
                        style={{
                            width: '250px',
                            background: '#2F2F38',
                            borderRadius: '10px',
                            boxShadow:
                                '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)'
                        }}
                    >
                        <Form
                            form={form}
                            layout="vertical"
                            name="crudFilterFields"
                            style={{ position: 'relative', width: '100%' }}
                        >
                            <FilterFields fields={fields} form={form} />
                        </Form>
                    </div>
                )}
                open={open}
                onOpenChange={(open) => setOpen(open)}
            >
                <Button
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                    icon={<FilterOutlined />}
                ></Button>
            </Dropdown>
        </FormProvider>
    )
}

export default CrudListFilter

