import React, { useEffect, useState } from 'react'
import { message, Form, Select, DatePicker } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'
import { get } from 'lodash'

import SelectSearch from '@cms/core/components/SelectSearch'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'

import { REQUEST_REPORT } from '@cms/events/graphql/mutations/reports'
import { typesReport, typesReportConfig } from '../constants'

import { GET_TALK_OPTIONS } from '@cms/events/graphql/queries/talk'
import { GET_EVENT_FORMS_OPTIONS } from '@cms/events/graphql/queries/eventForms'
import { GET_REPORT_LIST_ORDENABLE } from '@cms/events/graphql/queries/reports'

const renderSelect = (type) => {
    const value = typesReportConfig[type]

    if (value) {
        if (value.requestTalk) {
            return {
                status: true,
                type: 'talk'
            }
        }

        if (value.requestDateRange) {
            return {
                status: true,
                type: 'dateRange'
            }
        }

        return { status: false }
    }
    return { status: false }
}

const EventFormSelect = ({ name, eventId, config }) => {
    const queryEventForms = useQuery(GET_EVENT_FORMS_OPTIONS, {
        variables: { first: 9999, eventId }
    })

    const [eventFormOptions, eventFormSelectOptions] = useCrudSelect(
        queryEventForms,
        {
            queryName: 'eventForms'
        }
    )

    return (
        <Form.Item
            label={get(config.params, name)}
            name={['params', name]}
            rules={[{ required: true, message: 'Tipo é obrigatório!' }]}
        >
            <SelectSearch mode="single" {...eventFormSelectOptions}>
                {eventFormOptions({
                    key: 'node.id',
                    label: 'node.name'
                })}
            </SelectSearch>
        </Form.Item>
    )
}

const ReportModal = ({ params, ...props }) => {
    const { eventId } = params

    const client = useApolloClient()

    const [{ form, getError, dataLoadingControl }, modalProps] = useCrudForm({
        createData: async (formData) => {
            await client.mutate({
                mutation: REQUEST_REPORT,
                refetchQueries: [GET_REPORT_LIST_ORDENABLE],
                variables: { ...formData, event: eventId }
            })

            message.success('Novo relatório requisitado com sucesso!')
        }
    })

    const queryTalks = useQuery(GET_TALK_OPTIONS, {
        variables: { first: 9999, eventId }
    })

    const [talksOptions, talksSelectOptions] = useCrudSelect(queryTalks, {
        queryName: 'talks'
    })

    useEffect(() => {
        dataLoadingControl.toggle(talksSelectOptions.loading)
    }, [dataLoadingControl, talksSelectOptions])

    function RenderPicker(type) {
        if (type === 'talk') {
            return (
                <Form.Item
                    label="Palestra"
                    name={['talk']}
                    {...getError('talk')}
                >
                    <SelectSearch mode="single" {...talksSelectOptions}>
                        {talksOptions({
                            key: 'node.id',
                            label: 'node.title',
                            groupBy: {
                                key: 'node.type',
                                groups: {
                                    STAGE: 'Palcos',
                                    LIVE: 'Palestras',
                                    ONDEMAND: 'Videos On Demand'
                                }
                            }
                        })}
                    </SelectSearch>
                </Form.Item>
            )
        }

        if (type === 'dateRange') {
            return (
                <>
                    <Form.Item
                        label="Primeira Data"
                        name={['dateRange', 'startDate']}
                        rules={[
                            { required: true, message: 'Data é obrigatório!' }
                        ]}
                        {...getError('dateRange')}
                    >
                        <DatePicker picker="date" />
                    </Form.Item>
                    <Form.Item
                        label="Segunda Data"
                        name={['dateRange', 'endDate']}
                        rules={[
                            { required: true, message: 'Data é obrigatório!' }
                        ]}
                        {...getError('dateRange')}
                    >
                        <DatePicker picker="date" />
                    </Form.Item>
                </>
            )
        }
    }

    const [type, setType] = useState('')

    const renderCustomParams = () => {
        const config = typesReportConfig[type]
        if (!config) return <></>
        if (!config.active) return <></>
        if (!config.params) return <></>

        return Object.keys(config.params).map((param) => (
            <EventFormSelect config={config} name={param} eventId={eventId} />
        ))
    }

    return (
        <CrudModal title={'Novo Relatório'} {...modalProps} {...props}>
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Tipo"
                    name="type"
                    placeholder="Selecione um tipo"
                    rules={[{ required: true, message: 'Tipo é obrigatório!' }]}
                    {...getError('type')}
                >
                    <Select onChange={(value) => setType(value)}>
                        {typesReport.map((obj) => (
                            <Select.Option key={obj.value} value={obj.value}>
                                {obj.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {renderSelect(type)?.status &&
                    RenderPicker(renderSelect(type)?.type)}
                {renderCustomParams()}
            </Form>
        </CrudModal>
    )
}

export default ReportModal
