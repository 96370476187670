import IntegrationInput from '@cms/core/components/Integrations/components/Input'
import { Col, Form, Input, Select, Typography, Tag, Checkbox } from 'antd'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useFormBuilderContext } from '../../context/context'
import FORM_WITH_PAGES from '../../FormProgressive/pages'
import { GET_TICKETS_LIST } from '@cms/events/graphql/queries/tickets'
import SelectSearch from '@cms/core/components/SelectSearch'
import { useCrudSelect } from '@cms/core/components/Crud'
import { useQuery } from '@apollo/client'

const InfosPage = () => {
    const { formData, setFormData, type } = useFormBuilderContext()
    const { eventId } = useParams()

    const [form] = Form.useForm()

    const customerModules = useSelector(
        (state) => state.customer?.modules || []
    )

    const queryTickets = useQuery(GET_TICKETS_LIST, {
        variables: { first: 9999, eventId }
    })

    const [ticketsOptions, ticketsSelectOptions] = useCrudSelect(queryTickets, {
        queryName: 'tickets'
    })

    useEffect(() => {
        form.setFieldsValue({
            ...formData,
            integrations: formData.integrations?.map((i) => i.id || i),
            name:
                type === 'register' ? 'Formulário de inscrição' : formData?.name
        })
    }, [formData, type])

    const handleFormName = (formName) => {
        setFormData({
            ...formData,
            name: formName
        })
    }

    const handleFormPage = (page) => {
        setFormData({
            ...formData,
            config: { page }
        })
    }

    const handleFormIntegrations = (integrations) => {
        setFormData({
            ...formData,
            integrations
        })
    }

    const handleEmailExternalValidation = (event) => {
        setFormData({
            ...formData,
            emailExternalValidation: event.target.checked
        })
    }

    const handleBotValidation = (event) => {
        setFormData({
            ...formData,
            botProtection: event.target.checked
        })
    }

    const handleFormTicket = (ticket) => {
        setFormData({
            ...formData,
            defaultTicket: ticket
        })
    }

    if (!formData) return null

    return (
        <>
            <Form name="preview" form={form} layout="vertical">
                <Col md={12}>
                    <Form.Item label="Nome" name="name">
                        <Input
                            disabled={type === 'register'}
                            onChange={(e) =>
                                handleFormName(e.currentTarget.value)
                            }
                        />
                    </Form.Item>
                </Col>

                {type === 'progressive' && (
                    <Col md={12}>
                        <Form.Item label="Página" name={['config', 'page']}>
                            <Select onChange={handleFormPage}>
                                {FORM_WITH_PAGES.map((value) => (
                                    <Select.Option key={value} value={value}>
                                        {value}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                )}

                <Col md={12}>
                    <IntegrationInput
                        eventId={eventId}
                        onChange={handleFormIntegrations}
                    />
                </Col>

                {customerModules.includes('TICKET') && type === 'register' && (
                    <Col md={12}>
                        <Form.Item
                            name="defaultTicket"
                            label="Vincular ticket ao cadastrar"
                        >
                            <SelectSearch
                                mode="single"
                                allowClear
                                onChange={handleFormTicket}
                                {...ticketsSelectOptions}
                            >
                                {ticketsOptions({
                                    key: 'node.id',
                                    label: 'node.name'
                                })}
                            </SelectSearch>
                        </Form.Item>
                    </Col>
                )}

                {customerModules.includes('EMAILABLE') && (
                    <Col md={12}>
                        <Form.Item
                            name="emailExternalValidation"
                            valuePropName="checked"
                        >
                            <Checkbox onChange={handleEmailExternalValidation}>
                                Validação externa de email
                            </Checkbox>
                        </Form.Item>
                    </Col>
                )}

                <Col md={12}>
                    <Form.Item name="botProtection" valuePropName="checked">
                        <Checkbox onChange={handleBotValidation}>
                            Proteção contra bots
                        </Checkbox>
                    </Form.Item>
                </Col>
            </Form>
        </>
    )
}

export default InfosPage
