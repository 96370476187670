import React, { useEffect, useState } from 'react'
import { CheckoutProvider, useCheckoutContext } from './context'
import { ThemeBuilderProvider } from '@cms/core/components/ThemeBuilder'
import { ThemeBuilderContent } from '@cms/core/components/ThemeBuilder/components/Content'
import { BASE_URL, ROUTES } from './constants/routes'

import { useQuery } from '@apollo/client'
import { GET_EVENT_CHECKOUT_TOKENS } from '@cms/events/graphql/queries/event'
import { useParams } from 'react-router-dom'

const CheckoutContent = () => {
    const { save } = useCheckoutContext()

    return <ThemeBuilderContent save={save} />
}

const CheckoutScreen = () => {
    const { eventId } = useParams()

    const [previewUrl, setPreviewUrl] = useState(null)

    const { data: tokensData } = useQuery(GET_EVENT_CHECKOUT_TOKENS, {
        variables: {
            event: eventId
        }
    })

    useEffect(() => {
        if (tokensData?.eventCheckoutTokens) {
            const { token } = tokensData?.eventCheckoutTokens
            setPreviewUrl(
                `${process.env.APIGW_ENDPOINT}/auth/magiclink/callback?token=${token}`
            )
        }
    }, [tokensData])

    return (
        <ThemeBuilderProvider
            routesConfig={{ baseUrl: BASE_URL, routes: ROUTES }}
            previewUrl={previewUrl}
        >
            <CheckoutProvider>
                <CheckoutContent />
            </CheckoutProvider>
        </ThemeBuilderProvider>
    )
}

export default CheckoutScreen
