import React, { useEffect } from 'react'
import { Form, Typography, Row, Collapse, Popconfirm, Input } from 'antd'
import AceEditor from 'react-ace'

import { PanelFooter } from '../../components'
import { usePageBuilderContext } from '../../context/context'
import { ColForm } from './styles'
import Title from 'antd/es/typography/Title'
import { InfoCircleOutlined } from '@ant-design/icons'
const { Panel } = Collapse

const WebScripts = (props) => {
    const [form] = Form.useForm()
    const { site } = usePageBuilderContext()

    useEffect(() => {
        form.setFieldsValue(site.scripts)
    }, [site])

    if (!site) return null

    return (
        <Row gutter={24} style={{ padding: '0px 12px' }}>
            <ColForm md={12} sm={24}>
                <Form
                    name="scripts"
                    form={form}
                    // onValuesChange={updateSection}
                    layout="vertical"
                >
                    <Title level={4}>Google Tag Manager (GTM)</Title>
                    <Form.Item
                        name="gtm"
                        label="ID do container GTM"
                        rules={[
                            {
                                validator(_, value) {
                                    const re = new RegExp(
                                        /^GTM-[A-Z0-9]{1,10}$/
                                    )
                                    console.log(re.test(value))

                                    if (value) {
                                        if (re.test(value)) {
                                            return Promise.resolve()
                                        } else {
                                            return Promise.reject(
                                                new Error(
                                                    'Por favor, informe um código GTM válido!'
                                                )
                                            )
                                        }
                                    }

                                    return Promise.resolve()
                                }
                            }
                        ]}
                    >
                        <Input
                            placeholder="GTM-XXXXXX"
                            onInput={(e) =>
                                (e.target.value = e.target.value.toUpperCase())
                            }
                        />
                    </Form.Item>

                    <Title level={4}>Estilos</Title>
                    <Collapse>
                        <Panel header="Estilos (CSS)" key="0">
                            <Form.Item label="" name={['css']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'css'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <Title level={4}>
                        Scripts{' '}
                        <Popconfirm
                            title={`Recomendamos a inserção de scripts através do Google Tag Manager(GTM).`}
                            showCancel={false}
                        >
                            <InfoCircleOutlined />
                        </Popconfirm>
                    </Title>

                    <Collapse>
                        <Panel header="No header" key="1">
                            <Form.Item label="" name={['header']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                        <Panel header="Na abertura do body" key="2">
                            <Form.Item label="" name={['bodyStart']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                        <Panel header="No fechamento do body" key="3">
                            <Form.Item label="" name={['bodyEnd']}>
                                <AceEditor
                                    placeholder="Entre seu código"
                                    mode={'html'}
                                    width={'100%'}
                                    height={'200px'}
                                    theme="terminal"
                                    name="td-control-code-editor"
                                    fontSize={14}
                                    showPrintMargin={true}
                                    showGutter={true}
                                    highlightActiveLine={true}
                                    setOptions={{
                                        enableBasicAutocompletion: true,
                                        enableLiveAutocompletion: true,
                                        enableSnippets: false,
                                        showLineNumbers: true,
                                        tabSize: 2
                                    }}
                                />
                            </Form.Item>
                        </Panel>
                    </Collapse>

                    <PanelFooter />
                </Form>
            </ColForm>
        </Row>
    )
}

export default WebScripts
