import React, { useEffect } from 'react'
import {
    BrowserRouter,
    Route,
    Switch,
    Redirect,
    Router
} from 'react-router-dom'
import { isAuthenticated } from '@cms/core/utils/auth'

import Container from '@cms/core/components/Container'
import { Button, Result } from 'antd'

import Login from '@cms/core/pages/SignIn'
import RecoverPassword from '@cms/core/pages/RecoverPassword'
import User from '@cms/core/pages/User'
import PrivateRoute from './privateRoute'
import { globalHistory } from '../components/RootLink'
import Route404 from './404Route'

const CheckRouter = ({ component: Component, ...rest }) => {
    useEffect(() => {
        if (isAuthenticated()) {
            globalHistory.push('/eventos')
        }
    }, [])

    if (!isAuthenticated()) {
        return <Route {...rest} render={(props) => <Component {...props} />} />
    }

    return null
}

export default function routes() {
    return (
        <Router history={globalHistory}>
            <Switch>
                <CheckRouter exact path="/" component={Login} />
                <Route path="/recuperar-senha" component={RecoverPassword} />
                <Container />
                <Route404 />
            </Switch>
        </Router>
    )
}
