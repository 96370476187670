import React, { useEffect, useState } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { gql, useMutation, useQuery } from '@apollo/client'

import { GET_BRAND_BY_EVENT } from '@cms/events/graphql/queries/brand'

import { SelectStyled, DropDownStyled } from './style'
import HeaderCrumb from '@cms/core/components/Header/HeaderCrumb'
import { useBrandSelect } from './context'

const { Option } = SelectStyled

const SelectBrand = ({ ...props }) => {
    const history = useHistory()
    const { eventId } = useParams<{ eventId: string }>()

    const { brandId, setBrandId } = useBrandSelect()

    const { error, data } = useQuery(GET_BRAND_BY_EVENT, {
        variables: { eventId }
    })

    useEffect(() => {
        if (data?.userBrands) {
            if (data.userBrands.length === 1) {
                setBrandId(data.userBrands[0].id)
            }
        }
    }, [data])

    if (error) return <p>Error :(</p>

    return (
        <HeaderCrumb
            title={
                <SelectStyled
                    {...props}
                    placeholder="Selecionar marcas"
                    value={brandId}
                    onChange={setBrandId}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    dropdownRender={(menu) => (
                        <DropDownStyled>{menu}</DropDownStyled>
                    )}
                >
                    {/* {data?.userBrands?.length === 1 ? null : (
                        <Option key={'all'} value={'all'}>
                            Todas as marcas
                        </Option>
                    )} */}

                    {!data ? (
                        <Option key={'loading'} value={null}>
                            carregando...
                        </Option>
                    ) : null}

                    {data?.userBrands.map((obj) => (
                        <Option key={obj.id} value={obj.id}>
                            {obj.name}
                        </Option>
                    ))}
                </SelectStyled>
            }
        />
    )
}

export default SelectBrand
