import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Form, message } from 'antd'

import Steps from '@cms/core/components/Steps'
import { TDCardForForm } from '@cms/core/components/TDCards'

import { Step1, Step2 } from './steps'
import { merge } from 'lodash'
import {
    GET_INTEGRATION,
    GET_ZOHO_AUTH_URL
} from '@cms/core/graphql/queries/integrations'
import { useMutation, useQuery } from '@apollo/client'

import {
    CREATE_INTEGRATION,
    UPDATE_INTEGRATION
} from '@cms/core/graphql/mutations/integrations'

const { error } = message

const config = {
    category: 'conversions',
    unique: false,
    appType: 'EVENTS'
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms))

const ZohoCRM = () => {
    const [form] = Form.useForm()

    const useSearchParams = new URLSearchParams(useLocation().search)
    const { eventId } = useParams()
    const history = useHistory()

    const isEditing = !!useSearchParams.get('id')

    const [editIntegration, setEditIntegration] = useState({
        active: true,
        category: config.category,
        type: 'ZohoCRM',
        config: {},
        privateConfig: {}
    })

    const [nextLabel, setNextLabel] = useState()

    const { data } = useQuery(GET_INTEGRATION, {
        variables: {
            id: useSearchParams.get('id')
        }
    })

    const [create] = useMutation(CREATE_INTEGRATION)
    const [update] = useMutation(UPDATE_INTEGRATION)

    const steps = [
        {
            title: 'Passo 1',
            component: <Step1 />
        },
        {
            title: 'Passo 2',
            component: <Step2 form={form} integrationId={editIntegration.id} />
        }
    ]

    const { refetch } = useQuery(GET_ZOHO_AUTH_URL, {
        variables: {
            integrationId: useSearchParams.get('id'),
            clientRedirectUri: ''
        }
    })

    const handleAuthentication = async ({ integrationId, create = false }) => {
        const queryParam = create ? `&id=${integrationId}&step=2` : '&step=2'

        const { data: zohoData } = await refetch({
            integrationId: integrationId,
            clientRedirectUri: `${window.location.href}${queryParam}`
        })

        window.location.replace(zohoData.zohoAuthUrl)

        return delay(5000).then(() => false)
    }

    const onSave = useCallback(
        async (integration) => {
            const variables = {
                ...integration,
                event: eventId,
                appType: config.appType,
                config: {
                    body: integration.config?.body,
                    campaign:
                        typeof integration.config?.campaign === 'string'
                            ? JSON.parse(integration.config?.campaign)
                            : integration.config?.campaign
                }
            }

            if (isEditing) {
                if (
                    integration.privateConfig?.accessToken &&
                    integration.privateConfig?.refreshToken
                ) {
                    await update({ variables })
                    return true
                }

                await update({ variables })
                return handleAuthentication({ integrationId: integration.id })
            }

            const response = await create({ variables })
            return handleAuthentication({
                integrationId: response.data.createIntegration.id,
                create: true
            })
        },
        [eventId, config.unique]
    )

    const onNext = async () => {
        return form
            .validateFields()
            .then(async (values) => {
                return await onSave(merge({}, editIntegration, values))
            })
            .catch((info) => {
                console.log(info)
                error('Verifique os campos do formulário!')
                return false
            })
    }

    const onFinish = () =>
        history.replace(`/evento/${eventId}/integracoes/conversions/home`)

    useEffect(() => {
        if (data?.integration) {
            setEditIntegration(data?.integration)
            form.setFieldsValue(data?.integration)
        }
    }, [data])

    useEffect(() => {
        setNextLabel(
            isEditing && editIntegration?.privateConfig?.accessToken
                ? 'Próximo'
                : 'Autenticar'
        )
    }, [editIntegration])

    return (
        <TDCardForForm>
            <Steps
                form={form}
                steps={steps}
                onNext={onNext}
                onPrev={onNext}
                onFinish={onFinish}
                currentDefault={+useSearchParams.get('step')}
                enableDelete={false}
                nextLabel={nextLabel}
                textAlign="left"
            />
        </TDCardForForm>
    )
}

export default ZohoCRM

