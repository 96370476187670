import styled from 'styled-components'
import { COLORS } from '@cms/core/themes'

export const IconWrapper = styled.div`
    padding: 8px;
    border-radius: 100%;
    background-color: ${(props) =>
        props.bg ? props.bg : 'rgba(255, 255, 255, 0.2)'};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const ButtonPDF = styled.div`
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    height: 6vh;
    border-radius: 1.5vh;
    background-color: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    #pdfText {
        margin-left: 5px;
        color: #0c1717;
        font-weight: 600;
    }
    &:hover {
        cursor: pointer;
    }

    @media (min-width: 1600px) {
        height: 4.5vh;
    }
`

export const CardInfo = styled.div`
    padding: 10px;
    background-color: ${COLORS.bgDark};
    max-width: 97.5%;
    border-radius: 2vh;
    margin-bottom: 1.5vh;
    align-items: flex-start;
    justify-content: space-around;
    display: flex;
    flex-direction: column;

    h1.ant-typography {
        margin-bottom: 0;
        color: #ececec;
        opacity: 0.5;
        font-weight: 500;
        font-size: 0.85em;
    }

    .ant-typography {
        margin-bottom: 0;
        color: #ececec;
        font-weight: 500;
        font-size: 0.9em;
        max-width: 14.5vw;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .spaceFlex {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 100%;
    }

    #ButtonCopyUrl,
    #ButtonCopyEmail {
        height: 2.5vh;
        background-color: #ececec;
        border: none;
        display: flex;
        align-items: center;
        color: #0c1717;
        margin-left: auto;
    }
`

export const CardSearch = styled.div`
    padding: 10px;
    background-color: #cecece;
    height: 5vh;
    max-width: 100%;
    border-radius: 1.5vh;
    margin-bottom: 1.5vh;
    align-items: flex-start;
    justify-content: space-between;
    display: flex;

    &:hover {
        cursor: pointer;
    }

    h1.ant-typography {
        margin-bottom: 0;
        color: #0c1717;
        font-weight: 600;
        font-size: 0.85em;
    }
`

export const CardActivity = styled.div`
    width: 98%;
    border-radius: 1.5vh;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    h1.ant-typography {
        color: #ececec;
        font-weight: 600;
        margin-right: 60px;
    }
    span.ant-typography {
        width: 180px;
        text-align: right;
    }
`

export const ContentContainer = styled.div`
    height: calc(100vh - 230px);
    overflow-y: auto;
`
