import React from 'react'
import { Link, LinkProps, useHistory } from 'react-router-dom'

import { createBrowserHistory } from 'history'
import { Apps } from '@cms/core/Apps'
import { useApp } from '../Providers/AppProvider/Hooks/useApp'

interface customLinkProps {
    navigate: () => void
    children: React.ReactNode
    href: string
    checkMainApp?: boolean
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export const globalHistory = createBrowserHistory({
    basename: '/'
})

const CustomLink = React.forwardRef<HTMLAnchorElement, customLinkProps>(
    ({ navigate, href, children, checkMainApp, onClick, ...props }, ref) => {
        const app = useApp()
        const history = useHistory()

        // remove basePath regex
        const _href = href.replace(new RegExp(`^${app.basePath}`), '')

        const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
            if (onClick) {
                onClick(e)
            }

            if (!e.defaultPrevented) {
                e.preventDefault()
                if (checkMainApp && app.id === Apps.main.id) {
                    const __href = _href.replace(
                        new RegExp(`^${Apps.main.basePath}`),
                        ''
                    )
                    history.push(__href)
                } else {
                    globalHistory.push(_href)
                }
            }
        }

        return (
            <a {...props} ref={ref} href={_href} onClick={handleClick}>
                {children}
            </a>
        )
    }
)

export default function RootLink(
    props: React.PropsWithoutRef<LinkProps> &
        React.RefAttributes<HTMLAnchorElement> &
        Partial<customLinkProps>
) {
    return <Link to="/" {...props} component={CustomLink} />
}

