import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Affix, Col, Row, Space } from 'antd'

import { ButtonLink } from '@cms/events/components/Buttons'

import { Wrapper } from './styles'

const NavDashboard = () => {
    const { eventId } = useParams()

    return (
        <Wrapper>
            <Affix offsetTop={0}>
                <Row justify="end">
                    <Space>
                        <ButtonLink
                            to={`/evento/${eventId}/config/checkout/setup`}
                            text="Resetar"
                        />
                        <ButtonLink
                            to={`/evento/${eventId}/config/checkout/edit`}
                            text="Editar"
                        />
                    </Space>
                </Row>
            </Affix>
        </Wrapper>
    )
}

export default NavDashboard
