import React from 'react'
import { Switch } from 'react-router-dom'
import { useCreation } from '@umijs/hooks'

import PrivateRoute from '@cms/core/routes/privateRoute'
import { useParams } from 'react-router-dom'
import { CreateModalRoutes } from '@cms/core/components/Crud'

import Categories from '../Categories'
import SeriesList from '../pages/Series'
import PlaylistList from '../pages/Playlist'
import TrailsList from '../pages/Trails'
import CategoriesModal from '../CategoriesModal'

export const CategoriesBuilderRoutes = () => {
    const { eventId } = useParams()

    const TrailsModalRoute = useCreation(() => {
        const type = 'MAIN'
        const path = `/evento/${eventId}/categories`
        return CreateModalRoutes(CategoriesModal, path, { eventId, type })
    }, [eventId])

    const PlaylistModalRoute = useCreation(() => {
        const type = 'PLAYLIST'
        const path = `/evento/${eventId}/categories/playlist`
        return CreateModalRoutes(CategoriesModal, path, { eventId, type })
    }, [eventId])

    const SeriesModalRoute = useCreation(() => {
        const type = 'SERIE'
        const path = `/evento/${eventId}/categories/series`
        return CreateModalRoutes(CategoriesModal, path, { eventId, type })
    }, [eventId])

    return (
        <>
            <TrailsModalRoute />
            <PlaylistModalRoute />
            <SeriesModalRoute />
            <Switch>
                <PrivateRoute
                    exact
                    path="/evento/:eventId/categories"
                    component={TrailsList}
                />
                <PrivateRoute
                    exact
                    path="/evento/:eventId/categories/series"
                    component={SeriesList}
                />
                <PrivateRoute
                    exact
                    path="/evento/:eventId/categories/playlist"
                    component={PlaylistList}
                />
            </Switch>
        </>
    )
}

export const CategoriesRoutes = () => {
    return (
        <Switch>
            <PrivateRoute
                path="/evento/:eventId/categories/"
                component={Categories}
            />
        </Switch>
    )
}

export default CategoriesBuilderRoutes
