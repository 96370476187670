import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Input, Switch, Form } from 'antd'
import {
    CaretDownOutlined,
    CaretUpOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import Modal from '../../../../../../core/components/ModalFull'
import ModalContent from '../../ModalContent'
import RewardCounter from '../../RewardCounter'

import Context from '../../context'

import { Wrapper, MoveSection, MoveButton } from './styles'

const RewardAdd = ({
    title,
    disableCounter,
    instance,
    field,
    fields,
    disableEmail,
    topReward,
    index,
    size,
    pointLabel
}) => {
    const { onSave } = useContext(Context)
    const { move } = instance

    const handleClickUp = () => {
        move(index, index - 1)
    }

    const handleClickDown = () => {
        move(index, index + 1)
    }

    const enableUpClick = index > 0
    const enableDownClick = index < size - 1

    return (
        <Wrapper>
            <Row className="reward-add" gutter={16}>
                <Col span={4} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <MoveSection>
                        <MoveButton
                            block
                            onClick={handleClickUp}
                            disabled={!enableUpClick}
                        >
                            <CaretUpOutlined onClick={handleClickUp} />
                        </MoveButton>
                        <MoveButton
                            block
                            $side="right"
                            onClick={handleClickDown}
                            disabled={!enableDownClick}
                        >
                            <CaretDownOutlined onClick={handleClickDown} />
                        </MoveButton>
                    </MoveSection>
                    <Form.Item
                        name={[field.name, 'points']}
                        style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <RewardCounter pointLabel={pointLabel} />
                    </Form.Item>
                </Col>

                <Col
                    span={4}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Form.Item name={[field.name, 'media']}>
                        <MediaUploader type="image" style="image" />
                    </Form.Item>
                </Col>
                <Col
                    span={disableCounter ? 0 : 10}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        paddingBottom: '8px'
                    }}
                >
                    <Form.Item
                        style={{ width: '100%' }}
                        name={[field.name, 'name']}
                        label="Nome recompensa"
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        style={{ width: '100%' }}
                        name={[field.name, 'description']}
                        label="Descrição"
                        rules={[{ required: true }]}
                    >
                        <Input.TextArea />
                    </Form.Item>
                </Col>
                <Col
                    span={6}
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'space-between',
                        paddingBottom: '8px',
                        paddingTop: '8px'
                    }}
                >
                    <Row justify="end">
                        <Col span={24}>
                            <DeleteOutlined
                                onClick={() => instance.remove(field.name)}
                            />
                        </Col>
                    </Row>
                    {!disableEmail && !topReward && (
                        <>
                            <Row
                                className="inline-form-item"
                                justify="end"
                                style={{ width: '100%' }}
                            >
                                <Col span={24}>
                                    <Form.Item
                                        name={[field.name, 'useEmail']}
                                        label="E-mail"
                                        valuePropName="checked"
                                        labelCol={{ span: 4 }}
                                        wrapperCol={{ span: 20 }}
                                        className="switch-email"
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ width: '100%' }}>
                                <Col span={24}>
                                    <Modal
                                        onSave={onSave}
                                        block={true}
                                        showMenu={true}
                                    >
                                        <ModalContent
                                            variables="basic"
                                            title={title}
                                            field={[field.name]}
                                        />
                                    </Modal>
                                </Col>
                            </Row>
                        </>
                    )}
                </Col>
            </Row>
        </Wrapper>
    )
}

export default RewardAdd

