import Viral from './Viral/Viral'
import Viral2 from './Viral2/Viral'
import Viral3 from './Viral3/Viral'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'
import { Description } from '@cms/events/components/Dashboard/Sponsors/CardMetric/styles'

export const Virals = {
    Viral: {
        Component: Viral,
        name: 'Inscrição Confirmada',
        category: 'Inscrição Confirmada',
        columns: '2',
        thumb: require('./Viral/viral.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Viral2: {
        Component: Viral2,
        name: 'Inscrição Confirmada 2',
        category: 'Inscrição Confirmada',
        columns: '1',
        thumb: require('./Viral2/viral.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                box: {
                    bgColor: 'transparent',
                    indications: {
                        textColor: 'secondary'
                    },
                    reward: {
                        textColor: getColorContrast(colors, 'primary')
                    }
                },
                headline: {
                    text: 'indique amigos e ganhe prêmios',
                    textColor: getColorContrast(colors, 'primary')
                },
                content: {
                    text: 'Além de ter acesso a esse conteúdo gratuito, você pode convidar seusamigos e colegas de trabalho para participar com você e serrecompensado por isso! Confira:',
                    textColor: getColorContrast(colors, 'primary')
                },
                extratext: {
                    text: 'você pode convidar seus amigos e colegas de trabalho para participar com você',
                    textColor: getColorContrast(colors, 'primary')
                },
                button: {
                    bgColor: 'highlight',
                    textColor: getColorContrast(colors, 'primary'),
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    },
    Viral3: {
        Component: Viral3,
        name: 'Inscrição Confirmada 3',
        category: 'Inscrição Confirmada',
        columns: '1',
        thumb: require('./Viral3/viral.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                tagline: {
                    text: 'INDIQUE AMIGOS E',
                    textColor: 'primary'
                },
                headline: {
                    text: 'GANHE PRÊMIOS',
                    textColor: 'primary'
                },
                box: {
                    bgColor: 'transparent',
                    top3rewards: {
                        bgColor: 'transparent',
                        points: {
                            textColor: 'white'
                        },
                        title: {
                            textColor: 'highlight'
                        },
                        description: {
                            textColor: 'white'
                        },
                        scroll: {
                            textColor: 'highlight'
                        },
                        expand: {
                            bgColor: 'highlight',
                            textColor: 'primary'
                        }
                    },
                    toprewards: {
                        bgColor: 'transparent',
                        card: {
                            bgColor: 'transparent'
                        },
                        points: {
                            textColor: 'white'
                        },
                        title: {
                            textColor: 'highlight'
                        },
                        description: {
                            textColor: 'white'
                        },
                        scroll: {
                            textColor: 'highlight'
                        },
                        expand: {
                            bgColor: 'highlight',
                            textColor: 'primary'
                        }
                    },
                    rewards: {
                        bgColor: 'transparent',
                        indications: {
                            textColor: 'secondary'
                        },
                        reward: {
                            textColor: 'primary'
                        }
                    }
                },
                rewardImages: true,
                button: {
                    bgColor: 'highlight',
                    textColor: 'primary',
                    text: 'Inscreva-se gratuitamente'
                }
            }
        }
    }
}
