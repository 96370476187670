import { languages } from '@cms/core/components/ThemeBuilder/constants/languages'
import {
    COMPONENTS,
    TRoute
} from '@cms/core/components/ThemeBuilder/context/types'
import { socialMediaTypes } from '@cms/core/components/ThemeBuilder/pages/Footer/constants'
import { SECTIONS_DEFAULT } from '@cms/core/components/ThemeBuilder/pages/Theme/constants'

import Sections from '@cms/watch/pages/Config/pages/Home/components/Sections'

export const BASE_URL = `/config`

export const NAV_ROUTES: TRoute[] = [
    {
        path: `tema`,
        name: COMPONENTS.Theme,
        home: true,
        exact: true,
        title: 'Tema',
        subTitle: 'Configuração de cores da página de transmissão',
        sections: SECTIONS_DEFAULT.filter(
            (section) => section.name !== 'sponsor'
        )
    },
    {
        path: `images`,
        name: COMPONENTS.Images,
        exact: true,
        title: 'Imagens',
        subTitle: 'Imagens de fundo',
        sections: [
            {
                name: 'loginBackgroundImage',
                label: 'Imagem de fundo - Login'
            }
        ]
    },
    {
        path: `home`,
        name: COMPONENTS.Home,
        exact: true,
        title: 'Home',
        sections: Sections
    },
    {
        path: `footer`,
        name: COMPONENTS.Footer,
        exact: true,
        title: 'Footer',
        subTitle: 'Configurações do Footer',
        sections: [
            {
                fields: [
                    {
                        name: 'background',
                        label: 'Cor de fundo',
                        component: 'select',
                        initialValue: 'default',
                        options: [
                            { label: 'Padrão', value: 'default' },
                            { label: 'Primária', value: 'primary' },
                            { label: 'Destaque', value: 'highlight' }
                        ],
                        configs: {
                            mode: 'single'
                        }
                    },
                    {
                        name: 'social',
                        label: {
                            plural: 'Redes Sociais',
                            singular: 'Rede'
                        },
                        component: 'card-multiselect',
                        fields: [
                            {
                                label: 'Plataforma',
                                name: 'type',
                                component: 'select',
                                options: socialMediaTypes.map((s) => ({
                                    label: s,
                                    value: s
                                })),
                                configs: {
                                    mode: 'single',
                                    required: true
                                }
                            },
                            {
                                label: 'Url',
                                name: 'url',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            }
                        ]
                    },
                    {
                        name: 'links',
                        label: {
                            plural: 'Links',
                            singular: 'Link'
                        },
                        component: 'card-multiselect',
                        fields: [
                            {
                                label: 'Título',
                                name: 'label',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            },
                            {
                                label: 'Link',
                                name: 'url',
                                component: 'input',
                                configs: {
                                    required: true
                                }
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: `others`,
        name: COMPONENTS.Configs,
        exact: true,
        title: 'Outras configurações',
        subTitle: 'Outras configuração relacionadas ao TD Watch',
        sections: [
            {
                title: 'Geral',
                name: 'general',
                fields: [
                    {
                        name: ['translation', 'languages'],
                        label: 'Selecione os idiomas disponíveis para o TD Watch',
                        component: 'select',
                        options: languages,
                        configs: {
                            mode: 'multiple',
                            allowClear: true
                        }
                    }
                ]
            }
        ]
    }
]
