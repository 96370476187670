export const BASE_URL = `/evento/:eventId/integracoes/conversions`

export const ROUTES = [
    {
        path: 'home',
        name: 'Home',
        home: true,
        exact: true,
        title: 'Home'
    },
    {
        path: `steps/zohoCampaigns`,
        name: 'ZohoCampaigns',
        exact: true,
        title: 'Zoho Campaigns'
    },
    {
        path: `steps/zohoCRM`,
        name: 'ZohoCRM',
        exact: true,
        title: 'Zoho CRM'
    }
]

