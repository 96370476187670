import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState
} from 'react'
import { DEFAULT_THEME } from '../constants/theme'
import { TRoute } from './types'

type JsonType = Record<string, any>

interface ThemeBuilderContextProps {
    home: JsonType
    setHome: (home: JsonType) => void
    theme: JsonType
    setTheme: (theme: JsonType) => void
    images: JsonType
    setImages: (images: JsonType) => void
    scripts: JsonType
    navigation: JsonType
    setNavigation: (navigation: JsonType) => void
    setScripts: (scripts: JsonType) => void
    configs: JsonType
    setConfigs: (configs: JsonType) => void
    menus: JsonType
    setMenus: (menus: JsonType) => void
    footer: JsonType
    setFooter: (footer: JsonType) => void
    sections: JsonType
    routesConfig: {
        baseUrl: string
        routes: TRoute[]
    }
    getSectionConfig: (section: string) => any
    previewUrl?: string
    loading: boolean
    setLoading: (loading: boolean) => void
}

export const ThemeBuilderContext = createContext({
    home: null,
    setHome: () => null,
    theme: {},
    setTheme: () => null,
    images: {},
    navigation: {},
    setNavigation: () => null,
    setImages: () => null,
    scripts: {},
    setScripts: () => null,
    configs: {},
    setConfigs: () => null,
    menus: {},
    setMenus: () => null,
    footer: {},
    setFooter: () => null,
    sections: {},
    routesConfig: {
        baseUrl: '',
        routes: []
    },
    getSectionConfig: () => {},
    setLoading: () => null,
    loading: false
} as ThemeBuilderContextProps)

export const useThemeBuilderContext = () => useContext(ThemeBuilderContext)

interface IThemeBuilderProviderProps {
    children: React.ReactNode
    routesConfig: {
        baseUrl: string
        routes: TRoute[]
    }
    previewUrl?: string
}

export const ThemeBuilderProvider = ({
    children,
    routesConfig,
    previewUrl
}: IThemeBuilderProviderProps) => {
    const [theme, setTheme] = useState<JsonType>(DEFAULT_THEME)
    const [images, setImages] = useState<JsonType>({})
    const [scripts, setScripts] = useState<JsonType>({})
    const [footer, setFooter] = useState<JsonType>({})
    const [configs, setConfigs] = useState<JsonType>({})
    const [home, setHome] = useState<JsonType>(null)
    const [menus, setMenus] = useState<JsonType>(null)
    const [navigation, setNavigation] = useState<JsonType>(null)
    const [sections, setSections] = useState<JsonType | null>(null)
    const [loading, setLoading] = useState(false)

    const getSectionConfig = useCallback(
        (section: string) => {
            if (sections?.[section]) {
                return {
                    title: sections[section].title,
                    subTitle: sections[section].subTitle,
                    sections: sections[section].sections
                }
            }

            return null
        },
        [sections]
    )

    useEffect(() => {
        if (routesConfig?.routes && !sections) {
            const _sections = routesConfig?.routes.reduce((acc, section) => {
                acc[section.name] = section?.sections
                    ? {
                          title: section.title,
                          subTitle: section.subTitle,
                          sections: section?.sections || null
                      }
                    : null
                return acc
            }, {})
            setSections(_sections)
        }
    }, [routesConfig])

    return (
        <ThemeBuilderContext.Provider
            value={{
                home,
                setHome,
                menus,
                setMenus,
                theme,
                setTheme,
                images,
                setImages,
                navigation,
                setNavigation,
                scripts,
                setScripts,
                configs,
                setConfigs,
                footer,
                setFooter,
                sections,
                getSectionConfig,
                previewUrl,
                loading,
                setLoading,
                routesConfig: {
                    baseUrl: routesConfig.baseUrl,
                    routes: routesConfig.routes.map((route) => {
                        const { name, path, exact, home, title } = route
                        return {
                            name,
                            path,
                            exact,
                            home,
                            title
                        }
                    })
                }
            }}
        >
            {children}
        </ThemeBuilderContext.Provider>
    )
}

