import React from 'react'
import Icon from '@ant-design/icons'

const GraphSvg = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 8.25H5V14.25H0.5V8.25ZM5.75 0.75H10.25V14.25H5.75V0.75ZM11 4.5H15.5V14.25H11V4.5Z" fill="#FBFCFF"/>
    </svg>
)

export const IconGraph = (props) => <Icon component={GraphSvg} {...props} />
