import React, { useState } from 'react'
import { Progress, message, Select, Divider, Button } from 'antd'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import { Upload } from './styles'

import { usePageBuilderContext } from '../../apps/events/pages/Config/PageBuilder/context/context'
import { useMemo } from 'react'

import axios from 'axios'

const uploadClient = axios.create({
    baseURL: process.env.APIGW_UPLOAD_ENDPOINT
})

uploadClient.interceptors.request.use((config) => {
    const token = localStorage.getItem('@td-token')
    if (token) {
        const db = window.location.hostname.split('.')[0]
        config.headers['X-Customer-Id'] = db === 'localhost' ? 'platform' : db
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
})

function beforeUpload(file) {
    const isImage = file.type.indexOf('image/') === 0
    if (!isImage) {
        message.error('Arquivo de imagem inválido!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
        message.error('Imagem maior que 2MB!')
    }
    return isImage && isLt2M
}

const Uploader = ({ value, onChange, options }) => {
    const [fileList, setFileList] = useState([])
    const [loading, setLoading] = useState(false)
    const [progress, setProgress] = useState(false)
    const [_props, setProps] = useState()
    const { model } = usePageBuilderContext()

    const modelFields = useMemo(() => {
        return model?.fields.filter((field) => {
            return field.type == 'image'
        })
    }, [model])

    const formatFile = (file) => {
        if (file.response) {
            file.uid = file.response.id
            file.url = file.response.url
            file.name = file.response.filename
        }

        return {
            uid: file.uid,
            url: file.url,
            type: file.type,
            name: file.name,
            response: file.response,
            status: file.status
        }
    }

    const handleChange = (info) => {
        let _fileList = [...info.fileList]

        if (info.file.status === 'done') {
            _fileList = _fileList.slice(-1)
            _fileList = _fileList.map(formatFile)
            onChange(_fileList[0].response.url)
            message.success(
                `${_fileList[0].response.filename} carregado com sucesso`
            )
        }
        if (info.file.status === 'error') {
            message.error(`${info.file.name} erro ao carregar.`)
        }

        setFileList(_fileList)
    }

    const handleRemove = () => {
        onChange(null)
    }

    const handleUpload = async ({ onSuccess, onError, file, onProgress }) => {
        setLoading(true)

        try {
            const optionsArr = []

            if (options && options.optimize) {
                const { quality, resize } = options.optimize
                if (quality) {
                    optionsArr.push(`quality=${quality}`)
                }
                if (resize?.height) {
                    optionsArr.push(`resize.height=${resize.height}`)
                }
                if (resize?.width) {
                    optionsArr.push(`resize.width=${resize.width}`)
                }
            }
            const res = await uploadClient.post(
                `/upload${optionsArr.length ? `?${optionsArr.join('&')}` : ''}`,
                file,
                {
                    headers: {
                        ['Content-Type']: file.type
                    },
                    onUploadProgress: (progressEvent) => {
                        const percent = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total
                        )
                        setProgress(percent)
                        onProgress({ percent })
                    }
                }
            )
            onSuccess({
                _id: res.data._id,
                filename: res.data.filename,
                url: res.data.url
            })
        } catch (error) {
            console.log(error)
            onError(error.message)
        }

        setLoading(false)
        setProgress(false)

        // const result = await uploadMedia({
        //     variables: { file, options },
        //     context: {
        //         fetchOptions: {
        //             useUpload: true,
        //             onProgress: (e) => {
        //                 const percent = Math.floor((e.loaded / e.total) * 100)

        //                 setProgress(percent)
        //                 onProgress({ percent })
        //             }
        //         }
        //     }
        // })

        // if (result.data && result.data.uploadMedia) {
        //     setLoading(false)
        //     setProgress(false)
        //     onSuccess(result.data.uploadMedia)
        // } else {
        //     setLoading(false)
        //     setProgress(false)
        //     onError(result.errors[0].message)
        // }
    }

    const uploadButton = (
        <div>
            {/* {loading ? <LoadingOutlined /> : } */}
            {progress ? (
                <Progress
                    type="circle"
                    percent={progress}
                    strokeColor={{
                        '0%': '#2BD069',
                        '100%': '#2BD069'
                    }}
                />
            ) : (
                <>
                    <PlusOutlined />
                    <div className="ant-upload-text">Upload</div>
                    {modelFields?.length > 0 && (
                        <>
                            <Divider>Ou</Divider>
                            <Select
                                style={{ width: '200px' }}
                                placeholder="Seleciona uma variavel"
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                                onChange={(value) => {
                                    onChange(`{{ref.${value}}}`)
                                }}
                            >
                                {modelFields?.map((field) => (
                                    <Select.Option value={field.name}>
                                        {field.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </>
                    )}
                </>
            )}
        </div>
    )

    return (
        <>
            <Upload
                name="file"
                listType="picture-card"
                accept="image/*"
                className="avatar-uploader"
                showUploadList={false}
                customRequest={handleUpload}
                onChange={handleChange}
                onRemove={handleRemove}
                beforeUpload={beforeUpload}
                style={{ width: '100%' }}
            >
                {value && !loading ? (
                    value?.indexOf('{{') === 0 ? (
                        <div>
                            <div className="ant-upload-text">
                                Variavel Utilizada: {value}
                            </div>
                            <br />
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleRemove()
                                }}
                            >
                                <DeleteOutlined /> Remover
                            </Button>
                        </div>
                    ) : (
                        <>
                            <img
                                className="banner"
                                src={value}
                                alt="avatar"
                                style={{ height: '100%' }}
                            />
                            <Button
                                className="delete-button"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleRemove()
                                }}
                            >
                                <DeleteOutlined /> Remover
                            </Button>
                        </>
                    )
                ) : (
                    uploadButton
                )}
            </Upload>
            {/* {value && !loading ? (
                <Row justify="end" style={{ padding: '10px 0' }}>
                    <DeleteOutlined onClick={handleRemove} />
                </Row>
            ) : null} */}
        </>
    )
}

export default Uploader
