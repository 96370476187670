
import React from 'react'
import { FormsLead } from './styles'

const Form = ({children}) => {
    return (
        <FormsLead >      
            { children }
        </FormsLead>
  )
}

export default Form