import React from 'react'
import { Typography, Progress, Row, Empty } from 'antd'

import { TotalNumber, Percent, WrapperLines, StyledRow } from './styles'

const { Text, Paragraph } = Typography

const CompareLines = ({ title, values }) => {
    const valuesAux = values.map((obj) => obj.value)
    const max = Math.max(...valuesAux)

    const major = values[valuesAux.indexOf(max)]

    return (
        <>
            {/* <Paragraph style={{ color: '#fff', marginBottom: '0' }}>{major.name || ''}</Paragraph>
            <StyledRow>
                <TotalNumber style={{ color: '#fff', marginRight: '10px' }}>{major.value || ''} </TotalNumber>
                <Percent style={{ color: '#fff' }}>{major.percent || '0%'}</Percent>
            </StyledRow> */}
            {/* <Paragraph>{title}</Paragraph> */}
            {values.length > 0 ? (
                <WrapperLines>
                    {values.map((value, index) => (
                        <div style={{ marginBottom: '4px' }}>
                            <Row justify="space-between">
                                <Text style={{ color: '#fff' }}>
                                    {value.name}
                                </Text>
                                <Text style={{ color: '#fff' }} strong>
                                    {value.value}
                                </Text>
                            </Row>
                            <Progress
                                key={index}
                                percent={Number(value.percent.replace('%', ''))}
                                format={() =>
                                    `${Number(value.percent.replace('%', ''))}%`
                                }
                                size="small"
                                showInfo={false}
                                success={{
                                    percent: Number(
                                        value.percent.replace('%', '')
                                    ),
                                    strokeColor: '#7730F6'
                                }}
                            />
                        </div>
                    ))}
                </WrapperLines>
            ) : (
                <Empty description="Não há dados" />
            )}
        </>
    )
}

export default CompareLines

