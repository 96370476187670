export const LABELS = {
    phone: 'Mascara de telefone',
    document: 'Mascara de CPF/CNPJ'
}

export const OPTIONS = {
    phone: [
        {
            label: 'Nenhuma',
            value: ''
        },
        {
            label: '(99) 9999-9999(BR)',
            value: '(99) 9999-9999'
        },
        {
            label: '(99) 99999-9999(BR)',
            value: '(99) 99999-9999'
        },
        {
            label: '+99 (99) 99999-9999(BR)',
            value: '+99 (99) 99999-9999'
        },
        {
            label: '+99 999-999-9999(US)',
            value: '+99 999-999-9999'
        }
    ],
    document: [
        {
            label: 'Cpf/Cnpj',
            value: 'cpfCnpj'
        },
        {
            label: 'Cpf',
            value: 'cpf'
        },
        {
            label: 'Cnpj',
            value: 'cnpj'
        }
    ]
}
