import React from 'react'
import Reward from '../Reward'

import { Row, Col, Typography } from 'antd'
const { Title, Paragraph } = Typography

const Step6 = () => {
    const title = 'Tops do Referenciador'
    return (
        <>
            <Row gutter={16}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        {title}
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}></Paragraph>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Reward
                                title={title}
                                reference={'topRewards'}
                                pointLabel="Top"
                                topReward={true}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Step6
