import styled from 'styled-components'
import { Layout } from 'antd'
import { ContentDefault } from '../Content/styles'

const { Content } = Layout

interface MainContentProps {
    $hasScrollbar: boolean
}

export const MainContent = styled(Content)<MainContentProps>`
    overflow-y: auto;
    overflow-x: hidden;
    ${ContentDefault} {
        ${({ $hasScrollbar }) => $hasScrollbar && 'margin-right: 10px;'}
    }
`
