
import styled from 'styled-components'
import { Modal } from 'antd'


export const ModalStyled = styled(Modal)`
    .step-hide {
        display: none;
    }
`
