import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { Checkbox, Col, Row, Typography } from 'antd'
import { useQuery } from '@apollo/client'
import { MessageOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'

import { CrudList } from '@cms/core/components/Crud'

import { GET_CHAT_MESSAGES } from '@cms/events/graphql/queries/chat'

import {
    ChatElements,
    ChatWrapper,
    IconWrapper,
    InnerChatWrapper
} from './styles'
import ChatItem from './ChatItem'
import ChatOptions from './ChatOptions'
import { ChatMessageInput } from './ChatInput'

const { Text } = Typography

const useRefCallback = (initialValue, fn, deps = []) => {
    const ref = useRef(initialValue)
    const setRef = useCallback(
        (node) => {
            if (node !== null) {
                ref.current = node
                fn(node)
            }
        },
        [fn, ...deps]
    )
    return [ref, setRef]
}

const Chat = ({ talk, token }) => {
    const [initialized, setInitialized] = useState(false)
    const [scrollIsDown, setScrollIsDown] = useState(false)

    const refContainer = useRef(null)

    const [message, setMessage] = useState('')

    const scrollDown = useCallback(() => {
        ref.current.scrollTo(-1)
        setScrollIsDown(true)
    }, [ref, setScrollIsDown])

    const variables = {
        channel: {
            type: 'TALK',
            id: talk
        }
    }

    const query = useQuery(GET_CHAT_MESSAGES, {
        variables,
        notifyOnNetworkStatusChange: false,
        fetchPolicy: 'cache-first'
    })

    const channel = useMemo(() => {
        if (!window.TD_INTERNAL?.WebSocketChannel) {
            return null
        }
        return new window.TD_INTERNAL.WebSocketChannel({
            name: 'chat',
            identify: talk,
            token
        })
    }, [talk, token])

    useEffect(() => {
        if (!channel) {
            return
        }
        const onMessage = ({ actionData, sender }) => {
            query.updateQuery((prev) => {
                return {
                    ...prev,
                    chatMessages: {
                        ...prev.chatMessages,
                        totalCount: prev.chatMessages.totalCount + 1,
                        edges: [
                            ...prev.chatMessages.edges,
                            {
                                __typename: 'ChatMessageEdge',
                                node: {
                                    __typename: 'ChatMessage',
                                    id: actionData.uid,
                                    uid: actionData.uid,
                                    user: {
                                        id: sender.userId,
                                        name: sender.name,
                                        avatar: {
                                            url: sender.avatar
                                        }
                                    },
                                    message: actionData.message
                                }
                            }
                        ]
                    }
                }
            })

            setTimeout(() => {
                scrollDown()
            }, 1000)
        }

        channel.on('message', onMessage)

        const onDelete = ({ actionData }) => {
            query.updateQuery((prev) => {
                const newEdges = prev.chatMessages.edges.filter(
                    (edge) => edge.node.uid !== actionData.uid
                )
                return {
                    ...prev,
                    chatMessages: {
                        ...prev.chatMessages,
                        totalCount:
                            prev.chatMessages.totalCount -
                            (prev.chatMessages.edges.length - newEdges.length),
                        edges: newEdges
                    }
                }
            })
        }

        channel.on('delete', onDelete)

        channel.connect()

        return () => {
            channel.leave()
            channel.off('message', onMessage)
            channel.off('delete', onDelete)
        }
    }, [query.updateQuery])

    const [ref, setRef] = useRefCallback(
        null,
        (node) => {
            if (node && !initialized && !query.loading) {
                ref.current.scrollTo(-1)
                setScrollIsDown(true)
                setInitialized(true)
            }
        },
        [query.loading]
    )

    const columns = [
        {
            title: 'Message',
            dataIndex: '',
            render: (value, node) => {
                return <ChatItem node={value?.node} />
            }
        },
        {
            title: 'Opções',
            dataIndex: '',
            width: '40px',
            render: (value) => {
                return (
                    <ChatOptions
                        popupContainer={refContainer.current}
                        node={value?.node}
                    />
                )
            }
        }
    ]

    const sendMessage = useCallback(() => {
        if (!message) return

        const uuid = uuidv4()

        channel.send('message', {
            message,
            uid: uuid
        })

        setMessage('')

        setTimeout(() => {
            scrollDown()
        }, 1000)
    }, [channel, message])

    return (
        <ChatWrapper ref={refContainer}>
            <ChatElements>
                <Row style={{ marginBottom: '20px' }} align="middle">
                    <IconWrapper>
                        <MessageOutlined style={{ color: '#000' }} />
                    </IconWrapper>
                    <Text style={{ marginLeft: '10px' }}>Chat</Text>
                </Row>
                <InnerChatWrapper>
                    <CrudList
                        header={{}}
                        columns={columns}
                        queryName={'chatMessages'}
                        hideAction={'all'}
                        showHeader={false}
                        search={false}
                        transparent
                        //ref={setRef}
                        scrollY={500}
                        reverse={true}
                        height={'100%'}
                        {...query}
                        refVT={setRef}
                    />
                </InnerChatWrapper>

                <ChatMessageInput
                    value={message}
                    onSend={sendMessage}
                    onChange={setMessage}
                />
            </ChatElements>
        </ChatWrapper>
    )
}

export default Chat
