import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button } from 'antd'

import { Preview } from '../../../components'

const PreviewModal = ({ data, setPreviewData }) => {
    const modalRef = useRef(null)
    const previewRef = useRef(null)

    const onInit = () => {
        previewRef.current.Emit('fullPage', data)
        previewRef.current.handleParentWidth(modalRef.current.offsetWidth)
    }

    return (<>
        <Modal
            open={true}
            onCancel={() => setPreviewData(null)}
            footer={null}
            width={800}
        >
            <div ref={modalRef} style={{ width: '100%' }}>
                <Preview ref={previewRef} onInit={onInit} page="home" />
            </div>
        </Modal>
    </>);
}

export default PreviewModal
