import styled from 'styled-components'

import { Card } from 'antd'

export const CustomCard = styled(Card)`
    background-color: transparent !important;

    border-color: ${({ theme }) => theme.color.backgroundDarkLight};

    margin-bottom: 1.4rem;
`

