import { ORDER_BY_NAME } from '@cms/core/components/Segment/OrderBy/constants'
import { OPTIONS_VALUE } from './options'

import { SectionsType } from '@cms/watch/pages/Config/constants/initialData'

export const COURSES_CONFIGS = {
    courses: {
        type: SectionsType.courses,
        label: 'Curso',
        defaultValues: {},
        fields: {
            _id: {
                label: 'Cursos',
                type: 'select',
                options: OPTIONS_VALUE.courses
            }
        },
        orderBy: {
            ...ORDER_BY_NAME
        }
    },
    coursesInProgress: {
        type: SectionsType.courses,
        label: 'Curso em progresso',
        defaultValues: {},
        fields: {},
        orderBy: {
            ...ORDER_BY_NAME
        }
    }
}

