import styled from 'styled-components'

export const Wrapper = styled.div`
    display: contents;

    .ant-table-wrapper {
        padding-bottom: 20px;
    }

    .ant-table-body {
        max-height: 57vh !important;
    }
`
