import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Frame } from './style'
import Template from '@td/email-template/render'

import { template } from '../TDLoops'

const Iframe = ({ data }) => {
    const frameRef = useRef(null)

    const handleLoad = useCallback(
        (content) => {
            const FrameHTML = Template.Render({data:content, template: 'TdLoops'})

            if (!frameRef.current) return;

            const frameDocument = frameRef.current.contentWindow.document
            frameDocument.open()
            frameDocument.write(FrameHTML)
            frameDocument.close()
        },
        []
    )

    useEffect(() => {
        if (data) {
            handleLoad({ ...data, media: data?.media?.url ? data.media.url : '' })
        }
    }, [data])

    return (
        <Frame id="email-preview" src='about:blank' ref={frameRef}>

        </Frame>
    )

}

export default Iframe