import React from 'react'
import { Divider, Form, Input, Select } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../../components'
import { Wrapper, Title } from '../../../styles'
import SelectSearch from '@cms/core/components/SelectSearch'

import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_FAQ_TOPICS_LIST_ORDERABLE } from '@cms/events/graphql/queries/faqTopics'
import RichTextEditor from '../../../components/RichTextEditor/Editor'

const Faq = (props) => {
    const { eventId } = useParams()

    const { data: TopicsQuestion } = useQuery(GET_FAQ_TOPICS_LIST_ORDERABLE, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Tópico das perguntas</Title>
                <Form.Item
                    name={[props.name, 'configs', 'faq', 'topics']}
                    label="Escolha o tópico das perguntas"
                >
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Escolha o tópico das perguntas"
                    >
                        {TopicsQuestion?.faqTopics?.edges?.map((faq) => (
                            <Select.Option
                                key={faq.node.id}
                                value={faq.node.id}
                            >
                                {faq.node.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Divider orientation="left">Definição de cores</Divider>
                <Form.Item
                    name={[props.name, 'configs', 'faqbg', 'bgColor']}
                    label="Cor do bloco"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'faqtext', 'textColor']}
                    label="Cor dos tópicos"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'question', 'textColor']}
                    label="Cor da pergunta"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'answer', 'textColor']}
                    label="Cor da resposta"
                >
                    <ColorsBadges />
                </Form.Item>
            </Wrapper>

            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default Faq
