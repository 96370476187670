import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, message } from 'antd'

import { useQuery, useMutation } from '@apollo/client'

import { GET_EVENT_CHECKOUT } from '@cms/events/graphql/queries/event'
import { UPDATE_SITE } from '@cms/events/graphql/mutations/site'

import { Nav } from '@cms/events/pages/Config/Checkout/components'
import { usePageBuilderContext } from '@cms/events/pages/Config/Checkout/context/context'
import { PageBuilderRoutes } from '@cms/events/pages/Config/Checkout/routes'

import Loader from '@cms/core/components/Loader'

const PageBuilder = () => {
    const { eventId } = useParams()
    const { push } = useHistory()
    const {
        setColors,
        setSite,
        site,
        setConfig,
        config,
        setLoading,
        event,
        setEvent
    } = usePageBuilderContext()

    const [updateSite] = useMutation(UPDATE_SITE)

    const { error, data: dataEvent } = useQuery(GET_EVENT_CHECKOUT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    const setupPageBuilder = () => {
        if (dataEvent) {
            if (!event) {
                setEvent(dataEvent.event)
            }

            if (!site) {
                const { draft } = dataEvent.event.checkout

                if (draft) {
                    setColors(draft.theme.colors)

                    setSite(draft)
                    setConfig(dataEvent.event.checkout)
                } else push(`/evento/${eventId}/config/checkout/templates`)
            }
        }
    }

    useEffect(() => {
        setupPageBuilder()
    }, [dataEvent])

    if (!dataEvent) return <Loader fixed={false} padding='10px' />
    if (error) return `Error! ${error.message}`

    const onFormFinish = async (name, { values, forms }) => {
        const currentValues = forms[name].getFieldsValue()
        const { id } = config
        let fields = {}
        setLoading(true)

        if (currentValues.sections) {
            setColors(currentValues.colors)

            fields = {
                ...site,
                pages: {
                    ...site.pages,
                    [name]: {
                        ...site.pages[name],
                        sections: currentValues.sections
                    }
                }
            }
            message.success('Página atualizada com sucesso!')
        } else {
            setColors(currentValues.colors)

            fields = {
                ...site,
                [name]: { ...currentValues }
            }
            message.success('Configurações atualizadas com sucesso!')
        }

        setSite(fields)

        await updateSite({
            variables: {
                id: id,
                draft: fields
            }
        })
        setLoading(false)
    }

    return (
        <>
            {site ? (
                <>
                    <Nav />
                    <Form.Provider onFormFinish={onFormFinish}>
                        <PageBuilderRoutes />
                    </Form.Provider>
                </>
            ) : null}
        </>
    )
}

export default PageBuilder
