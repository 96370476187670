import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { GET_CUSTOM_TRACKER_LIST_ORDENABLE } from '@cms/events/graphql/queries/trackers'

import {
    Row,
    Col,
    Typography,
    Select,
    Form,
    Button,
    InputNumber,
    Input,
    Spin
} from 'antd'
const { Title, Paragraph } = Typography

const systemTriggers = [
    {
        name: 'Fazer login no evento',
        event: 'login', //ok
        description: 'Fazer login no evento'
    },
    {
        name: 'Baixou certificado',
        event: 'certificateDownload', //ok
        description: 'Baixar certificado'
    },
    {
        name: 'Avaliou um conteúdo',
        event: 'talkRated', //ok
        description: 'Avaliar uma palestra'
    },
    {
        name: 'Assistir um conteúdo',
        event: 'talkWatch', //ok
        description: 'Assistir palestras (pontos por palestra assistida)'
    },
    {
        name: 'Clicou na CTA do chat em uma palestra',
        event: 'talkAdvertised', //ok
        description:
            'Clicar nas publicidades no chat da palestra (pontos por publicidade clicada)'
    },
    {
        name: 'Clicou em algum banner',
        event: 'bannerClick', //ok
        description: 'Clicar em algum banner (pontos por banners clicados)'
    },
    {
        name: 'Adicionou uma foto no perfil',
        event: 'profileAddPhoto', //ok
        description: 'Adicionar foto no perfil'
    },
    {
        name: 'Responder um formulário de coleta adicional de dados',
        event: 'formAnswer',
        description:
            'Responder formulário de coleta de dados (pontos por formulários respondidos)'
    },
    {
        name: 'Visitar um patrocinador',
        event: 'brandView', //ok
        description:
            'Visitar o stand do patrocinador (pontos por stands visitados)'
    },
    {
        name: 'Clicar na CTA de patrocinador',
        event: 'brandAction', //ok
        description: 'Clicar na CTA do patrocinador (pontos por CTA clicada)'
    }
]

const TriggerSelect = ({
    triggers = [],
    selectedTriggers = [],
    handleSelectTrigger,
    fieldName,
    ...props
}) => {
    return (
        <SelectSearch
            {...props}
            mode="single"
            placeholder="Selecione um gatilho"
            onSelect={() => {
                handleSelectTrigger(fieldName)
            }}
            virtual={false}
        >
            <Select.OptGroup label="Nativos">
                {triggers
                    .filter((o) => !o.custom)
                    .map((trigger) => (
                        <Select.Option
                            style={{
                                display: selectedTriggers.includes(
                                    trigger.event
                                )
                                    ? 'none'
                                    : ''
                            }}
                            key={trigger.event}
                            value={trigger.event}
                        >
                            {trigger.name}
                        </Select.Option>
                    ))}
            </Select.OptGroup>

            <Select.OptGroup label="Customizados">
                {triggers
                    .filter((o) => o.custom)
                    .map((trigger) => (
                        <Select.Option
                            style={{
                                display: selectedTriggers.includes(
                                    trigger.event
                                )
                                    ? 'none'
                                    : ''
                            }}
                            key={trigger.event}
                            value={trigger.event}
                        >
                            {trigger.name}
                        </Select.Option>
                    ))}
            </Select.OptGroup>
        </SelectSearch>
    )
}

const EngagementTriggers = ({ form }) => {
    const [selectedTriggers, setSelectedTriggers] = useState([])
    const [triggers, setTriggers] = useState([])

    const { loading, error, data } = useQuery(
        GET_CUSTOM_TRACKER_LIST_ORDENABLE,
        {
            variables: {
                filter: { active: true }
            }
        }
    )

    useEffect(() => {
        if (data) {
            const _triggers = [...systemTriggers]

            data.customTrackers.edges.forEach(({ node }) => {
                _triggers.push({
                    name: node.name,
                    event: node.eventName,
                    description: node.description,
                    custom: true
                })
            })

            setTriggers(_triggers)
        }
    }, [data])

    const handleSelectTrigger = (index) => {
        const _triggers = form.getFieldValue('triggers') || []

        form.setFields([
            {
                name: ['triggers', index, 'description'],
                value: triggers.find((t) => t.event === _triggers[index]?.event)
                    ?.description,
                errors: []
            }
        ])

        setSelectedTriggers(_triggers.map((trigger) => trigger.event))
    }

    const handleRemoveSelectTrigger = () => {
        setSelectedTriggers(
            form.getFieldValue('triggers').map((trigger) => trigger.event)
        )
    }

    useEffect(() => {
        const _triggers = form.getFieldValue('triggers') || []
        setSelectedTriggers(_triggers.map((trigger) => trigger.event))
    }, [form])

    if (loading) return <Spin />

    return (
        <>
            <Row gutter={16}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        Gatilhos
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}></Paragraph>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.List name="triggers">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`triggers.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={6}>
                                                        <Form.Item
                                                            label="Gatilho"
                                                            name={[
                                                                field.name,
                                                                'event'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Gatilho é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <TriggerSelect
                                                                fieldName={
                                                                    field.name
                                                                }
                                                                triggers={
                                                                    triggers
                                                                }
                                                                selectedTriggers={
                                                                    selectedTriggers
                                                                }
                                                                handleSelectTrigger={
                                                                    handleSelectTrigger
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Pontos"
                                                            name={[
                                                                field.name,
                                                                'points'
                                                            ]}
                                                            initialValue={1}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Pontuação é obrigatória!'
                                                                }
                                                            ]}
                                                        >
                                                            <InputNumber
                                                                min={0}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Descrição"
                                                            required={false}
                                                            name={[
                                                                field.name,
                                                                'description'
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <MinusCircleOutlined
                                                            style={{
                                                                marginTop: 20
                                                            }}
                                                            onClick={() => {
                                                                remove(
                                                                    field.name
                                                                ),
                                                                    handleRemoveSelectTrigger(
                                                                        selectedTriggers[
                                                                            field
                                                                                .name
                                                                        ]
                                                                    )
                                                            }}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}

                                        {selectedTriggers.length !==
                                        triggers.length ? (
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        add({})
                                                    }}
                                                    style={{ width: '100%' }}
                                                >
                                                    <PlusOutlined /> Adicionar
                                                </Button>
                                            </Form.Item>
                                        ) : null}
                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default EngagementTriggers
