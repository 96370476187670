import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ formItem?: any }>`
    ${({ formItem }) =>
        formItem &&
        css`
            margin-top: -39px;
            .colors-group {
                margin-top: 20px;
            }
        `}

    .colors-group {
        margin-bottom: 20px;
    }
    
    .ant-divider {
        border-color: ${({ theme }) => theme.color.backgroundDarkLight};
    }
`

export const ColorBadge = styled.div`
    margin-top: 21px;
    width: 38px;
    height: 38px;
    border-radius: 10px;
    border: 3px solid #fff;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    background-color: ${({ color }) => color};
    position: relative;

    .popover {
        position: fixed;
        z-index: 60;
        transition: opacity 0.2s ease-in-out;
        opacity: 0;
        border-radius: 9px;
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
`
