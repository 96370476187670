import styled, { css } from 'styled-components'
import { COLORS } from '@cms/core/themes'
import { TDCard } from '@cms/core/components/TDCards'
import { lighten } from 'polished'

interface CardWrapperProps {
    selectType?: boolean
}

export const CardWrapper = styled(TDCard)<CardWrapperProps>`
    padding: 20px;
    border-radius: 11px;
    border: 1px solid ${COLORS.bgLight};
    position: relative;
    display: block;
    transition: border-color 0.2s ease-out;
    margin-top: 10px;
    cursor: pointer;
    white-space: initial;
    height: 100%;

    ${({ selectType }) =>
        selectType &&
        css`
            display: flex;
            flex-direction: column;
            align-items: center;
        `}

    h4.ant-typography {
        color: ${COLORS.text};
    }

    &:hover {
        opacity: 0.9;
        border-color: ${COLORS.active};
    }
`

export const CardNew = styled(TDCard)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 100%;
    min-height: 200px;
    padding: 20px;
    border-radius: 11px;
    background: ${lighten(0.1, COLORS.bgLight)};
    border: 1px solid ${COLORS.bgLight};
    position: relative;
    transition: border-color 0.2s ease-out;
    margin-top: 10px;
    cursor: pointer;
    white-space: initial;

    .ant-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ant-btn {
        margin-bottom: 8px;
    }

    &:hover {
        opacity: 0.6;
        border-color: ${COLORS.active};
    }
`

export const CardLogo = styled.img`
    padding-bottom: 15px;
    margin: 0 auto;
    min-height: 45px;
    max-height: 45px;
`
