import React from 'react'
import { Route } from 'react-router-dom'
import { useModulePermissions } from '../Providers/ModulePermissionProvider'

export const CreateModalRoutes = (
    Component,
    path,
    params = {},
    { ignoreId = false, returnPath } = {}
) => {
    const viewOrEditPath = ignoreId ? path : `${path}/:id`
    const RouterComponent = ({ ...rest }) => {
        const { hasActions } = useModulePermissions()
        if (hasActions('write')) {
            return (
                <>
                    <Route
                        exact
                        path={`${path}/adicionar`}
                        render={() => (
                            <Component
                                path={returnPath || path}
                                params={params}
                                mode="write"
                                {...rest}
                            />
                        )}
                    />

                    <Route
                        exact
                        path={`${viewOrEditPath}/editar`}
                        render={() => (
                            <Component
                                path={returnPath || viewOrEditPath}
                                params={params}
                                mode="write"
                                {...rest}
                            />
                        )}
                    />
                </>
            )
        }

        if (hasActions('read')) {
            return (
                <>
                    <Route
                        exact
                        path={`${viewOrEditPath}/visualizar`}
                        render={() => (
                            <Component
                                path={returnPath || viewOrEditPath}
                                params={params}
                                mode="view"
                                {...rest}
                            />
                        )}
                    />
                </>
            )
        }

        return null
    }

    RouterComponent.Custom = (props) => <Route {...props} />

    return RouterComponent
}

