import { gql } from '@apollo/client'
import { BillingPlanData } from '../fragments'

export const CREATE_BILLING_PLAN = gql`
    mutation CreateBillingPlan(
        $name: String
        $fullAccess: Boolean
        $modules: [String]!
    ) {
        createBillingPlan(
            input: { name: $name, fullAccess: $fullAccess, modules: $modules }
        ) {
            ...BillingPlanData
        }
    }
    ${BillingPlanData}
`

export const UPDATE_BILLING_PLAN = gql`
    mutation UpdateBillingPlan(
        $id: ObjectID!
        $name: String
        $fullAccess: Boolean
        $modules: [String]!
    ) {
        updateBillingPlan(
            id: $id
            input: { name: $name, fullAccess: $fullAccess, modules: $modules }
        ) {
            ...BillingPlanData
        }
    }
    ${BillingPlanData}
`

export const DELETE_BILLING_PLAN = gql`
    mutation deleteBillingPlan($id: ObjectID!) {
        deleteBillingPlan(id: $id) {
            id
            success
        }
    }
`
