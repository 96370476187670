import { createStore } from 'redux'

const INITIAL_STATE = {
    user: {},
    customer: {},
    selectedEvent: null,
    meta: {},
    events: [],
    showModalEvent: false,
    collapseSide: false
}

interface Action {
    type: string
    data: any
    eventId?: string
}

function reduces(state = INITIAL_STATE, action: Action) {
    switch (action.type) {
        case 'SET_META':
            return {
                ...state,
                meta: action.data
            }
        case 'CHANGE_EVENT':
            return {
                ...state,
                eventSelected: action.data
            }
        case 'SET_EVENTS':
            return {
                ...state,
                events: action.data
            }
        case 'ADD_EVENT':
            return {
                ...state,
                events: [...state.events, action.data]
            }
        case 'SHOW_MODAL_EVENT':
            return {
                ...state,
                showModalEvent: action.eventId || true
            }
        case 'HIDE_MODAL_EVENT':
            return {
                ...state,
                showModalEvent: false
            }
        case 'SET_USER':
            return {
                ...state,
                user: action.data
            }
        case 'SET_CUSTOMER':
            return {
                ...state,
                customer: action.data
            }
        case 'SET_COLLAPSE':
            return {
                ...state,
                collapseSide: action.data
            }
        default:
            return state
    }
}

const store = createStore(reduces)

export default store
