import React from 'react'

import BaseConfig from '../BaseConfig'
import Segment from '@cms/core/components/Segment'
import { EXPERTS_CONFIGS } from '@cms/core/components/Segment/constants/experts'

interface IExpertsProps {
    name: string
}

export function Experts({ name }: IExpertsProps) {
    return (
        <>
            <BaseConfig name={name} title={true} />
            <Segment
                name={[name, 'config']}
                type="experts"
                filters={EXPERTS_CONFIGS}
                configs={{
                    formName: 'sections'
                }}
            />
        </>
    )
}
