import React from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Button, Layout, Row, Col, Form, Input, message } from 'antd'

import { CHANGE_PASSWORD } from '@cms/core/graphql/mutations/auth'
import { Box } from './styles'
import Content from '@cms/core/components/Content'

import { useCrudForm } from '@cms/core/components/Crud'
import { TDCardForForm } from '@cms/core/components/TDCards'
import { PageHeader } from '@ant-design/pro-layout'

export default function ChangePassword() {
    const [changePassword] = useMutation(CHANGE_PASSWORD)

    const [{ form, formSave, saveLoading }] = useCrudForm(
        {
            id: 'me',
            getData: async () => {
                return {}
            },
            updateData: async (id, formData) => {
                const variables = {
                    password: formData.password
                }
                await changePassword({ variables })
                message.success('Senha alterada com sucesso')
            }
        },
        []
    )

    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Usuário"
                subTitle="Alterar senha"
            />
            <Form
                layout="vertical"
                form={form}
                onFinish={formSave}
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <TDCardForForm
                            actions={[
                                <div>
                                    <Button
                                        block
                                        type="primary"
                                        htmlType="submit"
                                        loading={saveLoading}
                                    >
                                        Alterar senha
                                    </Button>
                                </div>
                            ]}
                        >
                            <Form.Item
                                name="password"
                                label="Nova Senha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Digite a senha.'
                                    },
                                    {
                                        min: 6,
                                        message:
                                            'A senha deve ter no mínimo 6 caracteres.'
                                    }
                                ]}
                                hasFeedback
                            >
                                <Input type="password" placeholder="Senha" />
                            </Form.Item>
                            <Form.Item
                                name="repeat-password"
                                label="Confirme a senha"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Digite a senha novamente'
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (
                                                !value ||
                                                getFieldValue('password') ===
                                                    value
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                'A senhas não conferem'
                                            )
                                        }
                                    })
                                ]}
                                dependencies={['password']}
                                hasFeedback
                            >
                                <Input
                                    type="password"
                                    placeholder="Insira sua senha novamente"
                                />
                            </Form.Item>
                        </TDCardForForm>
                    </Col>
                </Row>
            </Form>
        </>
    )
}
