import React from 'react'

import { useParams } from 'react-router-dom'

import {
    DashboardCard,
    SocialNetworks,
    SocialMetrics,
    Ranking
} from '@cms/events/components/Dashboard'

import { Row, Col } from 'antd'

import { StarOutlined } from '@ant-design/icons'
import {
    DashboardCardTitle,
    DashboardTitle,
    IconWrapper
} from '@cms/events/components/Dashboard/styles'
import { TDDashboardCard } from '@cms/core/components/TDCards'

const Sponsors = () => {
    const { eventId } = useParams()

    return (
        <>
            <DashboardCardTitle>
                <IconWrapper>
                    <StarOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Patrocinadores</DashboardTitle>
            </DashboardCardTitle>

            <Row gutter={[24, 24]}>
                <Col span={24}>
                    <SocialMetrics eventId={eventId} />
                </Col>

                <Col span={24}>
                    <Row gutter={[24, 24]}>
                        <Col lg={12} sm={12} xs={12} md={12}>
                            <TDDashboardCard>
                                <Ranking eventId={eventId} />
                            </TDDashboardCard>
                        </Col>

                        <Col lg={12} sm={12} xs={12} md={12}>
                            <TDDashboardCard>
                                <SocialNetworks eventId={eventId} />
                            </TDDashboardCard>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Sponsors
