import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { Space } from 'antd'

import { CrudColumns, CrudList } from '@cms/core/components/Crud'
import { ButtonLink } from '@cms/events/components/Buttons'

import { GET_CATEGORY_LIST_ORDERABLE } from '@cms/events/graphql/queries/category'
import { DELETE_CATEGORY } from '@cms/events/graphql/mutations/category'
import { removeFromList } from '@cms/core/graphql/utils'
import Nav from '../../Nav'

import dayjs from 'dayjs'

const filterFields = [{ name: 'name', label: 'Nome', queryType: 'Filter' }]

const columns = [
    {
        title: 'Nome',
        dataIndex: ['node', 'name'],
        sorter: 'name',
        render: (value, obj) => {
            return (
                <CrudColumns
                    image={obj.node?.icon}
                    title={obj.node.name}
                    text={[
                        `Palestras vinculadas: ${obj.node.talksTotalCount || 0}`
                    ]}
                />
            )
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const actionsExtra = {
    dataIndex: '',
    key: 'x',
    width: 150,
    align: 'right',
    render: (_, record) => {
        const id = record.node.id

        return (
            <Space>
                <ButtonLink
                    id={id}
                    path={`dashboard`}
                    key={id}
                    text={'Dashboard'}
                />
            </Space>
        )
    }
}

const TrailsList = () => {
    const { eventId } = useParams()
    const type = 'MAIN'

    const path = `/evento/${eventId}/categories`

    const query = useQuery(GET_CATEGORY_LIST_ORDERABLE, {
        variables: {
            first: 25,
            eventId,
            orderBy: { createdAt: 'DESC' },
            type
        }
    })

    const [deleteData] = useMutation(DELETE_CATEGORY, {
        update: removeFromList({ list: 'categories', Type: 'Category' })
    })

    if (query.error) return <div>Error</div>

    return (
        <CrudList
            header={{
                title: 'Trilhas',
                countNames: {
                    singular: 'Trilha',
                    plural: 'Trilhas'
                },
                subTitle: 'Adicione e gerencie as trilhas',
                buttons: [
                    {
                        children: 'Nova Trilha',
                        type: 'primary',
                        link: path + '/adicionar'
                    }
                ]
            }}
            search={true}
            columns={columns}
            queryName={'categories'}
            onDelete={deleteData}
            actionsExtra={actionsExtra}
            filterFields={filterFields}
            contentHeader={<Nav />}
            showHeader
            {...query}
        />
    )
}

export default TrailsList

