export const FIELDS = {
    speakers: {
        configs: {
            color: '#000',
            shape: 'rounded',
            logo: 'white'
        }
    },
    sponsors: {
        configs: {
            shape: 'rounded',
            color: '#000',
            logo: 'white'
        }
    },
    schedule: {
        configs: {
            font: 'Montserrat',
            variant: 'padrão',
            title: 'Confira os horários \ndas palestras e os palestrantes',
            titleTextcolor: '#000000',
            boxShape: 'rounded',
            boxBgcolor: '#000000',
            boxTextcolor: '#ffffff'
        }
    },
    form: {
        configs: {
            btn: 'Retire seu ingresso',
            'btn-shape': 'circle',
            highlight: '#22D66A',
            'highlight-text': '#000',
            textcolor: '#000',
            shape: 'rounded',
            redirect: 'https://example.com/'
        }
    },
    viral: {
        configs: {
            'btn-shape': 'circle',
            highlight: '#22D66A',
            'highlight-text': '#000',
            textcolor: '#000',
            shape: 'rounded',
            redirect: 'https://example.com/'
        }
    },
    rewards: {
        configs: {
            box: false,
            shape: 'rounded',
            color: '#ffffff',
            indications: '#D222D6',
            rewards: '#000'
        }
    }
}

export const LABELS = {
    speakers: 'Palestrantes',
    sponsors: 'Patrocinadores',
    schedule: 'Agenda',
    forms: 'Formulários',
    viral: 'Get Member',
    rewards: 'Recompensas'
}
