import { GET_BRAND_PLAN_LIST_ORDENABLE } from '@cms/events/graphql/queries/brandPlan'
import { GET_STAGES } from '@cms/events/graphql/queries/talk'
import { GET_BRAND_LIST } from '@cms/events/graphql/queries/brand'

export const QUERY_FILTER_OPTIONS_VALUE = {
    stages: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_STAGES,
            variables: {
                eventId,
                orderBy: { createdAt: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data?.talks?.edges.map((item) => ({
                    label: item.node.title,
                    value: item.node.id
                }))) ||
            []
        return parsedData
    },
    brandPlans: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_BRAND_PLAN_LIST_ORDENABLE,
            variables: {
                first: 1000,
                orderBy: { createdAt: 'DESC' }
            }
        })

        const parsedData =
            (data &&
                data?.brandPlans?.edges.map((item) => ({
                    label: item.node.name,
                    value: item.node.id
                }))) ||
            []
        return parsedData
    },
    brands: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_BRAND_LIST,
            variables: {
                eventId,
                orderBy: { priority: 'DESC' }
            }
        })

        const parsedData =
            (data &&
                data?.brands?.edges.map((item) => ({
                    label: item.node.name,
                    value: item.node.name
                }))) ||
            []
        return parsedData
    }
}

