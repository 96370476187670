import React from 'react'
import { Input, Select } from 'antd'
import { getIcon } from './utils'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_EVENT } from '@cms/events/graphql/queries/event'
import { StyledOption, WrapperInoutTranslation } from './styles'

interface IInputTranslation {
    value?: any
    onChange?: (props) => void
    mode?: string;
}

export const languagesLabels = {
    'pt-BR': 'Português/BR',
    'pt-PT': 'Português/PT',
    'en-US': 'Inglês',
    'es-ES': 'Espanhol'
}

const defaultLanguages = ['pt-BR', 'es-ES', 'en-US']

const InputTranslation: React.FC<IInputTranslation> = ({ value, onChange, mode = 'input' }) => {
    const [currentTranslation, setCurrentTranslation] = React.useState(null)
    const [languages, setLanguages] = React.useState(null)

    const { eventId } = useParams<any>()

    const { data } = useQuery(GET_EVENT, {
        variables: { id: eventId }
    })

    const handleChange = (e) => {
        onChange({
            ...value,
            [currentTranslation]: e.target.value
        })
    }

    React.useEffect(() => {
        if (data?.event?.translation?.languages?.length) {
            setLanguages(data.event.translation.languages)
            setCurrentTranslation(data.event.translation.languages[0])
        } else {
            setLanguages(defaultLanguages)
            setCurrentTranslation(defaultLanguages[0])
        }
    }, [data])

    if (!languages) return null

    const SelectLanguage = (
        <Select
            onChange={(value) => setCurrentTranslation(value)}
            value={currentTranslation}
        >
            {languages.map((lang, index) => (
                <Select.Option value={lang} key={index}>
                    <StyledOption>
                        <img
                            style={{
                                width: '20px'
                            }}
                            src={getIcon(lang)}
                        />
                        {mode === 'textArea' && (
                            <span style={{ marginLeft: '20px' }}>
                                {languagesLabels[lang]}
                            </span>
                        )}
                    </StyledOption>
                </Select.Option>
            ))}
        </Select>
    )

    if (mode === 'textArea') {
        return (
            <WrapperInoutTranslation className="input-translation--textArea">
                {SelectLanguage}
                <Input.TextArea
                    value={value?.[currentTranslation]}
                    onChange={handleChange}
                />
            </WrapperInoutTranslation>
        )
    }

    return (
        <WrapperInoutTranslation>
            <Input
                value={value?.[currentTranslation]}
                onChange={handleChange}
                addonBefore={SelectLanguage}
            />
        </WrapperInoutTranslation>
    )
}

export default InputTranslation
