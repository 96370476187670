import React from 'react'
import styled from 'styled-components'

export const BadgeStyled = styled.div`
    width: 70px;
    height: 30px;
    border-radius: 2px;
    background: ${props => props.color};
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    ` 
export const Bagde = (props) => {
    return(
        <BadgeStyled name="BadgeStyled" {...props} />
    )
}