import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import useMenu from '../hooks/useMenu'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { LockOutlined } from '@ant-design/icons'

import {
    IsEnablePermissions,
    selectPlanModuleskey
} from '@cms/core/components/Sidebar/common'
import { AllAppsModules } from '@cms/core/Apps'

export default function Menu({ menu, children }) {
    const { eventId } = useParams()

    const planModules = useSelector((state) =>
        selectPlanModuleskey(state, AllAppsModules)
    )

    const {
        selectedMenus,
        mainMenu: _mainMenu,
        secondMenu: _secondMenu
    } = useMenu(menu)

    const parseToMenuItems = (data, showSubmenu = false) => {
        if (!data) return []
        return data.map((item) => {
            const base = {
                label: (
                    <>
                        <Link
                            to={
                                item.path?.replace(':eventId', eventId) ||
                                '/404'
                            }
                        >
                            {item.name}{' '}
                        </Link>
                        {IsEnablePermissions(item, menu) &&
                            !planModules.includes(item.module) && (
                                <span
                                    className="menu-locked"
                                    style={{ float: 'right' }}
                                >
                                    <LockOutlined />
                                </span>
                            )}
                    </>
                ),
                title: item.name,
                key: item.module,
                icon: item.icon ? (
                    <Link
                        to={item.path?.replace(':eventId', eventId) || '/404'}
                    >
                        {selectedMenus.includes(item.module)
                            ? item.icon.filled
                            : item.icon.outline}
                    </Link>
                ) : null,
                target: item.path?.replace(':eventId', eventId) || '/404'
            }
            if (item.children?.length && showSubmenu) {
                return {
                    ...base,
                    children: parseToMenuItems(item.children)
                }
            }
            return base
        })
    }

    const { mainMenu, mainMenuFooter } = useMemo(() => {
        if (!_mainMenu) return []

        const menus = _mainMenu.reduce((acc, item) => {
            return {
                ...acc,
                [item.type || 'main']: [
                    ...(acc[item.type || 'main'] || []),
                    item
                ]
            }
        }, {})

        return {
            mainMenu: parseToMenuItems(menus.main),
            mainMenuFooter: parseToMenuItems(menus?.footer)
        }
    }, [_mainMenu, eventId])

    const secondMenu = useMemo(() => {
        if (!_secondMenu) return []
        return parseToMenuItems(_secondMenu)
    }, [_secondMenu, eventId])

    const firstItemSelected = selectedMenus[0] === mainMenu[0]?.key

    return children({
        mainMenu,
        mainMenuFooter,
        secondMenu,
        selectedMenus,
        firstItemSelected
    })
}
