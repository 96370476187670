import React from 'react'

import Integration from '@cms/core/components/Integrations/components/Integration'
import { PageHeader } from '@ant-design/pro-layout';

const config = {
    category: 'players',
    unique: true,
    appType: 'EVENTS'
}

const PlayersIntegrations = () => {
    return (
        <>
            <PageHeader
                className="site-page-header"
                title="Integrações de players"
                subTitle="Habilite players para a transmissão do seu evento"
            />
            <Integration config={config} />
        </>
    )
}

export default PlayersIntegrations
