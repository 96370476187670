import React from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { useCreation } from '@umijs/hooks'

import {
    CrudList,
    CreateModalRoutes,
    CrudColumns
} from '@cms/core/components/Crud'

import { GET_COUPON_LIST_ORDENABLE } from '@cms/events/graphql/queries/coupons'
import { DELETE_COUPON } from '@cms/events/graphql/mutations/coupons'
import CouponModal from './CouponModal'

import dayjs from 'dayjs'

const columns = [
    {
        title: 'Código',
        dataIndex: '',
        render: (_, obj) => {
            return <CrudColumns onlyText text={[obj.node.code]} />
        }
    },
    {
        title: 'Desconto',
        dataIndex: '',
        render: (_, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[obj.node.discountPercentage + '%']}
                />
            )
        }
    },
    {
        title: 'Ativo',
        dataIndex: '',
        render: (_, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[obj.node.active ? 'Ativo' : 'Inativo']}
                />
            )
        }
    },
    {
        title: 'Data de criação',
        dataIndex: ['node', 'createdAt'],
        sorter: 'createdAt',
        render: (_value, obj) => {
            return (
                <CrudColumns
                    onlyText
                    text={[dayjs(_value).format('DD/MM/YYYY [-] H:mm')]}
                />
            )
        }
    }
]

const CouponList = () => {
    const { eventId } = useParams()

    const variables = { first: 25, eventId, orderBy: { createdAt: 'DESC' } }

    const path = `/evento/${eventId}/cupons`

    const query = useQuery(GET_COUPON_LIST_ORDENABLE, {
        variables
    })

    const [deleteData] = useMutation(DELETE_COUPON, {
        refetchQueries: [{ query: GET_COUPON_LIST_ORDENABLE, variables }]
    })

    const ModalRoutes = useCreation(() => {
        return CreateModalRoutes(CouponModal, path, { eventId })
    }, [eventId])

    if (query.error) return <div>Error: {query.error}</div>

    const filterFields = [
        { name: 'code', label: 'Código', queryType: 'Filter' }
    ]

    return (
        <>
            <ModalRoutes />
            <CrudList
                header={{
                    title: 'Cupons de Desconto',
                    subTitle: 'Adicione e gerencie os cupons de desconto',
                    buttons: [
                        {
                            children: 'Novo Cupom',
                            type: 'primary',
                            link: path + '/adicionar'
                        }
                    ]
                }}
                search={true}
                columns={columns}
                queryName={'ticketCoupons'}
                onDelete={deleteData}
                filterFields={filterFields}
                showHeader
                {...query}
            />
        </>
    )
}

export default CouponList

