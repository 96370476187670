import React from 'react'
import Reward from '../../Reward'

import { Row, Col, Typography } from 'antd'
const { Title, Paragraph } = Typography

const EngagementRewards = () => {
    const title = 'Recompensas'
    return (
        <>
            <Row gutter={16}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        {title}
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}></Paragraph>

                    <Row gutter={16}>
                        <Col span={24}>
                            <Reward
                                title={title}
                                reference={'rewards'}
                                pointLabel="Pontos"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default EngagementRewards
