import React from 'react'
import { Switch, Redirect, Route } from 'react-router-dom'

import PrivateRoute from '@cms/core/routes/privateRoute'

import Events from '../pages/Events'
import Locations from '../pages/Location'
import Categories from '../pages/Categories'
// import Playlists from '../pages/Playlists'
import Speakers from '../pages/Speakers'
import Talks from '../pages/Talks'
import OnDemand from '../pages/OnDemand'
import Stage from '../pages/Stage/'
import Brands from '../pages/Brands'
import BrandsDashboard from '../pages/Brands/BrandsDashboard'
import BrandsDraft from '../pages/Brands/BrandDraft'
import Banners from '../pages/Banners'
import Notifications from '../pages/Notifications'
import Referal from '../pages/Referal'
import Engagement from '../pages/Engagement'
import Info from '../pages/Config/Info'
import Ticket from '../pages/Tickets'
import Orders from '../pages/Orders'
import Domains from '../pages/Config/Domains'
import PageBuilder from '../pages/Config/PageBuilder'
import Checkout from '../pages/Config/Checkout'
import Widgets from '../pages/Config/Widgets'
import Certificate from '../pages/Config/Certificate'
import Integrations from '../pages/Config/Integrations'
import Terms from '../pages/Config/Terms'
import HashTags from '../pages/Config/Hashtags'
import Streaming from '../pages/Config/Streaming'

import FormBuilder from '../pages/Forms/FormBuilder'
import FormProgressive from '../pages/Forms/FormProgressive'
import FormProgressiveEdit from '../pages/Forms/FormProgressive/edit'

import FormOther from '../pages/Forms/FormOthers'
import FormOtherEdit from '../pages/Forms/FormOthers/edit'

import Subscriptions from '../pages/Subscriptions'
import FormDataList from '../pages/FormData'
import Reports from '../pages/Reports'
import Faq from '../pages/Faq'
import FaqTopics from '../pages/FaqTopics'
import FaqQuestions from '../pages/FaqQuestions'
import CollectionCategoryList from '../pages/CollectionCategories'
import CollectionItemList from '../pages/CollectionItems'

import CategoriesDashboard from '../pages/Categories/Dashboard'
import Coupon from '../pages/Coupons'
import CheckoutConfig from '../pages/Checkout'
import OnDemandDashboard from '../pages/OnDemand/Dashboard'
import StageDashboard from '../pages/Stage/Dashboard'
import TalkDashboard from '../pages/Talks/Dashboard'

// prettier-ignore
export default function routes() {

    return (
        <Switch>
            <Route
                exact
                path={`/evento/:eventId`}
                render={({ match }) => (
                    <Redirect
                        to={`/evento/${match.params.eventId}/dashboard`}
                    />
                )}
            />
            <PrivateRoute
                checkModulePath
                
                path={`/evento/:eventId/dashboard`}
                module={'event.dashboard'}
                component={Events}
            />
            <PrivateRoute
                checkModule={'events.event.contect.categories'}
                path={`/evento/:eventId/categories/:id/dashboard`}
                component={CategoriesDashboard}
            />
            <PrivateRoute
                checkModule={'events.event.contect.categories'}
                path={`/evento/:eventId/categories/playlist/:id/dashboard`}
                component={CategoriesDashboard}
            />
            <PrivateRoute
                checkModule={'events.event.contect.categories'}
                path={`/evento/:eventId/categories/series/:id/dashboard`}
                component={CategoriesDashboard}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/locais`}
                component={Locations}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/categories`}
                component={Categories}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/palestrantes`}
                component={Speakers}
            />
            <PrivateRoute
                path={`/evento/:eventId/palestras/:id/dashboard`}
                component={TalkDashboard}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/palestras`}
                component={Talks}
            />
            <PrivateRoute
                path={`/evento/:eventId/videos/:id/dashboard`}
                component={OnDemandDashboard}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/videos`}
                component={OnDemand}
            />
            <PrivateRoute
                path={`/evento/:eventId/palcos/:id/dashboard`}
                component={StageDashboard}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/palcos`}
                component={Stage}
            />
            <PrivateRoute
                path={`/evento/:eventId/patrocinadores/:id/dashboard`}
                component={BrandsDashboard}
            />
            <PrivateRoute
                checkModule="events.event.content.sponsors"
                path={`/evento/:eventId/patrocinadores/:id/draft`}
                component={BrandsDraft}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/patrocinadores`}
                component={Brands}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/notificacoes`}
                component={Notifications}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/banners`}
                component={Banners}
            />
            <PrivateRoute
                checkModule="events.event.member-get-member"
                path={`/evento/:eventId/referal`}
                component={Referal}
            />
            <PrivateRoute
                checkModule="events.event.engagement"
                path={`/evento/:eventId/engajamento`}
                component={Engagement}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/reports`}
                component={Reports}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/info`}
                component={Info}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/ingressos`}
                component={Ticket}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/checkout/config`}
                component={CheckoutConfig}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/cupons`}
                component={Coupon}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/pedidos`}
                component={Orders}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/paginas`}
                component={PageBuilder}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/checkout`}
                component={Checkout}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/widgets`}
                component={Widgets}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/dominio`}
                component={Domains}
            />
            <PrivateRoute
                checkModule="events.event.integrations"
                path={`/evento/:eventId/integracoes`}
                component={Integrations}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/streaming/certificado`}
                component={Certificate}
            />
            <PrivateRoute
                checkModule="events.event.streaming.config"
                path={`/evento/:eventId/streaming/config`}
                component={Streaming}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/termos`}
                component={Terms}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/config/hashtags`}
                component={HashTags}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/inscricoes`}
                component={Subscriptions}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/form-data/:uuid`}
                component={FormDataList}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/faq/topicos`}
                component={FaqTopics}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/faq/perguntas`}
                component={FaqQuestions}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/faq/geral`}
                component={Faq}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/colecoes/categorias`}
                component={CollectionCategoryList}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/colecoes/itens`}
                component={CollectionItemList}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/forms/inscricao`}
                component={FormBuilder}
            />
            <PrivateRoute
                checkModule="events.event.forms.progressive"
                path={`/evento/:eventId/forms/progressive/:formId/editar`}
                component={FormProgressiveEdit}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/forms/progressive`}
                component={FormProgressive}
            />
            <PrivateRoute
                checkModule="events.event.forms.others"
                path={`/evento/:eventId/forms/others/:formId/editar`}
                component={FormOtherEdit}
            />
            <PrivateRoute
                checkModulePath
                path={`/evento/:eventId/forms/others`}
                component={FormOther}
            />
        </Switch>
    )
}
