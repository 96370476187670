import styled from 'styled-components'
import { Col } from 'antd'
import { Wrapper as DefaultWrapper } from '../styles'

export const ColForm = styled(Col)`
    background-color: rgba(255, 255, 255, 0.2);
    padding: 16px;
    border-radius: 20px;

    .list-label {
        font-family: HelveticaNowRegular, sans-serif;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.85);
    }

    .affix-form-footer {
        margin-left: -12px;
        margin-right: -12px;
    }
`

export const Wrapper = styled(DefaultWrapper)`
    margin-bottom: 20px;
    background-color: transparent;
    border: 2px solid rgba(228, 236, 255, 0.1);
`
