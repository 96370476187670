import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { usePageBuilderContext } from '../../context/context'
import { getColor } from '../../utils'

import { Badge, Checkbox } from './styles'

const ColorsBadges = (props) => {
    const { colors } = usePageBuilderContext()
    const [colorsOptions, setColorsOptions] = useState()

    useEffect(() => {
        if (colors) {
            const _colors = []

            Object.keys(colors).forEach(key => _colors.push(
                { value: key, label: <Badge background={getColor(colors[key])} /> }
            ))

            setColorsOptions(_colors)
        }
    }, [colors])

    return (
        <>
            {colorsOptions
                ? (
                    <Checkbox options={colorsOptions} {...props} />
                )
                : null
            }
        </>
    )
}

export default ColorsBadges