import React from 'react'

import { Button, Typography } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { CardNew } from './styles'

const { Paragraph } = Typography

import * as _connectors from '../connectores/configs'

const CardNewItem = (props) => {
    return (
        <CardNew newIntegration {...props}>
            <Button type="default" icon={<PlusOutlined />} />
            <Paragraph>Nova integração</Paragraph>
        </CardNew>
    )
}

export default CardNewItem
