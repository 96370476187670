import transform from 'lodash/transform'
import isObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
// import { ApolloClient } from '@apollo/client'

// import { useQuery } from '@apollo/client'
import { useApolloClient } from '@apollo/client'
import { GET_EVENT_FIELDS } from '@cms/events/graphql/queries/event'

export const removeObjectProps = (obj, keysMap) => {
    return transform(obj, (result, value, key) => {
        if (keysMap.includes(key)) return
        if (Array.isArray(value)) {
            return (result[key] = value.map((o) => {
                return isObject(o) ? removeObjectProps(o, keysMap) : o
            }))
        }
        result[key] = isObject(value)
            ? removeObjectProps(value, keysMap)
            : value
    })
}

export const getFieldLabel = async (eventId, field) => {
    const client = useApolloClient()

    const { data } = await client.query({
        query: GET_EVENT_FIELDS,
        variables: { id: eventId }
    })

    if (data) {
        const targetField = data.event.fields.filter(
            (item) => item.name === field
        )

        return targetField[0].label
    }
}

export const resolveRelateds = (values, relateds) => {
    return values
    // const checkRelateds = (data, prefix = '') => {
    //     Object.keys(data).forEach((item) => {
    //         if (relateds.indexOf(prefix + item) >= 0 && data[item]) {
    //             data[item] = data[item].id
    //         } else if (
    //             data[item] &&
    //             (typeof data[item] === 'object' ||
    //                 typeof data[item] === 'array')
    //         ) {
    //             checkRelateds(data[item], prefix + item + '.')
    //         }
    //     })
    // }

    // let result = merge({}, { ...values })

    // checkRelateds(result)

    // return result
}
