import React, { useState } from 'react'
import { Divider, Button, Modal, Card, Row, Col, Typography, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { HiddenArea } from './styles'
import { useSectionBuilder } from '../../context'

interface Props {
    index: number
    emptySections?: boolean
    add?: (section: any, index: number) => void
}

const AddSection = ({ emptySections, index, add }: Props) => {
    const [sectionsModalParams, setSectionsModalParams] = useState(false)
    const { sections } = useSectionBuilder()

    const showModal = () => {
        setSectionsModalParams(true)
    }

    const handleCancel = () => {
        setSectionsModalParams(false)
    }

    const setNewSection = (section) => {
        const defaultSectionConfig = section.defaultConfig

        const newSection = {
            name: section.label,
            type: section.name,
            active: true,
            config: defaultSectionConfig || {
                title: section.label,
                condition: {
                    type: 'AND',
                    filters: []
                }
            }
        }
        if (add) {
            add(newSection, index)
        }
        setSectionsModalParams(false)
    }

    const sectionsByCategory = Object.keys(sections).reduce((acc, section) => {
        const category = sections[section].category
        if (!acc[category]) {
            acc[category] = []
        }
        acc[category].push(sections[section])
        return acc
    }, {})

    return (
        <>
            {emptySections ? (
                <Button type="primary" onClick={showModal}>
                    Adicionar
                </Button>
            ) : (
                <HiddenArea onClick={showModal}>
                    <Divider plain dashed className="divider">
                        {<PlusOutlined />}
                    </Divider>
                </HiddenArea>
            )}

            <Modal
                title="Escolha uma seção"
                open={sectionsModalParams}
                onCancel={handleCancel}
                footer={null}
                width={1200}
            >
                {Object.keys(sectionsByCategory).map((section, index) => {
                    return (
                        <Row
                            gutter={[16, 16]}
                            style={{ marginBottom: '32px' }}
                            key={index}
                        >
                            <Col span={24}>
                                <div>{section}</div>
                            </Col>
                            {sectionsByCategory[section].map((item, i) => {
                                const sectionTitle = item.label
                                return (
                                    <Col span={8} key={i}>
                                        <Card
                                            title={
                                                <Space>
                                                    <Typography.Text>
                                                        {sectionTitle}
                                                    </Typography.Text>
                                                </Space>
                                            }
                                            onClick={() => setNewSection(item)}
                                        >
                                            <Typography.Paragraph>
                                                {item.description}
                                            </Typography.Paragraph>
                                            <Divider />
                                            <Row
                                                gutter={[16, 16]}
                                                justify="end"
                                            >
                                                <Button type="primary">
                                                    Adicionar
                                                </Button>
                                            </Row>
                                        </Card>
                                    </Col>
                                )
                            })}
                        </Row>
                    )
                })}
            </Modal>
        </>
    )
}

export default AddSection

