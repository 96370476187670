import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import Text from 'antd/es/typography/Text'
import { CheckCircleFilled } from '@ant-design/icons'
import { Footer, ModalConfirmationContent } from '../styles'
import { useMutation } from '@apollo/client'
import { IMPORT_SITE_SETTINGS } from '@cms/events/graphql/mutations/site'
import { useHistory, useParams } from 'react-router-dom'

const warning = require('./warning.png')

const ConfirmationModal = ({ event, onCancel }) => {
    const history = useHistory()
    const { eventId } = useParams()

    const [importSettings] = useMutation(IMPORT_SITE_SETTINGS)

    const [loadingPageTemplate, setLoadingPageTemplate] = useState(false)

    const onImportTemplate = () => {
        setLoadingPageTemplate(true)

        importSettings({
            variables: {
                sourceEventId: event.id,
                targetEventId: eventId
            }
        })
            .then(() => {
                history.push(`/evento/${eventId}/paginas`)
            })
            .catch(() => {
                console.log('Error')
            })
            .finally(() => {
                setLoadingPageTemplate(false)
                onCancel()
            })
    }

    return (
        <>
            <Modal open={true} footer={null} width={450} onCancel={onCancel}>
                <div style={{ width: '100%' }}>
                    <Row style={{ marginTop: '12px' }}>
                        <Col sm={4}>
                            <img width="70px" src={warning} />
                        </Col>
                        <Col sm={20}>
                            <ModalConfirmationContent>
                                <Text>
                                    Você está importando as configuações e
                                    estilos da transmissão do evento:
                                    <br />
                                    <Text strong>{event.name}</Text>
                                </Text>
                            </ModalConfirmationContent>
                        </Col>
                    </Row>

                    <Footer align="center">
                        <Button
                            type="primary"
                            icon={<CheckCircleFilled />}
                            onClick={onImportTemplate}
                            loading={loadingPageTemplate}
                        >
                            Confirmar
                        </Button>
                        <Button
                            type="ghost"
                            onClick={onCancel}
                            disabled={loadingPageTemplate}
                        >
                            Cancelar
                        </Button>
                    </Footer>
                </div>
            </Modal>
        </>
    )
}

export default ConfirmationModal
