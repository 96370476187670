import React, { useState, useContext } from 'react'
import Context from '../context'

import { Row, Col, Typography, Form, Input, Switch } from 'antd'
import { Social, SocialRow } from './styles'
import {
    FacebookOutlined,
    LinkedinOutlined,
    WhatsAppOutlined,
    MailOutlined,
    CopyOutlined,
    TwitterOutlined
} from '@ant-design/icons'

const { Title, Paragraph } = Typography
const channelsTypes = {
    whatsapp: false,
    twitter: false,
    email: false
}

const Step4 = ({}) => {
    const { form } = useContext(Context)
    const [channels, setChannels] = useState(channelsTypes)

    const handleActive = (e, type) => {
        setChannels({ ...channels, [type]: e })
    }

    const whatsapp = form.getFieldValue(['social', 'whatsapp', 'active'])
    const email = form.getFieldValue(['social', 'email', 'active'])
    const twitter = form.getFieldValue(['social', 'twitter', 'active'])

    const handleCopy = () => console.log('copied')

    return (
        <>
            <Row gutter={16}>
                <Col lg={24} md={24} sm={24} xs={24}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        Compatilhar campanha
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}>
                        Compartilhe com seus amigos e comece já
                    </Paragraph>

                    <Row gutter={(16, 16)}>
                        <Col span={8}>
                            <Social className="social-switch">
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <WhatsAppOutlined />{' '}
                                    <span style={{ marginLeft: '20px' }}>
                                        WhatsApp
                                    </span>
                                </Col>
                            </Social>

                            <SocialRow>
                                <Form.Item
                                    name={['social', 'whatsapp', 'text']}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Texto de compartilhamento é obrigátório!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Configure uma mensagem" />
                                </Form.Item>
                            </SocialRow>
                        </Col>

                        <Col span={8}>
                            <Social className="social-switch">
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <TwitterOutlined />{' '}
                                    <span style={{ marginLeft: '20px' }}>
                                        Twitter
                                    </span>
                                </Col>
                            </Social>

                            <SocialRow>
                                <Form.Item
                                    name={['social', 'twitter', 'text']}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Texto de compartilhamento é obrigátório!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Configure uma mensagem" />
                                </Form.Item>
                            </SocialRow>
                        </Col>
                        <Col span={8}>
                            <Social className="social-switch">
                                <Col span={24} style={{ textAlign: 'left' }}>
                                    <MailOutlined />{' '}
                                    <span style={{ marginLeft: '20px' }}>
                                        E-mail
                                    </span>
                                </Col>
                            </Social>

                            <SocialRow>
                                <Form.Item
                                    name={['social', 'email', 'text']}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Texto de compartilhamento é obrigátório!'
                                        }
                                    ]}
                                >
                                    <Input placeholder="Configure uma mensagem" />
                                </Form.Item>
                            </SocialRow>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Step4
