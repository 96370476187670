import React from 'react'
import { Button, Form, Switch, Popconfirm, message } from 'antd'
import {
    CaretUpOutlined,
    CaretDownOutlined,
    DeleteOutlined,
    EyeOutlined,
    EyeInvisibleOutlined,
    EditOutlined
} from '@ant-design/icons'

import { Row, CustomInput } from './styles'
import { debounce } from 'lodash'

interface Props {
    index: number
    checked: boolean
    activePanel: number
    setSection: (section: number | null) => void
    sectionName?: string
    move: (from: number, to: number) => void
    editable: boolean
    remove: (index: number) => void
}

const PanelHeader = ({
    index,
    checked,
    activePanel,
    setSection,
    sectionName = 'sections',
    move,
    editable,
    remove
}: Props) => {
    const form = Form.useFormInstance()
    const size = form.getFieldsValue()[sectionName]?.length || 0

    const handleClickUp = (e) => {
        e.stopPropagation()
        e.preventDefault()
        move(index, index - 1)
        message.success('Seção movida com sucesso!')
        form.submit()
    }

    const handleClickDown = (e: any) => {
        e.stopPropagation()
        e.preventDefault()
        move(index, index + 1)
        message.success('Seção movida com sucesso!')
        form.submit()
    }

    const onChecked = (e) => {
        if (e) {
            message.success('Seção ativada com sucesso!')
        } else {
            message.success('Seção desativada com sucesso!')
        }
        form.submit()
    }

    const removeSection = (index) => {
        remove(index)
        form.submit()
    }

    const cancel = (e) => {}

    const handleTitleChange = (e, index) => {
        form.submit()
        message.success('Salvo com sucesso!')
    }

    const enableUpClick = index > 0
    const enableDownClick = index < size - 1

    return (
        <Row style={{ width: '100%' }} justify="space-between" align="middle">
            <Row align="middle" justify="start">
                <div className="move-section">
                    {enableUpClick && (
                        <CaretUpOutlined onClick={handleClickUp} />
                    )}
                    {enableDownClick && (
                        <CaretDownOutlined onClick={handleClickDown} />
                    )}
                </div>

                <Form.Item
                    className="panel-header"
                    name={[index, 'name']}
                    valuePropName="value"
                >
                    <CustomInput
                        bordered={false}
                        addonAfter={<EditOutlined />}
                        placeholder="Escolha um Título"
                        onChange={() => debounce(handleTitleChange, 1000)}
                    />
                </Form.Item>
            </Row>

            <Row
                align="middle"
                justify="space-between"
                style={{ width: '185px' }}
            >
                {index >= 0 && (
                    <Form.Item
                        className="panel-header"
                        name={[index, 'active']}
                        initialValue={checked}
                        valuePropName="checked"
                    >
                        <Switch
                            checked={checked}
                            checkedChildren={<EyeOutlined />}
                            unCheckedChildren={<EyeInvisibleOutlined />}
                            onChange={onChecked}
                            size="small"
                        />
                    </Form.Item>
                )}
                {editable ? (
                    <>
                        {index === activePanel ? (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSection(null)
                                }}
                            >
                                Fechar
                            </Button>
                        ) : (
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setSection(index)
                                }}
                            >
                                Editar
                            </Button>
                        )}
                    </>
                ) : (
                    <span style={{ width: '67.42px' }}></span>
                )}
                <Popconfirm
                    title="Você realmente quer remover esta seção?"
                    onConfirm={() => removeSection(index)}
                    onCancel={cancel}
                    okText="Sim"
                    cancelText="Não"
                >
                    <a href="#">
                        <DeleteOutlined />
                    </a>
                </Popconfirm>
            </Row>
        </Row>
    )
}

export default PanelHeader

