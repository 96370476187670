import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_NOTIFICATION_LIST = gql`
    query GetNotifications(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID
    ) {
        notifications(
            first: $first
            after: $afterCursor
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...NotificationData
                }
            }
        }
    }

    ${fragments.NotificationData}
`

export const GET_NOTIFICATION_LIST_ORDERABLE = gql`
    query GetNotifications(
        $first: Int
        $afterCursor: Cursor
        $eventId: ObjectID!
        $orderBy: NotificationOrderBy
        $search: String
        $title: Filter
    ) {
        notifications(
            first: $first
            after: $afterCursor
            filter: { event: $eventId, title: $title }
            search: $search
            orderBy: $orderBy
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...NotificationData
                }
            }
        }
    }

    ${fragments.NotificationData}
`

export const GET_NOTIFICATION = gql`
    query GetNotification($id: ObjectID!) {
        notification(id: $id) {
            ...NotificationData
        }
    }

    ${fragments.NotificationData}
`

