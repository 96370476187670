import React from 'react'

import { Link } from 'react-router-dom'

import { Content } from './styles'
import dayjs from 'dayjs'
import { CalendarOutlined } from '@ant-design/icons'

const EventCard = function ({ event }) {
    const formatDate = (date) => dayjs.utc(new Date(date)).format('DD/MM/YYYY')

    const startDate = formatDate(event.startDate)
    const endDate = formatDate(event.endDate)

    const Image = () => {
        if (event.logo)
            return <img alt={`${event.name} Logo`} src={event.logo.url} />
        return null
    }

    const eventLink = () => `/evento/${event.id}/dashboard`

    return (
        <Content hoverable bodyStyle={{ padding: 10 }}>
            <Link to={eventLink()}>
                <Image />
                <div className="event-info">
                    <p className="event-name">{event.name}</p>
                    <p className="event-date">
                        <CalendarOutlined /> {startDate} {'>'} {endDate}
                    </p>
                </div>
            </Link>
        </Content>
    )
}

export default EventCard
