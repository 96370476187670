import React, { useState, useEffect } from 'react'
import { Editor } from '@tinymce/tinymce-react'

import { EditorWrapper } from './styles'

const EditorHTML = ({ onChange, value, toolbar, ...rest }) => {
    return (
        <EditorWrapper>
            <Editor
                tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@5.5.1/tinymce.min.js"
                disabled={false}
                id="uuid"
                init={{ menubar: false }}
                initialValue=""
                inline={false}
                onEditorChange={onChange}
                plugins="link code lists"
                tagName="div"
                textareaName=""
                toolbar={
                    'undo redo | styleselect | forecolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link | code'
                }
                value={value}
                {...rest}
            />
        </EditorWrapper>
    )
}

export default EditorHTML

