import styled from 'styled-components'
import { Row, Menu } from 'antd'
import { Link } from 'react-router-dom'
import { COLORS } from '@cms/core/themes'
import { lighten } from 'polished'
import Affix from '@cms/core/components/Affix'

export const LinkStyled = styled(Link)`
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
    padding: 8px 15px;

    &:hover {
        color: ${({ theme }) => theme.color.activeColors};
    }
`
export const CustomMenu = styled(Menu)`
    ul.ant-dropdown-menu {
        padding: 7px 0px;
    }

    li.active-item {
        background-color: rgba(255, 255, 255, 0.08);
    }

    .ant-dropdown-menu-item-group {
        padding-bottom: 10px;
    }
`

export const HrefStyled = styled.a`
    color: ${({ theme }) => theme.color.primaryColor};
    text-decoration: none;
    padding: 8px 15px;

    &:hover {
        color: ${({ theme }) => theme.color.activeColors};
    }

    &.floating {
        padding: 0px;
        position: absolute;
        top: -22px;
        font-weight: 400;
    }
`

export const Navigation = styled(Row)`
    background-color: ${COLORS.bgLight};
    width: fit-content;
    border-radius: 84px;
    height: 38px;
    align-items: center;

    a {
        padding: 12px 20px;
        font-weight: 600;
        border-radius: 84px;
        &.active-nav {
            background-color: ${COLORS.active};
            color: ${COLORS.text};
        }
    }

    .active-item {
        background-color: ${({ theme }) => theme.color.activeColors};
    }
`

export const Wrapper = styled.div`
    display: contents;

    .menu-title {
        display: flex;
        justify-content: center;
        align-items: 'center';

        h2 {
            margin-bottom: 0px;
            margin-left: 20px;
            margin-top: 0;
        }
    }
    .menu-preview {
        display: flex;
        justify-content: center;
        align-items: 'center';
    }

    .previewCol {
        display: flex;
        justify-content: center;
        align-items: start;
        flex-direction: column;
        position: relative;

        select {
            min-width: 198px;
        }
    }

    .anticon-exclamation-circle > svg {
        fill: #faad14;
    }

    .anticon-check-circle > svg {
        fill: #52c41a;
    }
`

export const ProgressContainer = styled.div`
    z-index: 100;
    position: fixed;

    width: calc(100% - 260px);
    height: 100vh;

    top: 0px;
    right: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(0, 0, 0, 0.9);
`

export const ProgressContent = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    justify-content: space-between;
`

