import React from 'react'

import { Row, Col, Typography, Form, Input, InputNumber } from 'antd'

const { Title, Paragraph } = Typography

const EngagementInfo = ({}) => {
    return (
        <>
            <Row gutter={16}>
                <Col span={12}>
                    <Title level={2} style={{ textAlign: 'left' }}>
                        Informações
                    </Title>
                    <Paragraph style={{ textAlign: 'left' }}>
                        Alguns detalhes sobre a campanha
                    </Paragraph>

                    <Row gutter={16}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                name="name"
                                label="Nome da campanha"
                                rules={[{ required: true }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default EngagementInfo
