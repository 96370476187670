import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { useApolloClient } from '@apollo/client'
import { useParams, useHistory } from 'react-router-dom'
import { addToList } from '@cms/core/graphql/utils'
import { REQUEST_REPORT } from '@cms/events/graphql/mutations/reports'

import ModalFilter from './Modal'
import { FormProvider } from 'antd/lib/form/context'

const CrudFilter = ({ types, refetch }) => {
    const { eventId } = useParams()
    const client = useApolloClient()
    const history = useHistory()
    const [segment, setSegment] = useState()

    const handleFormFinish = (name, { values, forms }) => {
        const filters = values.filters.map((filter) => ({
            name: filter.name,
            operator: filter.operator,
            value: filter.value
        }))
        const segment = { ...values, filters }
        setSegment(segment)
        refetch({
            segment
        })
    }

    return (
        <FormProvider onFormFinish={handleFormFinish}>
            <Row align="middle" justify="space-between" gutter={8}>
                <Col>
                    <ModalFilter refetch={refetch} />
                </Col>
                <Col>
                    <Button
                        type="primary"
                        onClick={() => {
                            client.mutate({
                                mutation: REQUEST_REPORT,
                                update: addToList({
                                    list: 'reports',
                                    Type: 'Report'
                                }),
                                variables: {
                                    type: types,
                                    event: eventId,
                                    segment: segment
                                }
                            })
                            history.push('/evento/' + eventId + '/reports')
                        }}
                    >
                        Exportar Inscritos
                    </Button>
                </Col>
            </Row>
        </FormProvider>
    )
}

export default CrudFilter
