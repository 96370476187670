import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Box = styled.div`
	form {
		margin-top: 25px;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		button {
			/* reset */
			border: none;
			color: white;

			/* size */
			width: 250px;
			height: 50px;

			background: #7730f6 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 6px;
			opacity: 1;

			margin-top: 25px;

			cursor: pointer;
		}

		.ant-input {
			width: 350px;
		}

		@media (max-width: 799px){
			.ant-input {
			width: 100%;
			}
		}
	}

	display: flex;
	align-items: center;
	flex-direction: column;
`
