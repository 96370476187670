import React, { useEffect, useState } from 'react'

import { Steps, StepsContent, StepsActions, Button } from './styles'
import { Space } from 'antd'
import { TDStepItem } from './type'
import { useHistory } from 'react-router-dom'

const { Step } = Steps

interface TDStepsProps {
    steps: TDStepItem[]
    enableStepHash?: boolean
    onNext?: (current: number) => Promise<boolean>
    onPrev?: (current: number) => Promise<boolean>
    onFinish?: () => Promise<boolean>
}

/**
 * Wrapper ao redor do component nativo de Steps do antd com lógica de navegação
 */
const TDSteps: React.FC<TDStepsProps> = ({
    steps,
    onNext,
    onPrev,
    onFinish,
    enableStepHash = false
}) => {
    const [current, setCurrent] = useState(0)
    const [loading, setLoading] = useState(false)
    const [initialized, setInitialized] = useState(false)
    const history = useHistory()

    const next = async (shift = 1) => {
        const _current = current + shift
        setLoading(true)
        if (!onNext || (await onNext(_current))) {
            setCurrent(_current)
            enableStepHash && history.push(`#step-${_current}`)
        }
        setLoading(false)
    }

    const prev = async (shift = 1) => {
        const _current = current - shift
        setLoading(true)
        if (!onPrev || (await onPrev(_current))) {
            setCurrent(_current)
            enableStepHash && history.push(`#step-${_current}`)
        }
        setLoading(false)
    }

    const finish = async () => {
        setLoading(true)

        if (!onFinish || (await onFinish())) {
            setCurrent(0)
        }
        setLoading(false)
    }

    useEffect(() => {
        // get hash step-index from url
        const hash = window.location.hash
        if (enableStepHash && hash?.includes('step-')) {
            const index = hash.replace('#step-', '')
            setCurrent(Number(index))
        }

        setInitialized(true)
    }, [])

    if (!initialized) {
        return null
    }

    return (
        <>
            <Steps
                type="navigation"
                className="td-steps"
                current={current}
                progressDot={false}
                onChange={(_current) => {
                    if (current < _current) {
                        next(_current - current)
                    } else if (current > _current) {
                        prev(current - _current)
                    }
                }}
            >
                {steps.map((item) => (
                    <Step
                        icon={item.icon}
                        key={item.title}
                        title={item.title}
                    />
                ))}
            </Steps>
            <StepsContent>{steps[current].component}</StepsContent>
            <StepsActions
                style={current === 0 ? { justifyContent: 'flex-end' } : null}
            >
                <div>
                    {current > 0 && (
                        <Button
                            type="default"
                            loading={loading}
                            style={{ margin: '0 8px' }}
                            onClick={() => prev()}
                        >
                            Voltar
                        </Button>
                    )}
                </div>

                <Space>
                    {(current === steps.length - 1 ||
                        steps[current].allowFinish) && (
                        <Button
                            type={
                                steps[current].allowFinish
                                    ? 'dashed'
                                    : 'primary'
                            }
                            loading={loading}
                            onClick={finish}
                        >
                            Finalizar
                        </Button>
                    )}
                    {current < steps.length - 1 && (
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => next()}
                        >
                            Avançar
                        </Button>
                    )}
                </Space>
            </StepsActions>
        </>
    )
}

export default TDSteps
