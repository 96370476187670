export const DEFAULT_SECTIONS = [
    [
        {
            name: 'backgroundImageDesktop',
            label: 'Imagem de fundo - Login'
        },
        {
            name: 'backgroundImageHome',
            label: 'Imagem de fundo - Home'
        }
    ],
    [
        {
            name: 'scheduleImage',
            label: 'Imagem da Agenda - Desktop'
        },
        {
            name: 'scheduleImageMobile',
            label: 'Imagem da Agenda - Mobile'
        }
    ]
]
