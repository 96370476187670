import React from 'react'

import { CustomRadioButton } from './styles'

const RadioButton = ({ children, ...props}) => {
    return (
        <CustomRadioButton {...props}>
            {children}
        </CustomRadioButton>
    )
}

export default RadioButton