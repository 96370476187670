import { Dropdown, Tooltip } from 'antd'
import styled from 'styled-components'
export const CustomDropdown = styled(Dropdown)`
    &.ant-dropdown-trigger {
        max-height: 38px;
    }

    &.ant-form-item-control-input-content {
        display: flex;

        &.ant-dropdown-trigger.textarea {
            border-radius: 0px 10px 10px 0px;
        }
    }

    textarea {
        border-radius: 6px 0px 6px 6px;
    }
`
export const CustomTooltip = styled.div`
    display: flex;
    .ant-dropdown-trigger {
        border-radius: 0px 10px 10px 0px;
    }

    .ant-input {
        border-radius: 6px 0px 6px 6px;
    }
`
