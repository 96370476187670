import React from 'react'

import * as components from '@cms/core/components/ThemeBuilder/pages'
import DynamicRoutes from '@cms/events/pages/utils/dynamicRoutes'
import { Container } from '../../styles'
import { Nav } from '@cms/core/components/Nav'
import { useThemeBuilderContext } from '../../context'

export const ThemeBuilderRoutes = () => {
    const {
        routesConfig: { routes, baseUrl }
    } = useThemeBuilderContext()

    return (
        <DynamicRoutes
            baseUrl={baseUrl}
            routes={routes}
            components={components}
            redirectToRoot
        />
    )
}

export const ThemeBuilderContent = ({ save }: { save: () => Promise<any> }) => {
    const {
        routesConfig: { routes, baseUrl }
    } = useThemeBuilderContext()

    return (
        <Container>
            <Nav idParam={'eventId'} config={{ baseUrl, routes }} save={save} />
            <ThemeBuilderRoutes />
        </Container>
    )
}

