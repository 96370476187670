import React from 'react'

import Card, { CompareLines } from '../../Card'

import { StarOutlined } from '@ant-design/icons'

const UserPerCard = ({ data, title, metric, icon: Icon = StarOutlined }) => {
    const values =
        data.length > 0
            ? data.map((users) => {
                  const keyPercentage = metric.name + 'Percentage'
                  const keyNumberOf = metric.name + 'NumberOfUsers'

                  return {
                      name: users[metric.name],
                      percent: users[keyPercentage],
                      value: users[keyNumberOf]
                  }
              })
            : []

    const rest = values.slice(1, values.length)

    return (
        <Card icon={Icon} title={title}>
            {/* <MainNumber
                title={values[0]?.name}
                value={values[0]?.value}
                percent={values[0]?.percent}
            /> */}
            <CompareLines title={metric.label} values={values} />
        </Card>
    )
}

export default UserPerCard
