import React, { useState, useEffect } from 'react'
import { Button, Row, Modal, Form } from 'antd'
import { FilterOutlined } from '@ant-design/icons'

import Segment from '../Segment'

const ModalFilter = () => {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()

    const handleOk = async () => {
        await form.validateFields()
        form.submit()
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const showModal = () => {
        setIsModalVisible(true)
    }

    return (<>
        <Button
            type="primary"
            onClick={showModal}
            style={{ marginLeft: 'auto' }}
            icon={<FilterOutlined />}
        ></Button>
        <Modal
            title="Filtrar"
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                name="segment"
                className="queryBuilder"
                style={{ position: 'relative', width: '100%' }}
            >
                <Segment form={form} />
            </Form>
        </Modal>
    </>);
}

export default ModalFilter
