import React from 'react'
import { Form, Input, Radio } from 'antd'

import {
    ButtonConfig,
    ColorsBadges,
    ContainerConfig,
    Uploader
} from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const SideImage = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Texto</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Subtitulo</Title>
                <Form.Item
                    name={[props.name, 'configs', 'subtitle', 'text']}
                    label="Texto"
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
            <Wrapper>
                <Title level={4}>Imagem</Title>
                <Form.Item
                    name={[props.name, 'configs', 'image', 'src']}
                    label="imagem"
                >
                    <Uploader />
                </Form.Item>

                <Title level={4}>Posição da imagem</Title>
                <Form.Item name={[props.name, 'configs', 'image', 'position']}>
                    <Radio.Group
                        initialValue={'right'}
                        style={{ marginBottom: 8 }}
                    >
                        <Radio.Button
                            value="left"
                            style={{
                                borderRight: '1px solid rgb(255 255 255 / 10%)'
                            }}
                        >
                            Esquerda
                        </Radio.Button>
                        <Radio.Button value="right">Direita</Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Wrapper>
            <ButtonConfig
                enableActions
                allowVisibilityControl
                fieldPath={[props.name, 'configs', 'button']}
                {...props}
            />
        </>
    )
}

export default SideImage
