import React, { useEffect, useState } from 'react'
import { Space, Tree, TreeProps } from 'antd'
import { TreeWrapper } from './styles'
import { CustomTreeProps, ITreeData } from './types'

export const findNode = (
    key: string | number,
    treeData: ITreeData[]
): ITreeData => {
    for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i]
        if (node.key === key) return node
        if (node.children) {
            const result = findNode(key, node.children)
            if (result) return result
        }
    }
}

export const getParents = (
    key: string | number,
    treeData: ITreeData[],
    parents: ITreeData[] = []
): ITreeData[] => {
    for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i]
        if (node.key === key) return parents
        if (node.children) {
            parents.push(node)
            const result = getParents(key, node.children, parents)
            if (result?.length) return result
            parents.pop()
        }
    }
    return []
}

export const getAllChildren = (
    key: string | number | null,
    treeData: ITreeData[]
): ITreeData[] => {
    // get all children of a node
    const children: ITreeData[] = []
    for (let i = 0; i < treeData.length; i++) {
        const node = treeData[i]
        if (key === null) {
            children.push(node)
            if (node.children?.length) {
                children.push(...getAllChildren(node.key, node.children))
            }
        } else {
            if (node.key === key && node.children?.length) {
                node.children.forEach((child) => {
                    children.push(child)
                    if (child.children?.length) {
                        children.push(...getAllChildren(child.key, treeData))
                    }
                })
                return children
            } else {
                if (node.children?.length) {
                    children.push(...getAllChildren(key, node.children))
                }
            }
        }
    }

    return children
}

const CustomTree: React.FC<CustomTreeProps> = ({
    treeData,
    onChange,
    value: valueForm,
    autoSelectParent,
    autoExpandParent,
    ...rest
}) => {
    const [_autoExpandParent, setAutoExpandParent] = useState(autoExpandParent)
    const [expandedKeys, setExpandedKeys] = useState(valueForm || [])

    const onCheckAutoSelect = (checkedKeysValue: string[], info: any) => {
        let items = [...checkedKeysValue]
        if (info.node.autoSelectItems) {
            if (!info.checked && info.node.autoSelect) {
                items = info.checkedNodes
                    .filter((o) => o.parentKey !== info.node.parentKey)
                    .map((item) => item.key)
            }

            if (info.checked) {
                items = [...new Set([...items, ...info.node.autoSelectItems])]
            }
        }

        onChange(items)
    }

    const onExpand = (expandedKeysValue: any[]) => {
        setExpandedKeys(expandedKeysValue)
        setAutoExpandParent(false)
    }

    useEffect(() => {
        if (treeData && _autoExpandParent && valueForm?.length) {
            setExpandedKeys(
                valueForm.flatMap((key) => [
                    ...getParents(key, treeData).map((o) => o.key)
                ])
            )
            setAutoExpandParent(false)
        }
    }, [treeData, _autoExpandParent, valueForm])

    const TreeProps: TreeProps = {
        treeData,
        checkable: true,
        checkedKeys: valueForm,
        onCheck: onCheckAutoSelect,
        selectable: false,
        showIcon: true,
        autoExpandParent: _autoExpandParent,
        onExpand,
        expandedKeys
    }

    if (autoSelectParent) {
        const checkAllChildren = (key: string | number) => {
            const children = getAllChildren(key, treeData).map(
                (child) => child.key
            )

            onChange([...new Set([...valueForm, ...children, key])])
            setExpandedKeys((e) => [...new Set([...e, ...children, key])])
            setAutoExpandParent(false)
        }

        const uncheckAllChildren = (key: string | number) => {
            const children = getAllChildren(key, treeData).map(
                (child) => child.key
            )

            const newValue = valueForm.filter(
                (value) => !children.includes(value)
            )

            onChange([...new Set(newValue)])
        }

        TreeProps.checkStrictly = true
        TreeProps.defaultExpandAll = true
        TreeProps.titleRender = (node: ITreeData) => {
            const children = getAllChildren(node.key, treeData)

            const checked = children.filter((child) =>
                valueForm?.includes(child.key)
            ).length

            return (
                <div>
                    <Space>
                        <span>{node.title}</span>

                        {!!children.length && (
                            <>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        e.preventDefault()
                                        if (checked === children.length) {
                                            uncheckAllChildren(node.key)
                                        } else {
                                            checkAllChildren(node.key)
                                        }
                                    }}
                                >
                                    {checked === children.length
                                        ? 'Desmarcar Todos'
                                        : 'Marcar Todos'}
                                </a>
                                <i>
                                    ({checked}/{children.length})
                                </i>
                            </>
                        )}
                    </Space>
                </div>
            )
        }
        TreeProps.onCheck = (newValue: any, info: any) => {
            const node = info.node
            let selected = newValue.checked

            const parents = getParents(node.key, treeData)
            const children = getAllChildren(node.key, treeData)

            if (info.checked) {
                // check all parents
                parents.forEach((parent) => {
                    if (!selected.includes(parent.key)) {
                        selected.push(parent.key)
                    }
                })
            } else {
                const hasSelectedChildren = children.some((child) =>
                    selected.includes(child.key)
                )

                if (hasSelectedChildren) {
                    selected.push(node.key)
                }

                // uncheck all children
                children.forEach((child) => {
                    if (selected.includes(child.key)) {
                        selected = selected.filter(
                            (key: string) => key !== child.key
                        )
                    }
                })
            }

            onChange(selected)
        }
    }

    return (
        <TreeWrapper>
            <Tree {...TreeProps} {...rest} />
        </TreeWrapper>
    )
}

export default CustomTree

