import React, { useEffect, useState } from 'react'
import { useApolloClient, useQuery } from '@apollo/client'
import dayjs from 'dayjs'

import {
    message,
    Form,
    Input,
    Row,
    Col,
    Button,
    DatePicker,
    InputNumber,
    Checkbox,
    Typography,
    Select,
    Tag
} from 'antd'

import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import SelectSearch from '@cms/core/components/SelectSearch'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'

import {
    CrudForm,
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import { useSelector } from 'react-redux'

import { addToList } from '@cms/core/graphql/utils'
import {
    GET_TALK,
    GET_TALK_LIST,
    GET_TALK_TAGS
} from '@cms/events/graphql/queries/talk'
import { GET_SPEAKER_LIST } from '@cms/events/graphql/queries/speaker'
import { GET_BRAND_LIST } from '@cms/events/graphql/queries/brand'
import { GET_EVENT_LANGUAGES } from '@cms/events/graphql/queries/event'
import { GET_CATEGORY_LIST } from '@cms/events/graphql/queries/category'
import { CREATE_TALK, UPDATE_TALK } from '@cms/events/graphql/mutations/talk'

import { RadioGroup, RadioButton } from '@cms/core/components/Radio'
import { displayIf } from '@cms/core/utils'
import Card from '@cms/core/components/Card'
import { GET_LOCATION_LIST_ORDENABLE } from '@cms/events/graphql/queries/locations'
import { array } from 'prop-types'
import { GET_INTEGRATIONS } from '@cms/core/graphql/queries/integrations'
import { languages } from '../../Config/Streaming/constants/languages'

const { Paragraph } = Typography

const formatData = ({ ...data }) => {
    // data.image = data.image?.id
    // data.imageLive = data.imageLive?.id

    return data
}

const TalksModal = ({ params, mode, ...props }) => {
    const [hostType, setHostType] = useState('DIGITAL')
    const { eventId, type, label, id } = params
    const [players, setPlayers] = useState([])

    const { data: dataPlayers } = useQuery(GET_INTEGRATIONS, {
        variables: { eventId, category: 'players', appType: 'EVENTS' }
    })

    useEffect(() => {
        if (dataPlayers) {
            const players = dataPlayers.integrations.edges
            if (players?.length > 0) {
                setPlayers(
                    players.map(({ node }) => ({
                        label: node.name,
                        value: node.type
                    }))
                )
            } else {
                setPlayers([])
            }
        }
    }, [dataPlayers])

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm(
        {
            id,
            relateds: [],
            getData: async (id) => {
                if (!id) return

                const response = await client.query({
                    query: GET_TALK,
                    variables: { id }
                })

                const data = { ...response.data.talk }
                setHostType(data.hostType)

                data.categories =
                    data.categories?.edges.map((o) => o.node.id) || []
                data.speakers = data.speakers?.edges.map((o) => o.node.id) || []
                data.brands = data.brands?.edges.map((o) => o.node.id) || []
                data.sponsorTmp = data.sponsorTmp?.id
                data.stage = data.stage?.id
                data._categories = data.categories.edges
                data._speakers = data.speakers.edges
                data._brands = data.brands.edges
                data.location = data.location?.id
                data.resources = data.resources

                if (data.date) data.date = dayjs(new Date(data.date))

                return data
            },
            createData: async (formData) => {
                if (formData.hostType !== 'LIVE') {
                    formatData.stage = null
                }

                if (type === 'ONDEMAND') {
                    formData.date = new Date().toISOString()
                }

                await client.mutate({
                    mutation: CREATE_TALK,
                    update: addToList({ list: 'talks', Type: 'Talk' }),
                    variables: { ...formatData(formData), event: eventId, type }
                })

                message.success(label + ' cadastrada com sucesso!')
            },
            updateData: async (id, formData) => {
                if (formData.hostType !== 'LIVE') {
                    formatData.stage = null
                }

                if (type === 'ONDEMAND') {
                    formData.date = new Date().toISOString()
                }

                await client.mutate({
                    mutation: UPDATE_TALK,
                    variables: {
                        id,
                        ...formatData(formData),
                        event: eventId,
                        type
                    }
                })

                message.success(label + ' atualizada com sucesso!')
            }
        },
        [id]
    )

    const queryCategories = useQuery(GET_CATEGORY_LIST, {
        variables: { first: 9999, eventId },
        fetchPolicy: 'cache-and-network'
    })

    const [categoriesOptions, categoriesSelectProps] = useCrudSelect(
        queryCategories,
        {
            queryName: 'categories',
            additionalValues: form.getFieldValue('_categories')
        }
    )

    const querySpeakers = useQuery(GET_SPEAKER_LIST, {
        variables: { first: 9999, eventId }
    })

    const [SpeakersOptions, speakersSelectProps] = useCrudSelect(
        querySpeakers,
        {
            queryName: 'speakers',
            additionalValues: form.getFieldValue('_speakers')
        }
    )

    const queryBrands = useQuery(GET_BRAND_LIST, {
        variables: { first: 9999, eventId }
    })

    const [brandsOptions, brandsSelectOptions] = useCrudSelect(queryBrands, {
        queryName: 'brands',
        additionalValues: form.getFieldValue('_brands')
    })

    // HACK: Mesma query para o sponsorTmp
    const [brandsOptions2, brandsSelectOptions2] = useCrudSelect(queryBrands, {
        queryName: 'brands',
        additionalValues: form.getFieldValue('_brands'),
        includeEmpty: true
    })

    const queryLocations = useQuery(GET_LOCATION_LIST_ORDENABLE, {
        variables: { first: 9999, eventId }
    })

    const [locationOptions, locationsSelectOptions] = useCrudSelect(
        queryLocations,
        {
            queryName: 'locations',
            additionalValues: form.getFieldValue('_locations')
        }
    )

    const queryStages = useQuery(GET_TALK_LIST, {
        variables: { first: 9999, eventId, type: 'STAGE' },
        fetchPolicy: 'cache-and-network'
    })

    const [stagesOptions, stagesSelectProps] = useCrudSelect(queryStages, {
        queryName: 'talks'
    })

    const toggleHostType = (event) => {
        setHostType(event.target.value)
        if (event.target.value === 'ONSTAGE') {
            //form.setFieldsValue({ type: undefined })
        }
    }

    const { data: TagsData } = useQuery(GET_TALK_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    const [selectedLanguages, setSelectedLanguages] = useState([])

    const handleSelectLanguage = (value) => {
        if (!selectedLanguages.includes(value)) {
            setSelectedLanguages([...selectedLanguages, value])
        }
    }

    const handleRemoveSelectLanguage = (value) => {
        setSelectedLanguages(
            selectedLanguages.filter((language) => language !== value)
        )
    }

    const modalHeader = mode === 'write' ? 'Editar ' : 'Visualizar '
    const isView = mode === 'view'

    return (
        <CrudModal
            width={900}
            title={isEdit ? modalHeader + label : 'Nova ' + label}
            mode={mode}
            {...modalProps}
            {...props}
        >
            <CrudForm
                layout={'vertical'}
                form={form}
                initialValues={{ hostType: hostType }}
            >
                {type === 'LIVE' && (
                    <Form.Item name="hostType">
                        <RadioGroup
                            onChange={toggleHostType}
                            defaultValue={hostType}
                            buttonStyle="solid"
                        >
                            <RadioButton value="DIGITAL">Digital</RadioButton>
                            <RadioButton noBorder value="HYBRID">
                                Híbrido
                            </RadioButton>
                            <RadioButton value="ONSTAGE">
                                Presencial
                            </RadioButton>
                        </RadioGroup>
                    </Form.Item>
                )}

                <Card title="Geral">
                    <Form.Item
                        label="Título"
                        name="title"
                        rules={[
                            { required: true, message: 'Título é obrigatório!' }
                        ]}
                        {...getError('tile')}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Descrição"
                        name="description"
                        {...getError('description')}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>

                    <Row gutter={24}>
                        {type !== 'ONDEMAND' && (
                            <Col span={12}>
                                <Form.Item
                                    label="Data"
                                    name="date"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'A data é obrigatória!'
                                        }
                                    ]}
                                    {...getError('date')}
                                >
                                    <DatePicker
                                        showTime
                                        needConfirm={false}
                                        style={{ width: '100%' }}
                                        format={'DD MMM YYYY HH:mm:ss'}
                                        placeholder=""
                                    />
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={12}>
                            <Form.Item
                                label="Duração (minutos)"
                                name="duration"
                                rules={[
                                    {
                                        required: true,
                                        message: 'A duração é obrigatória!'
                                    }
                                ]}
                                {...getError('duration')}
                            >
                                <InputNumber style={{ width: 140 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Palestrantes"
                                name={['speakers']}
                                {...getError('speakers')}
                            >
                                <SelectSearch {...speakersSelectProps}>
                                    {SpeakersOptions({
                                        key: 'node.id',
                                        label: 'node.name'
                                    })}
                                </SelectSearch>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Patrocinadores"
                                name={['brands']}
                                {...getError('brands')}
                            >
                                <SelectSearch {...brandsSelectOptions}>
                                    {brandsOptions({
                                        key: 'node.id',
                                        label: 'node.name'
                                    })}
                                </SelectSearch>
                            </Form.Item>
                        </Col>
                    </Row>

                    {type !== 'STAGE' && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Trilhas / Playlists / Séries"
                                    name={['categories']}
                                    {...getError('categories')}
                                >
                                    <SelectSearch {...categoriesSelectProps}>
                                        {categoriesOptions({
                                            key: 'node.id',
                                            label: 'node.name',
                                            groupBy: {
                                                key: 'node.type',
                                                groups: {
                                                    MAIN: 'Trilhas',
                                                    PLAYLIST: 'Playlists',
                                                    SERIE: 'Séries'
                                                }
                                            }
                                        })}
                                    </SelectSearch>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    {hostType !== 'ONSTAGE' && type === 'LIVE' && (
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    label="Palco"
                                    name={['stage']}
                                    {...getError('stage')}
                                >
                                    <SelectSearch
                                        {...stagesSelectProps}
                                        mode="single"
                                        allowClear
                                    >
                                        {stagesOptions({
                                            key: 'node.id',
                                            label: 'node.title'
                                        })}
                                    </SelectSearch>
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                </Card>

                {hostType !== 'ONSTAGE' && (
                    <Card title="Plataforma de Transmissão">
                        <Row gutter={24}>
                            <Col span={6}>
                                <Form.Item
                                    label="Player"
                                    name={['video', 'player']}
                                    {...getError('brands')}
                                >
                                    <Select>
                                        <Select.Option>Padrão</Select.Option>
                                        {players.map((player) => (
                                            <Select.Option
                                                key={player.name}
                                                value={player.value}
                                            >
                                                {player.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={18}>
                                <Form.Item
                                    label="Link do video (Youtube, Vimeo, mp4, m3u8)"
                                    name={['video', 'url']}
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Url inválida'
                                        }
                                    ]}
                                    {...getError('video.url')}
                                >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Card title="Links idiomas adicionais">
                            <Form.List name="videos">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <div key={`videos.${index}`}>
                                                <Row
                                                    className="actionsBox"
                                                    gutter={24}
                                                    align="middle"
                                                >
                                                    <Col span={24}>
                                                        <Paragraph>
                                                            Link {index + 1}
                                                        </Paragraph>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            label="Player"
                                                            name={[
                                                                field.name,
                                                                'video',
                                                                'player'
                                                            ]}
                                                        >
                                                            <Select
                                                                disabled={
                                                                    isView
                                                                }
                                                            >
                                                                <Select.Option>
                                                                    Padrão
                                                                </Select.Option>
                                                                {players.map(
                                                                    (
                                                                        player
                                                                    ) => (
                                                                        <Select.Option
                                                                            key={
                                                                                player.name
                                                                            }
                                                                            value={
                                                                                player.value
                                                                            }
                                                                        >
                                                                            {
                                                                                player.label
                                                                            }
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            label="Link"
                                                            name={[
                                                                field.name,
                                                                'video',
                                                                'url'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Link é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input
                                                                disabled={
                                                                    isView
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item
                                                            {...field}
                                                            label="Descrição"
                                                            name={[
                                                                field.name,
                                                                'locale'
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Idioma é obrigatório!'
                                                                }
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        {isView ? null : (
                                                            <MinusCircleOutlined
                                                                style={{
                                                                    marginTop: 20
                                                                }}
                                                                onClick={() => {
                                                                    remove(
                                                                        field.name
                                                                    ),
                                                                        handleRemoveSelectLanguage(
                                                                            selectedLanguages[
                                                                                field
                                                                                    .name
                                                                            ]
                                                                        )
                                                                }}
                                                            />
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        ))}

                                        {isView ? null : (
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => {
                                                        add({})
                                                    }}
                                                    style={{ width: '100%' }}
                                                >
                                                    <PlusOutlined /> Adicionar
                                                </Button>
                                            </Form.Item>
                                        )}
                                    </>
                                )}
                            </Form.List>
                        </Card>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label="Imagem (900 x 500)"
                                    name="image"
                                    {...getError('image')}
                                >
                                    <MediaUploader
                                        options={{
                                            optimize: {
                                                resize: {
                                                    width: 900,
                                                    height: 500
                                                },
                                                quality: 80
                                            }
                                        }}
                                        type="image"
                                    />
                                </Form.Item>
                            </Col>

                            {type === 'STAGE' && (
                                <Col span={12}>
                                    <Form.Item
                                        label="Imagem do palco (400x520)"
                                        name="featureImage"
                                        {...getError('featureImage')}
                                    >
                                        <MediaUploader
                                            options={{
                                                optimize: {
                                                    resize: {
                                                        width: 400,
                                                        height: 520
                                                    },
                                                    quality: 80
                                                }
                                            }}
                                            type="image"
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            <Col span={12}>
                                <Form.Item
                                    label="Patrocinador Transmissão"
                                    name={'sponsorTmp'}
                                    {...getError('sponsorTmp')}
                                >
                                    <SelectSearch
                                        mode="single"
                                        {...brandsSelectOptions2}
                                    >
                                        {brandsOptions2({
                                            key: 'node.id',
                                            label: 'node.name'
                                        })}
                                    </SelectSearch>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Card title="Formulário tela de transmissão">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        label="Nome do iframe"
                                        name={['iframe', 'name']}
                                        {...getError('iframe.name')}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="Link do iframe"
                                        name={['iframe', 'url']}
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Url inválida'
                                            }
                                        ]}
                                        {...getError('iframe.url')}
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>

                        <Row gutter={24}>
                            <Col span={24}>
                                {type !== 'LIVE' && (
                                    <>
                                        <Form.Item
                                            label="Prioridade (Ordena os videos do maior para o menor)"
                                            name="priority"
                                            {...getError('priority')}
                                        >
                                            <InputNumber
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </>
                                )}
                            </Col>
                            <Col span={24}>
                                {type === 'LIVE' && (
                                    <>
                                        <Form.Item
                                            name="featured"
                                            valuePropName="checked"
                                        >
                                            <Checkbox>Destaque</Checkbox>
                                        </Form.Item>
                                    </>
                                )}

                                <Form.Item
                                    name="hiddenInHome"
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        Ocultar na home (Transmissão)
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                )}

                {type === 'LIVE' && (
                    <Card
                        title="Local do evento presencial"
                        style={displayIf(hostType, 'DIGITAL')}
                    >
                        <Col span={24}>
                            <Form.Item
                                label="Local"
                                name={'location'}
                                {...getError('location')}
                            >
                                <SelectSearch
                                    mode="single"
                                    {...locationsSelectOptions}
                                >
                                    {locationOptions({
                                        key: 'node.id',
                                        label: 'node.name'
                                    })}
                                </SelectSearch>
                            </Form.Item>
                        </Col>
                    </Card>
                )}
                <Card title="Informações adicionais">
                    <Col span={12}>
                        <Form.Item label="Tags" name={['tags']}>
                            <Select
                                mode="tags"
                                tagRender={({ label, ...props }) => (
                                    <Tag {...props} color="#7F44FF">
                                        {label}
                                    </Tag>
                                )}
                                style={{ width: '100%' }}
                                placeholder="Selecione uma tag existente ou crie uma nova."
                            >
                                {TagsData?.talkTags?.map((tag) => (
                                    <Select.Option key={tag} value={tag}>
                                        {tag}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Card>
                <Card
                    style={displayIf(hostType, 'ONSTAGE')}
                    title="Materiais para download"
                >
                    <Form.List name="resources">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <div key={`resources.${index}`}>
                                        <Row
                                            className="actionsBox"
                                            gutter={24}
                                            align="middle"
                                        >
                                            <Col span={24}>
                                                <Paragraph>
                                                    Recurso {index + 1}
                                                </Paragraph>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Titulo"
                                                    name={[field.name, 'title']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Titulo é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={14}>
                                                <Form.Item
                                                    {...field}
                                                    label="Link"
                                                    name={[field.name, 'link']}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Link é obrigatório!'
                                                        }
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                {isView ? null : (
                                                    <MinusCircleOutlined
                                                        style={{
                                                            marginTop: 20
                                                        }}
                                                        onClick={() =>
                                                            remove(field.name)
                                                        }
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                                {isView ? null : (
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => {
                                                add({})
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <PlusOutlined /> Adicionar
                                        </Button>
                                    </Form.Item>
                                )}
                            </>
                        )}
                    </Form.List>
                </Card>
            </CrudForm>
        </CrudModal>
    )
}

export default TalksModal

