import React from 'react'
import { Collapse } from 'antd'

import { PanelFooter, PanelHeader } from '../../../components'

const { Panel } = Collapse

const Section = (props) => {
    const { index, activePanel, section, setSection, children, form } = props

    return (
        <Collapse activeKey={activePanel} className={`field-${index}`}>
            <Panel
                id={`section-${section}`}
                header={<PanelHeader form={form} {...props} />}
                key={section}
                showArrow={false}
            >
                {children}
                <PanelFooter setSection={setSection} />
            </Panel>
        </Collapse>
    )
}

export default Section
