import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const CREATE_EVENT_EDITION = gql`
    mutation CreateEventEdition($name: String, $events: [ObjectID]) {
        createEventEdition(input: { name: $name, events: $events }) {
            ...EventEditionData
        }
    }

    ${fragments.EventEditionData}
`

export const UPDATE_EVENT_EDITION = gql`
    mutation UpdateEventEdition(
        $id: ObjectID!
        $name: String
        $events: [ObjectID]
    ) {
        updateEventEdition(id: $id, input: { name: $name, events: $events }) {
            ...EventEditionData
        }
    }

    ${fragments.EventEditionData}
`

export const DELETE_EVENT_EDITION = gql`
    mutation DeleteEventEdition($id: ObjectID!) {
        deleteEventEdition(id: $id) {
            id
            success
        }
    }
`
