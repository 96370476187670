import React from 'react'
import { Form } from 'antd'

import { ContainerConfig } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'

const Footer2 = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Informações do rodapé</Title>
                <Form.Item
                    name={[props.name, 'configs', 'firstText', 'text']}
                    label={'Texto lado esquerdo'}
                >
                    <RichTextEditor />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'secondText', 'text']}
                    label={'Texto lado direito'}
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Footer2
