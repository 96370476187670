import styled from 'styled-components'

export const StyledRow = styled.div`
    display: flex;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`

export const TextNumber = styled.span`
    font-size: 1.7em;

    font-weight: bold;
`

export const Description = styled.span`
    font-size: 0.8em;
`