import styled from 'styled-components'

export const WrapperDots = styled.div`
    display: contents;

    .ant-badge-status-dot {
        width: 16px;
        height: 16px;
    }
    .ant-badge-status-text {
        color: #fff;
        font-size: 16px;
    }
`
