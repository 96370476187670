import React from 'react'

import { FormRoutes } from './routes'
import { FormBuilderProvider } from '../context/context'

const FormBuilderScreen = () => {
    return (
        <FormBuilderProvider type="others">
            <FormRoutes />
        </FormBuilderProvider>
    )
}

export default FormBuilderScreen
