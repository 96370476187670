import { gql } from '@apollo/client'
import * as fragments from '../../../apps/events/graphql/fragments'

export const CREATE_USER_PROFILE = gql`
    mutation CreateUserProfile(
        $name: String
        $fullAccess: Boolean
        $modules: [String]!
    ) {
        createUserProfile(
            input: { name: $name, fullAccess: $fullAccess, modules: $modules }
        ) {
            ...UserProfileData
        }
    }
    ${fragments.UserProfileData}
`

export const UPDATE_USER_PROFILE = gql`
    mutation UpdateUserProfile(
        $id: ObjectID!
        $name: String
        $fullAccess: Boolean
        $modules: [String]!
    ) {
        updateUserProfile(
            id: $id
            input: { name: $name, fullAccess: $fullAccess, modules: $modules }
        ) {
            ...UserProfileData
        }
    }
    ${fragments.UserProfileData}
`

export const DELETE_USER_PROFILE = gql`
    mutation deleteUserProfile($id: ObjectID!) {
        deleteUserProfile(id: $id) {
            id
            success
        }
    }
`
