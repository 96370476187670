import { gql } from '@apollo/client'
import * as fragments from '../fragments'

export const GET_REPORT_LIST_ORDENABLE = gql`
    query GetReport(
        $first: Int
        $afterCursor: Cursor
        $orderBy: ReportOrderBy
        $eventId: ObjectID!
    ) {
        reports(
            first: $first
            after: $afterCursor
            orderBy: $orderBy
            filter: { event: $eventId }
        ) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...ReportData
                }
            }
        }
    }

    ${fragments.ReportData}
`

export const GET_REPORTS_LIST = gql`
    query GetReportList($first: Int, $afterCursor: Cursor) {
        reports(first: $first, after: $afterCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    ...ReportData
                }
            }
        }
    }

    ${fragments.ReportData}
`