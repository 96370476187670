import React from 'react'
import { Typography } from 'antd'
import { InfoCard } from '../styles'
const { Text } = Typography

export const TalksInfos = ({ title, subtitle }) => {
    return (
        <InfoCard justify={'center'}>
            <Text style={{ fontSize: 20 }}>
                {title}
                <span style={{ fontSize: 14, marginTop: 4 }}>{subtitle}</span>
            </Text>
        </InfoCard>
    )
}

