import React from 'react'
import { message, Form, Input, Checkbox, Select } from 'antd'
import { useApolloClient, useQuery } from '@apollo/client'

import SelectSearch from '@cms/core/components/SelectSearch'

import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_BANNER } from '@cms/events/graphql/queries/banner'
import {
    CREATE_BANNER,
    UPDATE_BANNER
} from '@cms/events/graphql/mutations/banner'
import { GET_TALK_OPTIONS } from '@cms/events/graphql/queries/talk'
import { GET_TICKETS_LIST } from '@cms/events/graphql/queries/tickets'

import IntegrationInput from '@cms/core/components/Integrations/components/Input'

const BannersModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const queryTalks = useQuery(GET_TALK_OPTIONS, {
        variables: { first: 9999, eventId }
    })

    const [talksOptions, talksSelectOptions] = useCrudSelect(queryTalks, {
        queryName: 'talks'
    })

    const queryTickets = useQuery(GET_TICKETS_LIST, {
        variables: { first: 9999, eventId }
    })

    const [ticketsOptions, ticketsSelectOptions] = useCrudSelect(queryTickets, {
        queryName: 'tickets'
    })

    const [{ form, isEdit, getError, formData, formUpdate }, modalProps] =
        useCrudForm({
            relateds: [],
            getData: async (id) => {
                if (!id) return

                const response = await client.query({
                    query: GET_BANNER,
                    variables: { id }
                })

                const data = { ...response.data.banner }

                data.talks = data.talks?.edges.map((o) => o.node.id) || []
                data.tickets = data.tickets?.map((o) => o.id) || []
                data.integrations = data.integrations?.map((o) => o.id) || []

                return data
            },
            createData: async (formData) => {
                await client.mutate({
                    mutation: CREATE_BANNER,
                    update: addToList({ list: 'banners', Type: 'Banner' }),
                    variables: { ...formData, event: eventId }
                })

                message.success('Banner cadastrado com sucesso!')
            },
            updateData: async (id, formData) => {
                await client.mutate({
                    mutation: UPDATE_BANNER,
                    variables: { id, ...formData, event: eventId }
                })

                message.success('Banner atualizado com sucesso!')
            }
        })

    return (
        <CrudModal
            title={isEdit ? 'Editar Banner' : 'Novo Banner'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item name="link" label="Link">
                    <Input />
                </Form.Item>

                <Form.Item name="active" valuePropName="checked">
                    <Checkbox>Ativo</Checkbox>
                </Form.Item>

                <Form.Item name="mobile" label="Mobile" {...getError('mobile')}>
                    <MediaUploader type="image" />
                </Form.Item>

                <Form.Item
                    name="desktop"
                    label="Desktop"
                    {...getError('desktop')}
                >
                    <MediaUploader type="image" />
                </Form.Item>

                <Form.Item
                    label="Páginas"
                    name={'pages'}
                    placeholder="Selecione um tipo"
                    style={{ width: '100%' }}
                >
                    <Select mode="tags" onChange={() => formUpdate()}>
                        {['Home', 'Sponsor', 'Live'].map((value) => (
                            <Select.Option key={value} value={value}>
                                {value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Tickets"
                    name={'tickets'}
                    placeholder="Selecione um tipo"
                    style={{ width: '100%' }}
                >
                    <SelectSearch
                        mode="tags"
                        {...ticketsSelectOptions}
                        onChange={() => formUpdate()}
                    >
                        {ticketsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>

                {formData.pages?.includes('Live') && (
                    <Form.Item
                        label="Palestras"
                        name={['talks']}
                        {...getError('talks')}
                    >
                        <SelectSearch {...talksSelectOptions}>
                            {talksOptions({
                                key: 'node.id',
                                label: 'node.title',
                                groupBy: {
                                    key: 'node.type',
                                    groups: {
                                        STAGE: 'Palcos',
                                        LIVE: 'Palestras',
                                        ONDEMAND: 'Videos On Demand'
                                    }
                                }
                            })}
                        </SelectSearch>
                    </Form.Item>
                )}

                <IntegrationInput eventId={eventId} />
            </Form>
        </CrudModal>
    )
}

export default BannersModal
