import {
    GET_SPEAKER_SELECT_LIST,
    GET_SPEAKER_TAGS
} from '@cms/events/graphql/queries/speaker'

export const OPTIONS_VALUE = {
    highlight: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_SPEAKER_SELECT_LIST,
            variables: {
                eventId,
                sort: 'ASC',
                featured: true
            }
        })

        const parsedData =
            (data &&
                data.speakers.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    others: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_SPEAKER_SELECT_LIST,
            variables: {
                eventId,
                sort: 'ASC',
                featured: false
            }
        })

        const parsedData =
            (data &&
                data.speakers.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    all: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_SPEAKER_SELECT_LIST,
            variables: {
                eventId,
                sort: 'ASC'
            }
        })

        const parsedData =
            (data &&
                data.speakers.edges.map((item) => {
                    const { __typename, ...rest } = item.node
                    return {
                        ...rest
                    }
                })) ||
            []
        return parsedData
    },
    tags: async (client, eventId) => {
        const { data } = await client.query({
            query: GET_SPEAKER_TAGS,
            variables: {
                eventId,
                orderBy: { name: 'ASC' }
            }
        })

        const parsedData =
            (data &&
                data.speakerTags.map((item) => {
                    return {
                        value: item,
                        label: item
                    }
                })) ||
            []
        return parsedData
    }
}
