import React from 'react'
import MediaUploader from '@cms/core/components/MediaUploader/graphql'
import { Col, Form, Input, message, Row, Switch, Typography } from 'antd'

import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import {
    CREATE_TALK_NOTIFICATION,
    UPDATE_TALK_NOTIFICATION
} from '@cms/events/graphql/mutations/talkNotification'
import { GET_TALK_NOTIFICATION } from '@cms/events/graphql/queries/talkNotification'
import { addToList } from '@cms/core/graphql/utils'
import { useHistory } from 'react-router-dom'

import IntegrationInput from '@cms/core/components/Integrations/components/Input'

const { Text } = Typography

const NotificationTalkModal = ({ params, path, ...props }) => {
    const { talkId, eventId } = params

    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        relateds: [],
        getData: async (id) => {
            if (!id) return

            const res = await client.query({
                query: GET_TALK_NOTIFICATION,
                variables: { id }
            })

            const data = { ...res.data.talkNotification }

            data.integrations = data.integrations?.map((o) => o.id) || []

            return data
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_TALK_NOTIFICATION,
                update: addToList({
                    list: 'talkNotifications',
                    Type: 'TalkNotification'
                }),
                variables: { ...formData, event: eventId, talk: talkId }
            })

            message.success('Notificação de palestra enviada com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_TALK_NOTIFICATION,
                variables: { id, ...formData, event: eventId, talk: talkId }
            })

            message.success('Notificação de palestra atualizada com sucesso!')
        }
    })

    return (
        <CrudModal
            title={
                isEdit
                    ? 'Editar Notificação de Palestra'
                    : 'Nova Notificação de Palestra'
            }
            path={path}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Row>
                    <Col>
                        <Row
                            style={{
                                alignItems: 'baseline',
                                marginRight: '10px'
                            }}
                        >
                            <Form.Item name="chat" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <Text strong style={{ marginLeft: '8px' }}>
                                Chat
                            </Text>
                        </Row>
                    </Col>
                    <Col>
                        <Row style={{ alignItems: 'baseline' }}>
                            <Form.Item name="popup" valuePropName="checked">
                                <Switch />
                            </Form.Item>
                            <Text strong style={{ marginLeft: '8px' }}>
                                Popup
                            </Text>
                        </Row>
                    </Col>
                </Row>
                <Form.Item
                    label="Título"
                    name="title"
                    rules={[
                        { required: true, message: 'Título é obrigatório!' }
                    ]}
                    {...getError('title')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Imagem"
                    name="image"
                    rules={[
                        { required: true, message: 'Imagem é obrigatório!' }
                    ]}
                    {...getError('avatar')}
                >
                    <MediaUploader
                        options={{
                            optimize: {
                                resize: {
                                    width: 310,
                                    height: 210
                                },
                                quality: 80
                            }
                        }}
                        type="image"
                    />
                </Form.Item>

                <Form.Item
                    label="Mensagem"
                    name="message"
                    {...getError('message')}
                >
                    <Input.TextArea rows={4} />
                </Form.Item>

                <Form.Item
                    label="Link"
                    name="link"
                    rules={[{ required: true, message: 'Link é obrigatório!' }]}
                    {...getError('link')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Texto do Botão"
                    name="buttonText"
                    {...getError('button')}
                >
                    <Input placeholder="Saiba mais" />
                </Form.Item>
                <IntegrationInput eventId={eventId} />
            </Form>
        </CrudModal>
    )
}

export default NotificationTalkModal
