import React, { useMemo } from 'react'
import { Button, Popover } from 'antd'
import { useQuery } from '@apollo/client'

import { useParams, Link, useLocation } from 'react-router-dom'
import { GET_EVENT } from '@cms/events/graphql/queries/event'

import useEventDropdown from './hooks/useEventDropdown'

import { EventItem, EventList, EventsContainer, IconLinkButton } from './style'
import HeaderCrumb from '@cms/core/components/Header/HeaderCrumb'
import { useDispatch, useSelector } from 'react-redux'
import { headerMenuIcons } from '@cms/core/components/RemixIcons'
import { getCurrentMenu } from '@cms/core/components/Sidebar/common'
import { AllAppsModules } from '@cms/core/Apps'

const helpIcon = headerMenuIcons.help
const homeIcon = headerMenuIcons.home

export const HeaderMenu = [
    {
        key: 'main',
        label: (
            <IconLinkButton to={`/principal`}>
                {homeIcon.outline} Home
            </IconLinkButton>
        )
    },
    {
        key: 'help',
        label: (
            <IconLinkButton to={`/help`} target="_blank">
                {helpIcon.outline} Ajuda
            </IconLinkButton>
        )
    }
]

export const HeaderEvent = () => {
    const { eventId } = useParams() as { eventId: string }
    const isAdmin = useSelector((state) => state.user.isAdmin)
    const { items, EventSearchHeader } = useEventDropdown()
    const dispatch = useDispatch()
    const location = useLocation()

    const handleCreateEvent = (e) => {
        e.stopPropagation()
        dispatch({ type: 'SHOW_MODAL_EVENT' })
    }

    const currentLink = useMemo(() => {
        const curretMenu = getCurrentMenu(AllAppsModules, location.pathname)

        return curretMenu?.path || '/evento/:eventId/dashboard'
    }, [location.pathname])

    const { data } = useQuery(GET_EVENT, {
        variables: { id: eventId },
        fetchPolicy: 'network-only'
    })

    const { event } = data?.event ? data : { event: { name: 'Carregando...' } }

    const EventsContent = () => {
        return (
            <EventsContainer>
                <EventList>
                    {items.map((item) => {
                        return (
                            <Link
                                to={{
                                    pathname: currentLink.replace(
                                        ':eventId',
                                        item.id
                                    ),
                                    state: { forceRefresh: true }
                                }}
                                key={item.id}
                            >
                                <EventItem isActive={item.id === eventId}>
                                    {item.label}
                                </EventItem>
                            </Link>
                        )
                    })}
                </EventList>
                {isAdmin && (
                    <Button onClick={handleCreateEvent} type="primary">
                        Criar Evento
                    </Button>
                )}
            </EventsContainer>
        )
    }

    return (
        <>
            {event && eventId && (
                <Popover
                    placement="bottomRight"
                    title={EventSearchHeader}
                    content={items?.length > 1 ? EventsContent : null}
                    mouseLeaveDelay={0.3}
                >
                    <Link to={`/evento/${eventId}`}>
                        <HeaderCrumb title={event?.name} subtitle={'Evento'} />
                    </Link>
                </Popover>
            )}
        </>
    )
}

