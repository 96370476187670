import React from 'react'
import { Form } from 'antd'
import type { FormProps } from 'antd/lib/form'
import { useModulePermissions } from '../../Providers/ModulePermissionProvider'

interface ICrudFormProps extends FormProps {
    children: React.ReactNode
}
const CrudForm: React.FC<ICrudFormProps> = ({ children, ...props }) => {
    const { hasActions } = useModulePermissions()

    const isWrite = hasActions('write')

    return (
        <Form
            layout={'vertical'}
            {...props}
            disabled={!isWrite}
            data-form-disabled={!isWrite}
        >
            {children}
        </Form>
    )
}

export default CrudForm

