import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Select, Divider, Input } from 'antd'

import { FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'
import { useWidgetContext } from '../../context/widgets'

import Colors from '@cms/core/components/Colors'

const Fields = () => {
    return (
        <>
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>

            <FontSelector />

            <Form.Item
                name={['configs', 'variant']}
                label="Variante"
                initialValue={'default'}
            >
                <Select>
                    <Option value="default">Padrão</Option>
                    <Option value="detailed">Detalhado</Option>
                </Select>
            </Form.Item>

            <Wrapper transparent>
                <Title level={5}>Título</Title>

                <Form.Item name={['configs', 'title']} label="Texto">
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    name={['configs', 'titleTextcolor']}
                    label="Cor de texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>

            <Wrapper transparent>
                <Title level={5}>Caixas</Title>

                <Form.Item label="Formatos" name={['configs', 'boxShape']}>
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name={['configs', 'boxBgcolor']}
                    label="Cor de fundo"
                >
                    <Colors />
                </Form.Item>

                <Form.Item
                    name={['configs', 'boxTextcolor']}
                    label="Cor de texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>

            <Wrapper transparent>
                <Title level={5}>Filtros</Title>

                <Form.Item
                    name={['configs', 'filterboxBgcolor']}
                    label="Cor de fundo"
                >
                    <Colors />
                </Form.Item>

                <Form.Item
                    name={['configs', 'filterboxTextcolor']}
                    label="Cor de texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>

            <Wrapper transparent>
                <Title level={5}>Box de hora</Title>

                <Form.Item
                    name={['configs', 'timerboxBgcolor']}
                    label="Cor de fundo"
                >
                    <Colors />
                </Form.Item>

                <Form.Item
                    name={['configs', 'timerboxTextcolor']}
                    label="Cor de texto"
                >
                    <Colors />
                </Form.Item>
            </Wrapper>
        </>
    )
}
const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="schedule"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            // data-x-event="5f5f6ac9a7dd244f0b6d14bc"
            data-x-font="Montserrat"
            data-x-title="Confira os horários \ndas palestras e os palestrantes"
            //New props

            data-x-title-textcolor="red"
            data-x-box-shape="rounded"
            data-x-box-bgcolor="#000"
            data-x-box-textcolor="#fff"
            data-x-box-titlecolor="#fff"

            //Legacy props
            // data-x-color="#fff"
            // data-x-textcolor="#000"
            // data-x-timecolor="#642BDE"
            // data-x-shape="rounded"
        />
    )
}

export const Schedule = {
    Fields,
    Preview
}
