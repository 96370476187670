import React from 'react'
import Icon from '@ant-design/icons'

const AngrySvg = () => (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.99866 20.6497C15.5208 20.6497 19.9973 16.1731 19.9973 10.651C19.9973 5.12889 15.5208 0.652344 9.99866 0.652344C4.47655 0.652344 0 5.12889 0 10.651C0 16.1731 4.47655 20.6497 9.99866 20.6497Z" fill="url(#paint0_radial_5621_22887)"/>
    <path opacity="0.2" d="M9.99866 20.6497C15.5208 20.6497 19.9973 16.1731 19.9973 10.651C19.9973 5.12889 15.5208 0.652344 9.99866 0.652344C4.47655 0.652344 0 5.12889 0 10.651C0 16.1731 4.47655 20.6497 9.99866 20.6497Z" fill="url(#paint1_radial_5621_22887)"/>
    <path fillRule="evenodd"  d="M9.9994 14.7383C8.00934 14.7383 6.39258 15.544 6.39258 16.1563C6.39258 16.7713 8.00665 16.4839 9.9994 16.4839C11.9895 16.4839 13.6062 16.7686 13.6062 16.1563C13.6062 15.544 11.9921 14.7383 9.9994 14.7383Z" fill="url(#paint2_linear_5621_22887)"/>
    <path fillRule="evenodd"  d="M5.90509 8.97266C4.93558 8.97266 4.15137 10.1731 4.15137 11.6529C4.15137 13.1327 4.93558 13.6108 5.90509 13.6108C6.87461 13.6108 7.65882 13.1327 7.65882 11.6529C7.65613 10.1731 6.87193 8.97266 5.90509 8.97266Z" fill="url(#paint3_radial_5621_22887)"/>
    <path d="M7.84196 10.1358C7.82047 10.1358 7.7963 10.1358 7.77481 10.1331C4.71318 9.77324 2.74997 9.10183 2.66672 9.07497C2.36861 8.97292 2.21016 8.64795 2.31221 8.34985C2.41427 8.05174 2.73923 7.89329 3.03734 7.99534C3.05614 8.00071 4.72929 8.57007 7.33705 8.92726L7.50356 7.47701C7.53848 7.16279 7.82047 6.9372 8.13738 6.9748C8.4516 7.00971 8.67719 7.29439 8.63959 7.60861L8.40863 9.63359C8.39251 9.78398 8.31463 9.92095 8.19646 10.0176C8.09441 10.0928 7.97087 10.1358 7.84196 10.1358Z" fill="url(#paint4_linear_5621_22887)"/>
    <path opacity="0.1" fillRule="evenodd"  d="M4.92847 10.084C4.8855 10.1834 4.86133 10.2935 4.86133 10.4116C4.86133 10.8091 5.13258 11.1314 5.4656 11.1314C5.79862 11.1314 6.06987 10.8091 6.06987 10.4116C6.06987 10.374 6.0645 10.3391 6.06181 10.3015C5.65091 10.2317 5.27223 10.1592 4.92847 10.084Z" fill="white"/>
    <path fillRule="evenodd"  d="M14.0936 8.97266C15.0631 8.97266 15.8473 10.1731 15.8473 11.6529C15.8473 13.1327 15.0631 13.6108 14.0936 13.6108C13.1241 13.6108 12.3398 13.1327 12.3398 11.6529C12.3398 10.1731 13.1267 8.97266 14.0936 8.97266Z" fill="url(#paint5_radial_5621_22887)"/>
    <path opacity="0.1" fillRule="evenodd"  d="M13.0547 10.4563C13.0735 10.835 13.334 11.1358 13.6536 11.1358C13.9866 11.1358 14.2579 10.8135 14.2579 10.416C14.2579 10.365 14.2525 10.314 14.2444 10.2656C13.8738 10.3301 13.4763 10.3945 13.0547 10.4563Z" fill="white"/>
    <path d="M12.1582 10.1358C12.1797 10.1358 12.2038 10.1358 12.2253 10.1331C15.287 9.77324 17.2502 9.10183 17.3334 9.07497C17.6315 8.97292 17.79 8.64795 17.6879 8.34985C17.5859 8.05174 17.2609 7.89329 16.9628 7.99534C16.944 8.00071 15.2708 8.57007 12.6631 8.92726L12.4966 7.47701C12.4617 7.16279 12.1797 6.9372 11.8628 6.9748C11.5485 7.00971 11.3229 7.29439 11.3605 7.60861L11.5915 9.63359C11.6076 9.78398 11.6855 9.92095 11.8037 10.0176C11.903 10.0928 12.0293 10.1358 12.1582 10.1358Z" fill="url(#paint6_linear_5621_22887)"/>
    <defs>
    <radialGradient id="paint0_radial_5621_22887" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.99974 20.5633) scale(19.8867)">
    <stop stopColor="#FFA320"/>
    <stop offset="0.1883" stopColor="#FE9F1F"/>
    <stop offset="0.3587" stopColor="#FA941B"/>
    <stop offset="0.5222" stopColor="#F48215"/>
    <stop offset="0.6804" stopColor="#EC680C"/>
    <stop offset="0.828" stopColor="#E24902"/>
    <stop offset="1" stopColor="#E04300"/>
    </radialGradient>
    <radialGradient id="paint1_radial_5621_22887" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(9.99974 10.6521) scale(9.99974)">
    <stop stopColor="white"/>
    <stop offset="0.3726" stopColor="#FFFEFD"/>
    <stop offset="0.5069" stopColor="#FEF9F6"/>
    <stop offset="0.6026" stopColor="#FCF0EB"/>
    <stop offset="0.68" stopColor="#FAE4DA"/>
    <stop offset="0.7463" stopColor="#F7D4C4"/>
    <stop offset="0.805" stopColor="#F3C1A8"/>
    <stop offset="0.8581" stopColor="#EEAA88"/>
    <stop offset="0.9069" stopColor="#E98E62"/>
    <stop offset="0.9523" stopColor="#E36F37"/>
    <stop offset="0.9926" stopColor="#DC4F09"/>
    <stop offset="1" stopColor="#DB4800"/>
    </radialGradient>
    <linearGradient id="paint2_linear_5621_22887" x1="10.0005" y1="16.5536" x2="10.0005" y2="14.7395" gradientUnits="userSpaceOnUse">
    <stop stopColor="#85370E"/>
    <stop offset="1" stopColor="#482314"/>
    </linearGradient>
    <radialGradient id="paint3_radial_5621_22887" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(5.90399 11.2916) scale(1.63959 2.50631)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    <linearGradient id="paint4_linear_5621_22887" x1="2.48396" y1="8.55095" x2="8.53472" y2="8.55095" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D64B00"/>
    <stop offset="0.2432" stopColor="#CD4701"/>
    <stop offset="0.6383" stopColor="#B53D03"/>
    <stop offset="1" stopColor="#993106"/>
    </linearGradient>
    <radialGradient id="paint5_radial_5621_22887" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(14.0942 11.2916) rotate(180) scale(1.63959 2.50631)">
    <stop stopColor="#3B446B"/>
    <stop offset="1" stopColor="#202340"/>
    </radialGradient>
    <linearGradient id="paint6_linear_5621_22887" x1="17.5157" y1="8.55095" x2="11.4649" y2="8.55095" gradientUnits="userSpaceOnUse">
    <stop stopColor="#D64B00"/>
    <stop offset="0.2432" stopColor="#CD4701"/>
    <stop offset="0.6383" stopColor="#B53D03"/>
    <stop offset="1" stopColor="#993106"/>
    </linearGradient>
    </defs>
    </svg>
)

export const IconAngry = (props) => <Icon component={AngrySvg} {...props} />
