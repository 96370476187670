import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Col, Row, Switch, Typography, Input, Divider } from 'antd'
import { setLightness } from 'polished'
import { throttle } from 'lodash'

import ColorHls from '@cms/events/components/ColorHSL'

import { Wrapper } from './styles'
import { ConsoleSqlOutlined } from '@ant-design/icons'
import PopoverColor from '@cms/core/components/Colors/popoverColor'

const { Text } = Typography

const Colors = (props) => {
    const { value, type, onChange } = props
    const [checked, setChecked] = useState(false)
    const [color, setColor] = useState(null)
    const [color2, setColor2] = useState(null)

    const changeColors = useCallback(
        (_colors) => {
            let isValid = true
            _colors.split(';').forEach((_color) => {
                if (!/^#[0-9A-F]{3,6}$/i.test(color)) {
                    isValid = false
                }
            })

            if (isValid) {
                onChange(_colors)
            }
        },
        [onChange]
    )

    const debounceChange = useRef(throttle(onChange, 300)).current

    const handleChecked = (e) => {
        setChecked(e)

        if (e) {
            const _color = setLightness(0.5, value)
            setColor2(_color)
            onChange(color + ';' + _color)
        } else {
            setColor2(null)
            onChange(color)
        }
    }

    const handleColor1 = (e) => {
        let _value = e

        if (color2) {
            _value += ';' + color2
        }

        setColor(e)
        debounceChange(_value)
    }

    const handleColor2 = (e) => {
        let _value = color + ';' + e

        setColor2(e)
        debounceChange(_value)
    }

    useEffect(() => {
        if (value) {
            const [_color, _color2] = value.split(';')
            if (_color2) {
                setColor2(_color2)
                setChecked(true)
            }
            setColor(_color)
        }
    }, [value])

    return (
        <Wrapper>
            <Row justify="end">
                <Col>
                    <span style={{ marginRight: '10px' }}>Degrade</span>
                    <Switch onChange={handleChecked} checked={checked} />
                </Col>
            </Row>
            <Row className="colors-group" gutter={24}>
                <Col span={8}>
                    <Text>Cor</Text>
                    <Input
                        value={color}
                        onChange={(e) => handleColor1(e.target.value)}
                        maxLength={7}
                    />
                </Col>
                <Col span={16}>
                    <PopoverColor onChange={handleColor1} color={color} />
                </Col>
            </Row>
            {checked ? (
                <>
                    <Divider />
                    <Row className="colors-group" gutter={24}>
                        <Col span={8}>
                            <Text>Cor complementar</Text>
                            <Input
                                value={color2}
                                onChange={(e) => handleColor2(e.target.value)}
                                maxLength={7}
                            />
                        </Col>
                        <Col span={16}>
                            <PopoverColor
                                onChange={handleColor2}
                                color={color2}
                            />
                        </Col>
                    </Row>
                </>
            ) : null}
        </Wrapper>
    )
}

export default Colors
