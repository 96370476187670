import Faq from './Faq1/Faq'
import Faq2 from './Faq2/Faq'
import Faq3 from './Faq3/Faq'
import { getColorContrast } from '../../utils'
import { nanoid } from 'nanoid'

export const Faqs = {
    Faq: {
        Component: Faq,
        name: 'Perguntas frequentes (FAQ Dinamico)',
        category: 'FAQ',
        columns: '2',
        thumb: require('./Faq1/faq.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Faq2: {
        Component: Faq2,
        name: 'Perguntas frequentes (FAQ Manual)',
        category: 'FAQ',
        columns: '2',
        thumb: require('./Faq2/faq.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: 'Sobre o evento',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: 'Saiba o que as maiores lideranças de marketing do Brasil\n e do mundo têm a dizer.',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    },
    Faq3: {
        Component: Faq3,
        name: 'Perguntas frequentes centralizado (FAQ Manual)',
        category: 'FAQ',
        columns: '1',
        thumb: require('./Faq3/faq.png'),
        create: async (site) => {
            const colors = site.theme.colors
            return {
                id: 'section-' + nanoid(),
                container: {
                    bgColor: 'primary',
                    bgImage: {
                        src: null,
                        layout: 'fill', // fill, repeat
                        objectFit: 'fill', // fill, contain, cover, none
                        objectPosition: 'center center' // top, center, bottom
                    }
                },
                headline: {
                    text: '<div class="td-align-center">Sobre o evento</div>',
                    textColor: getColorContrast(colors, 'primary')
                },
                subtitle: {
                    text: '<div class="td-align-center">Saiba o que as maiores lideranças de marketing do Brasil e do mundo têm a dizer.</div>',
                    textColor: getColorContrast(colors, 'primary')
                }
            }
        }
    }
}
