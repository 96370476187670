import React from 'react'
import { TotalsChart } from '@cms/events/components/Dashboard'
import { TotalPerformance } from './styles'
import { TDDashboardCard } from '@cms/core/components/TDCards'

const EventCardReferral = ({ eventId }) => {
    return (
        <TDDashboardCard highlight padding={[16, 12, 16, 12]}>
            <TotalPerformance>
                <TotalsChart eventId={eventId} />
            </TotalPerformance>
        </TDDashboardCard>
    )
}

export default EventCardReferral
