export const BASE_URL = `/evento/:eventId/integracoes`

export const ROUTES = [
    {
        path: `conversions`,
        name: 'Conversions',
        home: true,
        checkModulePath: true,
        title: 'Conversões'
    },
    {
        path: `players`,
        name: 'Players',
        exact: true,
        checkModulePath: true,
        title: 'Players'
    },
    {
        path: `others`,
        name: 'Others',
        exact: true,
        checkModulePath: true,
        title: 'Outros'
    },
    {
        path: `logs`,
        name: 'Logs',
        exact: true,
        checkModulePath: true,
        title: 'Logs'
    }
]
