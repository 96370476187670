import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Select, Input } from 'antd'

import { FontSelector } from '../../components'
import { Wrapper, Title } from '../../styles'
import { useWidgetContext } from '../../context/widgets'
import Colors from '@cms/core/components/Colors'

const Fields = () => {
    return (
        <>
            <FontSelector />
            <Form.Item
                name={['configs', 'event']}
                label="Texto"
                style={{ display: 'none' }}
            >
                <Input />
            </Form.Item>
            <Wrapper transparent>
                {/* <Divider orientation="left">Cores</Divider> */}
                <Form.Item label="Formato caixas" name={['configs', 'shape']}>
                    <Select>
                        <Option value="circle">Redondo</Option>
                        <Option value="rounded">Arredondado</Option>
                        <Option value="default">Quadrado</Option>
                    </Select>
                </Form.Item>
            </Wrapper>
            <Wrapper transparent>
                <Title level={4}>Cores</Title>
                <Form.Item name={['configs', 'color']} label="Texto">
                    <Colors formItem />
                </Form.Item>
                <Form.Item name={['configs', 'logo']} label="Logo">
                    <Select>
                        <Option value="default">Original</Option>
                        <Option value="white">Branco</Option>
                        <Option value="black">Preto</Option>
                    </Select>
                </Form.Item>
            </Wrapper>
        </>
    )
}
const Preview = ({ db }) => {
    const { eventId } = useParams()

    if (!db) return null

    return (
        <div
            data-x-widget="sponsors"
            data-x-customer={db}
            data-x-preview="true"
            data-x-event={eventId}
            // data-x-event="5f5f6ac9a7dd244f0b6d14bc"
            data-x-font="Montserrat"
            data-x-color="#000"
            data-x-shape="rounded"
            data-x-logo="white"
        />
    )
}

export const Sponsors = {
    Fields,
    Preview
}
