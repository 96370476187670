import React from 'react'

import { Wrapper, BigCount } from '../styles'

const CardInner = ({ title, data, reverse, align, full }) => {

    return (
        <Wrapper reverse={reverse} align={align} full={full}>
            <>
                <h1>{title}</h1>
                <BigCount className={reverse ? 'reverse-count' : 'normal-count'}>
                    {data}
                </BigCount>
            </>
        </Wrapper>
    )
}

CardInner.defaultProps = {
    reverse: false,
    align: '',
    full: false
}

export default CardInner
