import React from 'react'

import MainRoutes from '@cms/events/routes/mainRoutes'
import AppLayout from '@cms/core/components/AppLayout'
import { HeaderMenu } from '@cms/events/components/Header'

const Main = function () {
    return (
        <AppLayout rootModule={'events.main'} headerMenuItems={HeaderMenu}>
            <MainRoutes />
        </AppLayout>
    )
}

export default Main
