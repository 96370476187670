import React from 'react'
import { Form, Input, Select } from 'antd'
import { get } from 'lodash'

import { ColorsBadges, WrapperConfig } from '..'

const actionsOptions = [
    { value: 'formModal', label: 'Abrir Formulário' },
    // { value: 'internal', label: 'Interno' },
    { value: 'external', label: 'Externo' },
    { value: 'streaming', label: 'Página de transmissão' }
]

const ButtonConfig = (props) => {
    const { enableText = true, enableActions, inner } = props
    const fieldPath = props.fieldPath || [props.name, 'configs', 'button']

    const getNode = (obj, path) => {
        const _path = path.splice(1, path.length - 1)
        return get(obj, _path)
    }

    const handleShowUrl = (prevValues, currentValues) => {
        const pathType = [...fieldPath, 'action', 'type']

        const prevValue = getNode(prevValues.sections[props.name], pathType)
        const currentValue = getNode(
            currentValues.sections[props.name],
            pathType
        )

        return prevValue !== currentValue
    }

    return (
        <WrapperConfig title={props.title || 'Botão'} inner={inner}>
            <Form.Item name={[...fieldPath, 'id']} label="id">
                    <Input />
                </Form.Item>
            {enableText && (
                <Form.Item name={[...fieldPath, 'text']} label="Texto">
                    <Input />
                </Form.Item>
            )}
            {enableActions && (
                <>
                    <Form.Item
                        name={[...fieldPath, 'action', 'type']}
                        label="Ação"
                    >
                        <Select options={actionsOptions} />
                    </Form.Item>
                    <Form.Item noStyle shouldUpdate={handleShowUrl}>
                        {({ getFieldsValue }) => {
                            const paths = [...fieldPath, 'action', 'type']
                            const obj = getFieldsValue()
                            const type = getNode(
                                obj.sections[props.name],
                                paths
                            )

                            return type === 'external' ? (
                                <Form.Item
                                    name={[...fieldPath, 'action', 'url']}
                                    label="Url"
                                    rules={[{ required: true }]}
                                >
                                    <Input />
                                </Form.Item>
                            ) : null
                        }}
                    </Form.Item>
                </>
            )}
            <Form.Item name={[...fieldPath, 'bgColor']} label="Cor de fundo">
                <ColorsBadges />
            </Form.Item>
            <Form.Item name={[...fieldPath, 'textColor']} label="Cor do texto">
                <ColorsBadges />
            </Form.Item>
        </WrapperConfig>
    )
}

export default ButtonConfig
