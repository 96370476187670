import React from 'react'
import { Button, Collapse, Form, Input } from 'antd'

import { ContainerConfig, Uploader } from '../../components'
import { Wrapper, Title } from '../../styles'
import RichTextEditor from '../../components/RichTextEditor/Editor'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { nanoid } from 'nanoid'

const { Panel } = Collapse

const Footer3 = (props) => {
    return (
        <>
            <ContainerConfig {...props} />
            <Wrapper>
                <Title level={4}>Informações do rodapé</Title>
                <Form.Item
                    name={[props.name, 'configs', 'firstText', 'text']}
                    label={'Texto lado direito'}
                >
                    <RichTextEditor />
                </Form.Item>

                <Wrapper>
                    <Title level={4}>Links</Title>
                    <Form.List
                        name={[props.name, 'configs', 'social', 'items']}
                    >
                        {(fields, { add, remove }) => (
                            <>
                                <Collapse>
                                    {fields.map(
                                        ({ key, name, ...restField }, i) => (
                                            <Panel
                                                header={i + 1 + 'º link'}
                                                key={key}
                                                extra={
                                                    <DeleteOutlined
                                                        onClick={() =>
                                                            remove(name)
                                                        }
                                                    />
                                                }
                                            >
                                                <Form.Item
                                                    name={[name, 'icon', 'src']}
                                                    label="Icone da rede"
                                                >
                                                    <Uploader />
                                                </Form.Item>

                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'url']}
                                                    label="Url"
                                                >
                                                    <Input placeholder="Url" />
                                                </Form.Item>
                                            </Panel>
                                        )
                                    )}
                                </Collapse>

                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() =>
                                            add({
                                                id: nanoid(),
                                                button: {
                                                    active: false
                                                }
                                            })
                                        }
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        Adicionar Link
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                </Wrapper>

                <Form.Item
                    name={[props.name, 'configs', 'bottomText', 'text']}
                    label={'Texto rodapé'}
                >
                    <RichTextEditor />
                </Form.Item>
            </Wrapper>
        </>
    )
}

export default Footer3
