import { useApolloClient, useQuery, gql } from '@apollo/client'
import {
    CrudModal,
    useCrudForm,
    useCrudSelect
} from '@cms/core/components/Crud'
import SelectSearch from '@cms/core/components/SelectSearch'
import { ADD_TICKET_TO_USER } from '@cms/events/graphql/mutations/tickets'
import {
    GET_TICKETS_LIST,
    GET_TICKET_OWNERSHIPS_LIST
} from '@cms/events/graphql/queries/tickets'
import { useHistory, useParams } from 'react-router-dom'
import { Form, message } from 'antd'
import React from 'react'

const AddTicketToUser = ({ params, ...props }) => {
    const { eventId } = params
    const { userId } = useParams()
    const history = useHistory()
    const client = useApolloClient()

    const queryTickets = useQuery(GET_TICKETS_LIST, {
        variables: { first: 9999, eventId }
    })

    const [ticketsOptions, ticketsSelectOptions] = useCrudSelect(queryTickets, {
        queryName: 'tickets'
    })

    const [{ form, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            return { id }
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: ADD_TICKET_TO_USER,
                variables: { eventRegister: id, ...formData },
                refetchQueries: [
                    {
                        query: GET_TICKET_OWNERSHIPS_LIST,
                        variables: { eventId: eventId, userId: userId }
                    }
                ],
                update: (cache, { data }) => {
                    cache.updateFragment(
                        {
                            id: 'EventRegistration:' + id,
                            fragment: gql`
                                fragment UpdateRegisterTicket on EventRegistration {
                                    id
                                    ticketOwnership {
                                        id
                                    }
                                }
                            `
                        },
                        (_data) => {
                            return {
                                ..._data,
                                ticketOwnership: data.addTicketToUser
                            }
                        }
                    )
                }
            })

            message.success('Tipo de ingresso vinculado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={'Vincular tipo de ingresso'}
            {...modalProps}
            {...props}
            path={history.location.pathname.replace('/add-ticket', '')}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Tipo de ingresso"
                    name={['ticket']}
                    {...getError('ticket')}
                >
                    <SelectSearch mode="single" {...ticketsSelectOptions}>
                        {ticketsOptions({
                            key: 'node.id',
                            label: 'node.name'
                        })}
                    </SelectSearch>
                </Form.Item>
            </Form>
        </CrudModal>
    )
}

export default AddTicketToUser
