import React from 'react'
import BaseConfig from '../BaseConfig'
import Segment from '@cms/core/components/Segment'
import { COURSES_CONFIGS } from '@cms/core/components/Segment/constants/courses'

interface ICoursesProps {
    name: string
}

export function Courses({ name }: ICoursesProps) {
    return (
        <>
            <BaseConfig name={name} title={true} description={false} />
            <Segment
                name={[name, 'config']}
                type="courses"
                filters={COURSES_CONFIGS}
                configs={{
                    formName: 'sections'
                }}
            />
        </>
    )
}

