import React from 'react'
import { Row, Col } from 'antd'
import { DashboardCard } from '@cms/events/components/Dashboard'
import {
    DisplayTime,
    PeakAudience,
    TotalViews,
    TalkViews
} from '../../BlockItens'

const CardsComponents = ({ eventId, category, talk, type, span }) => {
    return (
        <Row
            type="flex"
            gutter={24}
            style={{ padding: '20px 0', width: '100%' }}
            justify="center"
        >
            <Col span={span}>
                <DashboardCard>
                    <TotalViews
                        eventId={eventId}
                        category={category}
                        talk={talk}
                        reverse={true}
                        align={'center'}
                    />
                </DashboardCard>
            </Col>
            <Col span={span}>
                <DashboardCard>
                    <DisplayTime
                        eventId={eventId}
                        category={category}
                        talk={talk}
                        reverse={true}
                        align={'center'}
                    />
                </DashboardCard>
            </Col>
            {type !== 'ondemand' && (
                <Col span={span}>
                    <DashboardCard>
                        <PeakAudience
                            eventId={eventId}
                            category={category}
                            talk={talk}
                            reverse={true}
                            align={'center'}
                        />
                    </DashboardCard>
                </Col>
            )}
            <Col span={span}>
                <DashboardCard>
                    <TalkViews
                        talk={talk}
                        reverse={true}
                        align={'center'}
                        event={eventId}
                    />
                </DashboardCard>
            </Col>
        </Row>
    )
}

export default CardsComponents
