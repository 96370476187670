import React from 'react'
import dayjs from 'dayjs'
import { Row, Typography } from 'antd'
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    Line,
    LineChart
} from 'recharts'

import { useQuery } from '@apollo/client'
import { GET_DAILY_OVERALL_PERFOMANCE } from '@cms/events/graphql/queries/dashboards'
import Empty from '@cms/core/components/Empty'

import { RiseOutlined } from '@ant-design/icons'
import {
    IconWrapper,
    ContainerChart,
    DashboardTitle,
    DashboardCardTitle
} from '../styles'

import Loader from '@cms/core/components/Loader'

const { Text } = Typography

const ReferalChart = ({ event, viralCampaign }) => {
    const { data, error } = useQuery(GET_DAILY_OVERALL_PERFOMANCE, {
        variables: { eventId: event.id }
    })

    if (error) return `Error! ${error.message}`
    if (!data) return <Loader fixed={false} padding="10px" />

    let startDate, endDate

    if (
        data.dailyOverallPerformance &&
        data.dailyOverallPerformance.length > 0
    ) {
        const items = data.dailyOverallPerformance
        startDate = new Date(items[items.length - 1].date).getTime()
        endDate = new Date(items[0].date).getTime()
    }

    return (
        <ContainerChart>
            <DashboardCardTitle>
                <IconWrapper>
                    <RiseOutlined style={{ color: '#fff' }} />
                </IconWrapper>
                <DashboardTitle>Desempenho dos últimos 30 dias</DashboardTitle>
            </DashboardCardTitle>

            <ResponsiveContainer width="100%" height={300}>
                {data.dailyOverallPerformance &&
                data.dailyOverallPerformance.length > 0 ? (
                    <LineChart
                        data={data.dailyOverallPerformance.map((values) => ({
                            ...values,
                            total: values.referrals + values.registrations
                        }))}
                        margin={{ top: 30, right: 10, left: -10, bottom: 0 }}
                    >
                        <XAxis
                            domain={[startDate, endDate]}
                            dataKey="date"
                            type={'number'}
                            tickFormatter={(v) =>
                                dayjs.utc(v).format('DD/MM/YYYY')
                            }
                            tickCount={30}
                            axisLine={false}
                            tickLine={false}
                            tickMargin={10}
                        />
                        <YAxis
                            dataKey="total"
                            axisLine={false}
                            tickLine={false}
                            padding={{ right: 30 }}
                        />

                        <CartesianGrid
                            vertical={false}
                            stroke="rgba(255, 255, 255, 0.1)"
                            strokeDasharray="2 0"
                        />
                        <Tooltip
                            labelFormatter={(v) =>
                                dayjs.utc(v).format('DD/MM/YYYY')
                            }
                        />
                        <Legend />
                        <Line
                            type="monotone"
                            stroke="#7F44FF"
                            name="Não Indicados"
                            dataKey="registrations"
                            strokeWidth={5}
                            dot={false}
                        />

                        <Line
                            type="monotone"
                            stroke="#00D394"
                            name="Indicações"
                            dataKey="referrals"
                            strokeWidth={5}
                            dot={false}
                        />

                        {viralCampaign &&
                            viralCampaign.dailyGoals.subscribers && (
                                <ReferenceLine
                                    y={viralCampaign.dailyGoals.subscribers}
                                    stroke="rgba(127, 68, 255, .9)"
                                    strokeDasharray="3 3"
                                    isFront={true}
                                />
                            )}

                        {viralCampaign &&
                            viralCampaign.dailyGoals.referrals && (
                                <ReferenceLine
                                    y={viralCampaign.dailyGoals.referrals}
                                    stroke="rgba(0, 211, 148, .9)"
                                    strokeDasharray="3 3"
                                    isFront={true}
                                />
                            )}
                    </LineChart>
                ) : (
                    <Empty />
                )}
            </ResponsiveContainer>
        </ContainerChart>
    )
}

export default ReferalChart
