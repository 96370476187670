import React from 'react'
import { Form, Input, Divider, Select, Tag } from 'antd'

import { ColorsBadges, ContainerConfig } from '../../../components'
import { Wrapper, Title } from '../../../styles'
import { GET_TALK_TAGS } from '@cms/events/graphql/queries/talk'
import { useQuery } from '@apollo/client'
import { useParams } from 'react-router-dom'

const Schedule = (props) => {
    const { eventId } = useParams()
    const { data: TagsData } = useQuery(GET_TALK_TAGS, {
        variables: { eventId },
        fetchPolicy: 'no-cache'
    })

    return (
        <>
            <ContainerConfig {...props} />

            <Wrapper>
                <Title level={4}>Título</Title>
                <Form.Item
                    name={[props.name, 'configs', 'headline', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'headline', 'text']}
                    label="Text"
                >
                    <Input.TextArea />
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Regras de exibição</Title>

                <Form.Item
                    name={[props.name, 'configs', 'filters', 'tags']}
                    label="Exibir apenas palestras com as tags:"
                >
                    <Select
                        mode="tags"
                        tagRender={({ label, ...props }) => (
                            <Tag {...props} color="#7F44FF">
                                {label}
                            </Tag>
                        )}
                        style={{ width: '100%' }}
                        placeholder="Selecione uma tag existente ou crie uma nova."
                    >
                        {TagsData?.talkTags?.map((tag) => (
                            <Select.Option key={tag} value={tag}>
                                {tag}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Wrapper>

            <Wrapper>
                <Title level={4}>Box</Title>
                <Form.Item
                    name={[props.name, 'configs', 'box', 'bgColor']}
                    label="Cor do fundo"
                >
                    <ColorsBadges />
                </Form.Item>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Divider orientation="left">Tempo</Divider>

                <Form.Item
                    name={[props.name, 'configs', 'box', 'time', 'textColor']}
                    label="Cor do texto"
                >
                    <ColorsBadges />
                </Form.Item>

                <Wrapper>
                    <Title level={4}>Bagde</Title>

                    <Form.Item
                        name={[props.name, 'configs', 'badge', 'bgColor']}
                        label="Cor da badge"
                    >
                        <ColorsBadges />
                    </Form.Item>
                </Wrapper>
            </Wrapper>
        </>
    )
}

export default Schedule
