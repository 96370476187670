const dayjs = require('dayjs')

const configs = {
    system: false,
    required: false,
    size: 24
}

const limits = {
    min: 1,
    max: 255,
    lengthControl: false
}

const dateLimits = {
    dateLimits: false,
    maxCurrentDate: true,
    minCurrentDate: false,
    min: undefined,
    max: undefined
}

export const allowFloatLabelTypes = [
    'text',
    'email',
    'textarea',
    'number',
    'select',
    'date',
    'document',
    'tel'
]

export const FIELDS = [
    {
        type: 'text',
        label: '',
        btnLabel: 'Text',
        placeholder: 'Text',
        options: null,
        config: {
            ...limits,
            ...configs
        }
    },
    {
        type: 'textarea',
        label: '',
        btnLabel: 'Textarea',
        placeholder: 'Textarea',
        options: null,
        config: {
            rows: 5,
            ...configs
        }
    },
    {
        type: 'number',
        label: '',
        btnLabel: 'Number',
        placeholder: 'Number',
        options: null,
        config: {
            ...limits,
            ...configs
        }
    },
    {
        type: 'select',
        label: '',
        btnLabel: 'Select',
        placeholder: 'Select',
        options: [
            {
                label: 'Option 1',
                value: 'Option 1'
                // value: 'option-1'
            },
            {
                label: 'Option 2',
                value: 'Option 2'
                // value: 'option-2'
            },
            {
                label: 'Option 3',
                value: 'Option 3'
                // value: 'option-3'
            }
        ],
        config: {
            multiple: false,
            ...configs
        }
    },
    {
        type: 'checkbox',
        label: '',
        btnLabel: 'Checkbox',
        placeholder: 'Checkbox',
        integrations: [
            {
                label: 'Option 1',
                value: 'Option 1'
                // value: 'option-1'
            },
            {
                label: 'Option 2',
                value: 'Option 2'
                // value: 'option-2'
            }
        ],
        options: null,
        config: {
            ...configs
        }
    },
    {
        type: 'radio',
        label: '',
        btnLabel: 'Radio',
        placeholder: 'Radio',
        options: [
            {
                label: 'Option 1',
                value: 'Option 1'
                // value: 'option-1'
            },
            {
                label: 'Option 2',
                value: 'Option 2'
                // value: 'option-2'
            }
        ],
        config: {
            ...configs
        }
    },
    {
        type: 'date',
        label: '',
        btnLabel: 'Data',
        placeholder: 'Date',
        options: null,
        config: {
            ...dateLimits,
            ...configs
        }
    }
    // {
    //     type: 'time',
    //     label: 'Time',
    //     btnLabel: 'Time',
    //     placeholder: 'Time',
    //
    //     options: null,
    //     config: {
    //         ...configs
    //     }
    // },
    // {
    //     type: 'datetime',
    //     label: 'Datetime',
    //     btnLabel: 'Datetime',
    //     placeholder: 'Datetime',
    //
    //     options: null,
    //     config: {
    //         ...configs
    //     }
    // }
]

export const FIELDSETS = [
    {
        name: 'jobTitle',
        label: 'Cargo',
        type: 'select',
        options: [
            {
                label: 'Gerente',
                value: 'Gerente'
                // value: 'manager'
            },
            {
                label: 'Analista',
                value: 'Analista'
                // value: 'analyst'
            },
            {
                label: 'Desenvolvedor',
                value: 'Desenvolvedor'
                // value: 'developer'
            }
        ],
        config: {
            ...configs
        }
    },
    {
        name: 'numberOfEmployees',
        label: 'Número de funcionários',
        type: 'select',
        options: [
            {
                label: '1-10',
                value: '1-10'
                // value: '1-10'
            },
            {
                label: '11-50',
                value: '11-50'
                // value: '11-50'
            },
            {
                label: '51-100',
                value: '51-100'
                // value: '51-100'
            },
            {
                label: '101-200',
                value: '101-200'
                // value: '101-200'
            },
            {
                label: '201-500',
                value: '201-500'
                // value: '201-500'
            }
        ],
        config: {
            ...configs
        }
    },
    {
        name: 'phone',
        label: 'Telefone',
        type: 'tel',
        options: null,
        config: {
            mask: '(99) 9999-9999',
            ...configs
        }
    },
    {
        name: 'document',
        label: 'Cpf/Cnpj',
        type: 'document',
        options: null,
        config: {
            mask: {
                value: 'cpfCpnpj'
            },
            ...configs
        }
    },
    {
        name: 'address',
        label: 'Endereço',
        type: 'address',
        options: null,
        config: {
            ...configs
        }
    },
    {
        name: 'address',
        label: 'Estado/Cidade',
        type: 'stateCity',
        options: null,
        config: {
            ...configs
        }
    },
    {
        name: 'address',
        label: 'Cep/Estado/Cidade',
        type: 'cepStateCity',
        options: null,
        config: {
            ...configs
        }
    },
    {
        name: 'termOfUse',
        label: 'Eu li e concordo com os',
        btnLabel: 'Termos de uso',
        type: 'term',
        options: null,
        config: {
            size: 24,
            url: '',
            text: '',
            label: 'termos de uso'
        }
    },
    {
        name: 'section',
        label: 'Nova seção',
        btnLabel: 'Nova seção',
        type: 'section',
        options: null,
        config: {
            types: ['progressive']
        }
    }
]
