import React, { createContext, useState, useEffect, useCallback } from 'react'

import { IApp, IAppModule } from '@cms/core/types/app'

interface IAppContext {
    app: IApp
    rootModuleKey?: string
    setRootModule?: (rootModule: string) => void
}

const AppContext = createContext<IAppContext>({
    app: null,
    rootModuleKey: null,
    setRootModule: () => {}
})

interface AppProviderProps {
    children?: React.ReactNode
    app: IApp
    defaultRootModule?: string
}

const AppProvider = ({ children, app }: AppProviderProps) => {
    const [rootModuleKey, _setRootModule] = useState<string>(
        app.defaultRootModule
    )

    useEffect(() => {
        window.localStorage.setItem('@td-app-id', app.id)
    }, [app.id])

    const setRootModule = useCallback((rootModule: string) => {
        _setRootModule(rootModule)
        window.localStorage.setItem('@td-app-root-module', rootModule)
    }, [])
    return (
        <AppContext.Provider
            value={{
                app,
                rootModuleKey,
                setRootModule
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export { AppContext, AppProvider }

