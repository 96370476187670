import styled from 'styled-components'
import { Card } from 'antd'

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: row;
    align-content: flex-start;
    max-height: 60vh;
    min-height: 60vh;
    overflow-y: scroll;
    width: calc(100% - 256px);
    padding: 0px 12px;

    .ant-empty {
        margin: auto;
    }
`

export const CustomCard = styled(Card)`
    margin: 5px;
    transition: 0.1s;
    max-width: 270px;
    min-width: 270px;
    max-height: 210px;

    .ant-card-body {
        border-radius: inherit;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        overflow-y: hidden;

        .ant-image {
            width: 270px;
            height: 170px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`
