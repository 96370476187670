import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { merge } from 'lodash'

import { Row, Col, Form, message } from 'antd'

import ModalForm from '../Modal'
import Loader from '@cms/core/components/Loader'
import { useMutation, useQuery } from '@apollo/client'
import { useHistory, useParams } from 'react-router-dom'

import {
    CREATE_INTEGRATION,
    CREATE_OR_UPDATE_INTEGRATION,
    UPDATE_INTEGRATION
} from '@cms/core/graphql/mutations/integrations'

import { GET_INTEGRATIONS } from '@cms/core/graphql/queries/integrations'

import CardItem from '../CardItem'
import CardNewItem from '../CardItem/New'

import * as _connectors from '../connectores/configs'

type Props = {
    config: {
        category: string
        unique?: boolean
        appType: 'EVENTS' | 'WATCH'
    }
}

const Integration = ({ config }) => {
    const { eventId } = useParams<any>()
    const history = useHistory()
    const [editIntegration, setEditIntegration] = useState(null)
    const [integrations, setIntegrations] = useState([])
    const [loading, setLoading] = useState(true)

    const { data } = useQuery(GET_INTEGRATIONS, {
        variables: {
            eventId,
            category: config.category,
            appType: config.appType
        }
    })

    const [createOrUpdate] = useMutation(CREATE_OR_UPDATE_INTEGRATION)
    const [create] = useMutation(CREATE_INTEGRATION)
    const [update] = useMutation(UPDATE_INTEGRATION)

    const connectors = useMemo(() => {
        return Object.keys(_connectors)
            .filter((key) => _connectors[key].category === config.category)
            .map((key) => _connectors[key])
    }, [config.category])

    useEffect(() => {
        if (!data?.integrations) return

        const _integrations = data.integrations.edges.map((edge) => edge.node)

        if (config?.unique === true) {
            setIntegrations(
                connectors.map((connector) => {
                    const integration = _integrations.find(
                        (integration) => integration.type === connector.type
                    )
                    return merge(
                        {
                            active: false,
                            category: connector.category,
                            name: connector.title,
                            type: connector.type,
                            config: {},
                            privateConfig: {}
                        },
                        integration
                    )
                })
            )
        } else if (config?.unique === false) {
            setIntegrations(
                _integrations.map((integration) =>
                    merge(
                        {
                            config: {},
                            privateConfig: {}
                        },
                        integration
                    )
                )
            )
        }

        setLoading(false)
    }, [config?.unique, connectors, data])

    const saveIntegration = useCallback(
        async (integration) => {
            setLoading(true)
            const variables = {
                ...integration,
                event: eventId,
                appType: config.appType
            }

            if (config?.unique === true) {
                await createOrUpdate({
                    variables
                })
            } else if (config?.unique === false) {
                if (variables.id) {
                    await update({
                        variables
                    }).then(() => {
                        setIntegrations((integrations) =>
                            integrations.map((item) => {
                                if (item.id === variables.id) {
                                    return variables
                                }
                                return item
                            })
                        )
                    })
                } else {
                    await create({
                        variables
                    }).then(({ data }) => {
                        setIntegrations((integrations) => [
                            ...integrations,
                            {
                                ...variables,
                                id: data.createIntegration.id
                            }
                        ])
                    })
                }
            }
            message.success('Integração salva com sucesso')
            setLoading(false)
        },
        [eventId, config?.unique]
    )

    const onChangeActive = (integration, active) => {
        setIntegrations((integrations) =>
            integrations.map((item) => {
                if (item.type === integration.type) {
                    return {
                        ...item,
                        active
                    }
                }
                return item
            })
        )

        saveIntegration({
            ...integration,
            active
        })
    }

    return (
        <>
            <Row gutter={[24, 24]} align="stretch">
                {loading && <Loader fixed={false} />}

                {config?.unique === false && (
                    <Col span={8}>
                        <CardNewItem
                            onClick={() =>
                                setEditIntegration({
                                    active: true,
                                    category: config.category,
                                    name: '',
                                    type: '',
                                    config: {},
                                    privateConfig: {}
                                })
                            }
                        />
                    </Col>
                )}

                {integrations
                    .filter((o) => !!o.type?.trim())
                    .map((integration) => (
                        <Col key={integration.id || integration.type} span={8}>
                            <CardItem
                                hiddenName={config?.unique === true}
                                onChangeActive={(e) =>
                                    onChangeActive(integration, e)
                                }
                                onEdit={() => {
                                    if (integration.type.includes('Zoho')) {
                                        return history.push(
                                            `steps/${integration.type}?integration=${integration.type}&id=${integration.id}`
                                        )
                                    }
                                    setEditIntegration(integration)
                                }}
                                integration={integration}
                            />
                        </Col>
                    ))}
            </Row>

            {editIntegration && (
                <ModalForm
                    onSave={saveIntegration}
                    onCancel={() => setEditIntegration(null)}
                    integration={editIntegration}
                />
            )}
        </>
    )
}

export default Integration

