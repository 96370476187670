import { gql } from "@apollo/client";
import * as fragments from '../fragments'


export const GET_QUIZZES = gql`
  query GetQuizzes(
      $talkId: ObjectID!
      $first: Int
      $offset: BigInt
      $search: String
  ){
      quizzes(
          filter: { talk: $talkId }
          first: $first
          offset: $offset
          search: $search
      ){
          totalCount
          edges {
              node{
                  ...QuizData
              }
          }
      }
  }
  ${fragments.QuizData}
`

export const GET_QUIZ = gql`
    query GetQuiz(
        $id: ObjectID!
    ){
        quiz(id: $id){
            ...QuizData
        }
    }
    ${fragments.QuizData}
`

export const GET_QUIZ_REPORT = gql`
    query GetQuizReport(
        $id: ObjectID!
    ){
        quizReport(id: $id){
            ...QuizData
        }
    }
    ${fragments.QuizData}
`