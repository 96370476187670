import styled from 'styled-components'
import { Tag, Typography } from 'antd'
import { transparentize } from 'polished'

const { Text } = Typography

export const TagDefault = styled(Tag)`
    &.ant-tag { 
        border-radius: 40px;
        border: none;
        background-color:  ${({ theme }) => transparentize(0.9, theme.color.primaryColor)};
        color: #FFFFFF;
        width: 47px;
        margin: 0;
    }
    
`
export const Totals = styled(Tag)`
    &.ant-tag { 
        border-radius: 40px;
        border: none;
        background-color:  ${({ theme }) => transparentize(0.8, theme.color.primaryColor)};
        color: #fff;
        width: 47px;
        margin: 0;
        font-weight: bold;
    }
`
export const TextTitle = styled(Text)`
    &.ant-typography { 
       font-size: 12px;
       
 }
`