import styled, { css } from 'styled-components'
import Color from 'color'
import { Radio } from 'antd'
import { COLORS } from '@cms/core/themes'

export const Container = styled.div`
    .ant-page-header {
        margin-bottom: 16px;
        padding: 0;

        span.ant-page-header-heading-extra {
            display: flex;
            width: 100%;
            justify-content: flex-end;
        }
    }
`
export const CardGroup = styled(Radio.Group)`
    display: block;
`

export const CardItem = styled(Radio)`
    background: ${({ theme }) => theme.color.backgroundDarkLight};
    padding: 20px;
    border-radius: 11px;
    border: 1px solid #ffffff12;
    position: relative;
    display: block;
    transition: border-color 0.2s ease-out;
    margin-top: 10px;
    cursor: pointer;
    white-space: initial;
    height: 100%;

    .ant-radio-wrapper {
        > span {
            display: block;
            height: 100%;
        }
    }

    h4.ant-typography {
        color: ${({ theme }) => theme.color.primaryColor};
    }

    .ant-radio {
        display: none;
    }

    &.ant-radio-wrapper-checked {
        border-color: ${({ theme }) => theme.color.activeColors};
        border-width: 2px;
    }

    &:hover {
        opacity: 0.6;
        border-color: ${({ theme }) => theme.color.activeColors};
    }
`

export const CardNew = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 100%;
    padding: 20px;
    border-radius: 11px;
    border: 1px solid ${({ theme }) => theme.color.backgroundDarkLight};
    position: relative;
    transition: border-color 0.2s ease-out;
    margin-top: 10px;
    cursor: pointer;
    white-space: initial;
    ${({ newIntegration }) =>
        newIntegration &&
        css`
            background-color: ${({ theme }) => theme.color.backgroundBtnUpload};
        `}

    .ant-btn {
        margin-bottom: 8px;
    }

    &:hover {
        opacity: 0.6;
        border-color: ${({ theme }) => theme.color.activeColors};
    }
`

export const CardLogo = styled.img`
    padding-bottom: 15px;
    margin: 0 auto;
    min-height: 60px;
    max-height: 60px;
`

export const WelcomeEmailContent = styled.div`
    &, .ant-layout {
        background: ${COLORS.bgLight};
        border-radius: 8px;
    }

    .td-content-default {
        margin: 0;
        padding: 0;
    }
`
