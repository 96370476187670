import React, { useState } from 'react'
import { message, Form, Input, Row, Col, Collapse, Switch } from 'antd'
import { useApolloClient } from '@apollo/client'

import { CrudModal, useCrudForm } from '@cms/core/components/Crud'

import { addToList } from '@cms/core/graphql/utils'
import { GET_CUSTOM_TRACKER } from '@cms/events/graphql/queries/trackers'
import {
    CREATE_CUSTOM_TRACKER,
    UPDATE_CUSTOM_TRACKER
} from '@cms/events/graphql/mutations/trackers'

const { Panel } = Collapse

const TrackerModal = ({ params, ...props }) => {
    const { eventId } = params
    const client = useApolloClient()

    const [{ form, isEdit, getError }, modalProps] = useCrudForm({
        getData: async (id) => {
            if (!id) return

            const { data } = await client.query({
                query: GET_CUSTOM_TRACKER,
                variables: { id }
            })

            return { ...data.customTracker }
        },
        createData: async (formData) => {
            await client.mutate({
                mutation: CREATE_CUSTOM_TRACKER,
                update: addToList({
                    list: 'customTrackers',
                    Type: 'CustomTracker'
                }),
                variables: {
                    ...formData,
                    event: eventId
                }
            })

            message.success('Tracker cadastrado com sucesso!')
        },
        updateData: async (id, formData) => {
            await client.mutate({
                mutation: UPDATE_CUSTOM_TRACKER,
                variables: {
                    id,
                    ...formData,
                    event: eventId
                }
            })

            message.success('Tracker atualizado com sucesso!')
        }
    })

    return (
        <CrudModal
            title={isEdit ? 'Editar tracker' : 'Novo tracker'}
            {...modalProps}
            {...props}
        >
            <Form layout={'vertical'} form={form}>
                <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                        {
                            required: true,
                            message: 'Nome é obrigatório!'
                        }
                    ]}
                    {...getError('customTracker')}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Nome do evento"
                    name="eventName"
                    rules={[
                        {
                            required: true,
                            message: 'Nome do evento é obrigatório!'
                        }
                    ]}
                    {...getError('customTracker')}
                    value
                >
                    <Input disabled={isEdit} placeholder="my-custom-event" />
                </Form.Item>

                <Form.Item
                    label="Descrição"
                    name="description"
                    {...getError('customTracker')}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Mensagem do evento"
                    name="eventMessage"
                    rules={[
                        {
                            required: true,
                            message: 'Mensagem é obrigatória!'
                        }
                    ]}
                    {...getError('customTracker')}
                >
                    <Input.TextArea />
                </Form.Item>

                <Form.Item
                    label="Ativo"
                    name={'active'}
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>

                <Row>
                    <Col span={24}>
                        <Collapse>
                            <Panel header="Variáveis para usar na mensagem do tracker">
                                <p>{`{{ name }} para acessar o nome do inscrito.`}</p>
                                <p>{`{{ email }} para acessar o email do inscrito.`}</p>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
            </Form>
        </CrudModal>
    )
}

export default TrackerModal
